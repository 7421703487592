import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react'
import * as S from './DetailPage.style'
import {
  Paper,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import List from '../../../components/detail/detail/List'
import SubList from '../../../components/detail/detail/SubList'
import ProgressBarOrigin from '../../../elements/progressBar/ProgressBarOrigin'

const DetailListPage = (props) => {
  const dispatch = useDispatch()
  const [ pageNum, setPageNum ] = useState(1)
  const [ filter, setFilter ] = useState('')

  // useEffect(() => {
  //   dispatch(matchingActions.getMatchingDataListAPI(pageNum, filter, search));
  // }, [dispatch, filter, pageNum, search]);

  const getFilter = (filter) => {
    setFilter(filter);
  }

  const getPageNum = (page) => {
    setPageNum(page);
  }

  return (
    <Container direction={'column'} >
      <S.Wrap>
        <S.Title>2022년도 1학기 중간고사 프로젝트 라벨링 에디터</S.Title>
        <S.Info>
          <div className='desc_wrap'>
            <div>기간: 2022.03.20 ~ 2022.04.20</div>
            <div>단가: OCR 한 문항 당 700원, NOCR 850원</div>
          </div>
          <ProgressBarOrigin overall={400} done={132} percent={36} />
        </S.Info>
        <SubList
          getFilter={getFilter}
        />
        <List
          getPageNum={getPageNum}
        />
      </S.Wrap>
    </Container>
  )
}
const Container = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.direction};
  overflow-y: auto;
  background: transparent;
  min-height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  /* border: 1px solid red; */
`

export default DetailListPage