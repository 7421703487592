import styles from './chip.module.css'

const ChipAndDeletion = (props) => {
  const { id, label, onDeletion } = props

  const handleClick = (e) => {
    e.stopPropagation()
    onDeletion()
  }

  return (
    <div 
      data-type='chip' 
      data-id={id} 
      className={[styles.chip, styles.chip_grammar, 'input-origin'].join(' ')}
    >
      {label}
      <div className={styles.deletion} onClick={handleClick}>X</div>
    </div>
  )
}

export default ChipAndDeletion