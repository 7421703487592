import styled from 'styled-components'
import { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { actionCreators as postActions } from '../../redux/modules/post'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  Modal,
} from '@mui/material'

const PostModal = ({ id, modal, closeModal }) => {
  const dispatch = useDispatch()
  const is_last = useSelector((state) => state.post.is_last)
  const post_message = useSelector((state) => state.post.message)
  const alert_send = useSelector((state) => state.post.alert_send)
  const post_title_content = useSelector((state) => state.post.post_title_content)

  const scrollRef = useRef()
  const textareaRef = useRef(null)
  const [ postId, setPostId ] = useState(id)
  const [ count, setCount ] = useState(1)
  const [ target, setTarget ] = useState(null)

  const handleSend = (e) => {
    e.preventDefault()
    const a = textareaRef.current.value.replaceAll("\r\n", "")
    console.log(a)
    if (a === '') return
    const data = textareaRef.current.value.replace(/(?:\r\n|\r|\n)/g, '<br/>')
    dispatch(postActions.postSendMessageAPI(postId, data))
    let text = document.getElementById('textareaId').value.toString().trim()
    if (text !== '') {
      document.getElementById('textareaId').value = ''
    }
    dispatch(postActions.setIsLast(false))
    setCount(2)
  }

  console.log(count)
  const dispatchGETmessageAPI = useCallback(() => {
    if (!postId) return
    dispatch(postActions.getMessageAPI(postId, count))
    setCount(count + 1)
  }, [count, postId])

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }

  useEffect(() => {
    if (alert_send) {
      scrollToBottom()
      dispatch(postActions.setAlertSend(false))
    }
  }, [alert_send])

  useEffect(() => {
    dispatch(postActions.getPostContentAPI(id))
    dispatchGETmessageAPI()

    // setText(document.getElementById('textareaId'))

    return () => {
      setCount(1)
      setPostId(null)
      dispatch(postActions.setIsLast(false))
      dispatch(postActions.setPostMessage([]))
    }
  }, [])


  const onIntersect = async ([entry], observer) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target)
      dispatchGETmessageAPI()
      observer.observe(entry.target)
    }
  }

  useEffect(() => {
    let observer
    if (target && !is_last) {
      observer = new IntersectionObserver(onIntersect, {threshold: 0.4})
      observer.observe(target)
    }
    return () => observer && observer.disconnect()
  }, [target, count, is_last])

  // esc - 모달 닫기
  document.onkeydown = (e) => {
    if (e.keyCode === 27) {
      closeModal()
    }
  }

  return post_title_content?.name && (
    <Modal
      open={modal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      sx={{ outline: 0}}
    >
      <ModalImgWrap>
        <div className='container'>
          <div className='exit_icon' onClick={closeModal}>
            <CloseRoundedIcon />
          </div>
          <div className='post'>
            <div className='post__title_wrap'>
              <div className='post__title'>{post_title_content.title}</div>
            </div>
            <div className='post__content_wrap'>
              {post_title_content.content.replaceAll("<br/>", "\r\n")}
            </div>
          </div>
          <div className='display'>
            <div className='display__title_wrap'>
              <div className='display__title_name'>{post_title_content.name}</div>
              <div className='display__title_question_number'>{post_title_content.question_id}</div>
            </div>
            <div className='display__content_wrap'>
              <form className='display__content_textarea_wrap' onSubmit={e => handleSend(e)}>
                <textarea 
                  id='textareaId'
                  ref={textareaRef} 
                  className='display__content_textarea'
                  onKeyPress={(e) => {
                    if (window.event.keyCode === 13 && !e.shiftKey && e.target.value !== '') {
                      handleSend(e)
                    }
                  }}
                ></textarea>
                <input type='submit' value='전송' className='display__content_button'></input>
              </form>
              <div className='display__content_message_wrap'>
                <div ref={scrollRef}></div>
                {post_message.length > 0 && post_message.map((el, idx) => {
                  const className = el.message_owner === 0 
                    ? 'display__content_message_left' 
                    : 'display__content_message_right'
                  
                  const msg = el.message.replaceAll("<br/>", "\r\n")

                  if (post_message.length - 1 === idx) {
                    return <div key={el.id} className={className}>{msg}</div>
                  }
                  return <div key={el.id} className={className}>{msg}</div>
                })}
                <div ref={setTarget} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  {/* {isLoaded && '로딩중...'} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalImgWrap>
    </Modal>
  )
}
const ModalImgWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  z-index: 9999;
  /* border: 1px solid red; */

  .exit_icon {
    position: absolute;
    top: 0.8rem;
    right: 1rem;
    width: 24px;
    height: 24px;
    color: rgba(0, 0, 0, 0.4);
    /* border: 1px solid red; */
    cursor: pointer;
  }

  .container {
    position: relative;
    max-width: 90vw;
    min-width: 80vw;
    max-height: 90vh;
    min-height: 70vh;
    border-radius: 1rem;
    /* background-color: #f5f4fb; */
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
  .post {
    flex-basis: 35%;
    max-width: 35%;
    min-width: 35%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
  .post__title_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.6rem;
    border-radius: 0.4rem;
    background-color: #eaecee;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .post__title {
    width: 100%;
  }
  .post__content_wrap {
    white-space : pre-wrap;
    flex-grow: 1;
    padding: 0.6rem;
    overflow-y: auto;
    border-radius: 0.4rem;
    background-color: #eaecee;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .display {
    flex-grow: 1;
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: column;
  }
  .display__title_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 0.3rem;
    border-radius: 0.4rem;
  }
  .display__title_name {
    font-size: 1.2rem;
    font-weight: 600;
    height: 1.7rem;
  }
  .display__title_question_number {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.9rem;
    font-weight: 600;
  }
  .display__content_wrap {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    /* width: 100%;
    height: 100%; */

    flex-grow: 1;
    /* padding: 0.6rem 0 0; */
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  }
  .display__content_message_wrap {
    height: 500px;
    flex-grow: 1;
    padding: 0.6rem;
    overflow-y: auto;
    border-radius: 0.4rem;
    background-color: #eaecee;

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .display__content_message_left {
    padding: 0.7rem 1rem;
    max-width: 80%;
    background-color: white;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    white-space : pre-wrap;

    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
  .display__content_message_right {
    padding: 0.7rem 1rem;
    max-width: 80%;
    color: white;
    background-color: #1f5be0;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    white-space : pre-wrap;

    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  .display__content_textarea_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.6rem;
    max-height: 3rem;
    min-height: 2rem;
    border-radius: 0.4rem;
  }
  .display__content_textarea {
    height: 100%;
    flex-grow: 1;
    border: none;
    resize: none;
    outline: none;
    padding: 0.9rem;
    font-size: 1rem;
    border-radius: 0.4rem;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);

    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    :-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
  .display__content_button {
    flex-basis: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #1f5be0;
    border-radius: 0.4rem;
    border: none;
    cursor: pointer;
  }
`

export default PostModal