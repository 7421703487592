import React, { useEffect, useState, useRef } from 'react'
import * as S from '../mypage/MyInfoForm.style'
import Form from '../mypage/forms/Form'
import { useMutation } from "react-query";
import { putEditPW } from '../../api/putAPI'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as userActions } from "../../redux/modules/user"
import { useNavigate } from 'react-router-dom';

const FindPw = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mutate, data, isSuccess, isError, error } = useMutation(putEditPW)
  const CODE = useSelector((state) => state.user.code_pw)
  console.log('code', CODE)

  // 비밀번호 찾기 State

  const [ pwKeyType, setPwKeyType ] = useState('requestPwCode')
  const [ pwWrong, setPwWrong ] = useState(0)  // 0: gray, 1: red, 2: blue - 번호 인증
  const [ rePwWrong, setRePwWrong ] = useState(0)  // 0: gray, 1: red, 2: blue - 비밀번호 확인
  const [ pwState, setPwState ] = useState(0)  // 
  const [ pwCode, setPwCode ] = useState('')

  const pwCodeRef = useRef(0)
  
  const idPWRef = useRef('')
  const namePWRef = useRef('')
  const phonePWRef = useRef('')
  const pwRef = useRef('')
  const rePwRef = useRef('')

  const handlePwCode = (e) => {
    const value = e.target.value
    setPwCode(value)
    if ((value || pwCode) === CODE) {
      setPwWrong(2)
    } else if (value.length > 0) {
      setPwWrong(1)
    } else {
      setPwWrong(0)
    }
  }

  const recallCode = () => {
    // setWrong(1)
    // requestCode()
  }

  const requestPwCode = () => {
    if (idPWRef.current.value &&
      namePWRef.current.value &&
      phonePWRef.current.value
    ) {
      const codeData = {
        phone: phonePWRef.current.value,
        user_name: namePWRef.current.value,
        user_id: idPWRef.current.value,
        type: 'password'
      }
      dispatch(userActions.requestCodeAPI(codeData, 'pw'))
    } else {
      alert('이름과 아이디, 휴대폰번호를 모두 입력해 주세요.')
    }
  }

  const checkPwCode = () => {
    if (
      pwState === 1 &&
      pwCode === CODE &&
      idPWRef.current.value &&
      namePWRef.current.value &&
      phonePWRef.current.value
    ) {
      setPwState(2)
      setPwKeyType('handleFindPW')
    } else if (pwCode !== CODE) {
      alert('인증번호를 다시 입력해 주세요. 잘못된 인증번호입니다.')
    }
  }

  const handleFindPW = () => {
    if (
      pwWrong === 2 &&
      rePwWrong === 2 &&
      pwState === 2 &&
      pwCode === CODE &&
      namePWRef.current.value &&
      idPWRef.current.value &&
      phonePWRef.current.value &&
      pwRef.current.value &&
      rePwRef.current.value
    ) {
      const pwData = {
        user_name: namePWRef.current.value,
        user_id: idPWRef.current.value,
        phone: phonePWRef.current.value,
        new_pw: rePwRef.current.value,
      }
      mutate(pwData)
    } else if (pwCode !== CODE) {
      alert('인증번호를 다시 입력해 주세요. 잘못된 인증번호입니다.')
    } else if (rePwWrong !== 2) {
      alert('비밀번호를 다시 입력해 주세요.')
    }
  }

  const clickPwBtn = () => {
    if (pwState === 0) requestPwCode()
    if (pwState === 1) checkPwCode()
    if (pwState === 2) handleFindPW()
  }

  const handleKeyPress = (e, type) => {
    console.log(type)
    if(e.key === 'Enter') {
      switch(type) {
        case 'requestPwCode':
          requestPwCode()
          break
        case 'checkPwCode':
          checkPwCode()
          break
        case 'handleFindPW':
          handleFindPW()
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (pwCode === '' && CODE) {
      setPwState(1)
      setPwKeyType('checkPwCode')
    }
  }, [CODE])

  useEffect(() => {
    console.log(data) 
    if (isSuccess) {
      alert(`비밀번호 변경이 완료되었습니다.`)
      namePWRef.current.value = ''
      idPWRef.current.value = ''
      phonePWRef.current.value = ''
      pwRef.current.value = ''
      rePwRef.current.value = ''
      pwCodeRef.current.value = ''
      
      setPwState(0)
      setPwCode('')      
      setPwWrong(0)
      setRePwWrong(0)
      setPwKeyType('requestCode')
      
      dispatch(userActions.setCodePW(null))
      navigate('/login')
    } else if (isError) {
      console.log(error && error.response.data.error)
    }
  }, [isSuccess, data])

  const handleRePW = (e, type) => {
    const value = e.target.value
    const rePw = rePwRef.current.value
    const target = type === 'rePw' ? pwRef.current.value : rePwRef.current.value
    if (!rePw) {
      setRePwWrong(0)
    } else if (value === target) {
      setRePwWrong(2)
    } else if (value.length > 0) {
      setRePwWrong(1)
    } else {
      setRePwWrong(0)
    }
  }


  return (
    <Box sx={{ marginTop: '2rem'}}>
      <S.Header>
        <h1>비밀번호 변경</h1>
      </S.Header>
      <S.Forms style={{borderBottom: 'none', padding: '20px 0'}}>
        <Form ref={namePWRef} title={'이름'} type={'text'} value={''} disabled={pwState !== 0} onkeypress={(e) => handleKeyPress(e, pwKeyType)} />
        <Form ref={idPWRef} title={'아이디'} type={'text'} value={''} disabled={pwState !== 0} onkeypress={(e) => handleKeyPress(e, pwKeyType)} />
        <Form ref={phonePWRef} title={'휴대폰번호'} type={'number'} value={''} disabled={pwState !== 0} onkeypress={(e) => handleKeyPress(e, pwKeyType)} />
        
        <Form ref={pwCodeRef} value={''} title={'인증번호'} type={'number'} disabled={pwState !== 1} onkeypress={(e) => handleKeyPress(e, pwKeyType)} onchange={handlePwCode} className={`class${pwWrong}`} />
        <Form ref={pwRef} value={''} title={'새 비밀번호'} type={'password'} disabled={pwState !== 2} onkeypress={(e) => handleKeyPress(e, pwKeyType)} onchange={(e) => handleRePW(e, 'pw')} className={`class0`} />
        <Form ref={rePwRef} value={''} title={'새 비밀번호 확인'} type={'password'} disabled={pwState !== 2} onkeypress={(e) => handleKeyPress(e, pwKeyType)} onchange={(e) => handleRePW(e, 'rePw')} className={`class${rePwWrong}`} />

        <S.Button
          onClick={() => clickPwBtn()}
          style={{margin: '16px 0'}}
        >
          {pwState === 0 && '인증 번호 요청'}
          {pwState === 1 && '번호 인증하기'}
          {pwState === 2 && '비밀번호 변경'}
        </S.Button>
        
      </S.Forms>
    </Box>
  )
}

export default React.memo(FindPw)