import * as S from './DnD.style'
import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { COLUMN_NAMES } from "./constants";
import { tasks } from "./tasks";
import Column from './Column'
import Item from './Item'
import RelatedColumn from './RelatedColumn'
import TrashColumn from './TrashColumn'
import { actionCreators as imageEditorActions } from "../../../../redux/modules/imageEditor";

import { CanvasContextStore } from '../../../CanvasContext'

const DnD = (props) => {
  const { fabricCanvas, setFabricCanvas } = useContext(CanvasContextStore)
  const dispatch = useDispatch()
  const questionData = useSelector((state) => state.imageEditor.question_data)
  const relatedData = useSelector((state) => state.imageEditor.related_data)
  const is_sidebar = useSelector((state) => state.imageEditor.sidebar)
  
  const updateQuestionData = (data) => {
    dispatch(imageEditorActions.update_question_data(data))
  }

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = questionData[dragIndex];

    if (dragItem) {
      const coppiedStateArray = [...questionData];
      const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
      coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
      updateQuestionData(coppiedStateArray)
      console.log(coppiedStateArray)
    }
  };
  
  const returnItemsForColumn = (columnName) => { // 문제 or uuid
    return questionData
      .filter((item) => item.column === columnName)
      .map((item, index) => (
        <Item
          key={item.id}
          index={index}
          id={item.id}
          num={item.num}
          column={item.column}
          related={item.related}
          multiple={item.multiple}
          questionData={questionData}
          moveCardHandler={moveCardHandler}
          updateQuestionData={updateQuestionData}
        />
      ));
  };
  
  // 연관 지문 - 컬럼 추가
  const AddRelatedColumn = () => {
    const uuid = uuidv4()
    const numList = relatedData.length === 0 ? null : relatedData.map(item => {
      return item.name
    })
    const sortedNumList = numList && numList.sort(function(a, b) {
      return a - b;
    })
    const newNumState = !numList ? null : sortedNumList.map((num, idx) => {
      if (num !== idx + 1) return idx + 1
    }).filter(el => el !== undefined)
    const newNum = newNumState === null ? 1 : (newNumState.length === 0 ? Number(sortedNumList.slice(-1)) + 1 : newNumState[0])
    const data = [...relatedData, { id: uuid, name: newNum }]
    data.sort((a, b) => a.name - b.name)
    dispatch(imageEditorActions.update_related_data(data))
  }

  // 문제 - 컬럼 추가
  const AddQuestionColumn = () => {
    const uuid = uuidv4()
    const numList = questionData.length === 0 ? null : questionData.map(item => {
      return item.num
    })
    const sortedNumList = numList && numList.sort(function(a, b) {
      return a - b;
    })
    const newNumState = !numList ? null : sortedNumList.map((num, idx) => {
      if (num !== idx + 1) return idx + 1
    }).filter(el => el !== undefined)
    const newNum = newNumState === null ? 1 : (newNumState.length === 0 ? Number(sortedNumList.slice(-1)) + 1 : newNumState[0])
    const data = [...questionData, {id: uuid, num: newNum, related: null, column: '문제', multiple: true}]
    data.sort((a, b) => a.num - b.num)
    dispatch(imageEditorActions.update_question_data(data))
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }

  useEffect(() => {
    const targetDeleteData = questionData.filter(el => el.column === 'trash')
    const nemQuestionData = questionData.filter(el => el.column !== 'trash')
    if (questionData.length !== nemQuestionData.length) {
      dispatch(imageEditorActions.update_question_data(nemQuestionData))
    }
  }, [questionData])

  return (
      <S.Container open={is_sidebar}>
        <DndProvider backend={HTML5Backend}>
          <TrashColumn id={'trash'} />

          <Column id={'문제'} AddQuestionColumn={AddQuestionColumn} >
            {returnItemsForColumn('문제')}
          </Column>

          {/* related_column */}
          <RelatedColumn AddRelatedColumn={AddRelatedColumn} >
          {relatedData.map(el => {
            return (
              <Column key={el.id} id={el.id} name={el.name} >
                {returnItemsForColumn(el.id)}
              </Column>
            )
          })}
            
          </RelatedColumn>
        </DndProvider>
      </S.Container>
  )
}

export default DnD