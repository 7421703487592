import { Plugin } from 'ckeditor5/src/core';

import BlockEditing from './block/blockediting';
import BlockUI from './block/blockui';

export default class Block extends Plugin {
	static get requires() {
		return [BlockEditing, BlockUI];
	}

	static get pluginName() {
		return 'Block';
	}
}
