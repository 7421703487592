"use client"

import { useRef, useEffect, forwardRef } from 'react';
import styles from 'styles/modal.module.scss';

type DialogProps = {
  component: React.ReactNode
}

const Dialog = forwardRef<HTMLDialogElement, DialogProps>((function Dialog(props, ref) {

  return (
    <dialog ref={ref} className={styles.dialog}>
      {props.component}
    </dialog>
  );
}));

type Options = {
  onClickBackdrop?: ()=>void
}

const useModal = (
  component: React.ReactNode,
  { onClickBackdrop }: Options = {}
) => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  // forwardRef로 컴포넌트 분리 필요
  const create = () => <Dialog ref={dialogRef} component={component} />;

  const showModal = () => {
    const inputElement = dialogRef.current?.getElementsByTagName('input')[0]
    dialogRef.current?.showModal();
    inputElement?.focus()
  };

  const close = () => {
    dialogRef.current?.close();
    onClickBackdrop && onClickBackdrop();
  };
  
  useEffect(()=>{
    dialogRef.current?.addEventListener("click", (e: any) => {
      if (!!(dialogRef.current === e.target)) close();
      // https://pay-it-forward-trevor1107.tistory.com/16
    })
  }, [])

  return { Dialog: create(), showModal, close };
};

export default useModal;
