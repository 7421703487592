import Layout from './Layout'
import CanvasContext from '../../CanvasContext'

const EditorImageCrop = () => {
  return (
    <CanvasContext>
      <Layout></Layout>
    </CanvasContext>
  )
}

export default EditorImageCrop