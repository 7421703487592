import styled from 'styled-components'
import {
  Grid,
  Paper,
} from '@mui/material'
import NewLabelerList from '../../../components/admin/labeler/NewLabelerList'
import OldLabelerList from '../../../components/admin/labeler/OldLabelerList'

const LabelerPage = (props) => {
  
  return (
    <Grid container spacing={2} direction={"row"} >
      
      <Grid item xs={12} sm={6} md={6} lg={6} >
        <Item id='item' hidden={'hidden'} >
          <Title>신규 가입자 (승인 대기)</Title>
          <NewLabelerList />
        </Item>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} >
        <Item id='itemk' hidden={'hidden'}>
          <Title>기존 라벨러</Title>
          <OldLabelerList />
        </Item>
      </Grid>

    </Grid>
  )
}

const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  :-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 6px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default LabelerPage