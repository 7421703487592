import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
} from '@mui/material'
import { useQuery, useMutation } from 'react-query'
import { useState, useRef, useCallback } from 'react'
import Button from '../../../elements/button/Button'
import BackToList from '../../../elements/button/BackToList'
import LabelerInfo from '../../../components/admin/labeler/LabelerInfo'
import { getLabelerInfoAPI } from '../../../api/getAPI' 
import { postLabelerInfoAPI } from '../../../api/putAPI' 
import { actionCreators as labelerActions } from "../../../redux/modules/labeler";

const ManagementPage = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storeRef = useRef()
  const params = useParams()
  const labeler_id = Number(params['id'])
  const labeler_info = useSelector((state) => state.labeler.labeler_info)

  const { mutate } = useMutation(postLabelerInfoAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'success') {
        alert('라벨러 정보가 수정되었습니다.')
        navigate('/labeler/management')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const handleGetResult = () => {
    return storeRef.current.handleSubmit()
  }

  const GoBackToList = () => {
    navigate('/labeler/management')
  }

  const handleStore = () => {
    const result = handleGetResult()
    console.log('result', result)

    if (!result) {
      alert('모든 항목을 입력해 주세요.')
      return
    } 

    // if (result.admin === data.data.admin && 
    //   result.group === data.data.group &&
    //   result.check === (data.data.check === 0 ? false : true) &&
    //   result.description === (data.data.description ? data.data.description : '')
    // ) return alert('변경된 내용이 없습니다.')

    let res = {
      id: labeler_id,
    }

    res = {...res, ...result}
    console.log(res)

    mutate(res)
  }

  useEffect(() => {
    dispatch(labelerActions.getLabelerInfoAPI(labeler_id))

    return () => {
      dispatch(labelerActions.getLabelerInfo({}))
    }
  }, [])

  return labeler_info?.id && (
    <Grid container spacing={2} direction={"row"} >
    
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item id='item' hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>라벨러 정보</div>
              <BtnBox02>
                <BackToList onclick={GoBackToList}>라벨러 리스트</BackToList>
                <Button onclick={handleStore}>저장하기</Button>
              </BtnBox02>
              
            </BtnBox>
          </Title>
          <LabelerInfo ref={storeRef} data={labeler_info?.id && labeler_info} />
        </Item>
      </Grid>
    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default React.memo(ManagementPage)