/**
 * @module basic-styles/code/codeediting
 */

import { Plugin } from 'ckeditor5/src/core';

import AttributeCommand from '@ckeditor/ckeditor5-basic-styles/src/attributecommand';
import InsertTextBoxCommand from './textboxcommand';

const TEXTBOX = 'textbox';
const HIGHLIGHT_CLASS = 'ck-block_selected';

/**
 * @extends module:core/plugin~Plugin
 */
export default class TextBoxEditing extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'TextBoxEditing';
	}

	/**
	 * @inheritDoc
	 */
	init() {
		const editor = this.editor;

		// Allow code attribute on text nodes.

		// editor.model.schema.extend('$text', { allowAttributes: TEXTBOX });
		// console.log(editor.model.schema);
		// editor.model.schema.extend('paragragh', {
		// 	allowChildren: TEXTBOX
		//  });

		editor.model.schema.register(TEXTBOX, {
			allowWhere: ['$text'],
			isInline: true,
			isObject: true,
			allowChildren: '$text',
		});
		editor.model.schema.setAttributeProperties(TEXTBOX, {
			isFormatting: true,
			copyOnEnter: true,
		});

		editor.conversion.for('upcast').elementToElement({
			view: {
				name: 'span',
				classes: 'pr-text_box',
			},
			model: TEXTBOX,
		});
		editor.conversion.for('downcast').elementToElement({
			model: TEXTBOX,
			view: {
				name: 'span',
				classes: 'pr-text_box',
			},
		});
		/*
			// attribute로 동작할 때, 단 수식이 입력되지 않음
			editor.model.schema.register('textbox', {
				inheritAllForm: '$block',
				isInline: true,
				isObject: true,
			});

			editor.conversion.for('upcast').elementToElement({
				view: {
					name: 'span',
					classes: 'pr-text_box',
				},
				model: TEXTBOX,
			});
			editor.conversion.for('downcast').elementToElement({
				model: TEXTBOX,
				view: {
					name: 'span',
					classes: 'pr-text_box',
				},
			});

			// Create bold command.
			editor.commands.add(TEXTBOX, new AttributeCommand(editor, TEXTBOX));
			*/
		editor.commands.add('insertTextBox', new InsertTextBoxCommand(editor));
		// Set the Ctrl+B keystroke.
		editor.keystrokes.set('CTRL+E', 'insertTextBox');
	}
}
