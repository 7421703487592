import * as S from './Input.style'
import { forwardRef } from 'react'

const Input = forwardRef(function Input(props, ref) {
  const { type, name, placeholder, width, value, onChange, flex, disabled } = props
  
  return (
    <S.Wrap>
      <S.MyInput
        ref={ref}
        name={name}
        type={type}
        placeholder={placeholder}
        width={width}
        value={value}
        onChange={onChange}
        flex={flex}
        disabled={disabled}
      />
    </S.Wrap>
  )
})

export default Input
