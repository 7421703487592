import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as S from './CreationForm.style'
import Input from '../../../elements/Input'
import Select from '../../../elements/SelectBox'

const CreationForm = forwardRef(function CreationForm(props, ref) {
  const { isCreationPage, projectData } = props // 생성 페이지일 경우 ? false : true
  const [ qb_PDF_id, setQbPDFid ] = useState(null)
  const [ books_PDF_id, setBooksPDFid ] = useState(null)
  const [ thai_PDF_id, setThaiPDFid ] = useState(null)

  const handleQbPDFid = (id) => {
    setQbPDFid(id)
    setBooksPDFid(null)
    setThaiPDFid(null)
  }
  const handleBooksPDFid = (id) => {
    setQbPDFid(null)
    setBooksPDFid(id)
    setThaiPDFid(null)
  }
  const handleThaiPDFid = (id) => {
    setQbPDFid(null)
    setBooksPDFid(null)
    setThaiPDFid(id)
  }

  console.log(projectData)
  const date = new Date()
  const [ startEditorDate, setStartEditorDate ] = useState(projectData ? projectData.labeling_term.start : date)
  const [ endEditorDate, setEndEditorDate ] = useState(projectData ? projectData.labeling_term.end : date)
  const [ startMidUnitDate, setStartMidUnitDate ] = useState( projectData ? projectData.review_term.start : date)
  const [ endMidUnitDate, setEndMidUnitDate ] = useState(projectData? projectData.review_term.end : date)

  const titleRef = useRef('')
  const descRef = useRef('')

  useEffect(() => {
    if (projectData) {
      titleRef.current.value = projectData.title
      descRef.current.value = projectData.description
      setStartEditorDate(projectData.labeling_term.start)
      setEndEditorDate(projectData.labeling_term.end)
      setStartMidUnitDate(projectData.review_term.start)
      setEndMidUnitDate(projectData.review_term.end)
    }
  }, [projectData])

  function dateFormat(date) {
    let month = date.getMonth() + 1
    let day = date.getDate()
    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day

    return date.getFullYear() + '-' + month + '-' + day
}
  
  const handleSubmit = () => {
    const title = titleRef.current.value
    const desc = descRef.current.value
    const startLabelingDate = typeof startEditorDate === 'string' ? startEditorDate : dateFormat(startEditorDate)
    const endLabelingDate = typeof endEditorDate === 'string' ? endEditorDate : dateFormat(endEditorDate)
    const startReviewDate = typeof startMidUnitDate === 'string' ? startMidUnitDate : dateFormat(startMidUnitDate)
    const endReviewDate = typeof endMidUnitDate === 'string' ? endMidUnitDate : dateFormat(endMidUnitDate)

    if (!title || !desc) return console.log('모든 항목을 입력해주세요.')
    if (!projectData && ((qb_PDF_id && books_PDF_id && thai_PDF_id) || (!qb_PDF_id && !books_PDF_id && !thai_PDF_id))) return console.log('모든 항목을 입력해주세요.')

    let data = {
      title: title,
      description: desc,
      labeling_term: {
        start: startLabelingDate,
        end: endLabelingDate
      },
      review_term: {
        start: startReviewDate,
        end: endReviewDate
      },
      content: {
        type: qb_PDF_id ? 'qb' : (books_PDF_id ? 'books' : 'thai'),
        id: qb_PDF_id ? qb_PDF_id : (books_PDF_id ? books_PDF_id : thai_PDF_id)
      },
    }
    return data
  }
  
  useImperativeHandle(ref, () => ({ handleSubmit }))
  
  return (
    <S.CardPadding>
      <S.Wrap>
        <S.Grid onSubmit={handleSubmit}>
          <S.Category>프로젝트명</S.Category>
          <Input ref={titleRef} type={'text'} placeholder={'입력하세요.'} />

          <S.Category>1, 3차 기한</S.Category>
          <S.DatePickerWrap>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="시작일"
                value={startEditorDate}
                onChange={(newValue) => {
                  setStartEditorDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="마감일"
                value={endEditorDate}
                onChange={(newValue) => {
                  setEndEditorDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </S.DatePickerWrap>

          <S.Category>2차, 검수 기한</S.Category>
          <S.DatePickerWrap>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="시작일"
                value={startMidUnitDate}
                onChange={(newValue) => {
                  setStartMidUnitDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="마감일"
                value={endMidUnitDate}
                onChange={(newValue) => {
                  setEndMidUnitDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </S.DatePickerWrap>

          <S.Category>설명</S.Category>
          <textarea ref={descRef} name={'desc'} type={'text'} placeholder={'입력하세요'} disabled={projectData} style={{cursor: 'default'}} />

          {isCreationPage && !projectData &&
            <>
              <S.Category>기출 PDF</S.Category>
              <Select type={'qb_pdf'} setPDFid={handleQbPDFid} zeroValue={qb_PDF_id} />

              <S.Category>북스 PDF</S.Category>
              <Select type={'books_pdf'} setPDFid={handleBooksPDFid} zeroValue={books_PDF_id} />

              <S.Category>태국 PDF</S.Category>
              <Select type={'thai_pdf'} setPDFid={handleThaiPDFid} zeroValue={thai_PDF_id} />
            </>
          }
          {!isCreationPage && projectData &&
            <>
              <S.Category>PDF 지정</S.Category>
              <Input value={projectData.content_title} disabled type={'text'} placeholder={'입력하세요.'} />
            </>
          }

        </S.Grid>
      </S.Wrap>
    </S.CardPadding>
  )
})

export default React.memo(CreationForm)