import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useMemo, memo, useCallback, createRef, createElement } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router'
import styled from 'styled-components'
import {
  Grid,
  Paper
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Button from '../../../elements/button/Button'

import TableIcon from '../../../elements/table/TableIcon'

import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useMutation } from 'react-query'
import { putNoticeAPI } from '../../../api/putAPI' 
import { postNoticeAPI, postImageUploadAPI, deleteNoticeAPI } from '../../../api/postAPI' 
import { actionCreators as dashboardActions } from "../../../redux/modules/dashboard"
import ImageResize from 'quill-image-resize-module-react';
// import { ImageResize } from 'quill-image-resize-module-withfix';

Quill.register('modules/imageResize', ImageResize);
let BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];
const WHITE_STYLE = ['margin', 'display', 'float'];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    const attributes = ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
    return attributes
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        if (name === 'style') {
          value = this.sanitize_style(value);
        }
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
  sanitize_style(style) {
    let style_arr = style.split(";")
    let allow_style = "";
    style_arr.forEach((v, i) => {
      if (WHITE_STYLE.indexOf(v.trim().split(":")[0]) !== -1) {
        allow_style += v + ";"
      }
    })
    return allow_style;
  }
}
Quill.register(ImageFormat, true)


// let Block = Quill.import("blots/block")
// Block.tagName = "DIV"
// Quill.register(Block, true)


let Inline = Quill.import('blots/inline');
class SpanBlock extends Inline{     
  static create(value){
    let node = super.create();
    node.setAttribute('class','spanblock');
    return node;    
  }   
}
SpanBlock.blotName = 'spanblock';
SpanBlock.tagName = 'q';
Quill.register(SpanBlock);


const NoticeRegistration = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {id: params} = useParams()
  const notice_id = Number(params)
  const notice_data = useSelector((state) => state.dashboard.notice_detail)

  // const quill = new Quill()
  const quillRef = useRef();
  const titleRef = useRef();
  const [ value, setValue ] = useState(notice_data?.content || '');
  const [ imageIdList, setImageIdList ] = useState(notice_data?.image || [])

  const handleSetValue = useCallback((e) => {
    setValue(e)
  }, [value, notice_data])

  const handleSetImageIdList = useCallback((e) => {
    setImageIdList(e)
  }, [imageIdList, notice_data])

  const [ active, setActive ] = useState(notice_data?.check === 1 ? true : false)

  const handleSetActive = useCallback(() => {
    setActive(!active)
  }, [active, notice_data])

  const changeImageWidth = (value, quill_width) => {
    return value.replace(/<img([\w\W]+?)(\/)?>/g, (_all, arg1) => {
      let flag = false
      const newImg = arg1.replace(/width="(\d+)"/, (all, width) => {
        flag = true
        let percentage = (width || quill_width) / quill_width * 100
        return `width="${Math.floor(percentage > 100 ? 100 : percentage)}%"`
      })
      return `<img ${newImg}${flag ? "" : ' width="100%"'}/>`
    })
  }

  // 이미지 첨부 시 URL 받아오기
  const { mutate: uploadImage } = useMutation(postImageUploadAPI, {
    onSuccess: data => {
      setImageIdList([...imageIdList, data])
      const IMG_URL = data.image_url;
      const editor = quillRef.current.getEditor()  // 에디터 객체 가져오기
      const range = editor.getSelection()  // 2. 현재 에디터 커서 위치값을 가져온다
      editor.insertEmbed(range.index, 'image', IMG_URL)  // 가져온 위치에 이미지를 삽입한다
      editor.setSelection(range.index + 1)  // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동

    },
    onError: error => {
      console.log(error)
      alert('이미지 업로드 실패했어요..')
      // const editor = quillRef.current.getEditor()  // 에디터 객체 가져오기
      // const range = editor.getSelection()
      // editor.deleteText(range.index, 1)
    }
  })

  // 공지사항 작성하기(저장) - useMutation
  const { mutate: createNotice } = useMutation(postNoticeAPI, {
    onSuccess: data => {
      // console.log(data)
      if (data.message === 'success created') {
        alert('공지사항 등록이 완료되었습니다.')
        navigate('/notice')
      }
    },
    onError: error => {
      console.log(error)
    }
  })
  // 공지사항 작성하기(저장)
  const handleCreate = () => {
    if (!quillRef.current.lastDeltaChangeSet &&
      !titleRef.current.value
    ) return alert('변경된 내용이 없습니다.')

    const quill_width = quillRef.current.editingArea.firstChild.clientWidth
      
    const newValue = changeImageWidth(value, quill_width)

    const imageIdList = handleSelectImageID()
    const data = {
      title: titleRef.current.value,
      content: newValue,
      image: imageIdList,
      check: active
    }
    // console.log(data)
    createNotice(data)
  }

  const handleSelectImageID = () => {
    if (!quillRef.current.lastDeltaChangeSet || imageIdList.length === 0) return notice_data.image || []
    const imageUrlList = quillRef.current.lastDeltaChangeSet.ops.filter(el => el.insert?.image).map(el => el.insert.image)
    const existedImageIDList = imageIdList.filter(el => {
      const img = el.image_url 
      const is_existed = imageUrlList.includes(img)
      if (is_existed) return img
    }).map(el => el.id)

    return existedImageIDList
  }

  // 공지사항 수정 - useMutation
  const { mutate: editNotice } = useMutation(putNoticeAPI, {
    onSuccess: data => {
      // console.log(data)
      if(data.message === 'update success') {
        alert('수정이 완료되었습니다.')
        navigate('/notice')
        // dispatch(dashboardActions.getNoticeDetailAPI(notice_id))
      }
    },
    onError: error => {
      console.log(error)
    }
  })
  // 공지사항 수정
  const handleEdit = () => {
    if (!quillRef.current.lastDeltaChangeSet &&
      titleRef.current.value === notice_data.title &&
      active === (notice_data.check === 1 ? true : false)
    ) return alert('변경된 내용이 없습니다.')

    const quill_width = quillRef.current.editingArea.firstChild.clientWidth
      
    const newValue = changeImageWidth(value, quill_width)
    // console.log(value)
    // console.log(newValue)

    // return

    const imageIdList = handleSelectImageID()
    const data = {
      id: notice_id,
      title: titleRef.current.value,
      content: newValue,
      image: imageIdList,
      check: active
    }
    // console.log(data)
    editNotice(data)
  }
  // 공지사항 삭제 - useMutation
  const { mutate: deleteNotice } = useMutation(deleteNoticeAPI, {
    onSuccess: data => {
      // console.log(data)
      if (data.message === 'delete success') {
        alert('삭제가 완료되었습니다.')
        navigate('/notice')
      }
    },
    onError: error => {
      console.log(error)
    }
  })
  // 공지사항 삭제
  const handleDeleteNotice = () => {
    if (window.confirm('정녕 삭제하시겠습니까?') === false) return
    deleteNotice({id: notice_id})
  }

  useEffect(() => {
    if(location.pathname.includes('/notice/modification')) {
      dispatch(dashboardActions.getNoticeDetailAPI(notice_id))
    }

    const handleScroll = () => {
      console.log('scroll')
    }
    
    // const quillClass = document.getElementsByClassName('ql-editor')
    // const quillId = document.getElementById('quill_id')
    console.log(quillRef)
    // console.log(quillRef.current.props)
    // this.quill.root.addEventListener('scroll', this.hide, false);
    // quillRef.current.props.addEventListener('scroll', handleScroll, false)

    return () => {
      dispatch(dashboardActions.setNoticeDetail({}))
    }
  }, [])

  useEffect(() => {
    if (notice_data.content) {
      handleSetValue(notice_data.content)
      handleSetImageIdList(notice_data.image)
      handleSetActive(notice_data.check === 0 ? false : true)
    }
  }, [notice_data])

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.

    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async (e) => {
      const [file] = e.target.files
      // const [file] = input && input.files
      const formData = new FormData()
      formData.append('image', file)
      uploadImage(formData)
    }
  };

  const tableHandler = () => {}

  const handleTableInsert = (e) => {
    // e.stopPropagation();
    const editor = quillRef.current.getEditor()
    let tableModule = editor.getModule('better-table')
    tableModule.insertTable(1,1)
  }

  // Quill 에디터에서 사용하고싶은 모듈들을 설정한다.
  // useMemo를 사용해 modules를 만들지 않는다면 매 렌더링 마다 modules가 다시 생성된다.
  // 그렇게 되면 addrange() the given range isn't in document 에러가 발생한다.
  // -> 에디터 내에 글이 쓰여지는 위치를 찾지 못하는듯
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: 
        // '#toolbar',
        [
          ['image', 'link'],
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          // ['spanblock', 'table'],
          // ['clean'],
        ],
        handlers: {
          image: imageHandler,
          // table: tableHandler,
          // insertStar: insertStar,
          // insertTable: handleTableInsert,
        },

      },
      // imageResize: {
      //   parchment: Quill.import('parchment'),
      //   modules: ['Resize', 'DisplaySize', 'Toolbar']
      // },
      // table: false, // disable table module
      // 'better-table': {
      //   operationMenu: {
      //     items: {
      //       unmergeCells: {
      //         text: 'Another unmerge cells name'
      //       }
      //     },
      //     color: {
      //       colors: ['green', 'red', 'yellow', 'blue', 'white'],
      //       text: 'Background Colors:'
      //     }
      //   }
      // },
      // keyboard: {
      //   bindings: QuillBetterTable.keyboardBindings
      // }
    };
  }, []);

  // 위에서 설정한 모듈들 foramts을 설정한다
  const formats = [
    'image', 'link',
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    // 'spanblock', 'table',
    'width', 'height', 'style',
  ]

  function insertStar() {
    const editor = quillRef.current.getEditor()
    const {index, length} = editor.getSelection()
    const text = editor.getText()
    // editor.firstChild.class = 'ql_editor ql_box'
    // const html = editor.getHTML()
    // console.log(editor.firstChild.innerHTML)
    
    // console.log(text, index, length)
    // editor.insertEmbed(index,"hr","null")
    // editor.insertEmbed(index, length, 'strong')
    // console.log(text)
    // editor.insertText(index, '<div>')
    // editor.insertText(index + 5 + length, '</div>')
    // editor.setSelection(index + 5 + length + 1)
  }
  const CustomButton = () => <span className="octicon octicon-star" />;

  const handleAddBlock = () => {
    const spanBlockButton = document.querySelector('.ql-spanblock');

    spanBlockButton.addEventListener('click', function() {
      const editor = quillRef.current.getEditor()
      const {index, length} = editor.getSelection()
      if(index){
        editor.formatText(index,'spanblock');
      }else{
        console.log('it it invalid');
      }

    })
  }


  return (
    <Containers>
      <Item>
        <InputBox>
          <Label>제목</Label>
          <Title ref={titleRef} defaultValue={notice_data.title} type='text' id="title" />
        </InputBox>

        <QuillWrap className='text-editor'>
          <ReactQuill
            id='quill_id'
            // ref={quillRef}
            ref={(element) => {
              if (element !== null) {
                quillRef.current = element;
              }
            }}
            theme='snow'
            value={value}
            onChange={(e) => handleSetValue(e)}
            modules={modules}
            formats={formats}
            placeholder='입력하세요.'
            style={{height: '90%'}}
          />
        </QuillWrap>

        <ButtonBox>
          <StarBox onClick={handleSetActive}>
            {active &&
              <StarIcon sx={{color: '#0B52D4'}} />
            }
            {!active &&
              <StarBorderIcon />
            }
          </StarBox>
          {location.pathname.includes('/notice/registration') &&
            <Button padding={'0 20px'} onclick={handleCreate} >저장하기</Button>
          }
          {location.pathname.includes('/notice/modification') &&
            <>
              <Button onclick={handleEdit} >수정하기</Button>
              <Button del bgColor={'white'} color={'#111'} onclick={handleDeleteNotice} >삭제하기</Button>
            </>
          }
        </ButtonBox>
      </Item>
    </Containers>
  )
}
const StarBox = styled.div`
  width: fit-content;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  /* background-color: rgba(0, 0, 0, 0.2); */
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
`
const ButtonBox = styled.div`
  /* border: 1px solid red; */
  position: absolute;
  padding: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
`
const QuillWrap = styled.div`
  height: 550px;

  @media (min-width: 1600px) {
  height: 740px;
  }

  .ql-toolbar {
    background: #eaecec;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    border-bottom: none;
  }
  .ql_box {
    display: block;
    color: red;
  }

  .ql-spanblock:after {
    content: "<div/>";
  }

  .spanblock {
      background-color: #F8F8F8;
      border: 1px solid #CCC;
      line-height: 19px;
      padding: 2px 4px;
      border-radius: 3px;
      margin: 1px 0;
      width: fit-content;
      height: fit-content;
      display: inline-block;
  }
`
const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Title = styled.input` 
  width: 100%;
  height: 2rem;
  padding: 2px 16px 0;
  border: none;

  :focus {
    outline: none;
  }

`
const Label = styled.div`
  width: 50px;
  height: fit-content;
  padding: 0 6px;
  color: #111;
  font-size: 0.9rem;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
`
const Containers = styled(Grid)`
  width: 100%;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: ${props => props.direction};
  gap: 20px;
  background: transparent;
  min-height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  /* border: 1px solid red; */
`
const Item = styled(Paper)`
  position: relative;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  overflow-y: hidden;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  :-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

export default NoticeRegistration