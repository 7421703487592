import styled from 'styled-components'
import ItemBox from './ItemBox'

interface SelectBoxProps {
  children: JSX.Element
  label: string
  disabled?: boolean
}

const SelectBox = ({children, label='', disabled=false}: SelectBoxProps) => {
  return (
    <Container>
      <Select>
        <LabelBox>{label}</LabelBox>
        <ItemBox>
          {children}
        </ItemBox>
        {/* {disabled && <Disabled />} */}
      </Select>
    </Container>
  )
}

const Container = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 0.4rem;
  display: grid;
  column-gap: 0.4rem;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
`
const Select = styled.div`
  position: relative;
  color: #333;
  font-weight: 500;
  border-radius: 4px;
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
`
const LabelBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const Disabled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
`

export default SelectBox