import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import LayoutModal from '../../../elements/modal/LayoutModal'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Button from '../../../elements/button/Button'

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;

const LabelerList = ({ labelerList, handleCount, handleMatchingPdf }) => {
  const navigate = useNavigate()
  const [ page, setPage ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ selected, setSelected ] = useState([])
  const [ totalCount, setTotalCount ] = useState(0)

  const total_count = labelerList.length > 0 
    ? labelerList.map(el => el.count).reduce((a, b) => (a + b))
    : 0

  // handleCount(total_count)
    
  const emptyRows = labelerList.length > 0 &&
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - labelerList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const dataList = labelerList.length > 0 && rowsPerPage > 0
    ? (labelerList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    : labelerList

  const handleSelectOne = (id) => {
    if (selected[0] === id) {
      setSelected([])
    } else {
      setSelected([id])
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const handleSubmit = () => {
    handleMatchingPdf(selected)
    // setSelected([])
  }

  useEffect(() => {
    handleCount(total_count)

    return () => {
      setPage(0)
      setRowsPerPage(10)
      setSelected([])
      setTotalCount(0)
    }
  }, [labelerList])

  return (
    <Wrap>
      <TableContainer component={Paper} sx={{ height: '100%' }} >        
        <Table sx={{ minWidth: 80, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align="center" sx={{maxWidth: '20px'}}>No.</Cell>
              <Cell align="center">라벨러</Cell>
              <Cell align="center">
                <Button width={'100%'} height={'40px'} onclick={handleSubmit}>PDF 부여</Button>
              </Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList && dataList.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              const isItemSelected = isSelected(row.labeler_id)
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <Row
                  key={row.labeler_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { cursor: 'pointer' } }}
                  onClick={() => handleSelectOne(row.labeler_id)}
                >
                  <Cell align="center">{no}</Cell>
                  <Cell align="center">{row.user_name} ({row.count})</Cell>
                  <Cell align="center">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </Cell>
                </Row>
              )
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{overflow: 'hidden'}}
        rowsPerPageOptions={[50]}
        colSpan={9}
        count={labelerList ? labelerList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Wrap>
  );
}

export default LabelerList