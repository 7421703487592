import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import {
  Grid,
  Paper,
} from '@mui/material'
import Button from '../../elements/button/Button'
import NoticeList from '../../components/dashboard/NoticeList'
import ProgressList from '../../components/dashboard/ProgressList'
import { useQuery } from 'react-query'
import { getLabelingListAPI } from '../../api/getAPI'

const DashboardPage = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ selected, setSelected ] = useState(0)
  const handleTab = (data) => {
    if (data === 1 && selected === 0) {
      setSelected(1)
    } else if (data === 0 && selected === 1) {
      setSelected(0)
    }
  }

  const [ nowList, setNowList ] = useState([])
  const [ waitingList, setWaitingList ] = useState([])
  const handleSetNowList = useCallback((arg) => {
    setNowList(arg)
  }, [nowList])
  const handleSetWaitingList = useCallback((arg) => {
    setWaitingList(arg)
  }, [waitingList])

  const labelingList = useQuery('labelingList', getLabelingListAPI, {
    retry: false,
    onSuccess: data => {
      console.log(data)
      const now = data.data.filter(el => el.now === 1)
      const waiting = data.data.filter(el => el.now === 0)
      handleSetNowList(now)
      handleSetWaitingList(waiting)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    return () => {
      handleSetNowList([])
      handleSetWaitingList([])
    }
  }, [])

  return (
    <Grid container spacing={2} direction={"row"} >
      
      <Grid item xs={12} sm={7} md={7} lg={7} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div>공지사항</div>
            </BtnBox>
          </Title>
          <NoticeList />
        </Item>
      </Grid>
      <Grid item xs={12} sm={5} md={5} lg={5} >
        <Item hidden={'hidden'}>
          <Title>
            <TabBox>
              <Tab selected={selected === 0} onClick={() => handleTab(0)}>진행 중인 라벨링</Tab>
              <Tab selected={selected === 1} onClick={() => handleTab(1)}>대기 중인 라벨링</Tab>
            </TabBox>
          </Title>
          {selected === 0 &&
            <ProgressList dataList={nowList} />
          }
          {selected === 1 &&
            <ProgressList dataList={waitingList} />
          }
        </Item>
      </Grid>

    </Grid>
  )
}
const Tab = styled.div`
  color: ${(props) => (props.selected ? '#1976d2' : 'rgba(0, 0, 0, 0.3)' )};
  font-weight: ${(props) => (props.selected ? '20px' : '1rem' )};
  cursor: pointer;
`
const TabBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  :-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 6px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default DashboardPage