import styled from 'styled-components'
import Channel from './channel/Channel'
import Item from './channel/Item'
import Display from './display/Display'

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  z-index: 9999;
  /* border: 1px solid red; */

  .container {
    max-width: 80vw;
    min-width: 60vw;
    max-height: 80vh;
    min-height: 60vh;
    border-radius: 1rem;
    background-color: #f8f7fc;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
`

const Container = (props) => {
  return (
    <Wrap className='grid'>
      <div className='container'>
        <Channel>
          <Item />
          <Item />
        </Channel>
        <Display />
      </div>
    </Wrap>
  )
}

export default Container