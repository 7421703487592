import { useState, useEffect } from 'react'
import { useNavigate, createSearchParams, useSearchParams, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getAPI } from '../../../api'

import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'
import Button from '../../../elements/button/Button'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.color};
`
const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any
  service: string | null
  openModal?: (projectId: number, role: number, labeler: any)=>void
  filter: number
  sub_section_id: number
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], service, openModal=()=>{}, filter=0, sub_section_id=0}: tableBodyProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const _tab = location.pathname.split('/')[2] // 'management' or 'pdf'

  const goToDetail = (id: number, name?: string, service?: string) => {
    localStorage.setItem("upload-creation-name", name)

    let pathname: string = ''
    if (service === 'books') pathname = `modification/${id}`
    if (service === 'thai') pathname = `modification/thai/${id}`
    if (service === 'qb') pathname = `modification/qb/${id}`
    navigate({
      pathname: pathname,
      search: `?${createSearchParams({service: service})}`
    })
  }

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        return (
          <Row
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="center">{row.name}</Cell>
            <Cell align="center">{row.year}</Cell>
            {service === 'qb' &&
              <>
                <Cell align="center">{row.grade}</Cell>
                <Cell align="center">{row.subsubject}</Cell>
              </>
            }
            {(service === 'books' || service === 'qb') && <Cell align="center">{row.publisher}</Cell>}
            <Cell align="center">
              {row.sub_section.is_linked ? <Circle color={'#66CC33'} /> 
                : (row.sub_section.is_linked === null ? '-' : <Circle color={'red'} />)
              }
            </Cell>

            {_tab === 'management' &&
              <Cell>
                <Btn onClick={()=>goToDetail(row.id, row.name, row.service)}>수정하기</Btn>
              </Cell>
            }
            {_tab === 'pdf' &&
              <>
                <Cell>
                  {row.sub_section.is_matched === 0 && <Circle color={'#ddd'} />}
                  {row.sub_section.is_matched === 1 && <Circle color={'#66CC33'} />}
                  {row.sub_section.is_matched === 2 && <Circle color={'orange'} />}
                  {row.sub_section.is_matched === 3 && <Circle color={'red'} />}
                </Cell>
                
                <Cell>
                  {row.sub_section.is_matched === 2 &&
                    <div style={{fontSize: '13px'}}>uploading..</div>
                  }
                  {row.sub_section.is_matched !== 2 &&
                    <Btn onClick={()=>goToDetail(row.id, row.name, row.service)}>
                      {row.sub_section.is_matched 
                        ? 'pdf 수정'
                        : '업로드'
                      }
                    </Btn>
                  }
                </Cell>
              </>
            }
          </Row>
        )
      })}
    </>
  )
}

let head_list = {
  management: {
    books: ['이름', '연도', '출판사', '중단원 생성/링킹', ''],
    thai: ['이름', '연도', '중단원 생성/링킹', ''],
    qb: ['이름', '연도', '학년', '과목', '출판사', '중단원 생성/링킹', ''],
  },
  pdf: {
    books: ['이름', '연도', '출판사', '중단원 생성/링킹', 'PDF 매칭여부', ''],
    thai: ['이름', '연도', '중단원 생성/링킹', 'PDF 매칭여부', ''],
    qb: ['이름', '연도', '학년', '과목', '출판사', '중단원 생성/링킹', 'PDF 매칭여부', ''],
  },
}

const Main = () => {
  const location = useLocation()
  const _tab = location.pathname.split('/')[2] // 'management' or 'pdf'
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const _service = searchParams.get('service')
  const _page = Number(searchParams.get('page'))
  const _search_filter = Number(searchParams.get('search_filter'))
  const _search = searchParams.get('search')
  const _filter = Number(searchParams.get('filter'))

  // let headList = _tab === 'management' 
  //   ? ['이름', '연도', '학기', '출판사', '중단원 생성/링킹', '']
  //   : ['이름', '연도', '학기', '출판사', '중단원 생성/링킹', 'PDF 매칭여부', '']

  const [headList, setHeadList] = useState([])
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  // ----- SERVICE -----
  const [service, setService] = useState(_service)
  const serviceList = [
    {name: '책', value: 'books'},
    {name: '기출', value: 'qb'},
    {name: '태국', value: 'thai'},
  ]

  useEffect(()=>{
    // 책 / 기출 생성
    if (_tab === 'management' ) {
      if (service === 'books') setHeadList(head_list.management.books)
      if (service === 'thai') setHeadList(head_list.management.thai)
      if (service === 'qb') setHeadList(head_list.management.qb)
    }

    // pdf 업로드
    if (_tab === 'pdf' ) {
      if (service === 'books') setHeadList(head_list.pdf.books)
      if (service === 'thai') setHeadList(head_list.pdf.thai)
      if (service === 'qb') setHeadList(head_list.pdf.qb)
    }
  }, [_tab, service])

  // ----- SEARCH -----
  const [keyword, setKeyword] = useState(_search)
  const [searchFilter, setSearchFilter] = useState(_search_filter)
  const [isEmptyQuestion, setIsEmptyQuestion] = useState(false)
  const searchFilterList = [
    {name: '이름', value: 1, value_name: 'name'},
    {name: '연도', value: 2, value_name: 'year'},
    {name: '출판사', value: 3, value_name: 'publisher'},
  ]
  const setInputType = () => {
    const v = searchFilterList.find(el => el.value === searchFilter)
  }
  setInputType()

  // ----- FILTER -----
  const [filter, setFilter] = useState(_filter)
  const filterList = [
    {name: '전체', value: 0},
    {name: '중단원 입력여부 X', value: 1},
    {name: '중단원 링킹여부 X', value: 2},
    {name: '1학기', value: 3},
    {name: '2학기', value: 4},
  ]

  const { isFetching } = useQuery([`uploads`, {service, page, keyword, filter}], () => getAPI(`uploads`, {page: page + 1, service: service, search_filter: searchFilter, keyword: keyword, filter: filter}), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: data => {
      console.log(data)
      if (!(data.data.page)) return
      if (data?.data?.page.content_count === 0 && data.data.uploads === null) return setIsEmptyQuestion(true)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.uploads} service={_service} />
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
    }
  })

  const changedService = (v: string) => {
    setSearchParams({
      service: v,
      page: 1, 
      search_filter: searchFilter,
      search: keyword,
      filter: filter, 
    })
  }

  const changedPage = (v: number) => {
    setSearchParams({
      service: service,
      page: v + 1, 
      search_filter: searchFilter,
      search: keyword,
      filter: filter, 
    })
  }

  const changedKeyword = (v: string) => {
    setSearchParams({
      service: service,
      page: 1, 
      search_filter: searchFilter,
      search: v,
      filter: filter, 
    })
  }

  const changedFilter = (v: number) => {
    setSearchParams({
      service: service,
      page: 1, 
      search_filter: searchFilter,
      search: keyword,
      filter: v, 
    })
  }

  useEffect(() => {
    setService(_service)
  }, [_service])

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setKeyword(_search)
  }, [_search])

  useEffect(() => {
    setFilter(_filter)
  }, [_filter])

  useEffect(()=>{
    setSearchFilter(_search_filter)
  }, [])

  return (
    <MainSection>
      <>
        <Toolbar
          title={'생성된 책/기출'} 
          isFilterAndSearch 
          // inputType={searchFilter === 1 ? 'number' : 'text'}
          keyword={keyword} 
          getKeyword={changedKeyword} 
          searchFilter={searchFilter} 
          searchFilterList={searchFilterList}
          getSearchFilter={setSearchFilter} 

          isFilter={!isEmptyQuestion} 
          filter={filter} 
          filterList={filterList} 
          getFilter={changedFilter} 
          filterLabel={'필터'} 
          filterBoxWidth={'9rem'}
        />
        <TabWrap>
          <div className='tab_box'>
            {serviceList.map(el => 
              <div 
                key={el.value}
                className={['tab_item', service === el.value ? 'active' : 'inactive'].join(' ')} 
                onClick={()=>changedService(el.value)}
              >{el.name}</div>
            )}
          </div>
          <Button onclick={()=>{
            let pathname: string = ''
            if (service === 'books') pathname = `/upload/management/creation`
            if (service === 'thai') pathname = `/upload/management/creation/thai`
            if (service === 'qb') pathname = `/upload/management/creation/qb`

            navigate({
              pathname: pathname,
              search: `?${createSearchParams({service: service})}`
            })
          }}>
            {serviceList.find(el => el.value === service)?.name} 생성하기
          </Button>
        </TabWrap>
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

const TabWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.8rem;

  .tab_box {
    padding: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .tab_item {
      width: 3.5rem;
      padding: 0.4rem;
      font-size: 0.85rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      cursor: pointer;
    }
    .inactive {
      :hover {
        background-color: #f1f1f1;
      }
    }
    .active {
      font-size: 0.9rem;
      font-weight: 600;
      background-color: #dadada;
    }
  }
`

export default Main