import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Grid, Paper } from '@mui/material'
import MyWorkSpaceList02 from '../../../components/myWorkSpace/midUnit/List'
import MyWorkSpaceToolbar from '../../../components/myWorkSpace/editor/Toolbar'
import { useQuery } from 'react-query'
import { getPdfList02API } from '../../../api/getAPI'

const MiddleUnitSpacePage = (props) => {
  const {'id': params} = useParams()
  const project_id = Number(params)
  const [ pageNum, setPageNum ] = useState(1)
  const [ filter, setFilter ] = useState('전체')

  const [ pdfList, setPdfList ] = useState([])
  const [ totalCount, setTotalCount ] = useState(0)
  const [ progressInfo, setProgressInfo ] = useState({})

  const getPdfList = useQuery(['pdfList', project_id, pageNum, filter],
    () => getPdfList02API(project_id, pageNum, (filter === '전체' ? '' : filter)), 
    {
      onSuccess: data => {
        console.log(data)
        const pregress_data = {
          all_pdf: data.data.all_pdf,
          done_pdf: data.data.done_pdf,
          percentage: data.data.percentage
        }

        handleSetPdfList(data.data.data)
        handleTotalCount(data.data.total_page_count)
        handleProgressInfo(pregress_data)
      },
      onError: error => {
        console.log(error)
      }
    }
  )


  const handleSetPdfList = useCallback((arg) => {
    setPdfList(arg)
  }, [pdfList])

  const handleTotalCount = useCallback((arg) => {
    setTotalCount(arg)
  }, [totalCount])

  const handleProgressInfo = useCallback((arg) => {
    setProgressInfo(arg)
  }, [progressInfo])

  const handleSetFilter = useCallback((filter) => {
    setFilter(filter)
  }, [filter])

  const handleSetPageNum = useCallback((page) => {
    setPageNum(page)
  }, [pageNum])

  return (
    <Grid container direction={"column"} >
          
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>시험지 리스트</Title>
          <MyWorkSpaceToolbar
            progressInfo={progressInfo}
            handleSetFilter={handleSetFilter}
          />
          <MyWorkSpaceList02
            pdfList={pdfList}
            totalCount={totalCount}
            project_id={project_id}
            handleSetPageNum={handleSetPageNum}
          />
        </Item>
      </Grid>
    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 6px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default MiddleUnitSpacePage