import styled from "styled-components/macro"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid orange; */
`

export const MoEWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  /* border: 1px solid blue; */
`

export const MoEHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`
interface HeaderItemProps {
  backgroundColor?: boolean;
  border?: boolean;
  color?: string;
}

export const HeaderItem = styled.div<HeaderItemProps>`
  position: relative;
  margin: 0 30px;
  text-align: center;
  font-size: 14px;
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  color: ${(props: HeaderItemProps) => props.color};
  border: ${(props: HeaderItemProps) => props.border ? '1px solid rgba(172, 215, 250, 0.5)' : '0.5px solid rgba(0, 0, 0, 0.1)'};
  background-color: ${(props: HeaderItemProps) => props.backgroundColor ? '#c8e4fb' : 'none'};
  cursor: pointer;
`

export const Main = styled.div`
  width: 100%;
  max-width: 376px;
  height: 100%;
  /* padding: 0 1.5rem 1rem; */
  overflow-x: hidden;
  overflow-y: auto;
  /* border: 1px solid red; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  :-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`