import { useState, useEffect, useRef } from 'react'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAPI, postAPI, putAPI } from '../../../../api'

import styled from 'styled-components'
import plusIcon from '../../../../assets/icons/plus.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'
import MainSection from '../../../../components/_templates/MainSection'
import Toolbar from '../../../../components/_organisms/mainSection/Toolbar'
import SelectBox from '../../../../components/_molecules/select/SelectBox'
import ChipSelectBox from '../../../../components/_molecules/select/ChipSelectBox'

const Detail = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { id } = useParams()
  const _id = id ? Number(id) : null // number or null
  const [searchParams] = useSearchParams()
  const _service = searchParams.get('service')
  const _tab = searchParams.get('tab')
  // const _name = localStorage.getItem("upload-creation-name")
  const [_name, setName] = useState(localStorage.getItem("upload-creation-name"))
  const [change, setChange] = useState(false)

  const [originData, setOriginData] = useState(null)
  const [stale, setStale] = useState(0)
  const [isEditable, setIsEditable] = useState(true)

  useEffect(()=>{
    if (!originData) return
    setIsEditable(originData.is_editable)
    setFullTitle(originData.name)
    const name_list = originData.name.split('_')
    setTitle(name_list[1] ? name_list[0] : originData.name)
    setYear(originData.year)
    
    if (_service !== 'books') {
      const isSemester = !!(originData.name.split('_')[3])
      if (isSemester) setSemester(originData.semester)
        else setSemester(2)
    } else {
      setSemester(originData.semester)
    }
    setGrade(originData.grade.id)
    setData(originData.sections ? originData.sections : [])
    setTab(originData.sections ? 0 : 1)
  }, [originData, _service])


  // ----- SERVICE -----
  const [service, setService] = useState(_service)
  const serviceList = [
    {name: '책', value: 'books'},
    {name: '기출', value: 'qb'},
    {name: '태국', value: 'thai'},
  ]

  // ----- TAB -----
  const [tab, setTab] = useState(0)
  const tabList = [
    {name: `${serviceList.find(el => el.value === _service)?.name} 생성하기`, value: 0},
    {name: '중단원 생성 / 링킹하기', value: 1},
  ]

  const {refetch} = useQuery([`get-upload/${_id}/${_service}`, {_id, _service, _name, change}], () => 
    getAPI(`upload/${_id}/${_service}`, {name: _name}), {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!(_id),
      onSuccess: data => {
        console.log('성공했다', data.data, _id, _name)
        setOriginData(data?.data)
        setSubSection(data?.data.sub_subject.id)
      }
    }
  )

  // ----- title -----
  const [title, setTitle] = useState('')
  const [fullTitle, setFullTitle] = useState(_name)

  // ----- YEAR -----
  const [year, setYear] = useState(-1)
  const getYearList = (year: number, beforeCount: number, afterCount: number) => {
    const nowAndBeforeYears = Array.from({length: beforeCount}, (el, index) => {return {name: year - index, value: year - index}})
    const afterYears = Array.from({length: afterCount}, (el, index) => {return {name: year + index + 1, value: year + index + 1}}).reverse()
    return [...afterYears, ...nowAndBeforeYears]
  }
  const yearList = getYearList(new Date().getFullYear(), 10, 2)

  // ----- SEMESTER -----
  const [semester, setSemester] = useState(-1)
  const semesterList = [
    {name: '1학기', value: 0},
    {name: '2학기', value: 1},
    {name: '학기구분없음', value: 2},
  ]

  // ----- GRADE -----
  const [grade, setGrade] = useState(-1)
  const gradeList = [
    {name: '중1', value: 4},
    {name: '중2', value: 0},
    {name: '중3', value: 1},
    {name: '고1', value: 2},
    {name: '고2-3', value: 3},
  ]
  useEffect(()=>{
    if (grade !== -1) {
      setSubSubject(-1)
      setSubSubjectList(null)
      setPublisher(-1)
      setPublisherList(null)
    }
  }, [grade])

  // ----- SUB_SUBJECT -----
  const [subSubject, setSubSubject] = useState(-1)
  const [subSubjectList, setSubSubjectList] = useState(null)

  // ----- PUBLISHER -----
  const [publisher, setPublisher] = useState(-1)
  const [publisherList, setPublisherList] = useState(null)


  const getSubSubjects = useQuery([`qb/${grade}/sub-subjects`, {grade}], () => getAPI(`qb/${grade}/sub-subjects`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!(grade !== -1),
    onSuccess: data => {
      console.log(data)
      setSubSubjectList(data?.data)
      if (!!originData && stale === 0) {
        setStale(1)
        setSubSubject(originData.sub_subject.id)
      } else {
        setSubSubject(-1)
      }
    }
  })
  
  const getPublisher = useQuery([`qb/${subSubject}/publishers`, {subSubject}], () => getAPI(`qb/${subSubject}/publishers`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!(subSubject !== -1),
    onSuccess: data => {
      console.log(data)
      setPublisherList(data?.data)
      if (!!originData && stale === 1) {
        setStale(2)
        setPublisher(originData.publisher.id)
      } else {
        setPublisher(-1)
      }
    }
  })
  const { mutate: POST_API_01 } = useMutation(postAPI)
  const { mutate: PUT_API_01 } = useMutation(putAPI)
  const post01 = () => {
    console.log(_id, title, year, semester, grade, subSubject, publisher)
    if (!_id) {
      if (title === '' || year === -1 || semester === -1 || grade === -1 || subSubject === -1 || publisher === -1) return alert('모든 항목을 입력 또는 선택해주세요.')
    } else {
      if (title === '' || year === -1 || (service !== 'books' && semester === -1) || grade === -1 || subSubject === -1 || publisher === -1) return alert('모든 항목을 입력 또는 선택해주세요.')
    }
    
    const _subSubject = subSubjectList?.find(el => el.id === subSubject)?.name
    const _grade = gradeList.find(el => el.value === grade)?.name
    const _semester = semesterList.find(el => el.value === semester)?.name
    const fullTitle = `${title}_${_subSubject}_${_grade}_${_semester}(${year})`
    if (window.confirm(`${fullTitle}을(를) ${!_id ? '생성' : '수정'}하시겠습니까?`) === false) return
    setFullTitle(fullTitle)

    const data = {
      apiURL: _id ? `/upload/${_id}/${service}` : `/upload/books`,
      body: {
        name: fullTitle,
        year: year,
        semester: semester,
        grade: grade,
        sub_subject: subSubject,
        publisher: publisher,
      }
    }
    !_id && POST_API_01(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        alert('생성이 완료되었습니다.')
        setTab(1)
        localStorage.setItem("upload-creation-id", data.data.id)
        localStorage.setItem("upload-creation-name", data.data.name)
        setName(data.data.name)
        localStorage.setItem("upload-creation-service", data.data.service)
        getAPI(`sub-subject/${data.data.sub_subject_id}/qb-sub-sections`, {publisher_id: data.data.publisher_id})
          .then(res => setSubSections(res?.data.sub_sections))
      },
    })

    _id && PUT_API_01(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data?.status === 201) {
          localStorage.setItem("upload-creation-name", fullTitle)
          setName(fullTitle)
          setChange(!change)
          alert('수정이 완료되었습니다.')
          getAPI(`sub-subject/${data.data.sub_subject_id}/qb-sub-sections`, {publisher_id: data.data.publisher_id})
          .then(res => setSubSections(res?.data.sub_sections))
        }
      },
    })
  }

  useEffect(()=>{
    return () => {
      localStorage.removeItem('upload-creation-id')
      // localStorage.removeItem('upload-creation-name')
      localStorage.removeItem('upload-creation-service')
    }
  }, [])

  useEffect(() => {
    setService(_service)
  }, [_service])


  const [subSection, setSubSection] = useState(null)
  const [subSections, setSubSections] = useState([])
  const getSubSections = useQuery([`sub-subject/${subSection}/qb-sub-sections`, {subSection, publisher}], () => getAPI(`sub-subject/${subSection}/qb-sub-sections`, {publisher_id: publisher}), {
    refetchOnWindowFocus: false,
    retry: false,
    // enabled: !!(subSection !== null),
    enabled: !!(subSection !== null && publisher !== -1),
    onSuccess: data => {
      console.log(data, subSection, publisher)
      setSubSections(data?.data.sub_sections)
    }
  })
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const defaultData = [
    {id: uuidv4(), name: '', sub_sections: [{id: uuidv4(), name: ''}]}
  ]
  const [data, setData] = useState(defaultData)
  const addGroup = () => {
    setData([...data, ...defaultData])
  }
  const deleteGroup = (id) => {
    const new_data = data.filter(el => el.id !== id)
    // if (new_data.length === 0) return 
    setData(new_data)
  }

  const addItem = (id: string) => {
    const res = data.map(el => {
      if (el.id === id) {
        el.sub_sections = [...el.sub_sections, {id: uuidv4(), name: ''}]
      }
      return el
    })
    setData(res)
  }
  const deleteItem = (sectionId: string, id: string) => {
    const new_data = data.map(el=>{
      const new_subsections = el.sub_sections.filter(v=>v.id !== id)
      el.sub_sections = new_subsections
      return el
    })
    setData(new_data)
  }

  const checkEmptyData = (obj: any) => {
    const isEmptySection: boolean = obj.findIndex(e => e.name === '') !== -1
    const isEmptySubSection: boolean = obj.findIndex(e => e.sub_sections.find(v => v.name === '')) !== -1
    const isEmptyQbSubSection: boolean = obj.findIndex(e => e.sub_sections.find(v => v.qb_sub_sections.length === 0)) !== -1

    const result = {isEmptySection, isEmptySubSection, isEmptyQbSubSection}
    return result
  }
  
  const { mutate: POST_API_02 } = useMutation(postAPI)
  const { mutate: PUT_API_02 } = useMutation(putAPI)
  const post02 = () => {
    const all_input_data = Array.from(document.querySelectorAll('.input-origin'))
    let res: any[] = []
    
    all_input_data.forEach(el => {

      if (el.dataset.type === 'section') {
        const section_id = el.dataset.id
        res = [...res, {id: section_id, name: el.value, sub_sections: []}]
      } 
      
      if (el.dataset.type === 'sub_section') {
        const section_id = el.dataset.sectionid
        const sub_section_id = el.dataset.subsectionid

        const new_data = res.map((section: any) => {
          if (section.id === section_id) {
            section.sub_sections = [...section.sub_sections, {id: sub_section_id, name: el.value, qb_sub_sections: []}]
          }
          return section
        })
        res = new_data
      }

      if (el.dataset.type === 'chip') {
        const section_id = el.dataset.sectionid
        const sub_section_id = el.dataset.subsectionid

        const new_data = res.map((section: any) => {
          if (section.id === section_id) {
            const subSections = section.sub_sections.map((subSection: any) => {
              if (subSection.id === sub_section_id) {
                subSection.qb_sub_sections = [...subSection.qb_sub_sections, Number(el.dataset.id)]
              }
              return subSection
            })
            section.sub_sections = subSections
          }
          return section
        })
        res = new_data
      }
    })
    console.log(res)
    const emptyData = checkEmptyData(res)
    if (emptyData.isEmptySection) return alert('대단원을 모두 입력해주세요!')
    if (emptyData.isEmptySubSection) return alert('중단원을 모두 입력해주세요!')
    if (emptyData.isEmptyQbSubSection) return alert('큐비 중단원을 모두 링킹해주세요!')

    const upload_creation_id = localStorage.getItem("upload-creation-id")
    console.log(_id, upload_creation_id)

    const data = {
      apiURL:`/upload/${_id ? _id : upload_creation_id}/${service}/sub-sections`,
      body: {
        sections: res,
      }
    }
    !_id && POST_API_02(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data.data.message === 'success') {
          alert('중단원 생성/링킹이 완료되었습니다.')
          navigate(-1)
        }
      },
    })

    _id && PUT_API_02(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data?.data.message === 'success') {
          alert('수정이 완료되었습니다.')
          queryClient.invalidateQueries(`get-upload/${_id}/${_service}`)
        }
      },
    })
  }

  const save = () => {
    if (tab === 0) return post01()
    if (tab === 1) return post02()
  }

  return (
    <MainSection >
      <>
        <Toolbar
          tabList={tabList}
          activeTab={tab}
          getTab={setTab}
          isButtonCus={isEditable}
          buttonName={_id ? '수정하기' : '저장하기'}
          onClickButton={()=>save()} 
        />
        {tab === 0 &&
        <>
          <InputWrap>
            <div className='input-box'>
              <div className='input-label'>{`${serviceList.find(el => el.value === _service)?.name} 이름`}</div>
              <input type='text' defaultValue={title} className='input' onChange={(el)=>setTitle(el.target.value)} />
            </div>
          </InputWrap>
          <SelectWrap>
            <SelectBox label='연도'>
              <>
                {yearList.map(el => 
                  <button key={el.value} onClick={() => setYear(el.value)} className={[
                    'item',
                    year === el.value && 'active'
                    ].join(' ')} type='button'
                  >{el.name}</button>
                )}
              </>
            </SelectBox>
            {!(_id && _service === 'books') &&
              <SelectBox label='학기'>
                <>
                  {semesterList.map(el => 
                    <button key={el.value} onClick={() => setSemester(el.value)} className={[
                      'item',
                      semester === el.value && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              </SelectBox>
            }
            <SelectBox label='학년'>
              <>
                {gradeList.map(el => 
                  <button key={el.value} onClick={() => setGrade(el.value)} className={[
                    'item',
                    grade === el.value && 'active'
                    ].join(' ')} type='button'
                  >{el.name}</button>
                )}
              </>
            </SelectBox>
            <SelectBox label='세부과목'>
              <>
                {subSubjectList?.map(el => 
                  <button key={el.id} onClick={() => setSubSubject(el.id)} className={[
                    'item',
                    subSubject === el.id && 'active'
                    ].join(' ')} type='button'
                  >{el.name}</button>
                )}
              </>
            </SelectBox>
            <SelectBox label='출판사'>
              <>
                {publisherList?.map(el => 
                  <button key={el.id} onClick={() => setPublisher(el.id)} className={[
                    'item',
                    publisher === el.id && 'active'
                    ].join(' ')} type='button'
                  >{el.name}</button>
                )}
              </>
            </SelectBox>
          </SelectWrap>
        </>
        }
        {tab === 1 &&
        <SubSectionGroup>
          <div className='header-wrap'>
            <div className='full_title'>{fullTitle}</div>
            <button className='button-add add-group' onClick={()=>addGroup()}>Add group</button>
          </div>
          
          <div className='container-wrap'>
            <div className='container'>
              {data.map(el => {
                return (
                  <div key={el.id} className='input-group'>
                    <div className='input-group-header'>
                      {/* <div className='button-wrap'> */}
                        <div className='button delete-group' onClick={()=>deleteGroup(el.id)}>
                          <img alt='icon-delete' src={deleteIcon} className='input-delete' />
                        </div>
                        <div className='button add-subSection' onClick={()=>addItem(el.id)}>
                          <img alt='icon-plus' src={plusIcon} className='input-plus' />
                        </div>
                      {/* </div> */}
                    </div>

                    <div className='input-item main'>
                      <input data-type='section' data-id={el.id} type='text' placeholder='대단원' defaultValue={el.name} className='input-origin' />
                    </div>

                    <div className='input-item-sub-wrap'>
                      {el.sub_sections.map(v=>{
                        console.log('sub_sections :', v)
                        return (
                          <div key={v.id} className='sub-box'>
                            <div key={v.id} className='input-item sub'>
                              <input data-type='sub_section' data-sectionid={el.id} data-subsectionid={v.id} type='text' placeholder='중단원' defaultValue={v.name} className='input-origin' />
                            </div>
                            <ChipSelectBox isMultiple items={subSections} mainId={el.id} subId={v.id} selectedItem={v.qb_sub_sections ? v.qb_sub_sections : []} />
                            <div className='delete-image-wrap delete-sub-item' onClick={()=>deleteItem(el.id, v.id)}>
                              <img alt='icon-delete' src={deleteIcon} className='input-delete' />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    {/* <div className='button-add add-item' onClick={()=>addItem(el.id)}>
                      <img alt='icon-plus' src={plusIcon} className='input-plus' />
                    </div> */}
                  </div>
                )
              })}
            </div>
          </div>
        </SubSectionGroup>
        }
      </>
    </MainSection>
  )
}

const SubSectionGroup = styled.div`
  overflow: hidden;
  height: 100%;

  .header-wrap {
    display: flex;
    justify-content: space-between;

    .full_title {
      font-size: 1.1rem;
      font-weight: 600;
      padding: 1rem 0;
    }
    button {
      cursor: pointer;
    }
  }
  .container-wrap {
    height: calc(100% - 59px);
    overflow: auto;
  }
  .container {
    /* height: 100%; */
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    /* border: 1px solid red; */
  }
  .input-group {
    position: relative;
    padding: 0.6rem;
    width: 100%;
    min-width: 10rem;
    max-width: 40rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    border-radius: 4px;
    border: 1px solid #eee;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .input-group-header {
      margin-bottom: 2px;
      display: flex;
      justify-content: space-between;

      .button {
        color: white;
        padding: 0.3rem;
        width: 1.1rem;
        height: 1.1rem;
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;

        img {
          display: inline;
          width: 0.8rem;
          height: 0.8rem;
        }

        :hover {
          border: none;
          img {
            display: inline;
          }
        }
      }
      .delete-group {
        :hover {
          background-color: #F15A59;
        }
      }
      .add-subSection {
        :hover {
          background-color: #57C5B6;
        }
      }
    }
  }
  .sub-box {
    display: grid;
    grid-template-columns: 1fr 1fr 2.3rem;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3rem;

  }
  .input-item {
    padding: 0 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e5e5e5;

    input {
      width: 100%;
      height: 100%;
      min-height: 2.2rem;
      padding: 0 0.4rem;
      border: none;
      outline: none;
    }
  }
  .delete-image-wrap {
    padding: 0.3rem;
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    :hover {
      background-color: #eee;
    }
  }
  .delete-sub-item {
    width: 2.3rem;
    height: 2.3rem;
    /* border: 1px solid red; */
  }
  .input-delete {
    max-width: 1.4rem;
  }
  .button-add {
    max-height: 1.7rem;
    font-size: 0.9rem;
    color: white;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .add-group {
    background-color: #57C5B6;
  }
  .main {
    /* background-color: #FFF9DE; */
    background-color: #eee;
  }
  .main input {
    background-color: #eee;
  }
  .sub {
    background-color: #fff;
  }
  .input-item-sub-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
`
const InputWrap = styled.div`
  display: flex;
  justify-content: flex-start;

  .input-box {
    padding: 0.4rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .input-label {
    padding: 0.4rem 1rem;
    font-size: 0.94rem;
  }
  input {
    padding: 0.6rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid #ddd;

    :focus {
      border: 1px solid #bbb;
    }
  }
`
const SelectWrap = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 0.4rem;
  display: grid;
  column-gap: 0.4rem;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
`

export default Detail