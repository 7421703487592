import { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import styled from 'styled-components'
import SignUp from '../../components/login/SignUp'
import FindIdPw from '../../components/login/FindIdPw'
import EditorCorrecting from './EditorCorrecting'
import EditorDevicePreview from './EditorDevicePreview'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',  
  maxHeight: '700px',
  // maxHeight: 'calc(100vh - 10%)',
  bgcolor: 'background.paper',
  // border: '1px solid #999',
  borderRadius: '5px',
  boxShadow: 24,
  pt: 0,
  // px: 2.5,
  pb: 0,
  outline: 'none',
};

const LayoutModal = (props) => {
  const { open, close, type, scrollToPage=null, Children } = props
  const [ children, setChildren ] = useState()

  useEffect(() => {
    switch(type) {
      case 'signup':
        setChildren(<SignUp close={close} />)
        break
      case 'findIdPw':
        setChildren(<FindIdPw />)
        break
      case 'imageEditor':
        setChildren(<EditorCorrecting close={close} scrollToPage={scrollToPage} />)
        break
      case 'devicePreview':
        setChildren(<EditorDevicePreview close={close} />)
        break
      default:
        setChildren(Children)
        break
    }
  }, [])

  if (type === 'imageEditor') {
    return (
      <Modal
        open={open}
        // onClose={close}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Wrap sx={{ ...style, padding: '1rem', maxWidth: '70%', minWidth: '80%', height: '80%', overflow: 'hidden' }}>
          {children}
        </Wrap>
      </Modal>
    )
  }

  if (type === 'devicePreview') {
    return (
      <Modal
        open={open}
        // onClose={close}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Wrap sx={{ ...style, padding: '1rem', maxWidth: '85%', minWidth: '80%', height: '90%', overflow: 'hidden' }}>
          {children}
        </Wrap>
      </Modal>
    )
  }

  
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Wrap sx={{ ...style, width: '700px' }}>
        {children}
      </Wrap>
    </Modal>
  )
}

const Wrap = styled(Box)`
  overflow: auto visible;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

export default LayoutModal