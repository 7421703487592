import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback, memo } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Box,
  Grid,
  Paper,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { useQuery, useMutation } from 'react-query'
import { getFilterAPI, getAllPdfListAPI } from '../../../api/getAPI' 
import { postPDFMatchingAPI, deletePdfAPI } from '../../../api/postAPI' 
import Button from '../../../elements/button/Button'
import BackToList from '../../../elements/button/BackToList'
import PdfList from '../../../components/admin/project/PdfList'
import LabelerList from '../../../components/admin/project/LabelerList'
import { actionCreators as projectActions } from "../../../redux/modules/project"

const MatchingPage = memo((props) => {
  const dispatch = useDispatch()
  const pdf_data = useSelector((state) => state.project.pdf_data)
  const pdf_data_02 = useSelector((state) => state.project.pdf_data_02)
  const labeler_data = useSelector((state) => state.project.labeler_data)
  const pdf_list = pdf_data && pdf_data.list
  const pdf_list_02 = pdf_data_02 && pdf_data_02.list
  const pdf_count = pdf_data && pdf_data.count
  const pdf_02_count = pdf_data && pdf_data_02.count
  const pdf_total_count = pdf_data && pdf_data.total_count
  const pdf_total_count_02 = pdf_data_02 && pdf_data_02.total_count
  const labeler_list = labeler_data.labeler_list

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const _id = searchParams.get('projectId')
  const _role = Number(searchParams.get('role'))
  const _title = searchParams.get('title')
  const _is_book = searchParams.get('is_book') === 'true' ? true : false
  const storeRef = useRef()
  
  const [ page, setPage ] = useState(1)
  const [ totalNum, setTotalNum ] = useState(0)
  const [ filterData, setFilterData ] = useState([])
  const [ grade, setGrade ] = useState('')
  const [ subject, setSubject ] = useState('')
  const [ publisher, setPublisher ] = useState('')
  const [ isOcr, setIsOcr ] = useState('')
  const [ isKorean, setIsKorean ] = useState(false)
  const [ isEnglish, setIsEnglish ] = useState(false)
  const [ isFilter, setIsFilter ] = useState(false)
  
  const [ activeSelect, setActiveSelect ] = useState(false)
  const [ activeDelete, setActiveDelete ] = useState(false)
  const [ labeler, setLabeler ] = useState('')
  const [ query, setQuery ] = useState([])

  const handleActiveSelect = (bool) => {
    setActiveSelect(bool)
  }
  const handleActiveDelete = (bool) => {
    setActiveDelete(bool)
  }
  console.log(filterData)

  useEffect(() => {
    if (filterData.length > 0 && grade && subject) {
      const subject_name = filterData.filter(el => el.subsubject_id === subject)[0].subsubject_name
      console.log(subject_name)
      if (subject_name === '국어') {
        setIsKorean(true)
        setIsEnglish(false)
      } else if (subject_name === '영어') {
        setIsEnglish(true)
        setIsKorean(false)
      } else {
        setIsKorean(false)
        setIsEnglish(false)
      }
    }
  }, [filterData, grade, subject])

  const handleLabeler = (e) => {
    setLabeler(e.target.value)
  }

  const handleGrade = (e) => {
    setGrade(e.target.value)
    setSubject('')
    setPublisher('')
    setIsOcr('')
  }
  const handleSubject = (e) => {
    setSubject(e.target.value)
    setPublisher('')
    setIsOcr('')
  }
  const handlePublisher = (e) => {
    setPublisher(e.target.value)
    setIsOcr('')
  }
  const handleIsOcr = (e) => {
    setIsOcr(e.target.value)
  }
  
  // 학년 선택 -> 나머지 필터 리스트 데이터 GET
  const { data: filter_data } = useQuery(['filterData', grade], () => getFilterAPI(grade), {
    enabled: !!(grade !== ''),
    onSuccess: data => {
      console.log('==================')
      console.log(data.data)
      setFilterData(data.data)
    }
  })
  // _is_book === true : 문제집 리스트 GET 
  const { data: books } = useQuery(['filterData', grade], () => getFilterAPI(grade), {
    enabled: !!(grade !== ''),
    onSuccess: data => {
      console.log('-----------------------')
      console.log(data.data)
      setFilterData(data.data)
    }
  })
  const { mutate } = useMutation(postPDFMatchingAPI, {
    onSuccess: data => {
      // console.log(data)
      if (data.message === 'success') {
        if (_is_book) {
          getBooksPdfAndLabeler()
        } else {
          handleSearch()
        }
        
        handleChangeSelected()
        dispatchGetLabeler()
      }
    }
  })
  const { mutate: deletePdf } = useMutation(deletePdfAPI, {
    onSuccess: data => {
      // console.log(data)
      if (data.message === 'delete success') {
        if (_is_book) {
          getBooksPdfAndLabeler()
        } else {
          handleSearch()
        }
        dispatchGetLabeler()
        setActiveSelect(false)
        setActiveDelete(false)
      }
    }
  })

  // const { data: all_PDF_data } = useQuery(['allPdfData', labeler], () => getAllPdfListAPI(page, _id, labeler), {
  //   enabled: !!(labeler !== ''),
  //   onSuccess: data => {
  //     console.log(data)
  //   }
  // })

  const [ selected, setSelected ] = useState(0)
  const handleTab = (data) => {
    if (data === 1 && selected === 0) {
      setSelected(1)
    } else if (data === 0 && selected === 1) {
      setSelected(0)
    }
  }

  const getPageNum = (page) => {
    setPage(page)
  }

  const GoBackToList = () => {
    navigate('/project/matching')
  }

  const handleFilter = () => {
    if (selected === 1 && !labeler) return alert('라벨러를 선택해 주세요.')
    if (filterData.length === 0 || 
      !grade || 
      !subject 
      // !subSubject
    ) return alert('필터를 모두 적용해 주세요.')

    // -----------------------------------
    if ((isKorean || isEnglish) && (!publisher || !isOcr)) return alert('출판사와 OCR 모두 적용해 주세요.')
    
    if (isKorean || isEnglish) { // -----------------------------------
      setQuery([null, publisher, isOcr])
      if (selected === 0) {
        dispatch(projectActions.getPdfListAPI(1, _id, _role, null, publisher, isOcr))
      } else if (selected === 1) {
        dispatch(projectActions.getAllPdfList02API(1, _id, labeler, null, publisher, isOcr))
      }
    } else {
      const sub = filterData.filter(el => el.subsubject_id === subject)[0].subsubject_id
      setQuery([sub])
      if (selected === 0) {
        dispatch(projectActions.getPdfListAPI(1, _id, _role, sub))
      } else if (selected === 1) {
        dispatch(projectActions.getAllPdfList02API(1, _id, labeler, sub))
      }
    }
    setIsFilter(true)
    setPage(1)
  }

  const handleAllPDFSearch = () => {
    if (!labeler) return alert('라벨러를 선택해 주세요.')
    dispatch(projectActions.getAllPdfList02API(1, _id, labeler))
  }

  const handleSearch = () => {
    if (_is_book) return
    if (isFilter) {
      
      if (isKorean || isEnglish) {
        if (selected === 0) {
          dispatch(projectActions.getPdfListAPI(page, _id, _role, query[0], query[1], query[2]))
        } else if (selected === 1) {
          dispatch(projectActions.getAllPdfList02API(page, _id, labeler, query[0], query[1], query[2]))
        }
      } else {
        if (selected === 0) {
          dispatch(projectActions.getPdfListAPI(page, _id, _role, query[0]))
        } else if (selected === 1) {
          dispatch(projectActions.getAllPdfList02API(page, _id, labeler, query[0]))
        }
      }
    } else {
      if (selected === 0) {
        dispatch(projectActions.getPdfListAPI(page, _id, _role))
      } else if (selected === 1) {
        dispatch(projectActions.getAllPdfList02API(page, _id, labeler))
      }
    }
  }

  const getBooksPdfAndLabeler = () => {
    console.log('aaaaaaaaaa')
    if (selected === 0) {
      dispatch(projectActions.getBooksPdfListAPI(page, _id, _role))
    } else if (selected === 1) {
      if (!labeler) return 
      dispatch(projectActions.getAllPdfList02API(page, _id, labeler))
    }
  }

  const handleDelete = () => {
    if (!activeDelete) {
      handleActiveSelect(!activeSelect)
    } else {
      const result = handleStore()

      let data = {
        labeler_id: labeler,
        project_id: _id,
        gived_list: result
      }
      // console.log(data)
      deletePdf(data)
    }
  }
  
  const handleCount = (count) => {
    setTotalNum(count)
  }

  const handleStore = () => {
    return storeRef.current.handleSubmit()
  }
  
  const handleChangeSelected = () => {
    return storeRef.current.handleInitialSelected()
  }
  
  const handleMatchingPdf = (labelerIdList) => {
    const result = handleStore()
    
    if (result.length === 0 || labelerIdList.length === 0) {
      alert('매칭을 위해 원하는 PDF와 라벨러를 선택해 주세요.')
      // console.log('실패')
      return
    } 
    let data
    if (_role === 0) {
      data = {
        pdf_id: result,
        labeler_id: labelerIdList[0],
        project_id: _id
      }
    } else {
      data = {
        pdf_id: result,
        labeler_id: labelerIdList[0],
        project_id: _id
      }
    }
    const arg = {
      role: _role,
      data: data
    }
    
    // console.log(data)
    mutate(arg)
    // console.log('성공')
  }

  const dispatchGetLabeler = useCallback(() => {
    dispatch(projectActions.getLabelerListAPI(_id, _is_book))
  }, [_id])

  const deleteAllUseState = () => {
    setPage(1)
    setFilterData([])
    setGrade('')
    setSubject('')
    // setSubSubject('')
    setPublisher('')
    setIsOcr('')
    setIsKorean(false)
    setIsEnglish(false)
    setIsFilter(false)
    setQuery([])
  }

  useEffect(() => {
    deleteAllUseState()
  }, [selected])
  
  useEffect(() => {
    dispatchGetLabeler()
  }, [_id])
  
  useEffect(() => {
    if (!_is_book) handleSearch() // 기출 프로젝트
    if (_is_book) getBooksPdfAndLabeler() // 북스 프로젝트
  }, [page, selected])

  useEffect(() => {
    return () => {
      setLabeler('')
      setSelected(0)
      setTotalNum(0)
      setActiveSelect(false)
      setActiveDelete(false)
      deleteAllUseState()
      dispatch(projectActions.deletePdfList())
    }
  }, [])
  console.log(isKorean, isEnglish)

  return (
    <Grid container >
      
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>{_title}</div>
            </BtnBox>
          </Title>

          <Title>
            <TabBox>
              <Tab selected={selected === 0} onClick={() => handleTab(0)}>라벨러 할당</Tab>
              <Tab selected={selected === 1} onClick={() => handleTab(1)}>pdf 할당 관리</Tab>
            </TabBox>
          </Title>

          {selected === 1 &&
            <LabelerWrap>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">라벨러</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={labeler}
                  label="라벨러"
                  onChange={handleLabeler}
                >
                  {labeler_list.length > 0 && labeler_list.map((el, idx) => {
                    return (
                      <MenuItem 
                        key={el.labeler_id} 
                        value={el.labeler_id}
                      >
                        {el.user_name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <Button height={'40px'} width={'9rem'} 
                onclick={handleAllPDFSearch}
              >
                전체 검색
              </Button>
              <Button height={'40px'} width={'4rem'} 
                color={activeDelete ? 'white' : 'black'} 
                bgColor={activeDelete ? '#F24246' : '#ddd'} onclick={handleDelete}
              >
                삭제
              </Button>
            </LabelerWrap>
          }
          
          {!_is_book
          && (
            <ContentWrap>
              {/* 학년 */}
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">학년</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={grade}
                  label="학년"
                  onChange={handleGrade}
                >
                  <MenuItem value={'4'}>중1</MenuItem>
                  <MenuItem value={'0'}>중2</MenuItem>
                  <MenuItem value={'1'}>중3</MenuItem>
                  <MenuItem value={'2'}>고1</MenuItem>
                  <MenuItem value={'3'}>고2/고3</MenuItem>
                </Select>
              </FormControl>

              {/* 영역(과목) */}
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">과목</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subject}
                  label="과목"
                  onChange={handleSubject}
                >
                  {filterData.length > 0 && filterData.map((el, idx) => {
                    return (
                      <MenuItem 
                        key={el.subsubject_id} 
                        value={el.subsubject_id}
                      >
                        {el.subsubject_name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              {/* 출판사 */}
              <FormControl disabled={!isKorean && !isEnglish} fullWidth size="small">
                <InputLabel id="demo-simple-select-label">출판사</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={publisher}
                  label="출판사"
                  onChange={handlePublisher}
                >
                  {subject && 
                  isKorean && 
                  filterData.filter(el => el.subsubject_name === '국어')[0].publisher.map(el => {
                    return (
                      <MenuItem key={el.id} value={el.id}>{el.publisher_name}</MenuItem>
                    )
                  })}
                  {subject && 
                  isEnglish && 
                  filterData.filter(el => el.subsubject_name === '영어')[0].publisher.map(el => {
                    return (
                      <MenuItem key={el.id} value={el.id}>{el.publisher_name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              {/* OCR/N */}
              <FormControl disabled={!isKorean && !isEnglish} fullWidth size="small">
                <InputLabel id="demo-simple-select-label">OCR/N</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isOcr}
                  label="OCR/N"
                  onChange={handleIsOcr}
                >
                  <MenuItem value={'0'}>NOCR</MenuItem>
                  <MenuItem value={'1'}>OCR</MenuItem>
                </Select>
              </FormControl>

              <Button height={'40px'} onclick={handleFilter}>필터 적용</Button>
            </ContentWrap>
          )}

          {selected === 0 && 
            <div style={{paddingTop: '10px'}}>{pdf_total_count}부 중 <span style={{fontSize: '18px', fontWeight: 700, color: '#2e6ef2'}}>{totalNum}</span>부 부여 완료</div>
          }
          
            <Grid container sx={{height: '100%', overflow: 'hidden'}} >

              <Grid item xs={12} sm={selected === 0 ? 9 : 12} md={selected === 0 ? 9 : 12} lg={selected === 0 ? 9 : 12} sx={{height: '100%', overflow: 'hidden'}} >
                <Item02 hidden={'hidden'} >
                  <PdfList
                    ref={storeRef} 
                    pdfList={selected === 0 
                      ? (pdf_list.length > 0 ? pdf_list : [])
                      : (pdf_list_02.length > 0 ? pdf_list_02 : [])
                    }
                    pageNum={page}
                    getPageNum={getPageNum}
                    pdf_total_count={selected === 0 ? pdf_count : pdf_02_count}
                    tab={selected}
                    activeSelect={activeSelect}
                    activeDelete={activeDelete}
                    handleActiveDelete={handleActiveDelete}
                  />
                </Item02>
              </Grid>
              {selected === 0 &&
              <Grid item xs={12} sm={3} md={3} lg={3} sx={{height: '100%', overflow: 'hidden'}} >
                <Item02 hidden={'hidden'}>
                  <LabelerList 
                    labelerList={labeler_list}
                    handleCount={handleCount}
                    handleMatchingPdf={handleMatchingPdf}
                  />
                </Item02>
              </Grid>
              }
            </Grid>
        </Item>
      </Grid>
    </Grid>
  )
})
const LabelerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-start; */
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`
const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  /* border: 1px solid red; */
`
const BtnBox = styled.div`
  padding-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`
const Tab = styled.div`
  color: ${(props) => (props.selected ? '#1976d2' : 'rgba(0, 0, 0, 0.3)' )};
  font-weight: ${(props) => (props.selected ? '20px' : '1rem' )};
  cursor: pointer;
`
const TabBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Item02 = styled(Paper)`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: calc(100vh - 130px); */
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 6px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default MatchingPage