import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const Axios = axios.create({baseURL: process.env.REACT_APP_API_URL})

Axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token")
    if (token) config.headers.Authorization = token
    return config
  },
  async (error) => {
    console.log(error)
    return Promise.reject(error)
  },
)

Axios.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    console.log(error, error.response.status)

    // if (error.message === 'Network Error') {
    //   alert('현재 서버가 불안정합니다. 10초 후 다시 시도해 주세요. (새로고침 시 작성 중인 데이터를 저장되지 않습니다)')
    //   return Promise.reject(error)
    // }

    if (error.response?.status) {
      switch (error.response?.status) {
        // case 401: case 403:
        //   localStorage.clear()
        //   window.location.href = "/login";
        //   break
        // default:
        //   return Promise.reject(error)
      }
    }
    
    return Promise.reject(error)
  }
)
