import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback, memo } from 'react'
import styled, {keyframes} from 'styled-components'
import {
  Box,
  Grid,
  Paper,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { useQuery, useMutation } from 'react-query'
import { getBooksFilterAPI } from '../../../api/getAPI' 
import Button from '../../../elements/button/Button'
import BackToList from '../../../elements/button/BackToList'
import List from '../../../components/admin/question/ListBooks'
import { actionCreators as questionManageActions } from "../../../redux/modules/questionManage"

const BooksManagePage = memo((props) => {
  const dispatch = useDispatch()
  const storeRef = useRef()
  const is_books = useSelector((state) => state.questionManage.is_books)
  const B_list = useSelector((state) => state.questionManage.B_list)
  const B_count = useSelector((state) => state.questionManage.B_count)
  const B_page = useSelector((state) => state.questionManage.B_before_page)
  const B_mid_unit = useSelector((state) => state.questionManage.B_before_mid_unit)
  const B_filter = useSelector((state) => state.questionManage.B_filter_data)
  
  const [ page, setPage ] = useState(1)
  const [ filterData, setFilterData ] = useState([])

  // 탭
  const [ workType, setWorkType ] = useState('1') // 1: 문제/해설 or 2: 지문

  // 메인 필터
  const [ labelingState, setLabelingState ] = useState(null)
  const [ grade, setGrade ] = useState('')
  const [ subject, setSubject ] = useState('')
  const [ book, setBook ] = useState('')
  const [ midUnit, setMidUnit ] = useState('')

  // 서브 필터
  const [ translation, setTranslation ] = useState('')
  const [ error, setError ] = useState('')
  const [ check, setCheck ] = useState('')
  const [ workStatus, setWorkStatus ] = useState('')

  useEffect(()=>{
    dispatch(questionManageActions.resetBooks())
    setError('')
    setWorkStatus('')
  }, [workType])

  const handleError = (e) => {
    setError(e.target.value)
  }
  const handleWorkStatus = (e) => {
    setWorkStatus(e.target.value)
  }
  
  const [ isModal, setIsModal ] = useState(false)
  const openModal = () => {
    setIsModal(true)
  }
  const closeModal = () => {
    setIsModal(false)
  }

  const handleGrade = (e) => {
    setGrade(e.target.value)
    setSubject('')
    setBook('')
    setMidUnit('')
  }
  const handleSubject = (e) => {
    setSubject(e.target.value)
    setBook('')
    setMidUnit('')
  }
  const handleBooks = (e) => {
    setBook(e.target.value)
    setMidUnit('')
  }
  const handleMidUnit = (e) => {
    setMidUnit(e.target.value)
  }
  
  const { data: filter_data } = useQuery(['filterData', grade], () => getBooksFilterAPI(grade), {
    enabled: !!(grade !== ''),
    onSuccess: data => {
      console.log(data.data)
      if (data.data.filter.length === 0) return alert('할당 받지 않은 학년입니다.')
      setFilterData(data.data.filter)
    }
  })

  const getPageNum = (page) => {
    setPage(page)
  }

  const handleInitP = () => {
    return storeRef.current.handleInitPage()
  }

  const handleFilter = () => {
    if (filterData.length === 0 || 
      !grade || 
      !subject ||
      !book ||
      !midUnit
    ) return alert('필터를 모두 적용해 주세요.')

    setTranslation('')
    setError('')
    setCheck('')
    setWorkStatus('')

    const filter_storage = {
      grade: grade,
      subject: subject,
      book: book,
      midUnit: midUnit,
      translation: translation,
      error: error,
      check: check,
      workType: workType,
      workStatus: workStatus,
      filterData: filterData,
    }
    console.log(filter_storage)
    dispatch(questionManageActions.getBooksListAPI(1, 'books', midUnit, filter_storage))
    handleInitP()
    getPageNum(1)
  }

  const handleSearch = () => {
    if (!midUnit) return
    const filter_storage = {
      grade: grade,
      subject: subject,
      book: book,
      midUnit: midUnit,
      error: error,
      check: check,
      workType: workType,
      workStatus: workStatus,
      filterData: filterData,
    }
    dispatch(questionManageActions.getBooksListAPI(page, 'books', midUnit, filter_storage))
  }

  const handleFirstStore = () => {
    return storeRef.current.handlePage()
  }

  useEffect(() => {
    if (!is_books) dispatch(questionManageActions.isBooks(true))
  }, [is_books])
  
  useEffect(() => {
    handleSearch()
  }, [page])

  useEffect(() => {
    if (B_mid_unit !== 0) {
      const filter_storage = {
        grade: B_filter.grade,
        subject: B_filter.subject,
        book: B_filter.book,
        midUnit: midUnit,
        translation: translation,
        error: error,
        check: check,
        workType: B_filter.workType,
        workStatus: workStatus,
        filterData: B_filter.filterData,
      }
      midUnit && dispatch(questionManageActions.getBooksListAPI(page, 'books', midUnit, filter_storage))
    }
  }, [error, workStatus])

  useEffect(() => {
    if (B_mid_unit !== 0) {
      getPageNum(B_page)
      handleFirstStore()
    }
  }, [B_page])


  useEffect(() => {
    if (B_mid_unit !== 0) {
      getPageNum(B_page)
      setGrade(B_filter.grade)
      setSubject(B_filter.subject)
      setBook(B_filter.book)
      setMidUnit(B_filter.midUnit)
      setWorkType(B_filter.workType)
      setTranslation(B_filter.thai)
      setError(B_filter.error)
      setCheck(B_filter.check)
      setWorkStatus(B_filter.workStatus)
      setFilterData(B_filter.filterData)
      handleFirstStore()
      dispatch(questionManageActions.getBooksListAPI(B_page, 'books', B_mid_unit, B_filter))
    }

  }, [])

  const changeStateForm = (grade, count, data) => {

    return (
      <div className='modal_content'>
        <div>
          <span>{grade}: </span>
          <span>{count}</span>
        </div>
        {data.length > 0 && data.map(el => (
          <div key={el.sub_subject_name} style={{minWidth: '7rem'}}>
            <span>{el.sub_subject_name}</span>: <span>{el.count}</span>
          </div>
        ))}
      </div>
    )
  }

  const getSubFilter = (workType) => {
    if (workType === '1') {
      return [
        {label: '오류 여부', value: error, onChange: handleError, items: [{name: '없음', value: '0'}, {name: '있음', value: '1'}]},
      ]
    } else if (workType === '2') {
      return [
        {label: '작성 여부', value: workStatus, onChange: handleWorkStatus, items: [{name: '없음', value: '0'}, {name: '있음', value: '1'}]},
      ]
    }
  }


  return (
    <Grid container sx={{position: 'relative'}}>
      {isModal &&
        <>
          <BackModal onClick={closeModal}></BackModal>
          <ContentModal>
            <div className='modal_title'>라벨링 현황</div>
            <div className='modal_content_wrap'>
              {labelingState 
                ? <>
                    <div className='modal_content'>
                      전체: {labelingState.h1_count + labelingState.h2_count + labelingState.h3_count + labelingState.m1_count + labelingState.m2_count + labelingState.m3_count}
                    </div>
                    {changeStateForm('중2', labelingState.m2_count, labelingState.m2_data)}
                    {changeStateForm('중3', labelingState.m3_count, labelingState.m3_data)}
                    {changeStateForm('고1', labelingState.h1_count, labelingState.h1_data)}
                    {changeStateForm('고2', labelingState.h2_count, labelingState.h2_data)}
                  </>
                : <div style={{padding: '5rem'}}>데이터를 불러오고 있어요...</div>
              }
            </div>
          </ContentModal>
        </>
      }
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>문제집 관리</div>
              <BtnBox02>
                <BackToList onclick={openModal}>라벨링 현황</BackToList>
              </BtnBox02>
            </BtnBox>
          </Title>

          <TabBox>
            <Tab selected={workType === '1'} onClick={()=>setWorkType('1')}>문제 / 해설</Tab>
            <Tab selected={workType === '2'} onClick={()=>setWorkType('2')}>지문</Tab>
          </TabBox>
          
          <ContentWrap>
            {/* 학년 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">학년</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={grade}
                label="학년"
                onChange={handleGrade}
              >
                <MenuItem value={'4'}>중1</MenuItem>
                <MenuItem value={'0'}>중2</MenuItem>
                <MenuItem value={'1'}>중3</MenuItem>
                <MenuItem value={'2'}>고1</MenuItem>
                <MenuItem value={'3'}>고2/고3</MenuItem>
              </Select>
            </FormControl>

            {/* 과목 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">과목</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subject}
                label="과목"
                onChange={handleSubject}
              >
                {filterData.length > 0 && filterData.map((el, idx) => {
                  return (
                    <MenuItem 
                      key={String(idx)}
                      value={el.subsubject_id}
                    >
                      {el.subsubject_name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            {/* 문제집 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">문제집</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={book}
                label="출판사"
                onChange={handleBooks}
              >
                {subject && 
                filterData.filter(el => el.subsubject_id === subject)[0].book.map(el => {
                  return (
                    <MenuItem key={el.book_id} value={el.book_id}>{el.book_name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            
            {/* 중단원 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">중단원</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue=""
                value={midUnit || ''}
                label="중단원"
                onChange={handleMidUnit}
              >
                {
                  subject && book && (
                    filterData.filter(el => el.subsubject_id === subject)[0].book.filter(el => el.book_id === book)[0].subsection.map(el => {
                      return (
                        <MenuItem key={el.id} value={el.id}>{el.sub_section_name}</MenuItem>
                      )
                    })
                  )
                }
              </Select>
            </FormControl>

            {/* 해설 or 지문 */}
            {/* <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">해설/지문</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={workType}
                label="해설/지문"
                onChange={handleWorkType}
              >
                <MenuItem value={'1'}>해설</MenuItem>
                <MenuItem value={'2'}>지문</MenuItem>
              </Select>
            </FormControl> */}

            <Button height={'40px'} onclick={handleFilter}>필터 적용</Button>
          </ContentWrap>
          <div style={{height: '1rem'}}></div>
          <ContentWrap>
            {getSubFilter(workType).map((e) => {
              return (
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">{e.label}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={e.value}
                    label={e.label}
                    onChange={e.onChange}
                  >
                    {e.items.map(v => <MenuItem value={v.value}>{v.name}</MenuItem>)}
                  </Select>
                </FormControl>
              )
            })}

            <div style={{minWidth: 'fit-content', fontSize: '0.9rem'}}>
              총 <span style={{fontSize: '18px', fontWeight: 700, color: '#2e6ef2'}}>{B_count}</span>개의 문제가 있습니다.
            </div>
          </ContentWrap>

          <Grid container sx={{height: '100%', overflow: 'hidden'}} >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{height: '100%', overflow: 'hidden'}} >
              <Item02 hidden={'hidden'} >
                <List ref={storeRef} Q_data={B_list} Q_count={B_count} Q_page={B_page} getPageNum={getPageNum} workType={B_filter.workType} />
              </Item02>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  )
})
const LabelerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-start; */
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`
const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  /* border: 1px solid red; */
`
const move = keyframes`
	0%{
    top: 8px;
    left: -260px;
  }
  100%{
    top: 8px;
    left: 450px;
  }
`
const move_reverse = keyframes`
	from {
    left: 450px;
  }
  to {
    left: -260px;
  }
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    /* min-width: fit-content; */
    width: 100%;
  }
  .animation_run {
    width: 100%;
    padding: 0 0 20px;
    position: relative;
    cursor: pointer;
    /* border: 1px solid blue; */
    :hover .complete_question {
      display: block;
    }
  }
  .gif_img {
    position: absolute;
    /* transition: all 360ms ease; */
    /* border: 1px solid red; */

    animation-name: ${move};
    animation-duration: 10s;
    /* animation-delay: 1s; */
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* animation-direction: alternate; */
  }
  .ddung {
    top: -10px;
    animation-name: ${move_reverse};
    animation-duration: 3s;
  }
  .jingjing {
    top: -8px;
    animation-name: ${move_reverse};
    animation-duration: 3s;
    animation-delay: 4s;
  }
  .spongibob {
    top: -25px;
    animation-name: ${move};
    animation-duration: 3s;
    animation-delay: 2s;
  }
  .complete_question {
    position: absolute;
    display: none;
    top: 3px;
    left: -65px;
    z-index: 100;
    color: #2e6ef2;
    min-width: fit-content;
    /* border: 1px solid red; */
  }
  .complete_question_right {
    left: 54px;
    color: #111;
    font-size: 14px;
    font-weight: 500;
    max-width: 300px;
    width: 300px;
    padding-top: 5px;
    /* border: 1px solid red; */
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`
const Tab = styled.div`
  padding: 10px 0;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.selected ? '#1976d2' : 'rgba(0, 0, 0, 0.3)' )};
  background-color: ${(props) => (props.selected ? '#f2f2f2' : 'transparent' )};
  font-weight: ${(props) => (props.selected ? '600' : '400' )};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #f2f2f2;
  cursor: pointer;

  :hover {
    background-color: #f2f2f2;
  }
`
const TabBox = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  gap: 10px;
`
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Item02 = styled(Paper)`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: calc(100vh - 130px); */
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 6px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

const BackModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  transition: all 360ms ease;
`
const ContentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: fit-content;
  /* max-width: 80%; */
  /* height: 60%; */
  max-height: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 25px 15px 15px;

  transition: all 360ms ease;

  .modal_title {
    color: #222222;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0 0;
  }
  .modal_content_wrap {
    width: 100%;
    max-width: 45rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 0.8rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);

    .modal_content {
      display: flex;
      flex-direction: column;
    }
  }
`

export default BooksManagePage