import { useState, useEffect, useRef } from 'react'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAPI, postAPI, putAPI } from '../../../../api'

import styled from 'styled-components'
import MainSection from '../../../../components/_templates/MainSection'
import Toolbar from '../../../../components/_organisms/mainSection/Toolbar'
import SelectBox from '../../../../components/_molecules/select/SelectBox'
import SectionBox from '../../../../components/admin/upload/thai/SectionBox'

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Detail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const __id = id ? Number(id) : null // number or null
  const [_id, setId] = useState(__id)
  const [searchParams] = useSearchParams()
  const _service = searchParams.get('service')
  const [_name, setName] = useState(localStorage.getItem("upload-creation-name"))
  const [change, setChange] = useState(false)

  const [originData, setOriginData] = useState(null)
  const [data, setData] = useState(null)
  const [stale, setStale] = useState(0)
  const [isEditable, setIsEditable] = useState(true)
  const [hasSection, setHasSection] = useState(true)

  const date = new Date()
  const [endDate, setEndDate] = useState(date)
  const [hour, setHour] = useState('')
  const [price, setPrice] = useState('')

  function dateFormat(date: Date, hour: string) {
    let month = date.getMonth() + 1
    let day = date.getDate()
    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day

    return `${date.getFullYear()}-${month}-${day} ${hour}:00`
}

  useEffect(()=>{
    if (!originData) return
    console.log(originData)
    setIsEditable(originData.is_editable)

    // 전체 이름
    setFullTitle(originData.name)

    // 시험지 정보
    setTerm(originData.term.id)
    
    // 학년
    setGrade(originData.grade.id)
    
    // 세부과목
    setSubSubject(originData.sub_subject.id)

    // 출판사
    setPublisher(originData.publisher.id)

    setData(originData.sections ? originData.sections : [])
    setTab(originData.sections ? 0 : 1)
  }, [originData, _service])


  // ----- SERVICE -----
  const [service, setService] = useState(_service)
  const serviceList = [
    {name: '책', value: 'books'},
    {name: '기출', value: 'qb'},
    {name: '태국', value: 'thai'},
  ]

  // ----- TAB -----
  const [tab, setTab] = useState(0)
  const tabList = [
    {name: `${serviceList.find(el => el.value === _service)?.name} 생성하기`, value: 0},
    {name: '중단원 생성 / 링킹하기', value: 1},
  ]

  const getDetail = useQuery([`get-upload/${_id}/${_service}`, {_id, _service, _name, change}], () => 
    getAPI(`upload/${_id}/${_service}`, {name: _name}), {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!(_id),
      onSuccess: data => {
        console.log('성공했다', data.data, _id, _name)
        setHasSection(data?.data.has_section)
        setOriginData(data?.data)

        if (data?.data.end_at) {
          const rowDate = data?.data.end_at
          const endDate = rowDate.split(' ')[0]
          const hour = rowDate.split(' ')[1].split(':')[0]
          setEndDate(new Date(endDate))
          setHour(hour)
        }
        if (data?.data.price) {
          setPrice(data?.data.price)
        }
      }
    }
  )

  const [fullTitle, setFullTitle] = useState(_name)

  // ----- TERMS -----
  const [term, setTerm] = useState(-1)
  const [termList, setTermList] = useState(null)

  // ----- GRADE -----
  const [grade, setGrade] = useState(-1)
  const [gradeList, setGradeList] = useState(null)

  // ----- SUB_SUBJECT -----
  const [subSubject, setSubSubject] = useState(-1)
  const [subSubjectList, setSubSubjectList] = useState(null)

  // ----- PUBLISHER -----
  const [publisher, setPublisher] = useState(-1)
  const [publisherList, setPublisherList] = useState(null)

  useEffect(()=>{
    if (grade !== -1) {
      setSubSubject(-1)
      setSubSubjectList(null)
      setPublisher(-1)
      setPublisherList(null)
    }
  }, [grade])

  const getTerms = useQuery([`qb/terms`], () => getAPI(`qb/terms`), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: data => {      
      setTermList(data?.data.terms)
    }
  })

  const getGrades = useQuery([`qb/grades`], () => getAPI(`qb/grades`), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: data => { 
      setGradeList(data?.data)
    }
  })

  const getSubSubjects = useQuery([`qb/${grade}/sub-subjects`, {grade}], () => getAPI(`qb/${grade}/sub-subjects`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!(grade !== -1),
    onSuccess: data => {
      console.log(data)
      setSubSubjectList(data?.data)
      if (!!originData && stale === 0) {
        setStale(1)
        setSubSubject(originData.sub_subject.id)
      } else {
        setSubSubject(-1)
      }
    }
  })
  
  const getPublisher = useQuery([`qb/${subSubject}/publishers`, {subSubject}], () => getAPI(`qb/${subSubject}/publishers`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!(subSubject !== -1),
    onSuccess: data => {
      setPublisherList(data?.data)
      if (!!originData && stale === 1) {
        setStale(2)
        setPublisher(originData.publisher.id)
      } else {
        setPublisher(-1)
      }
    }
  })

  const { mutate: POST_API_01 } = useMutation(postAPI)
  const { mutate: PUT_API_01 } = useMutation(putAPI)
  
  const post01 = () => {
    if (term === -1 || grade === -1 || subSubject === -1 || publisher === -1 || (!_id && !hour) || (!_id && !price)) return alert('모든 항목을 입력 또는 선택해주세요.')
    
    const _term = termList.find(el => el.id === term)?.name
    const _grade = gradeList.find(el => el.id === grade)?.name
    const _subSubject = subSubjectList?.find(el => el.id === subSubject)?.name
    const _publisher = publisherList?.find(el => el.id === publisher)?.name
    const fullTitle = `${_term}_${_grade}_${_subSubject}_${_publisher}`
    console.log(fullTitle)

    if (window.confirm(`${fullTitle}을(를) ${!_id ? '생성' : '수정'}하시겠습니까?`) === false) return
    setFullTitle(fullTitle)

    const data = {
      apiURL: _id ? `/upload/${_id}/${service}` : `/upload/qb`, // 수정 : 생성
      body: {
        name: fullTitle,
        term: term,
        grade: grade,
        sub_subject: subSubject,
        publisher: publisher,
        end_at: dateFormat(endDate, hour),
        price: Number(price),
      }
    }
    !_id && POST_API_01(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        alert('생성이 완료되었습니다.')
        // setTab(1)
        setId(data.data.id)
        localStorage.setItem("upload-creation-id", data.data.id)
        localStorage.setItem("upload-creation-name", data.data.name)
        localStorage.setItem("upload-creation-service", data.data.service)
        setName(data.data.name)
      },
    })

    _id && PUT_API_01(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data?.status === 201) {
          localStorage.setItem("upload-creation-name", fullTitle)
          setName(fullTitle)
          setChange(!change)
          alert('수정이 완료되었습니다.')
        }
      },
    })
  }

  useEffect(()=>{
    return () => {
      localStorage.removeItem('upload-creation-id')
      // localStorage.removeItem('upload-creation-name')
      localStorage.removeItem('upload-creation-service')
    }
  }, [])

  useEffect(() => {
    setService(_service)
  }, [_service])


  const { mutate: POST_API_02 } = useMutation(postAPI)
  
  const post02 = () => {
    // if (hasSection) return alert('실 DB에 저장된 대단원과 중단원이므로 수정이 불가능해요~ 지연님을 불러주세요!')
    const all_input_data = Array.from(document.querySelectorAll('.input-origin'))
    let res: any[] = []
    console.log(service)

    class subSectionClass {
      constructor(id: string, ko_name: string, is_editable) {
        this.id = id;
        this.name = service === 'qb' ? ko_name : {ko: ko_name, th: ''};
        this.is_editable = is_editable;
        this.sub_sections = [];
      }
    }
    
    all_input_data.forEach(el => {

      // 대단원 - 한국
      if (el.dataset.type === 'section') {
        const id = el.dataset.id
        const ko_name = el.value
        const is_editable = !!(el.dataset.iseditable === 'true')
        res = [...res, new subSectionClass(id, ko_name, is_editable)]
        return
      }

      // 대단원 - 태국
      if (el.dataset.type === 'section_thai') {
        const id = el.dataset.id
        const th_name = el.value
        const new_data = res.map((section: any) => {
          if (section.id === id) section.name.th = th_name
          return section
        })
        res = new_data
        return
      }
      
      // 중단원 - 한국
      if (el.dataset.type === 'sub_section') {
        const section_id = el.dataset.sectionid
        const sub_section_id = el.dataset.subsectionid
        const ko_name = el.value

        const new_data = res.map((section: any) => {
          if (section.id === section_id) {
            section.sub_sections = [...section.sub_sections, {id: sub_section_id, name: service === 'qb' ? ko_name : {ko: ko_name, th: ''}}]
          }
          return section
        })
        res = new_data
        return
      }

      // 중단원 - 태국
      if (el.dataset.type === 'sub_section_thai') {
        const section_id = el.dataset.sectionid
        const sub_section_id = el.dataset.subsectionid
        const th_name = el.value

        const new_data = res.map((section: any) => {
          if (section.id === section_id) {
            section.sub_sections.map((e: any) => {
              if (e.id === sub_section_id) {
                e.name.th = th_name
              }
              return e
            })
          }
          return section
        })
        res = new_data
        return
      }
    })
    console.log(res)
    
    const checkEmptyData = (obj: any) => {
      let isEmptySection: boolean = true
      let isEmptySubSection: boolean = true

      if (service === 'qb') {
        isEmptySection= obj.findIndex(e => e.name === '') !== -1
        isEmptySubSection= obj.findIndex(e => e.sub_sections.find(v => v.name === '')) !== -1
      } else if (service === 'thai') {
        isEmptySection= obj.findIndex(e => e.name.ko === '' || e.name.th === '') !== -1
        isEmptySubSection= obj.findIndex(e => e.sub_sections.find(v => v.name.ko === '' || v.name.th === '')) !== -1
      }
  
      const result = {isEmptySection, isEmptySubSection}
      return result
    }
    const {isEmptySection, isEmptySubSection} = checkEmptyData(res)

    if (isEmptySection) return alert('대단원을 모두 입력해주세요!')
    if (isEmptySubSection) return alert('중단원을 모두 입력해주세요!')

    const upload_creation_id = localStorage.getItem("upload-creation-id")

    const data = {
      apiURL:`/upload/${_id ? _id : upload_creation_id}/${service}/sub-sections`,
      body: {
        sections: res,
      }
    }
    POST_API_02(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data.data.message === 'success') {
          alert('중단원 생성/링킹이 완료되었습니다.')
          navigate(-1)
        }
      },
    })
  }

  const save = () => {
    if (tab === 0) return post01()
    if (tab === 1) return post02()
  }

  return (
    <MainSection >
      <>
        <Toolbar
          tabList={tabList}
          activeTab={tab}
          getTab={setTab}
          isButtonCus={isEditable}
          buttonName={_id ? '수정하기' : '저장하기'}
          onClickButton={()=>save()} 
        />
        {tab === 0 &&
        <>
          <FormWrap>
            <SelectWrap>
              <SelectBox label='시험지 정보'>
                <>
                  {termList?.map(el => 
                    <button key={el.id} onClick={() => setTerm(el.id)} className={[
                      'item',
                      term === el.id && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              </SelectBox>

              <SelectBox label='학년'>
                <>
                  {gradeList?.map(el => 
                    <button key={el.id} onClick={() => setGrade(el.id)} className={[
                      'item',
                      grade === el.id && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              </SelectBox>
              
              <SelectBox label='세부과목'>
                <>
                  {subSubjectList?.map(el => 
                    <button key={el.id} onClick={() => setSubSubject(el.id)} className={[
                      'item',
                      subSubject === el.id && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              </SelectBox>
              <SelectBox label='출판사'>
                <>
                  {publisherList?.map(el => 
                    <button key={el.id} onClick={() => setPublisher(el.id)} className={[
                      'item',
                      publisher === el.id && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              </SelectBox>
            </SelectWrap>
            <DateWrap>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="마감일"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue === null ? date : newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <PriceInput type='number' placeholder='시간(ex. 오후 5시 = 17)' value={hour} onChange={(e)=>setHour(e.target.value)}></PriceInput>
              <PriceInput type='number' placeholder='문제 단가(원)' value={price} onChange={(e)=>setPrice(e.target.value)}></PriceInput>
            </DateWrap>
          </FormWrap>
        </>
        }
        { tab === 1 && <SectionBox title={fullTitle} savedData={data} hasSection={hasSection} /> }
      </>
    </MainSection>
  )
}

const InputWrap = styled.div`
  display: flex;
  justify-content: flex-start;

  .input-box {
    padding: 0.4rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .input-label {
    padding: 0.4rem 1rem;
    font-size: 0.94rem;
  }
  input {
    padding: 0.6rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid #ddd;

    :focus {
      border: 1px solid #bbb;
    }
  }
`
const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const DateWrap = styled.div`
  display: flex;
  gap: 1rem;
`
const SelectWrap = styled.div`
  /* margin-bottom: 0.4rem; */
  display: grid;
  column-gap: 0.4rem;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
`
const PriceInput = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 0 1rem;
  height: 3.5rem;
`

export default Detail