import { produce } from "immer"
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from "redux-actions"

// // actions
const SET_PDF_LIST = "SET_PDF_LIST" // PDF 리스트
const SET_PDF_LIST_02 = "SET_PDF_LIST_02" // PDF 리스트
const DELETE_PDF_LIST = "DELETE_PDF_LIST" // PDF 리스트 삭제
const SET_LABELER_LIST = "SET_LABELER_LIST" // labeler 리스트
const SET_PROJECT_DATA = "SET_PROJECT_DATA"
const SET_CREATED_PROJECT_DATA = "SET_CREATED_PROJECT_DATA"

// // actionCreators
const setPdfList = createAction(SET_PDF_LIST, (obj) => ({ obj })) // PDF 리스트
const setPdfList02 = createAction(SET_PDF_LIST_02, (obj) => ({ obj })) // PDF 리스트
const deletePdfList = createAction(DELETE_PDF_LIST, () => ({  })) // PDF 리스트 삭제
const setLabelerList = createAction(SET_LABELER_LIST, (list) => ({ list })) // labeler 리스트
const setProjectData = createAction(SET_PROJECT_DATA, (obj) => ({ obj }))
const setCreatedProjectData = createAction(SET_CREATED_PROJECT_DATA, (obj) => ({ obj }))

// // initialState
const initialState = {
  pdf_data: {
    list: [],
    count: 0,
    total_count: 0,
    total_page_num: 0,
  },
  pdf_data_02: {
    list: [],
    count: 0,
    total_count: 0,
    total_page_num: 0,
  },
  labeler_data: {
    labeler_list: [],
    labeler_total_page_num: 0,
  },

  project_data: {
    list: [],
    total_count: 0,
    total_page_num: 0,
  },
  project_list: [],
  project_total_page: 0,
  project_total_count: 0,
}

// 북스일 때, 페이지 로딩 시 바로 호출
const getBooksPdfListAPI = (page, project_id, role) => {
  const accessToken = localStorage.getItem("access_token")
  const role_path = role === 0 ? 'admin-pdf' : 'done-pdf'
  let path = `books/project/${role_path}/${project_id}?page=${page}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setPdfList(res.data))
      }
    } catch (err) {
      console.log(err, "getBooksPdfListAPI")
    }
  }
}

const getPdfListAPI = (page, project_id, role, subject=null, publisher=null, ocr=null) => {
  const accessToken = localStorage.getItem("access_token")
  const role_path = role === 0 ? 'admin-pdf' : 'done-pdf'
  let path = `project/${role_path}/${project_id}?page=${page}`
  
  if (ocr) path = path + `&ocr=${ocr}`
  if (subject) path = path + `&subject=${subject}`
  if (publisher) path = path + `&publisher=${publisher}`
  console.log(path)

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setPdfList(res.data))
      }
    } catch (err) {
      console.log(err, "getPdfListAPI")
    }
  }
}


const getAllPdfList02API = (page, project_id, labeler_id, subject=null, publisher=null, ocr=null) => {
  let path = `project/give/${project_id}?page=${page}&labeler_id=${labeler_id}`

  if (ocr) path = path + `&ocr=${ocr}`
  if (subject) path = path + `&subject=${subject}`
  if (publisher) path = path + `&publisher=${publisher}`

  console.log('path', path)

  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setPdfList02(res.data))
      }
    } catch (err) {
      console.log(err, "getAllPdfList02API")
    }
  }
}

const getLabelerListAPI = (project_id, is_book) => {
  const path = `project/labeler/${project_id}`
  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setLabelerList(res.data))
      }
    } catch (err) {
      console.log(err, "getLabelerListAPI")
    }
  }
}

const getProjectListAPI = (page, keyword = '') => {
  const accessToken = localStorage.getItem("access_token")
  let path = `project?page=${page}`

  if (keyword) path = path + `&project_name=${keyword}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setProjectData(res.data))
      }
    } catch (err) {
      console.log(err, "getLabelerListAPI")
    }
  }
}

// 할당/부여 - 생성된 프로젝트 리스트
const getCreatedProjectListAPI = (page, keyword) => {
  const accessToken = localStorage.getItem("access_token")
  let path = `project/all?page=${page}`

  if (keyword) path = path + `&project_name=${keyword}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setCreatedProjectData(res.data))
      }
    } catch (err) {
      console.log(err, "getCreatedProjectListAPI")
    }
  }
}

// 검색 필터
const getFilterAPI = (project_id) => {
  const path = `project/filter?subject_class=${project_id}`
  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setLabelerList(res.data))
      }
    } catch (err) {
      console.log(err, "getLabelerListAPI")
    }
  }
}

// pdf labeler 부여
const postMatchingPdfAPI = (role, obj) => {
  const role_path = role === 0 ? 'admin-pdf' : 'done-pdf'
  const path = `/project/${role_path}`
  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.post(path, obj, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      // if (res.status === 200) {
      //   dispatch(setMatchingPdf(res.data))
      // } else {
      //   // alert('존재하지 않는 계정입니다')
      // }
    } catch (err) {
      console.log(err, "saveMiddleUnit")
    }
  }
}

// // reducer
export default handleActions(
  {
    [SET_PDF_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.pdf_data.list = action.payload.obj.data;
        draft.pdf_data.count = action.payload.obj.count;
        draft.pdf_data.total_count = action.payload.obj.total_count;
        draft.pdf_data.total_page_num = action.payload.obj.total_page;
      }),

    [SET_PDF_LIST_02]: (state, action) =>
      produce(state, (draft) => {
        draft.pdf_data_02.list = action.payload.obj.data;
        draft.pdf_data_02.count = action.payload.obj.count;
        draft.pdf_data_02.total_count = action.payload.obj.total_count;
        draft.pdf_data_02.total_page_num = action.payload.obj.total_page;
      }),

    [DELETE_PDF_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.pdf_data.list = [];
        draft.pdf_data.total_count = 0;
        draft.pdf_data.total_page_num = 0;

        draft.pdf_data_02.list = [];
        draft.pdf_data_02.total_count = 0;
        draft.pdf_data_02.total_page_num = 0;

        draft.labeler_data.labeler_list = [];
      }),
      
    [SET_LABELER_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.labeler_data.labeler_list = action.payload.list;
        // draft.labeler_data.labeler_total_page_num = action.payload.list;
      }),

    [SET_PROJECT_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.project_list = action.payload.obj.data;
        draft.project_total_page = action.payload.obj.total_page;
        draft.project_total_count = action.payload.obj.total_count;
      }),

    [SET_CREATED_PROJECT_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.project_data.list = action.payload.obj.data;
        draft.project_data.total_page = action.payload.obj.total_page;
        draft.project_data.total_count = action.payload.obj.total_count;
      }),
  },
  initialState
);

const actionCreators = {
  getPdfListAPI,
  getAllPdfList02API,
  getBooksPdfListAPI,
  deletePdfList,
  getLabelerListAPI,
  getFilterAPI,
  postMatchingPdfAPI,
  getProjectListAPI,
  getCreatedProjectListAPI,
};

export { actionCreators };
