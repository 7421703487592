import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from "react-query"
import { postCheckPhone, postCheckId, postSignUpAPI } from '../../api/postAPI'
import * as S from '../../components/mypage/MyInfoForm.style'
import Form from '../../components/mypage/forms/Form'
import SelectBox from '../../elements/SelectBox'
import { Box } from '@mui/material';
import { actionCreators as userActions } from "../../redux/modules/user"

const SignUp = ({close}) => {
  const dispatch = useDispatch()
  // 전화번호 인증 위한 인증번호 요청
  const { mutate: checkPhone } = useMutation(postCheckPhone, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'SUCCESS') {
        setState(1)
        setKeyType('checkCode')
        setCODE(data.CODE)
      }
    },
    onError: error => {
      console.log(error)
      if (error.response.data.message === 'phone exists') return alert('이미 가입된 전화번호 입니다.')
    }
  })
  // 아이디 중복 체크
  const { mutate: checkId } = useMutation(postCheckId, {
    onSuccess: data => {
      console.log(data)
      if (data.Message === 'Id can use') {
        setIdCheck(true)
        alert('사용 가능한 아이디 입니다.')
      }
    },
    onError: error => {
      console.log(error)
      setIdCheck(false)
      if (error.response.data.Error === 'Id already exists') return alert('이미 사용 중인 아이디 입니다.')
    }
  })
  // 최종 회원가입
  const { mutate } = useMutation(postSignUpAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'register success') {
        alert('회원가입 완료')
        handleStateReset()
        close()
      }
    },
    onError: error => {
      console.log(error)
      if (error.response.data.error === 'id duplicated') return alert('중복된 아이디 입니다.')
      if (error.response.data.error === 'phone duplicated') return alert('중복된 전화번호 입니다.')
    }
  })
  // const CODE = useSelector((state) => state.user.code)
  const [ CODE, setCODE ] = useState('')
  console.log('code', CODE)
  
  const [ keyType, setKeyType ] = useState('requestCode')
  const [ state, setState ] = useState(0)
  const [ wrong, setWrong ] = useState(0)  // 0: gray, 1: red, 2: blue - 번호 인증
  const [ wrongPW, setWrongPW ] = useState(0)  // 0: gray, 1: red, 2: blue - 비밀번호 확인
  const [ code, setCode ] = useState('')
  const [ idCheck, setIdCheck ] = useState(false)
  
  const codeRef = useRef(0)

  const nameRef = useRef('')
  const phoneRef = useRef('')
  // const ph01Ref = useRef('')
  // const ph02Ref = useRef('')
  // const ph03Ref = useRef('')
  const idRef = useRef('')
  const pwRef = useRef('')
  const rePwRef = useRef('')
  const [ group, setGroup ] = useState('')
  const [ role, setRole ] = useState('')

  const handleStateReset = () => {
    nameRef.current.value = ''
    phoneRef.current.value = ''
    idRef.current.value = ''
    pwRef.current.value = ''
    rePwRef.current.value = ''
    setGroup('')
    setRole('')
  }

  const handleCode = (e) => {
    const value = e.target.value
    setCode(value)
    if ((value || code) === CODE) {
      setWrong(2)
    } else if (value.length > 0) {
      setWrong(1)
    } else {
      setWrong(0)
    }
  }

  const handleRePW = (e, type) => {
    const value = e.target.value
    const rePw = rePwRef.current.value
    const target = type === 'rePw' ? pwRef.current.value : rePwRef.current.value
    if (!rePw) {
      setWrongPW(0)
    } else if (value === target) {
      setWrongPW(2)
    } else if (value.length > 0) {
      setWrongPW(1)
    } else {
      setWrongPW(0)
    }
  }

  const recallCode = () => {
    setWrong(1)
    requestCode()
  }

  const requestCode = () => {
    if (nameRef.current.value &&
      phoneRef.current.value 
    ) {
      const codeData = {
        phone: phoneRef.current.value,
        type: 'check'
      }
      checkPhone(codeData)
      // dispatch(userActions.requestCodeAPI(codeData))
    } else {
      alert('인증 번호 코드 요청 error')
    }
  }

  const checkCode = () => {
    if (code === CODE) {
      setState(2)
      setWrong(0)
      setKeyType('handleSignUp')
    } else {
      alert('인증번호를 다시 입력해 주세요. 인증번호가 잘못 입력되었습니다.')
    }
  }

  const FindRole = () => {
    // console.log(role)
    switch(role){
      case '라벨러':
        return 0
      case '관리자':
        return 1
      default:
        break
    }
  }  

  const handleSignUp = () => {
    if (!idCheck) return alert('아이디 중복 확인을 해주세요.')
    if (state > 1 &&
      nameRef.current.value &&
      phoneRef.current.value &&
      idRef.current.value &&
      pwRef.current.value &&
      rePwRef.current.value &&
      pwRef.current.value === rePwRef.current.value &&
      group &&
      role
    ) {
      const role = FindRole()
      const userData = {
        user_id: idRef.current.value,
        username: nameRef.current.value,
        password: rePwRef.current.value,
        group: group,
        admin: role,
        phone: phoneRef.current.value,
      }
      console.log('userData', userData)
      mutate(userData)
    } else if (pwRef.current.value !== rePwRef.current.value) {
      alert('비밀번호를 다시 입력해 주세요.')
    } else {
      alert('입력되지 않은 내용이 있습니다.')
    }
  }

  const handleCheckId = () => {
    if (!idRef.current.value) return alert('아이디를 입력해 주세요.')
    const data = {
      user_id: idRef.current.value
    }
    console.log(data)
    checkId(data)
  }

  const clickBtn = () => {
    // console.log(state)
    if (state === 0) requestCode()
    if (state === 1) checkCode()
    if (state === 2) handleSignUp()
  }

  const handleKeyPress = (e, type) => {
    // console.log(type)
    if(e.key === 'Enter') {
      switch(type) {
        case 'requestCode':
          requestCode()
          break
        case 'checkCode':
          checkCode()
          break
        case 'handleSignUp':
          handleSignUp()
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    return () => {
      setState(0)
      setWrong(0)
      setWrongPW(0)
      setCode('')
      setKeyType('requestCode')
      dispatch(userActions.setCode(null))
    }
  }, [])

  useEffect(() => {
    if (code === '' && CODE) {
      setState(1)
      setKeyType('checkCode')
    }
  }, [CODE])

  return (
    <Box>
      <S.CardPadding>
        <Box p="0 20px" display="flex" flexDirection="row" justifyContent="center" alignItems="center" >
          <S.Wrap>
            <S.Header>
              <h1>회원가입</h1>
            </S.Header>
            <div className='wrapwrap'>
            <S.Forms nogap state={state > 0 ? '20px' : '0'}>
              
              <Form className='form' ref={nameRef} title={'이름'} type={'text'} value={''} disabled={state !== 0} onkeypress={(e) => handleKeyPress(e, keyType)} />
              
              <div className="form_box form">
                <Form className='form' ref={phoneRef} title={'휴대폰번호'} type={'number'} value={''} disabled={state !== 0} onkeypress={(e) => handleKeyPress(e, keyType)} />
              </div>

              <S.CodeInputWrap className='form' height={state > 0 ? '60px' : 0} mt={state > 1 ? '20px' : 0} >
                <Form ref={codeRef} value={''} title={'인증번호'} type={'number'} disabled={state !== 1} onkeypress={(e) => handleKeyPress(e, keyType)} onchange={handleCode} className={`class${wrong}`} />
              </S.CodeInputWrap>

              {/* <S.IdInputWrap className='form' height={state > 0 ? '60px' : 0} mt={state > 1 ? '20px' : 0} >
                <Form ref={codeRef} value={''} title={'인증번호'} type={'number'} disabled={state > 1 ? true : false } onkeypress={(e) => handleKeyPress(e, keyType)} onchange={handleCode} className={`class${wrong}`} />
              </S.IdInputWrap> */}

              <S.InfoWrap height={state > 1 ? '380px' : 0} >
                <Form className='form' ref={idRef} value={''} title={'아이디'} type={'text'} width={'290px'} check_btn onCheckId={handleCheckId} disabled={idCheck} onkeypress={(e) => handleKeyPress(e, keyType)} />
                <Form className='form' ref={pwRef} value={''} title={'비밀번호'} type={'password'} onkeypress={(e) => handleKeyPress(e, keyType)} onchange={(e) => handleRePW(e, 'pw')} />
                <Form ref={rePwRef} value={''} title={'비밀번호 확인'} type={'password'} onkeypress={(e) => handleKeyPress(e, keyType)} onchange={(e) => handleRePW(e, 'rePw')} className={`class${wrongPW}`} />

                <Form className='form' setState={setGroup} title={'소속'} type={'text'} select_type={'group'} onkeypress={(e) => handleKeyPress(e, keyType)} />
                <Form className='form' setState={setRole} title={'권한'} type={'text'} select_type={'role'} onkeypress={(e) => handleKeyPress(e, keyType)} />
              </S.InfoWrap>
              
            </S.Forms>
            </div>
            <S.Button
              onClick={() => clickBtn()}
            >
              {state === 0 && '인증 번호 요청'}
              {state === 1 && '번호 인증하기'}
              {state === 2 && '회원가입'}
            </S.Button>
          </S.Wrap>
        </Box>
      </S.CardPadding>
    </Box>
  )
}

export default SignUp