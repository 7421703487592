import { useQuery } from "react-query";
import { getAPI } from "api";

const getGrammarChildUnits = '/grammar-child-units'

export default function useGetGrammarsForSearching (keyword: string) {
  const response = useQuery(
    [getGrammarChildUnits, keyword],
    () => getAPI(
      getGrammarChildUnits, { keyword }))
  return response
}
