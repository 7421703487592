// import { Plugin } from 'ckeditor5/src/core';
// import { TwoStepCaretMovement, inlineHighlight } from 'ckeditor5/src/typing';

// import InsertBlockCommand from './blockcommand';
// import InsertBlockTypeCommand from './blocktypecommand';
// import { Widget, toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget';

// export default class BlockEditing extends Plugin {
// 	static get pluginName() {
// 		return 'BlockEditing';
// 	}

// 	static get requires() {
// 		return [Widget];
// 	}

// 	init() {
// 		this._defineSchema();
// 		this._defineConverters();

// 		this.editor.commands.add(
// 			'insertBlock',
// 			new InsertBlockCommand(this.editor)
// 		);
// 		this.editor.commands.add(
// 			'insertBlockType',
// 			new InsertBlockTypeCommand(this.editor)
// 		);
// 	}

// 	_defineSchema() {
// 		const schema = this.editor.model.schema;

// 		schema.register('block', {
// 			inheritAllFrom: '$container',
// 			allowAttributes: ['data-type'],
// 		});

// 		schema.register('blockContent', {
// 			isLimit: true,
// 			allowIn: 'block',
// 			// allowContentOf: '$block',
// 			allowContentOf: '$root',
// 		});
// 	}

// 	_defineConverters() {
// 		const conversion = this.editor.conversion;

// 		// <simpleBox> converters
// 		conversion.for('upcast').elementToElement({
// 			view: {
// 				name: 'section',
// 				classes: 'pr-block',
// 			},
// 			model: 'block',
// 		});

// 		conversion.for('dataDowncast').elementToElement({
// 			model: 'block',
// 			view: {
// 				name: 'section',
// 				classes: 'pr-block',
// 			},
// 		});
// 		conversion.for('editingDowncast').elementToElement({
// 			model: 'block',
// 			view: (modelElement, { writer: viewWriter }) => {
// 				const section = viewWriter.createContainerElement(
// 					'section',
// 					{
// 						class: 'pr-block',
// 					},
// 					[]
// 				);
// 				viewWriter.setCustomProperty('prediction', true, section);

// 				return toWidget(section, viewWriter, {
// 					label: 'prediction block',
// 					hasSelectionHandle: true,
// 				});
// 			},
// 		});
// 		conversion.attributeToAttribute({
// 			model: {
// 				name: 'block',
// 				key: 'data-type',
// 				value: (viewElement, conversionApi) => {
// 					return viewElement.getAttribute('data-type');
// 				},
// 			},
// 			view: {
// 				name: 'section',
// 				key: 'data-type',
// 			},
// 		});

// 		// <blockContent> converters
// 		conversion.for('upcast').elementToElement({
// 			model: 'blockContent',
// 			view: {
// 				name: 'div',
// 				classes: 'pr-block_content',
// 			},
// 		});
// 		conversion.for('dataDowncast').elementToElement({
// 			model: 'blockContent',
// 			view: {
// 				name: 'div',
// 				classes: 'pr-block_content',
// 			},
// 		});
// 		conversion.for('editingDowncast').elementToElement({
// 			model: 'blockContent',
// 			view: (modelElement, { writer: viewWriter }) => {
// 				const div = viewWriter.createEditableElement('div', {
// 					class: 'pr-block_content',
// 				});
// 				return toWidgetEditable(div, viewWriter);
// 			},
// 		});
// 	}
// }

import { Plugin } from 'ckeditor5/src/core';
import { Enter } from 'ckeditor5/src/enter';
import { Delete } from 'ckeditor5/src/typing';

import { Widget, toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget';

import InsertBlockCommand from './blockcommand';
import InsertBlockTypeCommand from './blocktypecommand';

export default class BlockEditing extends Plugin {
	static get pluginName() {
		return 'BlockEditing';
	}

	static get requires() {
		return [Enter, Delete];
	}

	init() {
		const editor = this.editor;
		const schema = editor.model.schema;

		this.editor.commands.add(
			'insertBlock',
			new InsertBlockCommand(this.editor)
		);
		this.editor.commands.add(
			'insertBlockType',
			new InsertBlockTypeCommand(this.editor)
		);

		schema.register('block', {
			inheritAllFrom: '$container',
			allowAttributes: ['data-type'],
		});
		schema.register('blockContent', {
			isLimit: true,
			allowIn: 'block',
		});
		schema.extend('$text', {
			allowIn: 'blockContent',
		});

		// editor.conversion.elementToElement({
		// 	model: 'block',
		// 	view: {
		// 		name: 'section',
		// 		classes: 'pr-block',
		// 	},
		// });

		////////
		editor.conversion.for('upcast').elementToElement({
			view: {
				name: 'section',
				classes: 'pr-block',
			},
			model: (viewItem, { writer }) => {
				const type = viewItem.getAttribute('data-type');
				return writer.createElement('block', { 'data-type': type });
			},
		});

		editor.conversion.for('dataDowncast').elementToElement({
			model: 'block',
			view: (modelItem, { writer }) => {
				const type = modelItem.getAttribute('data-type');
				return writer.createContainerElement('section', {
					class: 'pr-block',
					'data-type': type,
				});
			},
		});

		editor.conversion.for('editingDowncast').elementToElement({
			model: 'block',
			view: (modelElement, { writer }) => {
				const type = modelElement.getAttribute('data-type');
				const section = writer.createContainerElement('section', {
					class: 'pr-block',
					'data-type': type,
				});

				writer.setCustomProperty('prediction', true, section);

				return section;
				return toWidget(section, writer, {
					label: 'prediction block',
					hasSelectionHandle: true,
				});
			},
			converterPriority: 'high',
		});

		editor.conversion.attributeToAttribute({
			model: {
				name: 'block',
				key: 'data-type',
				value: (viewElement, conversionApi) => {
					return viewElement.getAttribute('data-type');
				},
			},
			view: {
				name: 'section',
				key: 'data-type',
			},
		});
		////////

		// Postfixer which cleans incorrect model states connected with block quotes.
		editor.model.document.registerPostFixer((writer) => {
			const changes = editor.model.document.differ.getChanges();

			for (const entry of changes) {
				if (entry.type == 'insert') {
					const element = entry.position.nodeAfter;

					if (!element) {
						// We are inside a text node.
						continue;
					}

					if (element.is('element', 'block') && element.isEmpty) {
						// Added an empty blockQuote - remove it.
						writer.remove(element);

						return true;
					} else if (
						element.is('element', 'block') &&
						!schema.checkChild(entry.position, element)
					) {
						// Added a blockQuote in incorrect place. Unwrap it so the content inside is not lost.
						writer.unwrap(element);

						return true;
					} else if (element.is('element')) {
						// Just added an element. Check that all children meet the scheme rules.
						const range = writer.createRangeIn(element);

						for (const child of range.getItems()) {
							if (
								child.is('element', 'block') &&
								!schema.checkChild(
									writer.createPositionBefore(child),
									child
								)
							) {
								writer.unwrap(child);

								return true;
							}
						}
					}
				} else if (entry.type == 'remove') {
					const parent = entry.position.parent;

					if (parent.is('element', 'block') && parent.isEmpty) {
						// Something got removed and now blockQuote is empty. Remove the blockQuote as well.
						writer.remove(parent);

						return true;
					}
				}
			}

			return false;
		});

		const viewDocument = this.editor.editing.view.document;
		const selection = editor.model.document.selection;
		const blockQuoteCommand = editor.commands.get('block');

		// Overwrite default Enter key behavior.
		// If Enter key is pressed with selection collapsed in empty block inside a quote, break the quote.
		this.listenTo(
			viewDocument,
			'enter',
			(evt, data) => {
				if (!selection.isCollapsed || !blockQuoteCommand.value) {
					return;
				}

				const positionParent = selection.getLastPosition().parent;

				if (positionParent.isEmpty) {
					editor.execute('block');
					editor.editing.view.scrollToTheSelection();

					data.preventDefault();
					evt.stop();
				}
			},
			{ context: 'block' }
		);

		// Overwrite default Backspace key behavior.
		// If Backspace key is pressed with selection collapsed in first empty block inside a quote, break the quote.
		this.listenTo(
			viewDocument,
			'delete',
			(evt, data) => {
				if (
					data.direction != 'backward' ||
					!selection.isCollapsed ||
					!blockQuoteCommand.value
				) {
					return;
				}

				const positionParent = selection.getLastPosition().parent;

				if (positionParent.isEmpty && !positionParent.previousSibling) {
					editor.execute('block');
					editor.editing.view.scrollToTheSelection();

					data.preventDefault();
					evt.stop();
				}
			},
			{ context: 'block' }
		);
	}
}
