import React, { useEffect, useState } from 'react';
import styled from "styled-components";
// import { useMediaQuery } from "react-responsive";
import rightArrow from "../../assets/images/carousel/rightArrowMain.png";
import leftArrow from "../../assets/images/carousel/leftArrowMain.png";
import test00 from '../../components/editor/classification/[Q]_0.jpg'
import test01 from '../../components/editor/classification/[Q]_1.jpg'
import test02 from '../../components/editor/classification/[Q]_2.jpg'
import test03 from '../../components/editor/classification/[Q]_3.jpg'
// const DesktopTablet = ({ children }) => {
//   const isTablet = useMediaQuery({ minWidth: 480 });
//   return isTablet ? children : null;
// };

// const Mobile = ({ children }) => {
//   const isMobile = useMediaQuery({ maxWidth: 479 });
//   return isMobile ? children : null;
// };

const Wrap = styled.div`
  /* max-height: 480px; */
  /* max-width: 870px; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.5rem 1.2rem;
  overflow: hidden;
  
  @media screen and (max-width: 479px) {
    // max-height: 280px;
  }

  .container__slider {
    /* max-width: 870px; */
    /* max-height: 480px; */
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 479px) {
      max-height: 210px;
    }
  }
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* opacity: 0; */

    transition: opacity ease-in-out 0.3s;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

    display: none;

    /* border: 1px solid red; */

    /* & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    } */
  }
  .active-anim {
    /* opacity: 1; */
    display: flex;
  }


  .btn__slide {
    position: absolute;
    /* border: 1px solid red; */
    // display: flex;
    // justify-content: center;
    // align-items: center;
    cursor: pointer;
  }
  .btn__slide img {
    width: 17px;
    height: 29px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
    left: 6px;
    width: 30px;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    transform: translateY(-50%);
    
    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    @media screen and (max-width: 479px) {
      left: 20px;
    }
  }
  .next {
    top: 50%;
    right: 6px;
    width: 30px;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    transform: translateY(-50%);

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    @media screen and (max-width: 479px) {
      right: 20px;
    }
  }


  .container-dots {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    @media screen and (max-width: 479px) {
      bottom: 10px;
      justify-content: center;
      align-items: center;
    }
  }
  .dot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid #DBDBDB;
    margin: 0 5px;
    background: #DBDBDB;

    @media screen and (max-width: 479px) {
      width: 5px;
      height: 5px;
      border: none;
    }
  }
  .dot.active {
    background: rgb(32, 32, 32);

    @media screen and (max-width: 479px) {
      border: 1px solid rgb(32, 32, 32);
    }
  }
`;

const ContentsCarousel = React.memo(({ scrollToPage, imageList, handlePageCheck, handleClose }) => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
  const imageListNum = imageList && imageList.length;
  const [ pageList, setPageList ] = useState([1])
  // console.log(imageList)

  useEffect(() => {
    if (imageList.length === pageList.length) handlePageCheck(true)
  }, [imageList, pageList.length])

  const handlePageList = () => {
    if (pageList.includes(slideIndex + 1)) return
    setPageList([...pageList, slideIndex + 1])
  }

  const nextSlide = (e) => {
    if(slideIndex !== imageListNum){
      setSlideIndex(slideIndex + 1);
    } 
    else if (slideIndex === imageListNum){
      setSlideIndex(1);
    }
    handlePageList()
  };

  const prevSlide = () => {
    if(slideIndex !== 1){
      setSlideIndex(slideIndex - 1);
    }
    else if (slideIndex === 1){
      setSlideIndex(imageListNum);
    }
    handlePageList()
  };

  const moveDot = index => {
    setSlideIndex(index);
  };

  const touchEnd = (e) => {
    const distanceX = Math.abs(touchPosition.x - e.changedTouches[0].pageX);
    const distanceY = Math.abs(touchPosition.y - e.changedTouches[0].pageY);

    if((distanceY + distanceX > 30) && (distanceX > distanceY)) {
      if(touchPosition.x - e.changedTouches[0].pageX < 0 ) {
        if(slideIndex !== 1){
          setSlideIndex(slideIndex - 1);
        }
        else if (slideIndex === 1){
          setSlideIndex(imageListNum);
        }
      }
      else if(touchPosition.x - e.changedTouches[0].pageX > 0 ) {
        
        if(slideIndex !== imageListNum){
          setSlideIndex(slideIndex + 1);
        } 
        else if (slideIndex === imageListNum){
          setSlideIndex(1);
        }
      }
    }
  };

  const handlePropertyColor = (multiple) => {
    const bgColor = multiple === 'True' ? '#e3f3f7'
    : ( multiple === 'False' ? '#f7e1e1' : '#fcf1dc' )
    return bgColor
  }

  const handleGoToPage = (page, id) => {
    scrollToPage(page, id)
    handleClose()
  }


  return (
    <>
      <Wrap>
        <div 
          className="container__slider" 
          onTouchStart={(e) => setTouchPosition({
            x: e.changedTouches[0].pageX,
            y: e.changedTouches[0].pageY
          })}
          onTouchEnd={(e) => touchEnd(e)}
        >
          
          {imageList && imageList.map((data, idx) => {
            return (
              <div 
                key={String(idx)}
                className={slideIndex === idx + 1 ? 'slide active-anim' : 'slide'} 
              >
                {Array.from({ length: data.url_list.length }).map((el, idx) => {
                  return (
                    <ItemWrap key={String(idx)}>
                      <Item alt={`item_img${idx}`} src={`${data.url_list[idx]}`} onClick={() => handleGoToPage(data.page[idx], data.id[idx])} />
                      <Property bgColor={() => handlePropertyColor(data.multiple[idx])}>
                        {
                          data.multiple[idx] === 'True' 
                            ? '객관식' 
                            : (data.multiple[idx] === 'False' ? '주관식' : '연관 지문')
                        }
                      </Property>
                    </ItemWrap>
                  )
                })}
              </div>
            )
          })}

        </div>
          {imageListNum > 1 &&
            <>
              {/* <DesktopTablet> */}
                <div 
                  className="btn__slide next" 
                  onClick={(e) => nextSlide(e)}
                >
                  <img
                    src={rightArrow}
                    alt="rightArrow"
                  />
                </div>
                <div 
                  className="btn__slide prev"
                  onClick={() => prevSlide()}
                >
                  <img
                    src={leftArrow}
                    alt="leftArrow"
                  />
                </div>
              {/* </DesktopTablet> */}

              <div className="container-dots">
                {Array.from({length: imageListNum}).map((item, index) => (
                  <div 
                    key={String(index)}
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                  ></div>
                ))}
              </div>
            </>
          }
      </Wrap>
    </>
  )
})
const ItemWrap = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid red; */
`
const Item = styled.img`
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  cursor: pointer;
`
const Property = styled.div`
  width: fit-content;
  padding: 6px 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background-color: ${props => props.bgColor};
`

export default ContentsCarousel;
