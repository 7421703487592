import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components'
import Icon from "./Icon";
import usePopup from "./usePopup";
// import { Transforms } from "slate";
// import { TableUtil } from "./utils/table.js";

const Table = ({ editor }) => {
  const tableOptionsRef = useRef();
  const [selection, setSelection] = useState();
  const [showOptions, setShowOptions] = usePopup(tableOptionsRef);
  const [tableData, setTableData] = useState({
    row: 0,
    column: 0
  });
  const [tableInput, setTableInput] = useState(
    Array.from({ length: 6 }, () =>
      Array.from({ length: 6 }, (v, i) => ({
        bg: "lightGray",
        column: i
      }))
    )
  );

  useEffect(() => {
    const newTable = Array.from({ length: 6 }, (obj, row) =>
      Array.from({ length: 6 }, (v, col) => ({
        bg:
          row + 1 <= tableData.row && col + 1 <= tableData.column
            ? "orange"
            : "lightgray",
        column: col
      }))
    );
    setTableInput(newTable);
  }, [tableData]);
  // const table = new TableUtil(editor);

  const handleButtonClick = () => {
    // setSelection(editor.selection);
    setShowOptions((prev) => !prev);
  };
  const handleInsert = () => {
    // selection && Transforms.select(editor, selection);
    // setTableData({ row: -1, column: -1 });
    // table.insertTable(tableData.row, tableData.column);
    // setShowOptions(false);
  };
  return (
    <Wrap ref={tableOptionsRef} className="popup-wrapper">
      <button
        style={{ border: showOptions ? "1px solid lightgray" : "none" }}
        title="table"
        onClick={handleButtonClick}
      >
        <Icon icon="table" />
      </button>
      {showOptions && (
        <div className="popup">
          <div className="table_count">
            <i>{`${tableData.row} x ${tableData.column}`}</i>
          </div>
          <div className="table-input">
            {tableInput.map((grp, row) =>
              grp.map(({ column, bg }, index) => (
                <div
                  key={String(index)}
                  // onClick={() => handleInsert()}
                  onMouseOver={() =>
                    setTableData({ row: row + 1, column: column + 1 })
                  }
                  className="table-unit"
                  style={{ border: `1px solid ${bg}` }}
                ></div>
              ))
            )}
          </div>
        </div>
      )}
    </Wrap>
  )
}
const Wrap = styled.div`
  display: inline;
  position: relative;

  .table-option {
    display: flex;
    margin: 5px 2px;
    gap: 5px;
    white-space: nowrap;
  }
  .popup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;
    font-size: 14px;
  }
  button{
    cursor: pointer;
  }
  .table_count {
    padding-bottom: 4px;
  }
  .table-input {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 3px;
  }
  .table-unit {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
  }
`

export default Table;
