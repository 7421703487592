import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../../components/_templates/MainSection'
import Toolbar from '../../../../components/_organisms/mainSection/Toolbar'
import ItemBox from '../../../../components/_molecules/select/ItemBox'
import CancelIcon from '../../../../assets/icons/cancel.svg'
import QuestionPreview from '../../../../components/_organisms/subSection-preview'

import { useQuery, useMutation } from 'react-query'
import { getAPI, postAPI } from '../../../../api'

interface QBProps {
  _service: string
  _question_id: number
}

const QB = ({_question_id}: QBProps) => {

  // ----- subject -----
  const [subject, setSubject] = useState('') // 과목

  // ----- select -----
  const [major, setMajor] = useState(0) // 전공: 0(언어/수학), 1(과학)
  const [grade, setGrade] = useState('M4') // 학년: M4, M5, M6
  const [subSubject, setSubSubject] = useState(null) // 세부과목
  const [section, setSection] = useState(null) // 대단원
  const [subSection, setSubSection] = useState(null) // 중단원

  const majorList = [
    {value: 0, name: '언어, 수학()'},
    {value: 1, name: '과학()'},
  ] // 전공 list
  const gradeList = [
    {value: 'M4', name: 'M4'},
    {value: 'M5', name: 'M5'},
    {value: 'M6', name: 'M6'},
  ] // 학년 list
  const [subSubjectList, setSubSubjectList] = useState([]) // 세부과목 list
  const [sectionList, setSectionList] = useState([]) // 대단원 list
  const [subSectionList, setSubSectionList] = useState([]) // 중단원 list

  // ----- selected sub_subject list -----
  const [selectedSubSectionList, setSelectedSubSectionList] = useState([]) // 과목
  // console.log('selectedSubSectionList: ', selectedSubSectionList)
  const addSelectedSubSectionList = (id: number, name: string) => {
    setSubSection(id)
    const findIsDuplicated = (el) => el.id === id
    const is_duplicated = selectedSubSectionList.some(findIsDuplicated)
    if (is_duplicated) return
    setSelectedSubSectionList([...selectedSubSectionList, {id, name}])
  }
  const deleteSelectSubSectionItem = (id: number) => {
    const new_list = selectedSubSectionList.filter(el => el.id !== id)
    setSelectedSubSectionList(new_list)
  }

  // GET / 문제 정보들 요청(html, images, matched_subject, sub_section_list, ...)
  const { data: Q_data } = useQuery([`question-${_question_id}`, {_question_id}], () => getAPI(`question/${_question_id}/all`, {nation: '[1]'}), {
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (!Q_data) return

    const _subject = Q_data.data.ko.subject
    setSubject(_subject)
  }, [Q_data])
  // =============================================

  // GET / Filter list ===========================
  const { data: F_data } = useQuery([`thai-filter-${subject}_${major}_${grade}`, {subject, major, grade}], () => getAPI(`th/filter`, {subject: subject, major: subject === '수학' ? null : major, grade: subject === '과학' && major === 0 ? null : grade}), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!(subject !== ''),
  })

  useEffect(() => {
    if (!F_data) return
    setSubSubjectList(F_data.data)
  }, [F_data])
  // =============================================

  // GET / 이전에 매칭된 중단원 list
  const { data: sub_section_data } = useQuery([`question-sub_section-${_question_id}`, {_question_id}], () => getAPI(`question/sub-section/${_question_id}`, {nation: '[2]'}), {
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (!sub_section_data) return
    const _sub_section = sub_section_data.data.thai.sub_section.map(el => {return {id: el.sub_section_id, name: el.sub_section_name}})
    setSelectedSubSectionList(_sub_section)
  }, [sub_section_data])
  // =============================================

  // POST / 중단원 저장하기 ==========================
  const { mutate: saveSubsection } = useMutation(postAPI, {
    onSuccess: data => {
      console.log('data:', data)
      if (data.status === 200 && data.data.message === 'success subsection matching') return alert('저장이 완료되었습니다.')
    },
    onError: error => {
      console.log(error)
    }
  })

  const handleSaveSubSection = () => {
    if (selectedSubSectionList.length === 0) {
      if (window.confirm('중단원을 선택하지 않았습니다. 그래도 저장하시겠습니까?') === false) return
    }

    const selectedSubSectionIdList = selectedSubSectionList.map(el => el.id)
    const body = {
      question_id: _question_id,
      sub_section_id: selectedSubSectionIdList.length === 0 ? null : selectedSubSectionIdList,
    }
    let data = {
      apiURL: `/th/match`,
      body: body
    }
    saveSubsection(data)
  }
  // =============================================

  useEffect(() => {
    setSubSubject(null)
    setSection(null)
    setSubSection(null)
    setSubSubjectList([])
    setSectionList([])
    setSubSectionList([])
  }, [major])

  useEffect(() => {
    setSubSubject(null)
    setSection(null)
    setSubSection(null)
    // setSubSubjectList([])
    setSectionList([])
    setSubSectionList([])
  }, [grade])

  useEffect(() => {
    if (subSubjectList.length !== 0 && subSubject !== null) {
      const list = subSubjectList.filter(el => el.sub_subject_id === subSubject)[0].section
      setSectionList(list)

      setSection(null)
      setSubSection(null)
      setSubSectionList([])
    }
  }, [subSubject, subSubjectList.length])

  useEffect(() => {
    if (sectionList.length !== 0 && section !== null) {
      const list = sectionList.filter(el => el.section_id === section)[0].sub_section
      setSubSectionList(list)

      setSubSection(null)
    }
  }, [section, sectionList.length])
  console.log(subject, major)


  return (
    <MainSection>
      <>
        <Toolbar title={`매칭 과목 - ${subject}`} isSave save={handleSaveSubSection} />
        <FilterWrap>
          <SelectBox>
            <Select disabled={subject === '수학'}>
              <LabelBox>전공</LabelBox>
              <ItemBox>
                {subject !== '수학' &&
                  <>
                    {majorList.map(el => 
                      <button key={el.value} onClick={() => setMajor(el.value)} className={[
                        'item',
                        major === el.value && 'active'
                        ].join(' ')} type='button'
                      >{el.name}</button>
                    )}
                  </>
                }
              </ItemBox>
            </Select>
            <Select disabled={subject === '과학' && major === 0}>
              <LabelBox>학년</LabelBox>
              <ItemBox>
                {!(subject === '과학' && major === 0) &&
                  <>
                    {gradeList.map(el => 
                      <button key={el.value} onClick={() => setGrade(el.value)} className={[
                        'item',
                        grade === el.value && 'active'
                        ].join(' ')} type='button'
                      >{el.name}</button>
                    )}
                  </>
                }
              </ItemBox>
            </Select>
            <Select>
              <LabelBox>세부과목</LabelBox>
              <ItemBox>
                <>
                  {subSubjectList.map(el => 
                    <button key={el.sub_subject_id} onClick={() => setSubSubject(el.sub_subject_id)} className={[
                      'item',
                      subSubject === el.sub_subject_id && 'active'
                      ].join(' ')} type='button'
                    >{el.sub_subject_name}</button>
                  )}
                </>
              </ItemBox>
            </Select>
            <Select>
              <LabelBox>대단원</LabelBox>
              <ItemBox align={'flex-start'}>
                <>
                  {sectionList.map(el => 
                    <button key={el.section_id} onClick={() => setSection(el.section_id)} className={[
                      'item',
                      section === el.section_id && 'active'
                      ].join(' ')} type='button'
                    >{el.section_name}</button>
                  )}
                </>
              </ItemBox>
            </Select>
            <Select>
              <LabelBox>중단원</LabelBox>
              <ItemBox align={'flex-start'}>
                <>
                  {subSectionList.map(el => {
                    return (<button key={el.sub_section_id} onClick={() => addSelectedSubSectionList(el.sub_section_id, el.sub_section_name)} className={[
                      'item',
                      subSection === el.sub_section_id && 'active'
                      ].join(' ')} type='button'
                    >{el.sub_section_name}</button>)}
                  )}
                </>
              </ItemBox>
            </Select>
          </SelectBox>
          <SubSectionBox>
            <SubSectionList>
              {selectedSubSectionList.length === 0 
                ? <Empty>없음</Empty>
                : selectedSubSectionList.map((el, idx) => {
                  return (
                    <SubSectionItemBox key={el.id}>
                      <SubSectionItemText>
                        {el.name}
                      </SubSectionItemText>
                      <SubSectionItemDeleteIcon alt='CancelIcon' src={CancelIcon} onClick={() => deleteSelectSubSectionItem(el.id)} />
                    </SubSectionItemBox>
                  )
                })
              }
            </SubSectionList>
          </SubSectionBox>
        </FilterWrap>
        
{/* Preview */}
        {Q_data && <QuestionPreview Q_data={Q_data && Q_data.data} />}
        
      </>
    </MainSection>
  )
}

const SelectBox = styled.div`
  padding: 0.5rem 0 0;
  display: grid;
  column-gap: 0.2rem;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.08); */
  /* border: 1px solid orange; */
`
const Select = styled.div`
  box-sizing: border-box;
  color: #333;
  font-weight: 500;
  border-radius: 4px;
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  min-width: 3rem;
`
const LabelBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const FilterWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 12rem;
  gap: 0.5rem;
`
const SubSectionBox = styled.div`
  padding: 0.5rem 0 0;
`
const SubSectionList = styled.div`
  padding: 0.2rem;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`
const SubSectionItemBox = styled.div`
  padding: 0.3rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1rem;
  gap: 0.5rem;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`
const SubSectionItemText = styled.div`
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  /* border: 1px solid blue; */
`
const SubSectionItemDeleteIcon = styled.img`
  width: 0.9rem;
  height: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const Empty = styled.div`
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default QB