import Tr from './atoms/Tr'
import Th from './atoms/Th'
import Td from './atoms/Td'

type GrammarTag = {
  grammar_chapter_name: string
  grammar_child_unit_id: number
  grammar_child_unit_name: string
  grammar_parent_unit_name: string
}

export const Body = ({ items, selected, onClick, searchedKeyword }: { items: GrammarTag[], selected: GrammarTag | null, onClick: (grammar: GrammarTag)=>void, searchedKeyword: string }) => {
  return (
    <>
      {items.map((el: GrammarTag, index: number) => {
        const isSelected = selected?.grammar_child_unit_id === el.grammar_child_unit_id
        return (
          <Tr 
            key={el.grammar_child_unit_id} 
            disabled
            isSelected={isSelected}
            onClick={() => onClick(el)}
          >
            <Td>{el.grammar_child_unit_id}</Td>
            <Td align='left'
              selected={!!(searchedKeyword && !isSelected && el.grammar_chapter_name.includes(searchedKeyword))}
            >{el.grammar_chapter_name}</Td>
            <Td align='left'
              selected={!!(searchedKeyword && !isSelected && el.grammar_parent_unit_name.includes(searchedKeyword))}
            >{el.grammar_parent_unit_name}</Td>
            <Td align='left' 
              selected={!!(searchedKeyword && !isSelected && el.grammar_child_unit_name.includes(searchedKeyword))}
            >{el.grammar_child_unit_name}</Td>
          </Tr>
        )
      })}
    </>
  )
}

export const Col = (data: string[]) => 
  <>{data.map((width: string, index: number) => <col key={String(index)} span={1} width={width} />)}</>

export const Head = (data: string[]) => 
  <>{data.map((th: any, index: number) => <Th key={String(index)}>{th}</Th>)}</>