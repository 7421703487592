import { completion, dataToHtmlSelect, ce, uuidv4 } from "../../../utils/EditorUtils"

export const initAllStore = {
  multiple_data: [
    [" ", ""],
    ["①", ""],
    ["②", ""],
    ["③", ""],
    ["④", ""],
    ["⑤", ""],
  ],
  multiple_html: "",
  multiple_answer: [],
  multiple_answer_raw: [],
  essay_answer: [""],
  multiple_answer_html: '',  // 답:
  essay_answer_html: '',  // 답:

  order: 1,
  score: 0,
  level: 0,
  question_error: 0,
  explanation_exist: false,

  imageList: [],
  
  data: [{ id: uuidv4(), type: "일반", text: "", html: "" }],
  data_explanation: [{ id: uuidv4(), type: "일반", text: "", html: "" }],
  data_relation: [{ id: uuidv4(), type: "일반", text: "", html: "" }],
}

export const DToM2 = (j) => {
  return j
    .map(({ type, text }) => {
      switch (type) {
        case "일반":
          return "```basic\n" + text + "\n```"
        case "선":
          return "```border\n" + text + "\n```"
        case "":
          return "```border\n" + text + "\n```"
        case "보기":
          return "```choice\n" + text + "\n```"
        case "예시":
          return "```example\n" + text + "\n```"
        case "표":
          return "```table\n" + text + "\n```"
        default:
          return ""
      }
    })
    .join("\n")
}