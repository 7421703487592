import { Axios } from './api.config'

// 학년별 불러오는 과목 API
// export const requestSubjectList = async () => {
//   return await Axios.get("subsections", { params: { id } })
// }

// 학년별 불러오는 과목 API
// export const requestSubjectList = async () => {
//   return await Axios.get("search/subjects")
// }

export const requestRefreshToken = async (fetchAPI, ) => {
  return await fetchAPI()
}

// 라벨러 순위 API
export const getLabelersRankAPI = async () => {
  return await Axios.get('labelers/rank')
}
// const accessToken = localStorage.getItem("access_token")

// import { useQuery } from 'react-query'
// const { data, isSuccess, mutate } = useQuery('PDFList', getPDFListAPI)

// --------------------  라벨러  --------------------

// 공지사항
export const requestNoticeList = async (el) => {
  const path = el.queryKey[1]
  console.log(path)
  const response = await Axios.get(`notice/${path}`)
  return response.data
}

// 나의 작업 공간 - 1, 3차: pdf 리스트
export const getPdfListAPI = async (project_id, page, filter) => {
  return await Axios.get(`labeler-pdf/${project_id}?page=${page}&subject=${filter}`)
}

// 나의 작업 공간 - 1, 3차: 문제 리스트
export const getQuestionListAPI = async (verified_id) => {
  return await Axios.get(`questions/${verified_id}`)
}

// 나의 작업 공간 - 1, 3차: 이미지 URL
export const getUrlListAPI = async (pdf_id) => {
  return await Axios.get(`labeling/url/${pdf_id}`)
}

// 나의 작업 공간 - 2차: pdf 리스트
export const getPdfList02API = async (project_id, page, filter) => {
  return await Axios.get(`done-pdf/${project_id}?page=${page}&subject=${filter}`)
}

// 나의 작업 공간 - 2차: 문제 리스트
export const getQuestionList02API = async (donepdf_id) => {
  return await Axios.get(`questions/check/${donepdf_id}`)
}

// 나의 작업 상세 확인 - 작업 현황: 프로젝트 리스트
// export const getMyProjectListAPI = async (project_id) => {
//   return await Axios.get(`project/result/${project_id}`, {
//     headers: { Authorization: accessToken },
//   })
// }

// 나의 작업 상세 확인 - 상세 작업 현황
export const getMyProjectDetailAPI = async (path_bool, path_data) => {
  // console.log(path_bool, path_data)
  const path = path_bool
    ? `project/result/${path_data.project_id}?labeler_id=${path_data.labeler_id}`
    : `project/result/${path_data.project_id}`
  return await Axios.get(path)
}

// 마이 페이지 - 정보 가져오기
export const getMyPageAPI = async () => {
  return await Axios.get(`account/mypage`)
}

// 사이드바 - 알림
export const getSideBarAlarmAPI = async () => {
  return await Axios.get(`question/wrong`)
}


// --------------------  어드민  --------------------

// 대시보드 - 공지사항 리스트
export const getNoticeListAPI = async (page) => {
  return await Axios.get(`notice/${page}`)
}

// 대시보드 - 진행 중, 대기 중 리스트
export const getLabelingListAPI = async () => {
  return await Axios.get(`project/home`)
}

// 프로젝트 리스트
export const getProjectListAPI = async (page) => {
  return await Axios.get(`project?page=${page}`)
}

// 프로젝트 생성 - PDF 지정
export const getPDFListAPI = async () => {
  return await Axios.get(`project/info`)
}

// 프로젝트 생성 - 매칭 가능한 라벨러 리스트
export const getLabelerListAPI = async () => {
  return await Axios.get(`project/labeler`)
}

// 프로젝트 수정 - 기존 프로젝트 데이터
export const getProjectInfoAPI = async (id_list) => {
  // console.log(`[${id_list}]`)
  return await Axios.get(`project/detail?project_id=[${id_list}]`)
}

// 프로젝트
  // 할당/부여 - pdf 불러오기
export const getPdfAPI = async (page, project_id, subject=null) => {
  return await Axios.get(`project/admin-pdf/${project_id}?page=${page}/subject=${subject}`)
}

  // 할당/부여 - labeler 불러오기
export const getLabelerAPI = async (project_id) => {
  return await Axios.get(`project/labeler/${project_id}`)
}

  // 할당/부여 - filter 불러오기
export const getFilterAPI = async (subject_class) => {
  return await Axios.get(`project/filter?subject_class=${subject_class}`)
}

// 할당/부여 - 해당 라벨러에 할당된 모든 PDF 리스트
// export const getAllPdfListAPI = async (subject_class) => {
//   return await Axios.get(`project/give?subject_class=${subject_class}`, {
//     headers: { Authorization: accessToken },
//   })
// }

// 라벨러 관리
  // 승인/수정 리스트
export const getNewOldLabelerListAPI = async (page1, page2) => {
  return await Axios.get(`project/labeler-list?page1=${page1}&page2=${page2}`)
}

// 라벨러 관리
  // 승인/수정 - 신규 가입자 리스트
export const getNewLabelerListAPI = async (page) => {
  return await Axios.get(`project/labeler-list/unsigned?page=${page}`)
}

// 라벨러 관리
  // 승인/수정 - 기존 가입자 리스트
  export const getOldLabelerListAPI = async (page) => {
    return await Axios.get(`project/labeler-list/signed?page=${page}`)
  }

  // 라벨러 정보
export const getLabelerInfoAPI = async (labeler_id) => {
  return await Axios.get(`project/labeler-detail/${labeler_id}`)
}

  // 작업 현황 리스트
export const getWorkStateListAPI = async (page) => {
  return await Axios.get(`project/all?page=${page}`)
}

  // 나의 게시글 리스트
  export const getPostListAPI = async (page) => {
    return await Axios.get(`project/all?page=${page}`)
  }

  // 게시글 - 제목, 내용
  export const getPostContentAPI = async (page) => {
    // return await Axios.get(`project/all?page=${page}`)
    return await Axios.get(`project/all`,{page: page})
  }

  // 게시글 - 채팅 메시지
  export const getPostMessageAPI = async (page) => {
    return await Axios.get(`project/all?page=${page}`)
  }

  // 문제 관리(기출) - filter
  export const getQuestionFilterAPI = async (grade) => {
    return await Axios.get(`project/filter/subsection?subject_class=${grade}`)
  }

  // 문제 관리(문제집) - filter
  export const getBooksFilterAPI = async (grade) => {
    return await Axios.get(`books/project/filter/subsection?subject_class=${grade}`)
  }

  // --------------------  태국  --------------------

  // 문제 관리 - filter
  export const getThQuestionFilterAPI = async (grade) => {
    return await Axios.get(`thai/filter/subsection?subject_class=${grade}`)
  }
  
  // 번역 - 중단원 list
  export const getThMidUnitAPI = async (grade) => {
    return await Axios.get(`thai/subsection`)
  }

  // 번역 - 중단원 선택 결과 list
  export const getThMidUnitListAPI = async (page, mid_unit_id) => {
    return await Axios.get(`thai/subsection/${mid_unit_id}?page=${page}`)
  }


  export const getAPI = async (apiURL: string, params?: any) => {
    console.log(apiURL)
    return await Axios.get(apiURL, {params})
  }

  export const postAPI = async (data: any) => {
    console.log(data)
    return await Axios.post(data.apiURL, data.body)
  }

  // export const getParamsSerializerAPI = async (apiURL: string, params?: any) => {
  //   console.log(apiURL, params)
  //   return await Axios.get(apiURL, {params: params, paramsSerializer: (params) => {
  //     return 
  //   }})
  // }