import { memo } from 'react'
import * as S from '../../style/EditorQuestionInfoStyle'
import { useDispatch, useSelector } from 'react-redux'
import MultipleChoice from './MultipleChoice'
import EssayQuestion from './EssayQuestion'
import CommonInfo from './CommonInfo'
import Accordion from '../../../_molecules/Accordion'
import { actionCreators as writeEditorActions } from "../../../../redux/modules/writeEditor";

const EditorQuestionInfo = () => {
  const dispatch = useDispatch()
  const nation = localStorage.getItem('nation')
  const writeEditor = useSelector((state) => state.writeEditor)
  const {
    is_multiple,
    difficulty,
    is_explanation,
  } = writeEditor
  
  const handleDifficulty = (v) => {
    dispatch(writeEditorActions.set_difficulty(v))
  }

  const difficultyItemList = [
    {value: 3, label: '최상'},
    {value: 2, label: '상'},
    {value: 1, label: '중'},
    {value: 0, label: '하'},
  ]

  return (
    <S.Container>
      <S.MoEWrap>
        <S.Main>
          {is_multiple === 1 &&
            <MultipleChoice />  // 객관식
          }
          {is_multiple === 0 &&
            <EssayQuestion />  // 주관식
          }
          {nation !== '3' &&
            // <CommonInfo />
            <Accordion name={'난이도'} value={difficulty} onchange={handleDifficulty} itemList={difficultyItemList} />
          }
        </S.Main>
      </S.MoEWrap>
    </S.Container>
  )
}

export default memo(EditorQuestionInfo)