"use client"

import styles from './modal.module.scss'
import searchIcon from './search.svg'
import Button from './button'
import SearchingGrammarTable from './table'
import useGetGrammarsForSearching from './useGetGrammarsForSearching'

type GrammarTag = {
  grammar_chapter_name: string
  grammar_child_unit_id: number
  grammar_child_unit_name: string
  grammar_parent_unit_name: string
}

const SearchingGrammar = ({
  keyword,
  searchedKeyword,
  selected,
  handleKeyword,
  handleSelect,
  onSearch,
  onComplete,
  onClose,
}: {
  keyword: string
  searchedKeyword: string
  selected: GrammarTag | null
  handleKeyword: (e: React.ChangeEvent<HTMLInputElement>)=>void
  handleSelect: (grammar: GrammarTag)=>void
  onSearch: ()=>void
  onComplete: ()=>void
  onClose: ()=>void
}) => {
  const { data: grammarData } = useGetGrammarsForSearching(searchedKeyword)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') onSearch()
  }

  return (
    <div className={`${styles['searching']} ${styles['searching-idiom']}`}>
      <div className={styles['searching-input']}>
        <input 
          type='text'
          value={keyword} 
          placeholder='문법을 입력해 주세요'
          onChange={handleKeyword} 
          onKeyDown={handleKeyDown}
        />
        <button type='button' disabled className={styles.search_icon_wrapper} onClick={onSearch}>
          <img src={searchIcon} width={17} height={17} alt='search-input' />
        </button>
      </div>
      <SearchingGrammarTable 
        searchedKeyword={searchedKeyword}
        contents={grammarData?.data ? grammarData.data : []} 
        selected={selected}
        handleClickRaw={handleSelect} 
      />
      <div className={styles['searching-buttons']}>
        <Button 
          type='empty'
          onClick={onClose} 
          style={{width: '7rem', height: '1.5rem', fontSize: '13px'}}
        >
          나가기
        </Button>
        <Button 
          type={selected ? 'normal' : 'disabled'}
          onClick={onComplete} 
          style={{width: '15rem', height: '1.5rem', fontSize: '13px'}}
        >
          선택하기
        </Button>
      </div>
    </div>
  );
};

export default SearchingGrammar;