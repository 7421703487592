import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import * as S from './SelectBox.style'
import { useQuery } from 'react-query'
import { getPDFListAPI } from '../../api/getAPI' 
import select_arrow from '../../assets/icons/select/select_arrow.png'

function makeRangeList(start, end, diff = 1, reverse = false) {
  const list = []
  if (reverse) {
    for (let i = end; i >= start; i -= diff) {
      list.push(i)
    }
  } else {
    for (let i = start; i <= end; i += diff) {
      list.push(i)
    }
  }
  return list
}

const roleList = [
  { value: '라벨러' },
  { value: '관리자' },
]
const groupList = [
  { value: '프리딕션' },
  { value: '개념원리' },
  { value: '지학사' },
]
const nationList = [
  { value: '거부' },
  { value: '모두 가능' },
  { value: '한국' },
  { value: '태국' },
]
const approvalList = [
  { value: '승인' },
  { value: '거부' },
]

const SelectBox = forwardRef(function SelectBox(props, ref) {
  const { type, _value, width, height, setPDFid, setState, disabled, zeroValue = 0 } = props
  const [ pdfState ] = useState(type === 'qb_pdf' || type === 'books_pdf' ? true : false)
  const { data, isSuccess } = useQuery('PDFList', getPDFListAPI, {enabled: !!(pdfState)})
  console.log(isSuccess && data)
  console.log(type, zeroValue)
  const qbPdfList = isSuccess && data.data.qb.map(el => {
    return { id: el.info_id, value: el.info_name, count: el.count }
  })
  const booksPdfList = isSuccess && data.data.books.map(el => {
    return { id: el.book_id, value: el.books_name, count: el.count }
  })
  const thaiPdfList = isSuccess && data.data.thai && data.data.thai.map(el => {
    return { id: el.id, value: el.name, count: el.count }
  })
  
  const [ pdfQbCount, setPdfQbCount ] = useState(null)
  const [ pdfBookCount, setPdfBookCount ] = useState(null)
  const [ pdfCount, setPdfCount ] = useState(null)
  const [ value, setValue ] = useState(_value || '')
  const handleStore = () => {
    setValue('모두 가능')
  }

  useImperativeHandle(ref, () => ({ handleStore }))

  const date = new Date()
  const year = date.getFullYear()
  const month = makeRangeList(1, 12)
  const lastDate = new Date(date.getFullYear() + 1, date.getMonth() + 1, 0).getDate()
  
  const yearList = [
    { value: year + 1 },
    { value: year },
  ]
  const monthList = month.map(el => {
    return { value: el }
  })
  const dateList = Array.from({ length: lastDate }).map((el, idx) => {
    return { value: idx + 1 }
  })

  const handleValue = (e) => {
    setValue(e.target.value)
    
    if (type === 'qb_pdf' || type === 'books_pdf' || type === 'thai_pdf') {
      const count = selectedList.find(el => el.value === e.target.value).count
      const id = selectedList.find(el => el.value === e.target.value).id
      
      setPdfCount(count)
      setPDFid(id)
    } else {
      setState(e.target.value)
    }
  }

  const handleType = () => {
    switch(type) {
      case 'role':
        return roleList
      case 'group':
        return groupList
      case 'nation':
        return nationList
      case 'approval':
        return approvalList
      case 'year':
        return yearList
      case 'month':
        return monthList
      case 'date':
        return dateList
      case 'qb_pdf':
        return qbPdfList
      case 'books_pdf':
        return booksPdfList
      case 'thai_pdf':
        return thaiPdfList
      default:
        break
    }
  }
  const selectedList = handleType()

  useEffect(() => {
    if (zeroValue === null) setValue('')
  }, [zeroValue])

  return (
    <S.Wrap>
      <S.Select value={value} width={width} height={height} disabled={disabled} onChange={handleValue}>
        <option value={''} disabled>선택</option>
        { selectedList && selectedList.map((el, idx) => {
          return <option key={el.value} value={el.value} name={el.count} data-count={el.count} >{el.value}</option>
        })}
      </S.Select>
      <img className="select_arrow" alt="select_arrow" src={select_arrow} />
      {(type === 'qb_pdf' || type === 'books_pdf' || type === 'thai_pdf') && pdfCount !== null && zeroValue &&
        <S.PDFCount><b style={{color: '#2e6ef2', fontSize: '16px'}} >{pdfCount}</b> 부의 PDF가 있습니다.</S.PDFCount>
      }
    </S.Wrap>
  )
})

export default SelectBox