import styled, { css } from 'styled-components'

const Content = styled.div`
  cursor: ${props => props.focus ? 'pointer' : 'default'};
  box-sizing: border-box;
  padding: ${props => props.padding ? props.padding : '0 14px'};
  margin: ${props => props.margin ? props.margin : '0'};
  width: ${props => props.width ? props.width : 'fit-content'};
  min-width: fit-content;
  height: ${props => props.height ? props.height : '40px'};
  color: ${props => props.color ? props.color : 'white'};
  font-size: ${props => props.fontSize ? props.fontSize : '14px'};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 700};
  border-radius: 5px;
  border: ${props => props.border ? props.border : 'none' };
  background-color: ${props => props.focus ? '#2e6ef2' : '#a8c5ff' };
  background-color: ${props => props.bgColor};
  position: ${props => props.position ? props.position : ''};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;

  ${props => props.focus && css`
    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      transition: all 0.12s ease;
    }
  `}
  ${props => props.del && css`
    &:hover {
      color: white;
      background-color: #F20505;
    }
  `}
`

const Button = (props) => {
  const { children, onclick, focus=true, del, color, bgColor, border, padding, margin, width, height, position, fontSize, fontWeight } = props

  return (
    <Content
      focus={focus}
      onClick={onclick}
      del={del}
      color={color}
      border={border}
      bgColor={bgColor}
      padding={padding}
      margin={margin}
      position={position}
      width={width}
      height={height}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </Content>
  )
}

export default Button