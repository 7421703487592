import { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination'
import { actionCreators as writeEditorActions } from "../../../redux/modules/imageEditor";
import { actionCreators as middleUnitActions } from "../../../redux/modules/imageEditor";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;



const MidUnitList = forwardRef(function MidUnitList(props, ref) {
  const { Q_data, Q_count, Q_page, getPageNum, workType } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ page, setPage ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(50)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    getPageNum(newPage + 1)
  }
  const handlePage = () => {
    setPage(Q_page - 1)
  }
  const handleInitPage = () => {
    setPage(0)
  }

  useImperativeHandle(ref, () => ({ handlePage, handleInitPage }))

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const moveToFirst = async (question_id) => {
    if (workType === '1') navigate(`/workspace/project/labeling/content/question/${question_id}/correcting`)
    if (workType === '2') navigate(`/workspace/project/labeling/content/related_text/${question_id}/correcting`)
  }

  const moveToSecond = async (id) => {
    navigate(`/workspace/project/review/content/question/${id}`)
  }

  return (
    <Wrap style={{position: 'relative'}}>
      <TableContainer component={Paper} sx={{ height: '100%' }} >  
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            {workType === '1' 
              ? (
                <TableRow>
                  <Cell align="center">No.</Cell>
                  <Cell align="center">문제 번호</Cell>
                  <Cell align="center">오류 여부</Cell>
                  <Cell align="center">1/2차</Cell>
                  <Cell align="center">문제</Cell>
                  <Cell align="center"></Cell>
                  <Cell align="center"></Cell>
                </TableRow>
              )
              : (
                <TableRow>
                  <Cell align="center">No.</Cell>
                  <Cell align="center">지문 번호</Cell>
                  <Cell align="center">작성 여부(라벨러/검수자)</Cell>
                  <Cell align="center">라벨러/검수자</Cell>
                  <Cell align="center">지문</Cell>
                  <Cell align="center">연관 문제</Cell>
                  <Cell align="center"></Cell>
                </TableRow>
              )
            }
            
          </TableHead>
          <TableBody>
            {Q_data.length > 0 && Q_data.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              return (
                <Row
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {workType === '1'  // 문제/해설
                    ? (
                      <>
                        <Cell align="center">{no}</Cell>
                        <Cell align="center">{row.id}</Cell>
                        <Cell align="center">
                          {row.question_error === 0 && <Circle bgColor={'#66CC33'} />}
                          {row.question_error === 1 && <Circle bgColor={'red'} />}
                          {row.question_error === 2 && <Circle bgColor={'orange'} />}
                          {row.question_error === 3 && <Circle bgColor={'#66CC33'} />}
                        </Cell>
                        <Cell align="center">{row.labeler}</Cell>
                        <Cell align="left" 
                          style={{
                            maxWidth: '20rem',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBread: 'break-all'
                          }}
                        >
                          {row.image_url}
                        </Cell>
                        <Cell align="center">
                          <Btn onClick={() => moveToFirst(row.id)} >
                            1차
                          </Btn>
                        </Cell>
                        <Cell align="center">
                          <Btn onClick={() => moveToSecond(row.id)} >
                            2차
                          </Btn>
                        </Cell>
                      </>
                    )
                    : (
                      <>
                        <Cell align="center">{no}</Cell>
                        <Cell align="center">{row.id}</Cell>
                        <Cell align="center">
                          <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50px', margin: '0 auto'}}>
                            {row.labeling_status === 0 ? <Circle bgColor={'red'} /> : <Circle bgColor={'#66CC33'} />} / {row.review_status === 0 ? <Circle bgColor={'red'} /> : <Circle bgColor={'#66CC33'} />}
                          </Box>
                        </Cell>
                        <Cell align="center">{row.labeler} / {row.reviewer}</Cell>
                        <Cell align="left"
                          style={{
                            maxWidth: '20rem',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBread: 'break-all'
                          }}
                        >{row.name}</Cell>
                        <Cell align="center">{row.question_ids?.join(" / ")}</Cell>
                        <Cell align="center">
                          <Btn onClick={() => moveToFirst(row.id)} >
                            문제 보기
                          </Btn>
                        </Cell>
                      </>
                    )
                  }
                </Row>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{overflow: 'hidden'}}
        rowsPerPageOptions={[50]}
        colSpan={9}
        count={Q_count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Wrap>
  );
})

const Btn = styled.div`
  padding: 7px 1.4rem;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    font-weight: 600;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`

const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor};
`

export default MidUnitList