import React, {useState, useEffect} from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import styled, {css} from 'styled-components'

interface SidebarButtonProps {
  children: JSX.Element;
  item: any;
  activeMenu: number;
  activeSubMenu: number;
  setActiveMenu: React.Dispatch<React.SetStateAction<number>>;
  setActiveSubMenu: React.Dispatch<React.SetStateAction<number>>;
}

const SidebarButton = ({children, item, activeMenu, activeSubMenu, setActiveMenu, setActiveSubMenu}: SidebarButtonProps) => {
  const navigate = useNavigate()
  const isSubMenu = !!item.subMenu
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(true)

  const setSubMenuHeight = () => {
    const panel: HTMLElement | null = document.getElementById(`panel-${item.value}`)
    if (!panel) return
      else if (panel?.style.maxHeight) panel.setAttribute("style", "maxHeight: null;")
      else panel.style.maxHeight = panel.scrollHeight + 'px'
  }
  const movePage = (path: string, query?: any) => {
    // router.push({
    //   pathname: `/${path}`,
    //   query: query
    // })
    navigate({
      pathname: `/th/${path}`,
      search: `?${createSearchParams(query)}`
    })
  }
  const onClickTab = () => {
    if (!isSubMenu) {
      movePage(item.path)
      setActiveMenu(item.value)
      setActiveSubMenu(0)
    } else {
      setSubMenuHeight()
      setIsOpenSubMenu(!isOpenSubMenu)
    }
  }
  const onClickSubTab = (v: number, path: string, query: any) => {
    movePage(path, query)
    setActiveMenu(item.value)
    setActiveSubMenu(v)
  }

  useEffect(()=>{
    setSubMenuHeight()
  }, [])
  

  return (
    <>
      <Button value={item.value} active={activeMenu === item.value} isSubMenu={isSubMenu} onClick={onClickTab}>
        <span className='icon'>{item.icon}</span>
        <span>{children}</span>
        {isSubMenu &&
          <span className='nav-arrow'>
            <div>
              {isOpenSubMenu
                ? <svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M6 15l6-6 6 6" stroke="#bcbcbc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                : <svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M6 9l6 6 6-6" stroke="#bcbcbc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              }
            </div>
          </span>
        }
      </Button>

      {isSubMenu && 
        <SubMenu id={`panel-${item.value}`} isOpenSubMenu={isOpenSubMenu}>
          {item.subMenu.map(el => {
            return (
              <SubButton key={el.value} active={activeSubMenu === el.value} onClick={()=>onClickSubTab(el.value, el.path, el.query)}>{el.name}</SubButton>
            )
          })}
        </SubMenu>
      }
    </>
  )
}

export default SidebarButton

const Button = styled.button`
  color: #bcbcbc;
  padding: 0.75rem 1rem;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 2rem;
  }
  .nav-arrow {
    margin-left: auto; // item을 우측으로 정렬
  }

  :hover {
    background-color: ${(props) => props.isSubMenu ? 'transparent' : "#f6f6f6"};
  }

  ${(props)=>props.active && css`
    color: #232323;
    :hover {
      background-color: transparent;
    }
  `}
`
const SubMenu = styled.div`
  overflow: hidden;
  transition: all 0.17s ease-in-out;
  max-height: 0;
  display: flex;
  flex-direction: column;
`
const SubButton = styled.button`
  border-radius: 5px;
  color: #c0c0c0;
  font-size: 15px;
  padding: 0.5rem;
  padding-left: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: #f6f6f6;
  }

  ${(props)=>props.active && css`
    color: #232323;
    font-size: 15.5px;
    font-weight: 500;
  `}
`