import { useEffect, useState, useRef, memo } from 'react'
import styled from 'styled-components'
import {
  Box,
  Grid,
  Paper,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useQuery, useMutation } from 'react-query'
import { getAPI, postAPI } from '../../../api' 
import Button from '../../../elements/button/Button'
import SubSectionList from '../../../components/admin/project/SubSectionList'
import SubSectionLabelerList from '../../../components/admin/project/SubSectionLabelerList'

const WorkTypeMatchingPage = memo((props) => {
  const storeRef = useRef()
  const [ page, setPage ] = useState(1)
  const [ pageLabeler, setPageLabeler ] = useState(1)
  const [ totalCount, setTotalCount ] = useState(0)
  const [ totalCountLabeler, setTotalCountLabeler ] = useState(0)

  const getPageNum = (page) => {
    setPage(page)
    setOnSearch(true)
  }

  const getPageNumLabeler = (page) => {
    setPageLabeler(page)
    setOnSearch(true)
  }

  // filter - 기출/북스, 해설/지문
  const [service, setService] = useState('qb') // 1: qb, 2: books
  const [workType, setWorkType] = useState('1') // 1: 해설, 2: 지문

  // filter - 학년, 세부과목, 출판사, 대단원
  const [ grade, setGrade ] = useState('')
  const [ subSubject, setSubSubject ] = useState('')
  const [ publisher, setPublisher ] = useState('')
  const [ section, setSection ] = useState('')

  // filter - grade(학년)
  const { data: grade_list } = useQuery([`qb/grades`], () => getAPI(`qb/grades`), {
    select: data => data.data,
    onSuccess: data => {
      // console.log(data)
    }
  })

  // filter - subSubject(세부과목)
  const { data: sub_subject_list } = useQuery([`${grade}/sub-subjects`, grade], () => getAPI(`qb/${grade}/sub-subjects`, {publisher: true}), {
    enabled: !!(grade),
    select: data => data.data,
    onSuccess: data => {
      // console.log(data)
    }
  })

  // filter - section(대단원)
  const { data: section_list } = useQuery([`${subSubject}/sections`, subSubject, publisher], () => getAPI(`qb/${subSubject}/sections`, {publisher_id: publisher ? publisher : '0'}), {
    enabled: !!(subSubject),
    select: data => data.data,
    onSuccess: data => {
      // console.log(data)
    }
  })
  
  // 필터 리셋 - 학년 선택 시
  useEffect(()=>{
    if (!grade) return
    setSubSubject('')
    setPublisher('')
    setSection('')
  }, [grade])
  
  // 필터 리셋 - 세부과목 선택 시
  useEffect(()=>{
    if (!subSubject) return
    setPublisher('')
    setSection('')
  }, [subSubject])
  
  // 필터 리셋 - 출판사 선택 시
  useEffect(()=>{
    if (!publisher) return
    setSection('')
  }, [publisher])

  // 검색 클릭
  const handleSearch = () => {
    if (onSearch) return
    setOnSearch(true)
    setSelectedSubSections([])
    setSelectedLabelers([])
  }

  const [onSearch, setOnSearch] = useState(false) // 검색 트리거
  const [subSections, setSubSections] = useState([]) // 검색 결과 - 중단원 list
  const [labelers, setLabelers] = useState([]) // 검색 결과 - 라벨러 list

  // GET - 할당 가능한 중단원 list
  const { data: sub_section_list } = useQuery([`distribution/${section}/sub-sections`, onSearch, page], () => getAPI(`distribution/${section}/sub-sections`, {page: page, service: service, type: workType}), {
    enabled: !!(onSearch && section),
    select: data => data.data,
    onSuccess: data => {
      console.log(data)
      if (data.page.total_count === 0) alert('해당 데이터가 존재하지 않습니다')
      setTotalCount(data.page.total_count)
      setSubSections(data.sub_sections)
      setOnSearch(false)
    },
    onError: error => {
      console.log(error)
      setOnSearch(false)
    }
  })

  // GET - 라벨러 list
  const { data: labeler_list } = useQuery([`labelers-${workType}`, onSearch, pageLabeler], () => getAPI(`labelers`, {page: pageLabeler, type: workType}), {
    enabled: !!(onSearch),
    select: data => data.data,
    onSuccess: data => {
      console.log(data)
      setTotalCountLabeler(data.page.total_count)
      setLabelers(data.labelers)
    },
    onError: error => {
      console.log(error)
    }
  })

  const [selectedSubSections, setSelectedSubSections] = useState([]) // 선택된 중단원들 - [id, id, ...]
  const [selectedLabelers, setSelectedLabelers] = useState([]) // 선택된 라벨러들 - [id, id, ...]

  const {mutate: POST_API} = useMutation(postAPI)

  // 라벨러 할당 클릭
  const handleDistributeLabeler = () => {
    if (selectedSubSections.length === 0) return alert('중단원을 선택해 주세요')
    if (selectedLabelers.length === 0) return alert('라벨러를 선택해 주세요')

    const data = {
      apiURL: `/distribution/labeler/sub-section`,
      body: {
        type: Number(workType),
        sub_sections: selectedSubSections,
        labelers: selectedLabelers
      }
    }
    
    POST_API(data, {
      onSuccess: (data) => {
        console.log(data)
        setSelectedSubSections([])
        setSelectedLabelers([])
        setOnSearch(true)
      },
      onError: (err) => {
        console.log(err)
        if (err.response.data.error === "duplicated") return alert('중복 할당은 안돼요~')
      }
    })
  }

  // 검수자 할당 클릭
  const handleDistributeReviewer = () => {
    if (selectedSubSections.length === 0) return alert('중단원을 선택해 주세요')
    if (selectedLabelers.length === 0) return alert('라벨러를 선택해 주세요')

    const data = {
      apiURL: `/distribution/reviewer/sub-section`,
      body: {
        type: Number(workType),
        sub_sections: selectedSubSections,
        labelers: selectedLabelers
      }
    }
    
    POST_API(data, {
      onSuccess: (data) => {
        console.log(data)
        setSelectedSubSections([])
        setSelectedLabelers([])
        setOnSearch(true)
      },
      onError: (err) => {
        console.log(err)
        if (err.response.data.error === "duplicated") return alert('중복 할당은 안돼요~')
      }
    })
  }

  return (
    <Grid container >
      
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>해설 / 지문 할당</div>
            </BtnBox>
          </Title>

          <ContentWrap>
            {/* 기출 / 북스 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">기출 / 북스</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={service}
                label="기출 / 북스"
                onChange={(e)=>setService(e.target.value)}
              >
                <MenuItem value={'qb'}>기출</MenuItem>
                <MenuItem value={'books'}>북스</MenuItem>
              </Select>
            </FormControl>

            {/* 해설 / 지문 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">해설 / 지문</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={workType}
                label="해설 / 지문"
                onChange={(e)=>setWorkType(e.target.value)}
              >
                <MenuItem value={'1'}>해설</MenuItem>
                <MenuItem value={'2'}>지문</MenuItem>
              </Select>
            </FormControl>
          </ContentWrap>

          <ContentWrap style={{marginTop: '1rem'}}>
            {/* 학년 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">학년</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={grade}
                label="학년"
                onChange={(e)=>setGrade(e.target.value)}
              >
                {grade_list?.map(e => <MenuItem value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>

            {/* 세부과목 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">세부과목</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subSubject}
                label="세부과목"
                onChange={(e)=>setSubSubject(e.target.value)}
              >
                {sub_subject_list?.map(e => <MenuItem value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>
            
            {/* 출판사 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">출판사</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={publisher}
                label="출판사"
                onChange={(e)=>setPublisher(e.target.value)}
              >
                {sub_subject_list?.find(e => String(e.id) === subSubject)?.publishers.map(e => <MenuItem value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>

            {/* 대단원 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">대단원</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={section}
                label="대단원"
                onChange={(e)=>setSection(e.target.value)}
              >
                {section_list?.map(e => <MenuItem value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>

            <Button height={'40px'} onclick={handleSearch}>검색</Button>
          </ContentWrap>
          

          {/* <div style={{paddingTop: '10px'}}>{pdf_total_count}부 중 <span style={{fontSize: '18px', fontWeight: 700, color: '#2e6ef2'}}>{totalNum}</span>부 부여 완료</div> */}
          
          <Grid container sx={{height: '100%', overflow: 'hidden'}} >
            <Grid item xs={12} sm={9} md={9} lg={9} sx={{height: '100%', overflow: 'hidden'}} >
              <Item02 hidden={'hidden'} >
                <SubSectionList
                  ref={storeRef} 
                  workType={workType}
                  subSectionList={subSections}
                  pageNum={page}
                  getPageNum={getPageNum}
                  totalCount={totalCount}
                  selectedItem={selectedSubSections}
                  handleSelectedItem={setSelectedSubSections}
                />
              </Item02>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} sx={{height: '100%', overflow: 'hidden'}} >
              <Item02 hidden={'hidden'}>
                <SubSectionLabelerList 
                  pageNum={pageLabeler}
                  getPageNum={getPageNumLabeler}
                  totalCount={totalCountLabeler}
                  labelerList={labelers}
                  selectedLabelers={selectedLabelers}
                  handleSelectedLabelers={setSelectedLabelers}
                  handleDistributeLabeler={handleDistributeLabeler}
                  handleDistributeReviewer={handleDistributeReviewer}
                />
              </Item02>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  )
})
const LabelerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`
const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`
const BtnBox = styled.div`
  padding-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Item02 = styled(Paper)`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: calc(100vh - 130px); */
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default WorkTypeMatchingPage