import { produce } from 'immer'
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from 'redux-actions'

// actions
const IS_BOOKS = "IS_BOOKS";
const IS_LOADING = "IS_LOADING";
const SET_SUB_UNIT_SECTION = "SET_SUB_UNIT_SECTION";
const SET_SUBJECT = "SET_SUBJECT";
const SET_SUB_SECTION = "SET_SUB_SECTION";
const SET_GRAMMARS = "SET_GRAMMARS";
const SET_ADDITIONAL_OPTION = "SET_ADDITIONAL_OPTION";
const SET_MIDDLE_UNIT_OPTION = "SET_MIDDLE_UNIT_OPTION";
const SET_OPTION_ID_LIST = "SET_OPTION_ID_LIST";
const SET_IMAGE_DATA = "SET_IMAGE_DATA";
const IS_MUTIPLE = "IS_MUTIPLE";
const IS_ERROR_LIST = "IS_ERROR_LIST";

const SET_QUESTION_DATA_HTML = "SET_QUESTION_DATA_HTML" // 메인 문제 html 데이터
const SET_MULTIPLE_DATA_HTML = "SET_MULTIPLE_DATA_HTML" // 객관식 html 데이터
const SET_ANSWER = "SET_ANSWER" // 정답
const SET_EXPLANATION_DATA_HTML = "SET_EXPLANATION_DATA_HTML" // 해설 html 데이터

const SET_ID = "SET_ID"
const SET_CONTENT_ID = "SET_CONTENT_ID"
const SET_DIFFICULT = "SET_DIFFICULT"
const SET_DIFFICULT_PRICE = "SET_DIFFICULT_PRICE"
const SET_NEAR_QUESTION = "SET_NEAR_QUESTION"

const SET_INIT = "SET_INIT" // 데이터 초기화

// actionCreators
const isBooks = createAction(IS_BOOKS, (boolean) => ({ boolean }))
const set_is_Loading = createAction(IS_LOADING, (boolean) => ({ boolean }))
const set_sub_unit_section = createAction(SET_SUB_UNIT_SECTION, (list) => ({ list }))
const set_subject = createAction(SET_SUBJECT, (string) => ({ string }))
const set_sub_section = createAction(SET_SUB_SECTION, (list) => ({ list }))
const set_grammars = createAction(SET_GRAMMARS, (list) => ({ list }))
const set_additional_option = createAction(SET_ADDITIONAL_OPTION, (list) => ({ list }))
const setMiddleUnitOption = createAction(SET_MIDDLE_UNIT_OPTION, (list) => ({ list }));
const set_option_id_list = createAction(SET_OPTION_ID_LIST, (list) => ({ list }));
const set_image_data = createAction(SET_IMAGE_DATA, (obj) => ({ obj }));
const set_is_multiple = createAction(IS_MUTIPLE, (int) => ({ int }));
const set_is_error_check_list = createAction(IS_ERROR_LIST, (list) => ({ list }));

const set_question_data_html = createAction(SET_QUESTION_DATA_HTML, (string) => ({ string }))
const set_multiple_data_html = createAction(SET_MULTIPLE_DATA_HTML, (string) => ({ string }))
const set_answer = createAction(SET_ANSWER, (list) => ({ list }))
const set_explanation_data_html = createAction(SET_EXPLANATION_DATA_HTML, (string) => ({ string }))

const set_id = createAction(SET_ID, (obj) => ({ obj }))
const set_content_id = createAction(SET_CONTENT_ID, (int) => ({ int }))
const set_difficult = createAction(SET_DIFFICULT, (int) => ({ int }))
const set_difficult_price = createAction(SET_DIFFICULT_PRICE, (int) => ({ int }))
const set_near_question = createAction(SET_NEAR_QUESTION, (obj) => ({ obj }))

const set_init = createAction(SET_INIT, () => ({  }))


// initialState
const initialState = {
  is_books: false,
  is_loading: true,
  sub_unit_section: [],        // 중단원 - 소단원 옵션들

  subject: [],             // 과목
  sub_section: [],             // 저장된 중단원
  grammars: [],                // 저장된 영문법
  unit_section: [],            // 저장된 소단원
  additional_option: [],       // 저장된 추가 옵션

  option_list: [],
  option_id_list: [],
  image_data: null,
  is_multiple: 1,              // 객관식: 1, 주관식: 0
  error_check_list: [],        // 오류 여부: [0, 0, 1]

  question_data_html: '',      // 메인 문제 데이터
  // multiple_data_html: [],      // 객관식 선지: ['<p></p>']
  multiple_data_html: '',      // 객관식 선지: ['<p></p>']
  answer: [],                  // 객관식: ['1', '4'], 주관식: ['<p></p>']
  e_markdown: '',              // 해설 데이터

  content_id: 0,

  project_pdf_id: {
    project_id: 0,
    pdf_id: 0
  },

  difficult: 0,
  difficult_price: null,

  near_question: null
};

const check_nation = (id) => {
  const v = id.split('_')[0]
  switch(v) {
    case 'TH':
      return `/thai/check/${id}` // 태국 라벨링 데이터
    default:
      return `/thai/subsection/matching/${id}` // 한국 라벨링 데이터
  }
}

const getThData = (id) => {
  const token = localStorage.getItem('access_token');
  const path = check_nation(id)
  console.log(path)

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log('--------')
      console.log(res.data)
      
      const data = res.data
      const image_data = {
        Q_img: data.question_image,
        A_img: data.answer_image,
        R_img: data.rel_image,
      }

      if (data.is_multiple === 1) {
        await dispatch(set_multiple_data_html(data.select_html))

        const handleNumber = (el) => {
          if (el === 1) return "①"
          if (el === 2) return "②"
          if (el === 3) return "③"
          if (el === 4) return "④"
          if (el === 5) return "⑤"
        }
        const answer = data.answer.map(el => handleNumber(Number(el))).join(', ')
        await dispatch(set_answer(answer))
      } else {
        await dispatch(set_answer(data.answer))
      }
      await dispatch(set_question_data_html(data.question_html))
      await dispatch(set_is_multiple(data.is_multiple))
      await dispatch(set_option_id_list(data.subsection))
      await dispatch(set_image_data(image_data))

      await dispatch(set_is_Loading(false))
    } catch (err) {
      console.log(err, "getMiddleUnitData")
      if (err.response?.data?.error === "thai labeling not yet") alert('아직 라벨링 되지 않은 태국 문제입니다.')
    }
  }
}

// select option list - before
const getMiddleUnitOption = (question_id, sub_subject_id=0) => {
  const token = localStorage.getItem('access_token');
  const path = `/question/${question_id}/sub-section${sub_subject_id === 0 ? '' : `?sub_subject_id=${sub_subject_id}`}`;

  console.log(path)

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res.data)
      if (res.status === 200) {
        dispatch(set_sub_unit_section(res.data))
      }
    } catch (err) {
      console.log(err, "getMiddleUnitOption")
    }
  }
}

// question images
const getMiddleUnitData = (question_id, filters=null) => {
  const token = localStorage.getItem('access_token')
  let path = `/question/${question_id}`
  console.log(filters)
  if (filters?.midUnit) path += `?sub_section_id=${filters.midUnit}`
  console.log(path)

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.status === 200) {
        const ko_data = res.data
        await dispatch(set_content_id(ko_data.content_id))
        await dispatch(resetData())
        await dispatch(storeData(ko_data)) 
      }
    } catch (err) {
      console.log(err, "getMiddleUnitData")
      if (err.message === "Request failed with status code 422") alert('유효하지 않은 문제 번호입니다.')
    }
  }
}

const resetData = () => {
  return async function (dispatch) {
    await dispatch(set_is_error_check_list([]))
    await dispatch(set_question_data_html(""))
    await dispatch(set_explanation_data_html(""))
    await dispatch(set_is_multiple(1))
    await dispatch(set_subject(""))
    await dispatch(set_sub_section([]))
    await dispatch(set_grammars([]))
    
    await dispatch(set_additional_option([]))
    await dispatch(set_image_data(null))
    await dispatch(isBooks(false))
    await dispatch(set_difficult(0))
    await dispatch(set_difficult_price(null))
    await dispatch(set_near_question(null))
  }
}

const storeData = (ko_data) => {
  return async function (dispatch) {
    try {
      const html_data = ko_data.html
      const image_data = {
        Q_img: ko_data.image.question_image,
        A_img: ko_data.image.answer_image,
        R_img: ko_data.image.relation_image,
      }

      if (ko_data.is_select === 1) {
        await dispatch(set_multiple_data_html(html_data.selection_html))

        const handleNumber = (el) => {
          if (el === 1) return "①"
          if (el === 2) return "②"
          if (el === 3) return "③"
          if (el === 4) return "④"
          if (el === 5) return "⑤"
        }
        const answer = ko_data.answer.map(el => handleNumber(Number(el))).join(', ')
        await dispatch(set_answer(answer))
      } else {
        await dispatch(set_answer(ko_data.answer === null ? ['<p></p>'] : ko_data.answer))
      }
      console.log('[ko_data] :', ko_data)
      console.log('[image_data] :', image_data)
      const error_data = ko_data.errors.map(el => String(el))
      await dispatch(set_is_error_check_list(error_data))
      await dispatch(set_question_data_html(html_data.question_html))
      await dispatch(set_explanation_data_html(html_data.explanation_html))
      await dispatch(set_is_multiple(ko_data.is_select))
      await dispatch(set_subject(ko_data.subject))
      await dispatch(set_sub_section(ko_data.sub_section ? ko_data.sub_section : []))
      await dispatch(set_grammars(ko_data.grammar_child_unit_ids ?? []))
      
      await dispatch(set_additional_option(ko_data.tags ? ko_data.tags : []))
      await dispatch(set_image_data(image_data))
      await dispatch(isBooks(ko_data.is_book))
      await dispatch(set_difficult(ko_data.difficult))
      await dispatch(set_difficult_price(ko_data.difficult_price))
      await dispatch(set_near_question(ko_data.near_question))

      await dispatch(set_is_Loading(false))

    } catch (err) {
      console.log(err, "storeData")
    }
  }
}

// reducer
export default handleActions(
  {
    [IS_BOOKS]: (state, action) =>
      produce(state, (draft) => {
        draft.is_books = action.payload.boolean;
      }),
    [IS_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = action.payload.boolean;
      }),
    [SET_SUBJECT]: (state, action) =>
      produce(state, (draft) => {
        draft.subject = action.payload.string;
      }),
    [SET_SUB_UNIT_SECTION]: (state, action) =>
      produce(state, (draft) => {
        draft.sub_unit_section = action.payload.list;
      }),
    [SET_SUB_SECTION]: (state, action) =>
      produce(state, (draft) => {
        draft.sub_section = action.payload.list;
      }),
    [SET_GRAMMARS]: (state, action) =>
      produce(state, (draft) => {
        draft.grammars = action.payload.list;
      }),
    [SET_ADDITIONAL_OPTION]: (state, action) =>
      produce(state, (draft) => {
        draft.additional_option = action.payload.list;
      }),
    [SET_MIDDLE_UNIT_OPTION]: (state, action) =>
      produce(state, (draft) => {
        draft.option_list = action.payload.list;
      }),
    [SET_OPTION_ID_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.option_id_list = action.payload.list;
      }),
    [SET_IMAGE_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.image_data = action.payload.obj;
      }),
    [IS_MUTIPLE]: (state, action) =>
      produce(state, (draft) => {
        draft.is_multiple = action.payload.int;
      }),
    [IS_ERROR_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.error_check_list = action.payload.list;
      }),
      
    [SET_QUESTION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.question_data_html = action.payload.string;
      }),
    [SET_MULTIPLE_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_data_html = action.payload.string;
      }),
    [SET_ANSWER]: (state, action) =>
      produce(state, (draft) => {
        draft.answer = action.payload.list;
      }),
    [SET_EXPLANATION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.e_markdown = action.payload.string;
      }),
    [SET_ID]: (state, action) =>
      produce(state, (draft) => {
        draft.project_pdf_id = action.payload.obj;
      }),
    [SET_CONTENT_ID]: (state, action) =>
      produce(state, (draft) => {
        draft.content_id = action.payload.int;
      }),
    [SET_DIFFICULT]: (state, action) =>
      produce(state, (draft) => {
        draft.difficult = action.payload.int;
      }),
    [SET_DIFFICULT_PRICE]: (state, action) =>
      produce(state, (draft) => {
        draft.difficult_price = action.payload.int;
      }),
    [SET_NEAR_QUESTION]: (state, action) =>
      produce(state, (draft) => {
        draft.near_question = action.payload.obj;
      }),
    [SET_INIT]: (state, action) =>
      produce(state, (draft) => {
        draft.is_books = false;
        draft.is_loading = true;
        draft.subject = '';
        draft.option_list = [];
        draft.sub_section = [];
        draft.grammars = [];
        draft.unit_section = [];
        draft.additional_option = [];
        draft.option_id_list = [];
        draft.image_data = null;
        draft.is_multiple = 1;
        draft.error_check_list = [];
        draft.question_data_html = '';
        draft.multiple_data_html = '';
        draft.answer = [];
        draft.project_pdf_id = {
          project_id: 0,
          pdf_id: 0
        };
        draft.difficult = 0;
        draft.difficult_price = null;
        draft.near_question = null;
      }),
  },
  initialState
);


const actionCreators = {
  set_is_Loading,
  getMiddleUnitOption,
  getMiddleUnitData,
  getThData,

  set_is_multiple,
  set_init,
};

export { actionCreators };