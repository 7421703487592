import styled from 'styled-components'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { TablePaginationActions } from '../../../assets/utils/TablePaginationActions'

const Container = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`

const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const SkeletonCell = styled.div`
  word-break: keep-all;
  width: 100%;
  height: 1.2rem;
  margin: 0.4rem 0;
  border-radius: .15rem;
  background-color: rgba(0, 0, 0, 0.15);
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    to { opacity: 0.4; }
  }
`

const createSkeleton = (count: number) => {
  return (
    <>
      {Array.from({ length: 20 }).map((item, index) => {
        return (
          <Row key={String(index)} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {Array.from({ length: count+1 }).map((item, index) => {
              return (
                <Cell key={String(index)} align='center'>
                  <SkeletonCell />
                </Cell> 
              )
            })}
          </Row>
        )
      })}
    </>
  )
}

interface TableProps {
  page: number
  rowsPerPage: number
  count_total: number
  headList: any[]
  bodyList: any
  setPage: (v:number) => void
  setRowsPerPage: (v:number) => void
  isLoading: boolean
}

const TableBox = ({page = 0, rowsPerPage = 0, count_total = 0, headList=[], bodyList=null, setPage = ()=>{}, setRowsPerPage = ()=>{}, isLoading=true}: TableProps) => {
  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: any) => {
    setRowsPerPage(parseInt(e.target.value, 20))
    setPage(0)
  }

  return (
    <Container>
      <TableContainer component={Paper} sx={{ height: '100%' }} >
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align='center'>No.</Cell>
              {headList.map((el: any, idx: number) => {
                return <Cell key={String(idx)} align='center'>{el}</Cell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && createSkeleton(headList.length)}
            {!isLoading && bodyList}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{overflow: 'hidden'}}
        rowsPerPageOptions={[20]}
        colSpan={9}
        count={count_total}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {'aria-label': 'rows per page',},
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Container>
  )
}

export default TableBox