import './index.css';
import App from './App'
import { Suspense } from 'react'

import { Provider } from 'react-redux'
import store from './redux/configureStore'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import CssBaseline from '@mui/material/CssBaseline'

const container = document.getElementById("root")
const root = createRoot(container!)
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1,
			refetchOnWindowFocus: false,
			useErrorBoundary: true,
		},
	},
})

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
          <CssBaseline />
          <App />
        </QueryClientProvider>
      </Suspense>
    </BrowserRouter>
  </Provider>
)

// React 18 버전
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis