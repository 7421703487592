import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import Fab from '@mui/material/Fab'  // 플로팅 버튼
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import alert_img from '../../../../assets/images/alert_desc.gif'
import ClassicEditor from 'ckeditor5/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import 'katex/dist/katex.css'
import Editor_writing from '../../../../components/_organisms/editor-writing'
import Editor_image from '../../../../components/_organisms/editor-image/thai/Workspace'
import Editor_preview from '../../../../components/_organisms/editor-image/thai/Preview'

import { actionCreators as editorWritingActions } from "../../../../redux/modules/thai/editor_writing"
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAPI, postAPI } from '../../../../api'

interface QBProps {
  _service: string
  _question_id: number
}

const QB = ({_service, _question_id}: QBProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const _authority = localStorage.getItem("_authority")
  const previous_page = localStorage.getItem("previous_page")
  const authority = Number(_authority)
  const [ modal, setModal ] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const _filter = Number(searchParams.get('filter'))
  const _sub_section_id = Number(searchParams.get('sub_section_id'))
  const previousPageRef = useRef(null) // 이전 페이지 id
  const forwardPageRef = useRef(null) // 다음 페이지 id
  // console.log('_page: ', _page, '_filter: ', _filter, '_sub_section_id: ', _sub_section_id)

  const th_editor_writing = useSelector((state) => state.th_editor_writing)
  const {
    is_select,
    question_html,
    relation_html,
    selection_html,
    explanation_html,
    selection_answer_list,
    essay_answer_list,
    fabric_canvas_list,
    image_file_url_list,
  } = th_editor_writing

  // handling Editor-image
  const [imageUrlList, setImageUrlList] = useState([])
  const [isVisiblePreview, setIsVisiblePreview] = useState(false)
  const [isVisibleWorkspace, setIsVisibleWorkspace] = useState(false)
  const OpenAllImageEditor = () => {
    if (imageUrlList.length === 0) return
    setIsVisiblePreview(true)
    setIsVisibleWorkspace(true)
  }

  const [koData, setKoData] = useState(null)
  const [thData, setThData] = useState(null)
  const [shareData, setShareData] = useState(null)
  const [thDataStatus, setThDataStatus] = useState(null)
  const [skimmerCommentData, setSkimmerCommentData] = useState('')
  const [translatorCommentData, setTranslatorCommentData] = useState('')
  const [reviewerCommentData, setReviewerCommentData] = useState('')
  const [isSkimmerComment, setIsSkimmerComment] = useState(false)
  const [isTranslatorComment, setIsTranslatorComment] = useState(false)
  const [isReviewerComment, setIsReviewerComment] = useState(false)
  const skimmerCommentRef = useRef(null)
  const translatorCommentRef = useRef(null)
  const reviewerCommentRef = useRef(null)
  console.log('======', thData, koData)
  useEffect(()=>{
    if (!thData) return
    const body = document.body
    const a = document.createElement('div')
    a.style.position = 'fixed'
    a.style.zIndex = '10000'
    a.style.width = '7rem'
    a.style.height = '2.6rem'
    a.style.border = '1px solid red'
    body.appendChild(a)
    // thData.question_id
  }, [thData])

  const handleSetSkimmerCommentData = (e) => {
    const text = e.target.value
    setSkimmerCommentData(text)
  }
  const handleSetTranslatorCommentData = (e) => {
    const text = e.target.value
    setTranslatorCommentData(text)
  }
  const handleSetReviewerCommentData = (e) => {
    const text = e.target.value
    setReviewerCommentData(text)
  }

  useEffect(()=>{
    return () => {
      localStorage.removeItem('previous_page')
    }
  }, [])

  // GET / 문제 정보들 요청(html, images, matched_subject, sub_section_list, ...)
  const { data: Q_data } = useQuery([`question-${_question_id}`, {_question_id}], () => getAPI(`question/${_question_id}/all`, {nation: '[1, 2]'}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      setKoData(data.data.ko)
      setThData(data.data.thai)
      setShareData(data.data.share)
      setSelectLevel(data.data.thai.difficult)

      setThDataStatus(data.data.thai.status)
      dispatch(editorWritingActions.saveThaiQuestionDataAPI(data.data))
    },
    onError: error => {
      console.log(error)
    }
  })
  // =============================================

  // GET / 코멘트 내용 요청
  const { data: comment_data } = useQuery([`th-comment_${_question_id}`, {_question_id}], () => getAPI(`th/comment/${_question_id}`), {
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!comment_data) return
    console.log(comment_data.data)
    const {skimmer_comment, translator_comment, reviewer_comment} = comment_data.data
    if (skimmer_comment) {
      setSkimmerCommentData(comment_data.data.skimmer_comment?.replaceAll("<br/>", "\r\n"))
      setIsSkimmerComment(true)
    } else {
      setIsSkimmerComment(false)
      setSkimmerCommentData('')
    }
    if (translator_comment) {
      setTranslatorCommentData(comment_data.data.translator_comment?.replaceAll("<br/>", "\r\n"))
      setIsTranslatorComment(true)
    } else {
      setIsTranslatorComment(false)
      setTranslatorCommentData('')
    }
    if (reviewer_comment) {
      setReviewerCommentData(comment_data.data.reviewer_comment?.replaceAll("<br/>", "\r\n"))
      setIsReviewerComment(true)
    } else {
      setIsReviewerComment(false)
      setReviewerCommentData('')
    }
    
    // setSkimmerCommentData(comment_data.data.skimmer_comment?.replaceAll("<br/>", "\r\n"))
    // setReviewerCommentData(comment_data.data.reviewer_comment?.replaceAll("<br/>", "\r\n"))
  }, [comment_data])
  // =============================================

  // GET / 이미지 URL LIST 요청
  const { data: imageurl_data } = useQuery([`th-translation-image-url_${_question_id}`, {_question_id}], () => getAPI(`th/translation/image-url/${_question_id}`), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      const testList = [
        {image_url: 'https://i.ytimg.com/vi/V91XWCKFEUM/maxresdefault.jpg'},
        {image_url: 'https://ichef.bbci.co.uk/news/640/cpsprodpb/7036/production/_111162782__313.jpg'},
        {image_url: 'https://swoop-patagonia.imgix.net/SWO_5_NMAP_ALL_Patagonia-What-are-the-regions-of-Patagonia.png?auto=format,enhance,compress&fit=crop&crop=entropy,faces,focalpoint&w=1200&h=0'},
        {image_url: 'https://blog.kakaocdn.net/dn/1Wniv/btrCNN4ZBpG/yhKBBKaxQVDMX7UATte0B0/img.jpg'},
        {image_url: 'https://www.jeonmae.co.kr/news/photo/202205/887964_580039_467.png'},
        {image_url: 'https://image.xportsnews.com/contents/images/upload/article/2019/0130/mb_1548840723134182.jpg'},
        {image_url: 'https://dimg.donga.com/wps/SPORTS/IMAGE/2021/10/06/109568770.1.jpg'},
        {image_url: 'https://post-phinf.pstatic.net/MjAyMjAxMjFfMjMz/MDAxNjQyNzU0NjM2MTk1.-XDmExxEn2J4Qw0fiW_jDbo7LSmGMB904DPKvX0bhjEg.d9bk5Mdm_KPVyirQc7XsEMrv6rhDouxvhtM9-dJJdzkg.JPEG/11_57_S46_00054_%EB%B3%B4%EC%A0%95%28%EA%B9%80%EB%8B%A4%EB%AF%B8%29.jpg?type=w1200'},
        {image_url: 'https://mblogthumb-phinf.pstatic.net/MjAxOTEyMDlfNTgg/MDAxNTc1ODc4NjE3NDcx.gd-6dOVp5oFzZmef5Wsn_UbqFOA6i9qbyxj9tBFZcUIg.SS_9lvxojf5ao8jOX5XuFqsttAce2xHGohL3yAIlF0Ig.PNG.b-seol/%EA%B9%80%EB%8B%A4%EB%AF%B8_%ED%95%9C%EB%B3%B55.png?type=w800'},
        {image_url: 'https://mblogthumb-phinf.pstatic.net/MjAyMDAzMDlfMjM1/MDAxNTgzNzI4NzYzNjU2.U6_7WwRf4KeA3JbAva0mtCQbgA5IABsJygT4OpigORAg.tLDgZ7XpB_VqKMtvVl8hIhfTIkcKXkgG1P6NYTAiO4cg.JPEG.photo039/0000347629_001_20180523172638617.jpg?type=w800'},
        {image_url: 'https://image.ytn.co.kr/general/jpg/2021/1221/202112211430574873_d.jpg'},
        {image_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ13mMZHjPgbcRt0WWEc0-0B_iUb9eRabeUgVDboH52TxA-F-V0wkJYRvFgHlyci62H9_Y&usqp=CAU'},
      ]
      // setImageUrlList(testList)
      setImageUrlList(data.data)
    },
    onError: error => {
      console.log(error)
    }
  })
  // =============================================

  // GET / previous & forward page
  const { data: page_data } = useQuery([`th-near_question-${_question_id}`, {_question_id}], () => getAPI(`th/near-question/${_question_id}`, {filter: _filter, sub_section_id: _sub_section_id, question_type: _service}), {
    refetchOnWindowFocus: false,
    enabled: !!(authority !== 3),
    onSuccess: data => {
      // console.log(data.data)
      previousPageRef.current = data.data.before_question_id
      forwardPageRef.current = data.data.after_question_id
    },
    onError: error => {
      console.log(error)
    }
  })
  // =============================================

  // POST / Status 확인 완료 ====================
  const { mutate: mutate_complete_done } = useMutation(postAPI, {
    onSuccess: data => {
      console.log('data:', data)
      if (data.data.message === 'success update status') {
        setThDataStatus(data.data.status)
        alert('확인 완료되었습니다.')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const saveCompleteDone = () => {
    let data = {
      apiURL: `/th/skimmer/comment/done`,
      body: {
        question_id: _question_id,
        difficult: selectLevel,
      }
    }
    mutate_complete_done(data)
  }
  // =============================================

  // POST / Status 검수 완료 ====================
  const { mutate: mutate_review_done } = useMutation(postAPI, {
    onSuccess: data => {
      console.log('data:', data)
      if (data.data.message === 'success update status') {
        setThDataStatus(data.data.status)
        alert('검수 완료되었습니다.')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const saveReviewDone = () => {
    let data = {
      apiURL: `/th/review/done`,
      body: {
        question_id: _question_id,
      }
    }
    mutate_review_done(data)
  }
  // =============================================

  // POST / 스키머 코멘트 저장하기 ====================
  const { mutate: mutate_save_skimmer_comment } = useMutation(postAPI, {
    onSuccess: data => {
      console.log('data:', data)
      if (data.data.message === 'success comment') {
        alert('코멘트 저장이 완료되었습니다.')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const saveSkimmerComment = () => {
    if (!isSkimmerComment) return

    let text = skimmerCommentRef.current.value
    if (text === '') return alert('코멘트를 작성해 주세요.')
    text = text.replaceAll(/(?:\r\n|\r|\n)/g, "<br/>")

    let data = {
      apiURL: `/th/skimmer/comment`,
      body: {
        question_id: _question_id,
        comment: text
      }
    }
    mutate_save_skimmer_comment(data)
  }
  // =============================================

  // POST / 번역자 코멘트 저장하기 ====================
  const { mutate: mutate_save_translator_comment } = useMutation(postAPI, {
    onSuccess: data => {
      console.log('data:', data)
      if (data.data.message === 'success translator comment') {
        alert('코멘트 저장이 완료되었습니다.')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const saveTranslatorComment = () => {
    if (!isTranslatorComment) return

    let text = translatorCommentRef.current.value
    if (text === '') return alert('코멘트를 작성해 주세요.')
    text = text.replaceAll(/(?:\r\n|\r|\n)/g, "<br/>")

    let data = {
      apiURL: `/th/translation/comment`,
      body: {
        question_id: _question_id,
        comment: text
      }
    }
    mutate_save_translator_comment(data)
  }
  // =============================================

  // POST / 검수자 코멘트 저장하기 ====================
  const { mutate: mutate_save_reviewer_comment } = useMutation(postAPI, {
    onSuccess: data => {
      console.log('data:', data)
      if (data.data.message === 'success error comment') {
        setThDataStatus(data.data.status)
        alert('코멘트 저장이 완료되었습니다.')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const saveReviewerComment = () => {
    if (!isReviewerComment) return

    let text = reviewerCommentRef.current.value
    if (text === '') alert('코멘트를 작성해 주세요.')
    text = text.replaceAll(/(?:\r\n|\r|\n)/g, "<br/>")

    let data = {
      apiURL: `/th/review/comment`,
      body: {
        question_id: _question_id,
        comment: text
      }
    }
    mutate_save_reviewer_comment(data)
  }
  // =============================================


  // POST / 최종 저장하기 ====================
  const { mutate: mutate_save_total } = useMutation(postAPI, {
    onSuccess: data => {
      console.log('data:', data)
      if (data.data.message === 'success') {
        alert('최종 저장이 완료되었습니다.')
        queryClient.invalidateQueries(`question-${_question_id}`)
        return
      }
    },
    onError: error => {
      console.log('error: ', error)
      if (error.response.data.error === 'not correct image count') setModal(true)
      if (error.response.data.error === 'is done question') alert('검수 완료 상태의 문제입니다. 운영자에게 해당 문제 번호로 문의 바랍니다.')
    }
  })

  const handleSave = () => {
    if (window.confirm('변경 내용을 저장하시겠습니까?') === false) return

    let image_index: any = []

    fabric_canvas_list?.forEach((klass: any, index: number) => {
      if (klass._FILE) {
        image_index = [...image_index, String(index)]
      }
    })

    const copyList = _.cloneDeep(image_file_url_list)
    
    let result = {
      question_id: _question_id,
      question_html: question_html,
      relation_html: koData?.image.relation_image ? relation_html : null,
      selection_html: is_select === 1 ? selection_html : null,
      explanation_html: _service === 'qb' ? null : explanation_html,
      answer: is_select === 1 ? selection_answer_list : essay_answer_list,

      image_index: image_index.length > 0 ? image_index : null,
      image_history: JSON.stringify(copyList),
    }

    let data = {
      apiURL: `/th/translation/done`,
      body: result
    }
    mutate_save_total(data)

    // const formData = new FormData()
    // formData.append('question_id', `${result.question_id}`)
    // formData.append('question_html', `${result.question_html}`)
    // formData.append('selection_html', `${result.selection_html}`)
    // formData.append('explanation_html', `${result.explanation_html}`)

    // for (let i = 0; i < result.answer.length; i++) {
    //   formData.append('answer', result.answer[i]);
    // }

    // if (image_index.length > 0) {
    //   for (let i = 0; i < image_index.length; i++) {
    //     formData.append('image_index', image_index[i]);
    //   }
    //   for (let i = 0; i < image_data.length; i++) {
    //     formData.append('image_data', image_data[i]);
    //   }
    //   // for (let i = 0; i < image_file.length; i++) {
    //   //   formData.append('image_file', image_file[i]);
    //   // }
    // } 
    // const copyList = _.cloneDeep(image_file_url_list)
    // const a = JSON.stringify(copyList)
    // formData.append('image_file', a);

    // mutate_save_total(formData)
  }
  // =============================================

  const handleMultipleAnswer = (list: string[]) => {
    const text = list.join(', ')
    return text
  }

  const goToList = () => {
    // console.log(previous_page)
    if (previous_page) {
      return navigate(`${previous_page}`)
    } else {
      return navigate(`/th/translation/${_service}?page=1&filter=0&tab=수학`)
    }
  }

  const goToPage = (type: string) => {
    const question_id = type === 'previous' ? previousPageRef.current : forwardPageRef.current
    if (!question_id) return alert(`${type === 'previous' ? '첫' : '마지막'} 페이지입니다.`)
    const new_page = location.search // ex - ?page=1&filter=0&sub_section_id=1
    // console.log(question_id, new_page)
    navigate({
      pathname: `/th/translation/${_service}/detail/${question_id}`,
      search: new_page
    })
  }

  const [ selectLevel, setSelectLevel] = useState(null)
  console.log(selectLevel)
  const onclickLevel = (level) => {
    if (!(authority === 0 || authority === 2)) return
    setSelectLevel(level)
  }

  const relationImageUrl = `${process.env.REACT_APP_IMAGE_URL}${koData?.image.relation_image}`
  console.log(koData?.image.relation_image)

  return (
    <Grid container spacing={1.5} sx={{position: 'relative'}}>
      {thData &&
        <Alert>
          <div className='alert_box'>태국 ID : {thData?.question_id}</div>
        </Alert>
      }
      {modal && 
        <Modal
          open={modal}
          onClose={()=>setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
          sx={{ outline: 0}}
        >
          <ModalImgWrap>
            <ModalTitle>
              <div>원본 이미지가 삭제되었습니다.</div>
              <div>한국 원본 또는 프리뷰한 이미지를 Drag&Drop 하여 채워주세요.</div>
            </ModalTitle>
            <img className='modal_img' alt={alert_img} src={alert_img} />
          </ModalImgWrap>
        </Modal>
      }

      <Grid item xs={12} sm={2} md={2} lg={2}>
        <Item sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', overflow: 'hidden' }}>
          <CommentSection>
            <div className='comment_wrap'>
              <div className='comment_header_wrap'>
                <div className='header_button comment_header_goback' onClick={goToList}>{`< List`}</div>
                {(authority === 0 || authority === 2 || authority === 3) && // 번역자
                  <div className={['header_button', `${imageUrlList.length > 0 ? 'comment_header_image_edit' : 'disabled'}`].join(' ')} onClick={()=>OpenAllImageEditor()}>Editor</div>
                }
              </div>
              <div className='comment_status_wrap'>
                <div className='comment_status_text'>
                  {thDataStatus === null && <Status color={'rgba(0, 0, 0, 0.3)'}>로딩 중..</Status>}
                  {thDataStatus === 0 && <Status color={'rgba(0, 0, 0, 0.3)'}>확인 대기</Status>}
                  {thDataStatus === 1 && <Status color={'rgba(0, 0, 0, 0.7)'}>번역 대기</Status>}
                  {thDataStatus === 2 && <Status color={'#FFB26B'}>검수 대기</Status>}
                  {thDataStatus === 3 && <Status color={'red'}>수정 대기</Status>}
                  {thDataStatus === 4 && <Status color={'#FF7B54'}>재검수 대기</Status>}
                  {thDataStatus === 5 && <Status color={'green'}>검수 완료</Status>}
                </div>
                {(authority === 0 || authority === 2 || authority === 4) && // 어드민 & 스키머 & 검수자
                  <div className='comment_button_wrap'>
                    {(authority === 0 || authority === 2) && // 어드민 & 스키머
                      <div className='comment_button full max-height' onClick={()=>saveCompleteDone()}>확인 완료</div>
                    }
                    {(authority === 0 || authority === 4) && // 어드민 & 검수자
                      <div className='comment_button full max-height' onClick={()=>saveReviewDone()}>검수 완료</div>
                    }
                  </div>
                }
              </div>

              <div className='comment_container'>
              <div className='level_wrap'>
                <div className='level_title'>난이도(Level)</div>
                <div className='level_item_wrap'>
                  <LevelItem className={selectLevel === 1 ? 'selected' : ''} onClick={()=>onclickLevel(1)}>1</LevelItem>
                  <LevelItem className={selectLevel === 2 ? 'selected' : ''} onClick={()=>onclickLevel(2)}>2</LevelItem>
                  <LevelItem className={selectLevel === 3 ? 'selected' : ''} onClick={()=>onclickLevel(3)}>3</LevelItem>
                  <LevelItem className={selectLevel === 4 ? 'selected' : ''} onClick={()=>onclickLevel(4)}>4</LevelItem>
                  <LevelItem className={selectLevel === 5 ? 'selected' : ''} onClick={()=>onclickLevel(5)}>5</LevelItem>
                </div>
              </div>

              {/* <div className='comment_container'> */}
                {/* 스키머 코멘트 */}
                {(authority === 0 || authority === 2 || authority === 3 || authority === 4) && // 어드민 & 스키머 & 번역자 & 검수자
                  <div className='comment_skimmer_wrap'>
                    {(authority === 0 || authority === 2) && // 어드민 & 스키머
                      <div className='comment_skimmer_button_wrap'>
                        <div className='comment_button write' onClick={()=>setIsSkimmerComment(!isSkimmerComment)}>코멘트 {isSkimmerComment ? '닫기' : '작성'}</div>
                        <div className={['comment_button', 'max-height', `${isSkimmerComment ? 'full' : 'disabled'}`].join(' ')} onClick={()=>saveSkimmerComment()}>코멘트 저장</div>
                      </div>
                    }
                    {(isSkimmerComment || authority === 3 || authority === 4) && // 스키머가 코멘트 열었을 때 & 번역자 & 검수자
                      <CommentBox>
                        <div className='title'>스키머 코멘트</div>
                        <textarea id='skimmer_comment' ref={skimmerCommentRef} value={skimmerCommentData} onChange={(e)=>handleSetSkimmerCommentData(e)} className='content_box' placeholder={(authority === 0 || authority === 2) ? '코멘트를 남겨주세요' : ''} disabled={!(authority === 0 || authority === 2)}></textarea>
                      </CommentBox>
                    }
                  </div>
                }

                {/* 번역자 코멘트 */}
                {(authority === 0 || authority === 3 || authority === 4) && // 어드민 & 스키머 & 번역자 & 검수자
                  <div className='comment_skimmer_wrap'>
                    {(authority === 0 || authority === 3) && // 번역자 & 스키머
                      <div className='comment_skimmer_button_wrap'>
                        <div className='comment_button write' onClick={()=>setIsTranslatorComment(!isTranslatorComment)}>코멘트 {isTranslatorComment ? '닫기' : '작성'}</div>
                        <div className={['comment_button', 'max-height', `${isTranslatorComment ? 'full' : 'disabled'}`].join(' ')} onClick={()=>saveTranslatorComment()}>코멘트 저장</div>
                      </div>
                    }
                    {(isTranslatorComment || authority === 4) && // 번역자가 코멘트 열었을 때 & 번역자 & 검수자
                      <CommentBox>
                        <div className='title'>번역자 코멘트</div>
                        <textarea id='skimmer_comment' ref={translatorCommentRef} value={translatorCommentData} onChange={(e)=>handleSetTranslatorCommentData(e)} className='content_box' placeholder={(authority === 0 || authority === 3) ? '코멘트를 남겨주세요' : ''} disabled={!(authority === 0 || authority === 3)}></textarea>
                      </CommentBox>
                    }
                  </div>
                }

                {/* 검수자 코멘트 */}
                {(authority === 0 || authority === 3 || authority === 4) && // 어드민 & 번역자 & 검수자
                  <div className='comment_skimmer_wrap'>
                    {/* {(thDataStatus === 2 || thDataStatus === 4 || thDataStatus === 5) && (authority === 0 || authority === 4) && // 어드민 & 검수자 */}
                    {(authority === 0 || authority === 4) && // 어드민 & 검수자
                      <div className='comment_skimmer_button_wrap'>
                        <div className='comment_button write' onClick={()=>setIsReviewerComment(!isReviewerComment)}>코멘트 {isReviewerComment ? '닫기' : '작성'}</div>
                        <div className={['comment_button', 'max-height', `${isReviewerComment ? 'full' : 'disabled'}`].join(' ')} onClick={()=>saveReviewerComment()}>코멘트 저장</div>
                      </div>
                    }
                    {(isReviewerComment || authority === 3) && // 검수자가 코멘트 열었을 때 & 번역자 
                      <CommentBox>
                        <div className='title'>검수자 코멘트</div>
                        <textarea id='skimmer_comment' ref={reviewerCommentRef} value={reviewerCommentData} onChange={(e)=>handleSetReviewerCommentData(e)} className='content_box' placeholder={(authority === 0 || authority === 4) ? '코멘트를 남겨주세요' : ''} disabled={!(authority === 0 || authority === 4)}></textarea>
                      </CommentBox>
                    }
                  </div>
                }
              </div>
            </div>
            {!(authority === 3) &&
              <div className='pagination_wrap'>
                <div className='pagination_button' onClick={() => goToPage('previous')}>{`< 이전`}</div>
                <div className='pagination_button' onClick={() => goToPage('forward')}>{`다음 >`}</div>
              </div>
            }
          </CommentSection>
        </Item>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Item isVisible={!isVisiblePreview}>
          {koData?.image.relation_image &&
            <img src={relationImageUrl} alt='relation_image' style={{marginBottom: '1rem'}} />
          }
          <CKEditor
            id='preview_editor'
            editor={ ClassicEditor }
            data={ koData?.html.question_html }
            
            onReady={( editor ) => {
              editor.enableReadOnlyMode( 'preview_editor' );
              const toolbarElement = editor.ui.view.toolbar.element;
              toolbarElement.style.display = 'none';
            }}
          />

          {shareData?.is_select === 1 &&  // 객관식 선지
            <MultipleWrap>
              <CKEditor
                id={`number_editor`}
                editor={ ClassicEditor }
                data={ koData?.html.selection_html }
                
                onReady={( editor ) => {
                  editor.enableReadOnlyMode( `number_editor` );
                  const toolbarElement = editor.ui.view.toolbar.element;
                  toolbarElement.style.display = 'none';
                }}
              />
            </MultipleWrap>
          }

          {shareData?.is_select === 1 &&  // 객관식 답
            <AnswerBox>
              정답: <span className='answer_span'>{handleMultipleAnswer(koData?.answer)}</span>
            </AnswerBox>
          }

          {shareData?.is_select === 0 &&  // 주관식
            <EssayWrap>
              <span className='answer_essay'>{`정답:`}</span>
              {koData?.answer.map((el, idx) => {
                return (
                  <EssayBox key={String(idx)}>
                    <Numbeing>{idx + 1}.</Numbeing>
                    <CKEditor
                      id={`number_editor${idx + 1}`}
                      editor={ ClassicEditor }
                      data={ `<p>${el}</p>` }
                      
                      onReady={( editor ) => {
                        editor.enableReadOnlyMode( `number_editor${idx + 1}` );
                        const toolbarElement = editor.ui.view.toolbar.element;
                        toolbarElement.style.display = 'none';
                      }}
                    />
                  </EssayBox>
                )
              })}
            </EssayWrap>
          }
          {koData?.html.explanation_html &&
            <CKEditor
              id='preview_e_editor'
              editor={ ClassicEditor }
              data={ koData?.html.explanation_html }
              
              onReady={( editor ) => {
                editor.enableReadOnlyMode( 'preview_e_editor' );
                const toolbarElement = editor.ui.view.toolbar.element;
                toolbarElement.style.display = 'none';
              }}
            />
          }
          
        </Item>
        <Editor_preview isVisible={isVisiblePreview} close={()=>setIsVisiblePreview(false)} />
      </Grid>
      
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Item hidden={'hidden'} isVisible={!isVisibleWorkspace}>
          <Editor_writing _service={_service} is_relation={!!koData?.image.relation_image} />
        </Item>
        {/* {isVisibleWorkspace &&
        } */}
        <Editor_image _question_id={_question_id} imageUrlList={imageUrlList} isVisible={isVisibleWorkspace} close={()=>setIsVisibleWorkspace(false)} />
      </Grid>

      {/* {(authority === 3 || authority === 0) && (thDataStatus === 1 || thDataStatus === 3) && */}
      {(authority === 3 || authority === 2 || authority === 0) && !(thDataStatus === 0) &&
        <Fab
          onClick={handleSave} 
          color="primary" 
          sx={{position: 'fixed', right: '2.6rem', bottom: '3rem', fontWeight: '600'}} 
        >
          저장
        </Fab>
      }
    </Grid>
  )
}

const Alert = styled.div`
  position: fixed;
  z-index: 10000;
  right: 7rem;
  top: 0;
  width: 76%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */

  .alert_box {
    padding: 1rem 1.5rem 0.95rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 2.6rem;
    border-radius: 50rem;
    background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`

const ModalTitle = styled.div`
  margin: 2rem 0;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ModalImgWrap = styled.div`
  padding: 0 1rem 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50vw;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 0.5rem;
  background-color: white;

  .modal_img {
    width: 100%;
    border-radius: 0.3rem;
  }
`
const Status = styled.div`
  padding: 0.2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: ${props => props.color};
  cursor: default;
`
const LevelItem = styled.div`
  width: 100%;
  min-width: 1rem;
  max-width: 3rem;
  color: #9c9c9c;
  background-color: #ddd;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    color: #fff;
    background-color: #2b78fd;
    border: 1px solid #2b78fd;
  }
`
const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .comment_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;

    .level_wrap{
      padding: 0.5rem;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      border-radius: 4px;
      background-color: rgba(41, 119, 255, 0.1);

      .level_title {
        padding-left: 0.2rem;
        padding-bottom: 0.1rem;
        margin-bottom: 0.5rem;
        color: rgba(41, 119, 255, 0.8);
        font-size: 0.8rem;
        font-weight: 500;
        border-bottom: 1px solid rgba(41, 119, 255, 0.15);
      }
      .level_item_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.4rem;
      }

      .selected {
        color: #fff;
        background-color: #2b78fd;
        border: 1px solid #2b78fd;
      }

      .level_item {
        width: 100%;
        min-width: 1rem;
        max-width: 3rem;
        color: #9c9c9c;
        background-color: #ddd;
        border: 1px solid #ddd;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        :hover {
          color: #fff;
          background-color: #2b78fd;
          border: 1px solid #2b78fd;
        }
      }
    }

    .comment_container {
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
    }
    .comment_header_wrap {
      /* border: 1px solid red; */
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.15s;

      .header_button {
        padding: 0.2rem 0.5rem;
        width: fit-content;
        font-size: 0.8rem;
        border-radius: 4px;
      }
      .comment_header_image_edit {
        color: #fff;
        background-color: #2b78fd;
        border: 1px solid #2b78fd;
        cursor: pointer;
        :hover {
          background-color: #0d65ff;
        }
      }
      .comment_header_goback {
        color: #555555;
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        :hover {
          border: 1px solid rgba(0, 0, 0, 0.3);
        }
      }

      .disabled {
        color: #9c9c9c;
        background-color: #ddd;
        border: 1px solid #ddd;
      }
    }

    .comment_status_wrap {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      border-radius: 4px;
      background-color: rgba(41, 119, 255, 0.1);
    }
    .comment_status_text {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .comment_button_wrap {
      display: flex;
      gap: 0.2rem;
      min-width: 5rem;
    }

    .comment_skimmer_button_wrap {
      display: flex;
      gap: 0.5rem;
    }
    .comment_skimmer_wrap {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 1rem;
    }

    .comment_button {
      padding: 0.4rem;
      width: 100%;
      color: #333333;
      font-size: 0.7rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.1s;
    }
    .full {
      color: white;
      background-color:#2977ff;
      :hover {
        background-color: #0d65ff;
      }
    }
    .write {
      color: #2977ff;
      border: 1px solid #2977ff;
      :hover {
        color: #0d65ff;
        border: 1px solid #0d65ff;
      }
    }
    /* .max-height {
      max-width: 5rem;
    } */
    .disabled {
      color: #777777;
      border: 1px solid #f7f8fd;
      background-color: #f7f8fd;
      cursor: default;
      transition: all 0.1s;
    }

    .comment_button_box {
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 0.3rem;
    }
  }
  .pagination_wrap {
    height: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);

    .pagination_button {
      padding: 0 0.5rem;
      cursor: pointer;
      transition: all 0.15s;
      :hover {
        color: rgba(0, 0, 0, 0.9);
        font-weight: 500;
      }
    }
  }
`
const CommentBox = styled.div`
  /* border: 1px solid red; */
  .title {
    padding-left: 0.2rem;
    padding-bottom: 0.1rem;
    margin-bottom: 0.5rem;
    color: rgba(41, 119, 255, 0.8);
    font-size: 0.8rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(41, 119, 255, 0.15);
  }
  .content_box {
    padding: 0.4rem;
    width: 100%;
    min-height: 20rem;
    /* height: 6rem; */
    font-size: 0.7rem;
    resize: none;
    outline: none;
    border: none;
    background-color: transparent;
    border: 1px solid rgba(41, 119, 255, 0.15);;
    border-radius: 3px;
  }
  padding: 0.4rem 0.4rem 0;
  /* min-height: 3rem; */
  /* max-height: 5rem; */
  border-radius: 4px;
  background-color: rgba(41, 119, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`
const Item = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};
  display: ${props => props.isVisible ? 'flex' : 'none' };

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  /* @media screen and (max-width: 778px) {
    height: calc(100vh - 665px);
  } */
`
const AnswerBox = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1rem;

  .answer_span {
    /* color: #282c34; */
    font-size: 1rem;
    font-weight: 600;
    padding-top: 0.5px;
  }
`
const EssayWrap = styled.div`
  width: 100%;
  padding: 0.8rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .answer_essay {
    font-size: 0.8rem;
  }
`
const MultipleWrap = styled.div`
  width: 100%;
  padding: 0 0 0.8rem;
  margin-bottom: 1rem;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  

  .pr-multiple_box[data-num=''] {
    border: none;
    .pr-multiple_box_content_wrap {
      border: none;
    }
    .pr-multiple_box_content {
      border: none;
    }
  }
  .pr-multiple_box[data-num='']:before {
    border: none;
  }

  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    border: none;
    padding: 0;
  }
  :root {
    --ck-widget-outline-thickness: 0px;
  }
  .image-inline {
    outline: 1px !important;
  }
  .ck-widget.ck-clipboard-drop-target-range {
    outline: 1px !important;
  }
`
const EssayBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    border: none;
    p:first-of-type {
      display: none;
    }
    p:last-of-type {
      display: none;
    }
  }
`
const Numbeing = styled.div`
  width: 1rem;
  /* border: 1px solid blue; */
`

export default QB