import { memo } from 'react'
import * as S from './style/EditorQuestionInfoStyle'
import { useDispatch, useSelector } from 'react-redux'
import MultipleChoice from './MultipleChoice'
import EssayQuestion from './EssayQuestion'
import Accordion from '../../_molecules/Accordion'
import { actionCreators as editorWritingActions } from "../../../redux/modules/thai/editor_writing"

const EditorQuestionInfo = () => {
  const dispatch = useDispatch()
  // const nation = localStorage.getItem('nation')
  const th_editor_writing = useSelector((state) => state.th_editor_writing)
  const {
    is_select,
    difficulty,
  } = th_editor_writing
  
  const handleDifficulty = (v) => {
    dispatch(editorWritingActions.set_difficulty(v))
  }

  const difficultyItemList = [
    {value: 2, label: '상'},
    {value: 1, label: '중'},
    {value: 0, label: '하'},
  ]

  return (
    <S.Container>
      <S.MoEWrap>
        <S.Main>
          {is_select === 1 &&
            <MultipleChoice />  // 객관식
          }
          {is_select === 0 &&
            <EssayQuestion />  // 주관식
          }
          {/* {nation !== '3' && */}
            <Accordion 
              disabled name={'난이도'} 
              value={difficulty} 
              onchange={handleDifficulty} 
              itemList={difficultyItemList} 
            />
          {/* } */}
        </S.Main>
      </S.MoEWrap>
    </S.Container>
  )
}

export default memo(EditorQuestionInfo)