import { useState, useEffect } from 'react';
import * as S from '../../../../components/editor/style/EditorPreviewStyle'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Skeleton from '../../../editor/skeleton/SkeletonEditor'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import ClassicEditor from 'ckeditor5/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import 'katex/dist/katex.css'
import { actionCreators as middleUnitActions } from "../../../../redux/modules/middleUnit";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props
  const theme = useTheme()

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const LabelingList = (props) => {
  const dispatch = useDispatch()
  const middleUnit = useSelector((state) => state.middleUnit)
  const {
    question_data_html,
    multiple_data_html,
    is_multiple,
    image_data,
    is_loading,
    answer,
    difficult,
    e_markdown
  } = middleUnit

  const [ img, setImg ] = useState(null)
  const [ modal, setModal ] = useState(false)
  const openModal = (img) => {
    if (!modal) {
      setImg(img)
      setModal(true)
    }
  }
  const closeModal = () => {
    if (modal) {
      setImg(null)
      setModal(false)
    }
  }

  const cloudfrontBaseUrl = process.env.REACT_APP_IMAGE_URL
  const questionImgUrl = image_data?.Q_img ? `${cloudfrontBaseUrl}${image_data?.Q_img}` : null
  const solutionImgUrls = image_data?.Q_img ? image_data.A_img.map(e => `${cloudfrontBaseUrl}${e}`) : []
  // const solutionImgUrl = `${cloudfrontBaseUrl}${image_data?.A_img}`
  const relatedImgUrl = image_data && image_data?.R_img 
    ? `${cloudfrontBaseUrl}${image_data?.R_img}` 
    : null
  const imageUrlList = questionImgUrl ? [questionImgUrl, ...solutionImgUrls] : []

  const handleNumber = (el) => {
    if (el === 1) return "①"
    if (el === 2) return "②"
    if (el === 3) return "③"
    if (el === 4) return "④"
    if (el === 5) return "⑤"
  }

  const getDifficult = (difficult) => {
    if (difficult === 0) return '하'
    if (difficult === 1) return '중'
    if (difficult === 2) return '상'
    return '알 수 없음'
  }

  useEffect(() => {
    dispatch(middleUnitActions.set_is_Loading(false))
  }, [])
  

  return (
    <TableContainer component={Paper} sx={{padding: '1rem 2px 2px', backgroundColor: 'transparent', boxShadow: 'none', overflow: 'hidden', height: '100%'}} >

      {modal && 
        <Modal
          open={modal}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
          sx={{ outline: 0}}
        >
          <ModalImgWrap>
            <img className='modal_img' alt={img} src={img} />
          </ModalImgWrap>
        </Modal>
      }
      
      <Grid container spacing={2} sx={{height: '100%'}} >

      {is_loading ? (
        <Skeleton type={'midUnit'} />
      ) : (
        <>
          {/* 연관 지문 이미지 */}
          <Grid item xs={12} sm={4} md={4} lg={4} >
            <Item>
              {image_data && relatedImgUrl
                ? (
                  <Image 
                    key={relatedImgUrl}
                    src={relatedImgUrl} 
                    width="100%" 
                    style={{ cursor: "pointer" }} 
                    onClick={() => openModal(img)}
                  />
                )
                : (
                  <NoImg>
                    연관된 지문이 없습니다.
                  </NoImg>
                )
              }
              
            </Item>
          </Grid>

          {/* 프리뷰 */}
          <Grid item xs={12} sm={4} md={4} lg={4} >
            <Item02>
              <CKEditor
                id='preview_editor'
                editor={ ClassicEditor }
                data={ question_data_html }
                
                onReady={( editor ) => {
                  editor.enableReadOnlyMode( 'preview_editor' );
                  const toolbarElement = editor.ui.view.toolbar.element;
                  toolbarElement.style.display = 'none';
                }}
              />

              {is_multiple === 1 &&  // 객관식
                <MultipleWrap>
                  {/* {multiple_data_html.length > 0 && 
                  multiple_data_html.map((el, idx) => {
                    return (
                      <EssayBox key={String(idx)}>
                        <Number>{handleNumber(idx + 1)}</Number>
                        <CKEditor
                          id={`number_editor${idx + 1}`}
                          editor={ ClassicEditor }
                          data={ `<p>${el}</p>` }
                          
                          onReady={( editor ) => {
                            editor.enableReadOnlyMode( `number_editor${idx + 1}` );
                            const toolbarElement = editor.ui.view.toolbar.element;
                            toolbarElement.style.display = 'none';
                          }}
                        />
                      </EssayBox>
                    )
                  })} */}
                  <CKEditor
                    id={`number_editor`}
                    editor={ ClassicEditor }
                    data={ multiple_data_html }
                    
                    onReady={( editor ) => {
                      editor.enableReadOnlyMode( `number_editor` );
                      const toolbarElement = editor.ui.view.toolbar.element;
                      toolbarElement.style.display = 'none';
                    }}
                  />
                </MultipleWrap>
              }

              {is_multiple === 1 &&  // 객관식
                <AnswerBox>
                  정답: <span className='answer_span'>{answer}</span>
                </AnswerBox>
              }

              {is_multiple === 0 &&  // 주관식
                <EssayWrap>
                  <span className='answer_essay'>{`정답:`}</span>
                  {answer.length > 0 && 
                  answer.map((el, idx) => {
                    return (
                      <EssayBox key={String(idx)}>
                        <Number>{idx + 1}.</Number>
                        <CKEditor
                          id={`number_editor${idx + 1}`}
                          editor={ ClassicEditor }
                          data={ `<p>${el}</p>` }
                          
                          onReady={( editor ) => {
                            editor.enableReadOnlyMode( `number_editor${idx + 1}` );
                            const toolbarElement = editor.ui.view.toolbar.element;
                            toolbarElement.style.display = 'none';
                          }}
                        />
                      </EssayBox>
                    )
                  })}
                </EssayWrap>
              }
              <AnswerBox>난이도: {getDifficult(difficult)}</AnswerBox>
              {e_markdown &&
                <CKEditor
                  id='preview_e_editor'
                  editor={ ClassicEditor }
                  data={ e_markdown }
                  
                  onReady={( editor ) => {
                    editor.enableReadOnlyMode( 'preview_e_editor' );
                    const toolbarElement = editor.ui.view.toolbar.element;
                    toolbarElement.style.display = 'none';
                  }}
                />
              }
              
            </Item02>
          </Grid>

          {/* 문제 + 답안지 이미지 */}
          <Grid item xs={12} sm={4} md={4} lg={4} >
            <Item>
              <Wrapper>
              {image_data && imageUrlList.map((img, index) => {
                return (
                  <Image 
                    key={String(index)}
                    src={img && img} 
                    width="100%" 
                    style={{ cursor: "pointer" }} 
                    onClick={() => openModal(img)}
                  />
                )
              })}
              </Wrapper>
            </Item>
          </Grid>
        </>
      )}
      </Grid>
    </TableContainer>
  )
}

const Wrapper = styled.div`
  /* border: 1px solid blue; */
  /* display: flex;
  flex-direction: column;
  gap: 1rem; */
`
const Item = styled(Paper)`
  /* border: 1px solid red; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: -webkit-calc(100vh - 380px);
  height: -moz-calc(100vh - 380px);
  height: -ms-calc(100vh - 380px);
  height: -o-calc(100vh - 380px);
  height: calc(100vh - 380px);
  overflow-y: auto;

  -ms-overflow-style: none; 
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none; 
  }

  /* @media screen and (max-width: 778px) {
    height: calc(100vh - 665px);
  } */
`
const Image = styled.img`
  width: 100%;
  /* aspect-ratio: auto 1/1; */
  /* min-height: 300px; */
  /* max-height: fit-content; */
  cursor: pointer;
`
const ModalImgWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50vw;
  max-height: 90%;
  overflow-y: auto;

  .modal_img {
    width: 100%;
  }
`
const AnswerBox = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1rem;

  .answer_span {
    /* color: #282c34; */
    font-size: 1rem;
    font-weight: 600;
    padding-top: 0.5px;
  }
`
const EssayWrap = styled.div`
  width: 100%;
  padding: 0.8rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .answer_essay {
    font-size: 0.8rem;
  }
`
const MultipleWrap = styled.div`
  width: 100%;
  padding: 0 0 0.8rem;
  margin-bottom: 1rem;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  

  .pr-multiple_box[data-num=''] {
    border: none;
    .pr-multiple_box_content_wrap {
      border: none;
    }
    .pr-multiple_box_content {
      border: none;
    }
  }
  .pr-multiple_box[data-num='']:before {
    border: none;
  }

  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    border: none;
    padding: 0;
  }
  :root {
    --ck-widget-outline-thickness: 0px;
  }
  .image-inline {
    outline: 1px !important;
  }
  .ck-widget.ck-clipboard-drop-target-range {
    outline: 1px !important;
  }
`
const EssayBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    border: none;
    p:first-of-type {
      display: none;
    }
    p:last-of-type {
      display: none;
    }
  }
`
const Number = styled.div`
  width: 1rem;
  /* border: 1px solid blue; */
`
const Wrap = styled(TableContainer)`
  height: 100%;
  padding: 1rem 2px 2px;
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
`
const NoImg = styled.div`
  flex-grow: 1;  // 부모 div가 display: flex; flex-direction: column; 인 경우
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
`

const Item02 = styled(Paper)`
  /* border: 1px solid blue; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  height: calc(100vh - 380px);
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  /* @media screen and (max-width: 778px) {
    height: calc(100vh - 665px);
  } */
`


export default LabelingList