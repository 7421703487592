import styled from 'styled-components'
import { useState, useRef } from 'react'
import { Grid, Paper } from '@mui/material'

interface MainSectionProps {
  children: JSX.Element
  isHidden?: boolean
}

const MainSection = ({children, isHidden = false}: MainSectionProps) => {

  return (
    <Grid container direction={"row"}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Container hidden={isHidden}>
          {children}
        </Container>
      </Grid>
    </Grid>
  )
}

const Container = styled(Paper)`
  position: relative;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? 'hidden' : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

export default MainSection