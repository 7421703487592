import styled from 'styled-components'
import { useState, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select';
import { actionCreators as writeEditorActions } from "../../../../redux/modules/writeEditor";

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
`

const CommonInfo = () => {
  const dispatch = useDispatch()
  const difficulty = useSelector((state) => state.writeEditor.difficulty)
  console.log(difficulty)
  
  const [level, setLevel] = useState(difficulty);
  console.log(level)

  const handleChange = (e) => {
    setLevel(e.target.value);
    dispatch(writeEditorActions.set_difficulty(e.target.value))
  };

  return (
    <Container>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">난이도</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={level}
          label="Level"
          onChange={handleChange}
        >
          <MenuItem value={2}>상</MenuItem>
          <MenuItem value={1}>중</MenuItem>
          <MenuItem value={0}>하</MenuItem>
        </Select>
      </FormControl>
    </Container>
  )
}

export default memo(CommonInfo)