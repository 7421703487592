import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as imageEditorActions } from "../../../../redux/modules/imageEditor";

const Wrap = styled.div`
  position: absolute;
  top: 80px;
  left: 445px;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: ${props => props.bgColor};
`

const TrashColumn = ({ id }) => {
  const dispatch = useDispatch()

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "Our first type",
    drop: () => { 
      return { id: id }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: (item) => {
      const { currentColumnName } = item;
      return (
        (currentColumnName !== 'trash')
      );
    }
  });

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return '#d42f2e';
      } else if (!canDrop) {
        return "red";
      }
      // return '#d42f2e';
    } else {
      return '#e0e0e0';
    }
  };
  
  return (
    <Wrap ref={drop} bgColor={getBackgroundColor} >
      <DeleteIcon sx={{fontSize: '30px', color: 'black'}} />
    </Wrap>
  )
}

export default TrashColumn