import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'

import { useQuery } from 'react-query'
import { getAPI } from '../../../api/getAPI'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any[]
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[]}: tableBodyProps) => {
  const navigate = useNavigate()
  const onClick = (projectId: number, projectType: string) => {
    // navigate(`/workspace/project/${projectType}/${projectId}/content?page=1&subject=`)
  }
  const goToMatching = (e: any, id: number, type: string, title: string, is_books: boolean) => {
    e.stopPropagation()
    navigate({
      pathname: `/project/matching/workspace`,
      search: `?${createSearchParams({projectId: id, role: type === 'labeling' ? 0 : 1, title: title, is_book: is_books})}`
    })
  }
  let no = page * rowsPerPage + 1
  let new_list: any[] = []
  list.length > 0 && list.map((row: any, index: number) => {
    const row_comp = (type: string, no: number, id: number, title: string, is_books: boolean, term: string) => {
      const comp = 
        <Row
          key={id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          onClick={()=>onClick(id, type)}
        >
          <Cell align="center">{no}</Cell>
          <Cell align="left">{title} - {type === 'labeling' ? '1차' : '2차'}</Cell>
          <Cell align="center">{is_books ? '문제집' : '기출'}</Cell>
          <Cell align="center">{term}</Cell>
          <Cell align="center">
            <Btn onClick={(e)=>goToMatching(e, id, type, title, is_books)} >
              {type === 'labeling' ? 'pdf 리스트 ❯' : '문제 리스트 ❯'}
            </Btn>
          </Cell>
        </Row>
      new_list.push(comp)
    }
    if (row.labeling_data) {
      row_comp('labeling', no, row.labeling_data.id, row.title, row.is_books, row.labeling_data.term)
      no += 1
    }
    if (row.review_data) {
      row_comp('review', no, row.review_data.id, row.title, row.is_books, row.review_data.term)
      no += 1
    }
  })

  return new_list
}

const ProjectListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _search = searchParams.get('search')
  const _status = Number(searchParams.get('status'))

  const headList = ['프로젝트명', '서비스', '기간', '']
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [keyword, setKeyword] = useState(_search)

  // ----- filter -----
  const [status, setStatus] = useState(_status)
  const statusList = [
    {name: '전체', value: 0},
    {name: '대기', value: 1},
    {name: '진행 중', value: 2},
    {name: '완료', value: 3},
  ]

  const { isFetching } = useQuery(['project', page, keyword, status], () => getAPI('projects', {page: page + 1, unit: 1, project_name: keyword, status: status}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.projects} />
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
    }
  })

  const changedPage = (v: number) => {
    setSearchParams({
      page: v + 1, 
      search: keyword,
      status: status, 
    })
  }

  const changedKeyword = (v: string) => {
    setSearchParams({
      page: 1, 
      search: v,
      status: status,
    })
  }

  const changedStatus = (v: number) => {
    setSearchParams({
      page: 1, 
      search: keyword,
      status: v, 
    })
  }

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setKeyword(_search)
  }, [_search])

  useEffect(() => {
    setStatus(_status)
  }, [_status])


  return (
    <MainSection>
      <>
        <Toolbar title={'생성된 프로젝트'} isSearch keyword={keyword} getKeyword={changedKeyword} isFilter filter={status} filterList={statusList} getFilter={changedStatus} filterLabel={'필터'} filterBoxWidth={'9rem'} />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

export default ProjectListPage