import styled from 'styled-components'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Content from './Content'

const theme = createTheme()

const Layout = () => {

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 20,
            // width: '100vw',
            // margin: 0,
            height: '100vh',
            overflow: 'auto',
            // border: '1px solid pink'
          }}
        >
          <Toolbar />
          <Container>
            <Content />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const Container = styled.div`
  margin: 0 20px 300px;
`

export default Layout
