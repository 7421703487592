import axios from 'axios'
import { produce } from 'immer'
import { Axios } from '../../api/api.config'
import { createAction, handleActions } from 'redux-actions'

// import { API } from "./api";

// actions
const IS_VISIBLE = "IS_VISIBLE";
const SET_BUBBLE_TALK = "SET_BUBBLE_TALK";
const IS_BUBBLE_TALK = "IS_BUBBLE_TALK";

// actionCreators
export const isVisible = createAction(IS_VISIBLE, (boolean) => ({ boolean }));
export const setBubbleTalk = createAction(SET_BUBBLE_TALK, (obj) => ({ obj }));
export const isBubbleTalk = createAction(IS_BUBBLE_TALK, (boolean) => ({ boolean }));

// initialState
const initialState = {
  visible: true,
  bubble_talk: {},
  is_bubble_talk: false,
};

// 게시글 제목 + 내용
const getBubbleTalkAPI = (questionId) => {
  const token = localStorage.getItem('access_token')
  const path = `/labeling/error/alarm/${questionId}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      console.log('-----------------------------------')
      if (res.status === 200) {
        await dispatch(setBubbleTalk(res.data))
        if (!res.data.title) await dispatch(isBubbleTalk(false))
        if (res.data.title) await dispatch(isBubbleTalk(true))
      }
    } catch (err) {
      console.log(err, "getBubbleTalkAPI")
    }
  }
}

// reducer
export default handleActions(
  {
    [IS_VISIBLE]: (state, action) =>
      produce(state, (draft) => {
        draft.visible = action.payload.boolean;
      }),
    [SET_BUBBLE_TALK]: (state, action) =>
      produce(state, (draft) => {
        draft.bubble_talk = action.payload.obj;
      }),
    [IS_BUBBLE_TALK]: (state, action) =>
      produce(state, (draft) => {
        draft.is_bubble_talk = action.payload.boolean;
      }),
  },
  initialState
);

const actionCreators = {
  isVisible,
  isBubbleTalk,
  setBubbleTalk,
  getBubbleTalkAPI
};

export { actionCreators };
