import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { completion } from '../../../../utils/EditorUtils'

import { foldKeymap } from "@codemirror/fold"
import { EditorState } from "@codemirror/state"
import { indentOnInput } from "@codemirror/language"
import { useCodeMirror } from "@uiw/react-codemirror"
import { bracketMatching } from "@codemirror/matchbrackets"
import { history, historyKeymap } from "@codemirror/history"
import { defaultHighlightStyle } from "@codemirror/highlight"
import { highlightSelectionMatches } from "@codemirror/search"
import { closeBracketsKeymap } from "@codemirror/closebrackets"
import { defaultKeymap, indentWithTab } from "@codemirror/commands"
import { rectangularSelection } from "@codemirror/rectangular-selection"
import { autocompletion, completionKeymap, closeCompletion } from "@codemirror/autocomplete"
import { highlightSpecialChars, drawSelection, dropCursor, highlightActiveLine, keymap, EditorView as CDEV } from "@codemirror/view"

const basicSetups = [
  /*@__PURE__*/ highlightSpecialChars(),
  // /*@__PURE__*/ history(),
  /*@__PURE__*/ drawSelection(),
  /*@__PURE__*/ dropCursor(),
  /*@__PURE__*/ EditorState.allowMultipleSelections.of(true),
  /*@__PURE__*/ indentOnInput(),
  // defaultHighlightStyle.fallback,
  // /*@__PURE__*/ bracketMatching(),
  /*@__PURE__*/ autocompletion(),
  // /*@__PURE__*/ rectangularSelection(),
  /*@__PURE__*/ highlightActiveLine(),
  /*@__PURE__*/ highlightSelectionMatches(),
  /*@__PURE__*/ keymap.of([indentWithTab, ...closeBracketsKeymap, ...defaultKeymap, ...historyKeymap, ...foldKeymap, ...completionKeymap]),
]

const EditorContent = (props) => {
  const ref = useRef(null)
  const { text, onChange, focus, eventKey } = props

  const { view, setContainer } = useCodeMirror({
    container: ref.current,
    extensions: [...basicSetups, autocompletion({ override: [completion] })],
    basicSetup: false,
    value: text,
    onChange: onChange,
    autoFocus: focus,
  })

  useEffect(() => {
    if (ref.current) {
      setContainer(ref.current)
      
      if (eventKey) {
        ref.current.addEventListener("keydown", eventKey)
        ref.current.addEventListener("keyup", eventKey)
      }
    }
  }, [ref.current])

  useEffect(() => {
    if (focus) ref.current.focus()
  }, [focus])

  return (
    <div 
      ref={ref}
      onBlur={() => closeCompletion(view)}
    >
    </div>
  )
}

export default EditorContent