import React, { createContext, useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fabric } from 'fabric'
import { useParams } from 'react-router'

export const CropContextStore = createContext();

const CropContext = (props) => { 
  const [ rect, setRect ] = useState(null)
  const [ image, setImage ] = useState(null)
  const [ imageSize, setImageSize ] = useState([])
  const [ canvasSize, setCanvasSize ] = useState([])
  const [ cropCanvas, setCropCanvas ] = useState(null)
  const [ croppedImageList, setCroppedImageList ] = useState([])
  const [ workspaceScrollY, setWorkspaceScrollY ] = useState(0)

  const createRect = () => {
    
    removeRect()
    // if (rect !== null) return
    const newRect = new fabric.Rect({
      width: 190,
      height: 100,
      fill: 'rgba(166, 212, 255, 0.2)',
      top: workspaceScrollY, // new - 좌측 상단 위치
      right: 0, // new - 좌측 상단 위치
    })

    newRect.setControlsVisibility({mtr: false})
    cropCanvas.add(newRect)
    cropCanvas.setActiveObject(newRect)
    setRect(newRect)
  }
  const removeRect = () => {
    if (rect === null) return
    cropCanvas.setActiveObject(rect)
    const activeRect = cropCanvas.getActiveObject()
    cropCanvas.remove(activeRect)
    setRect(null)
  }

  const initTextCanvas = async (img, moreLong) => {
    removeRect()
    let new_image = new Image()
    new_image.onload = function () {
      const new_img = new fabric.Image(new_image, {
        // width: moreLong === 'width' ? 'fit-content' : '100%',
        // height: moreLong === 'height' ? '100%' : 'fit-content',
        scaleX: cropCanvas.getWidth(),
        scaleY: cropCanvas.getHeight(),
        objectCaching: false,
        originX: 'left',
        originY: 'top'
      })
      cropCanvas.add(new_img)
    }
    
    new_image.src = img
  }

  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none'

    // if (cropCanvas && rect) cropCanvas.centerObject(rect) // rect 정가운데 위치

    // if (image && imageSize.length > 0 && canvasSize.length > 0 && !cropCanvas) {
    if (image && imageSize.length > 0 && !cropCanvas) {
      console.log('-------------&&&&&&_______', cropCanvas)

      const newFabricCanvas = new fabric.Canvas(`crop_canvas`, {
        width: imageSize[0],
        height: imageSize[1],
        uniformScaling: false,
      })
      setCropCanvas(newFabricCanvas)
      
      fabric.Object.prototype.cornerSize = 7;
      fabric.Object.prototype.cornerColor = 'white';
      fabric.Object.prototype.cornerStyle = 'circle';
      fabric.Object.prototype.borderColor = 'rgba(140, 180, 255, 1)';
      fabric.Object.prototype.cornerStrokeColor = 'rgba(166, 212, 255, 0.8)';
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.setControlsVisibility({mtr: false});

      if (newFabricCanvas._objects.length > 0) return

      // --- create default rect ---
      // const rect = new fabric.Rect({
      //   width: 190,
      //   height: 100,
      //   fill: 'rgba(166, 212, 255, 0.2)',
      //   top: 0, // new - 좌측 상단 위치
      //   right: 0, // new - 좌측 상단 위치
      // })

      // rect.setControlsVisibility({mtr: false})
      // newFabricCanvas.add(rect)
      // // newFabricCanvas.centerObject(rect) // before - rect 정가운데 위치
      // newFabricCanvas.setActiveObject(rect)
      // setRect(rect)
      //rect 크기 초기화 해주기
      //rect 크기 초기 사이즈 줄이기

      newFabricCanvas.on('object:moving', function (e) {
        let obj = e.target;
         // if object is too big ignore
        if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width){
          return;
        }        
        obj.setCoords();        
        // top-left  corner
        if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
          obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top)
          obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left)
        }
        // bot-right corner
        if(obj.getBoundingRect().top+obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
          obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top)
          obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left)
        }
      });
      // http://jsfiddle.net/nasir101m/gaz704v7/
    }


    return () => {
      document.body.style.overscrollBehaviorX = 'auto'
    }
  }, [
    image, 
    imageSize, 
    cropCanvas,
  ])
  

  const ImageData = {
    rect,
    setRect,
    image,
    setImage,
    imageSize,
    setImageSize,
    canvasSize,
    setCanvasSize,
    cropCanvas,
    setCropCanvas,
    croppedImageList,
    setCroppedImageList,
    createRect,
    removeRect,
    initTextCanvas,
    setWorkspaceScrollY,
  }

  return (<CropContextStore.Provider value={ImageData}>{props.children}</CropContextStore.Provider>);
};

export default CropContext;