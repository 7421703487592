import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-named-as-default
// import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
  button {
    all: unset;
    cursor: pointer;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`

export default GlobalStyle
