import styled, { keyframes } from "styled-components/macro"

const slideRight = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
`;
const slideLeft = keyframes`
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  padding: 160px 0 40px;
  width: 520px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border: 1px solid red; */
  transition: all 2s ease;

  animation-duration: 0.30s;
  animation-timing-function: ease;
  animation-name: ${props => props.open ? slideRight : slideLeft};
  animation-fill-mode: forwards;
`