import { useState, useEffect, useCallback, useRef, Component } from 'react'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAPI, postAPI, putAPI, deleteAPI } from '../../../../api'

import _ from 'lodash'
import { Axios } from '../../../../api/api.config'
import styled, {keyframes} from 'styled-components'
import uploadIcon from '../../../../assets/icons/upload.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'
import deleteDisabledIcon from '../../../../assets/icons/delete-disabled.svg'
import runningHorse from '../../../../assets/images/horse/running_horse_clean_01.gif'
import MainSection from '../../../../components/_templates/MainSection'
import Toolbar from '../../../../components/_organisms/mainSection/Toolbar'

import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Detail = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { id } = useParams()
  const _id = id ? Number(id) : null // number
  const [searchParams] = useSearchParams()
  const _service = searchParams.get('service')
  const _tab = searchParams.get('tab')
  const _name = localStorage.getItem("upload-creation-name")
  const host = 'https://d25wu8avft9qw6.cloudfront.net/'

  useEffect(()=>{
    return () => {
      localStorage.removeItem('upload-creation-name')
    }
  }, [])

  const [initialData, setInitialData] = useState<any>(null) // only in thai

  const [isEditable, setIsEditable] = useState<boolean>(true)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [pdfFiles, setPdfFiles] = useState([])
  const [pdfCount, setPdfCount] = useState<number>(0)
  const [isFetchingPOST, setIsFetchingPOST] = useState<boolean>(false)

  const { mutate: POST_API } = useMutation(postAPI)
  const { mutate: DELETE_API } = useMutation(deleteAPI)

  const {isFetching} = useQuery([`upload/${_id}/${_service}/pdf`, {_id, _service}], () => 
    getAPI(`upload/${_id}/${_service}/pdf`, {name: _name}), {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!(_id),
      onSuccess: data => {
        console.log('성공했다', data?.data)
        if (data?.status !== 200 && data?.data.uploads.length === 0) return
     
        if (data?.data.is_initial) {
          setInitialData(data?.data.uploads)
          return
        }
        
        setIsEditable(data?.data.is_editable)

        const school_id_list = data?.data.uploads.map((e: any) => e.school_id).reduce((acc, v) => {
          return acc.includes(v) ? acc : [...acc, v];
        }, [])

        groupIntoSections(school_id_list, data?.data.uploads)
      }
    }
  )

  const groupIntoSections = (
    school_id_list: number[], 
    files: {pdf_id: number, school_id: number, school_name: string, url: string}[]
  ) => {
    const groups = school_id_list.map((e: any) => {
      let data = {
        id: e,
        name: '',
        question: null,
        explanation: null,
        concept: null,
      }

      files.forEach((v: any) => {
        if (v.school_id === e) {
          data.name = v.school_name
          const isQuestion = v.url.includes('문제.pdf')
          if (isQuestion) data.question = v
          const isExplanation = v.url.includes('해설.pdf')
          if (isExplanation) data.explanation = v
          const isConcept = v.url.includes('개념.pdf')
          if (isConcept) data.concept = v
        }
      })

      return data
    })
    const total_count = count_files(groups)
    setPdfCount(total_count)
    setPdfFiles(groups)
  }

  const save = () => {
    let new_data: any[] = []
    const isPass = pdfFiles.find((e: any) => {
      if (!e.question) return true
      delete e.id
      delete e.name
      new_data.push(e)
      return false
    })
    if (isPass) return alert('문제 또는 해설이 비어있어요!')
    if (!pdfCount) return alert('pdf를 업로드 해주세요.')
    if (window.confirm('최종 저장을 하시겠습니까? 저장이 완료된 후에는 수정이 불가능합니다!') === false) return

    let data = {
      apiURL: `/upload/${_id}/${_service}/grouping`,
      body: new_data
    }

    POST_API(data, {
      onSuccess: (data) => {
        if (data.status === 200) {
          queryClient.invalidateQueries(`upload/${_id}/${_service}/pdf`)
          alert('저장이 완료되었습니다.')

          let data = {
            apiURL: `/upload/${_id}/${_service}/pdf`,
            body: { type: filter }
          }

          POST_API(data)

          const pathQuerystring = localStorage.getItem('prevPath')
          if (pathQuerystring === 'null' || pathQuerystring === null || pathQuerystring.includes('/upload/pdf/modification')) {
            navigate('/upload/pdf?service=books&page=1&search_filter=1&search=&filter=0', {replace: true})
          } else {
            navigate(pathQuerystring, {replace: true})
          }
        }
      },
      onError: (err) => {
        if (err.response.data.error === "not exist section and subsection") 
          alert('중단원 링킹이 되지 않았습니다. 중단원 링킹 작업을 먼저 진행해주세요.')
      }
    })
  }

  const count_files = (v: any) => {
    let total_count = 0
    v.forEach(e => {
      if (e.question) total_count += 1
      if (e.explanation) total_count += 1
      if (e.concept) total_count += 1
    })
    return total_count
  }

  const deleteFile = (school_id: number, img_data: {pdf_id: number, url: string}, type: string) => {
    console.log(school_id, type)
    let data = {
      apiURL: `/upload/file?upload_id=${_id}&&type=${'pdf'}&&service=${_service}&&pdf_id=${img_data.pdf_id}&&pre_url=${img_data.url}`,
    }

    DELETE_API(data, {
      onSuccess: (data) => {
        console.log(data)
        if (data.status !== 200) return

        setPdfFiles(prev => {
          const new_list = prev.map((e: any) => {
            if (e.id === school_id) e[type] = null
            return e
          })
          const total_count = count_files(new_list)
          setPdfCount(total_count)
          return [...new_list]
        })
      },
    })
  }

  const uploadFile = (e: any, school_id: number, img_data: any, type: string) => {
    const new_file = e.target.files[0]
    if (!new_file) return

    const isQuestion = new_file.name.normalize('NFC').includes('문제.pdf')
    const isAnswer = new_file.name.normalize('NFC').includes('답지.pdf')
    const isExplanation = new_file.name.normalize('NFC').includes('해설.pdf')
    const isConcept = new_file.name.normalize('NFC').includes('개념.pdf')
    if (!isQuestion && !isAnswer && !isExplanation && !isConcept) return alert('파일명을 확인해 주세요. (ex. 문제.pdf, 답지.pdf)')

    setIsFetchingPOST(true)

    const formData: any = new FormData()
    formData.append(`file`, new_file)

    let data = {
      apiURL: `/upload/qb/file?upload_id=${_id}&&school_id=${school_id}`,
      body: formData
    }

    POST_API(data, {
      onSuccess: (data) => {
        console.log(data)
        if (data?.status !== 200) return
        const _data = data?.data
        const new_data = {pdf_id: _data.pdf_id, url: _data.url}

        const school_id = _data.school_id

        setPdfFiles(prev => {
          const new_list = prev.map((e: any) => {
            if (e.id === school_id) e[type] = new_data
            return e
          })
          const total_count = count_files(new_list)
          setPdfCount(total_count)
          return [...new_list]
        })
        setIsFetchingPOST(false)
      },
      onError(err) {
        if (err.response.data.error === 'already exist file') {
          setIsFetchingPOST(false)
          alert('이미 업로드한 이미지입니다.')
        }
      },
    })
  }

  const split_name = (name: string) => {
    if (!name) return ''
    return name.split(host)[1]
  }

  const onPreview = (url: string) => {
    if (!url) return
    setPreviewImage(url)
    setIsPreview(true)
  }
  const offPreview = () => {
    setIsPreview(false)
    setNumPages(0)
    setPageNumber(1)
  }

  const [numPages, setNumPages] = useState<number>(0) // 전체 페이지 수
  const [pageNumber, setPageNumber] = useState<number>(1) // 현재 페이지

  const onClickPageArrow = (e: any, position: string) => {
    e.stopPropagation()
    if (position === 'left') setPageNumber(pageNumber === 1 ? pageNumber : pageNumber - 1)
    if (position === 'right') setPageNumber(numPages === pageNumber ? pageNumber : pageNumber + 1)
  }

  const getHorse = (n: number) => {
    if (n === 0) {
      return (
        <img 
          id='ddung'
          alt='running_horse'
          src='https://c.tenor.com/ijacVPaTqKIAAAAi/%EB%9A%B1%EC%9D%B4%EC%9B%80%EC%A7%A4.gif' 
        />
      )
    }
    if (n === 1) {
      return (
        <img id='brown_horse' alt='running_horse' src={runningHorse} />
      )
    }
    if (n === 2) {
      return (
        <img 
          id='pikachu'
          alt='running_horse'
          src='https://media.tenor.com/fSsxftCb8w0AAAAi/pikachu-running.gif' 
        />
      )
    }
  }

  const [school, setSchool] = useState<string>('') // school input value
  const [schoolId, setSchoolId] = useState<number>(0) // 선택한 학교 아이디
  const [schoolName, setSchoolName] = useState<string>('') // 선택한 학교 이름
  const [isActiveSearchBox, setIsActiveSearchBox] = useState(false)

  const {data: search_school_data} = useQuery([`schools`, {school}], () => getAPI('schools', {name: school}), {
    enabled: !!(school && school.length > 1 && school !== '고등' && school !== '학교'),
    select: data => {
      return data?.data
    },
  })

  const debounce = (callback: any, duration: number) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(()=>callback(...args), duration)
    }
  }

  const handleSchool = useCallback((e: any) => {
    debounce(setSchool(e.target.value), 500)
    if (isActiveSearchBox) return
    setIsActiveSearchBox(true)
  }, [school])

  const onClickSchool = (id: number, name: string) => {
    setIsActiveSearchBox(false)
    setSchool(name)
    setSchoolId(id)
    setSchoolName(name)
  }

  const handleGroup = () => {
    console.log(schoolId)
    if (schoolId === 0) return
    const new_group = {
      id: schoolId, 
      name: schoolName,
      question: null,
      explanation: null,
      concept: null,
    }
    const isAddible = !!(!pdfFiles.find((e: any) => e.id === schoolId))

    if (!isAddible) return alert('이미 존재하는 학교입니다.')

    setPdfFiles(prev => {
      return [...prev, new_group]
    })
    setSchool('')
    setSchoolId(0)
    setSchoolName('')
  }
  
  const [filter, setFilter] = useState(1)  // 1: 기존, 2: v3
  const filters = [
    {value: 1, name: '적용 X'},
    {value: 2, name: '적용 O'},
  ]
  const getFilter = (v: number) => {
    console.log(v)
    setFilter(v)
  }

  return (
    <MainSection isHidden>
      {!isFetching 
        ?
        <>
          <Toolbar
            title='PDF 업로드'
            isSave={isEditable && pdfCount > 0}
            save={()=>save()}
            isFilter
            filter={filter}
            filterList={filters}
            filterLabel={'오토라벨링 여부'}
            filterBoxWidth='10rem'
            getFilter={getFilter}
            // isButtonCus={isEditable && pdfCount > 0}
            // buttonName={'Reset'}
            // onClickButton={()=>reset()} 
          />
          <Container>
            {isFetchingPOST && (
              <div className='back-loading'>
                <LoadingHorse id='loading_horse'>
                  {getHorse(Math.floor(Math.random()*3))}
                </LoadingHorse>
              </div>
            )}
            {isPreview &&
              <Preview>
                <div className='preview-page-wrap'>
                  <div className='preview-exit' onClick={offPreview}>
                    <img alt='preview-delete' src={deleteIcon} />
                  </div>
                  <div className='preview-pagenation'>
                    <div className='preview-pagenation-arrow' onClick={(e)=>onClickPageArrow(e, 'left')}>{'<'}</div>
                    <div className='preview-pagenation-center'>Page {pageNumber} of {numPages}</div>
                    <div className='preview-pagenation-arrow' onClick={(e)=>onClickPageArrow(e, 'right')}>{'>'}</div>
                  </div>
                  <Document
                    className='preview-document'
                    file={`${previewImage}?random=${Math.random()}`}
                    onLoadSuccess={pdf => setNumPages(pdf.numPages)}
                  >
                    <Page className='preview-page' pageNumber={pageNumber} /> 
                  </Document>
                </div>
              </Preview>
            }
            <div className='title'>{_name}</div>

            <div className='info-container'>
              <div className='info-count'><span>{pdfFiles.length}</span>개의 Group, <span>{pdfCount}</span>개의 PDF가 생성되었습니다.</div>
              <div className={['form_box', 'search'].join(' ')}>
                {/* <label htmlFor='form_school'>학교</label> */}
                <input type='text' name='school' placeholder='학교를 입력해주세요.' id='form_school' className='form_school' value={school} onChange={handleSchool} />
                {isActiveSearchBox && school.length > 1 && search_school_data?.length > 0 &&
                  <div className='search_result_box'>
                    {search_school_data?.map((e: any)=>{
                      return <button key={e.id} type='button' className='button_result_item' onClick={()=>onClickSchool(e.id, e.name)}>{e.name}</button>
                    })}
                  </div>
                }
              </div>
              {isEditable && 
                <button className='add-group-button' onClick={handleGroup}>그룹 만들기</button>
              }
            </div>

            <div className='wrap-scroll'>
              {initialData && initialData.map((e: any) => {
                return (
                  <div className='content-box-initial' key={e.pdf_id}>
                    <div className={['item-name'].join(' ')} onClick={()=>onPreview(e.url)}>{split_name(e.url)}</div>
                  </div>
                )
              })}
              {pdfFiles.map((e: any, index: number) => {
                return (
                  <div className='content-box' key={e.name}>
                    <div className='content-name'>{e.name}</div>
                    <div className='content-files-box'>
                      <div className='content-files-item'>
                        <div className='item-title'>문제</div>
                        <div className={['item-name', !e.question && 'essential'].join(' ')} onClick={()=>onPreview(e.question?.url)}>{split_name(e.question?.url)}</div>
                        {isEditable && e.question &&
                          <div className='item-icon' onClick={()=>deleteFile(e.id, e.question, 'question')}>
                            <img alt='icon-delete' src={deleteDisabledIcon} />
                          </div>
                        }
                        {isEditable && (
                          <>
                            <label className='item-icon' htmlFor={`input-file-question-${e.name}`}>
                              <img alt='icon-upload' src={uploadIcon} />
                            </label>
                            <input type='file' id={`input-file-question-${e.name}`} style={{display: 'none'}} onChange={(v)=>uploadFile(v, e.id, e.question, 'question')} />
                          </>
                        )
                        }
                      </div>
                      <div className='content-files-item'>
                        <div className='item-title'>해설</div>
                        <div className={['item-name', !e.explanation && 'essential'].join(' ')} onClick={()=>onPreview(e.explanation?.url)}>{split_name(e.explanation?.url)}</div>
                        {isEditable && e.explanation &&
                          <div className='item-icon' onClick={()=>deleteFile(e.id, e.explanation, 'explanation')}>
                            <img alt='icon-delete' src={deleteDisabledIcon} />
                          </div>
                        }
                        {isEditable && (
                          <>
                            <label className='item-icon' htmlFor={`input-file-explanation-${e.name}`}>
                              <img alt='icon-upload' src={uploadIcon} />
                            </label>
                            <input type='file' id={`input-file-explanation-${e.name}`} style={{display: 'none'}} onChange={(v)=>uploadFile(v, e.id, e.explanation, 'explanation')} />
                          </>
                        )}
                      </div>
                      <div className='content-files-item'>
                        <div className='item-title'>개념</div>
                        <div className={['item-name', !e.concept && 'is-empty'].join(' ')} onClick={()=>onPreview(e.concept?.url)}>{split_name(e.concept?.url)}</div>
                        {isEditable && e.concept &&
                          <div className='item-icon' onClick={()=>deleteFile(e.id, e.concept, 'concept')}>
                            <img alt='icon-delete' src={deleteDisabledIcon} />
                          </div>
                        }
                        {isEditable && (
                          <>
                            <label className='item-icon' htmlFor={`input-file-concept-${e.name}`}>
                              <img alt='icon-upload' src={uploadIcon} />
                            </label>
                            <input type='file' id={`input-file-concept-${e.name}`} style={{display: 'none'}} onChange={(v)=>uploadFile(v, e.id, e.concept, 'concept')} />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Container>
        </>
        : <></>
      }
    </MainSection>
  )
}

const move = keyframes`
	0%{
    left: 0;
  }
  100%{
    left: 95%;
  }
`
const LoadingHorse = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 0.2s;
  animation-name: ${move};
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  #ddung {
    width: 3rem;
    height: 4rem;
  }
  #brown_horse {
    width: 3rem;
    height: 2rem;
  }
  #pikachu {
    width: 3.7rem;
    height: 3rem;
  }
`
const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;

  .preview-document {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
  .preview-page-wrap {
    position: relative;
    padding: 2rem 3rem 5rem;
    height: 100%;
  }
  .preview-exit {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.3rem;
    border-radius: 4px;
    background-color: #eee;
    cursor: pointer;

    :hover {
      background-color: white;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .preview-pagenation {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .preview-pagenation-arrow {
      width: 4rem;
      height: 2rem;
      color: #555;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #eee;
      cursor: pointer;

      :hover {
        background-color: white;
      }
    }
    .preview-pagenation-center {
      width: fit-content;
      color: white;
    }
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
  .react-pdf__Page__textContent {
    display: none;
  }
`
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  height: calc(100% - 4rem);

  .file_upload_wrap {
    position: relative;
    height: 100%;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;

      :hover {
        background-color: rgba(0, 0, 0, 0.14);
      }
    }
    #fileUpload {
      display: none;
    }
  }

  .back-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
  }
  
  .title {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-weight: 600;

    .info-count {
      font-size: 0.9rem;
      padding: 4px 8px;
      width: fit-content;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.07);

      span {
        color: #4ab7a9;
        font-size: 1.1rem;
      }
    }
    #upload_S3 {
      display: none;
    }

    .add-group-button {
      cursor: pointer;
      padding: 0 0.6rem;
      width: fit-content;
      height: 100%;
      color: white;
      font-size: 0.9rem;
      border-radius: 4px;
      background-color: #4ab7a9;

      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background-color: #3fac9d;
      }
    }

    .search {
      position: relative;
      width: 20rem;
    }
    .search_label_info {
      position: absolute;
      top: 1px;
      left: 1.8rem;
      color: #BDBDBD;
      font-size: 11px;
    }
    .search_result_box {
      padding: 0.3rem;
      position: absolute;
      top: 2.5rem;
      left: 0;
      width: 100%;
      max-height: 24.5rem;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      overflow-y: auto;
      z-index: 100;

      .button_result_item {
        font-size: 14px;
        font-weight: 500;
        padding: 0.4rem 0.6rem;
        border-radius: 4px;
        background-color: white;
      }
      .button_result_item:hover {
        background-color: #FFECEC;
      }
      .active {
        background-color: #FFECEC;
      }
    }

    .form_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.2rem;

      label {
        width: 2rem;
        font-size: 0.8rem;
        font-weight: 600;
      }

      .form_school {
        padding: 0.6rem 0.5rem;
        width: 100%;
        font-size: 13px;
        border-radius: 4px;
        border: 1px solid #E1E1E1;
        cursor: text;
      }
    }
  }

  .wrap-scroll {
    height: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .content-box-initial {
      /* width: 100%; */
      .item-name {
        width: fit-content;
        min-height: 1.8rem;
        padding: 8px 16px;
        border-radius: 4px;
        background-color: #e8f1ff;
        cursor: pointer;
        :hover {
          background-color: #d7eaff;
        }
      }
    }

    .content-box {
      padding: 0.7rem;
      max-width: 50rem;
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.4);

      .content-name {
        font-weight: 600;
      }
      .content-files-box {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .content-files-item {
          padding: 4px 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          /* gap: 0.5rem; */

          .item-title {
            min-width: fit-content;
            font-size: 0.8rem;
            font-weight: 600;
          }
          .item-name {
            width: 100%;
            margin: 0 0.5rem 0 0.7rem;
            min-height: 1.8rem;
            padding: 4px 8px;
            border-radius: 4px;
            background-color: #e8f1ff;
            cursor: pointer;
            :hover {
              background-color: #d7eaff;
            }
          }
          .essential {
            background-color: #ffcbcb;
            border: 1px solid #ffc1c1;
            cursor: default;
            :hover {
              background-color: #ffcbcb;
            }
          }
          .is-empty {
            background-color: rgba(0, 0, 0, 0.07);
            cursor: default;
            :hover {
              background-color: rgba(0, 0, 0, 0.07);
            }
          }
          .item-icon {
            width: 2rem;
            height: 1.7rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
            :hover {
              border: 1px solid #fff;
              background-color: rgba(0, 0, 0, 0.1);
            }

            img {
              width: 1.2rem;
              height: 1.2rem;
            }
          }

          .input-file-one {
            border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }
    }
  }
`

export default Detail