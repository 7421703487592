import { useState, createRef, forwardRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

const slideRight = keyframes`
  from {
    padding-left: 0;
  }
  to {
    padding-left: 490px;
  }
`;
const slideLeft = keyframes`
  from {
    padding-left: 490px;
  }
  to {
    padding-left: 0;
  }
`;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.22);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 2s ease;
  z-index: 50;

  animation-duration: 0.30s;
  animation-timing-function: ease;
  animation-name: ${props => props.open ? slideRight : slideLeft};
  animation-fill-mode: forwards;
`
const ContentFrame = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */

  overflow: auto visible;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const ItemList = styled.div`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 0 20px;
  /* border: 1px solid blue; */
`
const Item = styled.img`
  width: 100px;
  height: 100%;
  border-radius: 3px;
  transition: 150ms ease;
  background-color: transparent;
  border: ${(props) => props.border ?  '3px solid rgba(166, 212, 255, 1)' : '3px solid rgba(0, 0, 0, 0.08)'};
  cursor: pointer;

  :hover {
    transform: scale(1.07);
  }
`
const WrapItem = styled.div`
  width: 90px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PageNum = styled.div`
  padding-top: 2px;
  color: ${(props) => props.bool ? 'rgba(44, 138, 242, 0.96)' : 'rgba(0, 0, 0, 0.6)'};
`

const FooterImageList = (props) => {
  const { is_sidebar, question_list, scrollToPage, activeCanvas } = props
  const imageUrl = `https://qb-server-media.s3.ap-northeast-2.amazonaws.com/`
  const [ selected, setSelected ] = useState(0)
  const clickImg = (idx) => {
    setSelected(idx)
    scrollToPage(idx)
  }

  useEffect(() => {
    setSelected(activeCanvas)
  }, [activeCanvas])

  return (
    <Container open={is_sidebar}>
      <ContentFrame>
        <ItemList>

          {question_list.length > 0 
            && question_list.map((img, idx) => {
              return (
                <WrapItem key={String(idx)} onClick={() => clickImg(idx)} >
                  <Item 
                    alt={String(idx)} 
                    src={`${imageUrl}${img}`}
                    border={selected === idx} 
                  />
                  <PageNum bool={selected === idx }>{idx + 1}</PageNum>
                </WrapItem>
              )
            })
          }

        </ItemList>
      </ContentFrame>
    </Container>
  )
}

export default FooterImageList