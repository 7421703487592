import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Button from '../elements/button/Button'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Box,
  Grid,
  Paper,
  Modal,
  Container
} from '@mui/material'
import AlertModal from '../elements/modal/AlertModal'
import BackToList from '../elements/button/BackToList'
import MiddleUnitList from '../components/myWorkSpace/midUnit/middleUnit/List'
import MiddleUnitToolbar from '../components/myWorkSpace/midUnit/middleUnit/Toolbar'
import { actionCreators as middleUnitActions } from "../redux/modules/middleUnit";
import { actionCreators as sidebarActions } from "../redux/modules/sidebar";

import { useMutation, useQueryClient } from 'react-query'
import { postAPI } from '../api'
import { postMidUnitAPI, postSaveBooksMidUnitAPI, postWriteAPI, postFinalAPI, postSaveThaiMidUnitAPI } from '../api/postAPI'


const MiddleUnitPage = (props) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const _nation = localStorage.getItem("_nation")
  const _authority = localStorage.getItem("_authority")
  const {'question_id': project_id} = useParams()
  const _project_id = project_id
  const is_books = useSelector((state) => state.questionManage.is_books)
  // const is_books = useSelector((state) => state.middleUnit.is_books)
  const difficult_price = useSelector((state) => state.middleUnit.difficult_price)
  const near_question = useSelector((state) => state.middleUnit.near_question)
  const Q_filter = useSelector((state) => state.questionManage.filter_data)
  const B_filter = useSelector((state) => state.questionManage.B_filter_data)
  console.log(is_books, Q_filter, B_filter)

  const { mutate } = useMutation(postAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.data.message === 'checking success') {
        alert('저장이 완료되었습니다.')
        queryClient.invalidateQueries("alarmList")
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const { mutate: mutateWrite } = useMutation(postWriteAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'message success') {
        alert('저장이 완료되었습니다.')
        closeModal()
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const { mutate: mutateFinal } = useMutation(postFinalAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'success') {
        alert('최종으로 완료하였습니다. 수고 많으셨어요.')
      }
    }
  })

  const [btnActive, setBtnActive] = useState(false)
  const [allData, setAllData] = useState(null)

  const focusButton = (boolean) => {
    setBtnActive(boolean)
  }

  const handleStore = () => {
    let data = {
      apiURL: `/question/review`,
      body: allData
    }
    data.body.difficult_price = selectLevel
    if (data.body.sub_sections.length === 0) return alert('중단원을 입력해 주세요.')
    if (!is_books && btnActive && allData && _nation === '1') return mutate(data)
    if (is_books && btnActive && allData) return mutate(data)
  }

  const handleFinal = () => {
    const memo = prompt('오류가 있다면 메모를 남겨주세요.')
    if (memo === '') { // 확인 -> 작성 X
      const data = {
        question_id: _project_id,
        error_content: null
      }
      mutateFinal(data)
    } else if (memo === null){ // 취소
      return
    } else { // 확인 -> 작성 O
      const data = {
        question_id: _project_id,
        error_content: memo
      }
      mutateFinal(data)
    }
  }

  useEffect(()=>{
    if (difficult_price) setSelectLevel(difficult_price)
  }, [difficult_price])

  useEffect(() => {
    dispatch(sidebarActions.getBubbleTalkAPI(_project_id)) // 사이드바 게시글 말풍선
    dispatch(middleUnitActions.getMiddleUnitOption(_project_id)) // 중단원 옵션 리스트
    if (_project_id.split('_')[0] !== 'TH' && _nation !== '2') dispatch(middleUnitActions.getMiddleUnitData(_project_id, is_books ? B_filter : Q_filter)) // 
    if (_nation === '2') dispatch(middleUnitActions.getThData(_project_id)) // 태국

    return () => {
      dispatch(middleUnitActions.set_init())
    }
  }, [_project_id])


  const titleRef = useRef(null)
  const contentRef = useRef(null)
  const [ errorText, setErrorText ] = useState('')
  const [ modal, setModal ] = useState(false)
  const closeModal = () => {
    if (modal) {
      setModal(false)
    }
  }
  const handleWrite = () => {
    if (btnActive && allData) setModal(true)
  }

  const handleWriteStore = () => {
    if ( titleRef.current.value === '' || contentRef.current.value === '') {
      return alert('제목과 내용을 모두 입력해 주세요.')
    }

    const data = {
      id: _project_id,
      title: titleRef.current.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
      content: contentRef.current.value.replace(/(?:\r\n|\r|\n)/g, '<br/>')
    }
    mutateWrite(data)
  }

  const [ selectLevel, setSelectLevel] = useState(difficult_price)
  const onclickLevel = (level) => {
    setSelectLevel(level)
  }

  const handleMoveToPage = (type) => {
    let path = ''

    if (type === 'list') {
      path = is_books ? '/question/books' : '/question/qb'
      navigate(path)
    }
      
    if (type === 'previous' && near_question?.previous) {
      path = `/workspace/project/review/content/question/${near_question.previous}`
      navigate(path)
    }
    if (type === 'next' && near_question?.next) {
      path = `/workspace/project/review/content/question/${near_question.next}`
      navigate(path)
    }
    
    
  }
  
  return (
    <Grid container direction={"column"} >

      {modal && 
        <Modal
          open={modal}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
          sx={{ outline: 0 }}
        >
          <ModalImgWrap>
            <div className='input_wrap'>
              <input className='title' ref={titleRef} placeholder='제목'></input>
              <Button focus={btnActive} height={'40px'} onclick={handleWriteStore}>보내기</Button>
            </div>
            <textarea className='content' ref={contentRef} placeholder='내용'></textarea>
          </ModalImgWrap>
        </Modal>
      }
        
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>중단원 부여 및 검수</div>
              {_project_id.split('_')[0] !== 'TH' &&
                <BtnBox02>
                  {_authority !== '0' &&
                    <BackToList onclick={()=>{navigate(-1)}}>뒤로가기</BackToList>
                  }
                  {_authority === '0' && (
                    <>
                      {/* <div className='level_wrap'>
                        <div className='level_item_wrap'>
                          <LevelItem className={selectLevel === 1 ? 'selected' : ''} onClick={()=>onclickLevel(1)}>1</LevelItem>
                          <LevelItem className={selectLevel === 2 ? 'selected' : ''} onClick={()=>onclickLevel(2)}>2</LevelItem>
                          <LevelItem className={selectLevel === 3 ? 'selected' : ''} onClick={()=>onclickLevel(3)}>3</LevelItem>
                          <LevelItem className={selectLevel === 4 ? 'selected' : ''} onClick={()=>onclickLevel(4)}>4</LevelItem>
                          <LevelItem className={selectLevel === 5 ? 'selected' : ''} onClick={()=>onclickLevel(5)}>5</LevelItem>
                        </div>
                      </div> */}
                      <BackToList onclick={()=>{handleMoveToPage('list')}}>list</BackToList>
                      <Button onclick={()=>handleMoveToPage('previous')} bgColor={near_question?.previous ? '#444' : '#eee'}>이전</Button>
                      <Button onclick={()=>handleMoveToPage('next')} bgColor={near_question?.next ? '#444' : '#eee'}>다음</Button>
                    </>
                  )}
                  {_nation === '1' &&
                    <Button focus={btnActive} onclick={handleWrite}>글쓰기</Button>
                  }
                  <Button focus={btnActive} onclick={handleStore}>저장하기</Button>
                  {_authority === '0' &&
                    <Button focus={btnActive} onclick={handleFinal} bgColor={'#008F8C'}>최종완료</Button>
                  }
                </BtnBox02>
              }
              
            </BtnBox>
          </Title>

          <MiddleUnitToolbar
            setAllData={setAllData}
            focusButton={focusButton}
            questionId={_project_id}
          />

          <MiddleUnitList />
        </Item>
      </Grid>

      {/* {isModal &&
        <AlertModal content={'저장이 완료되었습니다.'} open={isModal} close={closeModal} move={clickBackToList} />
      } */}
    </Grid>
  )
}
const ModalImgWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  min-width: 60vw;
  max-height: 80%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #f8f7fc;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
  .input_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .title {
    width: 100%;
    height: 40px;
    outline: none;
    padding: 0 1rem;
    font-size: 17px;
    border-radius: 0.4rem;
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  .content {
    width: 100%;
    min-height: 400px;
    resize: none;
    outline: none;
    padding: 1rem;
    font-size: 17px;
    border-radius: 0.4rem;
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
`
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;

  .level_wrap{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* background-color: rgba(41, 119, 255, 0.1); */

    .level_title {
      padding-left: 0.2rem;
      padding-bottom: 0.1rem;
      margin-bottom: 0.5rem;
      color: rgba(41, 119, 255, 0.8);
      font-size: 0.8rem;
      font-weight: 500;
      border-bottom: 1px solid rgba(41, 119, 255, 0.15);
    }
    .level_item_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.4rem;
    }

    .selected {
      color: #fff;
      background-color: #2b78fd;
      border: 1px solid #2b78fd;
    }

    .level_item {
      width: 100%;
      min-width: 1rem;
      max-width: 3rem;
      color: #9c9c9c;
      background-color: #ddd;
      border: 1px solid #ddd;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      :hover {
        color: #fff;
        background-color: #2b78fd;
        border: 1px solid #2b78fd;
      }
    }
  }
`
const LevelItem = styled.div`
  width: 2rem;
  min-width: 1rem;
  max-width: 3rem;
  color: #9c9c9c;
  background-color: #ddd;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    color: #fff;
    background-color: #2b78fd;
    border: 1px solid #2b78fd;
  }
`

export default MiddleUnitPage