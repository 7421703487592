import './skeletonEditor.css'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

const SkeletonImageEditor = () => {

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{height: '100%'}} >
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          gap: '10px',
          minHeight: '100%'
        }}
      >
        <div className='skeleton imageBox' style={{height: '500px'}}></div>
        <div className='skeleton imageBox'></div>
        <div className='skeleton imageBox'></div>
      </Paper>
    </Grid>
  )
}

export default SkeletonImageEditor