import { useEffect, useRef } from 'react'

const useInterval = (callback: any, delay: number | null) => {
  const savedCallback = useRef(callback)

  useEffect(()=> {
    savedCallback.current = callback
  })

  useEffect(()=>{
    if (delay === null) return
    const timer = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(timer)
  }, [delay])
}

export default useInterval