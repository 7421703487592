import { produce } from "immer"
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from "redux-actions"
import { dataToHtmlSelect, creElStringify, dataToSelect, mdToHtml, highlight, splitType, uuidv4, toHtml } from "../../utils/EditorUtils"

// // actions
const IS_LOADING = "IS_LOADING" // 검색 로딩
const IS_MULTIPLE_ESSAY = "IS_MULTIPLE_ESSAY" // 객관식 or 주관식 상태
const SET_QUESTION_CONTENT = "SET_QUESTION_CONTENT" // 검색한 문제 내용

const UPDATE_ESSAY_ANSWER_HTML = "UPDATE_ESSAY_ANSWER_HTML" // 주관식 answer_html 업데이트
const UPDATE_MULTIPLE_ANSWER_RAW = "UPDATE_MULTIPLE_ANSWER_RAW" // multiple_answer_raw 업데이트
const UPDATE_MULTIPLE_ANSWER_HTML = "UPDATE_MULTIPLE_ANSWER_HTML" // 객관식 answer_html 업데이트
const UPDATE_MULTIPLE_DATA_HTML_MD = "UPDATE_MULTIPLE_DATA_HTML_MD"  // 객관식 answer 표, html, md 업데이트
const UPDATE_MULTIPLE_DATA = "UPDATE_MULTIPLE_DATA" // 객관식 answer 표 업데이트

const INIT_QUESTION_NEW = "INIT_QUESTION_NEW" // 수정 전 '문제' 데이터

const INIT_QUESTION_OLD = "INIT_QUESTION_OLD" // 수정된 '문제' 데이터
const INIT_QUESTION_INFO_OLD = "INIT_QUESTION_INFO_OLD" // 수정된 '문제 정보' 데이터
const INIT_MULTIPLE_OLD_DATA_HTML_MD_ANSWER = "INIT_MULTIPLE_OLD_DATA_HTML_MD_ANSWER" // 수정된 '객관식' 데이터

const INIT_ESSAY_OLD_ANSWER = "INIT_ESSAY_OLD_ANSWER" // 수정된 '주관식' 데이터
const INIT_ESSAY_OLD_ANSWER_EXPLANATION = "INIT_ESSAY_OLD_ANSWER_EXPLANATION" // 수정된 '주관식' 데이터

const ADD_BLOCK = "ADD_BLOCK" // 문제 블록 추가
const DELETE_BLOCK = "DELETE_BLOCK" // 문제 블록 제거
const CHANGE_BLOCK_TYPE = "CHANGE_BLOCK_TYPE" // 문제 블록 변경
const WRITE_BLOCK_DATA = "WRITE_BLOCK_DATA" // 문제 블록에 글 작성

const INIT_ALL_DATA = "INIT_ALL_DATA" // 모든 스토어 초기화
const INIT_IMAGE_LIST = "INIT_IMAGE_LIST" // imageList 초기화

const SET_WRITE_YN = "SET_WRITE_YN" // 해당 문제 작성 여부
const SET_TEXT_DATA = "SET_TEXT_DATA" //

const SET_ORDER = "SET_ORDER" // 
const SET_SCORE = "SET_SCORE" // 
const SET_LEVEL = "SET_LEVEL" // 


// // actionCreators
const isLoading = createAction(IS_LOADING, (boolean) => ({ boolean })) // 검색 로딩
const isMoE = createAction(IS_MULTIPLE_ESSAY, (string) => ({ string })) // 객관식 or 주관식 상태
const setQuestionContent = createAction(SET_QUESTION_CONTENT, (content) => ({ content })) // 검색한 문제 내용

const update_Essay_Answer_Html = createAction(UPDATE_ESSAY_ANSWER_HTML, (list, html) => ({ list, html })) // 주관식 answer_html 업데이트
const update_Multiple_Answer_Raw = createAction(UPDATE_MULTIPLE_ANSWER_RAW, (rawList) => ({ rawList })) // multiple_answer_raw 업데이트
const update_Multiple_Answer_Html = createAction(UPDATE_MULTIPLE_ANSWER_HTML, (list, html, rawList) => ({ list, html, rawList })) // 객관식 answer_html 업데이트
const update_Multiple_Data_Html_Md = createAction(UPDATE_MULTIPLE_DATA_HTML_MD, (newArr, html, markdown) => ({ newArr, html, markdown })) // 객관식 answer 표(multiple_data) 업데이트
const update_Multiple_Data = createAction(UPDATE_MULTIPLE_DATA, (list) => ({ list })) // 객관식 answer 표 업데이트

const init_question_new = createAction(INIT_QUESTION_NEW, (data_key, text) => ({ data_key, text })) // 수정 전 '문제' 데이터

const init_question_old = createAction(INIT_QUESTION_OLD, (data_key, text) => ({ data_key, text })) // 수정된 '문제' 데이터
const init_question_info_old = createAction(INIT_QUESTION_INFO_OLD, (order, score, level, question_error, is_multiple_essay) => ({ order, score, level, question_error, is_multiple_essay })) // 수정된 '문제 정보' 데이터
const init_multiple_old_data_html_md_answer = createAction(INIT_MULTIPLE_OLD_DATA_HTML_MD_ANSWER, (multiple_data, multiple_html, multiple_md, multiple_answer, multiple_answer_html) => ({ multiple_data, multiple_html, multiple_md, multiple_answer, multiple_answer_html })) // 수정된 '객관식' 데이터

const init_essay_old_answer = createAction(INIT_ESSAY_OLD_ANSWER, (essay_answer) => ({ essay_answer })) // 수정된 '주관식' 데이터
const init_essay_old_answer_explanation = createAction(INIT_ESSAY_OLD_ANSWER_EXPLANATION, (essay_answer, explanation_exist) => ({ essay_answer, explanation_exist })) // 수정된 '주관식' 데이터

const addBlock = createAction(ADD_BLOCK, (index, block_type) => ({ index, block_type })) // 문제 블록 추가
const deleteBlock = createAction(DELETE_BLOCK, (index, block_type) => ({ index, block_type })) // 문제 블록 제거
const changeBlockType = createAction(CHANGE_BLOCK_TYPE, (type, index, block_type) => ({ type, index, block_type })) // 문제 블록 변경
const writeBlockText = createAction(WRITE_BLOCK_DATA, (data, index, block_type) => ({ data, index, block_type })) // 문제 블록에 글 작성

const init_all_data = createAction(INIT_ALL_DATA, (obj) => ({ obj })) // 모든 스토어 초기화
const init_image_list = createAction(INIT_IMAGE_LIST, (imageList) => ({ imageList })) // imageList 초기화

const setWriteYN = createAction(SET_WRITE_YN, (string) => ({ string })) // 해당 문제 작성 여부
const setTextData = createAction(SET_TEXT_DATA, (string) => ({ string })) // 해당 문제 작성 여부

const setOrder = createAction(SET_ORDER, (int) => ({ int })) // 
const setScore = createAction(SET_SCORE, (int) => ({ int })) // 
const setLevel = createAction(SET_LEVEL, (int) => ({ int })) // 


// // initialState
const initialState = {
  is_loading: true,

  question_content_obj: null,  // 문제에 대한 서브 정보, (구) problem

  data_html: null,
  data: [{ id: uuidv4(), type: "일반", text: "", html: "<div class='block basic'></div>" },],
  data_explanation: [{ id: uuidv4(), type: "일반", text: "", html: "<div class='block basic'></div>" },],
  data_relation: [{ id: uuidv4(), type: "일반", text: "", html: "<div class='block basic'></div>" },],
  
  is_multiple_essay: 'multiple',  // 객관식: multiple, 주관식: essay
  multiple_data: [
    [" ", ""],
    ["①", ""],
    ["②", ""],
    ["③", ""],
    ["④", ""],
    ["⑤", ""],
  ],
  multiple_md: '',  // markdown
  multiple_html: '',
  multiple_answer: [],  // ['①', '②', '③']
  multiple_answer_raw: [],  // [1, 2, 3]
  essay_answer: [""],
  multiple_answer_html: '',  // 답:
  essay_answer_html: '',  // 답:

  // common question info 
  order: 1, 
  score: 0, 
  level: null, 
  // level: 0, 

  question_error: 0,
  explanation_exist: false,

  imageList: [],
  write_yn: '',
  text_data: '',
}

// { question_content_obj } 요청
const getQuestionContentAPI = (searchQuestionId) => {
  console.log(searchQuestionId)
  
  const path = searchQuestionId.split('_')[0] === 'TH' ? `/thai/question/detail/${searchQuestionId}` : `/labeling/question/detail/${searchQuestionId}`
  const token = localStorage.getItem('access_token');

  return async function (dispatch, getState) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      // console.log(res.data)
      if (res.status === 200) {
        await dispatch(setQuestionContent(res.data))        
        await dispatch(getOcrContentAPI(res.data, searchQuestionId))
        // await dispatch(getOcrContentAPI(getState().editor.question_content_obj, searchQuestionId))
        
      }
    } catch (err) {
      console.log(err, "getQuestionContentAPI")
    }
  }
}

const storeNewData = (data) => {
  return async function (dispatch) {
    try {
      dispatch(
        init_question_new(
          'data',  // data_key
          data.text  // text
        )
      )

      if (data.r_markdown) {
        dispatch(
          init_question_old(
            'data_relation',  // data_key
            data.r_markdown  // text
          )
        )
      } else {
        dispatch(
          init_question_new(
            'data_relation',  // data_key
            ''  // text
          )
        )
      }
    } catch (err) {
      console.log(err, "storeNewData")
    }
  }
}

const storeOldData = (data) => {
  return async function (dispatch) {
    try {
      dispatch(
        init_question_old(
          'data',  // data_key
          data.q_markdown  // text
        )
      )
      dispatch(
        init_question_info_old(
          data.order,  // order
          data.score,  // score
          data.difficulty,  // level
          data.question_error ,  // question_error
          data.s_markdown ? 'multiple' : 'essay',  //is_multiple_essay
        )
      )
      
      if (data.s_markdown) {
        let arr = []
        let selectMD = data.s_markdown + "\n"
        selectMD.replace(/\|([\s\S]+?)\|\n/g, (all, p) => {
          arr.push(p.split("|"))
        })
        const answerList = data.answer.map((el) => {
          if (el === 1) return "①"
          if (el === 2) return "②"
          if (el === 3) return "③"
          if (el === 4) return "④"
          if (el === 5) return "⑤"
          return el
        })
        const answerHtml = creElStringify("div", answerList.map((a) => highlight(a)).join(", "), { class: "answer" })
        dispatch(
          init_multiple_old_data_html_md_answer(
            arr,  // multiple_data
            dataToHtmlSelect(arr, []),  // multiple_html
            dataToSelect(arr),  // multiple_md
            data.answer,  // multiple_answer
            answerHtml  // multiple_answer_html
          )
        )
      } else {
        if (data.e_markdown) {
          dispatch(
            init_question_old(
              'data_explanation',  // data_key
              data.e_markdown  // text
            )
          )
          dispatch(init_essay_old_answer_explanation(data.answer, true))  // essay_answer, explanation_exist
        } else {
          dispatch(init_essay_old_answer(data.answer))
        }
      }

      if (data.r_markdown) {
        dispatch(
          init_question_old(
            'data_relation',  // data_key
            data.r_markdown  // text
          )
        )
      }
    } catch (err) {
      console.log(err, "storeOldData")
    }
  }
}


// { question_content_obj } 요청
const getOcrContentAPI = (searchQuestionId) => {
  const token = localStorage.getItem('access_token')
  const path = `/labeling/ocr/${searchQuestionId}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.status === 200) {
        if (res.data.write_yn === 0) {
          // await dispatch(storeNewData(res.data))
          await dispatch(setTextData(res.data.text))
        } else {
          await dispatch(storeOldData(res.data))
          // await dispatch(setTextData(res.data.text))
        }
        await dispatch(
          setQuestionContent(
            { question_image_name: res.data.question_image_name, solution_image_name: res.data.solution_image_name, related_image_name: res.data.related_image_name },
          )
        )
        await dispatch(isLoading(false))
      }
    } catch (err) {
      console.log(err, "getOcrContentAPI")
    }
  }
}

// { essay_answer, essay_answer_html 업데이트 }
const updateEssayAnswerHtml = (newEssayAnswerList) => {
  return async function (dispatch) {
    const answerHtml = creElStringify("div", newEssayAnswerList.map((a) => highlight(a)).join(", "), { class: "answer" })
    dispatch(update_Essay_Answer_Html(newEssayAnswerList, answerHtml))
  }
}

// { multiple_answer, multiple_answer_html 업데이트 }
const updateMultipleAnswerHtml = (newRawList) => {
  const answerList = newRawList.map((el) => {
    if (el === 1) return "①"
    if (el === 2) return "②"
    if (el === 3) return "③"
    if (el === 4) return "④"
    if (el === 5) return "⑤"
    return el
  })

  return async function (dispatch) {
    const answerHtml = creElStringify("div", answerList.map((a) => highlight(a)).join(", "), { class: "answer" })
    dispatch(update_Multiple_Answer_Html(answerList, answerHtml, newRawList))
  }
}

// { multiple_data, multiple_html, multiple_md 업데이트 }
const updateMultipleDataHtmlMd = (string, outerIndex, innerIndex, multiple_data, imageList) => {
  return async function (dispatch) {
    let newArr = JSON.parse(JSON.stringify(multiple_data))
    newArr[outerIndex][innerIndex] = string

    const multiple_html = dataToHtmlSelect(newArr, imageList)
    const multiple_md = dataToSelect(newArr)

    dispatch(update_Multiple_Data_Html_Md(newArr, multiple_html, multiple_md))
  }
}

// // reducer
export default handleActions(
  {
    [IS_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = action.payload.boolean;
      }),

    [SET_WRITE_YN]: (state, action) =>
      produce(state, (draft) => {
        draft.write_yn = action.payload.string;
      }),

    [IS_MULTIPLE_ESSAY]: (state, action) =>
      produce(state, (draft) => {
        draft.is_multiple_essay = action.payload.string;
      }),

    [SET_QUESTION_CONTENT]: (state, action) =>
      produce(state, (draft) => {
        draft.question_content_obj = action.payload.content;
      }),

    [UPDATE_ESSAY_ANSWER_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.essay_answer = action.payload.list;
        draft.essay_answer_html = action.payload.html;
      }),
      
    [UPDATE_MULTIPLE_ANSWER_RAW]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_answer_raw = action.payload.rawList;
      }),

    [UPDATE_MULTIPLE_ANSWER_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_answer = action.payload.list;
        draft.multiple_answer_html = action.payload.html;
        draft.multiple_answer_raw = action.payload.rawList;
      }),

    [UPDATE_MULTIPLE_DATA_HTML_MD]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_data = action.payload.newArr;
        draft.multiple_html = action.payload.html;
        draft.multiple_md = action.payload.markdown;
      }),

    [UPDATE_MULTIPLE_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_data = action.payload.list;
      }),
      
    [INIT_QUESTION_NEW]: (state, action) =>
      produce(state, (draft) => {
        const key_new = action.payload.data_key || 'data';
        draft[key_new] = [{ id: uuidv4(), type: "일반", text: action.payload.text, html: toHtml(action.payload.text, "일반", state.imageList) }];
      }),

    [INIT_QUESTION_OLD]: (state, action) =>
      produce(state, (draft) => {
        const key_old = action.payload.data_key || 'data';
        draft[key_old] = splitType(action.payload.text);
      }),

    [INIT_QUESTION_INFO_OLD]: (state, action) =>
      produce(state, (draft) => {
        draft.order = action.payload.order;
        draft.score = action.payload.score;
        draft.level = action.payload.level;
        draft.question_error = action.payload.question_error;
        draft.is_multiple_essay = action.payload.is_multiple_essay;
      }),

    [INIT_MULTIPLE_OLD_DATA_HTML_MD_ANSWER]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_data = action.payload.multiple_data;
        draft.multiple_html = action.payload.multiple_html;
        draft.multiple_md = action.payload.mmultiple_mdd;
        draft.multiple_answer = action.payload.multiple_answer;
        draft.multiple_answer_raw = action.payload.multiple_answer;
        draft.multiple_answer_html = action.payload.multiple_answer_html;
      }),

    [INIT_ESSAY_OLD_ANSWER]: (state, action) =>
      produce(state, (draft) => {
        draft.essay_answer = action.payload.essay_answer;
      }),

    [INIT_ESSAY_OLD_ANSWER_EXPLANATION]: (state, action) =>
      produce(state, (draft) => {
        draft.essay_answer = action.payload.essay_answer;
        draft.explanation_exist = action.payload.explanation_exist;
      }),

    [ADD_BLOCK]: (state, action) =>
      produce(state, (draft) => {
        let addBlockData = JSON.parse(JSON.stringify(state[action.payload.block_type]))
        addBlockData.splice(action.payload.index, 0, { id: uuidv4(), type: "일반", text: "", html: "<div class='block basic'></div>" })
        draft[action.payload.block_type] = addBlockData;
      }),

    [DELETE_BLOCK]: (state, action) =>
      produce(state, (draft) => {
        let delDum = JSON.parse(JSON.stringify(state[action.payload.block_type]))
        if (delDum.length < 2) return state
        delDum.splice(action.payload.index, 1)
        draft[action.payload.block_type] = delDum;
      }),

    [CHANGE_BLOCK_TYPE]: (state, action) =>
      produce(state, (draft) => {
        let newTypeData = JSON.parse(JSON.stringify(state[action.payload.block_type]))
        newTypeData[action.payload.index].type = action.payload.type
        newTypeData[action.payload.index].html = toHtml(newTypeData[action.payload.index].text, action.payload.type, state.imageList)
        draft[action.payload.block_type] = newTypeData;
      }),

    [WRITE_BLOCK_DATA]: (state, action) =>
      produce(state, (draft) => {
        let dum = JSON.parse(JSON.stringify(state[action.payload.block_type]))
        dum[action.payload.index].text = action.payload.data
        dum[action.payload.index].html = toHtml(action.payload.data, dum[action.payload.index].type, state.imageList)
        draft[action.payload.block_type] = dum;
      }),

    [INIT_IMAGE_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.imageList = action.payload.imageList;
      }),

    [INIT_ALL_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_data = [
          [" ", ""],
          ["①", ""],
          ["②", ""],
          ["③", ""],
          ["④", ""],
          ["⑤", ""],
        ]
        draft.multiple_html = ""
        draft.multiple_answer = []
        draft.multiple_answer_raw = []
        draft.essay_answer = [""]
        draft.multiple_answer_html = ''  // 답:
        draft.essay_answer_html = ''  // 답:

        draft.order = 1
        draft.score = 0
        draft.level = 0
        draft.question_error = 0
        draft.explanation_exist = false

        draft.imageList = []
        
        draft.data = [{ id: uuidv4(), type: "일반", text: "", html: "" }]
        draft.data_explanation = [{ id: uuidv4(), type: "일반", text: "", html: "" }]
        draft.data_relation = [{ id: uuidv4(), type: "일반", text: "", html: "" }]
      }),

    [SET_TEXT_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.text_data = action.payload.string;
      }),
    [SET_ORDER]: (state, action) =>
      produce(state, (draft) => {
        draft.order = action.payload.int;
      }),
    [SET_SCORE]: (state, action) =>
      produce(state, (draft) => {
        draft.score = action.payload.int;
      }),
    [SET_LEVEL]: (state, action) =>
      produce(state, (draft) => {
        draft.level = action.payload.int;
      }),
  }, 
  initialState
);

const actionCreators = {
  isLoading,
  isMoE,
  getQuestionContentAPI,
  getOcrContentAPI,

  updateEssayAnswerHtml,
  updateMultipleAnswerHtml,

  update_Essay_Answer_Html,
  update_Multiple_Answer_Raw,
  update_Multiple_Answer_Html,
  updateMultipleDataHtmlMd,
  update_Multiple_Data,

  setQuestionContent,

  addBlock,
  deleteBlock,
  changeBlockType,
  writeBlockText,

  init_all_data,
  init_image_list,

  setWriteYN,
  setTextData,


  setOrder,
  setScore,
  setLevel,
};

export { actionCreators };
