import { useNavigate, createSearchParams } from 'react-router-dom'

export const GetAuthority = (nation: number, authority: number) => {
  const navigate = useNavigate()

  // 한국 권한
  const parseKoAuthority = (authority: number) => {
    let pathname

    if (authority === -1) return alert('아직 운영자로부터 승인 되지 않은 계정입니다.')
      else if (authority === 0) pathname = '/notice' // 어드민
      else pathname = '/dashboard' // 라벨러

    navigate(pathname)
  }

  // 태국 권한
  const parseThAuthority = (authority: number) => {
    let pathname
        
    if (authority === -1) return alert('아직 운영자로부터 승인 되지 않은 계정입니다.')
      else if (authority === 1) pathname = `/th/mid-unit/qb`
      else pathname = `/th/translation/qb`

    navigate({
      pathname: pathname,
      search: `?${createSearchParams({page: 1, filter: 0, tab: '수학'})}`
    })
  }

  if (nation === 0) return navigate('/login')
    else if (nation === 1) return parseKoAuthority(authority) // 한국
    else if (nation === 2) return parseThAuthority(authority) // 태국
}


export const GetPathname = (nation: number, authority: number) => {
  console.log(nation, authority)
  // 한국 권한
  const parseKoAuthority = (authority: number) => {
    let pathname

    if (authority === -1) pathname = '/login'
      else if (authority === 0) pathname = '/notice' // 어드민
      else pathname = '/dashboard' // 라벨러

    return pathname
  }

  // 태국 권한
  const parseThAuthority = (authority: number) => {
    let pathname
        
    if (authority === -1) pathname = '/login'
      else if (authority === 1) pathname = `/th/mid-unit/qb?page=1&filter=0&tab=수학`
      else pathname = `/th/translation/qb?page=1&filter=0&tab=수학`

    return pathname
  }

  if (nation === 0) return '/login'
    else if (nation === 1) return parseKoAuthority(authority) // 한국
    else if (nation === 2) return parseThAuthority(authority) // 태국
}
