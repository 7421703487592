import styles from './chip.module.css'
import React from 'react'

const Chip = (props) => {
  const { type, id, label, mainId, subId } = props
  const handleType = (v) => {
    switch(v) {
      case 'select':
        return 
      default:
        break
    }
  }
  handleType(type)

  return (
    <div data-type='chip' data-id={id} data-sectionid={mainId} data-subsectionid={subId} className={[styles.chip, 'input-origin'].join(' ')}>{label}</div>
  )
}

export default React.memo(Chip)