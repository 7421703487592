import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import Post from "./modules/post";
import User from "./modules/user";
import Editor from "./modules/editor";
import Search from "./modules/search";
import Project from "./modules/project";
import Labeler from "./modules/labeler";
import Sidebar from "./modules/sidebar";
import Dashboard from "./modules/dashboard";
import MiddleUnit from "./modules/middleUnit";
import ImageEditor from "./modules/imageEditor";
import WriteEditor from "./modules/writeEditor";
import QuestionManage from "./modules/questionManage";
import Th_translation from "./modules/th_translation";

import TH_editor_writing from "./modules/thai/editor_writing"

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  post: Post,
  user: User,
  editor: Editor,
  search: Search,
  project: Project,
  labeler: Labeler,
  sidebar: Sidebar,
  dashboard: Dashboard,
  middleUnit: MiddleUnit,
  imageEditor: ImageEditor,
  writeEditor: WriteEditor,
  questionManage: QuestionManage,
  th_translation: Th_translation,
  th_editor_writing: TH_editor_writing,
  router: connectRouter(history),
});

const middlewares = [thunk.withExtraArgument({ history: history })];

const env = process.env.NODE_ENV;

// if (env === "development") {
//   const { logger } = require("redux-logger");
//   middlewares.push(logger);
// }

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

let store = createStore(rootReducer, enhancer);

export default store;
