import { produce } from "immer"
import { Axios } from "../../../api/api.config"
import { createAction, handleActions } from "redux-actions"

// actions
const IS_LOADING = "IS_LOADING" // 스켈레톤 로딩

const IS_BOOK = "IS_BOOK"
const IS_SELECT = "IS_SELECT"
const SET_DIFFICULTY = "SET_DIFFICULTY"

const SET_STATUS = "SET_STATUS"
const SET_QUESTION_DATA_HTML = "SET_QUESTION_DATA_HTML" // 문제 html 데이터
const SET_SELECTION_DATA_HTML = "SET_SELECTION_DATA_HTML" // 정답 html 데이터
const SET_EXPLANATION_DATA_HTML = "SET_EXPLANATION_DATA_HTML" // 해설 html 데이터
const SET_RELATION_DATA_HTML = "SET_RELATION_DATA_HTML" // 연관 지문 html 데이터

const SET_SELECTION_ANSWER_LIST = "SET_SELECTION_ANSWER_LIST" // 객관식 정답 리스트
const SET_ESSAY_ANSWER_LIST = "SET_ESSAY_ANSWER_LIST" // 주관식 정답 리스트

const SET_FABRIC_CANVAS_LIST = "SET_FABRIC_CANVAS_LIST" // all canvas list
const SET_IMAGE_FILE_URL_LIST = "SET_IMAGE_FILE_URL_LIST" //image file & url list
const SET_SAVED_DATA_JSON = "SET_SAVED_DATA_JSON" // 백에서 받은 json 형태의 모든 데이터
const SET_SELECTED_INDEX = "SET_SELECTED_INDEX" // 선택된 캔버스 INDEX
const SET_CLICK_PREVIEW = "SET_CLICK_PREVIEW" // 
const SET_FABRIC_HISTORY_UNDO = "SET_FABRIC_HISTORY_UNDO" // 
const SET_FABRIC_HISTORY_REDO = "SET_FABRIC_HISTORY_REDO" // 
const SET_FABRIC_HISTORY_LOCKED = "SET_FABRIC_HISTORY_LOCKED" // 


const SET_INIT_STATE = "SET_INIT_STATE" // 모든 데이터 초기화


// actionCreators
const isLoading = createAction(IS_LOADING, (boolean) => ({ boolean }))

const is_book = createAction(IS_BOOK, (boolean) => ({ boolean }))
const is_select = createAction(IS_SELECT, (int) => ({ int }))
const set_difficulty = createAction(SET_DIFFICULTY, (int) => ({ int }))

const set_status = createAction(SET_STATUS, (int) => ({ int }))
const set_question_data_html = createAction(SET_QUESTION_DATA_HTML, (string) => ({ string }))
const set_selection_data_html = createAction(SET_SELECTION_DATA_HTML, (string) => ({ string }))
const set_explanation_data_html = createAction(SET_EXPLANATION_DATA_HTML, (string) => ({ string }))
const set_relation_data_html = createAction(SET_RELATION_DATA_HTML, (string) => ({ string }))

const set_selection_answer_list = createAction(SET_SELECTION_ANSWER_LIST, (list) => ({ list }))
const set_essay_answer_list = createAction(SET_ESSAY_ANSWER_LIST, (list) => ({ list }))

const set_fabric_canvas_list = createAction(SET_FABRIC_CANVAS_LIST, (list) => ({ list }))
const set_image_file_url_list = createAction(SET_IMAGE_FILE_URL_LIST, (list) => ({ list }))
const set_saved_data_json = createAction(SET_SAVED_DATA_JSON, (string) => ({ string }))
const set_selected_index = createAction(SET_SELECTED_INDEX, (int) => ({ int }))
const set_click_preview = createAction(SET_CLICK_PREVIEW, (boolean) => ({ boolean }))
const set_fabric_history_undo = createAction(SET_FABRIC_HISTORY_UNDO, (list) => ({ list }))
const set_fabric_history_redo = createAction(SET_FABRIC_HISTORY_REDO, (list) => ({ list }))
const set_fabric_history_locked = createAction(SET_FABRIC_HISTORY_LOCKED, (boolean) => ({ boolean }))

const set_init_state = createAction(SET_INIT_STATE, () => ({  }))

const init_multiple_html = `
  <section class="pr-multiple_box_wrap">
    <div class="pr-multiple_box" data-num="">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="①">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="②">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="③">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="④">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="⑤">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
  </section>
`

// initialState
const initialState = {
  is_loading: true,                        // 스켈레톤 로딩

  is_book: false,                          // 큐비 or 북스
  is_select: 1,                            // 객관식: 1, 주관식: 0
  difficulty: 0,                           // 난이도 (int)

  status: 0,                               // 문제 작업 상태 - 확인 대기 / 번역 대기 / ...
  question_html: '',                       // 문제 데이터 - html
  selection_html: init_multiple_html,      // '<p></p>'
  explanation_html: '',                    // 해설 데이터 - html
  relation_html: '',                    // 연관 지문 데이터 - html

  selection_answer_list: [],                      // ['1', '2', '3'] : 정답
  essay_answer_list: ['<p></p>'],          // ['<p></p>', '<p></p>'] : 정답

  // is_selection_html_empty: false,          // 비어있으면 true, 다 작성 완료면 false

  // fabricCanvas
  fabric_canvas_list: [],
  image_file_url_list: [],                 // 이미지 프리뷰 history - [[{url: '', file: FILE}], []]
  saved_data_json: '',
  selected_index: 0,
  click_preview: false,
  fabric_history_undo: [],
  fabric_history_redo: [],
  locked: false,
}


// 태국 문제, 답, 해설 데이터 저장
const saveThaiQuestionDataAPI = (data) => {
  let th_data = data.thai
  const ko_data = data.ko
  const share_data = data.share
  if (!th_data.answer) th_data = ko_data
  console.log(data, th_data, share_data)
  return async function (dispatch) {
    try {
      // share
      await dispatch(is_book(share_data.is_book))
      await dispatch(is_select(share_data.is_select))
      await dispatch(set_difficulty(share_data.difficult))

      // thai
      await dispatch(set_status(th_data.status))
      await dispatch(set_question_data_html(th_data.html.question_html))
      
      if (th_data.html.selection_html) await dispatch(set_selection_data_html(th_data.html.selection_html))
      if (th_data.html.explanation_html) await dispatch(set_explanation_data_html(th_data.html.explanation_html))
      if (th_data.html.relation_html) await dispatch(set_relation_data_html(th_data.html.relation_html))

      if (share_data.is_select === 1) { // case: 객관식
        dispatch(set_selection_answer_list(th_data.answer))
      } else { // case: 주관식
        dispatch(set_essay_answer_list(th_data.answer))
      }
      
      // await dispatch(isLoading(false))
    } catch (err) {
      console.log(err, "saveThaiQuestionDataAPI")
    }
  }
}

// 문제 데이터 Reset
// const postQuestionResetAPI = (question_id = 0) => {
//   if (window.confirm('지금까지 수정된 모든 내용이 삭제됩니다. Reset 하시겠습니까?') === false) return
//   const path = `labeling/reset`
//   const accessToken = localStorage.getItem("access_token")
//   const body = {
//     question_id: Number(question_id),
//   }
//   // console.log(body)

//   return async function (dispatch) {
//     try {
//       const res = await Axios.post(path, body, {
//         headers: { Authorization: accessToken },
//       })
//       if (res.data.message === 'reset success') {
//         // dispatch(getOcrContentAPI(question_id))
//         window.location.reload()
//       }
//     } catch (err) {
//       console.log(err, "postQuestionResetAPI")
//     }
//   }
// }


// // reducer
export default handleActions(
  {
    [IS_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = action.payload.boolean;
      }),

    [IS_BOOK]: (state, action) =>
      produce(state, (draft) => {
        draft.is_book = action.payload.boolean;
      }),
    [IS_SELECT]: (state, action) =>
      produce(state, (draft) => {
        draft.is_select = action.payload.int;
      }),
    [SET_DIFFICULTY]: (state, action) =>
      produce(state, (draft) => {
        draft.difficulty = action.payload.int;
      }),
    [SET_STATUS]: (state, action) =>
      produce(state, (draft) => {
        draft.status = action.payload.int;
      }),
    [SET_QUESTION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.question_html = action.payload.string;
      }),
    [SET_SELECTION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.selection_html = action.payload.string;
      }),
    [SET_EXPLANATION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.explanation_html = action.payload.string;
      }),
    [SET_RELATION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.relation_html = action.payload.string;
      }),
    [SET_SELECTION_ANSWER_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.selection_answer_list = action.payload.list;
      }),
    [SET_ESSAY_ANSWER_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.essay_answer_list = action.payload.list;
      }),

    [SET_FABRIC_CANVAS_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.fabric_canvas_list = action.payload.list;
      }),
    [SET_IMAGE_FILE_URL_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.image_file_url_list = action.payload.list;
      }),
    [SET_SAVED_DATA_JSON]: (state, action) =>
      produce(state, (draft) => {
        draft.saved_data_json = action.payload.string;
      }),
    [SET_SELECTED_INDEX]: (state, action) =>
      produce(state, (draft) => {
        draft.selected_index = action.payload.int;
      }),
    [SET_CLICK_PREVIEW]: (state, action) =>
      produce(state, (draft) => {
        draft.click_preview = action.payload.boolean;
      }),
    [SET_FABRIC_HISTORY_UNDO]: (state, action) =>
      produce(state, (draft) => {
        draft.fabric_history_undo = action.payload.list;
      }),
    [SET_FABRIC_HISTORY_REDO]: (state, action) =>
      produce(state, (draft) => {
        draft.fabric_history_redo = action.payload.list;
      }),
    [SET_FABRIC_HISTORY_LOCKED]: (state, action) =>
      produce(state, (draft) => {
        draft.locked = action.payload.boolean;
      }),

    [SET_INIT_STATE]: (state, action) =>
      produce(state, (draft) => {
        draft.is_books = false;
        draft.is_loading = true;
        draft.is_relation = false;
        draft.difficulty = 0;
        draft.status = 0;
        draft.question_html = '';
        draft.selection_html = init_multiple_html;
        draft.explanation_html = '';
        draft.relation_html = '';
        draft.selection_answer_list = [];
        draft.essay_answer_list = ['<p></p>'];
        draft.fabric_canvas_list = [];
        draft.image_file_url_list = [];
        draft.saved_data_json = '';
        draft.selected_index = 0;
        draft.click_preview = false;
        draft.fabric_history_undo = [];
        draft.fabric_history_redo = [];
        draft.fabric_history_locked = false;
      }),
  }, 
  initialState
);

const actionCreators = {
  saveThaiQuestionDataAPI,

  set_question_data_html,
  set_explanation_data_html,
  set_relation_data_html,
  
  set_selection_data_html,
  set_selection_answer_list,
  set_essay_answer_list,

  set_fabric_canvas_list,
  set_image_file_url_list,
  set_saved_data_json,
  set_selected_index,
  set_click_preview,
  set_fabric_history_undo,
  set_fabric_history_redo,
  set_fabric_history_locked,

  set_init_state,
};

export { actionCreators };