import axios from 'axios'
import { produce } from 'immer'
import { Axios } from '../../api/api.config'
import { createAction, handleActions } from 'redux-actions'

// actions
const IS_BOOKS = "IS_BOOKS";
const UPDATE_Q_LIST = "UPDATE_Q_LIST";
const UPDATE_Q_COUNT = "UPDATE_Q_COUNT";
const UPDATE_FILTER = "UPDATE_FILTER";
const UPDATE_BEFORE_PAGE = "UPDATE_BEFORE_PAGE";
const UPDATE_BEFORE_MID_UNIT = "UPDATE_BEFORE_MID_UNIT";
const RESET_QB = "RESET_QB";

const UPDATE_B_LIST = "UPDATE_B_LIST";
const UPDATE_B_COUNT = "UPDATE_B_COUNT";
const UPDATE_B_FILTER = "UPDATE_B_FILTER";
const UPDATE_B_BEFORE_PAGE = "UPDATE_B_BEFORE_PAGE";
const UPDATE_B_BEFORE_MID_UNIT = "UPDATE_B_BEFORE_MID_UNIT";
const RESET_BOOKS = "RESET_BOOKS";

const UPDATE_TH_LIST = "UPDATE_TH_LIST";
const UPDATE_TH_COUNT = "UPDATE_TH_COUNT";
const UPDATE_TH_FILTER = "UPDATE_TH_FILTER";
const UPDATE_TH_PAGE = "UPDATE_TH_PAGE";
const UPDATE_TH_MID_UNIT = "UPDATE_TH_MID_UNIT";

// actionCreators
const isBooks = createAction(IS_BOOKS, (boolean) => ({ boolean }));

export const updateQList = createAction(UPDATE_Q_LIST, (list) => ({ list }));
export const updateQCount = createAction(UPDATE_Q_COUNT, (int) => ({ int }));
export const updateFilter = createAction(UPDATE_FILTER, (obj) => ({ obj }));
export const updateBeforePage = createAction(UPDATE_BEFORE_PAGE, (int) => ({ int }));
export const updateBeforeMidUnit = createAction(UPDATE_BEFORE_MID_UNIT, (int) => ({ int }));
export const resetQB = createAction(RESET_QB, () => ({  }));

export const updateBList = createAction(UPDATE_B_LIST, (list) => ({ list }));
export const updateBCount = createAction(UPDATE_B_COUNT, (int) => ({ int }));
export const updateB_Filter = createAction(UPDATE_B_FILTER, (obj) => ({ obj }));
export const updateB_BeforePage = createAction(UPDATE_B_BEFORE_PAGE, (int) => ({ int }));
export const updateB_BeforeMidUnit = createAction(UPDATE_B_BEFORE_MID_UNIT, (int) => ({ int }));
export const resetBooks = createAction(RESET_BOOKS, () => ({  }));

export const updateTHList = createAction(UPDATE_TH_LIST, (list) => ({ list }));
export const updateTHCount = createAction(UPDATE_TH_COUNT, (int) => ({ int }));
export const updateTHFilter = createAction(UPDATE_TH_FILTER, (obj) => ({ obj }));
export const updateTHPage = createAction(UPDATE_TH_PAGE, (int) => ({ int }));
export const updateTHMidUnit = createAction(UPDATE_TH_MID_UNIT, (int) => ({ int }));

// initialState
const initialState = {
  is_books: false,

  Q_list: [],
  Q_count: 0,
  filter_data: {
    grade: '',
    subject: '',
    subSubject: '',
    publisher: '',
    midUnit: '',
    isKorean: false,
    semester: '',
    explanation: '',
    error: '',
    check: '',
    workType: '',
    workStatus: '',
    filterData: [],
  },
  before_page: 0,
  before_mid_unit: 0,

  B_list: [],
  B_count: 0,
  B_filter_data: {
    grade: '',
    subject: '',
    subSubject: '',
    publisher: '',
    midUnit: '',
    isKorean: false,
    semester: '',
    explanation: '',
    thai: '',
    error: '',
    check: '',
    workType: '',
    workStatus: '',
    filterData: [],
  },
  B_before_page: 0,
  B_before_mid_unit: 0,

  th_list: [],
  th_count: 0,
  th_filter: {
    grade: '',
    subject: '',
    midUnit: '',
    multiple: '',
    matching: '',
    filterData: [],
  },
  th_page: 0,
  th_mid_unit: 0,
};

const getQuestionListAPI = (page, service, id, filter) => {
  console.log(id)
  const query_ko = `${filter.semester ? `&&info=${filter.semester}` : ''}${filter.explanation ? `&&explanation=${filter.explanation}` : ''}${filter.error ? `&&error=${filter.error}` : ''}${filter.check ? `&&check=${filter.check}` : ''}${filter.workStatus ? `&&work_status=${filter.workStatus}` : ''}`
  const path = `project/question-subsection/${id}?service=${service}&&page=${page}&&work_type=${filter.workType}${query_ko}`
  console.log(path)

  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200 && res.data.data.length === 0 && res.data.count > 0) {
        const _path = `project/question-subsection/${id}?service=${service}&&page=${1}&&work_type=${filter.workType}${query_ko}`
        const _res = await Axios.get(_path, {
          headers: { Authorization: accessToken },
        })
        console.log(_res)
        if (_res.status === 200) {
          dispatch(updateQList(_res.data.data))
          dispatch(updateQCount(_res.data.count))
          dispatch(updateFilter(filter))
          dispatch(updateBeforePage(1))
          dispatch(updateBeforeMidUnit(id))
        }
      } else if (res.status === 200) {
        dispatch(updateQList(res.data.data))
        dispatch(updateQCount(res.data.count))
        dispatch(updateFilter(filter))
        dispatch(updateBeforePage(page))
        dispatch(updateBeforeMidUnit(id))
      }
    } catch (err) {
      console.log(err, "getQuestionListAPI")
    }
  }
}

const getBooksListAPI = (page, service, id, filter) => {
  console.log(filter.workStatus)
  const query_ko = `${filter.error ? `&&error=${filter.error}` : ''}${filter.workStatus ? `&&work_status=${filter.workStatus}` : ''}`
  const path = `project/question-subsection/${id}?service=${service}&&page=${page}&&work_type=${filter.workType}${query_ko}`
  // console.log(path)

  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200 && res.data.data.length === 0 && res.data.count > 0) {
        const _path = `project/question-subsection/${id}?service=${service}&&page=${1}&&work_type=${filter.workType}${query_ko}`
        const _res = await Axios.get(_path, {
          headers: { Authorization: accessToken },
        })
        console.log(_res)
        if (_res.status === 200) {
          dispatch(updateBList(_res.data.data))
          dispatch(updateBCount(_res.data.count))
          dispatch(updateB_Filter(filter))
          dispatch(updateB_BeforePage(1))
          dispatch(updateB_BeforeMidUnit(id))
        }
      } else if (res.status === 200) {
        dispatch(updateBList(res.data.data))
        dispatch(updateBCount(res.data.count))
        dispatch(updateB_Filter(filter))
        dispatch(updateB_BeforePage(page))
        dispatch(updateB_BeforeMidUnit(id))
      }
    } catch (err) {
      console.log(err, "getBooksListAPI")
    }
  }
}

const getTHQuestionListAPI = (page, id, filter) => {
  const query_th = `${filter.matching === '' ? '' : `&&matching=${Number(filter.matching)}`}${filter.multiple === '' ? '' : `&&have_select=${Number(filter.multiple)}`}`
  const path = `thai/question-subsection/${id}?page=${page}${query_th}`
  console.log(path)

  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200 && res.data.data.length === 0 && res.data.count > 0) {
        const _path = `thai/question-subsection/${id}?page=${1}${query_th}`
        const _res = await Axios.get(_path, {
          headers: { Authorization: accessToken },
        })
        console.log(_res)
        if (_res.status === 200) {
          dispatch(updateTHList(_res.data.data))
          dispatch(updateTHCount(_res.data.count))
          dispatch(updateTHFilter(filter))
          dispatch(updateTHPage(1))
          dispatch(updateTHMidUnit(id))
        }
      } else if (res.status === 200) {
        dispatch(updateTHList(res.data.data))
        dispatch(updateTHCount(res.data.count))
        dispatch(updateTHFilter(filter))
        dispatch(updateTHPage(page))
        dispatch(updateTHMidUnit(id))
      }
    } catch (err) {
      console.log(err, "getTHQuestionListAPI")
    }
  }
}

// reducer
export default handleActions(
  {
    [IS_BOOKS]: (state, action) =>
      produce(state, (draft) => {
        draft.is_books = action.payload.boolean;
      }),

    [UPDATE_Q_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.Q_list = action.payload.list;
      }),
    [UPDATE_Q_COUNT]: (state, action) =>
      produce(state, (draft) => {
        draft.Q_count = action.payload.int;
      }),
    [UPDATE_FILTER]: (state, action) =>
      produce(state, (draft) => {
        draft.filter_data = action.payload.obj;
      }),
    [UPDATE_BEFORE_PAGE]: (state, action) =>
      produce(state, (draft) => {
        draft.before_page = action.payload.int;
      }),
    [UPDATE_BEFORE_MID_UNIT]: (state, action) =>
      produce(state, (draft) => {
        draft.before_mid_unit = action.payload.int;
      }),
    [RESET_QB]: (state, action) =>
      produce(state, (draft) => {
        draft.Q_list = [];
        draft.Q_count = 0;
        draft.filter_data = {
          grade: '',
          subject: '',
          subSubject: '',
          publisher: '',
          midUnit: '',
          workType: '',
          isKorean: false,
          semester: '',
          explanation: '',
          error: '',
          workStatus: '',
          check: '',
          filterData: [],
        };
        draft.before_page = 0;
        draft.before_mid_unit = 0;
      }),

    [UPDATE_B_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.B_list = action.payload.list;
      }),
    [UPDATE_B_COUNT]: (state, action) =>
      produce(state, (draft) => {
        draft.B_count = action.payload.int;
      }),
    [UPDATE_B_FILTER]: (state, action) =>
      produce(state, (draft) => {
        draft.B_filter_data = action.payload.obj;
      }),
    [UPDATE_B_BEFORE_PAGE]: (state, action) =>
      produce(state, (draft) => {
        draft.B_before_page = action.payload.int;
      }),
    [UPDATE_B_BEFORE_MID_UNIT]: (state, action) =>
      produce(state, (draft) => {
        draft.B_before_mid_unit = action.payload.int;
      }),
    [RESET_BOOKS]: (state, action) =>
      produce(state, (draft) => {
        draft.B_list = [];
        draft.B_count = 0;
        draft.B_filter_data = {
          grade: '',
          subject: '',
          subSubject: '',
          publisher: '',
          midUnit: '',
          workType: '',
          isKorean: false,
          semester: '',
          explanation: '',
          thai: '',
          error: '',
          workStatus: '',
          check: '',
          filterData: [],
        };
        draft.B_before_page = 0;
        draft.B_before_mid_unit = 0;
      }),

    [UPDATE_TH_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.th_list = action.payload.list;
      }),
    [UPDATE_TH_COUNT]: (state, action) =>
      produce(state, (draft) => {
        draft.th_count = action.payload.int;
      }),
    [UPDATE_TH_FILTER]: (state, action) =>
      produce(state, (draft) => {
        draft.th_filter = action.payload.obj;
      }),
    [UPDATE_TH_PAGE]: (state, action) =>
      produce(state, (draft) => {
        draft.th_page = action.payload.int;
      }),
    [UPDATE_TH_MID_UNIT]: (state, action) =>
      produce(state, (draft) => {
        draft.th_mid_unit = action.payload.int;
      }),
  },
  initialState
);

const actionCreators = {
  resetQB,
  resetBooks,
  getBooksListAPI,
  getQuestionListAPI,
  getTHQuestionListAPI,
  isBooks,
};

export { actionCreators };
