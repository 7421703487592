import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ClassicEditor from 'ckeditor5/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import 'katex/dist/katex.css'
import _ from 'lodash'
import { actionCreators as writeEditorActions } from "../../redux/modules/writeEditor";
import { question_config } from './CkEditorConfig'
import Accordion from '../_molecules/Accordion'

const ReEditArea = ({ type }) => {
  const dispatch = useDispatch()
  const is_explanation = useSelector((state) => state.writeEditor.is_explanation)
  const getHtmlState = (state) => {
    if (type === '문제') return state.writeEditor.data_html 
    if (type === '해설') return state.writeEditor.explanation_html 
    if (type === '지문') return state.writeEditor.related_text_html 
  }
  const data_html = useSelector(
    (state) => getHtmlState(state)
  )

  const updateHtmlState = (e) => {
    if (type === '문제') return writeEditorActions.set_question_data_html(e)
    if (type === '해설') return writeEditorActions.set_explanation_data_html(e)
    if (type === '지문') return writeEditorActions.set_related_text_data_html(e)
  }
  const onChange_data = (e) => {
    dispatch(updateHtmlState(e))
  }
  
  // About 해설 Accordion
  const handleIsExplanation = (v) => {
    dispatch(writeEditorActions.set_explanation(v))
  }
  const explanationItemList = [
    {value: 1, label: '있음'},
    {value: 0, label: '없음'},
  ]

  return (
    <>
      {type === '문제' &&
        (
          <Wrap>
            <CKEditor
              editor={ ClassicEditor }
              data={data_html}
              config={question_config}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  onChange_data(data)
              } }
            />
          </Wrap>
        )
      }

      {type === '해설' &&
        (
          <Container>
            <Accordion 
              name={'해설'} 
              value={is_explanation} 
              onchange={handleIsExplanation} 
              itemList={explanationItemList} 
            />
            <Wrap explanation>
              <CKEditor
                editor={ ClassicEditor }
                data={data_html}
                config={question_config}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    onChange_data(data)
                } }
              />
            </Wrap>
            
          </Container>
        )
      }

      {type === '지문' &&
        (
          <Wrap>
            <CKEditor
              editor={ ClassicEditor }
              data={data_html}
              config={question_config}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  onChange_data(data)
              } }
            />
          </Wrap>
        )
      }
    </>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: ${(props) => props.explanation ? `calc(100% - 5.8rem)` : '100%'};

  .ck.ck-reset.ck-editor.ck-rounded-corners {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ck.ck-editor__main {
    width: 100%;
    height: calc(100% - 4.8rem);
    overflow-y: hidden;

    @media (min-width: 1670px) {
      height: calc(100% - 2.4rem);
    }
  }
  .ck.ck-content.ck-editor__editable {
    height: 100%;
    /* width: 550px; */
    /* width: 350px; */
    width: 100%;
    min-width: 330px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
`
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`

export default ReEditArea