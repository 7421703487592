import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router'
import { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'
import List from '../../components/post/List'
import { useQuery } from 'react-query'
import { getPostListAPI } from '../../api/getAPI'
import { actionCreators as postActions } from '../../redux/modules/post'

const PostPage = (props) => {
  const dispatch = useDispatch()
  const _nation = localStorage.getItem("_nation")
  const _authority = localStorage.getItem("_authority")
  const post_list = useSelector((state) => state.post.post_list)
  const total_count = useSelector((state) => state.post.total_count)
  const [ pageNum, setPageNum ] = useState(1)
  const [ postList, setPostList ] = useState(post_list)
  const [ totalCount, setTotalCount ] = useState(total_count)
  const [ isSendOption, setIsSendOption ] = useState('')
  const [ selectedOption, setSelectedOption ] = useState('')

  useEffect(() => {
    if (_authority === '3') {
      setIsSendOption(0)
      setSelectedOption(0)
    } else if (_authority === '1') {
      setIsSendOption(0)
      setSelectedOption(0)
    } else if (_authority === '2') {
      setIsSendOption(1)
      setSelectedOption(0)
    }
    
  }, [])

  const dispatchGETPostList = useCallback(() => {
    dispatch(postActions.getPostListAPI(pageNum, selectedOption, isSendOption))
  }, [pageNum, selectedOption, isSendOption])

  useEffect(() => {
    if (selectedOption === '' || isSendOption === '') return
    dispatchGETPostList()
    // dispatch(postActions.getPostListAPI(pageNum, selectedOption, isSendOption))
  }, [pageNum, isSendOption, selectedOption])


  const handleSendOption = (e) => {
    setIsSendOption(e.target.value)
  }
  const handleSelectedOption = (e) => {
    setSelectedOption(e.target.value)
  }

  const handleSetPostList = useCallback((arg) => {
    setPostList(arg)
  }, [postList])

  const handleTotalCount = useCallback((arg) => {
    setTotalCount(arg)
  }, [totalCount])

  const handleSetPageNum = useCallback((page) => {
    setPageNum(page)
  }, [pageNum])

  return (
    <Grid container direction={"column"} >
          
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>나의 게시글</div>
              <BtnBox02>
                <FormControl sx={{ minWidth: 150 }}>
                  <Select
                    value={isSendOption}
                    onChange={handleSendOption}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={''}>선택</MenuItem>
                    {_authority === '1' &&
                      <MenuItem value={0}>받은 글</MenuItem>
                    }
                    {_authority === '2' &&
                      <MenuItem value={1}>보낸 글</MenuItem>
                    }
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 150 }}>
                  <Select
                    value={selectedOption}
                    onChange={handleSelectedOption}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={''}>선택</MenuItem>
                    <MenuItem value={0}>전체</MenuItem>
                    <MenuItem value={1}>안 읽은 게시글</MenuItem>
                  </Select>
                </FormControl>
              </BtnBox02>
              
            </BtnBox>
          </Title>
          <List
            projectList={post_list}
            totalCount={total_count}
            handleSetPageNum={handleSetPageNum}
            dispatchGETPostList={dispatchGETPostList}
          />
        </Item>
      </Grid>
    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default PostPage