import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

import MathUI from './math/mathui';
import MathEditing from './math/mathediting';
import AutoMath from './math/automath';

export default class Math extends Plugin {
	static get requires() {
		return [MathEditing, MathUI, AutoMath, Widget];
	}

	static get pluginName() {
		return 'Math';
	}
}
