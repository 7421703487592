
export const question_config = {
  toolbar: {
    shouldNotGroupWhenFull: true,
    items: [
      'alignment',
      'bold',
      'italic',
      'underline',
      '|',
      'math', // <---- ADDED | CUSTOM
      'textbox', // <---- CUSTOM
      '|',
      'block', // <---- CUSTOM
      'blockType', // <---- CUSTOM
      '|',
      // 'heading',
      // '|',
      // 'link',
      // 'code',
      'outdent',
      'indent',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      // 'uploadImage',
      // 'blockQuote',
      'insertTable',
      // 'mediaEmbed',
      // 'codeBlock',
      '|',
      'undo',
      'redo',
    ],
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        // '|',
        // 'imageTextAlternative',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties',
      ],
    },
    math: {
      engine: 'katex', // or katex or function. E.g. (equation, element, display) => { ... }
      outputType: 'span', // or script
    },
    mention: {
      dropdownLimit: 20,
      feeds: [
        {
          marker: '@',
          // feed: getFeedItems,
          // itemRenderer: customItemRenderer,
        },
      ],
    },
  },
}