import { useQuery } from "react-query";
import { getAPI } from "api";

const getGrammarChildUnits = '/grammar-child-units'

export default function useGetGrammarsAll () {
  const response = useQuery(
    [`${getGrammarChildUnits}-all`],
    () => getAPI(
      getGrammarChildUnits, { keyword: '' }))
  return response
}
