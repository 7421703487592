import styled from 'styled-components'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState, useCallback } from 'react'
import Button from '../../../elements/button/Button'
import BackToList from '../../../elements/button/BackToList'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Card, Grid, Paper } from '@mui/material'
import LabelingList from '../../../components/myWorkSpace/editor/platform/List'
import LabelingList02 from '../../../components/myWorkSpace/midUnit/platform/PlatformList'
import LabelingToolbar from '../../../components/myWorkSpace/editor/platform/Toolbar'
import { actionCreators as imageEditorActions } from '../../../redux/modules/imageEditor'
import { useQuery } from 'react-query'
import { getQuestionListAPI } from '../../../api/getAPI'

const PlatformPage = React.memo((props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const _filter = useSelector((state) => state.imageEditor.filter)
  console.log(_filter)
  const get_data = useSelector((state) => state.imageEditor.saved_data_json)
  const all_related_data = useSelector((state) => state.imageEditor.related_data)
  const all_question_data = useSelector((state) => state.imageEditor.question_data)
  // console.log(get_data)
  // console.log(all_related_data)
  // console.log(all_question_data)
  const {'projectId': params_1, 'paperId': params_2} = useParams()
  const projectId = Number(params_1)
  const pdf_id = Number(params_2)
  const [ title, setTitle ] = useState('')
  const [ filter, setFilter ] = useState(_filter)
  const [ is_not_editor, setEditor ] = useState(false)
  const [ questionList, setQuestionList ] = useState([])

  const { data } = useQuery(['questionList', filter],
    () => getQuestionListAPI(pdf_id), 
    {
      onSuccess: data => {
        console.log(data)
        handleSetTitle(data.data.pdf_name)
        dispatch(imageEditorActions.getFilter(filter))
        if (data.data.message === 'boxing not completed') {
          console.log('1차 에디터 작업을 완료해 주세요.')
          if (!is_not_editor) handleSetEditor(true)
        } else {
          if (is_not_editor) handleSetEditor(false)
          let data_list
          if (filter === '0') {
            data_list = data.data.data
          } else if (filter === '1') {
            data_list = data.data.data.filter(el => el.write_yn === '완료')
          } else if (filter === '2') {
            data_list = data.data.data.filter(el => el.write_yn === '미완료')
          } else if (filter === '3') {
            data_list = data.data.data.filter(el => el.error === 1)
          } else if (filter === '4') {
            data_list = data.data.data.filter(el => el.error === 0)
          }
          handleSetQuesList(data_list)
        }
      },
      onError: error => {
        console.log(error)
      },
      // enabled: false,
      // refetchOnMount : false,
      // refetchOnReconnect: false,
      refetchOnWindowFocus :false,
      // https://velog.io/@kimhyo_0218/React-Query-%EB%A6%AC%EC%95%A1%ED%8A%B8-%EC%BF%BC%EB%A6%AC-%EC%8B%9C%EC%9E%91%ED%95%98%EA%B8%B0-useQueryㄴ
    }
  )
  console.log(questionList)

  const handleSetQuesList = useCallback((arg) => {
    setQuestionList(arg)
  }, [questionList])

  const handleSetFilter = useCallback((e) => {
    setFilter(e.target.value)
  }, [filter])

  const handleSetTitle = useCallback((e) => {
    setTitle(e)
  }, [title])

  const handleSetEditor = useCallback((e) => {
    setEditor(e)
  }, [is_not_editor])

  const GoBackToList = () => {
    navigate(`/workspace/project/test-paper/${projectId}`)
  }

  const GoToEditor = useCallback(() => {
    navigate(`/workspace/labeling/editor/${projectId}/${pdf_id}`)
  }, [projectId, pdf_id])

  return (
    <Grid container direction={"column"} >
      
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>문제 리스트</div>
              <BtnBox02>
                <BackToList onclick={GoBackToList}>시험지 리스트</BackToList>
                <Button onclick={GoToEditor}>1차 에디터 작업하기</Button>
              </BtnBox02>
              
            </BtnBox>
          </Title>
          <Box>
            <CardPadding>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box minWidth="12%">
                  <b>{title}</b>
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" minWidth="100px" width="12%" height="40px">
                  <Select
                    value={filter}
                    onChange={handleSetFilter}
                  >
                    <option value='0'>전체</option>
                    <option value='1'>작업 완료</option>
                    <option value='2'>작업 미완료</option>
                    <option value='3'>오류 있음</option>
                    <option value='4'>오류 없음</option>
                  </Select>
                </Box>
              </Box>
            </CardPadding>
          </Box>
          <LabelingList
            questionList={questionList}
            is_not_editor={is_not_editor}
            filter={filter}
          />
        </Item>
      </Grid>
    </Grid>
  )
})

const Select = styled.select`
  width: 100%;
  height: 100%;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #cccccc;
  border-radius: 4px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  padding: 15px;
`;
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default PlatformPage