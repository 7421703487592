import styled, { keyframes } from "styled-components/macro"

const slideRight = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }
  to {
    width: 520px;
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  padding: 160px 0 70px;
  width: 400px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  animation-duration: 0.30s;
  animation-timing-function: ease-out;
  animation-name: ${slideRight};
  animation-fill-mode: forwards;
`

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: ${props => props.width};
  height: 100%;
  /* border: 1px solid red; */
`
export const ChildrenWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
  padding: 4px 0 10px;
  height: 100%;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  
`
export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 10px 4px;
`
export const ListBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding: 12px;
  /* padding-top: 26px; */
  /* padding-top: ${props => props.pTop}; */
  border-radius: 2px;
  background-color: ${props => props.bgColor};
  box-shadow: ${props => props.shadow};
  border: 1px solid rgba(0, 0, 0, 0.2);
  
  .del_btn {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 26px;
    color: #555;
    cursor: pointer;
    /* border: 1px solid red; */
  }

  .add_box {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`
export const TitleBox = styled.div`
  width: 90%;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  padding: 0 4px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* border: 1px solid blue; */
  cursor: pointer;

  .box_title {
    color: #222222;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 0;

    /* position: absolute;
    top: 3px;
    left: 10px; */
    /* border: 1px solid red; */
  }
  .box_count {
    color: #222222;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 0;

    /* position: absolute;
    top: 3px;
    left: 54px; */
    /* border: 1px solid red; */
  }
`
export const AddItem = styled.div`
  border: 2px solid #82c5d1;
  border-radius: 3px;
  background-color: #e3f3f7;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* background-color: #FF5F5D; */
  cursor: pointer;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 10px 10px 6px;

  span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.92rem;
    font-weight: 600;
  }

  .item_info_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex-direction: column; */
    gap: 10px;
  }
  .item_info {
    width: fit-content;
    font-size: 13px;
    font-weight: 500;
    color: RGB(72, 100, 130);
  }
`