import styled from 'styled-components'
import Button from '../../elements/button/Button'
import BackToList from '../../elements/button/BackToList'
import ContentsCarousel from '../../elements/carousel/ContentsCarousel'
import Skeleton from '../../components/editor/classification/skeleton/SkeletonImageEditor'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useParams } from 'react-router'
import { postCompleteEditorAPI } from '../../api/postAPI' 
import { actionCreators as imageEditorActions } from "../../redux/modules/imageEditor"
import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';

const EditorCorrecting = ({ close, scrollToPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {'project_id': project_id, 'content_id': content_id} = useParams()
  const projectId = Number(project_id)
  const paperId = content_id
  const all_question_data = useSelector((state) => state.imageEditor.question_data)
  const all_related_data = useSelector((state) => state.imageEditor.related_data)
  const ai_modal_image = useSelector((state) => state.imageEditor.ai_modal_image)
  const correct_data = useSelector((state) => state.imageEditor.correct_data)
  const boxing_data = useSelector((state) => state.imageEditor.boxing_data)

  const [ modal, setModal ] = useState(false)
  const [ pageCheck, setPageCheck ] = useState(false)
  const handlePageCheck = (bool) => {
    setPageCheck(bool)
  }

  const { mutate } = useMutation(postCompleteEditorAPI, {
    onSuccess: data => {
      console.log(data)
      setModal(false)
      if (data.message === 'boxing Success') {
        // navigate(`/workspace/project/test-paper/question/${projectId}/${paperId}`)
        navigate(-1)
      }
    },
    onError: error => {
      console.log(error)
      if (error.response.data.message === 'crop error') {
        alert('이미지 자르기 중 문제가 발생했습니다. 나가기를 통해 모달창을 나가신 후 ctrl + S로 저장 및 새로고침 후 다시 진행해 주시기 바랍니다. 같은 문제가 발생한 경우 운영자에게 문의해 주시기 바랍니다.')
      }
      setModal(false)
    }
  })

  const handleCorrect = () => {
    if (!pageCheck) return alert('모든 문제를 검수해주세요.')
    if (window.confirm('검수를 완료하시겠습니까?') === true) {
      setModal(true)
      if (correct_data) {
        const questionData_json = JSON.stringify(all_question_data)
        const relatedData_json = JSON.stringify(all_related_data)
        const annotation_list = `${boxing_data}__||__${questionData_json}__||__${relatedData_json}`
        const obj_info_list_txt = correct_data + '1'
        const box_count = all_question_data.length
  
        const data = {
          obj_info_list_txt: obj_info_list_txt,
          annotation_list: annotation_list,
          pdf_id: paperId,
          count: box_count
        }
        console.log(data)
        mutate(data)
      } else {
        alert('이미지 데이터를 불러오고 있습니다. 잠시 후 다시 시도해 주세요.')
      }
    }
  }

  const handleClose = () => {
    close()
  }
  
  useEffect(() => {
    return () => {
      dispatch(imageEditorActions.setAiModalImage([]))
    }
  }, [])

  return (
    <Wrap>
      {modal && 
        <Modal
          open={modal}
          // onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
          sx={{ outline: 0}}
        >
          <ModalImgWrap>
            잠시만 기다려주세요...
            {/* <div className='content_loading'>잠시만 기다려주세요...</div> */}
          </ModalImgWrap>
        </Modal>
      }
      <Header>
        <BtnBox>
          <div className='title'>라벨링 검수</div>
          {ai_modal_image.length > 0 &&
            <BtnBox02>
              <BackToList onclick={handleClose}>나가기</BackToList>
              <Button 
                onclick={handleCorrect} 
                focus={pageCheck}
                bgColor={pageCheck ? '#2e6ef2' : 'rgba(0, 0, 0, 0.5)'} 
              >
                검수 완료
              </Button>
            </BtnBox02>
          }
        </BtnBox>
      </Header>
      {ai_modal_image.length === 0 &&
        <Skeleton />
        // <LoadingWrap>
        //   <Loading>잠시만 기다려주세요</Loading>
        // </LoadingWrap>
      }
      
      {ai_modal_image.length > 0 &&
        <Content>
          <ContentsCarousel scrollToPage={scrollToPage} imageList={ai_modal_image} handlePageCheck={handlePageCheck} handleClose={handleClose} />
        </Content>
      }
    </Wrap>
  )
}
const ModalImgWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  width: fit-content;
  padding: 2rem 4rem;
  border-radius: 5px;
  background-color: white;

  .content_loading {
    width: 100%;
  }
`
const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
`
const Loading = styled.div`
  /* border: 1px solid red; */
`
const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const Wrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default EditorCorrecting