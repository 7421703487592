import styled from 'styled-components'
import Button from '../../../elements/button/Button'
import TabBox from '../../_molecules/toolbar/TabBox'
import SearchBox from '../../_molecules/toolbar/SearchBox'
import FilterBox from '../../_molecules/filter/'
import closeIcon from '../../../assets/icons/cancel.svg'

interface ToolbarProps {
  title?: string
  tabList?: any[]
  activeTab?: number
  getTab?: (v:number) => void
  isSearch?: boolean
  isFilterAndSearch?: boolean
  searchFilter?: number
  searchFilterList?: any[]
  getSearchFilter?: (v:number) => void
  keyword?: string | null
  inputType?: string
  getKeyword?: (v:string) => void
  isFilter?: boolean
  filter?: number
  filterList?: any
  filterLabel?: string
  filterBoxWidth?: string
  getFilter?: (v:number) => void
  isFilter_2?: boolean
  filter_2?: number
  filterList_2?: any
  filterLabel_2?: string
  filterBoxWidth_2?: string
  getFilter_2?: (v:number) => void
  isButton?: boolean
  isButtonCus?: boolean
  onClickButton?: () => void
  buttonName?: string
  goToPage?: () => void
  isSave?: boolean
  save?: () => void
  isGoBack?: boolean
  goBack?: () => void
  isClose?: boolean
  close?: () => void
  children?: JSX.Element
}

const Toolbar = ({title="", tabList=[], activeTab=0, getTab, isSearch=false, inputType='text', isFilterAndSearch=false, searchFilter=0, searchFilterList=[], getSearchFilter=()=>{}, isFilter=false, filter=0, filterList=[], filterLabel='', filterBoxWidth='', isFilter_2=false, filter_2=0, filterList_2=[], filterLabel_2='', filterBoxWidth_2='', keyword="", isSave, save, isClose=false, close=()=>{}, isButton=false, isButtonCus=false, buttonName='', isGoBack=false, goToPage=()=>{}, onClickButton=()=>{}, getKeyword=()=>{}, getFilter=()=>{}, getFilter_2=()=>{}, goBack=()=>{}, children}: ToolbarProps) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {tabList.length !== 0 && <TabBox tabList={tabList} activeTab={activeTab} getTab={getTab} />}
      <Tools>
        {isSearch && <SearchBox defaultKeyword={keyword} getKeyword={getKeyword} />}
        {isFilterAndSearch && <SearchBox defaultKeyword={keyword} inputType={inputType} getKeyword={getKeyword} searchFilter={searchFilter} searchFilterList={searchFilterList} getSearchFilter={getSearchFilter} />}
        {isFilter && <FilterBox filter={filter} list={filterList} getFilter={getFilter} label={filterLabel} width={filterBoxWidth} />}
        {isFilter_2 && <FilterBox filter={filter_2} list={filterList_2} getFilter={getFilter_2} label={filterLabel_2} width={filterBoxWidth_2} />}
        {isButton && <Button onclick={goToPage}>프로젝트 생성하기</Button>}
        {isButtonCus && <Button bgColor={buttonName === '초기화' || buttonName === 'Reset' || buttonName === '삭제하기' ? '#eb3f3f' : ''} onclick={onClickButton}>{buttonName}</Button>}
        {isSave && <Button onclick={save}>저장하기</Button>}
        {isClose && <Close src={closeIcon} onClick={close} />}
        {children}
      </Tools>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(20%, auto));
  padding-bottom: 0.625rem; // 10px
  margin-bottom: 0.875rem; // 10px
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const Tools = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  justify-self: end;
`
const Title = styled.div`
  font-size: 1.125rem; // 18px
  font-weight: 700;
`
const GoBack = styled.div`
  color: #555555;
  padding: 0.2rem 0.5rem;
  width: fit-content;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  :hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
`
const Close = styled.img`
  cursor: pointer;
  /* border: 1px solid red; */
`

export default Toolbar