import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'
// import Select from '../../../components/_molecules/select/SelectBoxTH'
import ItemBox from '../../../components/_molecules/select/ItemBox'

import { useQuery, useQueryClient } from 'react-query'
import { getAPI } from '../../../api/getAPI'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'


interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any
  openModal?: (projectId: number, role: number, labeler: any)=>void
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], openModal=()=>{}}: tableBodyProps) => {
  const navigate = useNavigate()

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        return (
          <Row
            key={row.question_id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={()=>navigate(`detail/${row.question_id}`)}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="center">{row.question_id}</Cell>
            <Cell align="center">
              {!row.is_match && <Circle color={'red'} />}
              {row.is_match && <Circle color={'#66CC33'} />}
            </Cell>
            <Cell align="center">{row.question_name}</Cell>
            <Cell align="center">{row.answer_type}</Cell>
          </Row>
        )
      })}
    </>
  )
}

interface QBProps {
  _service: string
}

const QB = ({_service}: QBProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _filter = Number(searchParams.get('filter'))

  const _tab = searchParams.get('tab')
  const _grade = Number(searchParams.get('grade'))
  const _subject = searchParams.get('subject')
  const _book = searchParams.get('book')
  const _section = searchParams.get('section')
  const _subSection = searchParams.get('subSection')

  const headList = ['문제 번호', '태국 대단원 매칭 여부', '문제', '문제 유형']
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

// ----- filter -----
  const [filter, setFilter] = useState(_filter)
  const filterList = [
    {name: '전체', value: 0},
    {name: '매칭 대기', value: 1},
    {name: '매칭 완료', value: 2},
    {name: '객관식', value: 3},
    {name: '주관식', value: 4},
  ]

// ----- tab -----
  const [tab, setTab] = useState(_tab ? _tab : '수학') // string
  const onClickTab = (e) => {
    setSearchParams({
      page: 1, 
      filter: filter, 
      tab: e.target.value
    })
    setBodyList(null)
    setSubject(null)
    setBook(null)
    setSection(null)
    setSubSection(null)
    setBookList([])
    setSectionList([])
    setSubSectionList([])
  }

  // ----- select -----
  const [grade, setGrade] = useState(_grade) // 학년
  const [subject, setSubject] = useState(_subject ? Number(_subject) : _subject) // 과목
  const [book, setBook] = useState(_book ? Number(_book) : _book) // 문제집
  const [section, setSection] = useState(_section ? Number(_section) : _section) // 대단원
  const [subSection, setSubSection] = useState(_subSection ? Number(_subSection) : _subSection) // 중단원

  const handleGrade = (v: number) => {
    setGrade(v)
    setBodyList(null)
    setBook(null)
    setSection(null)
    setSubSection(null)
    setBookList([])
    setSectionList([])
    setSubSectionList([])
  }

  const handleSubSection = (v: number) => {
    setSearchParams({
      page: 1, 
      filter: filter, 
      tab: tab, 
      grade: grade, 
      book: book, 
      subject: subject, 
      section: section, 
      subSection: v
    })
  }

  const gradeList = [
    {value: 0, name: '중2'},
    {value: 1, name: '중3'},
    {value: 2, name: '고1'},
    {value: 3, name: '고2,3'},
  ] // 학년 list
  const [subjectList, setSubjectList] = useState([]) // 과목 list
  const [bookList, setBookList] = useState([]) // 문제집 list
  const [sectionList, setSectionList] = useState([]) // 대단원 list
  const [subSectionList, setSubSectionList] = useState([]) // 중단원 list

  const { data: data_filter, refetch: refetch_filter } = useQuery([`th/match/${_service}/filter`, {tab, grade}], () => getAPI(`th/match/${_service}/filter`, {subject: tab, grade: grade}), {
    cacheTime: Infinity,
  })

  useEffect(() => {
    if (!data_filter) return
    console.log(data_filter.data)
    setSubjectList(data_filter.data)
  }, [data_filter])

  const { isFetching } = useQuery([`th/match/${_service}/filter/${subSection}`, {page, filter, subSection}], () => getAPI(`th/match/${_service}/filter/${subSection}`, {page: page + 1, filter: filter}), {
    enabled: !!(subSection !== null),
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.question} />
      setBodyList(list)
      setTotalCount(data.data.total_count)
    }
  })

  // console.log('params: ', _page, _filter, _tab, _grade, _subject, _section, _subSection)
  // console.log(page, filter, tab, grade, subject, section, subSection)

  const changedFilter = (v: number) => {
    // console.log('changedFilter-------')
    setSearchParams({
      page: 1, 
      filter: v, 
      tab: tab, 
      grade: grade, 
      book: book, 
      subject: subject, 
      section: section, 
      subSection: subSection
    })
  }

  const changedPage = (v) => {
    // console.log('changedPage-------')
    setSearchParams({
      page: v + 1, 
      filter: filter,
      tab: tab, 
      grade: grade, 
      book: book, 
      subject: subject, 
      section: section, 
      subSection: subSection
    })
  }

  useEffect(() => {
    setSubSection(Number(_subSection))
  }, [_subSection])

  // useEffect(() => {
  //   if (page === 0 && filter === 0) return
  //   if (_filter === filter && _page - 1 === page) {
  //     return
  //   } else if (_filter !== filter && _page - 1 === page) {
  //     return changedFilter()
  //   } else if (_filter === filter && _page - 1 !== page) {
  //     return changedPage()
  //   }
  // }, [page, filter])

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setFilter(_filter)
  }, [_filter])

  useEffect(() => {
    setTab(_tab)
  }, [_tab])


  useEffect(() => {
    if (subjectList.length !== 0 && subject !== null) {
      const _list = subjectList.filter(el => el.sub_subject_id === subject)[0]

      if (!_list) return
      const list = _list.book
      setBookList(list)

      if (Number(_subject) === subject) return
      setBook(null)
      setSection(null)
      setSubSection(null)
      setSectionList([])
      setSubSectionList([])
    }
  }, [subject, subjectList.length])

  useEffect(() => {
    if (bookList.length !== 0 && book !== null) {
      const list = bookList.filter(el => el.book_id === book)[0].section
      setSectionList(list)

      if (Number(_book) === book) return
      setSection(null)
      setSubSection(null)
      setSubSectionList([])
    }
  }, [book, bookList.length])

  useEffect(() => {
    if (sectionList.length !== 0 && section !== null) {
      const list = sectionList.filter(el => el.section_id === section)[0].sub_section
      setSubSectionList(list)

      if (Number(_section) === section) return
      setSubSection(null)
    }
  }, [section, sectionList.length])


  return (
    <MainSection>
      <>
        <TabBox>
          <TabButton type='button' name='tab_subject' value='수학' active={tab === '수학'} onClick={onClickTab}>수학</TabButton>
          <TabButton type='button' name='tab_subject' value='과학' active={tab === '과학'} onClick={onClickTab}>과학</TabButton>
        </TabBox>
        <SelectBox>
          <Select>
            <LabelBox>학년</LabelBox>
            <ItemBox>
              <>
                {gradeList.map(el => 
                  <button key={el.value} onClick={() => handleGrade(el.value)} className={[
                    'item',
                    grade === el.value && 'active'
                    ].join(' ')} type='button'
                  >{el.name}</button>
                )}
              </>
            </ItemBox>
          </Select>
          <Select>
            <LabelBox>과목</LabelBox>
            <ItemBox>
              <>
                {subjectList.map(el => 
                  <button key={el.sub_subject_id} onClick={() => setSubject(el.sub_subject_id)} className={[
                    'item',
                    subject === el.sub_subject_id && 'active'
                    ].join(' ')} type='button'
                  >{el.sub_subject_name}</button>
                )}
              </>
            </ItemBox>
          </Select>
          <Select>
            <LabelBox>문제집</LabelBox>
            <ItemBox>
              <>
                {bookList.map(el => 
                  <button key={el.book_id} onClick={() => setBook(el.book_id)} className={[
                    'item',
                    book === el.book_id && 'active'
                    ].join(' ')} type='button'
                  >{el.book_name}</button>
                )}
              </>
            </ItemBox>
          </Select>
          <Select>
            <LabelBox>대단원</LabelBox>
            <ItemBox>
              <>
                {sectionList.map(el => 
                  <button key={el.section_id} onClick={() => setSection(el.section_id)} className={[
                    'item',
                    section === el.section_id && 'active'
                    ].join(' ')} type='button'
                  >{el.section_name}</button>
                )}
              </>
            </ItemBox>
          </Select>
          <Select>
            <LabelBox>중단원</LabelBox>
            <ItemBox>
              <>
                {subSectionList.map(el => {
                  return (<button key={el.sub_section_id} onClick={() => handleSubSection(el.sub_section_id)} className={[
                    'item',
                    subSection === el.sub_section_id && 'active'
                    ].join(' ')} type='button'
                  >{el.sub_section_name}</button>)}
                )}
              </>
            </ItemBox>
          </Select>
        </SelectBox>
        <Toolbar isFilter filter={filter} filterList={filterList} getFilter={changedFilter} filterLabel={'필터'} filterBoxWidth={'9rem'} />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const TabBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const TabButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.15s;
  color: ${(props) => (props.active ? '#1976d2' : 'rgba(0, 0, 0, 0.3)' )};
  cursor: pointer;

  :hover {
    color: rgba(0, 0, 0, 0.5);
  }
  :focus {
    color: #1976d2;
  }
`
const SelectBox = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 0.4rem;
  display: grid;
  column-gap: 0.4rem;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.08); */
  /* border: 1px solid orange; */
`
const Select = styled.div`
  color: #333;
  font-weight: 500;
  border-radius: 4px;
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
`
const LabelBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.color}
`

export default QB