import React, { useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Button,
  Card,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import ProgressBarOrigin from '../../../elements/progressBar/ProgressBarOrigin'

const CardPadding = styled(Card)`
  padding: 15px;
  margin-top: 10px;
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
const SubjectBox = styled.div`
  width: fit-content;
  height: fit-content;
  /* padding: 17px 0; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  .selected {
    background-color: #1a76d3;
    border: none;
    color: white;
    font-weight: 500;
  }
`
const SubjectBtn = styled.div`
  width: 64px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${props => props.selected ? 'white' : '#111'};
  border-radius: 3px;
  background-color: ${props => props.selected ? '#2e6ef2' : '#d4e1fa'};
  cursor: default;

  :hover {
    background-color: #2e6ef2;
    border: none;
    color: white;
    font-weight: 500;
  }
`
const ProgressBox = styled.div`
  /* border: 1px solid red; */
  position: relative;
  padding: 2px 0 6px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;

  .text_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .label_pg {
    position: relative;
    width: 40%;
    height: 15px;
    color: #2e6ef2;
    font-size: 16px;
    text-align: right;
    transition: all 0.3s ease;
  }
  .label_pg_percent {
    position: absolute;
    top: 0;
    right: -15.5px;
    color: #2e6ef2;
    font-size: 16px;
    font-weight: 700;
  }
  .label_pg_text_box {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }
  .label_pg_current {
    color: #2e6ef2;
    font-size: 15px;
    font-weight: 700;
  }
  .label_pg_total {
    color: #222;
    font-size: 13px;
  }

  #progress {
    width: 100%;
    height: 14px;
    padding-bottom: 30px;
    appearance: none;

    ::-webkit-progress-bar {
      height: 11px;
      background-color: #f0f0f0;
      border-radius: 10px;
      box-shadow: inset 2px 2px 8px #ccc;
    }
    ::-webkit-progress-value {
      transition: all 0.3s ease;
      border-radius: 10px;
      background: #1a76d3;
      background: -webkit-linear-gradient(to right, #3d9eff, #2e6ef2);
      background: linear-gradient(to right, #3d9eff, #2e6ef2);
    }
  }

  #pg {
    /* position: absolute; */
    width: 10px;
    height: 10px;
    top: -10;
    right: 0;
    border: 1px solid red;
  }

  #pg_label {
    width: 100%;
    height: 5px;
    appearance: none;

    ::-webkit-progress-bar {
      height: 8px;
      background-color: transparent;
      border-radius: 10px;
    }
    ::-webkit-progress-value {
      position: relative;
      height: 18px;
      transition: all 0.3s ease;
      border-right: 1px solid rgba(14, 105, 196, 0.3);
      background: transparent;
    }
  }
`

const Toolbar = ({ progressInfo, handleSetFilter }) => {
  const [filter, setFilter] = useState('전체')

  const selectSubject = (e) => {
    setFilter(e.target.innerText)
    handleSetFilter(e.target.innerText)
  }

  const subjectList = [
    {value: '전체', pathname: '#'},
    {value: '국어', pathname: '#'},
    {value: '수학', pathname: '#'},
    {value: '사회', pathname: '#'},
    {value: '역사', pathname: '#'},
    {value: '과학', pathname: '#'},
  ]

  return (
    <Box>
        <ToolbarWrap>
          <ProgressBarOrigin 
            overall={progressInfo.all_pdf} 
            done={progressInfo.done_pdf} 
            percent={progressInfo.percentage} 
          />
          <CardPadding> 
            <SubjectBox>
              {subjectList.map(el => (
                <SubjectBtn
                  key={el.value} 
                  selected={filter === el.value} 
                  onClick={selectSubject}>{el.value}
                </SubjectBtn>
              ))}
            </SubjectBox>
          </CardPadding>
        </ToolbarWrap>
    </Box>
  )
}
const ToolbarWrap = styled(Box)`
`

export default Toolbar
