import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import LoginPage from './pages/LoginPage'
import MainPage from './pages/MainPage'
import Mypage from './pages/mypage/Mypage'
import PostPage from './pages/post/PostPage'
import DetailPage from './pages/detail/DetailPage'
import DetailListPage from './pages/detail/DetailListPage'
import DashboardPage from './pages/dashboard/DashboardPage'
import ProjectManagement from './components/project/ProjectManagement'
import LabelerManagement from './components/labeler/LabelerManagement'
import Labeling from './pages/myWorkSpace/editor/PlatformPage'
import PlatformPage from './pages/myWorkSpace/midUnit/PlatformPage'
import MiddleUnitPage from './pages/MiddleUnitPage'
import ProjectListPage from './pages/myWorkSpace/editor/ProjectListPage'
import EditorSpacePage from './pages/myWorkSpace/editor/EditorSpacePage'
import MidUnitSpacePage from './pages/myWorkSpace/midUnit/MidUnitSpacePage'
import EditorImageCrop from './components/editor/classification/EditorImageCrop'
import Editor from './components/editor/Editor'
import EditorWrap from './components/editor/EditorWrap'

import AdDashboardPage from './pages/admin/notice/DashboardPage'
import AdNoticeRegistration from './pages/admin/notice/NoticeRegistration'

import AdUploadPage from './pages/admin/upload'
import AdUploadDetailCreationBooksPage from './pages/admin/upload/detail/CreationBooks'
import AdUploadDetailCreationQBPage from './pages/admin/upload/detail/CreationQB'
import AdUploadDetailCreationThaiPage from './pages/admin/upload/detail/CreationThai'
import AdUploadDetailPdfPage from './pages/admin/upload/detail/PDF'
import AdUploadDetailPdfQbPage from './pages/admin/upload/detail/PDFqb'

import AdProjectPage from './pages/admin/project/CreateProject'
import AdProjectDetailPage from './pages/admin/project/DetailPage'
import AdProjectListPage from './pages/admin/project/MatchingProject'
import AdMatchingPage from './pages/admin/project/MatchingPage'
import AdWorkTypeMatchingPage from './pages/admin/project/WorkTypeMatchingPage'

import AdLabelerPage from './pages/admin/labeler/LabelerPage'
import AdManagementPage from './pages/admin/labeler/ManagementPage'
import AdMatchingListPage from './pages/admin/labeler/WorkStatus'
import AdTestPaperListPage from './pages/admin/labeler/TestPaper'
import AdDetailPage from './pages/admin/labeler/DetailPage'

import AdQuestionPage from './pages/admin/question/QuestionManagePage'
import AdBooksPage from './pages/admin/question/BooksManagePage'
import AdQuestionError from './pages/admin/question/QuestionError'

import Graph from './pages/Graph'

// new 한국
import ProjectPage from './pages/myWorkSpace/Project'
import ContentPage from './pages/myWorkSpace/Content'
import QuestionPage from './pages/myWorkSpace/Question'

import QuesQuestionPage from './pages/question/QuestionManagePage'
import QuesBooksPage from './pages/question/BooksManagePage'

// 태국
import Th_Main from './pages/th/midUnit'
import Th_Main_Detail from './pages/th/midUnit/detail'
import Th_Translation from './pages/th/translation'
import Th_Translation_Detail from './pages/th/translation/detail'
import Th_Management from './pages/th/management'

import { GetPathname } from './utils/getAuthority' 

const RouteList = React.memo(() => {
  const _nation = localStorage.getItem("_nation")
  const _authority = localStorage.getItem("_authority")

  const getNavigate = () => {
    return <Navigate to={GetPathname(Number(_nation), Number(_authority))} replace={true} />
  }

  const path = useLocation().pathname

  if (path === '/') return getNavigate()


  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<MainPage />} >

{/* ----- Labeler Page Korea ----- */}
        <Route path="dashboard" element={<DashboardPage />} />

        <Route path="workspace/project/test-paper/:id" element={<EditorSpacePage />} />
        <Route path="workspace/project/test-paper/question/:projectId/:paperId" element={<Labeling />} />
        <Route path="workspace/labeling/correcting/:questionId" element={<EditorWrap />} />
        
        <Route path="workspace/project/mid-unit/test-paper/:id" element={<MidUnitSpacePage />} />
        <Route path="workspace/project/mid-unit/test-paper/question/:projectId/:paperId" element={<PlatformPage />} />
        <Route path="workspace/project/mid-unit/test-paper/question/correcting/:questionId" element={<MiddleUnitPage />} />
        
        <Route path="workspace/progress" element={<DetailListPage />} />
        <Route path="workspace/progress/detail" element={<DetailPage />} />

        <Route path="mypage" element={<Mypage />} />

        <Route path="post" element={<PostPage />} />

{/* 어드민 문제 관리와 같은 컴포넌트 */}
        <Route path="questions/qb" element={<QuesQuestionPage />} />
        <Route path="questions/books" element={<QuesBooksPage />} />

{/*Labeler Page Korea - new*/}
    {/* project_type : 'labeling' / 'review' */}
        <Route path="workspace/project" element={<ProjectPage />} />
        <Route path="workspace/project/:project_type/:project_id/content" element={<ContentPage />} />
        <Route path="workspace/project/:project_type/:project_id/content/:content_id/question" element={<QuestionPage />} />
        <Route path="workspace/project/labeling/content/:data_type/:question_id/correcting" element={<EditorWrap />} />
        {/* question / related_text */}
        {/* <Route path="workspace/project/labeling/content/related_text/:question_id/correcting" element={<EditorWrap />} /> */}
        <Route path="workspace/project/review/content/question/:question_id" element={<MiddleUnitPage />} />


{/*Labeler Page Thailand*/}
        <Route path="th/mid-unit/:service" element={<Th_Main />} />
        <Route path="th/mid-unit/:service/detail/:question_id" element={<Th_Main_Detail />} />
        <Route path="th/translation/:service" element={<Th_Translation />} />
        <Route path="th/translation/:service/detail/:question_id" element={<Th_Translation_Detail />} />
        <Route path="th/management" element={<Th_Management />} />


{/*Admin Page*/}
        <Route path="dashboard/status" element={<Graph />} />
        <Route path="notice" element={<AdDashboardPage />} />
        <Route path="notice/registration" element={<AdNoticeRegistration />} />
        <Route path="notice/modification/:id" element={<AdNoticeRegistration />} />

        <Route path="upload/management" element={<AdUploadPage />} />
        <Route path="upload/management/creation" element={<AdUploadDetailCreationBooksPage />} />
        <Route path="upload/management/creation/qb" element={<AdUploadDetailCreationQBPage />} />
        <Route path="upload/management/creation/thai" element={<AdUploadDetailCreationThaiPage />} />
        <Route path="upload/management/modification/:id" element={<AdUploadDetailCreationBooksPage />} />
        <Route path="upload/management/modification/qb/:id" element={<AdUploadDetailCreationQBPage />} />
        <Route path="upload/management/modification/thai/:id" element={<AdUploadDetailCreationThaiPage />} />
        <Route path="upload/pdf" element={<AdUploadPage />} />
        <Route path="upload/pdf/modification/:id" element={<AdUploadDetailPdfPage />} />
        <Route path="upload/pdf/modification/thai/:id" element={<AdUploadDetailPdfPage />} />
        <Route path="upload/pdf/modification/qb/:id" element={<AdUploadDetailPdfQbPage />} />

        <Route path="project/management" element={<AdProjectPage />} />
        <Route path="project/management/creation" element={<AdProjectDetailPage />} />
        <Route path="project/management/modification" element={<AdProjectDetailPage />} />
        <Route path="project/matching" element={<AdProjectListPage />} />
        <Route path="project/matching/workspace" element={<AdMatchingPage />} />
        <Route path="project/workType" element={<AdWorkTypeMatchingPage />} />

        <Route path="labeler/management" element={<AdLabelerPage />} />
        <Route path="labeler/management/info/:id" element={<AdManagementPage />} />
        <Route path="labeler/matching" element={<AdMatchingListPage />} />
        <Route path="labeler/matching/test-paper/:project_id" element={<AdTestPaperListPage />} />
        <Route path="labeler/matching/detail" element={<DetailPage />} />

        <Route path="question/qb" element={<AdQuestionPage />} />
        <Route path="question/books" element={<AdBooksPage />} />
        <Route path="question/error" element={<AdQuestionError />} />
      </Route>
      <Route path="workspace/labeling/editor/:projectId/:paperId" element={<EditorImageCrop />} />
      <Route path="workspace/project/:project_type/:project_id/content/:content_id/editor" element={<EditorImageCrop />} />
    </Routes>
  )
})
// 최근 프로젝트

export default RouteList