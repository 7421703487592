import { useState } from 'react'
import styled from 'styled-components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const BackBtn = styled.div`
  cursor: pointer;
  flex: 60px;
  box-sizing: border-box;
  padding: 0 10px;
  max-width: fit-content;
  min-width: fit-content;
  height: ${(props) => (props.height ? props.height : '44px' )};
  color: #222;
  font-size: 14px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700' )};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'rgba(0, 0, 0, 0.16) 0px 1px 2px' )};

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  & span {
    box-sizing: border-box;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BackToList = (props) => {
  const { children, onclick, height, boxShadow, fontWeight } = props

  return (
    <BackBtn
      onClick={onclick}
      height={height}
      boxShadow={boxShadow}
      fontWeight={fontWeight}
    >
      <span>
        <ArrowBackIosNewIcon sx={{fontSize: '14px'}} />
      </span>
      {children}
    </BackBtn>
  )
}

export default BackToList