import { useEffect, useState, useRef, useCallback, memo, forwardRef, useImperativeHandle } from 'react'
import * as S from './MyInfoForm.style'
import Form from '../../components/mypage/forms/Form'
import { useQuery, useMutation } from 'react-query'
import { getMyPageAPI } from '../../api/getAPI' 
import { postCheckPhone } from '../../api/postAPI' 
import TextField from '@mui/material/TextField'

const MyInfoForm = forwardRef(function MyInfoForm(props, ref) {
  const [ info, setInfo ] = useState(null)
  const [ start, setStart ] = useState(false)
  const { data: myPageInfo } = useQuery('mypageInfo', () => getMyPageAPI(), {
    // enabled: !!(start),
    onSuccess: data => {
      console.log(data)
      handleSetInfo(data.data)
    },
    onError: error => {
      console.log(error)
    }
  })
  useEffect(() => {
    if (info) {
      setId(info.user_id)
      setName(info.user_name)
      setPhone(info.phone)
      setGroup(info.group)
      setRole(info.admin === 0 ? '라벨러' : '어드민')
      setDate(info.created_time)
    } else {
      setCode('')
      setCodeCheck('')
      setLabel('')
    }
  }, [info])

  const handleSetInfo = useCallback((e) => {
    setInfo(e)
  }, [info])

  const { mutate } = useMutation(postCheckPhone, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'SUCCESS') {
        setCodeCheck(data.CODE)
      }
    },
    onError: error => {
      console.log(error)
      if (error.response.data.message === 'phone exists') {
        alert('이미 가입된 번호입니다.')
      }
    }
  })

  const [ id, setId ] = useState('')
  const [ name, setName ] = useState('')
  const [ phone, setPhone ] = useState('')
  const [ code, setCode ] = useState('')
  const [ codeCheck, setCodeCheck ] = useState('')
  const [ label, setLabel ] = useState('')
  const [ group, setGroup ] = useState('')
  const [ role, setRole ] = useState('')
  const [ date, setDate ] = useState('')

  // const handleId = useCallback((e) => {
  //   setId(e.target.value)
  // }, [id])

  const handleNa = useCallback((e) => {
    setName(e)
  }, [name])

  const handleName = useCallback((e) => {
    setName(e.target.value)
  }, [name])
  
  const handlePhone = useCallback((e) => {
    setPhone(e.target.value)
  }, [phone])

  const handleCode = useCallback((e) => {
    setCode(e.target.value)
  }, [code])

  const handleCheckPhone = () => {
    if (phone === myPageInfo.data.phone) return

    const data = {
      phone: phone,
      user_id: id,
      user_name: name,
      type: 'check'
    }
    console.log(data)
    mutate(data)
  }

  const handleSubmit = useCallback(() => {
    if (codeCheck !== code) {
      setLabel('인증번호가 일치하지 않습니다.')
      return 
    }
    if (!phone || !name) {
      // alert('모든 항목을 입력해 주세요.')
      return 'alert01'
      // return
    }
    if (phone === info.phone && name === info.user_name) {
      // alert('변경된 내용이 없습니다.')
      return 'alert02'
      // return
    }
    if (phone !== info.phone && !codeCheck) return 'alert03'
    
    const data = {
      user_name: name,
      phone: phone
    }
    console.log(data)

    return data
  }, [myPageInfo, name, phone, code, codeCheck])

  useImperativeHandle(ref, () => ({ handleSubmit, handleSetInfo }))

  const [ color, setColor ] = useState(0)

  useEffect(() => {
    console.log(code)
    console.log(codeCheck)
    if (codeCheck !== code) {
      setColor(1)
    } else {
      setColor(2)
      setLabel('')
    }
  }, [code, codeCheck])

  return myPageInfo && (
    <S.CardPadding>
      <S.Wrap>
        <S.Forms>
          <TextField id="outlined-basic" sx={{minWidth: '30%'}} label="아이디" value={id} disabled />
          <TextField id="outlined-basic" sx={{minWidth: '30%'}} label="이름" value={name} onChange={handleName} />
          <div className='check_phone'>
            <TextField id="outlined-basic" sx={{minWidth: '100%'}} label="전화번호" length value={phone} onChange={handlePhone} type='number' />
            <S.checkBtn color={phone !== myPageInfo.data.phone ? 'white' : ''} bgColor={phone !== myPageInfo.data.phone && '#2e6ef2'} onClick={handleCheckPhone} >인증하기</S.checkBtn>
          </div>
          <div style={{width: '30%'}}>
            <TextField error={color === 2 ? false : true} id='outlined-basic' sx={{minWidth: '100%'}} label="인증번호" value={code} onChange={handleCode} disabled={!codeCheck} />
            { label && <div style={{color: 'red', fontSize: '12px', padding: '0.5rem 0 0 0.3rem'}}>{label}</div> }
          </div>
          <TextField id="outlined-basic" sx={{minWidth: '30%'}} label="소속" value={group} disabled />
          <TextField id="outlined-basic" sx={{minWidth: '30%'}} label="권한" value={role} disabled />
          <TextField id="outlined-basic" sx={{minWidth: '30%'}} label="가입일" value={date} disabled />
        </S.Forms>
      </S.Wrap>
    </S.CardPadding>
  )
})

export default memo(MyInfoForm)