import styled from "styled-components/macro"

export const Container = styled.div`
  width: 100%;
  /* max-width: 414px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  /* border: 1px solid red; */

  & th:nth-child(1) {
    width: 20px;
  }
  & th {
    text-align: left;
  }
  & td:nth-child(1) {
    width: 20px;
  }
  #answer {
    font-size: 16px;
    font-weight: 700;
  }

  .md span.text-line {
    display: block;
  }
  .md hr {
    /* margin: 1em 0; */
    border: 0;
    border-bottom: 1px solid #ccc;
  }
  .md blockquote {
    margin-left: 0;
    padding: 0.5em 0 0.5em 2em;
    border-left: 3px solid rgb(211, 218, 234);
  }
  .md li,
  .md code {
    margin: 0.4em 0;
  }
  .md li {
  }
  .md p {
    margin: 0.9em 0;
  }
  .md code {
    background: rgba(211, 218, 234, 0.25);
  }
  .md ol {
    margin: 0;
  }
  .md pre > code {
    display: block;
    padding: 0.5em 4em;
  }
  .md .block table,
  .md .block th,
  .md .block td {
    border: 1px solid #aaa;
  }
  .md .block table {
    width: 100%;
    margin: auto;
    border-spacing: 0;
    border-collapse: collapse;
  }

  .md .block td {
    padding: 4px 8px;
    text-align: center;
  }

  .md .block tr:nth-child(2n) {
    background: #f3f3f3;
  }

  .md .block th {
    border-bottom: 1px solid #aaa;
  }

  .md .block img {
    /* max-width: 96px; */
  }

  .md .plain,
  .md .markdown {
    position: absolute;
    width: 50%;
    height: 100%;
    margin: 0;
  }

  .md .plain {
    border: 0;
    border-right: 1px solid #000;
    padding: 12px;
    font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
    font-size: 12px;
    resize: none;
  }
  .md .markdown {
    left: 50%;
    padding: 0 0 0 12px;
    overflow: auto;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.3;
  }
  .md .border,
  .md .image,
  .md .basic,
  .md .table,
  .md .relation {
    position: relative;
    padding: 0px 0 15px;
    min-height: 1rem;
    tab-size: 4;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    -webkit-tab-size: 4;
  }
  .md .image {
    text-align: center;
  }
  .md .block {
    line-height: 1.3;
    /* margin: 5px 0; */
    white-space: pre-wrap;
  }
  .md .tag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px dashed;
  }
  .md .tag:before {
    position: absolute;
    top: 0;
    right: 7px;
    color: rgba(76, 76, 76, 0.4);
  }
  .md .basic .tag {
    border-color: var(--accent-color);
  }

  .md .border .tag,
  .md .table .tag {
    border: none;
  }

  .md .image .tag {
    border-color: red;
  }
  .md .basic .tag::before {
    content: "일반";
  }
  .md .border .tag::before {
    content: "보더";
  }
  .md .table .tag::before {
    content: "표";
  }
  .md .image .tag::before {
    content: "그림";
  }

  .md .border {
    position: relative;
    box-sizing: border-box;
  }
  .md .border .border-content {
    border: 1px solid;
    padding: 0.6rem;
    border-radius: 10px;
  }
  .md .border .border-name::before {
    content: attr(data-name);
    position: absolute;
    top: -8px;
    left: 25px;
    background: #fff;
    padding: 0 5px;
    font-size: 12px;
  }
  .md .rect-text {
    border: 1px solid;
    padding: 1px 5px;
  }
  .md .text-align-center {
    text-align: center;
  }
  .md .text-align-left {
    text-align: left;
  }
  .md .text-align-right {
    text-align: right;
  }
`
export const Content = styled.div`
  width: 100%;
  font-size: 13px;
  margin-bottom: 20px;
`