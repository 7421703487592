import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import {
	toWidget,
	toWidgetEditable,
} from '@ckeditor/ckeditor5-widget/src/utils';
import { PlusMultipleBoxCommand } from './PlusCommand';
import { DeleteMultipleBoxCommand } from './MinusCommand';
import ClickObserver from '@ckeditor/ckeditor5-engine/src/view/observer/clickobserver';

import '../../theme/css/multiplebox.css';

export default class MultipleBoxEditing extends Plugin {
	static get requires() {
		return [Widget];
	}
	init() {
		// 클릭 감지
		// const view = this.editor.editing.view;
		// const viewDocument = view.document;
		// view.addObserver( ClickObserver );
		// this.editor.listenTo( viewDocument, 'click', ( evt, data ) => {
		//     const modelElement = this.editor.editing.mapper.toModelElement( data.target );
		//     console.log(modelElement)

		//     const number_1 = document.querySelector( '.number_1' );
		//     console.log('number_1', number_1)

		//     if (modelElement.name === 'numberOne') {
		//         // number_1.classList.add( 'active_number' );
		//         const conversion = this.editor.conversion;
		//         conversion.elementToElement( {
		//             model: 'numberOne',
		//             view: {
		//                 name: 'div',
		//                 classes: 'active_1'
		//             }
		//         } );
		//     }
		// } );

		this._defineSchema();
		this._defineConverters();

		this.editor.commands.add(
			'plusMultipleBox',
			new PlusMultipleBoxCommand(this.editor)
		);
		this.editor.commands.add(
			'deleteMultipleBox',
			new DeleteMultipleBoxCommand(this.editor)
		);
	}

	_defineSchema() {
		const schema = this.editor.model.schema;

		schema.register('multipleBoxWrap', {
			isObject: true,
			allowWhere: '$block',
			allowAttributes: ['hasColumns'],
		});

		schema.register('multipleBox', {
			isLimit: true,
			allowIn: 'multipleBoxWrap',
			allowAttributes: ['isChecked', 'num'],
		});

		schema.register('multipleBoxNumber', {
			// inheritAllFrom: '$marker',
			allowIn: 'multipleBox',
			isSelectable: false,
		});

		schema.register('multipleBoxContentWrap', {
			isLimit: true,
			allowIn: 'multipleBox',
			allowContentOf: '$root',
		});

		schema.register('multipleBoxContent', {
			isLimit: true,
			allowIn: 'multipleBoxContentWrap',
			allowContentOf: '$root',
		});

		////

		// schema.addChildCheck((context, childDefinition) => {
		// 	if (
		// 		context.endsWith('multipleBoxDescription') &&
		// 		childDefinition.name == 'multipleBox'
		// 	) {
		// 		// console.log('commet success / dfffdfdfdfffdfddffd')
		// 		return false;
		// 	}
		// });
	}

	_defineConverters() {
		// ADDED
		const conversion = this.editor.conversion;

		conversion
			.for('upcast')
			.elementToElement({
				view: { name: 'section', classes: 'pr-multiple_box_wrap' },
				// model: 'multipleBoxWrap',
				model: (viewElement, { writer }) => {
					const multipleBox = viewElement.getChild(0);
					const multipleBoxContentWrap = multipleBox.getChild(0);

					return writer.createElement('multipleBoxWrap', {
						hasColumns: !(multipleBoxContentWrap.childCount === 1),
					});
				},
			})
			.elementToElement({
				view: { name: 'div', classes: 'pr-multiple_box' },
				// model: (viewElement, { writer }) => {
				// 	return writer.createElement('multipleBox');
				// },
				model: (viewElement, { writer }) => {
					const isChecked =
						viewElement.getAttribute('data-isChecked') || false;
					const num = viewElement.getAttribute('data-num') || false;

					return writer.createElement('multipleBox', {
						isChecked,
						num,
					});
				},
			})
			.elementToElement({
				view: { name: 'div', classes: 'pr-multiple_box_number' },
				model: 'multipleBoxNumber',
				// model: (viewElement, { writer }) => {
				// 	const isChecked =
				// 		viewElement.getAttribute('isChecked') || false;
				// 	return writer.createElement('multipleBoxNumber', {
				// 		isChecked,
				// 	});
				// },
			})
			.elementToElement({
				view: { name: 'div', classes: 'pr-multiple_box_content_wrap' },
				model: 'multipleBoxContentWrap',
			})
			.elementToElement({
				view: { name: 'div', classes: 'pr-multiple_box_content' },
				model: 'multipleBoxContent',
			});

		conversion
			.for('downcast')
			.elementToElement({
				model: { name: 'multipleBoxWrap' },
				// view: { name: 'section', classes: 'pr-multiple_box_wrap' },
				view: (modelElement, { writer }) => {
					const hasColumns = modelElement.getAttribute('hasColumns');
					const classes = ['pr-multiple_box_wrap'];
					if (hasColumns) classes.push('multiple');

					return writer.createContainerElement('section', {
						class: classes.join(' '),
					});
				},
			})
			.elementToElement({
				model: { name: 'multipleBox' },
				// view: { name: 'div', classes: 'pr-multiple_box' },
				view: (modelElement, { writer }) => {
					const isChecked =
						modelElement.getAttribute('isChecked') || false;
					const num = modelElement.getAttribute('num') || false;
					const element = writer.createContainerElement('div', {
						class: 'pr-multiple_box',
						'data-isChecked': isChecked,
						'data-num': num || '',
					});

					return element;
				},
			})
			.elementToElement({
				model: { name: 'multipleBoxNumber' },
				view: { name: 'div', classes: 'pr-multiple_box_number' },
			})
			.elementToElement({
				model: { name: 'multipleBoxContentWrap' },
				view: { name: 'div', classes: 'pr-multiple_box_content_wrap' },
			})
			.elementToElement({
				model: { name: 'multipleBoxContent' },
				view: { name: 'div', classes: 'pr-multiple_box_content' },
			});

		conversion.for('upcast').attributeToAttribute({
			model: {
				key: 'hasColumns',
				value: (viewElement, { writer }) => {
					const match = viewElement
						.getAttribute('class')
						.match('multiple');
					return match ? 'true' : 'false';
				},
			},
			view: {
				key: 'class',
				value: /multiple/,
			},
		});

		conversion.for('downcast').attributeToAttribute({
			model: 'hasColumns',
			view: (modelAttributeValue) => {
				return {
					key: 'class',
					value: modelAttributeValue ? 'multiple' : '',
				};
			},
		});

		// const hasColumns = modelElement.getAttribute('hasColumns');
		// return typeof hasColumns === 'string' &&
		// 	hasColumns === 'true'
		// 	? 'multiple'
		// 	: '';

		// conversion.for('upcast').elementToElement({
		// 	model: (viewElement, { writer }) => {
		// 		const columns = viewElement.getAttribute('data-columns');
		// 		return writer.createElement('multipleBox', { columns });
		// 	},
		// 	view: {
		// 		name: 'section',
		// 		classes: 'pr-multiple_box',
		// 	},
		// });

		// conversion.for('downcast').elementToElement({
		// 	model: 'multipleBox',
		// 	view: (modelElement, { writer }) => {
		// 		return writer.createContainerElement('section', {
		// 			class: 'pr-multiple_box',
		// 			'data-columns': modelElement.getAttribute('columns'),
		// 		});
		// 	},
		// });

		// // conversion.elementToElement( {
		// //     model: 'multipleBoxTitle',
		// //     view: {
		// //         name: 'h1',
		// //         classes: 'multiple-box-title'
		// //     }
		// // } );

		// conversion.elementToElement({
		// 	model: 'multipleBoxDescription',
		// 	view: {
		// 		name: 'div',
		// 		classes: 'pr-multiple_box_description',
		// 	},
		// });

		// conversion.elementToElement({
		// 	model: 'multipleBoxContainer',
		// 	view: {
		// 		name: 'div',
		// 		classes: 'pr-multiple_box_container_wrap',
		// 	},
		// });

		// // conversion.elementToElement( {
		// //     model: 'multipleBoxContent',
		// //     view: {
		// //         name: 'div',
		// //         classes: 'multiple-box-content-wrap',
		// //     }
		// // } );

		// // -----------------------------------------------------------------------------

		// conversion.for('upcast').elementToElement({
		// 	model: 'multipleBoxContent',
		// 	view: {
		// 		name: 'div',
		// 		classes: 'pr-multiple_box_content_wrap',
		// 	},
		// });
		// conversion.for('downcast').elementToElement({
		// 	model: {
		// 		name: 'multipleBoxContent',
		// 		attributes: 'content',
		// 	},
		// 	view: {
		// 		name: 'div',
		// 		classes: 'pr-multiple_box_content_wrap',
		// 	},
		// });
		// conversion.for('editingDowncast').elementToElement({
		// 	model: 'multipleBoxContent',
		// 	view: (modelElement, { writer: viewWriter }) => {
		// 		const div = viewWriter.createEditableElement('div', {
		// 			class: 'pr-multiple_box_content_wrap',
		// 		});

		// 		return toWidgetEditable(div, viewWriter);
		// 	},
		// });

		// // -----------------------------------------------------------------------------

		// conversion.elementToElement({
		// 	model: 'multipleBoxNumber',
		// 	view: {
		// 		name: 'div',
		// 		classes: 'pr-multiple_box_number',
		// 	},
		// });

		// conversion.elementToElement({
		// 	model: 'numberZero',
		// 	view: {
		// 		name: 'button',
		// 		classes: 'pr-multiple_box_number',
		// 	},
		// 	isSelectable: false,
		// });

		// conversion.elementToElement({
		// 	model: 'numberOne',
		// 	view: {
		// 		name: 'button',
		// 		classes: 'pr-multiple_box_number',
		// 	},
		// });

		// // ----------------------------------------------------------------

		// // conversion.for( 'upcast' ).elementToElement( {
		// //     model: 'numberOne',
		// //     view: {
		// //         name: 'button',
		// //         classes: 'number_1'
		// //     }
		// // } );
		// // conversion.for( 'downcast' ).elementToElement( {
		// //     model: 'numberOne',
		// //     view: {
		// //         name: 'button',
		// //         classes: 'number_1'
		// //     }
		// // } );
		// // conversion.for( 'editingDowncast' ).elementToElement( {
		// //     model: 'numberOne',
		// //     view: ( modelElement, { writer: viewWriter } ) => {
		// //         const div = viewWriter.createEditableElement( 'div', { class: 'number_1' } );

		// //         return toWidgetEditable( div, viewWriter );
		// //     }
		// // } );

		// // ----------------------------------------------------------------

		// conversion.elementToElement({
		// 	model: 'numberTwo',
		// 	view: {
		// 		name: 'button',
		// 		classes: 'pr-multiple_box_number',
		// 	},
		// });

		// conversion.elementToElement({
		// 	model: 'numberThree',
		// 	view: {
		// 		name: 'button',
		// 		classes: 'pr-multiple_box_number',
		// 	},
		// });

		// conversion.elementToElement({
		// 	model: 'numberFour',
		// 	view: {
		// 		name: 'button',
		// 		classes: 'pr-multiple_box_number',
		// 	},
		// });

		// conversion.elementToElement({
		// 	model: 'numberFive',
		// 	view: {
		// 		name: 'button',
		// 		classes: 'pr-multiple_box_number',
		// 	},
		// });
	}
}
