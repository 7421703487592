import React from 'react'
import GlobalStyle from './styles/GlobalStyle'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { actionCreators as userActions } from "./redux/modules/user";

import RouteList from './routes'
import ChatContainer from './components/common/chat/Container'

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const is_token = localStorage.getItem("access_token") ? true : false
  const is_login = useSelector((state) => state.user.is_login)

  React.useEffect(() => {
    if (is_token) {
      dispatch(userActions.isLogin(true))
    } else {
      // console.log(is_login)
      // console.log('is_not_token')
    }
  }, [is_token])

  React.useEffect(()=>{
    const storage = localStorage
    const prevPath = storage.getItem('currentPath')
    storage.setItem('prevPath', prevPath)
    storage.setItem('currentPath', location.pathname + location.search)
  }, [location.pathname, location.search])

  // 배포 환경에서 console.log, console.warn 지우기
  if (process.env.NODE_ENV === "production") {
    console.log = function no_console() {};
    // console.warn = function no_console() {};
  }

  return (
    <>
      <GlobalStyle />
      <RouteList />
      {/* <ChatContainer /> */}
    </>
  )
}

export default App