import Command from '@ckeditor/ckeditor5-core/src/command';
import { findAttributeRange } from 'ckeditor5/src/typing';
import { first } from 'ckeditor5/src/utils';

export default class InsertBlockTypeCommand extends Command {
	// execute() {
	// 	const model = this.editor.model;
	// 	const selection = model.document.selection;

	// 	const first = selection.getFirstPosition();
	// 	const last = selection.getLastPosition();

	// 	const blocks = Array.from(selection.getSelectedBlocks());

	// 	const isBlock = this._isBlock(first.parent);

	// 	model.change((writer) => {
	// 		if (isBlock) {
	// 		} else {
	// 		}

	// 		// const current = model.schema.findAllowedParent(
	// 		// 	selection.getFirstPosition(),
	// 		// 	'block'
	// 		// ).parent;

	// 		// writer.setAttribute('data-type', type, current);
	// 	});

	// }
	execute(input, options = {}) {
		const model = this.editor.model;
		const schema = model.schema;
		const selection = model.document.selection;

		const blocks = Array.from(selection.getSelectedBlocks());

		const value =
			options.forceValue === undefined ? !this.value : options.forceValue;
		model.change((writer) => {
			const position = writer.createPositionAfter(blocks[0]);
			if (!value) {
				this._removeQuote(writer, blocks.filter(findQuote));
			} else {
				const blocksToQuote = blocks.filter((block) => {
					// Already quoted blocks needs to be considered while quoting too
					// in order to reuse their <bQ> elements.
					return findQuote(block) || checkCanBeQuoted(schema, block);
				});

				const block = blocks.find((b) => findQuote(b));

				if (Object.keys(block || {}).includes('parent')) {
					writer.setAttribute('data-type', input, block.parent);
				} else {
					this._applyQuote(writer, blocksToQuote, input);
				}
				writer.insert(writer.createElement('paragraph'), position);
			}
		});
	}

	refresh() {
		this.value = this._getValue();
		this.isEnabled = this._checkEnabled();
	}

	_getValue() {
		const selection = this.editor.model.document.selection;

		const firstBlock = first(selection.getSelectedBlocks());

		// In the current implementation, the block quote must be an immediate parent of a block element.
		return !!(firstBlock && findQuote(firstBlock));
	}

	_checkEnabled() {
		if (this.value) {
			return true;
		}

		const selection = this.editor.model.document.selection;
		const schema = this.editor.model.schema;

		const firstBlock = first(selection.getSelectedBlocks());

		if (!firstBlock) {
			return false;
		}

		return checkCanBeQuoted(schema, firstBlock);
	}

	_removeQuote(writer, blocks) {
		// Unquote all groups of block. Iterate in the reverse order to not break following ranges.
		getRangesOfBlockGroups(writer, blocks)
			.reverse()
			.forEach((groupRange) => {
				if (groupRange.start.isAtStart && groupRange.end.isAtEnd) {
					writer.unwrap(groupRange.start.parent);

					return;
				}

				// The group of blocks are at the beginning of an <bQ> so let's move them left (out of the <bQ>).
				if (groupRange.start.isAtStart) {
					const positionBefore = writer.createPositionBefore(
						groupRange.start.parent
					);

					writer.move(groupRange, positionBefore);

					return;
				}

				// The blocks are in the middle of an <bQ> so we need to split the <bQ> after the last block
				// so we move the items there.
				if (!groupRange.end.isAtEnd) {
					writer.split(groupRange.end);
				}

				// Now we are sure that groupRange.end.isAtEnd is true, so let's move the blocks right.

				const positionAfter = writer.createPositionAfter(
					groupRange.end.parent
				);

				writer.move(groupRange, positionAfter);
			});
	}

	_applyQuote(writer, blocks, input = '') {
		const quotesToMerge = [];

		// Quote all groups of block. Iterate in the reverse order to not break following ranges.
		getRangesOfBlockGroups(writer, blocks)
			.reverse()
			.forEach((groupRange) => {
				let quote = findQuote(groupRange.start);

				if (!quote) {
					quote = writer.createElement('block', {
						'data-type': input,
					});

					writer.wrap(groupRange, quote);
					// writer.insert(quote, groupRange.start);
				}

				quotesToMerge.push(quote);
			});

		// Merge subsequent <bQ> elements. Reverse the order again because this time we want to go through
		// the <bQ> elements in the source order (due to how merge works – it moves the right element's content
		// to the first element and removes the right one. Since we may need to merge a couple of subsequent `<bQ>` elements
		// we want to keep the reference to the first (furthest left) one.
		quotesToMerge.reverse().reduce((currentQuote, nextQuote) => {
			if (currentQuote.nextSibling == nextQuote) {
				writer.merge(writer.createPositionAfter(currentQuote));

				return currentQuote;
			}

			return nextQuote;
		});
	}
	_isBlock(element) {
		let flag = true;
		while (flag) {
			if (element.is('element', 'block')) {
				flag = false;
				break;
			}
			if (element.parent) element = element.parent;
			else break;
		}
		return !flag;
	}
}

function findQuote(elementOrPosition) {
	return elementOrPosition.parent.name == 'block'
		? elementOrPosition.parent
		: null;
}

function getRangesOfBlockGroups(writer, blocks) {
	let startPosition;
	let i = 0;
	const ranges = [];

	while (i < blocks.length) {
		const block = blocks[i];
		const nextBlock = blocks[i + 1];

		if (!startPosition) {
			startPosition = writer.createPositionBefore(block);
		}

		if (!nextBlock || block.nextSibling != nextBlock) {
			ranges.push(
				writer.createRange(
					startPosition,
					writer.createPositionAfter(block)
				)
			);
			startPosition = null;
		}

		i++;
	}

	return ranges;
}

// Checks whether <bQ> can wrap the block.
function checkCanBeQuoted(schema, block) {
	// TMP will be replaced with schema.checkWrap().
	const isBQAllowed = schema.checkChild(block.parent, 'block');
	const isBlockAllowedInBQ = schema.checkChild(['$root', 'block'], block);

	return isBQAllowed && isBlockAllowedInBQ;
}
