import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'

import { useQuery, useMutation } from 'react-query'
import { getAPI, putAPI } from '../../../api'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid #ddd;
  color: ${props => props.isActive ? 'black' : '#7b7b7b'};
  background-color: ${props => props.isActive ? 'white' : '#ddd'};
  cursor: ${props => props.isActive ? 'pointer' : 'default'};

  :hover {
    color: ${props => props.isActive ? 'white' : ''};
    border: ${props => props.isActive ? '1px solid #2e6ef2' : ''};
    background-color: ${props => props.isActive ? '#2e6ef2' : ''};
  }
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any[]
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[]}: tableBodyProps) => {
  const navigate = useNavigate()
  const { mutate: PUT_API } = useMutation(putAPI)

  const check = (labeling_id: number, review_id: number) => {
    console.log(labeling_id, review_id)
    if (window.confirm('해당 프로젝트의 문제들을 서비스화 하시겠습니까?') === false) return
    const data = {
      apiURL: `/project/questions`,
      body: {
        labeling_id: labeling_id,
        review_id: review_id,
      }
    }
    console.log(data)
    PUT_API(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data?.data.message === 'success !') {
          // alert('완료되었습니다.')
        }
      },
    })
  }

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        console.log(row)
        return (
          <Row
            key={String(index)}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="left">{row.title}</Cell>
            <Cell align="center">{row.labeling_data?.term}</Cell>
            <Cell align="center">{row.review_data?.term}</Cell>
            <Cell align="center">{row.created_date}</Cell>
            <Cell align="center">
              <Btn isActive={true} onClick={() => navigate({
                pathname: `/project/management/modification`,
                search: `?${createSearchParams({labeling_id: row.labeling_data.id, review_id: row.review_data.id})}`
              })} >
                수정하기 ❯
              </Btn>
            </Cell>
            <Cell>
              <Btn isActive={!row.is_all_serviced} onClick={(e)=> !row.is_all_serviced ? check(row.labeling_data.id, row.review_data.id) : null}>서비스 적용</Btn>
            </Cell>
          </Row>
        )
      })}
    </>
  )
}

const ProjectListPage = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _search = searchParams.get('search')

  const headList = ['프로젝트명', '라벨링 기간', '검수 기간', '생성일', '', '']
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [keyword, setKeyword] = useState(_search)

  const { isFetching } = useQuery(['projects', page, keyword], () => getAPI('projects', {page: page + 1, unit: 0, project_name: keyword}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.projects} />
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
    }
  })

  const changedPage = (v: number) => {
    setSearchParams({
      page: v + 1, 
      search: keyword,
    })
  }

  const changedKeyword = (v: string) => {
    setSearchParams({
      page: 1, 
      search: v,
    })
  }

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setKeyword(_search)
  }, [_search])


  return (
    <MainSection>
      <>
        <Toolbar 
          title={'매칭된 프로젝트'} 
          isButton
          isSearch 
          keyword={keyword} 
          getKeyword={changedKeyword} 
          goToPage={() => navigate(`/project/management/creation`)} 
        />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

export default ProjectListPage