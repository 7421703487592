import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Card,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput
} from '@mui/material'
import styled from 'styled-components'

const Wrap = styled(Box)`
  /* min-width: 300px; */
  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
`

const LabelerMatching = ({ labelerList, setLabelers, labelers }) => {
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, users, theme) {
    return {
      fontWeight:
        users.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLabelers(value)
  }
  console.log(labelers)
  
  return (
    <Wrap>
      <FormControl sx={{ mt: 1, minWidth: 300, width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">선택</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={labelers}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {labelerList.length > 0 && labelerList.map((el, idx) => (
            <MenuItem
              key={el.id}
              id={el.id}
              value={el.name}
              style={getStyles(el.name, labelers, theme)}
            >
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrap>
  );
};

export default LabelerMatching;
