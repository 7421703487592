import styled, { keyframes } from 'styled-components'

export const ProgressBox = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  position: relative;
  padding: 2px 0 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;

  .text_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .label_pg {
    position: relative;
    width: ${({ percent }) => percent};
    /* width: ${({ width }) => width || '400px'}; */
    height: 15px;
    color: #2e6ef2;
    font-size: 16px;
    text-align: right;
    transition: all 0.3s ease;
  }
  .label_pg_percent {
    position: absolute;
    top: 0;
    right: -35px;
    min-width: fit-content;
    padding: 0 2px;
    color: #2e6ef2;
    font-size: 12px;
    font-weight: 500;
  }
  .label_left_text_box {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .label_right_text_box {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  /* .label_pg_current {
    color: #2e6ef2;
    font-size: 15px;
    font-weight: 700;
  } */
  .label_left_text {
    color: #222;
    font-size: 13px;
  }

  #progress {
    width: 100%;
    height: 10px;
    padding-bottom: 30px;
    appearance: none;

    ::-webkit-progress-bar {
      height: 8px;
      background-color: #f0f0f0;
      border-radius: 10px;
      box-shadow: inset 2px 2px 8px #ccc;
    }
    ::-webkit-progress-value {
      transition: all 0.3s ease;
      border-radius: 10px;
      background: #1a76d3;
      background: -webkit-linear-gradient(to right, #3d9eff, #2e6ef2);
      background: linear-gradient(to right, #3d9eff, #2e6ef2);
    }
  }

  #pg {
    /* position: absolute; */
    width: 10px;
    height: 10px;
    top: -10;
    right: 0;
    border: 1px solid red;
  }

  #pg_label {
    width: 100%;
    height: 5px;
    appearance: none;

    ::-webkit-progress-bar {
      height: 8px;
      background-color: transparent;
      border-radius: 10px;
    }
    ::-webkit-progress-value {
      position: relative;
      height: 18px;
      transition: all 0.3s ease;
      border-right: 1px solid rgba(14, 105, 196, 0.3);
      background: transparent;
    }
  }
`