import { produce } from "immer"
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from "redux-actions"

// // actions
const SET_MATCHED_PROJECT = "SET_MATCHED_PROJECT"
const SET_NEW_OLD_LABELER = "SET_NEW_OLD_LABELER"
const GET_LABELER_INFO = "GET_LABELER_INFO"

// // actionCreators
const setMatchedProject = createAction(SET_MATCHED_PROJECT, (obj) => ({ obj })) 
const setNewOldLabeler = createAction(SET_NEW_OLD_LABELER, (obj) => ({ obj })) 
const getLabelerInfo = createAction(GET_LABELER_INFO, (obj) => ({ obj })) 

// // initialState
const initialState = {
  project_data: {
    list: [],
    total_count: 0,
    total_page_num: 0,
  },
  labeler_info: {},
}

const getMatchedProjectAPI = (page, keyword) => {
  const accessToken = localStorage.getItem("access_token")
  let path = `project/all?page=${page}`

  if (keyword) path = path + `&project_name=${keyword}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(setMatchedProject(res.data))
      }
    } catch (err) {
      console.log(err, "getMatchedProjectAPI")
    }
  }
}

const getNewOldLabelerAPI = (page1, page2) => {
  const path = `project/labeler-list?page1=${page1}&page2=${page2}`
  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      // if (res.status === 200) {
      //   dispatch(setNewOldLabeler(res.data))
      // }
    } catch (err) {
      console.log(err, "getNewOldLabelerAPI")
    }
  }
}

const getLabelerInfoAPI = (labeler_id) => {
  const path = `project/labeler-detail/${labeler_id}`
  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status === 200) {
        dispatch(getLabelerInfo(res.data))
      }
    } catch (err) {
      console.log(err, "getNewOldLabelerAPI")
    }
  }
}


// // reducer
export default handleActions(
  {
    [SET_MATCHED_PROJECT]: (state, action) =>
      produce(state, (draft) => {
        draft.project_data.list = action.payload.obj.data;
        draft.project_data.total_count = action.payload.obj.total_count;
        draft.project_data.total_page_num = action.payload.obj.total_page;
      }),

    [SET_MATCHED_PROJECT]: (state, action) =>
      produce(state, (draft) => {
        draft.project_data.list = action.payload.obj.data;
        draft.project_data.total_count = action.payload.obj.total_count;
        draft.project_data.total_page_num = action.payload.obj.total_page;
      }),

    [GET_LABELER_INFO]: (state, action) =>
      produce(state, (draft) => {
        draft.labeler_info = action.payload.obj;
      }),
  },
  initialState
);

const actionCreators = {
  getMatchedProjectAPI,
  getNewOldLabelerAPI,
  getLabelerInfoAPI,
  getLabelerInfo,
};

export { actionCreators };
