import { produce } from 'immer'
import { createAction, handleActions } from 'redux-actions'

// actions
const SET_SEARCH_KEYWORD_01 = "SET_SEARCH_KEYWORD_01";
const SET_SEARCH_KEYWORD_02 = "SET_SEARCH_KEYWORD_02";
const SET_SEARCH_KEYWORD_03 = "SET_SEARCH_KEYWORD_03";

// actionCreators
export const setSearchKeyword01 = createAction(SET_SEARCH_KEYWORD_01, (string) => ({ string }));
export const setSearchKeyword02 = createAction(SET_SEARCH_KEYWORD_02, (string) => ({ string }));
export const setSearchKeyword03 = createAction(SET_SEARCH_KEYWORD_03, (string) => ({ string }));

// initialState
const initialState = {
  keyword_01: '',
  keyword_02: '',
  keyword_03: '',
};

// reducer
export default handleActions(
  {
    [SET_SEARCH_KEYWORD_01]: (state, action) =>
      produce(state, (draft) => {
        draft.keyword_01 = action.payload.string;
      }),
    [SET_SEARCH_KEYWORD_02]: (state, action) =>
      produce(state, (draft) => {
        draft.keyword_02 = action.payload.string;
      }),
    [SET_SEARCH_KEYWORD_03]: (state, action) =>
      produce(state, (draft) => {
        draft.keyword_02 = action.payload.string;
      }),
  },
  initialState
);

const actionCreators = {
  setSearchKeyword01,
  setSearchKeyword02,
  setSearchKeyword03,
};

export { actionCreators };
