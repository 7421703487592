import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { actionCreators as editorActions } from '../../../../redux/modules/editor'
import { TablePaginationActions } from '../../../../assets/utils/TablePaginationActions'

const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;

const List = React.memo(({ questionList, is_not_editor, filter }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {'projectId': params_1, 'paperId': params_2} = useParams()
  const projectId = Number(params_1)
  const pdf_id = Number(params_2)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(questionList.length)

  const GoToEditor = () => {
    navigate(`/workspace/labeling/editor/${projectId}/${pdf_id}`)
  }

  const handleEnterEditor = (question_id, filter, write_yn) => {
    // dispatch(editorActions.setWriteYN(write_yn))
    // navigate(`/workspace/labeling/correcting/${id}`)
    navigate(`/workspace/project/labeling/content/question/${question_id}/correcting`)
  }
  console.log(questionList)

  return (
    <Wrap style={{position: 'relative'}}>
      {questionList.length === 0 && is_not_editor &&
        <NoneList onClick={GoToEditor}>
            1차 에디터 작업을 완료해 주세요.
        </NoneList>
      }
      {!is_not_editor &&
        <>
          <TableContainer component={Paper} sx={{ height: '100%' }} >  
            <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <Cell align="center">No.</Cell>
                  <Cell align="center">번호</Cell>
                  <Cell align="center">작성 여부</Cell>
                  <Cell align="center">난이도</Cell>
                  <Cell align="center">오류 여부</Cell>
                  <Cell align="center">이미지</Cell>
                  <Cell align="center">객관식</Cell>
                  <Cell align="center">주관식</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questionList.length > 0 && questionList.map((row, index) => {
                  const no = page * rowsPerPage + index + 1
                  return (
                    <Row
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => handleEnterEditor(row.id, filter, row.write_yn)}
                    >
                      <Cell align="center">{no}</Cell>
                      <Cell align="center">{row.id}</Cell>
                      <Cell align="center">{row.write_yn}</Cell>
                      <Cell align="center">{row.difficult}</Cell>
                      <Cell align="center">
                      {row.error === '-' && '-'}
                      {row.error === 0 && <Circle bgColor={'#66CC33'} />}
                      {row.error === 1 && <Circle bgColor={'red'} />}
                      {row.error === 2 && <Circle bgColor={'orange'} />}
                      {row.error === 3 && <Circle bgColor={'#dddddd'} />}
                      </Cell>
                      <Cell align="center">{row.image}</Cell>
                      <Cell align="center">{row.type === '객관식' ? 'O' : ''}</Cell>
                      <Cell align="center">{row.type === '주관식' ? 'O' : ''}</Cell>
                    </Row>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    </Wrap>
  )
})
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor};
`
const NoneList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #2e6ef2;
  font-size: 1.2rem;
  font-weight: 700;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`
const EditorButton = styled.div`
  flex: 60px;
  box-sizing: border-box;
  padding: 0 16px;
  max-width: fit-content;
  height: 44px;
  color: #222;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;

  :hover {
    color: white;
    background-color: #2e6ef2;
  }
`
export default List