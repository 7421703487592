import {useState, useEffect} from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import Toolbar from '../mainSection/Toolbar'
import BackToList from '../../../elements/button/BackToList'

import cropIcon from '../../../assets/icons/workspace/crop.svg'
import inactiveCropIcon from '../../../assets/icons/workspace/crop_inactive.svg'
import frameIcon from '../../../assets/icons/workspace/add-frame.svg'
import textIcon from '../../../assets/icons/workspace/text-alt.svg'
import squareIcon from '../../../assets/icons/workspace/add-square.svg'

const GridContainer = styled(Grid)`
  position: absolute;
  right: 0;
  width: 100%;
  /* display: grid; */
  display: ${props => props.display ? 'grid' : 'none' };
  transition: all 180ms ease;
  z-index: 10;
`
const PaperContainer = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Toolbox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;

  .tool {
    padding: 0.3rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .tool__crop_inactive, .tool__cutting_box_inactive {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }

  .tool img path {
    stroke: '#fff';
  }
`
const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  width: 100%;
  flex: 1;
  padding: 0.8rem;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const ImageOrigin = styled.img`
  position: absolute;
  width: ${props => props.width};
  height: ${props => props.height};
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* z-index: 2; */
`
const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  /* right: 0;
  bottom: 0; */
  width: 100%;
  height: 100%;
  z-index: 1000;
  /* background: red; */
  /* border: 1px solid red; */
`
const TestPaper = styled.div`  
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transition: 150ms ease;
  background-color: transparent;
  overflow-y: auto;
  /* border: 1px solid red; */
`

interface WorkspaceProps {
  cropBox: boolean
  rect: boolean
  cropImage: ()=>void
  createRect: ()=>void
  isEditing: boolean
  selectedImg: string
  selectedImgSize: number[]
  onImgLoad: (v:any)=>void
  getInputColor: ()=>void
  exitTextEditSpace: ()=>void
  handleCloseCropEditor: ()=>void
}

const Workspace = ({
  cropBox=false,
  rect=false, 
  cropImage=()=>{}, 
  createRect=()=>{},
  isEditing=false,
  selectedImg,
  selectedImgSize,
  onImgLoad=()=>{},
  getInputColor=()=>{},
  exitTextEditSpace=()=>{},
  handleCloseCropEditor=()=>{}
}: WorkspaceProps) => {

  return (
    <GridContainer item xs={12} sm={8} md={8} lg={8} display={cropBox} >
      <PaperContainer >
        <Toolbar title={'Workspace'}>
          <>
            <Toolbox>
              <div 
                className={[ 'tool', rect ? 'tool__crop' : 'tool__crop_inactive' ].join(' ')} 
                onClick={cropImage}
              >
                <img alt={cropIcon} src={rect ? cropIcon : inactiveCropIcon} />
              </div>
              <div 
                className={[ 'tool', 'tool__cutting_box' ].join(' ')} 
                onClick={createRect}
              >
                <img alt={frameIcon} src={frameIcon} />
              </div>
            </Toolbox>
            {isEditing &&
              <Toolbox>
                <div className='tool tool__box'><img alt={squareIcon} src={squareIcon} /></div>
                <div className='tool tool__text'><img alt={textIcon} src={textIcon} /></div>
                <div className='tool tool__color_picker'><input type='color' onChange={getInputColor} /></div>
                {/* <div className='tool tool__color_picker'><img alt={colorPickerIcon} src={colorPickerIcon} /></div> */}
              </Toolbox>
            }
            {isEditing &&
              <BackToList onclick={exitTextEditSpace} height={'2.3rem'} fontWeight={400}>Crop</BackToList>
            }
            <BackToList onclick={handleCloseCropEditor} height={'2.3rem'} fontWeight={400}>Exit</BackToList>
          </>
        </Toolbar>

        <CardPadding>
          <TestPaper id='image_box' >
            {!isEditing &&
              <ImageOrigin 
                id='canvas_image'
                alt={selectedImg} 
                src={selectedImg} 
                onLoad={onImgLoad}
                width={selectedImgSize[0] > selectedImgSize[1] ? 'fit-content' : '100%'}
                height={selectedImgSize[0] < selectedImgSize[1] ? '100%' : 'fit-content'}
              />
            }
            <Canvas id='crop_canvas' />
          </TestPaper>
        </CardPadding>

      </PaperContainer>
    </GridContainer>
  )
}

export default Workspace


{/* <Boxing item xs={12} sm={8} md={8} lg={8} visible={`${cropBox}`} sx={{zIndex: '10', position: 'absolute', right: 0, width: '100%'}}>
  <Item hidden={'hidden'} sx={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} >
    <Title>
      <BtnBox>
        <div className='title'>Workspace</div>
        <BtnBox02>
          <Toolbar>
            <div 
              className={[ 'tool', rect ? 'tool__crop' : 'tool__crop_inactive' ].join(' ')} 
              onClick={handleCropImage}
            >
              <img alt={cropIcon} src={rect ? cropIcon : inactiveCropIcon} />
            </div>
            <div 
              className={[ 'tool', 'tool__cutting_box' ].join(' ')} 
              onClick={createRect}
            >
              <img alt={frameIcon} src={frameIcon} />
            </div>
          </Toolbar>

          {isEditing &&
            <Toolbar>
              <div className='tool tool__box'><img alt={squareIcon} src={squareIcon} /></div>
              <div className='tool tool__text'><img alt={textIcon} src={textIcon} /></div>
              <div className='tool tool__color_picker'><input type='color' onChange={getInputColor} /></div>
            </Toolbar>
          }
          {isEditing &&
            <BackToList onclick={exitTextEditSpace} height={'2.3rem'} fontWeight={400}>Crop</BackToList>
          }
          <BackToList onclick={handleCloseCropEditor} height={'2.3rem'} fontWeight={400}>Exit</BackToList>
        </BtnBox02>
      </BtnBox>
    </Title>

    <CardPadding>
      <TestPaper id='image_box' >
        {!isEditing &&
        <ImageOrigin 
          id='canvas_image'
          alt={selectedImg} 
          src={selectedImg} 
          onLoad={onImgLoad}
          width={selectedImgSize[0] > selectedImgSize[1] ? 'fit-content' : '100%'}
          height={selectedImgSize[0] < selectedImgSize[1] ? '100%' : 'fit-content'}
        />
        }
        <Canvas id='crop_canvas' />
      </TestPaper>
    </CardPadding>

  </Item>
</Boxing> */}