import { produce } from "immer"
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from "redux-actions"

// actions
const SET_POST_LIST = "SET_POST_LIST" // 
const SET_POST_TOTAL_COUNT = "SET_POST_TOTAL_COUNT" // 
const SET_POST_TITLE_CONTENT = "SET_POST_TITLE_CONTENT" // 
const SET_GET_MESSAGE_LIST = "SET_GET_MESSAGE_LIST" // 
const SET_POST_MESSAGE_LIST = "SET_POST_MESSAGE_LIST" // 
const SET_ALERT_SEND = "SET_ALERT_SEND" // 
const SET_IS_LAST = "SET_IS_LAST" // 


// actionCreators
const setPostList = createAction(SET_POST_LIST, (list) => ({ list }))
const setPostTotalCount = createAction(SET_POST_TOTAL_COUNT, (num) => ({ num }))
const setPostTitleContent = createAction(SET_POST_TITLE_CONTENT, (obj) => ({ obj }))
const setGetMessage = createAction(SET_GET_MESSAGE_LIST, (list) => ({ list }))
const setPostMessage = createAction(SET_POST_MESSAGE_LIST, (list) => ({ list }))
const setAlertSend = createAction(SET_ALERT_SEND, (bool) => ({ bool }))
const setIsLast = createAction(SET_IS_LAST, (bool) => ({ bool }))


// initialState
const initialState = {
  post_list: [],
  total_count: 0,      // 
  post_title_content: {},      // 
  message: [],      // 
  alert_send: false,      // 
  is_last: false,      // 
}


// 게시글 - 리스트
const getPostListAPI = (page, filter, category) => {
  if (filter === '' || category === '') return
  const token = localStorage.getItem('access_token')
  const path = `/labeling/errors?page=${page}&filter=${filter}&category=${category}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.status === 200) {
        await dispatch(setPostList(res.data.data))
        await dispatch(setPostTotalCount(res.data.total_count))
      }
    } catch (err) {
      console.log(err, "getPostListAPI")
    }
  }
}

// 게시글 - 제목 + 내용
const getPostContentAPI = (questionId) => {
  const token = localStorage.getItem('access_token')
  const path = `/labeling/errors/${questionId}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.status === 200) {
        await dispatch(setPostTitleContent(res.data))
      }
    } catch (err) {
      console.log(err, "getPostContentAPI")
    }
  }
}


// 게시글 - 채팅 메시지 받기
const getMessageAPI = (id, page) => {
  const token = localStorage.getItem('access_token')
  const path = `/labeling/errors/${id}/reples?page=${page}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.data.length === 0) {
        console.log('??????????????????????????????????')
        await dispatch(setIsLast(true))
        return
      }
      if (res.status === 200 && res.data.length > 0) {
        await dispatch(setGetMessage(res.data))
      }
    } catch (err) {
      console.log(err, "getMessageAPI")
    }
  }
}

// 게시글 - 채팅 메시지 보내기
const postSendMessageAPI = (id, message) => {
  const token = localStorage.getItem('access_token')
  const path = `/labeling/errors/${id}/reples`
  const body = {
    message: message,
  }

  return async function (dispatch) {
    try {
      const res = await Axios.post(path, body, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.status === 200) {
        await dispatch(setPostMessage(res.data))
        await dispatch(setAlertSend(true))
      }
    } catch (err) {
      console.log(err, "getPostMessageAPI")
    }
  }
}


// // reducer
export default handleActions(
  {
    [SET_POST_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.post_list = action.payload.list;
      }),

    [SET_POST_TOTAL_COUNT]: (state, action) =>
      produce(state, (draft) => {
        draft.total_count = action.payload.num;
      }),

    [SET_POST_TITLE_CONTENT]: (state, action) =>
      produce(state, (draft) => {
        draft.post_title_content = action.payload.obj;
      }),

    [SET_GET_MESSAGE_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.message = [...draft.message, ...action.payload.list];
      }),

    [SET_POST_MESSAGE_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.message = action.payload.list;
      }),

    [SET_ALERT_SEND]: (state, action) =>
      produce(state, (draft) => {
        draft.alert_send = action.payload.bool;
      }),

    [SET_IS_LAST]: (state, action) =>
      produce(state, (draft) => {
        draft.is_last = action.payload.bool;
      }),
  }, 
  initialState
);

  
const actionCreators = {
  getPostListAPI,
  getPostContentAPI,
  postSendMessageAPI,
  getMessageAPI,
  setPostMessage,
  setAlertSend,
  setIsLast
};

export { actionCreators };