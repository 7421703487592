import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  Grid,
  Paper,
} from '@mui/material'
import List from '../../components/detail/detail/List'
import MidList from '../../components/detail/detail/MidList'
import SubList from '../../components/detail/detail/SubList'
import BackToList from '../../elements/button/BackToList'
import ProgressBarOrigin from '../../elements/progressBar/ProgressBarOrigin'
import { useQuery } from 'react-query'
import { getMyProjectDetailAPI } from '../../api/getAPI'

const DetailListPage = (props) => {
  const location = useLocation()
  const path_bool = location.pathname.includes('labeler/matching/detail')  // 어드민에서 온다면 true
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  // console.log(searchParams.get('projectId'), searchParams.get('role'), searchParams.get('labelerId'))
  const role = Number(searchParams.get('role'))
  const project_id = Number(searchParams.get('projectId'))
  const labeler_id = Number(searchParams.get('labelerId'))
  const [ pageNum, setPageNum ] = useState(1)
  const [ pdfList, setPdfList ] = useState([])
  const [ totalCount, setTotalCount ] = useState(0)

  const path_data = path_bool 
    ? { project_id: project_id, labeler_id: labeler_id } 
    : { project_id: project_id }

  const { data } = useQuery(['projectData'],
    () => getMyProjectDetailAPI(path_bool, path_data), 
    {
      onSuccess: data => {
        console.log(data)

        handleSetPdfList(data.data.data)
        // handleTotalCount(data.data.total_page_count)
      },
      onError: error => {
        console.log(error)
      }
    }
  )

  const handleSetPdfList = useCallback((arg) => {
    setPdfList(arg)
  }, [pdfList])

  const handleTotalCount = useCallback((arg) => {
    setTotalCount(arg)
  }, [totalCount])

  const handleSetPageNum = useCallback((page) => {
    setPageNum(page)
  }, [pageNum])

  const GoBackToList = () => {
    navigate(-1)
    // navigate(`/detail`)
  }

  return data && (
    <Grid container direction={"column"} >
      
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>{data.data.project_name}</div>
              {/* <BtnBox02>
                <BackToList onclick={GoBackToList} >프로젝트 리스트</BackToList>
              </BtnBox02> */}
            </BtnBox>
          </Title>
            <BtnBox>
              <BtnBox03>
                <div className='info'>기간: {data.data.date}</div>
                <div className='info'>단가: {data.data.ocr_price}</div>
              </BtnBox03>
              <div className='progress'>
                <ProgressBarOrigin overall={data.data.all_count} done={data.data.done_count} percent={data.data.percentage} />
              </div>
            </BtnBox>
          {role === 0 &&
            <SubList OCR={data.data.OCR_count} NOCR={data.data.NO_OCR_count} KOCR={data.data.Korean_OCR_count} total={data.data.all_count} done={data.data.done_count} />
          }
          {role === 0 &&
            <List
              pdfList={pdfList}
              totalCount={totalCount}
              // handleSetPageNum={handleSetPageNum}
            />
          }
          {role === 1 &&
            <MidList 
              pdfList={pdfList}
              totalCount={totalCount}
            />
          }
        </Item>
      </Grid>
    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  padding-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
  .progress {
    width: 55%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`
const BtnBox03 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  min-width: fit-content;
  font-size: 0.85rem;
  font-weight: 400;

  .info {
    width: fit-content;
  }
`

export default DetailListPage