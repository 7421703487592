import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ClassicEditor from 'ckeditor5/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import 'katex/dist/katex.css'
import _ from 'lodash'
import { actionCreators as editorWritingActions } from "../../../redux/modules/thai/editor_writing"
import { question_config } from './CkEditorConfig'
import Accordion from '../../_molecules/Accordion'

const Question = ({ type }) => {
  const dispatch = useDispatch()
  const data_html = useSelector(
    (state) => 
      type === '문제'
        ? state.th_editor_writing.question_html
        : (type === '해설'
            ? state.th_editor_writing.explanation_html
            : state.th_editor_writing.relation_html
          )
  )

  // const onChange_data = _.debounce((e) => {
  //   // console.log(e)
  //   dispatch(
  //     type === '문제' 
  //       ? editorWritingActions.set_question_data_html(e)
  //       : editorWritingActions.set_explanation_data_html(e)
  //   )
  // }, 200)

  const onChange_data = (e) => {
    if (type === '문제') dispatch(editorWritingActions.set_question_data_html(e))
        else if (type === '해설') dispatch(editorWritingActions.set_explanation_data_html(e))
        else if (type === '연관 지문') dispatch(editorWritingActions.set_relation_data_html(e))
    // dispatch(
    //   type === '문제' 
    //     ? editorWritingActions.set_question_data_html(e)
    //     : editorWritingActions.set_explanation_data_html(e)
    // )
  }

  return (
    <>
      {type === '문제' 
        ? (
          <Wrap>
            <CKEditor
              editor={ ClassicEditor }
              data={data_html}
              config={question_config}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  onChange_data(data)
              } }
            />
          </Wrap>
        )
        : (
          <Container>
            <Wrap>
              <CKEditor
                editor={ ClassicEditor }
                data={data_html}
                config={question_config}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    onChange_data(data)
                } }
              />
            </Wrap>
            
          </Container>
        )
      }
    </>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 100%;

  .ck.ck-reset.ck-editor.ck-rounded-corners {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ck.ck-editor__main {
    width: 100%;
    height: calc(100% - 4.8rem);
    overflow-y: hidden;

    @media (min-width: 1670px) {
      height: calc(100% - 2.4rem);
    }
  }
  .ck.ck-content.ck-editor__editable {
    height: 100%;
    width: 350px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
`
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`

export default Question