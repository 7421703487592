import { produce } from 'immer'
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from 'redux-actions'


// actions
const IS_LOADING = "IS_LOADING";
const SET_MIDDLE_UNIT_OPTION = "SET_MIDDLE_UNIT_OPTION";
const SET_MIDDLE_UNIT_ID = "SET_MIDDLE_UNIT_ID";
const SET_Q_LIST = "SET_Q_LIST";

const SET_ALL_CACHE = "SET_ALL_CACHE" // 캐싱
const SET_INIT = "SET_INIT" // 데이터 초기화

// actionCreators
const set_is_Loading = createAction(IS_LOADING, (boolean) => ({ boolean }))
const set_MU_Option = createAction(SET_MIDDLE_UNIT_OPTION, (list) => ({ list }));
const set_MU_id = createAction(SET_MIDDLE_UNIT_ID, (int) => ({ int }));
const set_Q_list = createAction(SET_Q_LIST, (list) => ({ list }));

const set_all_cache = createAction(SET_ALL_CACHE, (obj) => ({ obj }));
const set_init = createAction(SET_INIT, () => ({  }))


// initialState
const initialState = {
  is_loading: true,
  option_list: [],
  mid_unit_id: 0,
  Q_list: [],
  Q_count: 0,
  Q_page: 1,
  filter_writing: '',
};

const getThMidUnit = () => {
  const path = `/thai/subsection`;
  const token = localStorage.getItem('access_token');

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res.data)
    } catch (err) {
      console.log(err, "getThMidUnit")
    }
  }
}

// 중단원 선택 후 결과 list 요청
const getThQuestionList = (page, subsection_id, writing) => {
  const token = localStorage.getItem('access_token');

  const filter_writing = writing === '' ? '' : `&&labeling=${writing}`
  const query = `?page=${page}${filter_writing}`
  const path = `/thai/subsection/${subsection_id}${query}`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log('--------')
      console.log(res.data)
      console.log('--------')
      if (res.status === 200) {
        const data = {
          mid_unit_id: subsection_id,
          Q_list: res.data.data,
          Q_count: res.data.count,
          Q_page: page
        }
        await dispatch(set_all_cache(data))
      }
    } catch (err) {
      console.log(err, "getThQuestionList")
    }
  }
}


// reducer
export default handleActions(
  {
    [IS_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = action.payload.boolean;
      }),
    [SET_MIDDLE_UNIT_OPTION]: (state, action) =>
      produce(state, (draft) => {
        draft.option_list = action.payload.list;
      }),
    [SET_MIDDLE_UNIT_ID]: (state, action) =>
      produce(state, (draft) => {
        draft.mid_unit_id = action.payload.int;
      }),
    [SET_Q_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.Q_list = action.payload.list;
      }),
    [SET_ALL_CACHE]: (state, action) =>
      produce(state, (draft) => {
        draft.mid_unit_id = action.payload.obj.mid_unit_id;
        draft.Q_list = action.payload.obj.Q_list;
        draft.Q_count = action.payload.obj.Q_count;
        draft.Q_page = action.payload.obj.Q_page;
      }),
    [SET_INIT]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = true;
        draft.option_list = [];
        draft.Q_list = [];
      }),
  },
  initialState
);

const actionCreators = {
  getThMidUnit,
  getThQuestionList,
  set_MU_Option,
  set_init,
};

export { actionCreators };