import { useState, useEffect, useRef } from 'react'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAPI, postAPI, putAPI } from '../../../../api'

import { Axios } from '../../../../api/api.config'
import styled from 'styled-components'
import plusIcon from '../../../../assets/icons/plus.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'
import MainSection from '../../../../components/_templates/MainSection'
import Toolbar from '../../../../components/_organisms/mainSection/Toolbar'
import SelectBox from '../../../../components/_molecules/select/SelectBox'
import ChipSelectBox from '../../../../components/_molecules/select/ChipSelectBox'

import SectionBox from '../../../../components/admin/upload/thai/SectionBox'

const Detail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const __id = id ? Number(id) : null // number or null
  const [_id, setId] = useState(__id)
  const [searchParams] = useSearchParams()
  const _service = searchParams.get('service')
  const _tab = searchParams.get('tab')
  const [_name, setName] = useState(localStorage.getItem("upload-creation-name"))
  const [change, setChange] = useState(false)

  const [originData, setOriginData] = useState(null)
  const [data, setData] = useState(null)
  const [stale, setStale] = useState(0)
  const [isEditable, setIsEditable] = useState(true)
  const [hasSection, setHasSection] = useState(true)

  useEffect(()=>{
    if (!originData) return
    console.log(originData)
    setIsEditable(originData.is_editable)

    // 전체 이름
    setFullTitle(originData.name)

    // 이름
    setTitle(originData.short_name)

    // 연도
    setYear(originData.year)
    
    // 학기
    setSemester(originData.semester)

    // 과목
    setSubject(originData.subject.id)

    // 세부과목
    setSubSubject(originData.sub_subject.id)
    
    // 학년
    setGrade(originData.grade.id)
    setData(originData.sections ? originData.sections : [])
    setTab(originData.sections ? 0 : 1)
  }, [originData, _service])


  // ----- SERVICE -----
  const [service, setService] = useState(_service)
  const serviceList = [
    {name: '책', value: 'books'},
    {name: '기출', value: 'qb'},
    {name: '태국', value: 'thai'},
  ]

  // ----- TAB -----
  const [tab, setTab] = useState(0)
  const tabList = [
    {name: `${serviceList.find(el => el.value === _service)?.name} 생성하기`, value: 0},
    {name: '중단원 생성 / 링킹하기', value: 1},
  ]

  const getDetail = useQuery([`get-upload/${_id}/${_service}`, {_id, _service, _name, change}], () => 
    getAPI(`upload/${_id}/${_service}`, {name: _name}), {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!(_id),
      onSuccess: data => {
        console.log('성공했다', data.data, _id, _name)
        setHasSection(data?.data.has_section)
        setOriginData(data?.data)
      }
    }
  )

  // ----- title -----
  const [title, setTitle] = useState('')
  const [fullTitle, setFullTitle] = useState(_name)

  // ----- YEAR -----
  const [year, setYear] = useState(-1)
  const getYearList = (year: number, beforeCount: number, afterCount: number) => {
    const nowAndBeforeYears = Array.from({length: beforeCount}, (el, index) => {return {name: year - index, value: year - index}})
    const afterYears = Array.from({length: afterCount}, (el, index) => {return {name: year + index + 1, value: year + index + 1}}).reverse()
    return [...afterYears, ...nowAndBeforeYears]
  }
  const yearList = getYearList(new Date().getFullYear(), 10, 2)

  // ----- SEMESTER -----
  const [semester, setSemester] = useState(-1)
  const semesterList = [
    {name: '1학기', value: 0},
    {name: '2학기', value: 1},
    {name: '학기구분없음', value: 2},
  ]


  // ----- SUBJECT -----
  const [subject, setSubject] = useState(-1)
  const [subjectList, setSubjectList] = useState(null)

  // ----- SUB_SUBJECT -----
  const [subSubject, setSubSubject] = useState(-1)
  const [subSubjectList, setSubSubjectList] = useState(null)

  // ----- SUBJECT -----
  const [grade, setGrade] = useState(-1)
  const [gradeList, setGradeList] = useState(null)

  useEffect(()=>{
    if (subject !== -1) {
      setSubSubject(-1)
      setSubSubjectList(null)
      setGrade(-1)
      setGradeList(null)
    }
  }, [subject])

  const getSubjects = useQuery([`th/subjects`], () => getAPI(`th/subjects`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!(!subjectList),
    onSuccess: (data) => {
      // console.log(data)
      setSubjectList(data.data)
    }
  })

  const getSubSubjects = useQuery([`th/${subject}/sub-subjects`, {subject}], () => getAPI(`th/${subject}/sub-subjects`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!(subject !== -1),
    onSuccess: (data) => {
      // console.log(data)
      setSubSubjectList(data.data)

      if (!!originData && stale === 0) {
        setStale(1)
        setSubSubject(originData.sub_subject.id)
      } else {
        setSubSubject(-1)
      }
      
    }
  })

  const getGrades = useQuery([`th/${subSubject}/grades`, {subSubject}], () => getAPI(`th/${subSubject}/grades`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!(subSubject !== -1),
    onSuccess: (data) => {
      // console.log(data)
      setGradeList(data.data)
      
      if (!!originData && stale === 1) {
        setStale(2)
        setGrade(originData.grade.id)
      } else {
        setGrade(-1)
      }
    }
  })

  const { mutate: POST_API_01 } = useMutation(postAPI)
  const { mutate: PUT_API_01 } = useMutation(putAPI)
  const post01 = () => {
    if (title === '' || year === -1 || semester === -1 || grade === -1 || subject === -1 || subSubject === -1) return alert('모든 항목을 입력 또는 선택해주세요.')
    
    const _subject = subjectList?.find(el => el.id === subject)?.subject
    const _subSubject = subSubjectList?.find(el => el.id === subSubject)?.sub_subject
    const _grade = gradeList.find(el => el.id === grade)?.grade
    const _semester = semesterList.find(el => el.value === semester)?.name
    const fullTitle = `${title}_${_subject}_${_subSubject}_${_grade}_${_semester}(${year})`
    if (window.confirm(`${fullTitle}을(를) ${!_id ? '생성' : '수정'}하시겠습니까?`) === false) return
    setFullTitle(fullTitle)

    const data = {
      apiURL: _id ? `/upload/${_id}/${service}` : `/upload/thai`, // 수정 : 생성
      body: {
        name: fullTitle,
        short_name: title,
        year: year,
        grade: grade,
        semester: semester,
      }
    }
    !_id && POST_API_01(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        alert('생성이 완료되었습니다.')
        // setTab(1)
        setId(data.data.id)
        localStorage.setItem("upload-creation-id", data.data.id)
        localStorage.setItem("upload-creation-name", data.data.name)
        localStorage.setItem("upload-creation-service", data.data.service)
        setName(data.data.name)
      },
    })

    _id && PUT_API_01(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data?.status === 201) {
          localStorage.setItem("upload-creation-name", fullTitle)
          setName(fullTitle)
          setChange(!change)
          alert('수정이 완료되었습니다.')
        }
      },
    })
  }

  useEffect(()=>{
    return () => {
      localStorage.removeItem('upload-creation-id')
      // localStorage.removeItem('upload-creation-name')
      localStorage.removeItem('upload-creation-service')
    }
  }, [])

  useEffect(() => {
    setService(_service)
  }, [_service])


  const { mutate: POST_API_02 } = useMutation(postAPI)
  
  const post02 = () => {
    // if (hasSection) return alert('실 DB에 저장된 대단원과 중단원이므로 수정이 불가능해요~ 지연님을 불러주세요!')
    const all_input_data = Array.from(document.querySelectorAll('.input-origin'))
    let res: any[] = []

    class subSectionClass {
      constructor(id: string, ko_name: string, is_editable) {
        this.id = id;
        this.name = {ko: ko_name, th: ''};
        this.is_editable = is_editable;
        this.sub_sections = [];
      }
    }
    
    all_input_data.forEach(el => {

      // 대단원 - 한국
      if (el.dataset.type === 'section') {
        const id = el.dataset.id
        const ko_name = el.value
        const is_editable = !!(el.dataset.iseditable === 'true')
        res = [...res, new subSectionClass(id, ko_name, is_editable)]
        return
      }

      // 대단원 - 태국
      if (el.dataset.type === 'section_thai') {
        const id = el.dataset.id
        const th_name = el.value
        const new_data = res.map((section: any) => {
          if (section.id === id) section.name.th = th_name
          return section
        })
        res = new_data
        return
      }
      
      // 중단원 - 한국
      if (el.dataset.type === 'sub_section') {
        const section_id = el.dataset.sectionid
        const sub_section_id = el.dataset.subsectionid
        const ko_name = el.value

        const new_data = res.map((section: any) => {
          if (section.id === section_id) {
            section.sub_sections = [...section.sub_sections, {id: sub_section_id, name: {ko: ko_name, th: ''}}]
          }
          return section
        })
        res = new_data
        return
      }

      // 중단원 - 태국
      if (el.dataset.type === 'sub_section_thai') {
        const section_id = el.dataset.sectionid
        const sub_section_id = el.dataset.subsectionid
        const th_name = el.value

        const new_data = res.map((section: any) => {
          if (section.id === section_id) {
            section.sub_sections.map((e: any) => {
              if (e.id === sub_section_id) {
                e.name.th = th_name
              }
              return e
            })
          }
          return section
        })
        res = new_data
        return
      }
    })
    console.log(res)
    
    const checkEmptyData = (obj: any) => {
      const isEmptySection: boolean = obj.findIndex(e => e.name.ko === '' || e.name.th === '') !== -1
      const isEmptySubSection: boolean = obj.findIndex(e => e.sub_sections.find(v => v.name.ko === '' || v.name.th === '')) !== -1
  
      const result = {isEmptySection, isEmptySubSection}
      return result
    }
    const {isEmptySection, isEmptySubSection} = checkEmptyData(res)

    if (isEmptySection) return alert('대단원을 모두 입력해주세요!')
    if (isEmptySubSection) return alert('중단원을 모두 입력해주세요!')

    const upload_creation_id = localStorage.getItem("upload-creation-id")

    const real_res = res.filter((e: any) => e.is_editable)

    const data = {
      apiURL:`/upload/${_id ? _id : upload_creation_id}/${service}/sub-sections`,
      body: {
        sections: real_res,
      }
    }
    POST_API_02(data, {
      onSuccess: (data) => {
        console.log('data:', data)
        if (data.data.message === 'success') {
          alert('중단원 생성/링킹이 완료되었습니다.')
          navigate(-1)
        }
      },
    })
  }

  const save = () => {
    if (tab === 0) return post01()
    if (tab === 1) return post02()
  }

  return (
    <MainSection >
      <>
        <Toolbar
          tabList={tabList}
          activeTab={tab}
          getTab={setTab}
          isButtonCus={isEditable}
          buttonName={_id ? '수정하기' : '저장하기'}
          onClickButton={()=>save()} 
        />
        {tab === 0 &&
        <>
          <InputWrap>
            <div className='input-box'>
              <div className='input-label'>{`${serviceList.find(el => el.value === _service)?.name} 이름`}</div>
              <input type='text' defaultValue={title} className='input' onChange={(el)=>setTitle(el.target.value)} />
            </div>
          </InputWrap>
          <SelectWrap>
            <SelectBox label='연도'>
              <>
                {yearList.map(el => 
                  <button key={el.value} onClick={() => setYear(el.value)} className={[
                    'item',
                    year === el.value && 'active'
                    ].join(' ')} type='button'
                  >{el.name}</button>
                )}
              </>
            </SelectBox>
            {!(_id && _service === 'books') &&
              <SelectBox label='학기'>
                <>
                  {semesterList.map(el => 
                    <button key={el.value} onClick={() => setSemester(el.value)} className={[
                      'item',
                      semester === el.value && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              </SelectBox>
            }
            
            <SelectBox label='과목'>
              <>
                {subjectList?.map(el => 
                  <button key={el.id} onClick={() => setSubject(el.id)} className={[
                    'item',
                    subject === el.id && 'active'
                    ].join(' ')} type='button'
                  >{el.subject}</button>
                )}
              </>
            </SelectBox>
            <SelectBox label='세부과목'>
              <>
                {subSubjectList?.map(el => 
                  <button key={el.id} onClick={() => setSubSubject(el.id)} className={[
                    'item',
                    subSubject === el.id && 'active'
                    ].join(' ')} type='button'
                  >{el.sub_subject}</button>
                )}
              </>
            </SelectBox>
            <SelectBox label='학년'>
              <>
                {gradeList?.map(el => 
                  <button key={el.id} onClick={() => setGrade(el.id)} className={[
                    'item',
                    grade === el.id && 'active'
                    ].join(' ')} type='button'
                  >{el.grade}</button>
                )}
              </>
            </SelectBox>
          </SelectWrap>
        </>
        }
        { tab === 1 && <SectionBox title={fullTitle} savedData={data} hasSection={hasSection} isThai /> }
      </>
    </MainSection>
  )
}

const InputWrap = styled.div`
  display: flex;
  justify-content: flex-start;

  .input-box {
    padding: 0.4rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .input-label {
    padding: 0.4rem 1rem;
    font-size: 0.94rem;
  }
  input {
    padding: 0.6rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid #ddd;

    :focus {
      border: 1px solid #bbb;
    }
  }
`
const SelectWrap = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 0.4rem;
  display: grid;
  column-gap: 0.4rem;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
`

export default Detail