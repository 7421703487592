import styled, { keyframes } from "styled-components/macro"

export const ConfirmBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  /* font-weight: 500; */
  height: 48px;
  padding: 0 12px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* color: black;
  background-color: rgba(255, 255, 255, 1); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  cursor: pointer;

  :hover {
    color: #0970c4;
    background-color: rgba(255, 255, 255, 1);
  }
`

export const StoreBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  min-width: fit-content;
  width: ${props => props.width ? props.width : '80px'};
  height: 48px;
  padding: 0 12px;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: ${(props) => props.bgColor ? 'white' : 'rgba(0, 0, 0, 0.6)'};
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: ${(props) => props.bgColor ? 'transparent' : 'rgba(201, 199, 199, 0.94)' };
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  cursor: ${(props) => props.bgColor ? 'pointer' : 'default'};

  & span {
    box-sizing: border-box;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :hover {
    color: ${(props) => props.bgColor ? '#0970c4' : ''};
    background-color: ${(props) => props.bgColor ? 'rgba(255, 255, 255, 1)' : 'rgba(201, 199, 199, 0.94)' };

    & span {
      color: #0970c4;
    }
  }
`

export const InputWrap = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
`
const slideRight = keyframes`
  from {
    padding-left: 0px;
  }
  to {
    padding-left: 500px;
  }
`;

const slideLeft = keyframes`
  from {
    padding-left: 500px;
  }
  to {
    padding-left: 0px;
  }
`;

export const Wrap = styled.div`
  width: 100%;
  padding-left: 0px;
  transition: all 2s ease;

  animation-duration: 0.30s;
  animation-timing-function: ease;
  animation-name: ${props => props.open ? slideRight : slideLeft};
  animation-fill-mode: forwards;
`
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  overflow: auto hidden;
  /* border: 1px solid blue; */

  .toaster {
    font-size: 0.8rem;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* border: 1px solid green; */
  }
`
export const FloatingBtn = styled.div`
  position: absolute;
  top: 80px;
  left: 20px;
`
export const FloatingBtnTrash = styled.div`
  position: absolute;
  top: 80px;
  left: 445px;
`
export const TestPaperFrame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow: auto visible;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
export const ItemList = styled.div`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
`
export const TestPaper = styled.div`  
  position: relative;
  width: 490px;
  height: 710px;
  border-radius: 4px;
  transition: 150ms ease;
  background-color: transparent;  
  border: ${(props) => props.border ? '5px solid rgba(166, 212, 255, 0.4)' : '5px solid rgba(0, 0, 0, 0.08)'};
  /* border: 1px solid blue; */
`
export const Hover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.08);
  /* border: 1px solid red; */
  cursor: pointer;
`
export const Image = styled.img`
  position: absolute;
  /* z-index: 2; */
`
export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  /* right: 0;
  bottom: 0; */
  width: 100%;
  height: 100%;
  z-index: 10;
  /* background: red; */
  /* border: 1px solid red; */
`