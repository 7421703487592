import * as S from './ProgressBarOrigin.style'

const ProgressBarOrigin = (props) => {
  const { overall, done, percent} = props

  return (
    <S.ProgressBox width={`${percent}%`}>
      <div className='label_pg'>
        <span className='label_pg_percent'>{percent}%</span>
      </div>
      <progress id='pg_label' value={percent} max="100"><span id='pg'></span></progress>
      <progress id='progress' value={percent} max="100"></progress>
      <div className='label_pg_text_box'>
        <span className='label_pg_current'>{done}</span>/
        <span className='label_pg_total'>{overall}부</span>
      </div>
      
    </S.ProgressBox>
  )
}

export default ProgressBarOrigin