import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components'
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader'
// import DashboardIcon from '@mui/icons-material/Dashboard'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PeopleIcon from '@mui/icons-material/People'
import BarChartIcon from '@mui/icons-material/BarChart'
import LayersIcon from '@mui/icons-material/Layers'
import AssignmentIcon from '@mui/icons-material/Assignment'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import arrowSubMenu from '../../assets/icons/sidebar_sub_menu.png'
import { useQuery } from 'react-query'
import { getSideBarAlarmAPI } from '../../api/getAPI'

import Button from './sidebarButton'
import DashboardIcon from './sidebar/Dashboard'
import ProjectIcon from './sidebar/Project'
import LabelerIcon from './sidebar/Labeler'
import QuestionIcon from './sidebar/Question'


interface SidebarProps {
  _authority: string
}

const SidebarTH = ({_authority=''}: SidebarProps) => {
  const { pathname } = useLocation()
  const [activeMenu, setActiveMenu] = useState<number>(0)
  const [activeSubMenu, setActiveSubMenu] = useState<number>(0)

  const SidebarItemsOfLabelerTh = [
    {
      value: 1, 
      name: '단원 부여', 
      icon: <ProjectIcon color={activeMenu === 1 ? '#000' : '#bcbcbc'} />, 
      path: 'mid-unit',
      query: {},
      subMenu: [
        {value: 11, name: '큐비 문제', path: 'mid-unit/qb', query: {page: 1, filter: 0, tab: '수학'}}, 
        {value: 12, name: '북스 문제', path: 'mid-unit/book', query: {page: 1, filter: 0, tab: '수학'}}
      ]
    },
    {
      value: 2, 
      name: '번역 및 검수', 
      icon: <LabelerIcon color={activeMenu === 2 ? '#000' : '#bcbcbc'} />, 
      path: 'translation',
      query: {},
      subMenu: [
        {value: 21, name: '큐비 문제', path: 'translation/qb', query: {page: 1, filter: 0, tab: '수학'}},
        {value: 22, name: '북스 문제', path: 'translation/book', query: {page: 1, filter: 0, tab: '수학'}}
      ]
    },
    {
      value: 3, 
      name: '문제 관리', 
      icon: <DashboardIcon color={activeMenu === 3 ? '#000' : '#bcbcbc'} />, 
      path: 'management',
      query: {}
    },
  ]
  
  const SidebarItemsOfSkimmerAndTranslationTh = [
    {
      value: 1, 
      name: '번역', 
      icon: <LabelerIcon color={activeMenu === 1 ? '#000' : '#bcbcbc'} />, 
      path: 'translation',
      query: {},
      subMenu: [
        {value: 11, name: '큐비 문제', path: 'translation/qb', query: {page: 1, filter: 0, tab: '수학'}},
        {value: 12, name: '북스 문제', path: 'translation/book', query: {page: 1, filter: 0, tab: '수학'}}
      ]
    },
  ]
  
  const SidebarItemsOfSubSectionTh = [
    {
      value: 1, 
      name: '단원 부여', 
      icon: <ProjectIcon color={activeMenu === 1 ? '#000' : '#bcbcbc'} />, 
      path: 'mid-unit',
      query: {},
      subMenu: [
        {value: 11, name: '큐비 문제', path: 'mid-unit/qb', query: {page: 1, filter: 0, tab: '수학'}}, 
        {value: 12, name: '북스 문제', path: 'mid-unit/book', query: {page: 1, filter: 0, tab: '수학'}}
      ]
    },
  ]
  
  const SidebarItemsOfExaminationTh = [
    {
      value: 1, 
      name: '검수', 
      icon: <ProjectIcon color={activeMenu === 1 ? '#000' : '#bcbcbc'} />, 
      path: 'translation',
      query: {},
      subMenu: [
        {value: 11, name: '큐비 문제', path: 'translation/qb', query: {page: 1, filter: 0, tab: '수학'}}, 
        {value: 12, name: '북스 문제', path: 'translation/book', query: {page: 1, filter: 0, tab: '수학'}}
      ]
    },
  ]

  let menuItems: any
  if (_authority === '0') menuItems = SidebarItemsOfLabelerTh // 어드민
  if (_authority === '1') menuItems = SidebarItemsOfSubSectionTh // 중단원
  if (_authority === '2') menuItems = SidebarItemsOfSkimmerAndTranslationTh // 스키머
  if (_authority === '3') menuItems = SidebarItemsOfSkimmerAndTranslationTh // 번역자
  if (_authority === '4') menuItems = SidebarItemsOfExaminationTh // 검수자

  const getMenu = () => {
    const a = pathname.split('?')
    const b = a[0].split('/')
    const c = b[2] // th 가 맨 앞에 위치하기 때문. 없다면 index 1로
    
    const item = menuItems.find(el => c === el.path)
    const mainValue = item?.value

    if (!item?.subMenu) {
      setActiveMenu(mainValue)
      setActiveSubMenu(0)
    } else {
      const subValue = item.subMenu.find(el => a[0].includes(el.path)).value
      setActiveMenu(mainValue)
      setActiveSubMenu(subValue)
    }
  }
  
  useEffect(()=>{
    getMenu()
  }, [pathname])


  return (
    <Wrap>
      <Container>
        {menuItems.map(el => 
          <Button 
            key={el.value} 
            item={el}
            activeMenu={activeMenu}
            activeSubMenu={activeSubMenu}
            setActiveMenu={setActiveMenu} 
            setActiveSubMenu={setActiveSubMenu} 
          >{el.name}</Button>)}
      </Container>
    </Wrap>
  )
}

const Wrap = styled.div`
  height: 100%;
  width: 14.95rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 0.8rem 1rem;
  display: flex;
  flex-direction: column;
`
const slideDown = keyframes`
  from {
    display: none;
  }
  to {
    display: block;
  }
`;
const slideUp = keyframes`
  from {
    display: block;
  }
  to {
    display: none;
  }
`;

const SubIcon = styled.img`
  width: 11px;
  height: 6px;
`

const LinkSubMenu = styled.div`
  width: 100%;
  height: fit-content;
  padding-left: 80px;

  animation-duration: 0.30s;
  animation-timing-function: ease-out;
  animation-name: ${props => props.open ? slideDown : slideUp};
  animation-fill-mode: forwards;
`

const LinkContainer = styled.div`
  cursor: pointer;
  padding-left: 6px;
`

const ItemButton = styled(ListItemButton)`
  /* background-color: #A1C7E0; */
`

const ItemText = styled(ListItemText)`
  color: ${(props) => (props.selected ? '#1976d2' : 'black' )};
  font-weight: ${(props) => (props.selected ? '24px' : '1rem' )};
`
const DisabledItemText = styled(ListItemText)`
  color: rgba(0, 0, 0, 0.4);
`

const SubItemText = styled(ListItemText)`
  color: ${(props) => (props.selected ? '#1976d2' : 'black' )};
  font-weight: ${(props) => (props.selected ? '20px' : '1rem' )};
  padding: 4px;
`

export default SidebarTH