import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Toolbar from '../../mainSection/Toolbar'

const Container = styled.div`
  display: ${props => props.isVisible ? 'grid' : 'none' };
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 180ms ease;
`
const PaperContainer = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  height: calc(100vh - 130px);
  overflow: hidden;

  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
`
const PreviewBox = styled.div`
  display: flex;
  flex-direction: column;

  &img {
    display: block;
    width: 100%;
    height: auto;
  }
`

interface PreviewProps {
  isVisible?: boolean
  close?: ()=>void
}

const Preview = ({
  isVisible=false,
  close=()=>{}
}: PreviewProps) => {
  const th_editor_writing = useSelector((state) => state.th_editor_writing)
  const {
    fabric_canvas_list,
    selected_index,
    click_preview,
  } = th_editor_writing

  const is_preview = fabric_canvas_list[selected_index]?._previewed
  const img_url = fabric_canvas_list[selected_index]?._URL
  // console.log('======== show preview ========', img_url)
  
  return (
    <Container isVisible={isVisible}>
      <PaperContainer>
        <div className='preview_wrap'>
          <Toolbar title={'Preview'} isClose close={close} />
          <PreviewBox>
            {is_preview &&
              <img src={img_url} alt={img_url} />
            }
          </PreviewBox>
        </div>
      </PaperContainer>
    </Container>
  )
}

export default Preview