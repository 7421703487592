import styled from 'styled-components'

const Wrap = styled.div`
  width: 100%;
  /* border: 1px solid green; */
`

const Display = () => {
  return (
    <Wrap></Wrap>
  )
}

export default Display