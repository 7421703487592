import { useState, memo } from 'react';
import styled from 'styled-components'
// import Skeleton from '../../../../editor/skeleton/SkeletonEditor'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import ClassicEditor from 'ckeditor5/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import 'katex/dist/katex.css'

interface Q_previewProps {
  Q_data?: any
}

const QuestionPreview = ({Q_data}: Q_previewProps) => {
  console.log('Q_data: ', Q_data)
  const share_data = Q_data.share
  const ko_data = Q_data.ko
  const d = ko_data.answer
  console.log(d)

  const [ img, setImg ] = useState(null)
  const [ modal, setModal ] = useState(false)
  const openModal = (img) => {
    if (!modal) {
      setImg(img)
      setModal(true)
    }
  }
  const closeModal = () => {
    if (modal) {
      setImg(null)
      setModal(false)
    }
  }

  const cloudfrontBaseUrl = process.env.REACT_APP_IMAGE_URL
  const questionImgUrl = `${cloudfrontBaseUrl}${ko_data.image.question_image && ko_data.image.question_image}`
  const answerImgUrl = `${cloudfrontBaseUrl}${ko_data.image.answer_image && ko_data.image.answer_image}`
  const relationImgUrl = ko_data.image.relation_image 
    ? `${cloudfrontBaseUrl}${ko_data.image.relation_image}` 
    : null
  const imageUrlList = [questionImgUrl, answerImgUrl]

  return (
    <TableContainer component={Paper} sx={{padding: '1rem 2px 2px', backgroundColor: 'transparent', boxShadow: 'none', overflow: 'hidden', height: '100%'}} >

      {modal && 
        <Modal
          open={modal}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
          sx={{ outline: 0}}
        >
          <ModalImgWrap>
            <img className='modal_img' alt={img} src={img} />
          </ModalImgWrap>
        </Modal>
      }
      
      <Grid container spacing={2} sx={{height: '100%'}} >

      {/* {is_loading ? (
        <Skeleton type={'midUnit'} />
      ) : ( */}
        <>
          {/* 연관 지문 이미지 */}
          <Grid item xs={12} sm={4} md={4} lg={4} >
            <Item>
              {relationImgUrl
                ? (
                  <Image 
                    key={relationImgUrl}
                    src={relationImgUrl} 
                    width="100%" 
                    style={{ cursor: "pointer" }} 
                    onClick={() => openModal(img)}
                  />
                )
                : (
                  <NoImg>
                    연관된 지문이 없습니다
                  </NoImg>
                )
              }
              
            </Item>
          </Grid>

          {/* 프리뷰 */}
          <Grid item xs={12} sm={4} md={4} lg={4} >
            <Item02>
              <CKEditor
                id='preview_editor'
                editor={ ClassicEditor }
                data={ ko_data.html.question_html }
                
                onReady={( editor ) => {
                  editor.enableReadOnlyMode( 'preview_editor' );
                  const toolbarElement = editor.ui.view.toolbar.element;
                  toolbarElement.style.display = 'none';
                }}
              />

              {share_data.is_select === 1 &&  // 객관식
                <MultipleWrap>
                  <CKEditor
                    id={`number_editor`}
                    editor={ ClassicEditor }
                    data={ ko_data.html.selection_html }
                    
                    onReady={( editor ) => {
                      editor.enableReadOnlyMode( `number_editor` );
                      const toolbarElement = editor.ui.view.toolbar.element;
                      toolbarElement.style.display = 'none';
                    }}
                  />
                </MultipleWrap>
              }

              {share_data.is_select === 1 &&  // 객관식
                <AnswerBox>
                  정답: <span className='answer_span'>{ko_data.answer}</span>
                </AnswerBox>
              }

              {share_data.is_select === 0 &&  // 주관식
                <EssayWrap>
                  <span className='answer_essay'>{`정답:`}</span>
                  {ko_data.answer.map((el, idx) => {
                    return (
                      <EssayBox key={String(idx)}>
                        <Number>{idx + 1}.</Number>
                        <CKEditor
                          id={`number_editor${idx + 1}`}
                          editor={ ClassicEditor }
                          data={ `<p>${el}</p>` }
                          
                          onReady={( editor ) => {
                            editor.enableReadOnlyMode( `number_editor${idx + 1}` );
                            const toolbarElement = editor.ui.view.toolbar.element;
                            toolbarElement.style.display = 'none';
                          }}
                        />
                      </EssayBox>
                    )
                  })}
                </EssayWrap>
              }
              {ko_data.html.explanation_html &&
                <CKEditor
                  id='preview_e_editor'
                  editor={ ClassicEditor }
                  data={ ko_data.html.explanation_html }
                  
                  onReady={( editor ) => {
                    editor.enableReadOnlyMode( 'preview_e_editor' );
                    const toolbarElement = editor.ui.view.toolbar.element;
                    toolbarElement.style.display = 'none';
                  }}
                />
              }
              
            </Item02>
          </Grid>

          {/* 문제 + 답안지 이미지 */}
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Item>
              {imageUrlList.map((img, index) => {
                return (
                  <Image 
                    key={String(index)}
                    src={img && img} 
                    width="100%" 
                    style={{ cursor: "pointer" }} 
                    onClick={() => openModal(img)}
                  />
                )
              })}
            </Item>
          </Grid>
        </>
      {/* )} */}
      </Grid>
    </TableContainer>
  )
}
const ModalImgWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50vw;
  max-height: 90%;
  overflow-y: auto;

  .modal_img {
    width: 100%;
  }
`
const AnswerBox = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1rem;

  .answer_span {
    /* color: #282c34; */
    font-size: 1rem;
    font-weight: 600;
    padding-top: 0.5px;
  }
`
const EssayWrap = styled.div`
  width: 100%;
  padding: 0.8rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .answer_essay {
    font-size: 0.8rem;
  }
`
const MultipleWrap = styled.div`
  width: 100%;
  padding: 0 0 0.8rem;
  margin-bottom: 1rem;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  

  .pr-multiple_box[data-num=''] {
    border: none;
    .pr-multiple_box_content_wrap {
      border: none;
    }
    .pr-multiple_box_content {
      border: none;
    }
  }
  .pr-multiple_box[data-num='']:before {
    border: none;
  }

  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    border: none;
    padding: 0;
  }
  :root {
    --ck-widget-outline-thickness: 0px;
  }
  .image-inline {
    outline: 1px !important;
  }
  .ck-widget.ck-clipboard-drop-target-range {
    outline: 1px !important;
  }
`
const EssayBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    border: none;
    p:first-of-type {
      display: none;
    }
    p:last-of-type {
      display: none;
    }
  }
`
const Number = styled.div`
  width: 1rem;
  /* border: 1px solid blue; */
`
const Wrap = styled(TableContainer)`
  height: 100%;
  padding: 1rem 2px 2px;
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
`
const NoImg = styled.div`
  flex-grow: 1;  // 부모 div가 display: flex; flex-direction: column; 인 경우
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
`
const Item = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100vh - 380px);
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  /* @media screen and (max-width: 778px) {
    height: calc(100vh - 665px);
  } */
`
const Item02 = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  height: calc(100vh - 380px);
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  /* @media screen and (max-width: 778px) {
    height: calc(100vh - 665px);
  } */
`
const Image = styled.img`
  width: 100%;
  cursor: pointer;
`

export default memo(QuestionPreview)