import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  max-height: 6.2rem;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  .item {
    box-sizing: border-box;
    padding: 0.1rem 0.3rem;
    width: 100%;
    display: flex;
    justify-content: ${props => props.align ?props.align : 'center'};
    align-items: center;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    cursor: pointer;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    :focus {
      background-color: rgba(228, 244, 247, 0.7);
    }
  }
  .active {
    background-color: rgba(228, 244, 247, 0.7);
  }
`

interface ItemBoxProps {
  children: JSX.Element
  align?: string
}

const ItemBox = ({children, align}: ItemBoxProps) => {
  // useEffect(()=>{
  //   const select_box = document.getElementById('select_box')
  //   const active_item = document.querySelector('.active')
  //   const target_position = active_item.getBoundingClientRect().top
  //   console.log(select_box.scrollHeight, active_item)
  // }, [children])

  return (
    <Container id='select_box' align={align}>
      {children}
    </Container>
  )
}

export default ItemBox