import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as S from './LabelerInfo.style'
import Input from '../../../elements/Input'
import Select from '../../../elements/SelectBox'
import { useQuery, useMutation } from 'react-query'
import { getLabelerInfoAPI } from '../../../api/getAPI' 

const LabelerInfo = forwardRef(function LabelerInfo(props, ref) {
  const { data } = props

  const [ group, setGroup ] = useState(data.group || '')
  const [ role, setRole ] = useState(data.admin === 1 ? '관리자' : (data.admin === 0 ? '라벨러' : ''))
  const [ approval, setApproval ] = useState(data.check === 1 ? '승인' : (data.check === 0 ? '거부' : ''))
  const [ nation, setNation ] = useState(parseNation(data.check))
  const descRef = useRef(null)
  const storeRef = useRef()

  const handleGetResult = () => {
    return storeRef.current.handleStore()
  }

  const handleGroup = (value) => {
    setGroup(value)
  }
  const handleRole = (value) => {
    if (value === '관리자') {
      setNation('모두 가능')
      handleGetResult()
    }
    setRole(value)
  }
  const handleNation = (value) => {
    setNation(value)
  }

  function parseNation(v) {
    if (typeof v === 'string') {
      switch(v) {
        case '거부':
          return 0
        case '모두 가능':
          return 1
        case '한국':
          return 2
        case '태국':
          return 3
        default:
          break
      }
    } else if (typeof v === 'number') {
      switch(v) {
        case 0:
          return '거부'
        case 1:
          return '모두 가능'
        case 2:
          return '한국'
        case 3:
          return '태국'
        default:
          break
      }
    }
  }

  const handleSubmit = () => {
    const desc = descRef.current.value

    if (!group || !role || !nation) return

    const data = {
      group: group,
      admin: role === '라벨러' ? 0 : 1,
      check: parseNation(nation),
      description: desc,
    }    
    // console.log(data)

    return data
  }
  
  useImperativeHandle(ref, () => ({ handleSubmit }))

  return (
    <S.CardPadding>
      <S.Wrap>
        <S.Grid onSubmit={handleSubmit}>
          <S.Category color={'rgba(0, 0, 0, 0.3)'}>이름</S.Category>
          <Input disabled value={data.user_name} type={'text'} />
          <S.Category  color={'rgba(0, 0, 0, 0.3)'}>전화번호</S.Category>
          <Input disabled value={data.phone} type={'text'} />
          <S.Category  color={'rgba(0, 0, 0, 0.3)'}>아이디</S.Category>
          <Input disabled value={data.user_id} type={'text'} />

          <S.Category>소속</S.Category>
          <Select type={'group'} _value={group} setState={handleGroup} />
          <S.Category>권한</S.Category>
          <Select type={'role'} _value={role} setState={handleRole} />
          {/* <S.Category>승인 여부</S.Category>
          <Select type={'approval'} _value={approval} setState={setApproval} /> */}
          <S.Category>승인 여부(국가)</S.Category>
          <Select ref={storeRef} type={'nation'} _value={nation} setState={handleNation} disabled={role === '관리자'} />

          <S.Category>라벨러 속성</S.Category>
          <textarea defaultValue={data.description || ''} ref={descRef} name={'desc'} type={'text'} placeholder={'입력하세요'} />
          
        </S.Grid>
      </S.Wrap>
    </S.CardPadding>
  )
})

export default React.memo(LabelerInfo)