import styled from 'styled-components'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Card, Grid, Paper } from '@mui/material'
import BackToList from '../../../elements/button/BackToList'
import LabelingList from '../../../components/myWorkSpace/midUnit/platform/PlatformList'
import LabelingToolbar from '../../../components/myWorkSpace/midUnit/platform/PlatformToolbar'
import { useQuery } from 'react-query'
import { getQuestionList02API } from '../../../api/getAPI'

const PlatformPage = (props) => {
  const navigate = useNavigate()
  const {'projectId': params_1, 'paperId': params_2} = useParams()
  const projectId = Number(params_1)
  const pdf_id = Number(params_2)
  
  const [ title, setTitle ] = useState('')
  const [ filter, setFilter ] = useState('0')
  const [ questionList, setQuestionList ] = useState([])

  const { data } = useQuery(['questionList', filter],
    () => getQuestionList02API(pdf_id), 
    {
      onSuccess: data => {
        console.log(data)
        handleSetTitle(data.data.pdf_name)
        if (data.data.message === 'boxing not completed') {
          console.log('1차 에디터 작업을 완료해 주세요.')
        } else {
          console.log(filter)
          let data_list
          if (filter === '0') {
            data_list = data.data.data
          } else if (filter === '1') {
            data_list = data.data.data.filter(el => el.check_yn === '완료')
          } else if (filter === '2') {
            data_list = data.data.data.filter(el => el.check_yn === '미완료')
          } else if (filter === '3') {
            data_list = data.data.data.filter(el => el.error === 1)
          } else if (filter === '4') {
            data_list = data.data.data.filter(el => el.error === 0)
          }
          handleSetQuesList(data_list)
        }
      },
      onError: error => {
        console.log(error)
      },
      refetchOnWindowFocus :false,
    }
  )
  console.log(questionList)

  const handleSetQuesList = useCallback((arg) => {
    setQuestionList(arg)
  }, [questionList])

  const handleSetFilter = useCallback((e) => {
    setFilter(e.target.value)
  }, [filter])

  const handleSetTitle = useCallback((e) => {
    setTitle(e)
  }, [title])

  const GoBackToList = () => {
    navigate(`/workspace/project/mid-unit/test-paper/${projectId}`)
  }


  return (
    <Grid container direction={"column"} >
        
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>문제 리스트</div>
              <BtnBox02>
                <BackToList onclick={GoBackToList}>시험지 리스트</BackToList>
              </BtnBox02>
              
            </BtnBox>
          </Title>
          <Box>
            <CardPadding>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box minWidth="12%">
                  <b>{title}</b>
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" minWidth="100px" width="12%" height="40px">
                  <Select
                    value={filter}
                    onChange={handleSetFilter}
                  >
                    <option value='0'>전체</option>
                    <option value='1'>작업 완료</option>
                    <option value='2'>작업 미완료</option>
                    <option value='3'>오류 있음</option>
                    <option value='4'>오류 없음</option>
                  </Select>
                </Box>
              </Box>
            </CardPadding>
          </Box>
          <LabelingList
            questionList={questionList}
          />
        </Item>
      </Grid>
    </Grid>
  )
}
const Select = styled.select`
  width: 100%;
  height: 100%;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #cccccc;
  border-radius: 4px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  padding: 15px;
`;
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default PlatformPage