import { useState, useEffect } from 'react'
import LayoutModal from '../elements/modal/LayoutModal'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { postLoginAPI } from '../api/postAPI' 
import { GetAuthority, GetPathname } from '../utils/getAuthority' 

import { actionCreators as userActions } from "../redux/modules/user";

const theme = createTheme()

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ device, setDevice ] = useState('WEB')
  const [ browser, setBrowser ] = useState('')
  const [ isModal, setIsModal ] = useState(false)
  const [ isModalType, setIsModalType ] = useState('')
  const [ nation, setNation ] = useState(2)

  const handleNation = (e) => {
    setNation(e.target.value)
  }

  const handleBtn = (type) => {
    setIsModal(true)
    setIsModalType(type)
  }

  const closeModal = () => {
    setIsModal(false)
  }

  const { mutate } = useMutation(postLoginAPI, {
    onSuccess: data => {
      // console.log(data)
      if (data.token) {
        const group = data.group
        const token = `Bearer ${data.token}`
        const _nation = data.nation
        const _authority = data.authority
        localStorage.setItem("_nation", _nation)
        localStorage.setItem("_authority", _authority)
        localStorage.setItem("group", group)
        localStorage.setItem("access_token", token)
        console.log(data.nation, data.authority)

        dispatch(userActions.isLogin(true))
        // GetAuthority(Number(_nation), Number(_authority))
      }
    },
    onError: error => {
      // console.log(error)
      const msg = error.response.data.error
      if (msg === '유저 미존재' || msg === '비밀번호 오류') {
        alert('아이디 또는 비밀번호가 일치하지 않습니다.')
      } else if (msg === '승인 오류') {
        alert('아직 승인되지 않은 계정입니다. 관리자에게 문의 바랍니다.')
      } else if (msg === '유저 나라 불일치') {
        alert('선택하신 국가에 대한 접근 권한이 없습니다.')
      }
    }
  })

  const is_login = useSelector((state) => state.user.is_login)

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const id = data.get('id')
    const pw = data.get('password')

    if (!id || !pw || !nation) return alert('아이디, 비밀번호, 국가를 모두 입력해 주세요.')

    const loginData = {
      user_id: id.trim(),
      password: pw.trim(),
      nation: nation ? Number(nation) : null
    }
    mutate(loginData)
  }

  useEffect(() => {
    localStorage.clear()
    dispatch(userActions.isLogin(false))
  }, [])

  useEffect(() => {
    if (!is_login) return
    
    const _nation = localStorage.getItem("_nation")
    const _authority = localStorage.getItem("_authority")
    console.log(_nation, _authority)
    if (_nation === null ||  _authority === null) return navigate('/')

    navigate(GetPathname(Number(_nation), Number(_authority)))

  }, [is_login])

  const checkBrowser = () => {
    let browser;
    const userAgent = navigator.userAgent.toLowerCase() //userAgent 값 얻기
    if (userAgent.indexOf('chrome') !== -1) {
      browser = 'Chrome'
    } else if (userAgent.indexOf('safari') !== -1) {
      browser = 'Safari'
    } else if (userAgent.indexOf('firefox') !== -1) {
      browser = 'Firefox'
    } else if (userAgent.indexOf('whale') !== -1) {
      browser = 'Whale'
    } else if (userAgent.indexOf('edge') !== -1) {
      browser = 'Edge'
    } else if ((navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') !== -1) || (userAgent.indexOf('msie') !== -1)) {
      browser = 'IE'
    } else {
      browser = 'No idea'
    }
    setBrowser(browser)
  }

  // const checkDevice = () => {
  //   const current_device = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
  //   console.log(current_device)
  //   setDevice(current_device)
  // }
  const checkDevice = () => {
    // const varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기
    // const userAgent = window.navigator.userAgent

    // if (varUA.indexOf("android") > -1) {
    //   //안드로이드
    //   return "APP"
    // } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
    //   //IOS
    //   return "APP"
    // } else {
    //   //아이폰, 안드로이드 외
    //   return "WEB"
    // }
    return 'WEB'
  }

  useEffect(() => {
    checkDevice()
    checkBrowser()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            placeContent: 'center',
            height: '100vh',
          }}
        >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20%',
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="id"
              label="Id"
              name="id"
              autoComplete="id"
              autoFocus
            />
            <TextField
              sx={{mt: 0}}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Nation</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={nation}
                label="Nation"
                onChange={handleNation}
              >
                <MenuItem value={2}>한국</MenuItem>
                <MenuItem value={3}>태국</MenuItem>
              </Select>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: '54px' }}
            >
              Login
            </Button>
          </Box>
          <Box sx={{
            width: '100%',
            fontSize: '14px', 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Btn onClick={() => handleBtn('signup')} >회원가입</Btn>
            <Btn onClick={() => handleBtn('findIdPw')} >아이디/비밀번호 찾기</Btn>
          </Box>
        </Box>
        </Box>
      </Container>
      {isModal &&
        <LayoutModal open={isModal} close={closeModal} type={isModalType} />
      }
    </ThemeProvider>
  )
}
const Btn = styled.div`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: underline;

  :hover {
    color: rgba(0, 0, 0, 0.8);
  }
`

export default Login