import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import { actionCreators as imageEditorActions } from "../../../../redux/modules/imageEditor"

const ButtonMR = styled(Button)`
  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const Select = styled.select`
  width: 100%;
  height: 100%;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #cccccc;
  border-radius: 4px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  padding: 15px;
`;

const PlatformToolbar = ({ title, handleSetFilter }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState('')

  const handleFilter = (e) => {
    setFilter(e.target.value)
    handleSetFilter(e.target.value)
  }

  return (
    <Box>
      <CardPadding>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box minWidth="12%">
            <b>{title}</b>
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" minWidth="100px" width="12%" height="40px">
            <Select
              value={filter}
              onChange={handleFilter}
            >
              <option defaultValue="select">선택</option>
              <option value="name">작업 완료</option>
              <option value="name">작업 미완료</option>
              <option value="name">오류 있음</option>
              <option value="name">오류 없음</option>
            </Select>
          </Box>
        </Box>
      </CardPadding>
    </Box>
  );
};

export default PlatformToolbar;
