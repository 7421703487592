import { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { useQuery } from 'react-query'
import { getNoticeListAPI } from '../../../api/getAPI'

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .title {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;

`;

const NoticeList = (props) => {
  const navigate = useNavigate()
  const [ page, setPage ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ checkList, setCheckList ] = useState([])
  const [ normalList, setNormalList ] = useState([])
  const [ totalCount, setTotalCount ] = useState(100)

  const { data } = useQuery(['noticeList', page], () => getNoticeListAPI(page + 1), {
    retry: false,
    onSuccess: data => {
      console.log(data)
      const noticeList = data.data.data
      const total_count = data.data.total_count
      const checkArr = noticeList.filter(el => el.check === 1)
      const normalArr = noticeList.filter(el => el.check === 0)
      setCheckList(checkArr)
      setNormalList(normalArr)
      setTotalCount(total_count)
    },
    onError: error => {
      // alert(error)
      if (error.response?.status) {
        switch (error.response?.status) {
          case 401: case 403:
            localStorage.clear()
          case 401:
            alert('로그인 후 이용해 주세요.')
            window.location.href = "/login";
            break;
          case 403:
            alert('세션이 만료되었습니다. 다시 로그인을 시도해 주세요.')
            window.location.href = "/login";
            break;
          default:
            return Promise.reject(error)
        }
      }
    }
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModification = (id) => {
    navigate(`/notice/modification/${id}`)
  }

  return (
    <Wrap>
      <TableContainer component={Paper} sx={{height: '100%'}} >    
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align="center">No.</Cell>
              <Cell align="center">내용</Cell>
              <Cell align="center">작성자</Cell>
              <Cell align="center">날짜</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkList.length > 0 && checkList.map((el, index) => {
              return (
                <Row
                  key={String(index)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => handleModification(el.id)}
                >
                  <Cell align="center">중요</Cell>
                  <Cell align="left" className='title'>{el.title}</Cell>
                  <Cell align="center">{el.writer}</Cell>
                  <Cell align="center">{el.created_time}</Cell>
                </Row>
              )
            })}
            {normalList.length > 0 && normalList.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              return (
                <Row
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => handleModification(row.id)}
                >
                  <Cell align="center">{no}</Cell>
                  <Cell align="left" className='title'>{row.title}</Cell>
                  <Cell align="center">{row.writer}</Cell>
                  <Cell align="center">{row.created_time}</Cell>
                </Row>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{overflow: 'hidden'}}
        rowsPerPageOptions={[10]}
        colSpan={9}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Wrap>
  );
}

export default NoticeList