import Command from '@ckeditor/ckeditor5-core/src/command';
import { first, toArray } from 'ckeditor5/src/utils';

export default class InsertTextBoxCommand extends Command {
	constructor(editor) {
		super(editor);
		this.set('itemArray', []);
	}
	execute() {
		const selection = this.editor.model.document.selection;

		this.editor.model.change((writer) => {
			if (this.value) {
				for (let element of this.itemArray) {
					writer.unwrap(element);
				}
			} else {
				if (
					selection
						.getFirstPosition()
						.isEqual(selection.getLastPosition())
				) {
					return;
				}
				const gen = selection.getRanges();
				const range = gen.next().value;
				writer.wrap(range, 'textbox');
			}
		});
	}

	refresh() {
		const selection = this.editor.model.document.selection;

		const { isEnabled, value } = this._isEnabled(selection);
		this.isEnabled = isEnabled;
		this.value = value;
		// this.value = this._getValue(selection);
	}

	_isEnabled(selection) {
		const blocks = selection.getSelectedBlocks();

		let flag = true;
		let leng = 0;
		while (flag) {
			flag = !blocks.next().done;
			if (flag) leng += 1;
		}

		if (leng >= 2) {
			return { isEnabled: false, value: false };
		} else {
			if (selection.getFirstPosition().parent.is('element', 'textbox')) {
				this.itemArray = [selection.getFirstPosition().parent];
				return { isEnabled: true, value: true };
			}
			const _value = this._getValue(selection);
			return { isEnabled: true, value: _value };
		}
	}

	_getValue(selection) {
		const selectionValue = selection.getRanges().next().value;
		const items = selectionValue.getItems();

		let flag = true;
		this.itemArray = [];
		while (flag) {
			const { value, done } = items.next();
			flag = !done;
			if (value && value.is('element') && value.name === 'textbox')
				this.itemArray.push(value);
		}

		return this.itemArray.length ? true : false;
	}
}

function isTextBox(element) {
	return element && element.name === 'textbox' ? null : true;
}

function createBlock(writer) {
	const textbox = writer.createElement('textbox');
	// writer.appendElement('paragraph', textbox);

	return textbox;
}
