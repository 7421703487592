import { useEffect, useState, useCallback, memo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled, {keyframes} from 'styled-components'
import {
  Box,
  Grid,
  Paper,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useQuery, useMutation } from 'react-query'
import { getAPI, postAPI } from '../../api' 
import Button from '../../elements/button/Button'
import List from './BooksManageList'

const BooksManagePage = memo((props) => {
  const navigate = useNavigate()
  const _authority = localStorage.getItem("_authority")
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = searchParams.get('page')
  const _work_type = searchParams.get('work_type')

  // 메인 필터
  const _grade = searchParams.get('grade')
  const _sub_subject = searchParams.get('sub_subject')
  const _book = searchParams.get('book')
  const _section = searchParams.get('section')
  const _sub_section = searchParams.get('sub_section')

  // 서브 필터
  const _error = searchParams.get('error')
  const _work_status = searchParams.get('work_status')

  const [ page, setPage ] = useState(Number(_page))
  const [ totalCount, setTotalCount ] = useState(0)
  
  // filter - 기출/북스, 해설/지문
  const [ service ] = useState('books') // qb / books
  const [ workType, setWorkType ] = useState(_authority === '5' ? '2' : '1') // 1: 문제/해설, 2: 지문

  // 메인 필터 선택 시 하위 필터들이 초기화되는 기능을 제한하기 위한 flag 변수 - true: 제한, false: 제한 X
  const [ flag, setFlag ] = useState(!!_sub_section)

  // query string 변경 시, _sub_section의 변경 유무 판단
  useEffect(()=>{
    if (!_sub_section) return // 쿼리에 _sub_section(중단원) 여부 확인
    setFlag(true)

    // 메인 필터
    setGrade(_grade)
    setSubSubject(_sub_subject)
    setBook(_book)
    setSection(_section)
    setSubSection(_sub_section)
    setOnSearch(true)
  }, [_sub_section])

  useEffect(()=>{
    setOnSearch(true)
    setPage(Number(_page))
  }, [_page])

  useEffect(()=>{
    setWorkType(_work_type)
    setQuestions([])
    setTotalCount(0)
  }, [_work_type])

  useEffect(()=>{
    setOnSearch(true)
    if (_work_status) setWorkStatus(_work_status)
      else setWorkStatus('')
  }, [_work_status])

  useEffect(()=>{
    setOnSearch(true)
    if (_error) setError(_error)
      else setError('')
  }, [_error])


  // filter - 학년, 세부과목, 출판사, 대단원
  const [ grade, setGrade ] = useState('') // 학년
  const [ subSubject, setSubSubject ] = useState('') // 세부과목
  const [ book, setBook ] = useState('') // 출판사
  const [ section, setSection ] = useState('') // 대단원
  const [ subSection, setSubSection ] = useState('') // 중단원

  // filter - 학기, 작성 여부, 오류 여부
  const [ semester, setSemester ] = useState('') // 학기
  const [ workStatus, setWorkStatus ] = useState('') // 작성 여부
  const [ error, setError ] = useState('') // 오류 여부

  // filter - grade(학년)
  const { data: grade_list } = useQuery([`books/grades`, workType], () => getAPI(`books/grades`, {work_type: workType}), {
    // enabled: !!(workType),
    select: data => data.data,
    onSuccess: data => {
      console.log(data)
    }
  })

  // filter - subSubject(세부과목)
  const { data: sub_subject_list } = useQuery([`books/${grade}/sub-subjects`, grade], () => getAPI(`books/${grade}/sub-subjects`, {work_type: workType}), {
    enabled: !!(workType && grade),
    select: data => data.data,
    onSuccess: data => {
      console.log(data)
    }
  })

  // filter - book(문제집)
  const { data: book_list } = useQuery([`books/${subSubject}/books`, subSubject], () => getAPI(`books/${subSubject}/books`, {work_type: workType}), {
    enabled: !!(workType && subSubject),
    select: data => data.data,
    onSuccess: data => {
      console.log(data)
    }
  })

  // filter - section(대단원)
  const { data: section_list } = useQuery([`books/${book}/sections`, book], () => getAPI(`books/${book}/sections`, {book_id: book ? book : '0', work_type: workType}), {
    enabled: !!(workType && book),
    select: data => data.data,
    onSuccess: data => {
      console.log(data)
    }
  })

  // filter - section(중단원)
  const { data: sub_section_list } = useQuery([`books/${section}/sub-sections`, section], () => getAPI(`books/${section}/sub-sections`, {work_type: workType}), {
    enabled: !!(workType && section),
    select: data => data.data,
    onSuccess: data => {
      console.log(data)
    }
  })

  // 필터 리셋 - 학년 선택 시
  useEffect(()=>{
    if (!grade || flag) return
    setSubSubject('')
    setBook('')
    setSection('')
    setSubSection('')
  }, [grade])

  // 필터 리셋 - 세부과목 선택 시
  useEffect(()=>{
    if (!subSubject || flag) return
    setBook('')
    setSection('')
    setSubSection('')
  }, [subSubject])

  // 필터 리셋 - 출판사 선택 시
  useEffect(()=>{
    if (!book || flag) return
    setSection('')
    setSubSection('')
  }, [book])

  // 필터 리셋 - 대단원 선택 시
  useEffect(()=>{
    if (!section || flag) return
    setSubSection('')
  }, [section])


  let queries = {
    page: page, // 페이지
    work_type: workType, // 탭 (문제/해설, 지문)
    grade: grade, // 필터 (메인)
    sub_subject: subSubject, // 필터 (메인)
    book: book, // 필터 (메인)
    section: section, // 필터 (메인)
    sub_section: subSection, // 필터 (메인)
    work_status: workStatus, // 필터 (서브)
    error: error, // 필터 (서브)
  }

  // Tab Click - update query string, reset all filter
  const handleWorkType = (v) => {
    queries.page = 1
    queries.work_type = v
    queries.grade = ''
    queries.sub_subject = ''
    queries.book = ''
    queries.section = ''
    queries.sub_section = ''
    queries.work_status = ''
    queries.error = ''
    setSearchParams(queries)

    setGrade('')
    setSubSubject('')
    setBook('')
    setSection('')
    setSubSection('')
    setOnSearch(true)
  }
  const handleError = (e) => {
    queries.page = 1
    queries.error = e.target.value
    setSearchParams(queries)
    setOnSearch(true)
  }
  const handleWorkStatus = (e) => {
    queries.page = 1
    queries.work_status = e.target.value
    setSearchParams(queries)
    setOnSearch(true)
  }

  const getPageNum = (page) => {
    queries.page = page
    setSearchParams(queries)
    setOnSearch(true)
  }

  // workType에 따른 서브 필터
  const getSubFilter = (workType) => {
    if (workType === '1') {
      return [
        {label: '오류 여부', value: error, onChange: handleError, items: [{name: '없음', value: '0'}, {name: '있음', value: '1'}]},
      ]
    } else if (workType === '2') {
      return [
        {label: '작성 여부', value: workStatus, onChange: handleWorkStatus, items: [{name: '없음', value: '0'}, {name: '있음', value: '1'}]},
      ]
    }
  }

  const [onSearch, setOnSearch] = useState(false) // 검색 트리거
  const [questions, setQuestions] = useState([]) // 검색 결과 list

  // 검색 클릭
  const handleSearch = () => {
    if (!subSection) return // 중단원이 없다면 검색 안 됨

    // 페이지 및 서브 필터 초기화
    queries.page = 1
    delete queries.work_status
    delete queries.error

    setSearchParams(queries)
    setOnSearch(true)
  }

  // GET - 할당된 list
  const { data: question_list } = useQuery(
    [`project/question-subsection/${subSection}`, subSection, page, workStatus, error], 
    () => getAPI(`project/question-subsection/${subSection}`, 
    {
      service: service, 
      page: page, 
      work_type: workType,
      work_status: workStatus ? workStatus : null,
      error: error ? error : null,
    }), {
      retry: false,
      enabled: !!(onSearch && _sub_section && subSection),
      select: data => data.data,
      onSuccess: data => {
        console.log(data)
        if (data.count === 0) alert('해당 데이터가 존재하지 않습니다')
        setTotalCount(data.count)
        setQuestions(data.data)
        setOnSearch(false)
        setFlag(false)
      },
      onError: error => {
        console.log(error)
        setTotalCount(0)
        setQuestions([])
        setOnSearch(false)
        setFlag(false)
      }
    }
  )

  return (
    <Grid container sx={{position: 'relative'}}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>기출 관리</div>
            </BtnBox>
          </Title>

          <TabBox>
            <Tab selected={workType === '1'} onClick={()=>handleWorkType('1')}>문제 / 해설</Tab>
            <Tab selected={workType === '2'} onClick={()=>handleWorkType('2')}>지문</Tab>
          </TabBox>
          
          <ContentWrap>
            {/* 학년 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">학년</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={grade}
                label="학년"
                onChange={e => setGrade(e.target.value)}
              >
                {grade_list?.map(e => <MenuItem key={e.id} value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>

            {/* 과목 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">세부과목</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subSubject}
                label="세부과목"
                onChange={e => setSubSubject(e.target.value)}
              >
                {sub_subject_list?.map(e => <MenuItem key={e.id} value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>

            {/* 문제집 */}
            <FormControl disabled={false} fullWidth size="small">
              <InputLabel id="demo-simple-select-label">문제집</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={book}
                label="문제집"
                onChange={e => setBook(e.target.value)}
              >
                
                {book_list?.map(e => <MenuItem key={e.id} value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>

            {/* 대단원 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">대단원</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue=""
                value={section}
                label="대단원"
                onChange={e => setSection(e.target.value)}
              >
                {section_list?.map(e => <MenuItem key={e.id} value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>
            
            {/* 중단원 */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">중단원</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue=""
                value={subSection}
                label="중단원"
                onChange={e => setSubSection(e.target.value)}
              >
                {sub_section_list?.map(e => <MenuItem key={e.id} value={`${e.id}`}>{e.name}</MenuItem>)}
              </Select>
            </FormControl>

            <Button onclick={handleSearch}>검색</Button>
          </ContentWrap>
          <div style={{height: '1rem'}}></div>
          <ContentWrap>
            {workType && getSubFilter(workType).map((e) => {
              return (
                <FormControl key={e.label} fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">{e.label}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={e.value}
                    label={e.label}
                    onChange={e.onChange}
                  >
                    {e.items.map(v => <MenuItem key={v.value} value={v.value}>{v.name}</MenuItem>)}
                  </Select>
                </FormControl>
              )
            })}
            
            <div style={{minWidth: 'fit-content', fontSize: '0.9rem'}}>
              총 <span style={{fontSize: '18px', fontWeight: 700, color: '#2e6ef2'}}>{totalCount}</span>개의 문제가 있습니다.
            </div>
          </ContentWrap>

          <Grid container sx={{height: '100%', overflow: 'hidden'}} >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{height: '100%', overflow: 'hidden'}} >
              <Item02 hidden={'hidden'} >
                <List Q_data={questions} Q_count={totalCount} Q_page={page} getPageNum={getPageNum} workType={_work_type} />
              </Item02>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  )
})
const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`
const move = keyframes`
	0%{
    top: 8px;
    left: -260px;
  }
  100%{
    top: 8px;
    left: 450px;
  }
`
const move_reverse = keyframes`
	from {
    left: 450px;
  }
  to {
    left: -260px;
  }
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    /* min-width: fit-content; */
    width: 100%;
  }
`
const Tab = styled.div`
  padding: 10px 0;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.selected ? '#1976d2' : 'rgba(0, 0, 0, 0.3)' )};
  background-color: ${(props) => (props.selected ? '#f2f2f2' : 'transparent' )};
  font-weight: ${(props) => (props.selected ? '600' : '400' )};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #f2f2f2;
  cursor: pointer;

  :hover {
    background-color: #f2f2f2;
  }
`
const TabBox = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  gap: 10px;
`
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Item02 = styled(Paper)`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: calc(100vh - 130px); */
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 6px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default BooksManagePage