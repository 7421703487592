import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { ReactSortable } from 'react-sortablejs'
import { Swap } from 'sortablejs'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const ImageBox = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: .2s ease;
  user-select: none;
  cursor: pointer;
`
const ImageDeleteIconBox = styled.div`
  position: absolute;
  top: -1px;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.8);
    .deleteIcon {
      color: #fff;
    }
  }
`
const Image = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .2s ease;
  backface-visibility: hidden;

  :hover {
    opacity: 0.7;
    z-index: 5;
  }
  :active {
    opacity: 0.7;
  }
`

const Wrap = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.container:nth-child(n+2){
    margin-top: 1rem;
}

.draggable {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: .2s ease;
  user-select: none;
  box-sizing: border-box;
  cursor: move;
}
/* .draggable:last-child {
  border-bottom: 1px solid #c5cdd7;
} */

.draggable.dragging {
  opacity: .5;
  border: 2px dashed red;
}

.el {
  display: inline-block;
  vertical-align: middle;
}
.ico-drag {
  display: inline-block;
  vertical-align: inherit;
  width: 15px;
  height: 15px;
  background: url(./menu.png) no-repeat center top;
  background-size: cover;
}
`

const Preview = forwardRef(function CreationForm(props, ref) {
  const { imageList, setImageList, handleClickImage, deletePreviewImage } = props
  
  function getDragAfterElement(container, y) {
    const draggableElements = [...container.querySelectorAll('.draggable:not(.dragging)')]
  
    return draggableElements.reduce((closest, child) => {
      const box = child.getBoundingClientRect() //해당 엘리먼트에 top값, height값 담겨져 있는 메소드를 호출해 box변수에 할당
      const offset = y - box.top - box.height / 2 //수직 좌표 - top값 - height값 / 2의 연산을 통해서 offset변수에 할당
      if (offset < 0 && offset > closest.offset) { // (예외 처리) 0 이하 와, 음의 무한대 사이에 조건
        return { offset: offset, element: child } // Element를 리턴
      } else {
        return closest
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element
  };


  useEffect(() => {
    const $ = (select) => document.querySelectorAll(select)
    const draggables = $('.draggable')
    const containers = $('.container')

    draggables.length > 0 && draggables.forEach((el, index) => {
      el.addEventListener('dragstart', () => {
        el.classList.add('dragging')
      })

      el.addEventListener('dragend', () => {
        el.classList.remove('dragging')

        const container = document.querySelector('.container')
        const childNodes = container.childNodes
        let newArr = []
        childNodes.forEach(el => newArr = [...newArr, el.lastChild.src])
        // console.log(newArr)
        setImageList(newArr)
      })
    })

    containers.length > 0 && containers.forEach(container => {
      container.addEventListener('dragover', e => {
        e.preventDefault()
        const afterElement = getDragAfterElement(container, e.clientY);
        const draggable = document.querySelector('.dragging')
        container.insertBefore(draggable, afterElement)
      })
    });
  }, [imageList.length])

  // drag & drop reference Github
  // https://github.com/younhoso/younhoso/tree/main/blogExample/Drag_Drop

  const handleClickItem = (e, img) => {
    if (e.detail === 2) {
      // console.log(e.target.naturalWidth)
      // console.log(e.target.naturalHeight)
      const moreLong = e.target.naturalWidth >= e.target.naturalHeight ? 'width' : 'height'
      handleClickImage(img, moreLong)
    }
  }

  const getOcrImageList = async () => {
    const container = document.querySelector('.container')
    const childNodes = container.childNodes
    let newArr = []
    childNodes.forEach(el => newArr = [...newArr, el.lastChild.src])
    await setImageList(newArr)
  }

  useImperativeHandle(ref, () => ({ getOcrImageList}))
  
  return (
    <Wrap>
      <Container className='container'>
        {imageList.length > 0 
        && imageList.map((img, index) => {
          return (
            <ImageBox key={img} data-order={index} className='draggable' onClick={(e) => handleClickItem(e, img)} >
              <ImageDeleteIconBox onClick={() => deletePreviewImage(img)}>
                <ClearRoundedIcon className='deleteIcon' sx={{color: '#eee'}} />
              </ImageDeleteIconBox>
              <Image alt={img} src={img} className='image' />
            </ImageBox>
          )
        })}
      </Container>
    </Wrap>
  )
})

export default React.memo(Preview)