import styled from 'styled-components'
import BackToList from '../button/BackToList'
import Skeleton from '../skeleton/writeEditor/devicePreview/Skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import 'katex/dist/katex.css'
import renderMathInElement from 'katex/dist/contrib/auto-render.js'

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';

const EditorDevicePreview = ({ close }) => {
  const writeEditor = useSelector((state) => state.writeEditor)
  const {
    is_multiple,
    data_html,
    image_data,
    multiple_answer_list,
    multiple_html_raw,
    is_multiple_raw_empty,
    essay_answer_list,
    difficulty
  } = writeEditor

  console.log(data_html)

  const ref = useRef()

  const typeOptionList = [ 
    { name: 'mobile_S', width: '326px', height: '580px' }, 
    { name: 'mobile_M', width: '370px', height: '630px' }, 
    { name: 'mobile_R', width: '400px', height: '680px' }, 
    { name: 'tablet_S', width: '600px', height: '800px' }, 
    { name: 'tablet_M', width: '700px', height: '900px' }, 
    { name: 'tablet_R', width: '800px', height: '1000px' }
  ]

  const [ w, setWidth ] = useState('326px')
  const [ h, setHeight ] = useState('580px')
  const [ type, setType ] = useState('mobile_S')
  const handleType = (e) => {
    const type_obj = typeOptionList.find(el => el.name === e.target.value)
    
    setType(type_obj.name)
    setWidth(type_obj.width)
    setHeight(type_obj.height)
  }

  const handleClose = () => {
    close()
  }

  const handleNumber = (el) => {
    if (el === 1) return "①"
    if (el === 2) return "②"
    if (el === 3) return "③"
    if (el === 4) return "④"
    if (el === 5) return "⑤"
  }

  useEffect(() => {
    if (multiple_answer_list.length > 0) {
      multiple_answer_list.map(el => handleNumber(String(el)))
      
    }
  }, [multiple_answer_list])
  

  useEffect(()=>{
    if (ref.current) {
      renderMathInElement(ref.current, {
        delimiters: [
          {left: "$\\[", right: "\\]$", display: true},
          {left: "$\\(", right: "\\)$", display: false},
        ],
        throwOnError: false
  
      })
    }
  })


  return (
    <Wrap>
      <Header>
        <BtnBox>
          <div className='title'>미리보기</div>
          {data_html &&
            <BtnBox02>
              <BackToList onclick={handleClose}>나가기</BackToList>
              <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '2px', width: 180 }}
              >
                <Select
                  size='small'
                  value={type}
                  defaultValue={'mobile_S'}
                  onChange={handleType}
                  displayEmpty
                  sx={{fontSize: '14px', fontWeight: 500, m: '4px', width: '100%', height: 36}}
                >
                  {typeOptionList.map((el, idx) => {
                    return <MenuItem key={String(idx)} value={el.name}>{el.name}</MenuItem>
                  })}
                </Select>
              </Paper>
            </BtnBox02>
          }
        </BtnBox>
      </Header>
      {!data_html &&
        <Skeleton />
      }
      
      {data_html &&
        <ContentWrap>
          <Content ref={ref} width={w} height={h} >
            <div dangerouslySetInnerHTML={ {__html: data_html.replace(/\<span class="math-tex"\>/g, '$').replace(/\<\/span\>/g, '$')} }></div>
            {is_multiple === 1 &&
              <div>
                <div dangerouslySetInnerHTML={ {__html: multiple_html_raw.replace(/\<span class="math-tex"\>/g, '$').replace(/\<\/span\>/g, '$')} }></div>
              </div>
            }
          </Content>
        </ContentWrap>
      }
    </Wrap>
  )
}

const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 0 1rem; */
  overflow-y: auto;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Content = styled.div`
  width: 100%;
  height: 100%;
  width: ${props => props.width};
  min-height: ${props => props.height};
  border: 10px solid rgba(0, 0, 0, 0.6);
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-radius: 2rem;
  padding: 1rem;
  margin: 0.8rem 0 1rem;

  p {
    margin: 5px 0;
  }

  .pr-multiple_box[data-num=''] {
    border: none;
    .pr-multiple_box_content_wrap {
      border: none;
    }
    .pr-multiple_box_content {
      border: none;
      p {

      }
    }
  }
  .pr-multiple_box[data-num='']:before {
    border: none;
  }
  .pr-multiple_box[data-num='④'] {
    background-color: #2e2e2e;
  }

  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    border: none;
    padding: 0;
  }
`
const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Header = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default EditorDevicePreview