import { useState, useEffect } from 'react'
import { useNavigate, createSearchParams, useSearchParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'
// import Select from '../../../components/_molecules/select/SelectBoxTH'
import ItemBox from '../../../components/_molecules/select/ItemBox'
import { getFilter } from '../utils/getFilter'

import { useQuery } from 'react-query'
import { getAPI } from '../../../api'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'


interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any
  openModal?: (projectId: number, role: number, labeler: any)=>void
  filter: number
  sub_section_id: number
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], openModal=()=>{}, filter=0, sub_section_id=0}: tableBodyProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleClick = (ko_id: number) => {
    const previous_page = location.pathname + location.search
    localStorage.setItem("previous_page", previous_page)
    navigate({
      pathname: `detail/${ko_id}`,
      search: `?${createSearchParams({filter: filter, sub_section_id: sub_section_id})}`
    })
  }

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        return (
          <Row
            key={row.th_id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={()=>handleClick(row.ko_id)}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="center">{row.ko_id}</Cell>
            <Cell align="center">{row.th_id}</Cell>
            <Cell align="center">
              {row.status === 0 && <StatusText color={'rgba(0, 0, 0, 0.3)'}>확인 대기</StatusText>}
              {row.status === 1 && <StatusText color={'rgba(0, 0, 0, 0.7)'}>번역 대기</StatusText>}
              {row.status === 2 && <StatusText color={'#FFB26B'}>검수 대기</StatusText>}
              {row.status === 3 && <StatusText color={'red'}>수정 대기</StatusText>}
              {row.status === 4 && <StatusText color={'#FF7B54'}>재검수 대기</StatusText>}
              {row.status === 5 && <StatusText color={'green'}>검수 완료</StatusText>}
            </Cell>
            <Cell align="center">{row.translate_name ? row.translate_name : '-'}</Cell>
            <Cell align="center">
              {!row.duplicate_major && <Circle color={'red'} />}
              {row.duplicate_major && <Circle color={'#66CC33'} />}
            </Cell>
            <Cell align="center">{row.difficult !== 0 ? row.difficult : '-'}</Cell>
            <Cell align="center">{row.updated_time}</Cell>
            <Cell align="center">{row.question_name}</Cell>
            <Cell align="center">{row.answer_type}</Cell>
          </Row>
        )
      })}
    </>
  )
}

interface QBProps {
  _service: string
}

const QB = ({_service}: QBProps) => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _filter = Number(searchParams.get('filter'))

  const _tab = searchParams.get('tab')
  const _major = Number(searchParams.get('major'))
  const _grade = searchParams.get('grade')
  const _subject = searchParams.get('subject')
  const _section = searchParams.get('section')
  const _subSection = searchParams.get('subSection')

  const headList = ['KO_id', 'TH_id', '번역 상태', '번역자', '언어/수학 전공', '난이도', '수정일', '문제', '문제 유형']
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

// ----- filter -----
  const [filter, setFilter] = useState(_filter)
  const filter_list = [
    {name: '전체', value: 0},
    {name: '확인 대기', value: 1},
    {name: '번역 대기', value: 2},
    {name: '검수 대기', value: 3},
    {name: '수정 대기', value: 4},
    {name: '재검수 대기', value: 5},
    {name: '검수 완료', value: 6},
    {name: '객관식', value: 7},
    {name: '주관식', value: 8},
    {name: '난이도 X', value: 9},
    {name: '난이도 O', value: 10},
    {name: '대표 중단원', value: 11},
  ]
  const [filterList, setFilterList] = useState(filter_list)

// ----- tab -----
  const [tab, setTab] = useState(_tab ? _tab : '수학') // string
  const onClickTab = (e) => {
    setSearchParams({
      page: 1, 
      filter: filter, 
      tab: e.target.value
    })
    setBodyList(null)
    setSubject(null)
    setSection(null)
    setSubSection(null)
    setSectionList([])
    setSubSectionList([])
  }

  // ----- select -----
  const [major, setMajor] = useState(_major ? Number(_major) : _major) // 전공
  const [grade, setGrade] = useState(_grade ? _grade : 'M4') // 학년
  const [subject, setSubject] = useState(_subject ? Number(_subject) : _subject) // 과목
  const [section, setSection] = useState(_section ? Number(_section) : _section) // 대단원
  const [subSection, setSubSection] = useState(_subSection ? Number(_subSection) : _subSection) // 중단원

  const handleMajor = (v: number) => {
    setMajor(v)
    setBodyList(null)
    setGrade('M4')
    setSubject(null)
    setSection(null)
    setSubSection(null)
    setSectionList([])
    setSubSectionList([])
  }
  const handleGrade = (v: string) => {
    setGrade(v)
    setBodyList(null)
    setSubject(null)
    setSection(null)
    setSubSection(null)
    setSectionList([])
    setSubSectionList([])
  }

  const handleSubSection = (v: number) => {
    setSearchParams({
      page: 1, 
      filter: filter, 
      tab: tab, 
      major: major, 
      grade: grade, 
      subject: subject, 
      section: section, 
      subSection: v
    })
  }

  const majorList = [
    {value: 0, name: '언어, 수학()'},
    {value: 1, name: '과학()'},
  ] // 전공 list
  const gradeList = [
    {value: 'M4', name: 'M4'},
    {value: 'M5', name: 'M5'},
    {value: 'M6', name: 'M6'},
  ] // 학년 list
  const [subjectList, setSubjectList] = useState([]) // 과목 list
  const [sectionList, setSectionList] = useState([]) // 대단원 list
  const [subSectionList, setSubSectionList] = useState([]) // 중단원 list

  const { data: data_filter } = useQuery([`th-filter-${tab}_${major}_${grade}`, {tab, major, grade}], () => getAPI(`th/filter`, {subject: tab, major: tab === '수학' ? null : major, grade: tab === '과학' && major === 0 ? null : grade}), {
    cacheTime: Infinity,
  })

  useEffect(() => {
    console.log(data_filter)
    if (!data_filter) return
    console.log(data_filter.data)
    setSubjectList(data_filter.data)
  }, [data_filter])

  const { isFetching } = useQuery([`th-${_service}-question`, {page, filter, subSection}], () => getAPI(`th/${_service}/question/${subSection}`, {page: page + 1, filter: filter}), {
    enabled: !!(subSection !== null),
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.question} filter={filter} sub_section_id={subSection} />
      setBodyList(list)
      setTotalCount(data.data.total_count)
    }
  })

  const changedFilter = (v: number) => {
    setSearchParams({
      page: 1, 
      filter: v, 
      tab: tab, 
      major: major, 
      grade: grade, 
      subject: subject, 
      section: section, 
      subSection: subSection
    })
  }

  const changedPage = (v) => {
    setSearchParams({
      page: v + 1, 
      filter: filter,
      tab: tab, 
      major: major, 
      grade: grade, 
      subject: subject, 
      section: section, 
      subSection: subSection
    })
  }

  useEffect(() => {
    setSubSection(Number(_subSection))
  }, [_subSection])

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setFilter(_filter)
  }, [_filter])

  useEffect(() => {
    setTab(_tab)
  }, [_tab])

  useEffect(() => {
    if (subjectList.length !== 0 && subject !== null) {
      const _list = subjectList.filter(el => el.sub_subject_id === subject)[0]

      if (!_list) return
      const list = _list.section
      setSectionList(list)

      if (Number(_subject) === subject) return
      setSection(null)
      setSubSection(null)
      setSubSectionList([])
    }
  }, [subject, subjectList.length])

  useEffect(() => {
    if (sectionList.length !== 0 && section !== null) {
      const list = sectionList.filter(el => el.section_id === section)[0].sub_section
      setSubSectionList(list)

      if (Number(_section) === section) return
      setSubSection(null)
    }
  }, [section, sectionList.length])

  useEffect(()=>{
    if (location.search === "?page=1&filter=0&tab=%EC%88%98%ED%95%99") {
      // console.log()
    }
  }, [_service])

  return (
    <MainSection>
      <>
        <TabBox>
          <TabButton type='button' name='tab_subject' value='수학' active={tab === '수학'} onClick={onClickTab}>수학</TabButton>
          <TabButton type='button' name='tab_subject' value='과학' active={tab === '과학'} onClick={onClickTab}>과학</TabButton>
        </TabBox>
        <SelectBox>
          <Select disabled={tab === '수학'}>
            <LabelBox>전공</LabelBox>
            <ItemBox>
              {tab !== '수학' &&
                <>
                  {majorList.map(el => 
                    <button key={el.value} onClick={() => handleMajor(el.value)} className={[
                      'item',
                      major === el.value && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              }
            </ItemBox>
          </Select>
          <Select disabled={tab === '과학' && major === 0}>
            <LabelBox>학년</LabelBox>
            <ItemBox>
              {!(tab === '과학' && major === 0) &&
                <>
                  {gradeList.map(el => 
                    <button key={el.value} onClick={() => handleGrade(el.value)} className={[
                      'item',
                      grade === el.value && 'active'
                      ].join(' ')} type='button'
                    >{el.name}</button>
                  )}
                </>
              }
            </ItemBox>
          </Select>
          <Select>
            <LabelBox>과목</LabelBox>
            <ItemBox>
              <>
                {subjectList.map(el => 
                  <button key={el.sub_subject_id} onClick={() => setSubject(el.sub_subject_id)} className={[
                    'item',
                    subject === el.sub_subject_id && 'active'
                    ].join(' ')} type='button'
                  >{el.sub_subject_name}</button>
                )}
              </>
            </ItemBox>
          </Select>
          <Select>
            <LabelBox>대단원</LabelBox>
            <ItemBox align={'flex-start'}>
              <>
                {sectionList.map(el => 
                  <button key={el.section_id} onClick={() => setSection(el.section_id)} className={[
                    'item',
                    section === el.section_id && 'active'
                    ].join(' ')} type='button'
                  >{el.section_name}</button>
                )}
              </>
            </ItemBox>
          </Select>
          <Select>
            <LabelBox>중단원</LabelBox>
            <ItemBox align={'flex-start'}>
              <>
                {subSectionList.map(el => {
                  return (<button key={el.sub_section_id} onClick={() => handleSubSection(el.sub_section_id)} className={[
                    'item',
                    subSection === el.sub_section_id && 'active'
                    ].join(' ')} type='button'
                  >{el.sub_section_name}</button>)}
                )}
              </>
            </ItemBox>
          </Select>
        </SelectBox>
        <Toolbar isFilter filter={filter} filterList={filterList} getFilter={changedFilter} filterLabel={'필터'} filterBoxWidth={'9rem'} />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const TabBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const TabButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.15s;
  color: ${(props) => (props.active ? '#1976d2' : 'rgba(0, 0, 0, 0.3)' )};
  cursor: pointer;

  :hover {
    color: rgba(0, 0, 0, 0.5);
  }
  :focus {
    color: #1976d2;
  }
`
const SelectBox = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 0.4rem;
  display: grid;
  column-gap: 0.4rem;
  grid-template-columns: repeat(auto-fit, minmax(10%, auto));
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.08); */
  /* border: 1px solid orange; */
`
const Select = styled.div`
  box-sizing: border-box;
  color: #333;
  font-weight: 500;
  border-radius: 4px;
  overflow-y: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  min-width: 3rem;
`
const LabelBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.color};
`
const StatusText = styled.div`
  /* width: 10px;
  height: 10px; */
  margin: 0 auto;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${props => props.color};
`

export default QB