import { useParams } from 'react-router-dom'
import QB from './QB'
import BOOKS from './BOOKS'

const Main = () => {
  const {'service': _service} = useParams()
  // console.log(_service)

  if (_service === 'qb') return <QB _service={_service} />
  if (_service === 'book') return <BOOKS _service={_service} />
}

export default Main