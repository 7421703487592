import * as S from './ProgressBar.style'

const ProgressBar = (props) => {
  const { start, end, overall, rest, percent, now} = props
  const new_percent = (12 < percent) && (percent < 89) 
    ? percent
    : (12 >= percent ? 13 : 88)

  return (
    <S.ProgressBox percent={`${new_percent}%`}>
      {now === 1 &&
        <div className='label_pg'>
          <span className='label_pg_percent'>{rest}일 남았습니다</span>
        </div>
      }
      {now === 1 &&
        <progress id='pg_label' value={percent} max="100"><span id='pg'></span></progress>
      }
      <progress id='progress' value={percent} max="100"></progress>
      <div className='label_left_text_box'>
        <span className='label_left_text'>{start}</span>
      </div>
      <div className='label_right_text_box'>
        <span className='label_left_text'>{end}</span>
      </div>
    </S.ProgressBox>
  )
}

export default ProgressBar