import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'
import ProgressBarOrigin from '../../../elements/progressBar/ProgressBarOrigin'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAPI, postAPI } from '../../../api'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  background-color: ${props => props.bgcolor};
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor};
`
const NoneList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #2e6ef2;
  font-size: 1.2rem;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.05);

  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`
const IsSelected = styled.div`
  color: ${props => props.color};
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any[]
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[]}: tableBodyProps) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const goToWorkspace = (project_type: string, question_id: number) => {
    localStorage.setItem("clicked_Q_id", question_id)
    if (project_type === 'labeling') {
      navigate(`/workspace/project/labeling/content/question/${question_id}/correcting`)
    } else if (project_type === 'review') {
      navigate(`/workspace/project/review/content/question/${question_id}`)
    }
  }
  // 53763
  const { mutate } = useMutation(postAPI, {
    onSuccess: (data) => {
      console.log('data:', data)
      if (data.data.message === 'success !') {
        queryClient.invalidateQueries('questions/error')
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })
  const check = (question_id: number) => {
    // if (window.confirm('오류 저장하시겠습니까?') === false) return
    const data = {
      apiURL: `/questions/error/check`,
      body: {
        question_id: question_id
      }
    }
    mutate(data)
  }
  const clicked_Q_id = localStorage.getItem("clicked_Q_id")

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        return (
          <Row
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            bgcolor={row.id === Number(clicked_Q_id) ? '#f0f8ff' : 'transparent'}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="center">{row.id}</Cell>
            <Cell align="left">{row.title}</Cell>
            <Cell align="center">{row.type.error_1}</Cell>
            <Cell align="center">{row.type.error_2}</Cell>
            <Cell align="center">{row.type.error_3}</Cell>
            <Cell align="center">{row.type.error_4}</Cell>
            <Cell align="center">
              <span style={{fontWeight: 'bold'}}>{row.count}</span>
            </Cell>
            <Cell align="center">
              {row.is_selected && <IsSelected color={'#1F8A70'}>객관식</IsSelected>}
              {!row.is_selected && <IsSelected color={'#DF7857'}>주관식</IsSelected>}
            </Cell>
            <Cell align="center">{row.labeler ? row.labeler : '-'} / {row.reviewer ? row.reviewer : '-'}</Cell>
            <Cell>
              <Btn onClick={(e)=>goToWorkspace('labeling', row.id)}>labeling</Btn>
            </Cell>
            <Cell>
              <Btn onClick={(e)=>goToWorkspace('review', row.id)}>review</Btn>
            </Cell>
            <Cell>
              <Btn onClick={(e)=>check(row.id)}>완료</Btn>
            </Cell>
          </Row>
        )
      })}
    </>
  )
}

const QuestionError = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _sort = Number(searchParams.get('sort'))
  const _search_filter = Number(searchParams.get('search_filter'))
  const _search = searchParams.get('search')

  const headList = ['문제 ID', '문제명', '에러 1', '에러 2', '에러 3', '에러 4', '신고 수', '객관식/주관식', '라벨러/검수자', '', '', '']
  const [bodyList, setBodyList] = useState(null)
  const [title, setTitle] = useState('')
  const [isEmptyQuestion, setIsEmptyQuestion] = useState(false)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [keyword, setKeyword] = useState(_search)

  // ----- SEARCH -----
  const [searchFilter, setSearchFilter] = useState(_search_filter)
  const searchFilterList = [
    {name: '문제 ID', value: 1, value_name: 'question_id'},
    {name: '문제명', value: 2, value_name: 'question_title'},
  ]

  // ----- FILTER -----
  const [sort, setSort] = useState(_sort)
  const sortList = [
    {name: '최신순', value: 0},
    {name: '문제 ID순', value: 1},
    {name: '신고 누적순', value: 2},
    {name: '1 - 지문 혹은 그림 없음', value: 3},
    {name: '2 - 답이 틀림', value: 4},
    {name: '3 - 해당 단원의 문제가 아님', value: 5},
    {name: '4 - 문제 오류(오타, $ 보임)', value: 6},
  ]

  const { isFetching } = useQuery([`questions/error`, {page, sort, keyword}], () => getAPI(`questions/error`, {page: page + 1, sort: sort, search_filter: searchFilter, keyword: keyword}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      if (data.data.page.content_count === 0 && data.data.questions === null) return setIsEmptyQuestion(true)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.questions} />
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
    }
  })

  const changedType = (v: number) => {
    setSearchParams({
      page: 1, 
      search_filter: searchFilter,
      search: keyword,
      sort: v, 
    })
  }

  const changedPage = (v: number) => {
    setSearchParams({
      page: v + 1, 
      search_filter: searchFilter,
      search: keyword,
      sort: sort, 
    })
  }

  const changedKeyword = (v: string) => {
    setSearchParams({
      page: 1, 
      search_filter: searchFilter,
      search: v,
      sort: sort, 
    })
  }

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setSort(_sort)
  }, [_sort])

  useEffect(() => {
    setKeyword(_search)
  }, [_search])

  useEffect(()=>{
    setSearchFilter(_search_filter)
  }, [])


  return (
    <MainSection>
      <>
        <Toolbar 
          title={`오류 신고 문제`} 
          isFilterAndSearch 
          inputType={searchFilter === 1 ? 'number' : 'text'}
          keyword={keyword} 
          getKeyword={changedKeyword} 
          searchFilter={searchFilter} 
          searchFilterList={searchFilterList}
          getSearchFilter={setSearchFilter} 
          isFilter={!isEmptyQuestion} 
          filter={sort} 
          filterList={sortList} 
          getFilter={changedType} 
          filterLabel={'정렬'} 
          filterBoxWidth={'9rem'}
        />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

export default QuestionError