import { createContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { fabric } from 'fabric'
import { actionCreators as imageEditorActions } from "../redux/modules/imageEditor";

export const CanvasContextStore = createContext();

const CanvasContext = (props) => { 
  const dispatch = useDispatch()
  const {'project_id': project_id, 'content_id': content_id} = useParams()
  // const _project_id = Number(project_id)
  const _content_id = content_id
  const [ fabricCanvas, setFabricCanvas ] = useState([])
  const [ activeCanvas, setActiveCanvas ] = useState(0)  // 0 부터 마지막 페이지 넘버까지. 0 = page 1(첫 페이지)

  const get_data = useSelector((state) => state.imageEditor.saved_data_json)
  const saved_data_json = get_data && get_data.boxing
  const question_list = get_data && get_data.url
  // const question_list = useSelector((state) => state.imageEditor.image_url_list)
  const Q_list_count = question_list && question_list.length

  useEffect(() => {
    dispatch(imageEditorActions.getImageListAPI(_content_id))
  }, [])
  
  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none'
    // console.log(Q_list_count)
    
    // if (Q_list_count > 0 && saved_data_json) {
    if (get_data) {
      // console.log(saved_data_json)
      let data

      if (saved_data_json === '-') {
        // 데이터 없을 경우
        data = Array.from({ length: Q_list_count }).map((item, index) => {
          const newFabricCanvas = new fabric.Canvas(`canvas_${index}`, {
            width: 480,
            height: 700,
            uniformScaling: false
          })
          
          return newFabricCanvas
        })
        
      } else {
        // console.log(saved_data_json)
        // 데이터 있을 경우

        const data_list = saved_data_json.split('__||__')
        const json = data_list[0]
        const question_data = JSON.parse(data_list[1])
        const related_data = JSON.parse(data_list[2])

        const origin_data = JSON.parse(json)
        let question_box_list = []
        let related_box_list = []

        data = origin_data.map((json, index) => {
          const canvas = JSON.parse(json)

          const newCanvas = new fabric.Canvas(`canvas_${index}`, canvas)
          newCanvas.set({width: 480, height: 700})

          newCanvas.loadFromJSON(canvas, function() {
            const klass_list = newCanvas.getObjects()

            // 또다른 로컬에 저장된 json 데이터와 연동
            klass_list.forEach(klass => {
              if (klass.question_id) {
                const question_id = {...klass.question_id}
                klass.question_id = question_id.id
                const is_id = question_box_list.findIndex(obj => obj.id === question_id.id)

                if (is_id === -1) {
                  question_box_list = [...question_box_list, question_id]
                }

              } else if (klass.related_id) {
                const related_id = {...klass.related_id}
                klass.related_id = related_id.id
                const is_id = related_box_list.findIndex(obj => obj.id === related_id.id)

                if (is_id === -1) {
                  related_box_list = [...related_box_list, related_id]
                }
              }
              newCanvas.renderAll()
            })
          })

          return newCanvas
        })
        // dispatch(imageEditorActions.update_question_data(question_box_list))
        // dispatch(imageEditorActions.update_related_data(related_box_list))
        dispatch(imageEditorActions.update_question_data(question_data.length > 0 ? question_data : []))
        dispatch(imageEditorActions.update_related_data(related_data.length > 0 ? related_data : []))
      }
      console.log(data)
      // const a = data.map(v => {
      //   v.objects = v.objects.filter(e => e.id !== 'f08ff4cd-1c80-4ffb-ba13-7c6a6c04d78e')
      //   v._objects = v._objects.filter(e => e.id !== 'f08ff4cd-1c80-4ffb-ba13-7c6a6c04d78e')
      //   return v
      // })
      // console.log(a)
      setFabricCanvas(data)

      fabric.Canvas.prototype.uniformScaling = false;
      fabric.Object.prototype.cornerSize = 7;
      fabric.Object.prototype.cornerColor = 'white';
      fabric.Object.prototype.cornerStyle = 'circle';
      fabric.Object.prototype.borderColor = 'rgba(140, 180, 255, 1)';
      fabric.Object.prototype.cornerStrokeColor = 'rgba(166, 212, 255, 0.8)';
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.setControlsVisibility({mtr: false})

      data.forEach(el => el.on('object:moving', function (e) {
        let obj = e.target;
         // if object is too big ignore
        if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width){
          return;
        }        
        obj.setCoords();        
        // top-left  corner
        if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
          obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top)
          obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left)
        }
        // bot-right corner
        if(obj.getBoundingRect().top+obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
          obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top)
          obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left)
        }
      }))
      // http://jsfiddle.net/nasir101m/gaz704v7/)
    }

    return () => {
      document.body.style.overscrollBehaviorX = 'auto'
      // console.log('dddddddddddddddddddddddddddddddddddddddddddd')
    }
  }, [get_data])

  const UserInfo = {
    fabricCanvas,
    setFabricCanvas,
    activeCanvas,
    setActiveCanvas,
  }

  return (<CanvasContextStore.Provider value={UserInfo}>{props.children}</CanvasContextStore.Provider>);
};

export default CanvasContext;