import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { requestNoticeList } from '../../../api/getAPI'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Card,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput
} from '@mui/material'
import styled from 'styled-components'
import ProgressBar from '../../../elements/progressBar/ProgressBar'
import { actionCreators as dashboardActions } from "../../../redux/modules/dashboard";

const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;
const Title = styled.div`
  color: #111111;
  font-weight: 500;
`
const Wrap = styled(Box)`
  /* min-width: 300px; */
  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
`
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid red;
`

const LabelerMatching = ({ labelerList, labeler2, setLabeler2 }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState('국어');
  const subjectRef = useRef()

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();
  const [personName, setPersonName] = React.useState(labeler2);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLabeler2(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };

  useEffect(() => {
    dispatch(dashboardActions.getNoticeListAPI(page))
  }, [])
  

  const searchFilter = () => {
    // dispatch(matchingActions.getMatchingDataListAPI(1, filter, search));
  };

  const selectSubject = (e) => {
    setSelected(e.target.innerText)
    // subjectRef.current = e.target.innerText 
  }

  // const { isSuccess, isError, data } = useQuery(['getNotice', page], requestNoticeList)
  // console.log(data)

  return (
    <Wrap>
      <FormControl sx={{ mt: 1, minWidth: 300, width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">선택</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={labeler2}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {labelerList.map((el) => (
            <MenuItem
              key={el.id}
              value={el.name}
              style={getStyles(el.name, personName, theme)}
            >
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrap>
  );
};

export default LabelerMatching;
