import * as S from './Column.style'
import { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import { actionCreators as imageEditorActions } from "../../../../redux/modules/imageEditor";

import _ from 'lodash'

import { CanvasContextStore } from '../../../CanvasContext'

const Column = ({ children, id, name=null, AddQuestionColumn=null }) => {
  const dispatch = useDispatch()
  const { fabricCanvas } = useContext(CanvasContextStore)
  // const all_box_data = useSelector((state) => state.imageEditor.box_data)
  const all_related_data = useSelector((state) => state.imageEditor.related_data)
  const all_question_data = useSelector((state) => state.imageEditor.question_data)

  // 각각의 related 박스에 표시되는 box_data 개수
  let target_klass_list = []
  if (id !== '문제') {
    fabricCanvas.map(canvas => {
      const targetKlass = canvas._objects.filter(klass => klass.related_id === id )
      target_klass_list = [...target_klass_list, ...targetKlass]
    })
  }
  const box_count = target_klass_list.length

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "Our first type",
    drop: () => ({ id: id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: (item) => {
      const { currentColumnName } = item;
      return (
        (currentColumnName !== '연관 지문')
      );
    }
  });

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return "RGB(228, 243, 247)";
      } else if (!canDrop) {
        return "rgba(255,188,188, 0.6)";
      }
    } else {
      return "white";
    }
  };

  const deleteColumn = () => {
    // 링크된 지우기 박스의 related_id = null + 색상 기본으로 변경
    // const new_B_data = all_box_data.map(obj => {
    //   if (obj.related_id === id) {
    //     obj.related_id = null
    //   }
    //   return obj
    // })
    console.log(target_klass_list)
    console.log(box_count)
    if (box_count > 0) {
      target_klass_list.map(klass => {
        klass.set('fill', 'rgba(166, 166, 166, 0.2)')
        klass.related_id = null
      })
    }
    fabricCanvas.map(canvas => canvas.requestRenderAll())

    // 연관 지문 객체 삭제 후 저장
    const new_R_data = all_related_data.filter(obj => obj.id !== id)
    
    // 링크된 문제 박스 기본 '문제' 속성으로 변경 -> 문제 영역으로 이동
    const cloned_Q_data = _.cloneDeep(all_question_data);
    const new_Q_data = cloned_Q_data.map(obj => {
      if (obj.related === id) {
        obj.column = '문제'
        obj.related = null
      }
      return obj
    })

    dispatch(imageEditorActions.update_related_data(new_R_data))
    dispatch(imageEditorActions.update_question_data(new_Q_data))
  }

  const clickRelatedBox = () => {
    if (box_count === 0) return
    fabricCanvas.map(canvas => canvas.discardActiveObject())

    // 클릭한 문제 박스와 연관된 지우기 박스들 활성화
    target_klass_list.map(klass => {
      fabricCanvas[klass.page].setActiveObject(klass)
    })

    fabricCanvas.map(canvas => canvas.requestRenderAll())
  }

  return (
    <S.Wrap 
      ref={drop}
      width={id !== '문제' ? '100%' : '240px'}
    >
      {/* {(id === '문제') && <S.Title>{id}</S.Title>} */}
      
      <S.ListBox
        bgColor={id !== '문제' && getBackgroundColor} 
        pTop={id !== '문제' ? '26px' : ''}
        shadow={id !== '문제' ? 'rgba(0, 0, 0, 0.1) 0px 3px 4px, rgba(0, 0, 0, 0.2) 0px 1px 4px' : ''} // 
      >
        {id === '문제'
          && (
            <S.AddItem onClick={() => id === '문제' && AddQuestionColumn()} className='add_box'>
              <AddIcon sx={{ width: '18px', color: 'rgba(0, 0, 0, 0.7)'}} /><span>문제</span>
            </S.AddItem>
          )
        }
        {id !== '문제' &&
          <div className='del_btn' onClick={deleteColumn}>
            <DisabledByDefaultRoundedIcon />
          </div>
        }
        {id !== '문제'
          && (
            <>
              <S.TitleBox onClick={clickRelatedBox}>
                <div className='box_title'>지문 {name}</div>
                <div className='box_count'>Box {box_count}</div>
                
              </S.TitleBox>
              <div style={{height: '4px'}}></div>
            </>
          )
        }
        <S.ChildrenWrap>
          {children}
        </S.ChildrenWrap>
      </S.ListBox>
    </S.Wrap>
  )
}

export default Column