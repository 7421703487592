import styled from 'styled-components'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'

const Container = styled.div`
  width: ${props => props.width};
`

interface FilterBoxProps {
  list: any
  filter: number
  getFilter: (v:number)=>void
  label: string
  width: string
}

const FilterBox = ({list=[], filter=0, getFilter=()=>{}, label='', width='100%'}: FilterBoxProps) => {
  const onChange = (e) => {
    getFilter(e.target.value)
  }
  return (
    <Container width={width}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label={label}
          onChange={onChange}
        >
          {list.map((el, idx) => {
            return <MenuItem key={String(idx)} value={el.value}>{el.name}</MenuItem>
          })}
        </Select>
      </FormControl>
    </Container>
  )
}

export default FilterBox