import React, { useEffect, useState, useRef, useCallback, useContext } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Skeleton from './skeleton/SkeletonEditor'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as sidebarActions } from "../../redux/modules/sidebar";
import { actionCreators as editorActions } from "../../redux/modules/editor";
import { actionCreators as writeEditorActions } from "../../redux/modules/writeEditor";
import { initAllStore, DToM2 } from './common/EditorFunc'

import _ from 'lodash'
import { fabric } from 'fabric'
import { CropContextStore } from '../CropContext'

import { useMutation, useQueryClient } from 'react-query'
import { postAPI } from '../../api'
import { postCropImageAPI, postSaveAPI } from '../../api/postAPI' 

import Fab from '@mui/material/Fab'  // 플로팅 버튼
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import MyinfoForm from '../../components/mypage/MyInfoForm'
import BackToList from '../../elements/button/BackToList'
import Button from '../../elements/button/Button'
import EditPreview from './EditPreview'
import ReEditPreview from './ReEditPreview'
import EditArea from './EditArea'
import ReEditArea from './ReEditArea'
import LayoutModal from '../../elements/modal/LayoutModal'
import EditorCrop from '../../elements/modal/EditorCrop'
import Preview from './Preview'

import WorkspaceCom from '../../components/_organisms/editor-image/Workspace'

const Editor = () => {

  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const _nation = localStorage.getItem("_nation")
  const _authority = localStorage.getItem("_authority")
  const {'data_type': params02, 'question_id': params} = useParams()
  const question_id = params
  const data_type = params02
  const row_history_url = localStorage.getItem('history_url')
  const origin_history_url = row_history_url !== "undefined" && JSON.parse(row_history_url)
  const { rect, setRect, image, setImage, imageSize, setImageSize, canvasSize, setCanvasSize, cropCanvas, setCropCanvas, croppedImageList, setImageList, createRect, removeRect, initTextCanvas, setWorkspaceScrollY } = useContext(CropContextStore)

  const writeEditor = useSelector((state) => state.writeEditor)
  const {
    is_books,
    is_loading,
    is_rec_ocr_loading,
    is_multiple,
    data_html,
    explanation_html,
    related_text_html,
    image_data,
    multiple_answer_list,
    multiple_html_raw,
    is_multiple_raw_empty,
    essay_answer_list,
    difficulty,
    is_explanation
  } = writeEditor
  const cloudfrontBaseUrl = process.env.REACT_APP_IMAGE_URL
  const questionImageUrl = `${cloudfrontBaseUrl}${image_data?.question_image_name}`
  const solutionImageUrl = `${cloudfrontBaseUrl}${image_data?.solution_image_name}`
  let imageUrlList = []
  if (image_data?.solution_image_name) {
    if (typeof image_data.solution_image_name === 'string') {
      imageUrlList = [ questionImageUrl, solutionImageUrl ]
    } else {
      const list = image_data?.solution_image_name.map(el => `${cloudfrontBaseUrl}${el}`)
      imageUrlList = [ questionImageUrl, ...list ]
    }
  }
  const relatedImageUrl = `${cloudfrontBaseUrl}${image_data?.related_image_name}`
  if (!image_data?.related_image_name?.includes('null')) imageUrlList = [ ...imageUrlList, relatedImageUrl ]


  useEffect(() => {
    // 지문 작성자와 그 외에 따른 GET API
    if (data_type === 'related_text') dispatch(writeEditorActions.getRelatedTextAPI(question_id))
      else {
        dispatch(writeEditorActions.getOcrContentAPI(question_id))
        dispatch(sidebarActions.getBubbleTalkAPI(question_id)) // 사이드바 게시글 말풍선
    }

    dispatch(writeEditorActions.isRecOcrLoading(false))
    // 크롭 및 프리뷰 모달 열린 상태에서 검색했을 때 초기화
    removeRect()
    setSelectedImg(null)
    setWorkspaceScrollY(0)
    setRect(null)
    // setCropCanvas(null)
    // setCropBox(false)
    setCroppedImageBox(false)
    setSelectedImage('')
    setActiveOCRPreview('ETC')
    setCroppedImagePreview([])
    setCroppedOCRImagePreviewOCR([])
  }, [question_id])
    
  useEffect(() => {

    return () => {
      dispatch(editorActions.init_all_data(initAllStore))
      dispatch(writeEditorActions.set_init_state())
      dispatch(sidebarActions.setBubbleTalk({})) // 사이드바 게시글 말풍선 content
      dispatch(sidebarActions.isBubbleTalk(false)) // 사이드바 게시글 말풍선 true or false
    }
  }, [])


  const [ cropBox, setCropBox ] = useState(false) // 이미지 크롭 영역
  const [ croppedImageBox, setCroppedImageBox ] = useState(false) // 크롭된 이미지 영역
  const [ selectedImg, setSelectedImg ] = useState(null) // 클릭된 이미지
  const [ selectedImgSize, setSelectedImgSize ] = useState([]) // 클릭된 이미지 사이즈

  useEffect(() => {
    if (selectedImg) {
      let img = new Image()
      img.src = selectedImg
      setSelectedImgSize(img.width, img.height)
    }
  }, [selectedImg])

  const debouce = _.debounce(e => {
    setWorkspaceScrollY(e.target.scrollTop)
  }, 300)

  const handleScroll = useCallback(e => debouce(e), [image])

  useEffect(() => {
    const workspaceImage = document.getElementById('image_box');

    if (!workspaceImage) return
    workspaceImage.addEventListener('scroll', (e) => debouce(e))
    return () => {
      workspaceImage.removeEventListener('scroll', handleScroll)
    }
  }, [image])

  useEffect(() => {

    if (selectedImgSize.length > 0) {      
      const canvas = document.getElementById('canvas_image')
    }
  }, [selectedImgSize.length])
  
  const handleClickImage = (img) => {
    setImage(img)
    setSelectedImg(img)
    setCropBox(true)
    handlePreview(true)
    // handleSelectedImage(img)
  }
  const handleAllClose = useCallback(() => {
    removeRect()
    setWorkspaceScrollY(0)
    setCropBox(false)
    setCroppedImageBox(false)
  }, [cropBox, croppedImageBox])
  
  const handlePreview = useCallback((e) => {
    setCroppedImageBox(e)
  }, [croppedImageBox])

  const handleCloseCropEditor = () => {
    removeRect()
    setSelectedImg(null)
    // setCropCanvas(null)
    setWorkspaceScrollY(0)
    setCropBox(false)
    // handleSelectedImage('')
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const { mutate } = useMutation(postCropImageAPI, {
    onSuccess: data => {
      if (activeOCRPreview === 'ETC') handleCroppedImagePreview([...croppedImagePreview, data])
      if (activeOCRPreview === 'OCR') handleCroppedImagePreviewOCR([...croppedImagePreviewOCR, data])
    },
    onError: error => {
      console.log(error)
    }
  })

  const ref = useRef()
  const [ selectedImage, setSelectedImage ] = useState('') // 크롭될 이미지
  const [ activeOCRPreview, setActiveOCRPreview ] = useState('ETC') // OCR preview 모달이 활성화되었을 때 === true
  const [ croppedImagePreview, setCroppedImagePreview ] = useState([]) // 크롭된 일반 이미지들
  const [ croppedImagePreviewOCR, setCroppedOCRImagePreviewOCR ] = useState([]) // 크롭된 OCR 예정인 이미지들
  const [ recLoading, setRecLoading ] = useState(false)

  const onClickRecOcr = async () => {
    if (is_rec_ocr_loading || croppedImagePreviewOCR.length === 0) return
    // setRecLoading(true)
    // console.log(croppedImagePreviewOCR)
    dispatch(writeEditorActions.postExplanationOcrContentAPI(question_id, croppedImagePreviewOCR))
  }

  // const handleSelectedImage = useCallback((e) => {
  //   setSelectedImage(e)
  // }, [selectedImage])

  const handleOcrToggleButton = (event, newAlignment) => {
    if (newAlignment !== null) {
      setActiveOCRPreview(newAlignment)
    }
  }

  const handleCroppedImagePreview = useCallback((e) => {
    setCroppedImagePreview(e)
  }, [croppedImagePreview])

  const handleCroppedImagePreviewOCR = useCallback((e) => {
    setCroppedOCRImagePreviewOCR(e)
  }, [croppedImagePreviewOCR])

  const deletePreviewImage = useCallback((img) => {
    let currentPreviewList
    if (activeOCRPreview === 'ETC') currentPreviewList = croppedImagePreview
    if (activeOCRPreview === 'OCR') currentPreviewList = croppedImagePreviewOCR
    const newPreviewList = currentPreviewList.filter(el => el !== img)
    if (activeOCRPreview === 'ETC') setCroppedImagePreview(newPreviewList)
    if (activeOCRPreview === 'OCR') setCroppedOCRImagePreviewOCR(newPreviewList)
  }, [activeOCRPreview, croppedImagePreview, croppedImagePreviewOCR])

  const handleCropImage = () => {
    if (rect === null) return
    const result = readyForJSON()
    const data = {
      image_data: result,
      image_url: image,
    }
    mutate(data)
  }  

  const readyForJSON = () => {
    let clonedCanvas = _.cloneDeep(cropCanvas)
    clonedCanvas._objects[0].www = imageSize[0]
    
    // const propertyToJSON = ['id', 'page', 'related_id', 'question_id', 'aCoords']
    const propertyToJSON = ['id', 'aCoords']
    clonedCanvas.includeDefaultValues = false
    const raw_json = JSON.stringify(clonedCanvas.toJSON(propertyToJSON))
    // const fabric_data = JSON.stringify(clonedCanvas)
    const json_data = raw_json.split(']}')[0]
    const json = `${json_data}],"width":${imageSize[0]},"height":${imageSize[1]},"id":"${uuidv4()}"}`
    // console.log(json)
    return json
  }

  // 최종 저장 -------------------------------------------------------<<<
  const { mutate: saveAllData } = useMutation(postSaveAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'labeling success') {
        alert('저장이 완료되었습니다.')

        queryClient.invalidateQueries("alarmList")
        
        if (_authority !== '0') {
          const beforeUrl = origin_history_url.slice(-2)[0]

          const checkPath = (searchString, length?) => { // length: 검색을 시작할 위치 (선택)
            if (length) return beforeUrl.includes(searchString, length)
            if (!length) return beforeUrl.includes(searchString)
          }
        
          const checkDetailedPath = (searchString, length) => {
            const dividedPathList = beforeUrl.split('/')
            const booleanList = searchString.map((el, index) => {
              return dividedPathList[length[index]] === searchString[index]
            })
            const isSearched = booleanList.findIndex(v => v === false)
            return isSearched === -1
          }

          if (checkPath('workspace/project/labeling') && checkDetailedPath(['content', 'question'], [5, 7])) {
            navigate(-1)
          }
        }
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  // ------------------------------------------------

  const { mutate: mutate_save_related_text } = useMutation(postAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.data === 'success') {
        alert('저장이 완료되었습니다.')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const handleSaveRelatedText = () => {
    if (!related_text_html) return alert('지문을 작성해 주세요')
    if (window.confirm('변경 내용을 저장하시겠습니까?') === false) return

    let data = {
      apiURL: `/related-text/${question_id}`,
      body: {html: related_text_html}
    }

    mutate_save_related_text(data)
  }

  const handleSave = async () => {
    // 지문 저장인 경우
    if (data_type === 'related_text') {
      handleSaveRelatedText()
      return
    }

    const essay_answer_list_epmty = essay_answer_list.map(el => {
      if (el === '<p></p>' || el === '') {
        return 1
      } else {
         return 0
      }
    }).reduce((a, b) => a + b, 0)

    let arr = null;
    let isChecked = true;

    if (is_multiple === 1) {
      const text = multiple_html_raw
      arr = text.match(/(?<=\<div class="pr-multiple_box_content_wrap">)(.*?)(?=<\/div><\/div>)/g);
      if (!arr) arr = text.match(/(?<=\<div class="pr-multiple_box_content_wrap">)(.*?)(?=<\/div> <\/div>)/g);
      if (!arr) return alert('보기에 문제가 발생했습니다.');
      
      let columnCount = 0;
  
      arr.forEach((e, index) => {
        const count = e.match(/<div class="pr-multiple_box_content">/g)?.length;
        if (index === 0) return columnCount = count;
        if (columnCount !== count) return isChecked = false;
      })
    }

    if (is_multiple === 1 && !arr) return alert('보기를 입력해 주세요.')
    if (window.confirm('변경 내용을 저장하시겠습니까?') === false) return
    if (is_multiple === 0 && essay_answer_list_epmty > 0) return alert('주관식 정보를 모두 입력해 주세요.')
    if (is_multiple === 1 && !isChecked && (_nation === '0' || _nation === '1')) return alert('보기를 모두 입력해 주세요.')
    if (is_multiple === 1 && multiple_answer_list.length === 0 && (_nation === '0' || _nation === '1')) return alert('정답을 선택해 주세요.')
    // 기출 해설 라벨링이 추가됨
    if (is_explanation === 1 && !explanation_html) return alert("해설을 입력해 주세요. 해설이 없다면 해설 '없음'을 선택해 주세요.")

    let result = {
      question_id: question_id,  // 필수
      q_markdown: data_html,  // 필수
      s_markdown: is_multiple === 1 ? multiple_html_raw : null,
      difficulty: difficulty,
      answer: is_multiple === 1 ? multiple_answer_list : essay_answer_list,  // 필수
    }

    if (is_books || (_authority === '0' || _authority === '4')) result = {...result, e_markdown: is_explanation === 1 ? explanation_html : `<p>해설 없음</p>`}

    console.log(result)
    saveAllData(result)
  }

  // ------------------------------------------------

  const onImgLoad = ({ target: img }) => {
    const canvas = document.getElementById('canvas_image')
    setImageSize([canvas.clientWidth, canvas.clientHeight])
    
    if (cropCanvas) {
      cropCanvas.setDimensions({width: canvas.clientWidth, height: canvas.clientHeight});
    }
  }

  const [ isModal, setIsModal ] = useState(false)
  const closeModal = () => {
    setIsModal(false)
  }
  const handlePreviewMobile = () => {
    setIsModal(true)
  }

  // ctrl + P
  document.onkeydown = (e) => {
    if (e.keyCode === 80 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
      e.preventDefault()
      handlePreviewMobile()
    }
  }

  const moveToSecond = async () => {
    navigate(`/workspace/project/mid-unit/test-paper/question/correcting/${question_id}`)
  }

  const handleReset = () => {
    dispatch(writeEditorActions.postQuestionResetAPI(question_id))
  }

  const canvasRef = useRef(null)
  const [ isEditing, setIsEditing ] = useState(false)
  const initCanvasForText = async (img, moreLong) => {
    // handleClickImage(img)

    // setImageSize()
    const width = document.getElementById('image_box').width
    console.log(width)
    setIsEditing(true)
    initTextCanvas(img, moreLong)
  }
  const exitTextEditSpace = () => {
    setIsEditing(false)
  }

  const getInputColor = (e) => {
    const color = e.target.value
  }


  return (
    <Wrap container spacing={2} >
      {is_loading ? (
        <Skeleton />
      ) : (
        <>
          {/* 이미지 */}
          <Grid item xs={12} sm={6} md={6} lg={6} >
            <Item sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {image_data && imageUrlList.map((img, idx) => {
                return img && (
                  <ImageContainer key={String(idx)}>
                    <Images 
                      key={img}
                      src={img} 
                      className='image'
                      // width="100%"  
                    />
                    <div className='back_modal'>
                      <div className='btn preview' onClick={() => handlePreview(true)}>Preview</div>
                      <div className='btn all' onClick={() => handleClickImage(img)}>Crop</div>
                    </div>
                  </ImageContainer>
                )
              })}
            </Item>
          </Grid>

{/* 프리뷰 */}
          {/* <Grid item xs={12} sm={4} md={4} lg={4} > */}
            {/* <Item> */}
              {/* <EditPreview ref={tx} data={data} /> */}
              {/* <ReEditPreview ref={tx} data={text_data} /> */}
            {/* </Item> */}
          {/* </Grid> */}

{/* 작업공간 */}
          <Grid item xs={12} sm={6} md={6} lg={6} >
            <ItemB >
              <EditArea />
            </ItemB>
          </Grid>

{/* 자른 이미지 프리뷰 */}
          <Boxing item xs={12} sm={4} md={4} lg={4} visible={`${croppedImageBox}`} sx={{zIndex: '10', position: 'absolute', left: 0, width: '100%'}}>
            <ItemA sx={{position: 'relative', display: 'flex', flexDirection: 'column', gap: '0.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', overflow: 'visible'}}>
              <div className='preview_wrap'>
                <Title className='preview_box'>
                  <BtnBox>
                    <div className='title'>Preview</div>
                    {/* {is_books && */}
                      <div className='toggleBox'>
                        {activeOCRPreview === 'OCR'
                          && (
                            <div className='ocrButton' onClick={onClickRecOcr}>
                              <div className={[
                                'recCircle',
                                is_rec_ocr_loading ? 'onRec' : 'offRec'
                              ].join(' ')}></div>
                              <div className='recText'>OCR</div>
                            </div>
                          )
                        }
                        
                        <ToggleButtonGroup
                          color="primary"
                          value={activeOCRPreview}
                          exclusive
                          onChange={handleOcrToggleButton}
                          aria-label="Platform"
                          style={{height: '2.3rem', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px'}}
                        >
                          <ToggleButton value="OCR" style={{fontSize: '0.7rem'}}>OCR</ToggleButton>
                          <ToggleButton value="ETC" style={{fontSize: '0.7rem'}}>ETC</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    {/* } */}
                    <BtnBox02>
                      <BackToList onclick={handleAllClose} height={'2.3rem'} fontWeight={400} >Exit</BackToList>
                    </BtnBox02>
                  </BtnBox>
                </Title>
                
                <Preview
                  ref={ref}
                  imageList={
                    activeOCRPreview === 'ETC'
                    ? croppedImagePreview
                    : croppedImagePreviewOCR
                  }
                  // setImageList={
                  //   activeOCRPreview === 'ETC'
                  //   ? handleCroppedImagePreview
                  //   : handleCroppedImagePreviewOCR
                  // }
                  setImageList={
                    activeOCRPreview === 'ETC'
                    ? setCroppedImagePreview
                    : setCroppedOCRImagePreviewOCR
                  }
                  handleClickImage={initCanvasForText}
                  // handleClickImage={handleClickImage}
                  deletePreviewImage={deletePreviewImage}
                />
              </div>
            </ItemA>
          </Boxing>

{/* Workspace */}
          {/* {cropBox && */}
            <WorkspaceCom 
              cropBox={cropBox}
              imageUrlList={imageUrlList}
              rect={rect} 
              cropImage={handleCropImage} 
              createRect={createRect} 
              isEditing={isEditing} 
              selectedImg={selectedImg} 
              selectedImgSize={selectedImgSize} 
              onImgLoad={onImgLoad} 
              getInputColor={getInputColor} 
              exitTextEditSpace={exitTextEditSpace} 
              handleCloseCropEditor={handleCloseCropEditor} 
            />
          {/* }  */}
          
          {/* 디바이스 프리뷰 */}
          {/* <Fab 
            onClick={handlePreviewMobile}
            color="secondary" 
            aria-label="edit"
            sx={{position: 'fixed', right: '2.6rem', bottom: '7.2rem'}} 
          >
            <PhoneIphoneIcon />
          </Fab> */}

          {/* 문제 리셋 */}
          {/* <Fab
            onClick={handleReset} 
            color="error" 
            sx={{position: 'fixed', right: '2.6rem', bottom: '11.4rem', fontSize: '0.7rem', fontWeight: '600'}} 
          >
            Reset
          </Fab> */}
          {/* {_authority === '0' && (
            <Fab 
              onClick={moveToSecond}
              color="secondary" 
              aria-label="edit"
              sx={{position: 'fixed', right: '2.6rem', bottom: '7.2rem'}} 
            >
              <LooksTwoIcon />
            </Fab>
          )} */}
          
          <Fab
            id='handleSaveButton'
            onClick={handleSave} 
            color="primary" 
            sx={{position: 'fixed', right: '2.6rem', bottom: '3rem', fontWeight: '600'}} 
          >
            저장
          </Fab>
        </>
      )}

      {isModal &&
        <LayoutModal open={isModal} close={closeModal} type={'devicePreview'} />
      }
      
    </Wrap>
  )
}
const Wrap = styled(Grid)`
  position: relative;
  height: 100%;

  /* overflow: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  } */
`
const Boxin = styled(Grid)`
  display: ${props => props.visible === 'true' ? 'grid' : 'none'};
  transition: all 180ms ease;
`
const Boxing = styled(Grid)`
  /* opacity: ${props => props.visible === true ? 1 : 0};
  z-index: ${props => props.visible === true ? 2 : -10}; */
  display: ${props => props.visible === 'true' ? 'grid' : 'none'};
  transition: all 180ms ease;
`
const TestPaper = styled.div`  
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transition: 150ms ease;
  background-color: transparent;
  overflow-y: auto;
`
const ImageOrigin = styled.img`
  position: absolute;
  width: ${props => props.width};
  height: ${props => props.height};
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* z-index: 2; */
`
const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  /* right: 0;
  bottom: 0; */
  width: 100%;
  height: 100%;
  z-index: 100;
  /* background: red; */
  /* border: 1px solid red; */
`
const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  width: 100%;
  flex: 1;
  padding: 0.8rem;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Item = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  /* -ms-overflow-style: none; 
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none; 
  } */
`
const ItemA = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: hidden;
  /* overflow-y: ${props => props.hidden ? props.hidden : 'auto'}; */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .preview_wrap {
    padding-top: 4rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    .preview_box {
      margin: 0 1rem;
      position: absolute;
      top: 1rem;
      left: 0rem;
      right: 0rem;
      background-color: white;
    }
  }
`
const ItemB = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  /* overflow-y: ${props => props.hidden ? props.hidden : 'auto'}; */
`
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  /* border: 1px solid red; */

  .back_modal {
    position: absolute;
    opacity: 0;
    top: 1rem;
    right: 1rem;
    text-align: center;
    transition: .1s ease;
    /* transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); */

    display: flex;
    /* flex-direction: column; */
    gap: 0.5rem;
  }
  .btn {
    color: #111;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    padding: 0.4rem 0.8rem;
    transition: .26s ease;

    :hover {
      color: white;
      border: none;
      background-color: #2e6ef2;
      border: 1px solid #2e6ef2;
      transition: none;
      cursor: pointer;
    }
  }

  :hover .image {
    opacity: 0.3;
  }
  :hover .back_modal {
    opacity: 1;
  }
`
const Images = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  /* height: 100%; */
  transition: .5s ease;
  backface-visibility: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
`
const Title = styled.div`
  /* width: 100%; */
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 100;
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */

  .title {
    width: 100%;
    font-size: 1.1rem;
  }
  .toggleBox {
    position: relative;
    display: flex;
    gap: 0.6rem;
    align-items: center;
  }
  .ocrButton {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -120%);
    padding: 0.25rem 0.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    border-radius: 1rem;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    color: white;
    background-color: black;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .onRec {
    background-color: red;
  }
  .offRec {
    background-color: #ddd;
  }
  .recCircle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50rem;
  }
  .recText {
    font-size: 0.95rem;
    font-weight: 
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`
const Toolbar = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;

  .tool {
    padding: 0.3rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .tool__crop_inactive, .tool__cutting_box_inactive {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }

  .tool img path {
    stroke: '#fff';
  }
`

export default Editor