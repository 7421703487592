import styled from 'styled-components'
import { Card, Paper } from '@mui/material';

export const CardPadding = styled(Paper)`
  position: relative;
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);

  & textarea {
    resize: none;
    text-decoration: none;

    cursor: pointer;

    width: 100%;
    min-height: 100px;
    max-height: 500px;
    padding: 20px 25px;
    font-size: 16px;
    background: #ffffff;
    border-radius: 6px;
    border: ${props => props.border ? props.border : '2px solid #dddddd'};

    :focus {
      outline: none;
      border: 2px solid #aaaaaa;
    }
  }
`;
export const Block = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
`
export const Wrap = styled.div`
  margin: 0 auto;
  width: 100%;

  h2 {
    padding-top: 20px;
    font-size: 20px;
    font-weight: 800;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[name=userid]::-ms-input-placeholder { /* 인터넷 익스플로러 */
    font-size: 14px;
  }
  input[name=userid]::placeholder {
    font-size: 14px;
  }
  /* border: 1px solid red; */

  .wrapwrap {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding: 30px 0;
  }
`
export const Header = styled.div`
  padding: 0px 40px 20px;
  h1 {
    color: #222222;
    font-size: 20px;
    font-weight: 600;
  }
`
export const Grid = styled.form`
  margin: 30px 0 30px;
  width: 100%;
  /* height: 100%;
  overflow: auto; */

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto 400px;
  column-gap: 25px;
  row-gap: 30px;

  /* border: 1px solid red; */
`
export const DatePickerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`
export const Div = styled.div`
  height: ${({ height }) => height};

  display: ${({ display }) => display || 'flex'};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};
`
export const P = styled.p`
  position: relative;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  height: ${({ height }) => height};

  font-size: ${({ fontSize }) => fontSize || '16px'};

  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  align-self: ${({ alignSelf }) => alignSelf};
  justify-self: ${({ justifySelf }) => justifySelf};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  transform: ${({ transform }) => transform};
`

export const Category = styled.div`
  font-size: 16px;
  text-align: center;
  justify-self: end;
  color: ${({ color }) => color || 'black'};
  & :nth-child(2) {
    font-size: 12px;
  }
`
export const Forms = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  gap: ${props => props.nogap ? 0 : '20px'};
  transition: transform 350ms ease;
  /* border: 1px solid red; */

  .form:nth-child(1) {
    gap: 0;
    margin-top: 0;
  }
  .form:nth-child(n+2) {
    margin-top: 20px;
  }
  .form:nth-child(3) {
    margin-top: ${props => props.state};
  }
  .form:nth-child(4) {
    margin-top: 40px;
  }

  .plus {
    color: #dddddd;
    cursor: pointer;
  }

  .form_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 27px;
    /* border: 1px solid red; */

    .title {
      flex: 1;
      color: #555555;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      direction: rtl;
      /* border: 1px solid red; */
    }
    .input_wrap {
      flex: 3;
      /* border: 1px solid red; */

      .dot_border {
        width: 400px;
        height: 60px;
        padding-left: 25px;

        background: #ffffff;
        border: 2px dashed #dddddd;
        border-radius: 6px;
      }
    }
  }
`
export const PhoneNumWrap = styled.div`
  width: 400px;
  height: 60px;
  /* border: 1px solid red; */

  display: flex;
  justify-content: space-between;
  align-items: center;
`
// export const Input = styled.input`
//   width: 400px;
//   height: 60px;
//   padding-left: 25px;

//   background: #ffffff;
//   border: 2px solid #dddddd;
//   border-radius: 6px;

//   :focus {
//     outline: none;
//     border: 2px solid #aaaaaa;
//   }
// `
export const ImageCount = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #dddddd;

  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .count {
      color: #555555;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    .info {
      color: rgba(0, 0, 0, 0.5);
      font-size: 10px;
      font-weight: 500;
      line-height: 13px;
    }
  }
`
export const AddImages = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid #dddddd;

  display: grid;
  grid-gap: 25px;
	gap: 25px;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(160px, auto));

  .add_img_box {
    width: 160px;
    height: 160px;
    border-radius: 15px;
    border: 2px solid #dddddd;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10.5px;

    & img {
      width: 40px;
      height: 36px;
    }
    .info {
      color: #999999;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
    }
  }
`
export const ImageBox = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 15px;
  background-color: #dddddd;
`
export const Desc = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid #dddddd;

  .wrap {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    /* border: 1px solid red; */

    .title_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        color: #555555;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
      .info {
        color: rgba(0, 0, 0, 0.5);
        font-size: 10px;
        font-weight: 500;
        line-height: 13px;
      }
    }
  }

  & textarea {
    flex: 4;
    padding: 30px;
    min-height: 200px;
    border-radius: 15px;
    border: 2px solid #dddddd;
    resize: none;

    :focus {
      outline: none;
      border: 2px solid #aaaaaa;
    }
  }
`
export const BtnBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  border: 1px solid red;
`
export const RecallBtn = styled.div`
  width: 86px;
  height: 60px;
  color: #444;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 6px;
  margin: 30px auto;
  transition: all 160ms ease;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`
export const Button = styled.div`
  width: 272px;
  height: 60px;
  /* color: #ffffff; */
  color: ${props => props.color ? props.color : '#fff'};
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 6px;
  margin: 30px auto;
  transition: all 360ms ease;
  /* background-color: #2e6ef2; */
  background-color: ${props => props.bgColor ? props.bgColor : '#2e6ef2'};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`
export const CodeInputWrap = styled.div`
  width: 100%;
  overflow: hidden;
  height: ${props => props.height};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
  margin-bottom: ${props => props.mt};
  transition: all 360ms ease;
  /* border: 1px solid red; */

  animation: fadein 360ms;
  -o-animation: fadein 360ms;
  -moz-animation: fadein 360ms;
  -webkit-animation: fadein 360ms;

  @keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @-moz-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @-webkit-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @-o-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
`
export const InfoWrap = styled.div`

  width: 100%;
  overflow: hidden;
  height: ${props => props.height};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* margin-top: ${props => props.mt}; */
  transition: all 1200ms ease;
  /* border: 1px solid red; */

  animation: fadein 1200ms;
  -o-animation: fadein 1200ms;
  -moz-animation: fadein 1200ms;
  -webkit-animation: fadein 1200ms;

  @keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @-moz-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @-webkit-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @-o-keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
`
export const FindResult = styled.div`
  border: 1px solid red;
`