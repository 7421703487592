export const completion = (context) => {
  let word = context.matchBefore(/[\w|ㄱ-힣|=|_|+|`|!]*/)
  // if (!word) return null
  if (word.from == word.to && !context.explicit) return null
  return {
    from: word.from,
    options: [
      { label: "1", type: "", apply: "①", detail: "①" },
      { label: "2", type: "", apply: "②", detail: "②" },
      { label: "3", type: "", apply: "③", detail: "③" },
      { label: "4", type: "", apply: "④", detail: "④" },
      { label: "5", type: "", apply: "⑤", detail: "⑤" },
      { label: "6", type: "", apply: "⑥", detail: "⑥" },
      { label: "7", type: "", apply: "⑦", detail: "⑦" },
      { label: "8", type: "", apply: "⑧", detail: "⑧" },
      { label: "9", type: "", apply: "⑨", detail: "⑨" },
      { label: "10", type: "", apply: "⑩", detail: "⑩" },
      //
      { label: "1", type: "", apply: "ⅰ", detail: "ⅰ" },
      { label: "2", type: "", apply: "ⅱ", detail: "ⅱ" },
      { label: "3", type: "", apply: "ⅲ", detail: "ⅲ" },
      { label: "4", type: "", apply: "ⅳ", detail: "ⅳ" },
      { label: "5", type: "", apply: "ⅴ", detail: "ⅴ" },
      { label: "6", type: "", apply: "ⅵ", detail: "ⅵ" },
      { label: "7", type: "", apply: "ⅶ", detail: "ⅶ" },
      { label: "8", type: "", apply: "ⅷ", detail: "ⅷ" },
      { label: "9", type: "", apply: "ⅸ", detail: "ⅸ" },
      { label: "10", type: "", apply: "ⅹ", detail: "ⅹ" },
      { label: "1", type: "", apply: "Ⅰ", detail: "Ⅰ" },
      { label: "2", type: "", apply: "Ⅱ", detail: "Ⅱ" },
      { label: "3", type: "", apply: "Ⅲ", detail: "Ⅲ" },
      { label: "4", type: "", apply: "Ⅳ", detail: "Ⅳ" },
      { label: "5", type: "", apply: "Ⅴ", detail: "Ⅴ" },
      { label: "6", type: "", apply: "Ⅵ", detail: "Ⅵ" },
      { label: "7", type: "", apply: "Ⅶ", detail: "Ⅶ" },
      { label: "8", type: "", apply: "Ⅷ", detail: "Ⅷ" },
      { label: "9", type: "", apply: "Ⅸ", detail: "Ⅸ" },
      { label: "10", type: "", apply: "X", detail: "X" },
      //
      { label: "ㄱ", type: "", apply: "㉠", detail: "㉠" },
      { label: "ㄴ", type: "", apply: "㉡", detail: "㉡" },
      { label: "ㄷ", type: "", apply: "㉢", detail: "㉢" },
      { label: "ㄹ", type: "", apply: "㉣", detail: "㉣" },
      { label: "ㅁ", type: "", apply: "㉤", detail: "㉤" },
      { label: "ㅂ", type: "", apply: "㉥", detail: "㉥" },
      { label: "ㅅ", type: "", apply: "㉦", detail: "㉦" },
      { label: "ㅇ", type: "", apply: "㉧", detail: "㉧" },
      { label: "ㅈ", type: "", apply: "㉨", detail: "㉨" },
      { label: "ㅊ", type: "", apply: "㉩", detail: "㉩" },
      { label: "ㅋ", type: "", apply: "㉪", detail: "⑩" },
      { label: "ㅌ", type: "", apply: "㉫", detail: "㉫" },
      { label: "ㅍ", type: "", apply: "㉬", detail: "㉬" },
      { label: "ㅎ", type: "", apply: "㉭", detail: "㉭" },

      { label: "가", type: "", apply: "㈎", detail: "㈎" },
      { label: "나", type: "", apply: "㈏", detail: "㈏" },
      { label: "다", type: "", apply: "㈐", detail: "㈐" },
      { label: "라", type: "", apply: "㈑", detail: "㈑" },
      { label: "마", type: "", apply: "㈒", detail: "㈒" },
      { label: "바", type: "", apply: "㈓", detail: "㈓" },
      { label: "사", type: "", apply: "㈔", detail: "㈔" },
      { label: "아", type: "", apply: "㈕", detail: "㈕" },
      { label: "자", type: "", apply: "㈖", detail: "㈖" },
      { label: "차", type: "", apply: "㈗", detail: "㈗" },
      { label: "카", type: "", apply: "㈘", detail: "㈘" },
      { label: "타", type: "", apply: "㈙", detail: "㈙" },
      { label: "파", type: "", apply: "㈚", detail: "㈚" },
      { label: "하", type: "", apply: "㈛", detail: "㈛" },

      { label: "가", type: "", apply: "㉮", detail: "㉮" },
      { label: "나", type: "", apply: "㉯", detail: "㉯" },
      { label: "다", type: "", apply: "㉰", detail: "㉰" },
      { label: "라", type: "", apply: "㉱", detail: "㉱" },
      { label: "마", type: "", apply: "㉲", detail: "㉲" },
      { label: "바", type: "", apply: "㉳", detail: "㉳" },
      { label: "사", type: "", apply: "㉴", detail: "㉴" },
      { label: "아", type: "", apply: "㉵", detail: "㉵" },
      { label: "자", type: "", apply: "㉶", detail: "㉶" },
      { label: "차", type: "", apply: "㉷", detail: "㉷" },
      { label: "카", type: "", apply: "㉸", detail: "㉸" },
      { label: "타", type: "", apply: "㉹", detail: "㉹" },
      { label: "파", type: "", apply: "㉺", detail: "㉺" },
      { label: "하", type: "", apply: "㉻", detail: "㉻" },
      //
      { label: "a", type: "", apply: "ⓐ", detail: "ⓐ" },
      { label: "b", type: "", apply: "ⓑ", detail: "ⓑ" },
      { label: "c", type: "", apply: "ⓒ", detail: "ⓒ" },
      { label: "d", type: "", apply: "ⓓ", detail: "ⓓ" },
      { label: "e", type: "", apply: "ⓔ", detail: "ⓔ" },
      { label: "f", type: "", apply: "ⓕ", detail: "ⓕ" },
      { label: "g", type: "", apply: "ⓖ", detail: "ⓖ" },
      { label: "h", type: "", apply: "ⓗ", detail: "ⓗ" },
      { label: "i", type: "", apply: "ⓘ", detail: "ⓘ" },
      { label: "j", type: "", apply: "ⓙ", detail: "ⓙ" },
      { label: "k", type: "", apply: "ⓚ", detail: "ⓚ" },
      { label: "l", type: "", apply: "ⓛ", detail: "ⓛ" },
      { label: "m", type: "", apply: "ⓜ", detail: "ⓜ" },
      { label: "n", type: "", apply: "ⓝ", detail: "ⓝ" },
      { label: "o", type: "", apply: "ⓞ", detail: "ⓞ" },
      { label: "p", type: "", apply: "ⓟ", detail: "ⓟ" },
      { label: "q", type: "", apply: "ⓠ", detail: "ⓠ" },
      { label: "r", type: "", apply: "ⓡ", detail: "ⓡ" },
      { label: "s", type: "", apply: "ⓢ", detail: "ⓢ" },
      { label: "t", type: "", apply: "ⓣ", detail: "ⓣ" },
      { label: "u", type: "", apply: "ⓤ", detail: "ⓤ" },
      { label: "v", type: "", apply: "ⓥ", detail: "ⓥ" },
      { label: "w", type: "", apply: "ⓦ", detail: "ⓦ" },
      { label: "x", type: "", apply: "ⓧ", detail: "ⓧ" },
      { label: "y", type: "", apply: "ⓨ", detail: "ⓨ" },
      { label: "z", type: "", apply: "ⓩ", detail: "ⓩ" },
      //
      { label: "A", type: "", apply: "Ⓐ", detail: "Ⓐ" },
      { label: "B", type: "", apply: "Ⓑ", detail: "Ⓑ" },
      { label: "C", type: "", apply: "ⓒ", detail: "ⓒ" },
      { label: "D", type: "", apply: "Ⓓ", detail: "Ⓓ" },
      { label: "E", type: "", apply: "Ⓔ", detail: "Ⓔ" },
      //
      //
      { label: "알파, alpha", type: "", apply: "$\\alpha$", detail: "α" },
      { label: "베타, beta", type: "", apply: "$\\beta$", detail: "β" },
      { label: "델타, delta", type: "", apply: "$\\delta$", detail: "δ" },
      { label: "감마, gamma", type: "", apply: "$\\gamma$", detail: "γ" },
      { label: "람다, lambda", type: "", apply: "$\\lambda$", detail: "λ" },
      { label: "파이, pi", type: "", apply: "$\\pi$", detail: "π" },
      { label: "세타, theta", type: "", apply: "$\\theta$", detail: "θ" },
      //
      { label: "리미트, lim", type: "", apply: "$\\lim\\limits_{x\\to1}$", detail: "lim" },
      { label: "루트", type: "", apply: "$\\sqrt[3]{x}$", detail: "∛x" },
      { label: "각도, 앵글, angle", type: "", apply: "$\\angle$", detail: "∠" },
      { label: "분수, frac", type: "", apply: "$\\frac{1}{2}$", detail: "¼" },
      // { label: "분수, dfrac", type: "", apply: "$\\dfrac{1}{2}$", detail: "¼" },
      { label: "점, 원, 총알", type: "", apply: "$\\bullet$", detail: "∙" },
      { label: " 점, 원, 각도, circ", type: "", apply: "$\\circ$", detail: "∘" },
      //
      { label: "무한", type: "", apply: "$\\infty$", detail: "∞" },
      { label: "나눗셈", type: "", apply: "$\\div$", detail: "÷" },
      { label: "마플, 플러스, 마이너스", type: "", apply: "$\\mp$", detail: "-+" },
      { label: "플마, 플러스, 마이너스", type: "", apply: "$\\pm$", detail: "+-" },
      { label: "=, 같지않음", type: "", apply: "$\\not=$", detail: "≠" },
      { label: "따라서, 결론, 결과, 마무리, 정답, 그러므로", type: "", apply: "$\\therefore$", detail: "∴" },
      { label: "곱셈", type: "", apply: "$\\times$", detail: "⨉" },
      //
      { label: "글자, 네모, 박스", type: "", info: "네모안 글자", apply: "$\\boxed{A}$", detail: "🔠" },
      { label: "글자, over, 화살표, 양방향", type: "", info: "글자 위 화살표", apply: "$\\overleftrightarrow{A}$", detail: "⬅➡/AB" },
      { label: "글자, over, 선, 줄", type: "", info: "글자 위 선", apply: "$\\overline{A}$", detail: "⎯/AB" },
      { label: "글자, over, 오른쪽", type: "", info: "글자 위 화살표", apply: "$\\overrightarrow{A}$", detail: "➡️/AB" },
      { label: "글자, over, 왼쪽", type: "", info: "글자 위 화살표", apply: "$\\overrightarrow{A}$", detail: "⬅/AB" },
      { label: "글자, over, 반원", type: "", info: "글자 위 반원", apply: "$\\overset{\\frown}{A}$", detail: "ᴖ/AB" },
      //
      { label: "적분, 인테그랄", type: "", apply: "$\\int_{i=1}^n$", detail: "∫" },
      { label: "시그마", type: "", apply: "$\\sum_{i=1}^n$", detail: "Σ" },
      //
      { label: "첨자, 위 첨자, 제곱", type: "", apply: "$\\A^{x}$", detail: "\\A^{x}" },
      { label: "첨자, 아래 첨자", type: "", apply: "$\\A_{x}$", detail: "\\A^{x}" },
      //
      { label: "집합, union, 합집합", type: "", apply: "$\\cup$", detail: "\\cup" },
      { label: "집합, intersection, 교집합", type: "", apply: "$\\cap$", detail: "\\cap" },
      { label: "집합, 포함", type: "", apply: "$\\in$", detail: "∈" },
      { label: "집합, 포함", type: "", apply: "$\\ni$", detail: "∋" },
      { label: "집합, 포함", type: "", apply: "$\\notin$", detail: "/∈" },
      { label: "집합, 포함", type: "", apply: "$\\subset$", detail: "⊂" },
      { label: "집합, 포함", type: "", apply: "$\\subseteq$", detail: "⊆" },
      { label: "집합, 포함", type: "", apply: "$\\supset$", detail: "⊃" },
      { label: "집합, 포함", type: "", apply: "$\\supseteq$", detail: "⊇" },
      { label: "집합, 공집합", type: "", apply: "$\\varnothing$", detail: "∅" },
      //
      { label: "부등호", type: "", apply: "$\\gt$", detail: ">" },
      { label: "부등호", type: "", apply: "$\\lt$", detail: "<" },
      { label: "부등호", type: "", apply: "$\\geq$", detail: "≥" },
      { label: "부등호", type: "", apply: "$\\leq$", detail: "≤" },
      //
      { label: "arrow, 화살표", type: "", apply: "$\\downarrow$", detail: "⬇️" },
      { label: "arrow, 화살표", type: "", apply: "$\\gets$", detail: "⬅" },
      { label: "arrow, 화살표", type: "", apply: "$\\leftrightarrow$", detail: "⬅➡" },
      { label: "arrow, 화살표", type: "", apply: "$\\to$", detail: "➡" },
      { label: "arrow, 화살표", type: "", apply: "$\\uparrow$", detail: "⬆️" },
      //
      { label: "arrow, 화살표", type: "", apply: "$\\iff$", detail: "⟺" },
      { label: "arrow, 화살표", type: "", apply: "$\\impliedby$", detail: "⟸" },
      { label: "arrow, 화살표", type: "", apply: "$\\implies$", detail: "⟹" },
      //
      { label: "도형, 원", type: "", apply: "○", detail: "○" },
      { label: "도형, 네모", type: "", apply: "□", detail: "□" },
      { label: "도형, 세모", type: "", apply: "△", detail: "△" },
      { label: "도형, 별", type: "", apply: "$\\bigstar$", detail: "★" },
      { label: "도형, 네모", type: "", apply: "$\\blacksquare$", detail: "■" },
      { label: "도형, 클로버", type: "", apply: "$\\clubsuit$", detail: "♣" },
      { label: "도형, 하트", type: "", apply: "$\\heartsuit$", detail: "♡" },
      { label: "도형, 스페이드", type: "", apply: "$\\spadesuit$", detail: "♠" },
      { label: "도형, 세모", type: "", apply: "$\\triangleright$", detail: "▹" },
      //
      { label: "타입, ```", type: "", apply: "```\n", detail: "('')" },
      { label: "타입, ```보기", type: "", apply: "```보기\n", detail: "('보기')" },
      { label: "타입, ```예시", type: "", apply: "```예시\n", detail: "('예시')" },
      { label: "타입, 이미지, !", type: "", apply: "![30]()", detail: "(30 이미지)" },
      { label: "타입, 이미지, !", type: "", apply: "![60]()", detail: "(60 이미지)" },
      { label: "타입, 이미지, !", type: "", apply: "![90]()", detail: "(90 이미지)" },
      { label: "타입, 테이블, 표", type: "", apply: "|||\n|||\n|||", detail: "테이블" },

      // { label: "hello", type: "variable", info: "(World)" },
      // { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" },
    ],
  }
}

export const stringToData = (string) => {
  const rx_highlight = /(^|[^A-Za-z\d\\])(([*_])|(~)|(\^)|(--)|(\+\+))(\2?)([^<]*?)\2\8(?!\2)(?=\W|_|$)/g
  const block_basic = /(?=^|>|\n)\s*\n+([^<|!]+?)\n+\s*(?=\n|<|$)/gm
  const block_border = /\n^```(?:(.*))\n([\s\S]*?)\n$/gm
  const block_img = /\n((!)\[([0-9]+?)\]\((.*?)( ".*")?\)|\\([\\`*_{}\[\]()#+\-.!~]))\n/g
  const rx_table = /\n(( *\|.*?\| *\n)+)/g
  const rx_thead = /^.*\n( *\|( *\:?-+\:?-+\:? *\|)* *\n|)/
  const rx_row = /.*\n/g
  const rx_cell = /\||(.*?[^\\])([\|]+)/g

  const rx_escape = /\\([\|`*_{}\[\]()#+\-~])/g
  const rx_stash = /-\d+\uf8ff/g

  const element = (tag, content, attr = {}) => {
    return (
      "<" +
      tag +
      " " +
      Object.entries(attr)
        .map(([k, v]) => k + "='" + v + "'")
        .join(" ") +
      ">" +
      content +
      "</" +
      tag +
      ">"
    )
  }

  const highlight = (src) => {
    return src.replace(rx_highlight, function (all, _, p1, emp, sub, sup, small, big, p2, content) {
      return _ + element(emp ? (p2 ? "strong" : "u") : sub ? (p2 ? "s" : "sub") : sup ? "sup" : small ? "small" : big ? "big" : "code", highlight(content))
    })
  }

  const replace = (type, rex, fn) => {
    string = string.replace(rex, fn)
  }

  const unesc = (str) => {
    return str.replace(rx_escape, "$1")
  }

  // {
  //   "id":23881,
  //   "answer_type":0,
  //   "answer":"3",
  //   "sub_sections":[
  //     {
  //       "id":23993,
  //       "sub_section_name":"동아시아의 국민 국가 건설 운동"
  //     }
  //   ],
  //   "select":{
  //     "id":14588,
  //     "select_1":"삼민주의를 혁명의 이념으로 삼아 무장 봉기를 시작하였다.",
  //     "select_2":"청의 위신이 하락하여 대규모 반봉건 농민 운동이 일어났다.",
  //     "select_3":"일부 지식인들이 중국의 정치 제도를 개혁하는 운동을 추진하였다.",
  //     "select_4":"서양 무기의 우수성을 알게 되면서 서양의 무기를 도입하자는 운동이 일어났다.",
  //     "select_5":"일본의 중국에 대한 침략이 심해져서 이에 반대하는 반제국주의 운동이 일어났다.",
  //     "is_image":0
  //   },
  //   "category":{
  //     "order":7,
  //     "score":1,
  //     "difficult":null,
  //     "question_error":0,
  //     "completed":0,
  //     "question": [
  //       {
  //         "box": 0,
  //         "content": "",

  //       }
  //     ]
  //   }
  // }

  var stash = []
  var si = 0
  string = "\n" + string + "\n"

  // border
  replace("border", block_border, (p1, p2, p3) => {
    return `<start class='border' data-name='${p2}'>${highlight(p3)}</end>`
  })

  // table
  replace("table", rx_table, function (all, table) {
    var sep = table.match(rx_thead)[1]

    return (
      "\n" +
      "<start class='table'>" +
      element(
        "table",
        table.replace(rx_row, function (row, ri) {
          return row == sep
            ? ""
            : element(
                "tr",
                row.replace(rx_cell, function (all, cell, ci, d) {
                  let colspan = 1
                  if (typeof ci == "string" && ci.length > 1) colspan = ci.length
                  return ci ? element(sep && !ri ? "th" : "td", unesc(highlight(cell || "")), colspan > 1 ? { colspan } : {}) : ""
                }),
              )
        }),
      ) +
      "</end>"
    )
  })

  replace("image", block_img, function (all, p1, p2, p3, p4, p5, p6) {
    return `<start class='image'>${p4}</end>`
  })

  replace("basic", block_basic, (p1, p2) => "<start class='basic'>" + highlight(p2) + "</end>")

  // stash
  replace("stash", rx_stash, function (all) {
    return stash[parseInt(all)]
  })

  let list = []
  const types = {
    basic: 0,
    border: 1,
    example: 2,
    image: 3,
    choice: 4,
    relation: 5,
    table: 6,
  }

  string.replace(/<start class='(\w+)'(( data-name='(.*)')?)>([\s|\S]*?)<\/end>/g, (all, type, dataAll1, dataAll2, data, content) => {
    let result = { box: 0, content: "" }
    switch (type) {
      case "basic":
        result = { box: types[type], content }
        break
      case "border":
        switch (data) {
          case "":
            result.box = types[type]
            break
          case "보기":
            result.box = types[type]
            break
          case "예시":
            result.box = types[type]
            break
          default:
            break
        }
        result.content = content
        break
      case "image":
        result = { box: types[type], content }
        break
      case "table":
        result = { box: types[type], content }
        break
      case "relation":
        result = { box: types[type], content }
        break
      default:
        break
    }
    list.push(result)
    return content
  })
  return list
}

export const mardownToString = (string) => {
  const rx_highlight = /(^|[^A-Za-z\d\\])(([*])|(__)|(~)|(--)|(&&)|(\+\+))(\2?)([^<]*?)\2\9(?!\2)(?=\W|_|$)/g

  const block_basic = /(?=^|>|\n)\s*\n+([^<|!]+?)\n+\s*(?=\n|<|$)/gm
  const block_border = /\n^```(?:(.*))\n([\s\S]*?)\n$/gm
  const block_img = /\n((!)\[([0-9]+?)\]\((?:(.{5,})|([0-9]+))( ".*")?\)|\\([\\`*_{}\[\]()#+\-.!~]))\n/g
  // const block_img = /!\[(.*?)\]\((.*?)\)/gim
  const rx_table = /\n(( *\|.*?\| *\n)+)/g
  const rx_thead = /^.*\n( *\|( *\:?-+\:?-+\:? *\|)* *\n|)/
  const rx_row = /.*\n/g
  const rx_cell = /\||(.*?[^\\])([\|]+)/g

  const rx_escape = /\\([\|`*_{}\[\]()#+\-~])/g
  const rx_stash = /-\d+\uf8ff/g

  const element = (tag, content, attr = {}) => {
    return (
      "<" +
      tag +
      " " +
      Object.entries(attr)
        .map(([k, v]) => k + "='" + v + "'")
        .join(" ") +
      ">" +
      content +
      "</" +
      tag +
      ">"
    )
  }

  const highlight = (src) => {
    return src.replace(rx_highlight, function (all, _, p1, u, emp, sub, small, rect, big, p2, content) {
      let result = _
      if (emp) {
        if (p2) {
          result += element("strong", highlight(content))
        } else {
          result += element("u", highlight(content))
        }
      } else {
        if (sub) {
          if (p2) {
            result += element("s", highlight(content))
          } else {
            result += element("sub", highlight(content))
          }
        } else {
          if (small) {
            result += element("small", highlight(content))
          } else {
            if (rect) {
              result += element("span", highlight(content), { class: "rect-text" })
              if (big) {
                result += element("big", highlight(content))
              } else {
                // result += element("code", highlight(content))
              }
            }
          }
        }
      }
      return result
      // return _ + element(emp ? (p2 ? "strong" : "u") : sub ? (p2 ? "s" : "sub") : sup ? "sup" : small ? "small" : big ? "big" : "code", highlight(content))
    })
  }

  const replace = (rex, fn) => {
    string = string.replace(rex, fn)
  }

  const unesc = (str) => {
    return str.replace(rx_escape, "$1")
  }

  var stash = []
  var si = 0
  string = "\n" + string + "\n"

  // border
  replace(block_border, (p1, p2, p3) => {
    return `<div class='block border'><div class='tag'></div><span class='border-name' data-name="${p2}"></span><div class='border-content'>${highlight(p3)}</div></div>`
  })

  // table
  replace(rx_table, function (all, table) {
    var sep = table.match(rx_thead)[1]
    return (
      "\n" +
      "<div class='block table'><div class='tag'></div>" +
      element(
        "table",
        table.replace(rx_row, function (row, ri) {
          return row == sep
            ? ""
            : !ri
            ? element(
                "thead",
                element(
                  "tr",
                  row.replace(/[ ]{2,}/g, "<br/>").replace(rx_cell, function (all, cell, ci, d) {
                    let colspan = 1
                    if (typeof ci == "string" && ci.length > 1) colspan = ci.length
                    if (ci) {
                      if (sep && !ri) {
                        return element("th", unesc(highlight(cell || "")), colspan > 1 ? { colspan } : {})
                      } else {
                        return element("td", unesc(highlight(cell || "")), colspan > 1 ? { colspan } : {})
                      }
                    } else {
                      return ""
                    }
                    // return ci ? element(sep && !ri ? "th" : "td", unesc(highlight(cell || "")), colspan > 1 ? { colspan } : {}) : ""
                  }),
                ),
              )
            : element(
                "tr",
                row.replace(/[ ]{2,}/g, "<br/>").replace(rx_cell, function (all, cell, ci, d) {
                  let colspan = 1
                  if (typeof ci == "string" && ci.length > 1) colspan = ci.length
                  if (ci) {
                    if (sep && !ri) {
                      return element("th", unesc(highlight(cell || "")), colspan > 1 ? { colspan } : {})
                    } else {
                      return element("td", unesc(highlight(cell || "")), colspan > 1 ? { colspan } : {})
                    }
                  } else {
                    return ""
                  }
                  // return ci ? element(sep && !ri ? "th" : "td", unesc(highlight(cell || "")), colspan > 1 ? { colspan } : {}) : ""
                }),
              )
        }),
      ) +
      "</div>"
    )
  })

  replace(block_img, function (all, p1, p2, p3, p4, p5, p6, p7) {
    return `<div class='block image'><div class='tag'></div><img src='${p4 ? p4 : "{%" + p5 + "%}"}' alt='이미지' width='${p3}%'/></div>`
  })

  replace(block_basic, (p1, p2) => "<div class='block basic'><div class='tag'></div>" + highlight(p2) + "</div>")

  // stash
  replace(rx_stash, function (all) {
    return stash[parseInt(all)]
  })

  return string
}

export const dataToHtmlSelect = (data, imageList) => {
  let arr = [...data]
  // [ [" ", ""], ["1", "내용"], ["2", "내용"], ["3", "내용"], ["4", "내용"], ["5", "내용"] ]
  const rows = arr.map((r, ri) => {
    let colspan = 1
    let reverse = [...r].reverse()
    const row = creElStringify(
      "tr",
      reverse
        .map((c, ci) => {
          if (c) {
            const cell = creElStringify(ri ? "td" : "th", imageToTag(textAlign(highlight(c)), imageList), { colspan })
            colspan = 1
            return cell
          }
          colspan += 1
          return ""
        })
        .reverse()
        .join(" "),
    )
    return row
  })
  return creElStringify("table", creElStringify("thead", rows[0]) + creElStringify("tbody", rows.slice(1).join("")), { style: "width: 100%" })
}

export const dataToSelect = (data) => {
  const rows = data
    .map((r, ri) => {
      return "|" + r.join("|") + "|"
    })
    .join("\n")
  return rows
}
// createElement
export const creElStringify = (tag, content, attr = {}) => {
  let attrStr = Object.entries(attr)
    // .map(([k, v]) => k + "='" + v + "'")
    .map(([k, v]) => `${k}='${v}'`)
    .join(" ")
  return `<${tag} ${attrStr}>${content}</${tag}>`
}

export const regex = {
  rx_img: /((!)\[([0-9]+?)\]\((?:(.{5,})|([0-9]+))( ".*")?\)|\\([\\`*_{}\[\]()#+\-.!~]))/gm,
}

export const mdToHtml = (mdStr, imageList) => {
  const rx_block = /(?=^|>|\n)\s*\n+((?=(`{1,3})|((?:[^\r\n|]*\|)+\r?\n\|(?::?-+:?\|)+\r?\n\|(?:(?:[^\r\n|]*)\|)+\r?\n)|(!\[(\d+)\]\(([^\n]+)\))|(.*))([^<]+?))\n+\s*(?=\n|<|$)/gm
  const rx_border = /^```(?:(.*))\n([\s\S]*?)\s$/gm
  const rx_row = /\||([^|]*?[^\\])([\|]+)/g
  const rx_cell = /([^|]*)/g
  const rx_lt = /</g
  const rx_gt = />/g
  var rx_list = /\n( *)(?:[*\-+]||((\d+)|([a-z])|[A-Z])[.)]) +([^]*?)(?=(\n|$){2})/g
  var rx_listjoin = /<\/(ol|ul)>\n\n<\1>/g
  const rx_highlight = /(^|[^A-Za-z\d\\])((\*\*)|(__)|(&&))(\2?)([^<]*?)(\2\6)(?!\2)(?=\W|_|$)/gm
  const rx_align = /^(:?)([\s\S]*?)(:?)$/g

  // const highlight = (src) => {
  //   return src.replace(rx_highlight, function (all, _, p2, p3, p4, p5, p6, content) {
  //     let result = _
  //     if (p3) {
  //       result += ce("strong", highlight(content))
  //     }
  //     if (p4) {
  //       result += ce("u", highlight(content))
  //     }
  //     if (p5) {
  //       result += ce("span", highlight(content), { class: "rect-text" })
  //     }
  //     return result
  //   })
  // }

  const align = (src) => {
    let left = false
    let right = false
    src = src.replace(rx_align, (all, l, content, r) => {
      if (l) left = true
      if (r) right = true
      return content
    })

    return [left && right ? "text-align-center" : left ? "text-align-left" : right ? "text-align-right" : "", src]
  }

  mdStr = mdStr.replace(rx_lt, "&lt;")
  mdStr = mdStr.replace(rx_gt, "&gt;")
  mdStr = "\n" + mdStr + "\n"

  mdStr = mdStr.replace(rx_block, (all, inner, border, table, image, imageWidth, imageSrc, basic) => {
    // let class_block = border ? 'border' : table ? 'table' : image ? 'image' : 'basic'
    let class_block = "basic"
    let content = ""
    let sub_tag = ""

    if (border) {
      class_block = "border"
      all.replace(rx_border, (all_border, p1, p2) => {
        let [text_align, src] = align(p2)
        sub_tag += `<span class='border-name' data-name='${p1}'></span>`
        content = `<div class="border-content ${text_align}">${highlight(src)}</div>`
      })
    }
    if (table) {
      class_block = "table"
      let rows = inner.split("\n")
      rows = rows.map((r) => r.split("|"))
      rows = rows.map((r) => r.slice(1, r.length - 1))
      // let align = rows[1]
      const html = [rows[0]].concat(rows.slice(2)).map((r, ri) => {
        let colspan = 1
        let reverse = [...r].reverse()

        const row = creElStringify(
          "tr",
          reverse
            .map((c, ci) => {
              if (c) {
                const cell = creElStringify(ri ? "td" : "th", highlight(c), { colspan })
                colspan = 1
                return cell
              }
              colspan += 1
              return ""
            })
            .reverse()
            .join(" "),
        )
        return row
      })
      content = creElStringify("table", creElStringify("thead", html[0]) + creElStringify("tbody", html.slice(1).join("")))
    }
    if (image) {
      class_block = "image"
      imageSrc.replace(/^(?:(\d+)|(http.*)|(.+))$/g, (a, arrIdx, url, bs64) => {
        if (arrIdx) {
          content = `<img src='${imageList[arrIdx - 1]}' alt='이미지' width='${imageWidth}%' />`
        }
        if (url) {
          content = `<img src='${url}' alt='이미지' width='${imageWidth}%' />`
        }
        if (bs64) {
          content = `<img src='${bs64}' alt='이미지' width='${imageWidth}%' />`
        }
      })
    }
    if (basic) {
      class_block = "basic"
      let [text_align, src] = align(inner)
      content = `<div class='basic-content ${text_align}'>${highlight(src)}</div>`
    }

    return `\n<div class='block ${class_block}'><div class='tag'></div>${sub_tag}${content}</div>\n`
  })

  return mdStr
}

export const highlight = (src) => {
  return src.replace(/(^|[^A-Za-z\d\\])((\*\*)|(__)|(&&)|(\#\#))(\2?)([^<]*?)(\2\7)(?!\2)(?=\W|_|$)/gm, function (all, _, p2, p3, p4, p5, p6, p7, content) {
    let result = _
    if (p3) {
      result += creElStringify("strong", highlight(content))
    }
    if (p4) {
      result += creElStringify("u", highlight(content))
    }
    if (p5) {
      result += creElStringify("span", highlight(content), { class: "rect-text" })
    }
    if (p6) {
      result += creElStringify("span", highlight(content), { class: "letter-spacing" })
    }
    // if (p6) {
    //   result += ce("span", highlight(content), { class: "text-center" })
    // }
    return result
  })
}
export const textAlign = (str) => {
  return str.replace(/^(:?)([\s\S]*?)(:?)$/gm, (all, l, content, r) => {
    return `<span class='text-line ${l && r ? "text-align-center" : l ? "text-align-left" : r ? "text-align-right" : ""}'>${content}</span>`
  })
}
export const imageToTag = (str, imageList) => {
  return str.replace(/!\[(\d+)\]\(((?:(\d){1,3}|(http.*)|(.+)))\)/g, (all, width, content, index, url, bs64) => {
    let src = ""
    if (index) {
      src = imageList[index - 1]
    }
    if (url) {
      src = url
    }
    if (bs64) {
      src = bs64
    }
    return `<img src='${src}' alt='이미지' width='${width}%'/>`
  })
}

export const splitType = (str) => {
  let arr = []
  str.replace(/```(?:([^\s]*))\n([\s\S]*?)```/gm, (all, type, text) => {
    switch (type) {
      case "basic":
        type = "일반"
        break
      case "":
      case "border":
        type = "선"
        break
      case "example":
        type = "예시"
        break
      case "choice":
        type = "보기"
        break
      case "table":
        type = "표"
        break
      default:
        break
    }
    arr.push({ id: uuidv4(), type, text, html: toHtml(text, type, []) })
  })
  return arr
}

export const joinType = (arr) => {
  return "```" + arr.map(({ type, text }) => type + "\n" + text).join("```\n```") + "```"
}

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
}
export const matchType = (val) => {
  let t = "일반"
  switch (val) {
    case "일반":
      t = "basic"
      break
    case "선":
    case "보기":
    case "예시":
      t = "border"
      break
    case "표":
      t = "table"
      break
  }
  return
}
export const toHtml = (str, type, imageList) => {
  let content = ""
  let t = "basic"
  str = str.replace(/</g, "&lt;")
  str = str.replace(/>/g, "&gt;")
  str = str.replace(/[\n]+/, "\n")

  switch (type) {
    case "일반":
      content = "<div class='basic-content'>" + imageToTag(textAlign(highlight(str)), imageList) + "</div>"
      break
    case "선":
    case "":
    case "보기":
    case "예시":
      content = `<div class='border-name' data-name='${type == "선" ? "" : type}'></div><div class='border-content'>${imageToTag(textAlign(highlight(str)), imageList)}</div>`
      t = "border"
      break
    case "표":
      let arr = []
      str = str + "\n"
      str.replace(/\|([\s\S]+?)\|\n/g, (all, p) => {
        arr.push(p.split("|"))
      })
      content = `
      <table>
        <tbody>
          ${arr
            .map((row, ri) => {
              let colspan = 1
              let rowspan = 1
              let reverse = [...row].reverse()
              return (
                "<tr>" +
                reverse
                  .map((cell, ci) => {
                    if (cell) {
                      let result = "<td colspan='" + colspan + "' rowspan='" + rowspan + "'>" + imageToTag(textAlign(highlight(cell)), imageList) + "</td>"
                      colspan = 1
                      return result
                    }
                    colspan += 1
                    return ""
                  })
                  .reverse()
                  .join("") +
                "</tr>"
              )
            })
            .join("")}
        </tbody>
      </table>
      `
      t = "table"
      break
    default:
      break
  }
  return `<div class='block ${t}'>${content}</div>`
}