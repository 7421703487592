import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { actionCreators as middleUnitActions } from '../../redux/modules/middleUnit'
import { actionCreators as writeEditorActions } from "../../redux/modules/writeEditor"
import { actionCreators as searchActions } from "../../redux/modules/search"
import { useNavigate } from 'react-router-dom'

import Axios from '../../api/api.config'

const SearchBtn = styled(Button)(({ theme }) => ({
  border: '1px solid rgba(255, 255, 255, 0.3)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    border: '1px solid rgba(255, 255, 255, 0.4)'
  },
}))

const SearchBox = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(0.7em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '15ch',
    },
  },
}))

const Search = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const _authority = localStorage.getItem("_authority")
  
  const [ qId, setSearchQuestionId ] = useState('')
  const [ qId_2, setSearchQuestionId02 ] = useState('')
  const [ qId_3, setSearchQuestionId03 ] = useState('')

  const Enter = async (key) => {
    if (key === 'Enter') {
      if (_authority !== '0') { // 어드민이 아닌 경우
        if (!qId && !qId_3) return

        await dispatch(writeEditorActions.set_init_state())
        await dispatch(middleUnitActions.set_init())

        if (qId) {
          await dispatch(searchActions.setSearchKeyword02(qId_2))
          dispatch(writeEditorActions.getOcrContentAPI(qId))
          navigate(`/workspace/project/labeling/content/question/${qId}/correcting`)
        }
        if (qId_3) {
          dispatch(writeEditorActions.getRelatedTextAPI(qId_3))
          navigate(`/workspace/project/labeling/content/related_text/${qId_3}/correcting`)
        }

      } else if (_authority === '0') { // 어드민인 경우
        if (!qId && !qId_2 && !qId_3) return
        
        await dispatch(writeEditorActions.set_init_state())
        await dispatch(middleUnitActions.set_init())

        if (qId) {
          dispatch(writeEditorActions.getOcrContentAPI(qId))
          navigate(`/workspace/project/labeling/content/question/${qId}/correcting`)
        } 
        if (qId_2) {
          const nation_code = qId_2.split('_')[0]

          if (nation_code === 'TH') {
            dispatch(middleUnitActions.getThData(qId_2))
          } else if (nation_code !== 'TH') {
            dispatch(middleUnitActions.getMiddleUnitData(qId_2))
          }
          navigate(`/workspace/project/review/content/question/${qId_2}`)
        }
        if (qId_3) {
          dispatch(writeEditorActions.getRelatedTextAPI(qId_3))
          navigate(`/workspace/project/labeling/content/related_text/${qId_3}/correcting`)
        }
      }
    }
  }

  const handleChange = (type, v) => {
    if (type === 1) {
      setSearchQuestionId(v)
      setSearchQuestionId02('')
      setSearchQuestionId03('')
    } else if (type === 2) {
      setSearchQuestionId('')
      setSearchQuestionId02(v)
      setSearchQuestionId03('')
    } else if (type === 3) {
      setSearchQuestionId('')
      setSearchQuestionId02('')
      setSearchQuestionId03(v)
    }
  }

  return (
    <Stack spacing={1.5} direction="row" sx={{marginRight: '20px'}}>
      <SearchBtn variant="outlined" onClick={() => Enter('Enter')}>
        <SearchIcon sx={{color: '#fff'}} />
      </SearchBtn>

      <SearchBox>
        <StyledInputBase
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          type="text"
          name="search"
          value={qId}
          onChange={(e) => {handleChange(1, e.target.value)}}
          onKeyPress={(e) => {Enter(e.key)}}
        />
      </SearchBox>  
      
      {_authority === '0' &&
        <SearchBox>
          <StyledInputBase
            placeholder="Search 2차"
            inputProps={{ 'aria-label': 'search_2' }}
            type="text"
            name="search"
            value={qId_2}
            onChange={(e) => {handleChange(2, e.target.value)}}
            onKeyPress={(e) => {Enter(e.key)}}
          />
        </SearchBox>
      }
      <SearchBox>
        <StyledInputBase
          placeholder="Search 지문"
          inputProps={{ 'aria-label': 'Search_related_text' }}
          type="text"
          name="search"
          value={qId_3}
          onChange={(e) => {handleChange(3, e.target.value)}}
          onKeyPress={(e) => {Enter(e.key)}}
        />
      </SearchBox>
    </Stack>
  )
}

export default Search
