import {useState} from 'react'
import styled from 'styled-components'

interface TabBoxProps {
  tabList: any[]
  activeTab: number
  getTab: React.Dispatch<React.SetStateAction<number>>
}

const TabBox = ({tabList=[], activeTab=0, getTab}: TabBoxProps) => {
  return (
    <Container>
      {tabList.map(el => 
        <button key={el.value} onClick={()=>getTab(el.value)} className={['item', el.value === activeTab ? 'active' : ''].join(' ')}>
          {el.name}
        </button>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;

  .item {
    padding: 0.6rem 0.8rem;
    color: #ddd;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;

    :hover {
      color: #828282;
      background-color: #e5e5e5;
    }
  }
  .active {
    color: #212121;

    :hover {
      color: #212121;
    }
  }
`

export default TabBox