import {useEffect, useState, useCallback} from 'react'
import styled from 'styled-components'
import {Grid} from '@mui/material'
import DoughnutChart from '../elements/chart/Doughnut'
import VerticalBartChart from '../elements/chart/VerticalBar'

const Graph = () => {
  const [tab, setTab] = useState(0)
  const tabList = [
    {value: 0, name: '진행 중'},
    {value: 1, name: '완료'},
  ]

  const [complete01, setComplete01] = useState(203244)
  const [complete02, setComplete02] = useState(109102)
  const [complete03, setComplete03] = useState(19091)
  const [complete04, setComplete04] = useState(12103)
  const [complete05, setComplete05] = useState(4582)

  const [progress01, setProgress01] = useState(110)
  const [progress02, setProgress02] = useState(182)
  const [progress03, setProgress03] = useState(312)
  const [progress04, setProgress04] = useState(290)
  const [progress05, setProgress05] = useState(92)

  const [error01, setError01] = useState(11)
  const [error02, setError02] = useState(13)
  const [error03, setError03] = useState(15)
  const [error04, setError04] = useState(19)
  const [error05, setError05] = useState(7)

  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const func01 = () => {
    setComplete01(prev=>prev + random(1, 20))
    const count = random(-10, 10)
    setProgress01(prev=>{
      const a = prev + count
      if (a < 80) return prev
        else return prev + count
    })
    setError01(prev=>{
      const a = prev - count
      if (a < 4 || a > 40) return prev
        else return prev - count
    })
    // setError01(prev=>Math.abs(prev - count))
  }

  const func02 = () => {
    setComplete02(prev=>prev + random(1, 20))
    const count = random(-10, 10)
    setProgress02(prev=>{
      const a = prev + count
      if (a < 80) return prev
        else return prev + count
    })
    setError02(prev=>{
      const a = prev - count
      if (a < 4 || a > 40) return prev
        else return prev - count
    })
  }

  const func03 = () => {
    setComplete03(prev=>prev + random(1, 20))
    const count = random(-10, 10)
    setProgress03(prev=>{
      const a = prev + count
      if (a < 80) return prev
        else return prev + count
    })
    setError03(prev=>{
      const a = prev - count
      if (a < 4 || a > 40) return prev
        else return prev - count
    })
  }

  const func04 = () => {
    setComplete04(prev=>prev + random(1, 20))
    const count = random(-10, 10)
    setProgress04(prev=>{
      const a = prev + count
      if (a < 80) return prev
        else return prev + count
    })
    setError04(prev=>{
      const a = prev - count
      if (a < 4 || a > 40) return prev
        else return prev - count
    })
  }

  const func05 = () => {
    setComplete05(prev=>prev + random(1, 20))
    const count = random(-10, 10)
    setProgress05(prev=>{
      const a = prev + count
      if (a < 80) return prev
        else return prev + count
    })
    setError05(prev=>{
      const a = prev - count
      if (a < 4 || a > 40) return prev
        else return prev - count
    })
  }

  const data01 = {
    labels: ['progress', 'error'],
    datasets: [
      {
        label: 'Detecting',
        data: [progress01, error01],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
  
  const data02 = {
    labels: ['progress', 'error'],
    datasets: [
      {
        label: 'OCR',
        data: [progress02, error02],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
  
  const data03 = {
    labels: ['progress', 'error'],
    datasets: [
      {
        label: 'Double Checking',
        data: [progress03, error03],
        backgroundColor: [
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(255, 159, 64, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const data04 = {
    labels: ['progress', 'error'],
    datasets: [
      {
        label: 'Thai Convert',
        data: [progress04, error04],
        backgroundColor: [
          'rgba(255, 206, 86, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const data05 = {
    labels: ['progress', 'error'],
    datasets: [
      {
        label: 'Thai Convert',
        data: [progress05, error05],
        backgroundColor: [
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(153, 102, 255, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
  
  const dataAll = {
    labels: ['Detecting', 'OCR', 'Double Checking', 'Thai Convert', 'Category'],
    datasets: [
      {
        label: 'progress',
        data: [progress01, progress02, progress03, progress04, progress05],
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
    ],
  }

  const garaList = [
    {id: 142940, status: 'Detecting', progress: 13, error: true},
    {id: 142941, status: 'Detecting', progress: 90, error: false},
    {id: 142944, status: 'OCR', progress: 37, error: false},
    {id: 142945, status: 'Double Checking', progress: 9, error: false},
    {id: 142946, status: 'Double Checking', progress: 68, error: true},
    {id: 142948, status: 'Thai Convert', progress: 27, error: true},
    {id: 142950, status: 'OCR', progress: 88, error: false},
    {id: 142953, status: 'Category', progress: 95, error: false},
    {id: 142954, status: 'Category', progress: 65, error: false},
    {id: 142955, status: 'Thai Convert', progress: 2, error: false},
    {id: 142957, status: 'Thai Convert', progress: 5, error: false},
    {id: 142959, status: 'OCR', progress: 53, error: true},
    {id: 142960, status: 'Category', progress: 37, error: false},
    {id: 142961, status: 'OCR', progress: 91, error: false},
    {id: 142962, status: 'Category', progress: 23, error: true},
    {id: 142964, status: 'Double Checking', progress: 51, error: false},
    {id: 142968, status: 'OCR', progress: 32, error: false},
    {id: 142969, status: 'Detecting', progress: 60, error: false},
    {id: 142971, status: 'Category', progress: 12, error: true},
    {id: 142973, status: 'OCR', progress: 38, error: false},
  ]
  const garaDoneList = [
    {id: 130140, status: 'Detecting', progress: 13, error: true},
    {id: 130141, status: 'Detecting', progress: 90, error: false},
    {id: 130144, status: 'OCR', progress: 37, error: false},
    {id: 130145, status: 'Double Checking', progress: 9, error: false},
    {id: 130146, status: 'Double Checking', progress: 68, error: true},
    {id: 130148, status: 'Thai Convert', progress: 27, error: true},
    {id: 130150, status: 'OCR', progress: 88, error: false},
    {id: 130153, status: 'Category', progress: 95, error: false},
    {id: 130154, status: 'Category', progress: 65, error: false},
    {id: 130155, status: 'Thai Convert', progress: 2, error: false},
    {id: 130157, status: 'Thai Convert', progress: 5, error: false},
    {id: 130159, status: 'OCR', progress: 53, error: true},
    {id: 130160, status: 'Category', progress: 37, error: false},
    {id: 130161, status: 'OCR', progress: 91, error: false},
    {id: 130162, status: 'Category', progress: 23, error: true},
    {id: 130164, status: 'Double Checking', progress: 51, error: false},
    {id: 130168, status: 'OCR', progress: 32, error: false},
    {id: 130169, status: 'Detecting', progress: 60, error: false},
    {id: 130171, status: 'Category', progress: 12, error: true},
    {id: 130173, status: 'OCR', progress: 38, error: false},
  ]
  const [proData, setProData] = useState(garaList)
  const [doData, setDoData] = useState(garaDoneList)

  const setProgressData = () => {
    const list = ['Detecting', 'OCR', 'Double Checking', 'Thai Convert', 'Category']
    setProData(prev=>{
      prev.shift()
      const id = prev[prev.length - 1].id
      return [...prev, {id: id + random(1, 3), status: list[random(0, 4)], progress: random(0, 100), error: random(0, 10) > 8 ? true : false}]
    })
  }
  const setDoneData = () => {
    const list = ['Detecting', 'OCR', 'Double Checking', 'Thai Convert', 'Category']
    setDoData(prev=>{
      prev.shift()
      const id = prev[prev.length - 1].id
      return [...prev, {id: id + random(2, 5), status: list[random(0, 4)], progress: random(0, 100), error: random(0, 10) > 8 ? true : false}]
    })
  }

  useEffect(()=>{
    setInterval(func01, random(2000, 10000));
    setInterval(func02, random(3000, 10000));
    setInterval(func03, random(4000, 10000));
    setInterval(func04, random(3000, 10000));
    setInterval(func05, random(8000, 10000));
    setInterval(setProgressData, random(300, 400));
    setInterval(setDoneData, random(300, 400));
  }, [])

const setPercent = (par, total) => {
  return Math.floor((par / total) * 100)
}

  return (
    <Grid container direction={"row"}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Container>
          <Section>
            <div className='item chart chart01'>
              <div className='item_title'>Detecting</div>
              <div className='item_main'>
                <div className='chart_box'>
                  <DoughnutChart data={data01} />
                  <div className='percent'>{setPercent(progress01, progress01 + error01)}<span className='percent_unit'>%</span></div>
                </div>
                <div className='desc_box'>
                  <div className='desc_item'>
                    <div className='desc_item_title'>완료</div>
                    <div className='desc_item_value'>{complete01}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>진행 중</div>
                    <div className='desc_item_value'>{progress01}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>오류</div>
                    <div className='desc_item_value'>{error01}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='item chart chart02'>
              <div className='item_title'>OCR</div>
              <div className='item_main'>
                <div className='chart_box'>
                  <DoughnutChart data={data02} />
                  <div className='percent'>{setPercent(progress02, progress02 + error02)}<span className='percent_unit'>%</span></div>
                </div>
                <div className='desc_box'>
                  <div className='desc_item'>
                    <div className='desc_item_title'>완료</div>
                    <div className='desc_item_value'>{complete02}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>진행 중</div>
                    <div className='desc_item_value'>{progress02}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>오류</div>
                    <div className='desc_item_value'>{error02}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='item chart chart03'>
              <div className='item_title'>Double Checking</div>
              <div className='item_main'>
                <div className='chart_box'>
                  <DoughnutChart data={data03} />
                  <div className='percent'>{setPercent(progress03, progress03 + error03)}<span className='percent_unit'>%</span></div>
                </div>
                <div className='desc_box'>
                  <div className='desc_item'>
                    <div className='desc_item_title'>완료</div>
                    <div className='desc_item_value'>{complete03}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>진행 중</div>
                    <div className='desc_item_value'>{progress03}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>오류</div>
                    <div className='desc_item_value'>{error03}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='item chart chart04'>
              <div className='item_title'>Thai Convert</div>
              <div className='item_main'>
                <div className='chart_box'>
                  <DoughnutChart data={data04} />
                  <div className='percent'>{setPercent(progress04, progress04 + error04)}<span className='percent_unit'>%</span></div>
                </div>
                <div className='desc_box'>
                  <div className='desc_item'>
                    <div className='desc_item_title'>완료</div>
                    <div className='desc_item_value'>{complete04}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>진행 중</div>
                    <div className='desc_item_value'>{progress04}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>오류</div>
                    <div className='desc_item_value'>{error04}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='item chart chart05'>
              <div className='item_title'>Category</div>
              <div className='item_main'>
                <div className='chart_box'>
                  <DoughnutChart data={data05} />
                  <div className='percent'>{setPercent(progress05, progress05 + error05)}<span className='percent_unit'>%</span></div>
                </div>
                <div className='desc_box'>
                  <div className='desc_item'>
                    <div className='desc_item_title'>완료</div>
                    <div className='desc_item_value'>{complete05}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>진행 중</div>
                    <div className='desc_item_value'>{progress05}</div>
                  </div>
                  <div className='divider'></div>
                  <div className='desc_item'>
                    <div className='desc_item_title'>오류</div>
                    <div className='desc_item_value'>{error05}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='item table'>
              <div className='item_title tab_box'>
                {tabList.map(el => <button className={tab === el.value ? 'tab active_tab' : 'tab'} onClick={()=>setTab(el.value)}>{el.name}</button>)}
              </div>
              <div className='item_main table_box'>
                {tab === 0 &&
                  <div className='table-wrap'>
                    <table>
                      <thead>
                        <tr className='fixedHeader'>
                          <th style={{width: '20%'}}>Question ID</th>
                          <th>Status</th>
                          <th>Progress(%)</th>
                          <th>Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {proData.slice(0).reverse().map(el => {
                          return (
                            <tr>
                              <td>{el.id}</td>
                              <td>{el.status}</td>
                              <td>{el.progress}</td>
                              <td>{el.error ? <div></div> : ''}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                }
                {tab === 1 &&
                  <div className='table-wrap'>
                    <table>
                      <thead>
                        <tr className='fixedHeader'>
                          <th style={{width: '20%'}}>Question ID</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {doData.slice(0).reverse().map(el => {
                          return (
                            <tr>
                              <td>{el.id}</td>
                              <td>{el.status}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                }
                
              </div>
            </div>
            <div className='item graph'>
              {/* <div className='item_title'></div> */}
              <div className='item_main'>
                <div className='vertical_bar_box'>
                  <VerticalBartChart data={dataAll} />
                </div>
              </div>
            </div>
          </Section>
        </Container>
      </Grid>
    </Grid>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? 'hidden' : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Section = styled.div`
  padding: .5rem;
  height: 100%;
  overflow: hidden;
  display: grid;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-rows: 1fr 1.5fr;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "chart01 chart02 chart03 chart04 chart05"
    "table table graph graph graph";

  .chart01 { grid-area: chart01;}
  .chart02 { grid-area: chart02;}
  .chart03 { grid-area: chart03;}
  .chart04 { grid-area: chart04;}
  .chart05 { grid-area: chart05;}
  .table { grid-area: table;}
  .graph { grid-area: graph;}

  .item {
    padding: 0.8rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    border-radius: 1rem;
    background-color: white;
    /* box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  .item_title {
    font-size: 1rem;
    font-weight: 600;
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    width: fit-content;
  }
  .tab_box {
    display: flex;
    align-items: flex-end;
    gap: 0.1rem;
    background-color: white;
    transition: all 0.2s;

    .tab {
      padding: 0.2rem 0.5rem;
      font-size: 1rem;
      font-weight: 400;
      cursor: pointer;
    }
    .active_tab {
      border-radius: 4px;
      background-color: #f3f3f3;
    }
  }
  .item_main {
    
  }
  .chart_box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .percent {
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .percent_unit {
      margin-left: 1px;
      font-size: 1rem;
    }
  }
  .table_box {
    height: 25.7rem;
    overflow: auto;

    .table-wrap {
      position: relative;
      border-left: 1px solid #f3f3f3;
      border-bottom: 1px solid #f3f3f3;
    }
    table {
      width: 100%;
      height: 100%;
      table-layout: fixed;
      border-collapse: collapse;
    }
    tbody {
      overflow: auto;
    }
    tbody tr {
      border-top: 1px solid #f3f3f3;
    }
    thead tr th {
      background-color: #f3f3f3;
      position: sticky;
      top: 0;
    }
    th {
      padding: 0.5rem;
      font-size: 13px;
      font-weight: 500;
      word-break: break-all;
    }
    td {
      text-align: center;
      font-size: 12px;
      padding: 0.2rem 1rem;
      word-break: break-all;

      div {
        margin: 0 auto;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: orange;
      }
    }
  }
  .desc_box {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .desc_item {
      padding: 0 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 1rem;

      .desc_item_title {
        font-size: 0.9rem;
      }
    }
    .divider {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .chart01 { grid-area: chart01;}
  .chart02 { grid-area: chart02;}
  .chart03 { grid-area: chart03;}
  .chart04 { grid-area: chart04;}
  .chart05 { grid-area: chart05;}
  .table { grid-area: table;}
  .graph { grid-area: graph;}

  .vertical_bar_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
`

export default Graph