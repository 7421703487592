import styles from './table.module.scss'

interface TdProps {
  children?: React.ReactNode
  align?: 'left' | 'center' | 'right'
  colSpan?: number
  rowSpan?: number
  selected?: boolean
  style?: any
}
const Td = ({
  children, 
  align='center',
  colSpan=1,
  rowSpan=1,
  selected,
  style,
}: TdProps) => {
  return (
    <td 
      className={`
        ${styles['td']}
        ${selected ? styles['td-selected'] : ''}
      `} 
      scope='row' 
      align={align} 
      colSpan={colSpan} 
      rowSpan={rowSpan} 
      style={style}
    >
      {children}
    </td>
  )
}

export default Td