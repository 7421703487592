import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import undoIcon from '../../theme/icons/undo.svg';
import redoIcon from '../../theme/icons/redo.svg';

export default class MultipleBoxUI extends Plugin {
	init() {
		const editor = this.editor;
		const t = editor.t;

		this._addButton('multiplePlusBox', t('Plus'), 'plusMultipleBox');
		this._addButton('multipleMinusBox', t('Minus'), 'deleteMultipleBox');
	}

	_addButton(name, label, func, Icon) {
		const editor = this.editor;

		editor.ui.componentFactory.add(name, (locale) => {
			const command = editor.commands.get(func);
			const view = new ButtonView(locale);

			view.set({
				label,
				// icon: Icon,
				withText: true,
				tooltip: true,
			});

			view.bind('isEnabled').to(command, 'isEnabled');
			// view.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

			this.listenTo(view, 'execute', () => {
				editor.execute(func);
				// editor.editing.view.focus();
			});

			return view;
		});
	}
}
