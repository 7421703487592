/**
 * @module custom/text/box
 */

import { Plugin } from 'ckeditor5/src/core';
import TextBoxEditing from './textbox/textboxediting';
import TextBoxUI from './textbox/textboxui';

import '../theme/css/textbox.css';

/**
 * The bold feature.
 *
 * For a detailed overview check the {@glink features/basic-styles Basic styles feature documentation}
 * and the {@glink api/basic-styles package page}.
 *
 * This is a "glue" plugin which loads the {@link module:basic-styles/bold/boldediting~BoxEditing bold editing feature}
 * and {@link module:basic-styles/bold/boldui~BoldUI bold UI feature}.
 *
 * @extends module:core/plugin~Plugin
 */
export default class TextBox extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get requires() {
		return [TextBoxEditing, TextBoxUI];
	}

	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'TextBox';
	}
}
