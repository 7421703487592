import styled from 'styled-components'
import ReplayIcon from '@mui/icons-material/Replay'

export const Wrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  /* border: 1px solid red; */

  .select_arrow {
    width: 15px;
    height: 8.85px;
    margin-left: -30px;
    align-self: center;  // 수직 가운데 정렬, when 부모가 'display: flex'인 경우
    z-index: 100;
  }
`
export const Select = styled.select`
  // default Icon 삭제
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ cursor }) => cursor || 'pointer'};

  width: ${props => props.width ? `${props.width}` : '400px'};
  height: ${props => props.height ? `${props.height}` : '60px'};
  padding-left: 25px;
  font-size: 16px;
  background: #ffffff;
  border-radius: 6px;
  border: ${props => props.border ? props.border : '2px solid #dddddd'};

  :focus {
    outline: none;
    border: 2px solid #aaaaaa;
  }
`
export const PDFCount = styled.div`
  /* border: 1px solid red; */
  position: absolute;
  bottom: -26px;
  left: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-weight: 500;
`