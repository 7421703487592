import styled from 'styled-components'

export const Wrap = styled.div`
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  transition: all 5s ease;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 27px;
  /* border: 1px solid blue; */

  .title {
    flex: 1;
    /* flex: ${props => props.titleFlex ? `${props.titleFlex}` : 1}; */
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    direction: rtl;
    /* border: 1px solid red; */
  }
  .recall {
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.09);
    cursor: pointer;
  }
  .input_wrap {
    flex: 3;
    display: flex;
    justify-content: ${props => props.flexAlign ? `${props.flexAlign}` : 'flex-start'};
    align-items: center;
    position: relative;
    /* border: 1px solid red; */

    .dot_border {
      width: 400px;
      height: 60px;
      padding-left: 25px;

      background: #ffffff;
      border: 2px dashed #dddddd;
      border-radius: 6px;
    }
  }

  .class0 {
    border: 2px solid #dddddd;
    transition: all 260ms ease;
    :focus {
      border: 2px solid #dddddd;
    }
  }
  .class1 {
    border: 2px solid #F50002;
    :focus {
      border: 2px solid #F50002;
    }
  }
  .class2 {
    border: 2px solid #2e6ef2;
    :focus {
      border: 2px solid #2e6ef2;
    }
  }
`
export const Input = styled.input`
  width: ${props => props.width ? `${props.width}` : '400px'};
  height: 60px;
  font-size: 18px;
  padding-left: 25px;
  float: center;

  background: #ffffff;
  border: ${props => props.border ? '2px solid #dddddd' : 'none'};
  border-radius: 6px;

  :focus {
    outline: none;
    border: 2px solid #aaaaaa;
  }
`
export const CheckBtn = styled.div`
  width: 100px;
  height: 60px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
  border: 2px solid #ddd;
  transition: all 160ms ease;
  margin-left: 10px;
  /* background-color: #2e6ef2; */
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  /* :hover {
    color: ${props => props.color ? props.color : '#111'};
    background-color: ${props => props.bgColor ? props.bgColor : 'white'};
  } */
`