import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../components/_templates/MainSection'
import Table from '../../components/_organisms/mainSection/Table'
import Toolbar from '../../components/_organisms/mainSection/Toolbar'
import ProgressBarOrigin from '../../elements/progressBar/ProgressBarOrigin'
import { useQuery } from 'react-query'
import { getAPI } from '../../api/getAPI'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor};
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any[]
  openModal?: (projectId: number, role: number, labeler: any)=>void
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[]}: tableBodyProps) => {
  const navigate = useNavigate()
  const onClick = (projectId: number, projectType: string) => {
    console.log(projectType)
    navigate(`/workspace/project/${projectType}/${projectId}/content?page=1&subject=`)
  }
  let no = page * rowsPerPage + 1
  let new_list: any[] = []
  list.length > 0 && list.map((row: any, index: number) => {
    const row_comp = (type: string, no: number, id: number, title: string, term: string) => {
      const comp = 
        <Row
          key={id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          onClick={()=>onClick(id, type)}
        >
          <Cell align="center">{no}</Cell>
          <Cell align="left">{title} - {type === 'labeling' ? '1차' : '2차'}</Cell>
          <Cell align="center">{term}</Cell>
        </Row>
      new_list.push(comp)
    }
    if (row.labeling_data) {
      row_comp('labeling', no, row.labeling_data.id, row.title, row.labeling_data.term)
      no += 1
    }
    if (row.review_data) {
      row_comp('review', no, row.review_data.id, row.title, row.review_data.term)
      no += 1
    }
  })

  return new_list
}

const MatchingPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const {'project_id': project_id, 'project_type': project_type} = useParams()
  // const _project_id = Number(project_id)
  // const _project_type = project_type
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _status = Number(searchParams.get('status'))

  const headList = ['프로젝트명', '기간']
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [ progressInfo, setProgressInfo ] = useState({})

  // ----- filter -----
  const [status, setStatus] = useState(_status)
  const statusList = [
    {name: '전체', value: 0},
    {name: '대기', value: 1},
    {name: '진행 중', value: 2},
    {name: '완료', value: 3},
  ]

  const { isFetching } = useQuery([`projects`, {page, status}], () => getAPI(`projects`, {page: page + 1, unit: 1, status: status}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.projects} />
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
    }
  })

  const changedStatus = (v: number) => {
    setSearchParams({
      page: 1, 
      status: v, 
    })
  }

  const changedPage = (v: number) => {
    setSearchParams({
      page: v + 1, 
      status: status, 
    })
  }

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setStatus(_status)
  }, [_status])


  return (
    <MainSection>
      <>
        <Toolbar title={'Projects'} isFilter filter={status} filterList={statusList} getFilter={changedStatus} filterLabel={'필터'} filterBoxWidth={'9rem'} />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

export default MatchingPage