import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Grid,
  Card,
  Paper,
} from '@mui/material'
import { useQuery, useMutation } from 'react-query'
import { getAPI, postAPI, putAPI, deleteAPI } from '../../../api'
import { useState, useRef, useCallback } from 'react'
import Button from '../../../elements/button/Button'
import CreationForm from '../../../components/admin/project/CreationForm'
import LabelerMatching from '../../../components/admin/project/LabelerMatching'
import LabelerMatching02 from '../../../components/admin/project/LabelerMatching02'
import { getLabelerListAPI, getProjectInfoAPI } from '../../../api/getAPI'
import { postProjectCreationAPI, postLabelerMatchingAPI } from '../../../api/postAPI'

const CreationPage = (props) => {
  const navigate = useNavigate()

  // 생성 페이지라면 두 개의 아이디 모두 'int 0'
  const [searchParams, setSearchParams] = useSearchParams()
  const labeling_id = Number(searchParams.get('labeling_id'))
  const review_id = Number(searchParams.get('review_id'))
  const isCreationPage = labeling_id === 0 && review_id === 0

  const { mutate: POST_API } = useMutation(postAPI)
  const { mutate: PUT_API } = useMutation(putAPI)
  const { mutate: DELETE_API } = useMutation(deleteAPI)
  const { data: projectData } = useQuery([`project/${labeling_id}`], () => getAPI(`project/${labeling_id}`), {
    refetchOnWindowFocus: false,
    enabled: !!(!isCreationPage),
    onSuccess: data => {
      console.log(data)
      if (data?.status === 200) {
        const labelers = data?.data.users.labelers
        const reviewers = data?.data.users.reviewers
        labelers && setLabelers(labelers.map(el => el.name))
        reviewers && setReviewers(reviewers.map(el => el.name))
      }
    },
  })
  const { data: userListData, isSuccess: isSuccessUserList } = useQuery('project/users', () => getAPI(`project/users`), {refetchOnWindowFocus: false})

  const dataStoreRef = useRef()
  const [ selected, setSelected ] = useState(0) // 상태값 - 라벨러 매칭 탭 
  const [ labelers, setLabelers ] = useState([])
  const [ reviewers, setReviewers ] = useState([])  

  const handleGetAllData = () => {
    return dataStoreRef.current.handleSubmit()
  }

  const handleTab = (v) => {
    if (v === 1 && selected === 0) {
      setSelected(1)
    } else if (v === 0 && selected === 1) {
      setSelected(0)
    }
  }

  const handleStore = useCallback(async () => {
    const res = await handleGetAllData()

    if (!res) return alert('모든 항목을 입력해 주세요.')

    let data = {
      apiURL: `/project`,
      body: res
    }

    const labelers_id = labelers.map(el=>userListData?.data.filter(v=>v.name === el)[0].id)
    const reviewers_id = reviewers.map(el=>userListData?.data.filter(v=>v.name === el)[0].id)

    data.body.users = {
      labelers_id: labelers_id.length === 0 ? null : labelers_id,
      reviewers_id: reviewers_id.length === 0 ? null : reviewers_id
    }
    data.body.project = {
      labeling_id: labeling_id,
      review_id: review_id
    }
 
    if (isCreationPage) {
      POST_API(data, {
        onSuccess: (data) => {
          if (data.data.message === 'success') alert('프로젝트 생성이 완료되었습니다.')
        },
      })
    } else {
      PUT_API(data, {
        onSuccess: (data) => {
          if (data.data.message === 'success') {
            alert('프로젝트 수정이 완료되었습니다.')
          }
        },
      })
    }
    
  }, [isCreationPage, userListData, labelers, reviewers])

  const handleDelete = () => {
    if (window.confirm('정녕, 진심으로 삭제하실건가요?') === false) return
    const data = {
      apiURL: `/project`,
      body: {
        labeling_id: labeling_id,
        review_id: review_id,
      }
    }
    DELETE_API(data, {
      onSuccess: (data) => {
        if (data.data.message === 'success') {
          alert('프로젝트가 삭제되었습니다.')
          navigate(-1)
        }
      }
    })
  }

  return (
    <Grid container spacing={2} direction={"row"} >
      <Grid item xs={12} sm={7} md={7} lg={7} >
        <Item id='item' hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>
                {isCreationPage
                  ? '프로젝트 생성'
                  : '프로젝트 수정'
                }
              </div>
              <BtnBox02>
                {isCreationPage 
                  ? <Button onclick={handleStore}>생성하기</Button>
                  : (
                    <>
                      <Button onclick={handleStore}>수정하기</Button>
                      <Button onclick={handleDelete} bgColor={'#F55050'}>삭제하기</Button>
                    </>
                  )
                }
              </BtnBox02>
            </BtnBox>
          </Title>
          <CreationForm ref={dataStoreRef} isCreationPage={isCreationPage} projectData={projectData?.data} />
        </Item>
      </Grid>
      <Grid item xs={12} sm={5} md={5} lg={5} >
        <Item hidden={'hidden'}>
          <Title>
            <TabBox>
              <Tab selected={selected === 0} onClick={() => handleTab(0)}>1, 3차 라벨러</Tab>
              <Tab selected={selected === 1} onClick={() => handleTab(1)}>2차, 검수 라벨러</Tab>
            </TabBox>
          </Title>
          {selected === 0 &&
            <LabelerMatching labelerList={isSuccessUserList && userListData?.data} labelers={labelers}  setLabelers={setLabelers} />
          }
          {selected === 1 &&
            <LabelerMatching02 labelerList={isSuccessUserList && userListData?.data} labeler2={reviewers} setLabeler2={setReviewers} />
          }
        </Item>
      </Grid>

    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const Tab = styled.div`
  color: ${(props) => (props.selected ? '#1976d2' : 'rgba(0, 0, 0, 0.3)' )};
  font-weight: ${(props) => (props.selected ? '20px' : '1rem' )};
  cursor: pointer;
  /* cursor: ${({ cursor }) => cursor}; */
`
const TabBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default CreationPage