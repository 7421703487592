import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router'
import { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'
import List from '../../components/detail/detailList/List'
import { useQuery } from 'react-query'
import { getWorkStateListAPI } from '../../api/getAPI'

const PlatformPage = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ pageNum, setPageNum ] = useState(1)
  const [ projectList, setProjectList ] = useState([])
  const [ totalCount, setTotalCount ] = useState(0)
  const [ selectedOption, setSelectedOption ] = useState(0)

  const getWorkStateList = useQuery(['workStateList', pageNum, selectedOption],
    () => getWorkStateListAPI(pageNum), 
    {
      onSuccess: data => {
        console.log(data)
        if (data.status === 200) {
          let data_list
          if (selectedOption === 1) {
            data_list = data.data.data.filter(el => el.status === '진행 중')
          } else if (selectedOption === 2) {
            data_list = data.data.data.filter(el => el.status === '완료')
          } else {
            data_list = data.data.data
          }
          handleSetProjectList(data_list)
          handleTotalCount(data.data.total_count)
        }
      },
      onError: error => {
        console.log(error)
      }
    }
  )

  const handleSelectedOption = (e) => {
    setSelectedOption(e.target.value)
  }

  const handleSetProjectList = useCallback((arg) => {
    setProjectList(arg)
  }, [projectList])

  const handleTotalCount = useCallback((arg) => {
    setTotalCount(arg)
  }, [totalCount])

  const handleSetPageNum = useCallback((page) => {
    setPageNum(page)
  }, [pageNum])

  return (
    <Grid container direction={"column"} >
          
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>나의 작업 리스트</div>
              <BtnBox02>
                <FormControl sx={{ minWidth: 150 }}>
                  <Select
                    value={selectedOption}
                    onChange={handleSelectedOption}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={0}>전체</MenuItem>
                    <MenuItem value={1}>진행 중인 프로젝트</MenuItem>
                    <MenuItem value={2}>완료된 프로젝트</MenuItem>
                  </Select>
                </FormControl>
              </BtnBox02>
              
            </BtnBox>
          </Title>
          <List
            projectList={projectList}
            totalCount={totalCount}
            // project_id={project_id}
            handleSetPageNum={handleSetPageNum}
          />
        </Item>
      </Grid>
    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default PlatformPage