import {useLocation} from 'react-router-dom'
import Layout from '../components/common/Layout'

const MainPage = () => {
  const location = useLocation()
  const row_history_url = localStorage.getItem('history_url')
  const origin_history_url = row_history_url !== "undefined" && JSON.parse(row_history_url)
  
  let new_history_url
  if (row_history_url && origin_history_url) {
    if (origin_history_url.length >= 5) {
      new_history_url = [...origin_history_url, location.pathname]
      new_history_url.shift()
    } else (
      new_history_url = [...origin_history_url, location.pathname]
    )
  } else {
    new_history_url = [location.pathname]
  }
  localStorage.setItem('history_url', JSON.stringify(new_history_url))
  // localStorage.removeItem('history_url')

  return (
    <Layout />
  )
}

export default MainPage