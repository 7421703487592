import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Sidebar from './Sidebar'
import Header from './Header'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { actionCreators as sidebarActions } from "../../redux/modules/sidebar";

const drawerWidth: number = 240

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
)

const theme = createTheme()

const Layout = () => {
  const dispatch = useDispatch()
  const currentUrl = useLocation()
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
    if (open) dispatch(sidebarActions.isVisible(false))
  }

  return (
    <>    
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />

        {/* 헤더 */}
        <Header open={open} toggleDrawer={toggleDrawer} />

        <Drawer variant="permanent" open={open} >

          {/* 메뉴 넣기 / 빼기 */}
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />          
          <Sidebar />
        </Drawer>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          
          <Toolbar />
          {/* <Container maxWidth="xl" sx={{ mt: 4, mb: 4, overflow: 'hidden', border: '1px solid red' }}> */}
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>

          {/* 중첩 라우팅 */}
            <Outlet /> 

          </Container>
        </Box>
      </Box>
    </ThemeProvider>
    </>
  )
}

export default Layout
