import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory'
import styled from "styled-components/macro"

// imterface ContainerProps {
//   display
// }

export const Container = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  /* border-bottom: 1px solid #c8e4fb; */
`

interface HeaderItemProps {
  backgroundColor?: boolean
}

export const HeaderItem = styled.div<HeaderItemProps>`
  position: relative;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border-bottom: ${(props: HeaderItemProps) => props.backgroundColor ? '' : '1px solid rgba(0, 0, 0, 0.1)'};
  background-color: ${(props: HeaderItemProps) => props.backgroundColor ? '#c8e4fb' : 'none'};
  cursor: pointer;
`

export const Main = styled.div`
  min-width: fit-content;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: calc(100% - 48px);
  flex-direction: column;

  .kOmNfV {
    height: fit-content;
  }
`