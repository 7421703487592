import {useState} from 'react'
import styled from 'styled-components'
import plusIcon from '../../../../assets/icons/plus.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
}

interface Props {
  title: string | null,
  savedData: any,
  hasSection: boolean
  isThai?: boolean
}

const SectionBox = ({title, savedData=null, hasSection=true, isThai=false}: Props) => {
  console.log(savedData)
  
  const defaultData = [
    {
      id: uuidv4(),
      name: isThai ? { ko: '', th: '' } : '',
      is_editable: true,
      sub_sections: [
        {
          id: uuidv4(),
          name: isThai ? { ko: '', th: '' } : ''
        }
      ]
    }
  ]

  const [data, setData] = useState(savedData ? savedData : defaultData)

  const addGroup = () => {
    setData([...data, ...defaultData])
  }

  const deleteGroup = (id) => {
    const new_data = data.filter((el: any) => el.id !== id)
    setData(new_data)
  }

  const addItem = (type: string, id: string) => {
    const res = data.map((el: any) => {
      if (el.id === id) {
        el.sub_sections = type === 'qb'
          ? [...el.sub_sections, {id: uuidv4(), name: ''}]
          : [...el.sub_sections, {id: uuidv4(), name: { ko: '', th: '' }}]
      }
      return el
    })
    setData(res)
  }

  const deleteItem = (id: string) => {
    const new_data = data.map((el: any)=>{
      const new_subsections = el.sub_sections.filter(v=>v.id !== id)
      el.sub_sections = new_subsections
      return el
    })
    setData(new_data)
  }

  return (
    <Container >
      <div className='header-wrap'>
        <div className='full_title'>{title}</div>
        <button className='button-add add-group' onClick={()=>addGroup()}>Add group</button>
      </div>
      
      <div className='container-wrap'>
        <div className='container'>
          {data.map((el: any) => {
            return (
              <div key={el.id} className='input-group'>
                {el.is_editable &&
                  <div className='input-group-header'>
                    <div className='button delete-group' onClick={()=>deleteGroup(el.id)}>
                      <img alt='icon-delete' src={deleteIcon} className='input-delete' />
                    </div>
                    <div className='button add-subSection' onClick={()=>addItem(isThai ? 'thai' : 'qb', el.id)}>
                      <img alt='icon-plus' src={plusIcon} className='input-plus' />
                    </div>
                  </div>
                }

                <div className='main-input-group'>
                  <div className='input-item main'>
                    <input data-type='section' data-id={el.id} data-iseditable={el.is_editable} type='text' placeholder='대단원' defaultValue={isThai ? el.name.ko : el.name} className='input-origin' />
                  </div>
                  {isThai &&
                    <div className='input-item main'>
                      <input data-type='section_thai' data-id={el.id} type='text' placeholder='대단원(태국)' defaultValue={el.name.th} className='input-origin' />
                    </div>
                  }
                </div>

                <div className='input-item-sub-wrap'>
                  {el.sub_sections.map(v=>{
                    return (
                      <div key={v.id} className={['sub-box', !isThai && 'sub-box-qb'].join(' ')}>
                        <div className='input-item sub'>
                          <input data-type='sub_section' data-sectionid={el.id} data-subsectionid={v.id} type='text' placeholder='중단원' defaultValue={isThai ? v.name.ko : v.name} className='input-origin' />
                        </div>
                        {isThai &&
                          <div className='input-item sub'>
                            <input data-type='sub_section_thai' data-sectionid={el.id} data-subsectionid={v.id} type='text' placeholder='중단원(태국)' defaultValue={v.name.th} className='input-origin' />
                          </div>
                        }
                        {el.is_editable &&
                          <div className='delete-image-wrap delete-sub-item' onClick={()=>deleteItem(v.id)}>
                            <img alt='icon-delete' src={deleteIcon} className='input-delete' />
                          </div>
                        }
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default SectionBox

const Container = styled.div`
  overflow: hidden;
  height: 100%;

  .header-wrap {
    display: flex;
    justify-content: space-between;

    .full_title {
      font-size: 1.1rem;
      font-weight: 600;
      padding: 1rem 0;
    }
    button {
      cursor: pointer;
    }
  }
  .container-wrap {
    height: calc(100% - 59px);
    overflow: auto;
  }
  .container {
    /* height: 100%; */
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    /* border: 1px solid red; */
  }
  .input-group {
    position: relative;
    padding: 0.6rem;
    width: 100%;
    min-width: 10rem;
    max-width: 40rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    border-radius: 4px;
    border: 1px solid #eee;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .input-group-header {
      margin-bottom: 2px;
      display: flex;
      justify-content: space-between;

      .button {
        color: white;
        padding: 0.3rem;
        width: 1.1rem;
        height: 1.1rem;
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;

        img {
          display: inline;
          width: 0.8rem;
          height: 0.8rem;
        }

        :hover {
          border: none;
          img {
            display: inline;
          }
        }
      }
      .delete-group {
        :hover {
          background-color: #F15A59;
        }
      }
      .add-subSection {
        :hover {
          background-color: #57C5B6;
        }
      }
    }

    .main-input-group {
      display: flex;
      justify-content: center;
      gap: 0.3rem;
    }
  }
  .sub-box {
    display: grid;
    grid-template-columns: 1fr 1fr 2.3rem;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3rem;

  }
  .sub-box-qb {
    grid-template-columns: 1fr 2.3rem;
  }
  .input-item {
    padding: 0 0.2rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e5e5e5;

    input {
      width: 100%;
      height: 100%;
      min-height: 2.2rem;
      padding: 0 0.4rem;
      border: none;
      outline: none;
    }
  }
  .delete-image-wrap {
    padding: 0.3rem;
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    :hover {
      background-color: #eee;
    }
  }
  .delete-sub-item {
    width: 2.3rem;
    height: 2.3rem;
    /* border: 1px solid red; */
  }
  .input-delete {
    max-width: 1.4rem;
  }
  .button-add {
    max-height: 1.7rem;
    font-size: 0.9rem;
    color: white;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .add-group {
    background-color: #57C5B6;
  }
  .main {
    /* background-color: #FFF9DE; */
    background-color: #eee;
  }
  .main input {
    background-color: #eee;
  }
  .sub {
    background-color: #fff;
  }
  .input-item-sub-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
`