import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as S from './style/EditAreaStyle'
import Answer from './Answer'
import Question from './Question'

interface EditorProps {
  _service: string;
  is_relation: boolean;
}

const Editor_writing = ({_service='qb', is_relation=false}: EditorProps) => {
  const qbTab = [{ key: 0, name: '문제' }, { key: 1, name: '답' }, { key: 2, name: '연관 지문' }]
  const bookTab = [{ key: 0, name: '문제' }, { key: 1, name: '답' }, { key: 2, name: '해설' }, { key: 3, name: '연관 지문' }]

  const [ tab, setTab ] = useState('문제')
  const [ tabItems, setTabItems ] = useState(qbTab)
  const onClickTab = (name: string) => {
    setTab(name)
  }

  useEffect(() => {
    if (_service === 'qb') setTabItems(qbTab)
    if (_service === 'book') setTabItems(bookTab)
  }, [_service])

  return (
    <S.Container>
      <S.Header>
        {tabItems.map((item, idx) => {
          return (
            <S.HeaderItem 
              key={item.name} 
              onClick={() => onClickTab(item.name)} 
              backgroundColor={item.name === tab ? true : false} 
            >
              {item.name}
            </S.HeaderItem>
          )
        })}
      </S.Header>
      <S.Main>
        {tab === '문제' &&
          <Question type={'문제'} />
        }
        {tab === '답' &&
          <Answer />
        }
        {tab === '해설' && _service === 'book' &&
          <Question type={'해설'} />
        }
        {tab === '연관 지문' && is_relation &&
          <Question type={'연관 지문'} />
        }
      </S.Main>
    </S.Container>
  )
}

export default Editor_writing