import { useEffect, useState, useRef } from 'react'
import * as S from '../../components/mypage/MyInfoForm.style'
import Form from '../../components/mypage/forms/Form'
import FindPw from '../../components/login/FindPw'
import { useMutation } from "react-query";
import { postFindID } from '../../api/postAPI'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as userActions } from "../../redux/modules/user"

const FindIdPw = (props) => {
  const dispatch = useDispatch()
  const { mutate, data, isSuccess, isError, error } = useMutation(postFindID)
  const CODE = useSelector((state) => state.user.code)
  console.log('code', CODE)

  // 아이디 찾기 State

  const [ keyType, setKeyType ] = useState('requestCode')
  const [ wrong, setWrong ] = useState(0)  // 0: gray, 1: red, 2: blue - 번호 인증
  const [ idState, setIdState ] = useState(0)
  const [ code, setCode ] = useState('')

  const idCodeRef = useRef(0)

  const nameRef = useRef('')
  const phoneRef = useRef('')

  // 비밀번호 찾기 State

  const [ pwKeyType, setPwKeyType ] = useState('requestPwCode')
  const [ pwWrong, setPwWrong ] = useState(0)  // 0: gray, 1: red, 2: blue - 번호 인증
  const [ pwState, setPwState ] = useState(0)
  const [ pwCode, setPwCode ] = useState('')

  const pwCodeRef = useRef(0)
  
  const idPWRef = useRef('')
  const namePWRef = useRef('')
  const ph01PWRef = useRef('')
  const ph02PWRef = useRef('')
  const ph03PWRef = useRef('')

  const handleCode = (e) => {
    const value = e.target.value
    setCode(value)
    if ((value || code) === CODE) {
      setWrong(2)
    } else if (value.length > 0) {
      setWrong(1)
    } else {
      setWrong(0)
    }
  }
  const handlePwCode = (e) => {
    const value = e.target.value
    setPwCode(value)
    if ((value || code) === CODE) {
      setPwWrong(2)
    } else if (value.length > 0) {
      setPwWrong(1)
    } else {
      setPwWrong(0)
    }
  }

  const requestCode = () => {
    if (nameRef.current.value &&
      phoneRef.current.value
    ) {
      const codeData = {
        phone: phoneRef.current.value,
        user_name: nameRef.current.value,
        type: 'id'
      }
      dispatch(userActions.requestCodeAPI(codeData, 'id'))
    } else {
      alert('이름과 휴대폰번호를 모두 입력해 주세요.')
    }
  }

  const handleFindID = () => {
    if (idState >= 1 &&
      code === CODE &&
      nameRef.current.value &&
      phoneRef.current.value
    ) {
      const idData = {
        phone: phoneRef.current.value,
        user_name: nameRef.current.value,
      }
      mutate(idData)
    } else {
      alert('인증번호를 다시 입력해 주세요. 잘못된 인증번호입니다.')
    }
  }

  const clickBtn = () => {
    if (idState === 0) requestCode()
    if (idState >= 1) handleFindID()
  }

  const handleKeyPress = (e, type) => {
    if(e.key === 'Enter') {
      switch(type) {
        case 'requestCode':
          requestCode()
          break
        case 'handleFindID':
          handleFindID()
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (code === '' && pwCode === '' && CODE) {
      setIdState(1)
      setKeyType('handleFindID')
      setPwKeyType('handleFindPW')
    }
  }, [CODE])

  useEffect(() => {
    console.log(data) 
    if (isSuccess) {
      alert(`${nameRef.current.value}님의 아이디는 ${data.user_id} 입니다.`)
      nameRef.current.value = ''
      phoneRef.current.value = ''
      idCodeRef.current.value = ''
      
      setIdState(0)
      setCode('')      
      setWrong(0)      
      setKeyType('requestCode')
      
      dispatch(userActions.setCode(null))
    } else if (isError) {
      console.log(error && error.response.data.error)
    }
  }, [isSuccess, data])

  return (
    <Box>
      <S.CardPadding>
        <Box p="0 20px" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <S.Wrap>
            <S.Header>
              <h1>아이디 찾기</h1>
            </S.Header>
            <S.Forms style={{marginBottom: '20px', borderBottom: '1px solid #ddd', padding: '20px 0'}}>
              <Form ref={nameRef} title={'이름'} type={'text'} value={''} disabled={idState !== 0} onkeypress={(e) => handleKeyPress(e, keyType)} />
              
              {/* <div className="form_box">
                <div className="title">PH</div>
                <div className="input_wrap">
                  <S.PhoneNumWrap className="date_wrap">
                    <Form ref={ph01Ref} phone type={'number'} width={'120px'} flexAlign={'flex-start'} onkeypress={(e) => handleKeyPress(e, keyType)} />
                    <Form ref={ph02Ref} phone type={'number'} width={'120px'} flexAlign={'center'} onkeypress={(e) => handleKeyPress(e, keyType)} />
                    <Form ref={ph03Ref} phone type={'number'} width={'120px'} flexAlign={'flex-end'} onkeypress={(e) => handleKeyPress(e, keyType)} />
                  </S.PhoneNumWrap>
                </div>
              </div> */}
              <Form ref={phoneRef} title={'휴대폰번호'} type={'number'} value={''} disabled={idState !== 0} onkeypress={(e) => handleKeyPress(e, keyType)} />

              <Form ref={idCodeRef} value={''} title={'인증번호'} type={'number'} disabled={idState === 0} onkeypress={(e) => handleKeyPress(e, keyType)} onchange={handleCode} className={`class${wrong}`} />
              {/* --- 인증번호 재요청 --- */}
              {/* <Form ref={idCodeRef} value={''} recall={idState > 0} recallFn={recallCode} title={'인증번호'} type={'number'} disabled={idState === 0} onkeypress={(e) => handleKeyPress(e, keyType)} onchange={handleCode} className={`class${wrong}`} /> */}

              <S.Button
                onClick={() => clickBtn()}
                style={{margin: '16px 0'}}
              >
                {/* {idState === 0 ? '번호 인증하기' : '아이디 찾기'} */}
                {idState === 0 && '인증 번호 요청'}
                {idState === 1 && '번호 인증하기'}
              </S.Button>
              
            </S.Forms>

            {/* --------------------------------------------------------------------------------- */}
            <FindPw />
            
          </S.Wrap>
        </Box>
      </S.CardPadding>
    </Box>
  )
}

export default FindIdPw