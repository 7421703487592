import Command from '@ckeditor/ckeditor5-core/src/command';

export class DeleteMultipleBoxCommand extends Command {
	execute() {
		const findNode = function (writer, name, root) {
			const range = writer.createRangeIn(root);

			for (const value of range.getWalker({ ignoreElementEnd: true })) {
				const node = value.item;

				if (node.is('element', name)) return node;
			}
		};
		const findNodes = function (writer, name, root) {
			const nodes = [];
			const range = writer.createRangeIn(root);

			for (const value of range.getWalker({ ignoreElementEnd: true })) {
				const node = value.item;

				if (node.is('element', name)) {
					nodes.push(node);
				}
			}

			return nodes;
		};

		this.editor.model.change((writer) => {
			const model = this.editor.model;
			const contentWrapList = findNodes(
				writer,
				'multipleBoxContentWrap',
				model.document.getRoot()
			);
			const contentList = findNodes(
				writer,
				'multipleBoxContent',
				model.document.getRoot()
			);

			const multipleBoxWrap = findNode(
				writer,
				'multipleBoxWrap',
				model.document.getRoot()
			);

			const leng = Math.floor(contentList.length / 6);

			if (contentList.length % 6 === 0) {
				for (const content of contentWrapList) {
					const descList = content._children._nodes;
					if (descList.length === 1) return;

					let count = 1;
					for (const desc of descList) {
						if (count === descList.length) writer.remove(desc);
						count += 1;
					}
				}
			} else {
				for (const wrap of contentWrapList) {
					if (leng !== wrap._children.length) {
						const descList = wrap._children._nodes;
						if (descList.length === leng || descList.length === 1)
							return;

						let count = 1;
						for (const desc of descList) {
							if (count === descList.length) writer.remove(desc);
							count += 1;
						}
					}
				}
			}

			writer.setAttribute(
				'hasColumns',
				leng - 1 === 1 ? false : true,
				multipleBoxWrap
			);
		});
	}

	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;
		const allowedIn = model.schema.findAllowedParent(
			selection.getFirstPosition(),
			'multipleBoxContent'
		);

		this.isEnabled = allowedIn === null;
	}
}
