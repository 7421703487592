import { Plugin } from 'ckeditor5/src/core';
import {
	ButtonView,
	ContextualBalloon,
	createDropdown,
	createLabeledInputText,
	FocusCycler,
	injectCssTransitionDisabler,
	InputTextView,
	LabeledFieldView,
	View,
	ViewCollection,
} from 'ckeditor5/src/ui';
import BlockTypeInputView from './ui/blocktypeinputview';

import '../../theme/css/block.css';
import nestedBlockIcon from '../../theme/icons/nestedblock.svg';
import icon from '../../theme/icons/block.svg';
import { icons } from 'ckeditor5/src/core';
import KeystrokeHandler from '@ckeditor/ckeditor5-utils/src/keystrokehandler';
import { submitHandler } from 'ckeditor5/src/ui';
import { FocusTracker } from 'ckeditor5/src/utils';

export const LINK_KEYSTROKE = 'Ctrl+H';

export default class BlockUI extends Plugin {
	static get pluginName() {
		return 'BlockUI';
	}

	init() {
		const editor = this.editor;
		const t = editor.t;

		editor.ui.componentFactory.add('block', (locale) => {
			const command = editor.commands.get('insertBlock');
			const buttonView = new ButtonView(locale);

			buttonView.set({
				label: t('Block'),
				icon: nestedBlockIcon,
				tooltip: true,
				isToggleable: true,
			});

			// Bind button model to command.
			buttonView.bind('isEnabled').to(command, 'isEnabled');

			// Execute command.
			this.listenTo(buttonView, 'execute', () => {
				editor.execute('insertBlock', { nest: true });
				editor.editing.view.focus();
			});

			return buttonView;
		});

		// The "simpleBox" button must be registered among the UI components of the editor
		// to be displayed in the toolbar.
		// editor.ui.componentFactory.add('block', (locale) => {
		// 	// The state of the button will be bound to the widget command.
		// 	const command = editor.commands.get('insertBlock');

		// 	// The button will be an instance of ButtonView.
		// 	const buttonView = new ButtonView(locale);

		// 	buttonView.set({
		// 		label: t('block'),
		// 		icon: icon,
		// 		tooltip: true,
		// 	});

		// 	buttonView
		// 		.bind('isOn', 'isEnabled')
		// 		.to(command, 'value', 'isEnabled');

		// 	this.listenTo(buttonView, 'execute', () => {
		// 		editor.execute('insertBlock');
		// 		editor.editing.view.focus();
		// 	});

		// 	return buttonView;
		// });

		// editor.ui.componentFactory.add('blockType', (locale) => {
		// 	const view = new CustomView();

		// 	const labeledInputView = new LabeledFieldView(
		// 		locale,
		// 		createLabeledInputText
		// 	);
		// 	labeledInputView.label = t('Type Input');

		// 	const saveButtonView = new ButtonView(locale);

		// 	saveButtonView.set({
		// 		label: t('Save'),
		// 		icon: icons.check,
		// 		tooltip: true,
		// 	});

		// 	saveButtonView.extendTemplate({
		// 		attributes: {
		// 			class: 'pr-block_btn_save',
		// 		},
		// 	});

		// 	this.listenTo(saveButtonView, 'execute', (aaa) => {
		// 		const type = labeledInputView.fieldView.element.value;
		// 		editor.execute('insertBlockType', type);
		// 	});

		// 	view.setTemplate({
		// 		tag: 'form',
		// 		attributes: {
		// 			tabindex: '-1',
		// 		},
		// 		children: [labeledInputView, saveButtonView],
		// 	});
		// 	return view;
		// });
		const command = editor.commands.get('insertBlockType');

		const form = new CustomView(editor.locale);
		const dropdownView = createDropdown(editor.locale);

		editor.ui.componentFactory.add('blockType', (locale) => {
			dropdownView.bind('isEnabled').to(command);
			dropdownView.panelView.children.add(form);
			dropdownView.buttonView.set({
				label: t('Block'),
				icon: icon,
				tooltip: true,
			});
			dropdownView.buttonView.on(
				'open',
				() => {
					form.disableCssTransitions();
					form.url = command.value || '111';
					form.urlInputView.fieldView.select();
					form.focus();
					form.enableCssTransitions();
				},
				{ priority: 'low' }
			);

			dropdownView.on('submit', () => {
				editor.execute('insertBlockType', form.mediaURLInputValue, {
					forceValue: true,
				});

				closeUI();
			});

			dropdownView.on('change:isOpen', () => form.resetFormStatus());
			dropdownView.on('cancel', () => {
				editor.execute('insertBlockType', undefined, {
					forceValue: false,
				});

				closeUI();
			});

			function closeUI() {
				editor.editing.view.focus();
				dropdownView.isOpen = false;
			}
			return dropdownView;
		});

		this._setUpForm(dropdownView, form, command);
	}

	_isBlock(element) {
		let flag = true;
		while (flag) {
			if (element.is('element', 'block')) {
				flag = false;
				break;
			}
			if (element.parent) element = element.parent;
			else break;
		}
		return !flag;
	}

	_setUpForm(dropdown, form, command) {
		form.delegate('submit', 'cancel').to(dropdown);
		form.urlInputView.bind('value').to(command, 'value');

		// Form elements should be read-only when corresponding commands are disabled.
		form.urlInputView
			.bind('isReadOnly')
			.to(command, 'isEnabled', (value) => !value);
	}
}

class CustomView extends View {
	constructor(locale) {
		super(locale);
		const t = locale.t;

		this.focusTracker = new FocusTracker();

		this.keystrokes = new KeystrokeHandler();

		this.set('mediaURLInputValue', '');

		this.urlInputView = this._createUrlInput();

		this.saveButtonView = this._createButton(
			t('Write'),
			icons.pencil,
			'ck-button-save'
		);
		this.saveButtonView.type = 'submit';
		// this.saveButtonView
		// 	.bind('isEnabled')
		// 	.to(this, 'mediaURLInputValue', (value) => !!value);

		this.cancelButtonView = this._createButton(
			t('Erase'),
			icons.eraser,
			'ck-button-cancel',
			'cancel'
		);

		this._focusables = new ViewCollection();

		this._focusCycler = new FocusCycler({
			focusables: this._focusables,
			focusTracker: this.focusTracker,
			keystrokeHandler: this.keystrokes,
			actions: {
				// Navigate form fields backwards using the <kbd>Shift</kbd> + <kbd>Tab</kbd> keystroke.
				focusPrevious: 'shift + tab',

				// Navigate form fields forwards using the <kbd>Tab</kbd> key.
				focusNext: 'tab',
			},
		});

		// this._validators = validators;

		this.setTemplate({
			tag: 'form',

			attributes: {
				class: ['ck', 'ck-media-form', 'ck-responsive-form'],

				tabindex: '-1',
			},

			children: [
				this.urlInputView,
				this.saveButtonView,
				this.cancelButtonView,
			],
		});

		injectCssTransitionDisabler(this);
	}

	render() {
		super.render();
		submitHandler({
			view: this,
		});

		const childViews = [
			this.urlInputView,
			this.saveButtonView,
			this.cancelButtonView,
		];

		childViews.forEach((v) => {
			// Register the view as focusable.
			this._focusables.add(v);

			// Register the view in the focus tracker.
			this.focusTracker.add(v.element);
		});

		// Start listening for the keystrokes coming from #element.
		this.keystrokes.listenTo(this.element);

		const stopPropagation = (data) => data.stopPropagation();

		// Since the form is in the dropdown panel which is a child of the toolbar, the toolbar's
		// keystroke handler would take over the key management in the URL input. We need to prevent
		// this ASAP. Otherwise, the basic caret movement using the arrow keys will be impossible.
		this.keystrokes.set('arrowright', stopPropagation);
		this.keystrokes.set('arrowleft', stopPropagation);
		this.keystrokes.set('arrowup', stopPropagation);
		this.keystrokes.set('arrowdown', stopPropagation);

		// Intercept the `selectstart` event, which is blocked by default because of the default behavior
		// of the DropdownView#panelView.
		// TODO: blocking `selectstart` in the #panelView should be configurable per–drop–down instance.
		this.listenTo(
			this.urlInputView.element,
			'selectstart',
			(evt, domEvt) => {
				domEvt.stopPropagation();
			},
			{ priority: 'high' }
		);
	}

	_createUrlInput() {
		const t = this.locale.t;

		const labeledInput = new LabeledFieldView(
			this.locale,
			createLabeledInputText
		);
		const inputField = labeledInput.fieldView;

		// this._urlInputViewInfoDefault = t('Paste the media URL in the input.');
		// this._urlInputViewInfoTip = t(
		// 	'Tip: Paste the URL into the content to embed faster.'
		// );

		labeledInput.label = t('type');
		labeledInput.class = t('pr-input_type');
		// labeledInput.infoText = this._urlInputViewInfoDefault;

		inputField.on('input', () => {
			// Display the tip text only when there is some value. Otherwise fall back to the default info text.
			// labeledInput.infoText = inputField.element.value
			// 	? this._urlInputViewInfoTip
			// 	: this._urlInputViewInfoDefault;
			this.mediaURLInputValue = inputField.element.value.trim();
		});

		return labeledInput;
	}

	_createButton(label, icon, className = '', eventName) {
		const button = new ButtonView(this.locale);

		button.set({
			label,
			icon,
			tooltip: true,
		});

		button.extendTemplate({
			attributes: {
				class: className,
			},
		});

		if (eventName) {
			button.delegate('execute').to(this, eventName);
		}

		return button;
	}
	resetFormStatus() {
		this.urlInputView.errorText = null;
		// this.urlInputView.infoText = this._urlInputViewInfoDefault;
	}
	focus() {
		this._focusCycler.focusFirst();
	}
}
