import React, { useEffect, useState } from 'react'
import styles from './select.module.css'
import Chip from '../_atom/Chip'
import ChipAndDeletion from '../_atom/ChipAndDeletion'
import arrow_up from '../../assets/icons/select/nav-arrow-up.svg'
import arrow_down from '../../assets/icons/select/nav-arrow-down.svg'

import { useSelector } from 'react-redux'

const Select = (props) => {
  const { 
    label, 
    multiple = false,
    selected_list,
    option_list,
    onSelect,
    width='100%',
    onClick,
    handleDeleteTag,
  } = props
  const [ open, setOpen ] = useState(false)
  const arrowStyleStatus = open 
    ? [styles.select__arrow, styles.select__arrow_open].join(' ') 
    : [styles.select__arrow, styles.select__arrow_close].join(' ')

  const onOpen = () => {
    if (!!onClick) return onClick()
    if (open) setOpen(false)
    if (!open) setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const parsingId = (_id, id) => {
    return _id !== id
  }
  const parsingId02 = (_id, id) => {
    return _id === id
  }

  const selectOption = (el, id) => {
    if (!multiple && selected_list.length > 0 && selected_list[0].id === id ) return 
    if (!multiple) return onSelect([el])

    let is_same_thing 
    if (label === '중단원' || label === '태국 세부과목') {
      is_same_thing = selected_list.filter(v => v.id === id)
    } else if (label === '소단원' || label === '추가 선택') {
      is_same_thing = selected_list.filter(v => v.id === id)
    }

    if (is_same_thing.length === 0) {
      onSelect([...selected_list, el])
    } else {
      onSelect(selected_list.filter(v => {
        if (label === '중단원' || label === '태국 세부과목') {
          return parsingId(v.id, id)
        } else if (label === '소단원' || label === '추가 선택') {
          return parsingId(v.id, id)
        }
      }))
    }
  }
  const getStyles = (id, target) => {
    if (target.length === 0) return
    const result = target.find(el => {
      if (label === '중단원' || label === '태국 세부과목') {
        return parsingId02(el.id, id)
      } else if (label === '소단원' || label === '추가 선택') {
        return parsingId02(el.id, id)
      }
    })
    
    return {
      fontWeight: result ? 600 : 400,
      backgroundColor: result ? 'rgba(47, 117, 241, 0.15)' : 'transparent',
    }
  }

  const [ targetWidth, setTargetWidth ] = useState(0)
  useEffect(() => {
    const target = document.getElementById('container')
    const target_width = target.getBoundingClientRect().width
    setTargetWidth(target_width)
  }, [open])

  return (
    <div className={styles.container} id='container' style={{width: width}}>
      <div className={styles.select__label}>{label}</div>
      <div 
        className={[
          styles.select__outer_box,
          open ? styles.select__active : styles.select__inactive
        ].join(' ')}
        onClick={onOpen}
      >
        <div className={styles.select__inner_box}>
          {selected_list.length > 0 && selected_list.map(el => {
            if (label === '영문법') return <ChipAndDeletion key={el.id} label={el.name} onDeletion={()=>handleDeleteTag(el.id)} />
            if (label === '중단원' || label === '태국 세부과목') return <Chip key={el.id} label={el.name} />
            if (label === '소단원' || label === '추가 선택') return <Chip key={el.id} label={el.sub_subsection_name} />
          })}
        </div>
        <img className={arrowStyleStatus} alt='arrow_down' src={arrow_down}></img>
      </div>
      {open &&
        <div>
          <div className={styles.select__background_close} onClick={onClose}></div>
          <div 
            className={styles.select__option_box}
            style={{width: targetWidth}}
          >
            {option_list.length > 0 && option_list.map(el => {
              if (label === '중단원' || label === '태국 세부과목') return (
                <div 
                  key={el.id} 
                  className={styles.select__option}
                  style={getStyles(el.id, selected_list)}
                  onClick={() => selectOption(el, el.id)}
                >
                  {el.name}
                </div>
              )
              if (label === '소단원' || label === '추가 선택') return (
                <div 
                  key={el.id} 
                  className={styles.select__option}
                  style={getStyles(el.id, selected_list)}
                  onClick={() => selectOption(el, el.id)}
                >
                  {el.sub_subsection_name}
                </div>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}

export default Select