import { produce } from "immer"
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from "redux-actions"

// // actions
const SIDEBAR = "SIDEBAR"  // 사이드바 on/off
const IS_LOADING = "IS_LOADING" // 검색 로딩
const FILTER = "FILTER" // 문제 리스트 필터

const SET_SAVED_DATA_JSON = "SET_SAVED_DATA_JSON" // 백에서 받은 json 형태의 모든 데이터

const SET_BOX_COUNT = "SET_BOX_COUNT" // 검색 로딩
const SET_BOX_DATA = "SET_BOX_DATA" // 검색 로딩
const SET_CORRECT_DATA = "SET_CORRECT_DATA" // 검색 로딩
const SET_PAGE_TITLE = "SET_PAGE_TITLE" // 페이지 제목
const SET_IMAGE_LIST = "SET_IMAGE_LIST" // 이미지 url list
const SET_AI_MODAL_IMAGE = "SET_AI_MODAL_IMAGE" // 이미지 url list
const SET_BOX_PROPERTY = "SET_BOX_PROPERTY" // 자른 박스에 속성 부여

const SET_FABRICCANVAS = "SET_FABRICCANVAS" // fabricCanvas 저장
const UPDATE_CLICK_STATUS = "UPDATE_CLICK_STATUS" // clickStatus 업데이트
const UPDATE_CLICK_DATA = "UPDATE_CLICK_DATA" // clickData 업데이트

const UPDATE_QUESTION_DATA = "UPDATE_QUESTION_DATAUPDATE_DATA" // question_data 업데이트
const UPDATE_RELATED_DATA = "UPDATE_RELATED_DATA" // related_data 업데이트
const UPDATE_BOX_DATA = "UPDATE_BOX_DATA" // box_data 업데이트
const UPDATE_SELECTED_BOX_LIST = "UPDATE_SELECTED_BOX_LIST" // selected_box 업데이트


// // actionCreators
const show_sidebar = createAction(SIDEBAR, (boolean) => ({ boolean })) // 검색 로딩
const isLoading = createAction(IS_LOADING, (boolean) => ({ boolean })) // 검색 로딩
const getFilter = createAction(FILTER, (string) => ({ string })) // 문제 리스트 필터

const setSavedDataJson = createAction(SET_SAVED_DATA_JSON, (string) => ({ string })) // 검색 로딩

const setBoxCount = createAction(SET_BOX_COUNT, (int) => ({ int })) // 검색 로딩
const setBoxingData = createAction(SET_BOX_DATA, (string) => ({ string })) // 검색 로딩
const setCorrectData = createAction(SET_CORRECT_DATA, (string) => ({ string })) // 검색 로딩
const setPageTitle = createAction(SET_PAGE_TITLE, (string) => ({ string })) // 검색 로딩
const setImageList = createAction(SET_IMAGE_LIST, (list) => ({ list })) // 이미지 url list
const setAiModalImage = createAction(SET_AI_MODAL_IMAGE, (list) => ({ list })) // 이미지 url list
const setBoxProperty = createAction(SET_BOX_PROPERTY, (string) => ({ string })) // 자른 박스에 속성 부여

const set_fabricCanvas = createAction(SET_FABRICCANVAS, (list) => ({ list })) // fabricCanvas 저장
const update_click_status = createAction(UPDATE_CLICK_STATUS, (boolean) => ({ boolean })) // clickStatus 업데이트
const update_click_data = createAction(UPDATE_CLICK_DATA, (obj) => ({ obj })) // clickData 업데이트

const update_question_data = createAction(UPDATE_QUESTION_DATA, (list) => ({ list })) // data 업데이트
const update_related_data = createAction(UPDATE_RELATED_DATA, (list) => ({ list })) // related_data 업데이트
const update_box_data = createAction(UPDATE_BOX_DATA, (list) => ({ list })) // box_data 업데이트
const update_selected_box_list = createAction(UPDATE_SELECTED_BOX_LIST, (list) => ({ list })) // selected_box 업데이트


// // initialState
const initialState = {
  sidebar: true,
  is_loading: false,
  filter: '0',

  saved_data_json: null,
  
  box_count: null,
  boxing_data: '',
  correct_data: '',
  page_title: '',
  image_url_list: [],
  ai_modal_image: [],
  box_property: '',     // '': 없음, '1': 연관 지문(related), '2': 객관식 문제(multiple), '3': 서술형 문제(essay)

  fabricCanvas: [],
  clickStatus: false,
  clickData: null,

  question_data: [],  // { id: UUID, num: 1, related: null or UUID, column: '문제', multiple: true or false }
  related_data: [],  // { id: UUID } 
  box_data: [],  // { id: UUID, page: 1, origin: {klass}, related_id: UUID or null, question_id: UUID or null }
  selected_box: []  // 선택된 box list
}


// 이미지 url list 요청
const getImageListAPI = (content_id) => {
  const path = `labeling/url/${content_id}`
  const accessToken = localStorage.getItem("access_token")

  return async function (dispatch) {
    try {
      const res = await Axios.get(path, {
        headers: { Authorization: accessToken },
      })
      // console.log(res.data)
      if (res.status === 200) {
        dispatch(setSavedDataJson(res.data))
        dispatch(setImageList(res.data.url))
      }
    } catch (err) {
      console.log(err, "getImageListAPI")
    }
  }
}

// ai 모달 이미지 요청
const getModalImageAPI = (boxing_data, image_list, box_count) => {
  const path = `ai/boxing`
  const accessToken = localStorage.getItem("access_token")
  const body = {
    annotation_list: boxing_data,
    image_url_list:image_list
  }
  // console.log(body)

  return async function (dispatch) {
    try {
      const res = await Axios.post(path, body, {
        headers: { Authorization: accessToken },
      })
      // console.log(res.data)
      if (res.data.response) {
        dispatch(setBoxCount(box_count))
        dispatch(setBoxingData(boxing_data))
        dispatch(setCorrectData(res.data.response))
        // console.log(res.data.response)
        const json = res.data.response
        const origin_data = JSON.parse(json)
        const data = origin_data.map((el, index) => JSON.parse(el))
        
        dispatch(setAiModalImage(data))
      }
    } catch (err) {
      console.log(err, "getImageListAPI")
    }
  }
}

// ai 1차 라벨링 완료
const postCompleteLabelingAPI = (boxing_data, pdf_id) => {
  const path = `ai/result`
  const accessToken = localStorage.getItem("access_token")
  const body = {
    obj_info_list_txt: boxing_data,
    pdf_id: pdf_id
  }
  // console.log(body)

  return async function (dispatch) {
    try {
      const res = await Axios.post(path, body, {
        headers: { Authorization: accessToken },
      })
      // console.log(res.data)
      // if (res.data.response) {
        // console.log(res.data.response)
      // }
    } catch (err) {
      console.log(err, "getImageListAPI")
    }
  }
}

// // reducer
export default handleActions(
  {
    [SIDEBAR]: (state, action) =>
      produce(state, (draft) => {
        draft.sidebar = action.payload.boolean;
      }),

    [IS_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = action.payload.boolean;
      }),

    [FILTER]: (state, action) =>
      produce(state, (draft) => {
        draft.filter = action.payload.string;
      }),

    [SET_SAVED_DATA_JSON]: (state, action) =>
      produce(state, (draft) => {
        draft.saved_data_json = action.payload.string;
      }),

    [SET_BOX_COUNT]: (state, action) =>
      produce(state, (draft) => {
        draft.box_count = action.payload.int;
      }),

    [SET_BOX_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.boxing_data = action.payload.string;
      }),

    [SET_CORRECT_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.correct_data = action.payload.string;
      }),

    [SET_PAGE_TITLE]: (state, action) =>
      produce(state, (draft) => {
        draft.page_title = action.payload.string;
      }),
      
    [SET_IMAGE_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.image_url_list = action.payload.list;
      }),

    [SET_AI_MODAL_IMAGE]: (state, action) =>
      produce(state, (draft) => {
        draft.ai_modal_image = action.payload.list;
      }),

    [SET_BOX_PROPERTY]: (state, action) =>
      produce(state, (draft) => {
        draft.box_property = action.payload.number;
      }),

    [SET_FABRICCANVAS]: (state, action) =>
      produce(state, (draft) => {
        draft.fabricCanvas = action.payload.list;
      }),

    [UPDATE_CLICK_STATUS]: (state, action) =>
      produce(state, (draft) => {
        draft.clickStatus = action.payload.boolean;
      }),

    [UPDATE_CLICK_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.clickData = action.payload.obj;
      }),

    [UPDATE_QUESTION_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.question_data = action.payload.list;
        // draft.question_data = [...draft.question_data, action.payload.obj];
      }),

    [UPDATE_RELATED_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.related_data = action.payload.list;
        // draft.related_data = [...draft.related_data, action.payload.obj];
      }),

    [UPDATE_BOX_DATA]: (state, action) =>
      produce(state, (draft) => {
        draft.box_data = action.payload.list;
        // draft.box_data = [...draft.box_data, action.payload.obj];
      }),

    [UPDATE_SELECTED_BOX_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.selected_box = action.payload.list;
      }),
  },
  initialState
);

const actionCreators = {
  show_sidebar,
  isLoading,
  getFilter,
  setPageTitle,
  setBoxProperty,
  setAiModalImage,

  set_fabricCanvas,
  update_click_status,
  update_click_data,

  update_question_data,
  update_related_data,
  update_box_data,
  update_selected_box_list,

  getImageListAPI,
  getModalImageAPI,
  postCompleteLabelingAPI,

  setSavedDataJson,
};

export { actionCreators };
