type ProjectProps = {
  color?: string;
}

const Project = ({color}: ProjectProps) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
      <path d="M7 18h7M7 14h1M7 10h3M7 2h9.5L21 6.5V19" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M3 20.5v-14A1.5 1.5 0 014.5 5h9.752a.6.6 0 01.424.176l3.148 3.148A.6.6 0 0118 8.75V20.5a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 013 20.5z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    <path d="M14 5v3.4a.6.6 0 00.6.6H18" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
  )
}

export default Project