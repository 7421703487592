import styled from 'styled-components'

const Wrap = styled.div`
  flex-basis: 35%;
  max-width: 35%;
  min-width: 35%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* flex-wrap: nowrap; */
  gap: 1rem;
  /* border: 1px solid green; */
`

const Channel = ({ children }) => {
  return (
    <Wrap>
      {children}
    </Wrap>
  )
}

export default Channel