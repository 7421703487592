import { useState, useEffect } from 'react'
import styled from 'styled-components'
import deleteIcon from '../../../assets/icons/delete.svg'
import MainSection from '../../../components/_templates/MainSection'
import { useMutation } from 'react-query'
import { deleteAPI } from '../../../api'

const QB = () => {
  const _authority = localStorage.getItem("_authority") // 0: 어드민, 1: 중단원 부여자, 2: 스키머, 3: 번역자, 4: 검수자
  const [inputText, setInputText] = useState('')
  const [chipList, setChipList] = useState([])

  // POST - Get image url ====================================
  const { mutate } = useMutation(deleteAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.data.message === 'success delete question') alert('해당 문제들의 태국 데이터 삭제가 완료되었습니다.')
    },
    onError: error => {
      console.log(error)
    }
  })

  const handleEnter = () => {
    setChipList([...chipList, Number(inputText)])
    setInputText('')
  }
  const onClickDelete = () => {
    let data = {
      apiURL: `/th/question`,
      body: {question_id: chipList}
    }
    mutate(data)
  }

  // Enter
  document.onkeydown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleEnter()
    }
  }

  return (
    <MainSection>
      <>
        <InputWrap>
          <Input type='number' value={inputText} onChange={(e)=> setInputText(e.target.value)} />
          <DeleteButton onClick={onClickDelete}>delete</DeleteButton>
        </InputWrap>
        <ChipWrap>
          {chipList.map((el, index) => {
            return <ChipButton key={String(index)}>{el}<ChipDelete src={deleteIcon} /></ChipButton>
          })}
        </ChipWrap>
      </>
    </MainSection>
  )
}

const InputWrap = styled.div`
  display: flex;
  gap: 0.5rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`
const Input = styled.input`
  padding: 0.5rem;
  width: 10rem;
  height: 3rem;
  outline: none;
  font-size: 1.1rem;
  border-radius: 4px;
  border: 1px solid #dddddd;
`
const DeleteButton = styled.div`
  padding: 0.8rem;
  /* width: 3rem; */
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    color: white;
    background-color: #F20530;
  }
`
const ChipWrap = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`
const ChipButton = styled.div`
  padding: 0.3rem 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
  border-radius: 50rem;
  background-color: #00000014;
`
const ChipDelete = styled.img`
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background-color: #fff;
  }
`

export default QB