import * as React from 'react'
import './skeletonEditor.css'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

const SkeletonEditor = ({ type=null }) => {
  const box_radio = type === 'midUnit' ? 4 : 6
  // console.log(box_radio)

  return (
    <>
    <Grid item xs={12} sm={box_radio} md={box_radio} lg={box_radio} >
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          minHeight: 'calc(100vh - 130px)'
        }}
      >
        {Array.from({ length: 2 }).map((item, idx) => (
          <div key={String(idx)} className='skeleton imageBox'></div>
        ))}
      </Paper>
    </Grid>

    <Grid item xs={12} sm={box_radio} md={box_radio} lg={box_radio} >
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          minHeight: '100%',
        }}
      >
        <div>
        {Array.from({ length: 10 }).map((item, idx) => (
          <div key={String(idx)} className='skeleton textBox'></div>
        ))}
        </div>
        <div>
        {Array.from({ length: 10 }).map((item, idx) => (
          <div key={String(idx)} className='skeleton textBox'></div>
        ))}
        </div>
      </Paper>
    </Grid>

    {type === 'midUnit' &&
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            minHeight: '100%',
          }}
        >
          <div>
          {Array.from({ length: 10 }).map((item, idx) => (
            <div key={String(idx)} className='skeleton textBox'></div>
          ))}
          </div>
          <div>
          {Array.from({ length: 10 }).map((item, idx) => (
            <div key={String(idx)} className='skeleton textBox'></div>
          ))}
          </div>
        </Paper>
      </Grid>
    }
    </>
  )
}

export default SkeletonEditor