import * as S from './style/Content.style'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useRef, useEffect, useState, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import Header from './Header'
import DnD from './dnd/DnD'
import TrashColumn from './dnd/TrashColumn'
import FooterImageList from './FooterImageList'

import { useMutation } from 'react-query'
import { putGoOutAPI } from '../../../api/putAPI' 
import { postImageEditorSaveAPI } from '../../../api/postAPI' 
import BackToList from '../../../elements/button/BackToList'
import LayoutModal from '../../../elements/modal/LayoutModal'

import _ from 'lodash'
import toast, { Toaster } from 'react-hot-toast'

import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import OutlinedInput from '@mui/material/OutlinedInput';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import HighlightAltSharpIcon from '@mui/icons-material/HighlightAltSharp';

import { fabric } from 'fabric'

import { CanvasContextStore } from '../../CanvasContext'

import { actionCreators as imageEditorActions } from "../../../redux/modules/imageEditor"
import useInterval from '../../../utils/useInterval'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))


const Content = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {'project_type': project_type, 'project_id': project_id, 'content_id': content_id} = useParams()
  const _project_type = project_type
  const _project_id = Number(project_id)
  const _content_id = content_id
  // console.log(_content_id)
  const imageUrl = process.env.REACT_APP_IMAGE_URL
  const { fabricCanvas, setFabricCanvas, activeCanvas, setActiveCanvas } = useContext(CanvasContextStore)

  const is_sidebar = useSelector((state) => state.imageEditor.sidebar)
  const question_list = useSelector((state) => state.imageEditor.image_url_list)
  const all_box_data = useSelector((state) => state.imageEditor.box_data)
  const all_related_data = useSelector((state) => state.imageEditor.related_data)
  const all_question_data = useSelector((state) => state.imageEditor.question_data)
  // console.log(question_list)
  const canvasRef = useRef([])
  const notifySave = () => toast.success('저장 완료!');

  // ------------------------------------------------------------------------------

  const [ questionSelect, setQuestionSelect ] = useState('')
  const [ relatedSelect, setRelatedSelect ] = useState('')

  const handleQuestionSelect = (e) => {
    setQuestionSelect(e.target.value)
  }
  const handleRelatedSelect = (e) => {
    setRelatedSelect(e.target.value)
  }

  // 푸터 페이지 클릭 -> 해당 페이지 위치로 스크롤 이동
  const scrollToPage = (idx) => {
    setActiveCanvas(idx)
    fabricCanvas.map(canvas => canvas.discardActiveObject().renderAll())
    canvasRef.current && canvasRef.current[idx].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  const handleActiveRect = (page, id) => {
    const targetKlass = fabricCanvas[page]._objects.filter(klass => klass.id === id)
    scrollToPage(page)
    fabricCanvas[page].setActiveObject(targetKlass[0])
    fabricCanvas.map(canvas => canvas.requestRenderAll())
  }


  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const changeBgColor = (klass, property, is_multiple) => {
    // if (property === 'question') klass.set('fill', 'rgba(119, 213, 237, 0.2)')
    if (property === 'unselect') klass.set({ stroke: 'red', strokeWidth: 3 })

    if (property === 'related') klass.set('fill', 'rgba(245, 205, 132, 0.2)')
    if (property === 'question' && is_multiple) klass.set('fill', 'rgba(119, 213, 237, 0.2)')  // blue
    if (property === 'question' && !is_multiple) klass.set('fill', 'rgba(245, 149, 149, 0.2)')  // red
  }

  // [저장]
  const handleStoreData = () => {
        
    // console.log(fabricCanvas)
    if (fabricCanvas.length === 0) return
    // 선택된 박스들
    const activeRectList = fabricCanvas[activeCanvas].getActiveObjects()
    const activeRectCount = activeRectList.length
    // console.log('activeRectList', activeRectList)

    if (activeRectCount === 0) {
      alert('박스를 선택해주세요')
      return
    } else if((!questionSelect && !relatedSelect)) {
      alert('문제 또는 지문을 선택해주세요')
      return
    } else if (activeRectCount > 0 && (questionSelect && relatedSelect)) {
      alert('문제와 지문은 동시에 선택할 수 없습니다')
      return
    }

    let data
    let property
    let is_multiple

    // 문제 or 연관지문 번호로 question_data 찾기 - question_data.num과 일치하는지 확인해서
    if (questionSelect) {
      property = 'question'
      data = all_question_data.filter(obj => obj.num === Number(questionSelect))

      const cloned_Q_data = _.cloneDeep(all_question_data);
      dispatch(imageEditorActions.update_question_data(cloned_Q_data))

    } else if (relatedSelect) {
      property = 'related'
      data = all_related_data.filter(el => el.name === Number(relatedSelect))

      const cloned_R_data = _.cloneDeep(all_related_data);
      dispatch(imageEditorActions.update_related_data(cloned_R_data))
    }

    is_multiple = data[0].multiple // true / false / undefined

    // klass 데이터 변경 - 문제 or 연관지문과 연결 + 배경색 변경
    activeRectList.map(klass => { 
      klass.question_id = property === 'question' ? data[0].id : null
      klass.related_id = property === 'related' ? data[0].id : null
      changeBgColor(klass, property, is_multiple)
    })
    
    fabricCanvas[activeCanvas].requestRenderAll()

    // const jsonOfFabricCanvas = readyForJSON()
    // console.log(jsonOfFabricCanvas)
    // const questionData_json = JSON.stringify(all_question_data)
    // const relatedData_json = JSON.stringify(all_related_data)
    // localStorage.setItem(`TP_${paperId}`, jsonOfFabricCanvas)
    // localStorage.setItem(`TP_${paperId}_Q`, questionData_json)
    // localStorage.setItem(`TP_${paperId}_R`, relatedData_json)
  }


  // fabricCanvas를 localStorage에 저장하기 위해 json 형태를 담은 배열 반환
  const readyForJSON = () => {
    let clonedCanvas = _.cloneDeep(fabricCanvas)
    // console.log(clonedCanvas)
    let json_list = []
    clonedCanvas.forEach(canvas => {
      canvas._objects.forEach(klass => {
        const question_id = klass.question_id
        const related_id = klass.related_id

        if (question_id) {
          const data = all_question_data.filter(obj => 
            obj.id === question_id
          )
          klass.question_id = data[0]
        } else if (related_id) {
          const data = all_related_data.filter(obj => 
            obj.id === related_id
          )
          klass.related_id = data[0]
        } 
        // else return alert('속성이 부여되지 않은 박스가 존재합니다. 재검수 해주세요.')
      })
      // fabric.js에서 canvas를 json으로 변환할 때 추가할 속성들이 정해져 있음
      // toDatalessJSON() 통해 custom 속성 명시 필요
      const propertyToJSON = ['id', 'page', 'related_id', 'question_id', 'aCoords']
      canvas.includeDefaultValues = false
      const json = JSON.stringify(canvas.toJSON(propertyToJSON))
      // canvas.toJSON(propertyToJSON)
      json_list = [...json_list, json]
    })
    // console.log(json_list)
    const fabric_data = JSON.stringify(json_list)
    
    return fabric_data
  }

  
  const handleGroupSelected = useCallback(() => {
    if (!fabricCanvas || fabricCanvas.length === 0) return

    const activeRectList = fabricCanvas[activeCanvas].getActiveObjects()
    
    if (activeRectList.length === 1) {
      setQuestionSelect('') 
      setRelatedSelect('')
      const question_id = activeRectList[0].question_id
      const related_id = activeRectList[0].related_id
      // console.log(question_id)
      // console.log(related_id)
      // console.log(activeRectList)

      if (question_id) {
        const target = all_question_data.filter(obj => obj.id === question_id)
        const target_num = target[0].num
        setQuestionSelect(String(target_num))
      } else if (related_id) {
        const target = all_related_data.filter(obj => obj.id === related_id)
        const target_num = target[0].name
        setRelatedSelect(String(target_num))
      } else {
        setQuestionSelect('') 
        setRelatedSelect('')
      }
    } else {
      setQuestionSelect('') 
      setRelatedSelect('')
    }
  },[all_question_data, all_related_data, activeCanvas, fabricCanvas])
  
  const handleGroupCleared = useCallback((obj) => {
    // console.log('선택 해제')
    setQuestionSelect('') 
    setRelatedSelect('')
  }, [all_question_data, all_related_data, activeCanvas])


  function addRect() {
    const UUID = uuidv4()
    const rect = new fabric.Rect({
      width: 190,
      height: 100,
      fill: 'rgba(166, 166, 166, 0.2)',
      id: UUID,
      page: activeCanvas, 
      related_id: null, 
      question_id: null
    })
    console.log(fabricCanvas)

    if (fabricCanvas.length === 0) return

    rect.setControlsVisibility({mtr: false})
    fabricCanvas[activeCanvas].add(rect)
    fabricCanvas[activeCanvas].centerObject(rect)
    fabricCanvas[activeCanvas].setActiveObject(rect)
    handleCorrectBtn(false)
  }

  const deleteIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='18px' height='18px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:black;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"
  // const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
  const cloneIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 55.699 55.699' width='100px' height='100px' xml:space='preserve'%3E%3Cpath style='fill:%23010002;' d='M51.51,18.001c-0.006-0.085-0.022-0.167-0.05-0.248c-0.012-0.034-0.02-0.067-0.035-0.1 c-0.049-0.106-0.109-0.206-0.194-0.291v-0.001l0,0c0,0-0.001-0.001-0.001-0.002L34.161,0.293c-0.086-0.087-0.188-0.148-0.295-0.197 c-0.027-0.013-0.057-0.02-0.086-0.03c-0.086-0.029-0.174-0.048-0.265-0.053C33.494,0.011,33.475,0,33.453,0H22.177 c-3.678,0-6.669,2.992-6.669,6.67v1.674h-4.663c-3.678,0-6.67,2.992-6.67,6.67V49.03c0,3.678,2.992,6.669,6.67,6.669h22.677 c3.677,0,6.669-2.991,6.669-6.669v-1.675h4.664c3.678,0,6.669-2.991,6.669-6.669V18.069C51.524,18.045,51.512,18.025,51.51,18.001z M34.454,3.414l13.655,13.655h-8.985c-2.575,0-4.67-2.095-4.67-4.67V3.414z M38.191,49.029c0,2.574-2.095,4.669-4.669,4.669H10.845 c-2.575,0-4.67-2.095-4.67-4.669V15.014c0-2.575,2.095-4.67,4.67-4.67h5.663h4.614v10.399c0,3.678,2.991,6.669,6.668,6.669h10.4 v18.942L38.191,49.029L38.191,49.029z M36.777,25.412h-8.986c-2.574,0-4.668-2.094-4.668-4.669v-8.985L36.777,25.412z M44.855,45.355h-4.664V26.412c0-0.023-0.012-0.044-0.014-0.067c-0.006-0.085-0.021-0.167-0.049-0.249 c-0.012-0.033-0.021-0.066-0.036-0.1c-0.048-0.105-0.109-0.205-0.194-0.29l0,0l0,0c0-0.001-0.001-0.002-0.001-0.002L22.829,8.637 c-0.087-0.086-0.188-0.147-0.295-0.196c-0.029-0.013-0.058-0.021-0.088-0.031c-0.086-0.03-0.172-0.048-0.263-0.053 c-0.021-0.002-0.04-0.013-0.062-0.013h-4.614V6.67c0-2.575,2.095-4.67,4.669-4.67h10.277v10.4c0,3.678,2.992,6.67,6.67,6.67h10.399 v21.616C49.524,43.26,47.429,45.355,44.855,45.355z'/%3E%3C/svg%3E%0A"

  let deleteImg = document.createElement('img')
  deleteImg.src = deleteIcon
  let cloneImg = document.createElement('img')
  cloneImg.src = cloneIcon

  function renderIcon(icon) {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size/2, -size/2, size, size);
      ctx.restore();
    }
  }

  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetX: 1,
    offsetY: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    render: renderIcon(deleteImg),
    cornerSize: 16
  })
  fabric.Object.prototype.controls.clone = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetX: -20,
    offsetY: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: copyAndPaste,
    // mouseUpHandler: cloneObject,
    render: renderIcon(cloneImg),
    cornerSize: 14
  })

  function deleteObject(eventData, transform) {
    const target = transform.target
    const canvas = target.canvas
    const targetList = target._objects  // 선택된(그룹으로 묶인) rect들
    const allRectList = target.canvas._objects  // 전체 rect 리스트

    if (targetList) {
      targetList.forEach(klass => {
        canvas.remove(klass)
      })
    } else {
      canvas.remove(target)
    }
        
    // console.log(data)
    target.hasBorders = false
    target.hasControls = false
    canvas.requestRenderAll()
    handleGroupCleared()
    setFabricCanvas([...fabricCanvas])  // 문제 또는 연관 지문의 Box 개수 실시간 업데이트 위해서
    handleCorrectCheck()
  }

  function copyAndPaste() {
    const UUID = uuidv4()
    const curCanvas = fabricCanvas[activeCanvas]
    curCanvas.getActiveObject().clone(function(cloned) {
      cloned.clone(function(clonedObj) {
        curCanvas.discardActiveObject();
        clonedObj.set({
          fill: 'rgba(166, 166, 166, 0.2)',
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
          id: UUID,
          page: activeCanvas, 
          related_id: null, 
          question_id: null
        });
        console.log(clonedObj)
        
        // 복사하려는 객체가 '여러 개'일 경우
        if (clonedObj.type === 'activeSelection') {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = curCanvas;
          clonedObj.forEachObject(function(obj) {
            const uUid = uuidv4()
            obj.set({
              fill: 'rgba(166, 166, 166, 0.2)',
              id: uUid,
              page: activeCanvas, 
              related_id: null, 
              question_id: null
            });
            curCanvas.add(obj);
          });

          // const data_list = clonedObj._objects.map(obj => {
          //   const uUid = uuidv4()
          //   obj.id = uUid
          //   return {id: uUid, page: activeCanvas + 1, origin: obj, related_id: null, question_id: null}
          // })
          // console.log(data_list)
          // dispatch(imageEditorActions.update_box_data([...all_box_data, ...data_list])) 

          // this should solve the unselectability
          clonedObj.setCoords();

        } else {  // 복사하려는 객체가 '하나'일 경우
          curCanvas.add(clonedObj);
        }
        cloned.left += 10
        cloned.top += 10
        curCanvas.setActiveObject(clonedObj);
        curCanvas.requestRenderAll();
      });
    });
  }

  const handleEvent = () => {
    fabricCanvas.map(canvas => canvas.on({
      'selection:created': handleGroupSelected, 
      'selection:updated': handleGroupSelected, 
      'selection:cleared': handleGroupCleared
    }))
  }

  const [ isComplete, setIsComplete ] = useState(false)
  const [ isModal, setIsModal ] = useState(false)
  const [ activeCorrectBtn, setActiveCorrectBtn ] = useState(false)

  const closeModal = () => {
    setIsModal(false)
  }

  const handleCorrectBtn = (bool) => {
    setActiveCorrectBtn(bool)
  }

  // [검수하기] 
  const handleCorrect = () => {
    // console.log(fabricCanvas)
    let clonedCanvas = _.cloneDeep(fabricCanvas)
    const is_not_property_count = clonedCanvas.map(klass => {
      return klass._objects.filter(el => !el.question_id && !el.related_id).length
    }).reduce((a, b) => a + b)

    if (is_not_property_count > 0) return alert('속성이 부여되지 않은 박스가 존재합니다.')
    // if (!activeCorrectBtn) return alert('모든 문제와 지문을 박싱한 후 저장해 주세요.')

    const box_count = clonedCanvas.map(el => el._objects.length).reduce((a, b) => a + b)

    const a = readyForJSON()
    const image_url_list = question_list.map(el => imageUrl + el)
    const b = JSON.stringify(image_url_list)
    // console.log(a)
    // console.log(b)

    dispatch(imageEditorActions.getModalImageAPI(a, b, box_count))
    setIsModal(true)
  }
  
  const { mutate } = useMutation(postImageEditorSaveAPI, {
    onSuccess: data => {
      // console.log(data)
      if (data.message === 'save success') {
        notifySave()
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const { mutate: autoSave_mutate } = useMutation(postImageEditorSaveAPI, {
    onSuccess: data => {
      if (data.message === 'save success') {
        console.log('gggggggggg')
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  // [나가기] 
  const handleGoOut = () => {
    if (window.confirm('저장하지 않은 데이터는 삭제됩니다. 라벨링 에디터를 나가시겠습니까?') === false) return
    navigate(-1)
  }

  useEffect(() => {
    return () => {
      dispatch(imageEditorActions.update_question_data([]))
      dispatch(imageEditorActions.update_related_data([]))
      dispatch(imageEditorActions.setSavedDataJson(null))
    }
  }, [])

  const handleCorrectCheck = () => {
    const activeCorrectButton = fabricCanvas.findIndex(el => el._objects.length === 0)
    if (activeCorrectButton === -1 && !activeCorrectBtn) {
      handleCorrectBtn(true)
    } else if (activeCorrectButton !== -1 && activeCorrectBtn) {
      handleCorrectBtn(false)
    }

    if (activeCanvas >= 0 && fabricCanvas.length > 0) {
      handleEvent()
    }

    // const activeCorrectButton = fabricCanvas.findIndex(el => el._objects.length === 0)
    // const is_not_property_count = fabricCanvas.map(klass => {
    //   return klass._objects.filter(el => !el.question_id && !el.related_id).length
    // }).reduce((a, b) => a + b, 0)

    // if ((activeCorrectButton === -1 && !activeCorrectBtn) || is_not_property_count === 0) {
    //   handleCorrectBtn(true)
    // } else if ((activeCorrectButton !== -1 && activeCorrectBtn) || is_not_property_count > 0) {
    //   handleCorrectBtn(false)
    // }

    // if (activeCanvas >= 0 && fabricCanvas.length > 0) {
    //   handleEvent()
    // }
  }
  
  useEffect(() => {
    handleCorrectCheck()
    return () => {
      fabricCanvas.map(canvas => canvas.off({
        'selection:created': handleGroupSelected, 
        'selection:updated': handleGroupSelected, 
        'selection:cleared': handleGroupCleared
      }))
    }
  }, [activeCanvas, fabricCanvas.length, all_question_data, all_related_data])

  const handleDnDStatus = () => {
    dispatch(imageEditorActions.show_sidebar(!is_sidebar))
  }

  // ctrl + S
  document.onkeydown = (e) => {
    // if (e.ctrlKey && e.key === 's') {
    if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
      e.preventDefault();

      const box_count = all_question_data.length       
      const jsonOfFabricCanvas = readyForJSON()
      const questionData_json = JSON.stringify(all_question_data)
      const relatedData_json = JSON.stringify(all_related_data)
      const all_data_json = `${jsonOfFabricCanvas}__||__${questionData_json}__||__${relatedData_json}`

      const data = {
        pdf_id: _content_id,
        count: box_count,
        annotation_list: all_data_json
      }
      mutate(data)
    } else if (e.keyCode === 27) {
      console.log('ESC Key Event')
    }
  }

  const autoSave = () => {
    const box_count = all_question_data.length       
    const jsonOfFabricCanvas = readyForJSON()
    const questionData_json = JSON.stringify(all_question_data)
    const relatedData_json = JSON.stringify(all_related_data)
    const all_data_json = `${jsonOfFabricCanvas}__||__${questionData_json}__||__${relatedData_json}`

    const data = {
      pdf_id: _content_id,
      count: box_count,
      annotation_list: all_data_json
    }
    autoSave_mutate(data)
  }

  useInterval(()=>{
    autoSave()
  }, 120000)

  return (
    <S.Wrap open={is_sidebar} >
      <S.Container >

        {/* 사이드바 */}
        <Toaster toastOptions={{ className: 'toaster' }} duration="1000" />
        <DnD />

  {/* header */}

        <AppBar position="absolute" open={true}>
          <Toolbar position="raletive">
            <S.FloatingBtn left={is_sidebar} onClick={handleDnDStatus}>
              <Fab color="primary" aria-label="add">
                <ArrowBackIosNewIcon 
                  sx={{
                    transform: `rotate(${is_sidebar ? '0' : '180deg'})`,
                  }} 
                />
              </Fab>
            </S.FloatingBtn>
            
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Prediction
            </Typography>

            <S.ConfirmBtn onClick={addRect}>
              <HighlightAltSharpIcon />
            </S.ConfirmBtn>

            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '2px', width: 320 }}
            >
              <Select
                size='small'
                value={questionSelect}
                onChange={handleQuestionSelect}
                displayEmpty
                sx={{fontSize: '14px', fontWeight: 500, m: '4px', width: '100%', height: 36}}
              >
                <MenuItem value=''>
                  <em>--- ---</em>
                </MenuItem>
                {all_question_data.length > 0 && [...all_question_data].reverse().map((el, idx) => {
                  return <MenuItem key={String(idx)} value={String(el.num)}>문제 {el.num}</MenuItem>
                })}
              </Select>

              <Select
                size='small'
                value={relatedSelect}
                onChange={handleRelatedSelect}
                displayEmpty
                sx={{fontSize: '14px', fontWeight: 500, m: '4px', width: '100%', height: 36}}
              >
                <MenuItem value=''>
                  <em>--- ---</em>
                </MenuItem>
                {all_related_data.length > 0 && [...all_related_data].reverse().map((el, idx) => {
                  return <MenuItem key={String(idx)} value={String(el.name)}>지문 {el.name}</MenuItem>
                })}
              </Select>
            </Paper>

            <S.StoreBtn onClick={handleStoreData} bgColor={questionSelect || relatedSelect}>
              저장
            </S.StoreBtn>

            <S.StoreBtn onClick={handleCorrect} bgColor={true}>
            {/* <S.StoreBtn onClick={handleCorrect} bgColor={activeCorrectBtn}> */}
              검수하기
            </S.StoreBtn>

            <S.StoreBtn onClick={handleGoOut} bgColor={true} width={'max-content'} >
              <span>
                <ArrowBackIosNewIcon sx={{fontSize: '14px'}} />
              </span>
              나가기
            </S.StoreBtn>
            
          </Toolbar>
        </AppBar>

  {/* body */}

        {/* <S.TestPaperFrame width={`${question_list.length * 480}px`} onKeyDown={handleKeyDown} contentEditable={true}> */}
        <S.TestPaperFrame width={`${question_list.length * 480}px`} >
          <S.ItemList>
            {question_list.length > 0 
              && question_list.map((img, idx) => {
                return (
                  <S.TestPaper key={String(idx)} border={activeCanvas === idx} >
                    <S.Image 
                      id='canvas_image'
                      alt={String(idx)} 
                      border={activeCanvas === idx} 
                      src={`${imageUrl}${img}`} 
                      width={480} 
                      height={700} 
                    />
                    <S.Canvas 
                      id={`canvas_${idx}`}
                      // src={`${imageUrl}${img}`} 
                      width={480}
                      height={700}
                      ref={(el) => {canvasRef.current[idx] = el}}
                    />
                    {activeCanvas !== idx 
                      && <S.Hover onClick={() => scrollToPage(idx)} />
                    }
                  </S.TestPaper>
                )
              })
            }
          </S.ItemList>
        </S.TestPaperFrame>
        <FooterImageList
          is_sidebar={is_sidebar}
          question_list={question_list} 
          scrollToPage={scrollToPage} 
          activeCanvas={activeCanvas} 
        />
      </S.Container>
      {isModal &&
        <LayoutModal open={isModal} close={closeModal} type={'imageEditor'} scrollToPage={handleActiveRect} />
      }
    </S.Wrap>
  )
}

export default Content