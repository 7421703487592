import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  Paper,
  TextField,
} from '@mui/material'
import styled from 'styled-components'
import ProgressBar from '../../../elements/progressBar/ProgressBar'

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`
const Container = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  /* overflow-y: auto; */
`
const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  padding: 10px 15px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  /* height: 200px; */
  height: fit-content;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;
const Title = styled.div`
  color: #111111;
  font-weight: 500;
`

const ProgressList = ({dataList}) => {

  return (
    <Wrap>
      <Container>
        {dataList.length > 0 && dataList.map((el, index) => {
          return (
            <CardPadding key={String(index)} style={{paddingBottom: 0}}> 
              <Title>{el.title}</Title>
              <ProgressBar start={el.start_date} end={el.end_date} overall={el.total} rest={el.remain} percent={el.percentage} now={el.now} />
              <Box p="0 10px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" />
            </CardPadding>
          )
        })}
      </Container>
    </Wrap>
  );
};

export default ProgressList;
