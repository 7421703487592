import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Chip from '../../_atom/Chip'
import arrow_down from '../../../assets/icons/select/nav-arrow-down.svg'

interface ChipSelectBoxProps {
  label?: string
  isMultiple?: boolean
  items: any[]
  mainId?: string
  subId?: string
  selectedItem?: any[]
}
const ChipSelectBox = ({
  label = '',
  items = [],
  isMultiple = false,
  mainId = '',
  subId = '',
  selectedItem = []
}: ChipSelectBoxProps) => {
  const [ open, setOpen ] = useState(false)
  const [ selectedItems, setSelectedItems ] = useState<any[]>(selectedItem)
  
  const arrowStyleStatus = open 
    ? ['select__arrow', 'select__arrow_open'].join(' ') 
    : ['select__arrow', 'select__arrow_close'].join(' ')

  const handleOpen = () => {
    if (open) setOpen(false)
    if (!open) setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const parsingId = (_id, id) => {
    return _id !== id
  }
  const parsingId02 = (_id, id) => {
    return _id === id
  }

  const selectOption = (id: number, name: string) => {
    const new_item = {id, name}
    if (!isMultiple) return setSelectedItems([new_item])

    const hasSameThing = selectedItems.filter(v => v.id === id).length > 0

    if (!hasSameThing) {
      setSelectedItems(prev => [...prev, new_item])
    } else {
      setSelectedItems(selectedItems.filter(v => {
        return parsingId(v.id, id)
      }))
    }
  }
  const getStyles = (id, target) => {
    if (target.length === 0) return
    const result = target.find(el => {
      return parsingId02(el.id, id)
    })
    
    return {
      fontWeight: result ? 600 : 400,
      backgroundColor: result ? 'rgba(47, 117, 241, 0.15)' : 'transparent',
    }
  }

  const [ targetWidth, setTargetWidth ] = useState(0)

  useEffect(() => {
    const target = document.getElementById(`container_${subId}`)
    const target_width = target.getBoundingClientRect().width
    setTargetWidth(target_width)

    const container = document.getElementById(`container_${subId}`)
    container?.addEventListener('blur', function(){
      handleClose()
    })
  }, [open])


  return (
    <Container id={`container_${subId}`} tabIndex={0}>
      {label &&
        <div className='label'>{label}</div>
      }
      <div 
        className={[
          'select__outer_box',
          open ? 'select__active' : 'select__inactive'
        ].join(' ')}
        id='select_box'
        onClick={()=>handleOpen()}
      >
        <div className='select__inner_box'>
          {selectedItems.length > 0 && selectedItems.map(el => <Chip key={el.id} id={el.id} label={el.name} mainId={mainId} subId={subId} />)}
        </div>
        <img className={arrowStyleStatus} alt='arrow_down' src={arrow_down}></img>
      </div>
      {/* <div className='select__background_close' onClick={()=>handleClose()}></div> */}
      {open &&
        <div className='select' id='select_option_box'>
          <div className='select__option_box' style={{width: targetWidth}}>
            {items.length > 0 && items.map(el => (
              <div 
                key={el.id} 
                className='select__option'
                style={getStyles(el.id, selectedItems)}
                onClick={() => selectOption(el.id, el.name)}
              >
                {el.name}
              </div>
            ))}
          </div>
        </div>
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: fit-content;

  .label {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.9rem;
    font-weight: 600;
    padding-bottom: 0.4rem;
  }
  .select__outer_box {
    padding: 0 0 0 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    
  }
  .select__outer_box:hover {
    border: 2px solid rgba(0, 0, 0, 0.4);
  }
  .select__inactive {
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  .select__active {
    border: 2px solid #2f75f1;
  }
  .select__inner_box {
    padding: 0.4rem 0;
    padding-right: 0.5rem;
    width: 100%;
    min-height: 2.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    
    /* border: 1px solid red; */
  }
  .select__arrow {
    width: 1.4rem;
    padding: 0 2px;
  }
  .select__arrow_open {}
  .select__background_close {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  .select__option_box {
    padding-top: 0.5rem;
    position: absolute;
    width: 100%;
    min-width: 10rem;
    max-height: 15rem;
    min-height: fit-content;
    overflow-y: auto;
    z-index: 1100;
    cursor: pointer;
    border-radius: 0.3rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* border: 1px solid red; */
  }
  .select__option {
    padding: 0.7rem 1rem;
    width: 100%;
    font-size: 0.9rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .select__option:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

export default React.memo(ChipSelectBox)