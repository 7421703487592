import styled from 'styled-components'
import { useState, useRef, useContext } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ToggleButton from '@mui/material/ToggleButton'
import SettingsIcon from '@mui/icons-material/Settings'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { actionCreators as imageEditorActions } from "../../../../redux/modules/imageEditor";

import _ from 'lodash'

import { CanvasContextStore } from '../../../CanvasContext'

const ItemBox = styled.div`
  border-radius: 3px;
  border: ${props => props.border};
  background-color: ${props => props.bgColor};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
  
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 10px 10px 10px 6px;

  .set_up {
    position: absolute;
    top: 0.5px;
    right: 0.5px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #82c5d1; */

    
  }
  .set_up_inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    /* padding: ${props => props.padding}; */
    /* border: 1px solid red; */

    :hover .tooltip {
      display: flex;
      /* visibility: visible; */
    }
  }
  .tooltip {
    /* visibility: hidden; */
    width: 130px;
    background-color: white;
    /* background-color: RGB(143, 192, 201); */
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 12px 6px;
    display: none;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(143, 192, 201, 0.5);
    /* border: 2px solid rgba(0, 0, 0, 0.8)); */

    /* Position the tooltip */
    position: absolute;
    top: ${props => props.top};
    /* right: ${props => props.right}; */
    /* top: 3px; */
    right: 20px;
    z-index: 1;
  }
  .item_info_box {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 10px;
  }
  .item_info {
    width: fit-content;
    font-size: 13px;
    font-weight: 500;
    color: RGB(72, 100, 130);
  }
`

const Item = ({
  index,
  id,
  num,
  column,
  related,
  multiple,
  questionData,
  moveCardHandler,
  updateQuestionData,
}) => {
  const dispatch = useDispatch()
  const { fabricCanvas } = useContext(CanvasContextStore)
  const all_related_data = useSelector((state) => state.imageEditor.related_data)
  const all_question_data = useSelector((state) => state.imageEditor.question_data)

  // 각각의 question 박스에 할당된 box_data list
  let target_klass_list = []
  fabricCanvas.map(canvas => {
    const targetKlass = canvas._objects.filter(klass => klass.question_id === id )
    target_klass_list = [...target_klass_list, ...targetKlass]
  })
  // console.log(target_klass_list)
  const box_count = target_klass_list.length

  const changeItemColumn = (currentItem, columnName) => {
    const data = questionData.map((el) => {
      if (el.id === currentItem.id && columnName === 'trash') {
        if (box_count > 0) {
          target_klass_list.map(klass => {
            klass.set('fill', 'rgba(166, 166, 166, 0.2)')
            klass.question_id = null
          })
        }
        fabricCanvas.map(canvas => canvas.requestRenderAll())
      }      

      return {
        ...el,
        related: el.id === currentItem.id 
          ? (columnName === '문제' ? null : columnName)
          : el.column,
        column: el.id === currentItem.id ? columnName : el.column,
      };
    });
    
    updateQuestionData(data)
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "Our first type",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCardHandler(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { index, id, num, related, column },
    type: "Our first type",
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      

      if (dropResult) {
        const { id } = dropResult;
        changeItemColumn(item, id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));
  
  const [ type, setType ] = useState(multiple ? 'multiple' : 'essay')
  const handleQuestionType = (e, selectedData) => {
    setType(selectedData)

    if (selectedData === 'essay') {
      const red = 'rgba(245, 149, 149, 0.2)'
      handleChangeTypeColor(red)
    } else if (selectedData === 'multiple') {
      const blue = 'rgba(119, 213, 237, 0.2)'
      handleChangeTypeColor(blue)
    }
    
    const cloned_Q_data = _.cloneDeep(all_question_data)
    const indexOfTarget = cloned_Q_data.findIndex(obj => obj.id === id)
    cloned_Q_data[indexOfTarget].multiple = selectedData === 'multiple' ? true : false
    dispatch(imageEditorActions.update_question_data(cloned_Q_data))
  }

  const handleChangeTypeColor = (bgColor) => {
    target_klass_list.map(klass => {
      klass.set({
        fill: bgColor,
      })
    })
    fabricCanvas.map(canvas => canvas.requestRenderAll())
  }

  const clickBox = () => {
    if (box_count === 0) return
    fabricCanvas.map(canvas => canvas.discardActiveObject())

    // 클릭한 문제 박스와 연관된 지우기 박스들 활성화
    target_klass_list.map(klass => {
      fabricCanvas[klass.page].setActiveObject(klass)
    })
    
    fabricCanvas.map(canvas => canvas.requestRenderAll())
  }

  
  const border = box_count === 0 ? '1px solid rgba(0, 0, 0, 0.1)'
    : ( multiple ? '1px solid #82c5d1' : '1px solid #f2a2a2' )
  const bgColor = box_count === 0 ? 'none'
    : ( multiple ? '#e3f3f7' : '#f7e1e1' )
  const settingIcon = box_count === 0 ? 'rgba(0, 0, 0, 0.2)'
    : ( multiple ? '#82c5d1' : '#f2a2a2' )

  return (
    <ItemBox 
      ref={ref} 
      className='movable-item' 
      style={{opacity}} 
      onClick={clickBox} 
      border={border}
      bgColor={bgColor}
      top={related === null ? '3px' : '-4px' } 
    >
      <DragIndicatorIcon sx={{ width: '18px', color: 'RGB(72, 100, 130)'}} />
      <div className='set_up'>
        <div className='set_up_inner'>
          <SettingsIcon sx={{ width: '13px', height: '13px', color: settingIcon }} />
          <div className='tooltip'>
            <ToggleButtonGroup
              color="primary"
              value={type}
              exclusive
              onChange={handleQuestionType}
              sx={{height: '34px', backgroundColor: 'rgba(255, 255, 255, 0.8)'}}
            >
              <ToggleButton value="multiple" disableRipple={true} disabled={type === 'multiple' ? true : false} sx={{fontSize: '11px', minWidth: 'fit-content'}}>객관식</ToggleButton>
              <ToggleButton value="essay" disableRipple={true} disabled={type === 'essay' ? true : false} sx={{fontSize: '11px', minWidth: 'fit-content'}}>주관식</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
      <div className='item_info_box'>
        <div className='item_info'>문제 {num}</div>
        <div className='item_info'>Box {box_count}</div>
        <div className='item_info'>{multiple ? '객관식' : '주관식'}</div>
      </div>
    </ItemBox>
  )
}

export default Item