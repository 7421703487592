import styled from 'styled-components'
import { memo } from 'react'
import {
  Box,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from '@mui/material'

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
`

const Accordion = (props) => {
  const { name, value, onchange, itemList, disabled } = props
  const handleOnChange = (e) => {
    onchange(e.target.value)
  }

  return (
    <Container>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{name}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Level"
          onChange={(e) => handleOnChange(e)}
          disabled={disabled}
        >
          {itemList?.map(el => {
            return <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
          })}
        </Select>
      </FormControl>
    </Container>
  )
}

export default memo(Accordion)