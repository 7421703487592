import styled from 'styled-components'
import dami_00 from './dami.jpg'
import dami_03 from './dami03.jpg'

const Wrap = styled.div`
  width: 100%;
  height: 4.5rem;
  padding: 0.6rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4rem;
  background-color: white;

  .profile__who {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.7rem;
    overflow: hidden;
    /* border: 1px solid red; */
  }
  .profile__image {
    width: 2.9rem;
    height: 2.9rem;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .profile__name_massage {
    overflow: hidden;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
  }
  .profile__name {
    font-size: 0.9rem;
    font-weight: 600;
  }
  .profile__massage_wrap {
    font-size: 0.85rem;
    font-weight: 400;

    display: inline-flex;
    max-width: 100%;
  }
  .profile__massage {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .profile__when {
    min-width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 3px;
    /* border: 1px solid red; */
  }
  .profile__create_at {
    width: fit-content;
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.8rem;
    font-weight: 600;
    /* border: 1px solid blue; */
  }
`

const Item = () => {
  return (
    <Wrap>
      <div className='profile__who'>
        <img className='profile__image' alt='str_img' src={dami_03} />
        <div className='profile__name_massage'>
          <div className='profile__name'>Joseph</div>
          <div className='profile__massage_wrap'>
            <div className='profile__massage'>Hello~Ho~Hello~Ho~Hello~Ho~</div>
          </div>
        </div>
      </div>
      <div className='profile__when'>
        <div className='profile__create_at'>오후 4:28</div>
      </div>
    </Wrap>
  )
}

export default Item