import styled from 'styled-components'
import ClassicEditor from 'ckeditor5/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import 'katex/dist/katex.css'
import _ from 'lodash'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as editorWritingActions } from "../../../redux/modules/thai/editor_writing"
import { useEffect } from 'react'

const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* border: 1px solid blue; */

  .ck.ck-reset.ck-editor.ck-rounded-corners {
    /* border: 1px solid red; */
    width: 100%;
  }
`
const EssayWrap = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  gap: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
`
const EssayNumWrap = styled.div`
  width: 30px;
`
const EssayNum = styled.div`
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .delete {
    :hover {
      color: #fff;
      background-color: #E62F32;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .add {
    :hover {
      color: #fff;
      background-color: #0099DD;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`

const Button = styled.div`
  width: 1.9rem;
  height: 1.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.7rem;
  cursor: pointer;
`

const EssayQuestion = () => {
  const dispatch = useDispatch()
  const th_editor_writing = useSelector((state) => state.th_editor_writing)
  const {essay_answer_list} = th_editor_writing
  console.log(essay_answer_list)

  const onClickDel = () => {
    if (essay_answer_list.length <= 1) return
    dispatch(editorWritingActions.set_essay_answer_list(essay_answer_list.slice(0, -1)))
  }

  const onClickAdd = () => {
    dispatch(editorWritingActions.set_essay_answer_list([...essay_answer_list, '<p></p>']))
  }

  const onChange_essay = _.debounce((idx, data) => {
    let newArr = [...essay_answer_list]
    newArr[idx] = data
    
    dispatch(editorWritingActions.set_essay_answer_list(newArr))
  }, 200)

  useEffect(() => {
    if (essay_answer_list.length === 0) {
      dispatch(editorWritingActions.set_essay_answer_list(['<p></p>']))
    }
  }, [])

  return (
    <Container>

      {essay_answer_list.length > 0 && essay_answer_list.map((el, idx) => {
        return (
          <EssayWrap key={`essay_answer${idx}`} >
            <EssayNumWrap>
              <EssayNum>{idx + 1}</EssayNum>
            </EssayNumWrap>
            <CKEditor
              editor={ ClassicEditor }
              data={el}
              config={{
                toolbar: {
                  shouldNotGroupWhenFull: true,
                  items: [
                    'alignment',
                    'bold',
                    'italic',
                    'underline',
                    '|',
                    'math', // <---- ADDED | CUSTOM
                    '|',
                    'undo',
                    'redo',
                  ],
                },
              }}
              onChange={ ( event, editor ) => {
                const data = editor.getData();
                onChange_essay(idx, data)
              } }
            />
          </EssayWrap>
        )
      })}
      {/* <BtnWrap>
        <Button className='add' onClick={() => onClickAdd()}>
          <AddIcon />
        </Button>
        <Button className='delete' onClick={() => onClickDel()}>
          <RemoveIcon />
        </Button>
      </BtnWrap> */}
    </Container>
  )
}

export default EssayQuestion