import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'
import LogoutIcon from '@mui/icons-material/Logout'
import IconButton from '@mui/material/IconButton'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Search from './Search'
import { actionCreators as userActions } from "../../redux/modules/user";
import { actionCreators as sidebarActions } from "../../redux/modules/sidebar";

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

type HeaderProps = {
  open: boolean;
  toggleDrawer: () => void;
}

const Header = ({open, toggleDrawer}: HeaderProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const path_name = location.pathname
  const [ pathname, setPathname ] = React.useState<string>()
  const _nation = localStorage.getItem("_nation")
  const _authority = localStorage.getItem("_authority")

  const checkPath = (searchString: string, length?: number) => { // length: 검색을 시작할 위치 (선택)
    if (length) return path_name.includes(searchString, length)
    if (!length) return path_name.includes(searchString)
  }

  const checkDetailedPath = (searchString: string[], length: number[]) => {
    const dividedPathList = path_name.split('/')
    const booleanList = searchString.map((el, index) => {
      return dividedPathList[length[index]] === searchString[index]
    })
    const isSearched = booleanList.findIndex(v => v === false)
    return isSearched === -1
  }

  React.useEffect(() => {
    let path: string

    // 라벨러
    if (_authority !== '0') {
      if (path_name.includes('mypage')) path = '마이페이지'
      if (path_name.includes('dashboard')) path = '공지사항'
      
      if (checkPath('workspace/project')) path = '나의 작업 공간 > 프로젝트'
      if (checkPath('workspace/project') && checkDetailedPath(['content'], [5])) path = '나의 작업 공간 > 프로젝트 > 콘텐츠'
      if (checkPath('workspace/project') && checkDetailedPath(['content', 'question'], [5, 7])) path = '나의 작업 공간 > 프로젝트 > 콘텐츠 > 문제'
      // if (checkPath('workspace/project/labeling')) path = '나의 작업 공간 > 프로젝트 > 시험지 > 문제 > 1차 라벨링 (1)'
      // if (path_name.includes('workspace/labeling/correcting')) path = '나의 작업 공간 > 프로젝트 > 시험지 > 문제 > 에디터'
      if (checkPath('workspace/project/labeling/content/question') && checkDetailedPath(['correcting'], [7])) path = '나의 작업 공간 > 프로젝트 > 콘텐츠 > 문제 > 에디터'
      if (path_name.includes('workspace/project/mid-unit/test-paper/question/correcting')) path = '나의 작업 공간 > 프로젝트 > 콘텐츠 > 문제 > 검수 및 중단원 부여'

      if (path_name.includes('workspace/progress')) path = '나의 작업 상세 확인'
      if (path_name.includes('workspace/progress/detail')) path = '나의 작업 상세 확인 > 작업 현황'
    }

    // 어드민
    if (_authority === '0') {
      if (path_name.includes('notice')) path = '공지사항 '
      if (path_name.includes('notice/registration')) path = '공지사항 > 등록'
      if (path_name.includes('notice/modification')) path = '공지사항 > 수정'

      if (path_name.includes('project/management')) path = '프로젝트 관리 > 매칭된 프로젝트'
      if (path_name.includes('project/management/creation')) path = '프로젝트 관리 > 매칭된 프로젝트 > 생성'
      if (path_name.includes('project/management/modification')) path = '프로젝트 관리 > 매칭된 프로젝트 > 수정'
      if (path_name.includes('project/matching')) path = '프로젝트 관리 > 생성된 프로젝트'
      if (path_name.includes('project/matching/workspace')) path = '프로젝트 관리 > 생성된 프로젝트 > 할당 및 부여'

      if (path_name.includes('labeler/management')) path = '라벨러 관리 > 라벨러 리스트'
      if (path_name.includes('labeler/management/info')) path = '라벨러 관리 > 라벨러 리스트 > 승인 및 수정'
      if (path_name.includes('labeler/matching')) path = '라벨러 관리 > 매칭된 프로젝트'
      if (path_name.includes('labeler/matching/detail')) path = '라벨러 관리 > 매칭된 프로젝트 > 작업 현황'
    }
    
    setPathname(path)
  }, [path_name, _authority])

  // 로그아웃
  const logout = () => {
    if (window.confirm('로그아웃 하시겠습니까?') === true) {
      // localStorage.clear()
      localStorage.removeItem('fabricCanvas')
      localStorage.removeItem('access_token')
      localStorage.removeItem('group')
      localStorage.removeItem('role')
      dispatch(userActions.isLogin(false))
      navigate('/login')
    }
  }

  const handleToggle = () => {
    toggleDrawer()
    dispatch(sidebarActions.isVisible(true))
  }

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleToggle}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, fontSize: '14px' }}
        >
          {/* Prediction */}
          {pathname}
        </Typography>
        
        {_nation !== '2' && <Search />}

        <IconButton 
          color="inherit"
          onClick={logout}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default Header