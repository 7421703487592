import React from 'react';
import styled from 'styled-components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartProps {
  data: any
}

const DoughnutChart = ({data}: ChartProps) => {
  return (
    <Container>
      <Doughnut data={data} />
    </Container>
  )
}

const Container = styled.div`
  /* width: 100%; */
  width: 15rem;
`

export default DoughnutChart
