import styled, { keyframes } from 'styled-components'
import { Card } from '@mui/material';

export const Wrap = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  background-color: #f6f6f6;
`
export const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
`
export const Info = styled.div`
  font-size: 13px;
  font-weight: 500;

  .desc_wrap {
    margin-bottom: 10px;
  }
`