import styled from 'styled-components'
import { useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as writeEditorActions } from "../../../../redux/modules/writeEditor";

import ClassicEditor from 'ckeditor5/build/ckeditor'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import 'katex/dist/katex.css'
import _ from 'lodash'

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput
} from '@mui/material'
import { useTheme } from '@mui/material/styles'


const Wrap = styled.div`
  width: 100%;
  overflow: hidden;
`

const MultipleChoice = () => {
  const dispatch = useDispatch()
  const multiple_html_raw = useSelector((state) => state.writeEditor.multiple_html_raw)
  const multiple_answer_list = useSelector((state) => state.writeEditor.multiple_answer_list)
  const is_multiple_raw_empty = useSelector((state) => state.writeEditor.is_multiple_raw_empty)
  const nation = localStorage.getItem('nation')
  // console.log(multiple_html_raw)

  const onChange_multiple = _.debounce((e) => {
    const dom = document.getElementsByClassName('pr-multiple_box_content')
    const dom_list = Array.from(dom)
    const count = dom_list.length
    console.log(dom_list)
    console.log(count)
    const a = dom_list.filter((el, idx) => {
      if (idx >= count / 6) {
        const b = el.innerText
        return b === '\n'
      }
    })
    console.log(a)
    console.log(a.length)

    // if ( a.length > 0 ) {
    //   if (!is_multiple_raw_empty) dispatch(writeEditorActions.set_is_multiple_raw_empty(true))
    // } else if (is_multiple_raw_empty) (
    //   dispatch(writeEditorActions.set_is_multiple_raw_empty(false))
    // )
    dispatch(writeEditorActions.set_multiple_html_raw(e))
  }, 200)


  

  // 정답 Area ---------------------------------------------------------<<<
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [ 1, 2, 3, 4, 5 ];
  
  function getStyles(name, number, theme) {
    return {
      fontWeight:
      number.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    }
  }

  const theme = useTheme()
  const [number, setNumber] = useState(multiple_answer_list)
  console.log(multiple_answer_list)

  const handleChange = (event) => {
    const { target: { value } } = event
    // setNumber(value)
    function findDuplicates(arr) {
      const filtered = arr.filter((item, index) => arr.indexOf(item) !== index);
      return [...new Set(filtered)]
    }
    console.log(value)
    const a = value.sort().map(el => el.toString())
    console.log(a)
    const rest_list = findDuplicates(a)
    console.log(rest_list)
    const result = a.filter(el => el !== rest_list[0])
    console.log(result)
    setNumber(result)
    dispatch(writeEditorActions.set_multiple_answer_list(result))
  }

  // 정답 Area --------------------------------------------------------->>>

  const [init, setInit] = useState(multiple_html_raw)

  const init_multiple_html = `
    <section class="pr-multiple_box_wrap">
      <div class="pr-multiple_box" data-num="">
        <div class="pr-multiple_box_content_wrap">
          <div class="pr-multiple_box_content">
            <p></p>
          </div>
        </div>
      </div>
      <div class="pr-multiple_box" data-num="①">
        <div class="pr-multiple_box_content_wrap">
          <div class="pr-multiple_box_content">
            <p></p>
          </div>
        </div>
      </div>
      <div class="pr-multiple_box" data-num="②">
        <div class="pr-multiple_box_content_wrap">
          <div class="pr-multiple_box_content">
            <p></p>
          </div>
        </div>
      </div>
      <div class="pr-multiple_box" data-num="③">
        <div class="pr-multiple_box_content_wrap">
          <div class="pr-multiple_box_content">
            <p></p>
          </div>
        </div>
      </div>
      <div class="pr-multiple_box" data-num="④">
        <div class="pr-multiple_box_content_wrap">
          <div class="pr-multiple_box_content">
            <p></p>
          </div>
        </div>
      </div>
      <div class="pr-multiple_box" data-num="⑤">
        <div class="pr-multiple_box_content_wrap">
          <div class="pr-multiple_box_content">
            <p></p>
          </div>
        </div>
      </div>
    </section>
`
  useEffect(() => {
    if (!multiple_html_raw) {
      setInit(init_multiple_html)
      dispatch(writeEditorActions.set_multiple_html_raw(init_multiple_html))
    }
  }, [])

  return (
    <Wrap>
      <CKEditor
        id='multiple_editor'
        editor={ ClassicEditor }
        data={ init }
        config={{
          // removePlugins: [ 'Bold' ],
          toolbar: {
            shouldNotGroupWhenFull: true,
            items: [
              'alignment',
              'bold',
              'italic',
              'underline',
              '|',
              'math', // <---- ADDED | CUSTOM
              '|',
              'undo',
              'redo',
              'multiplePlusBox', // <---- CUSTOM
              'multipleMinusBox', // <---- CUSTOM
            ],
          },
        }}
        onChange={ ( event, editor ) => {
          const data = editor.getData();
          onChange_multiple(data)
        }}
      />
      {nation !== '3' &&
        <FormControl sx={{ mt: 3, ml: 0, minWidth: 100, width: '100%' }}>
          <InputLabel id="demo-multiple-name-label">정답</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            sx={{padding: '0 0.6rem', fontWeight: '600'}}
            multiple
            value={number}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, number, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </Wrap>
  )
}

export default memo(MultipleChoice)