import styled from 'styled-components'

const Wrap = styled.div``

const MyInput = styled.input`
  width: ${({ width }) => width || '400px'};
  height: 60px;
  font-size: 16px;
  padding-left: 25px;

  background: #ffffff;
  border: 2px solid #dddddd;
  border: ${({ border }) => border || '2px solid #dddddd'};
  border-radius: 6px;

  flex: ${({ flex }) => flex || 'none'};

  :focus {
    outline: none;
    border: 2px solid #aaaaaa;
  }
`

export { Wrap, MyInput }
