import { Axios } from './api.config'

// --------------------  라벨러  --------------------

// 1차 에디터 - 나가기
export const putGoOutAPI = async (obj) => {
  const {data} = await Axios.put(`labeling/boxing-update`, obj)
  return data
}

// 마이 페이지 - 정보 변경
export const putMyPageInfoAPI = async (obj) => {
  const {data} = await Axios.put(`account/mypage`, obj)
  return data
}

// 비밀번호 찾기
export const putEditPW = async (pwData) => {
  const {data} = await Axios.put(`account/pw`, pwData)
  return data
}
// --------------------  어드민  --------------------


// 프로젝트 생성 - 프로젝트 id 받기
export const putProjectAPI = async (obj) => {
  const {data} = await Axios.put(`project`, obj)
  return data
}

// 라벨러 관리
  // 승인/수정 - 라벨러 정보 수정
  export const postLabelerInfoAPI = async (obj) => {
    const {data} = await Axios.put(`project/manage`, obj)
    return data
  }

  // 공지사항 수정
  export const putNoticeAPI = async (obj) => {
    const {data} = await Axios.put(`notice`, obj)
    return data
  }