import styles from './table.module.scss'

export default function table({
  col,
  head, 
  body,
  emptyMsg
}: {
  col: JSX.Element
  head: JSX.Element
  body: JSX.Element
  emptyMsg: string | null
}) {
  return (
    <div className={styles.container}>
      <div className={styles.table_wrap}>
        <table className={styles.table}>
          <colgroup>
            {col}
          </colgroup>
          <thead className={styles.thead}>
            <tr>
              {head}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {body}
          </tbody>
        </table>
        {emptyMsg && <div className={styles['empty-msg']}>{emptyMsg}</div>}
      </div>
    </div>
  )
}