import styled from "styled-components/macro"

export const Container = styled.div`
  border: 1px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.4);
`

export const Header = styled.div`
  position: relative;
  display: flex;
  padding: 2px 4px 0;
  cursor: pointer;
`

export const HeaderType = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
`

interface HeaderTypeItemWrapProps {
  display?: string
}

export const HeaderTypeItemWrap = styled.div<HeaderTypeItemWrapProps>`
  position: absolute;
  text-align: center;
  top: -1px;
  left: -1px;
  z-index: 2;
  width: max-content;
  color: #fff;
  font-size: 12px;
  padding: 2px;
  background: #1e88e5;
  display: ${(props: HeaderTypeItemWrapProps) => props.display};
`
export const HeaderTypeItem = styled.div`
  &:hover {
    color: #1e88e5;
    background: #fff;
  }
`

export const MainWrap = styled.div`
  flex: 1;
`