import styles from './table.module.scss'

interface ThProps {
  children?: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  colSpan?: number
  rowSpan?: number
  style?: any
}
const Th = ({
  children, 
  align='center',
  colSpan,
  rowSpan,
  style
}: ThProps) => {
  return <th className={styles.th} scope="col" align={align} colSpan={colSpan} rowSpan={rowSpan} style={style}>{children}</th>
}

export default Th