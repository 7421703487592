import { Axios } from './api.config'

export const getAPI = async (apiURL: string, params?: any) => {
  console.log('=== getAPI ===', apiURL)
  if (!params) return await Axios.get(apiURL)
  if (params) return await Axios.get(apiURL, {params})
}

export const postAPI = async (data: any) => {
  console.log('=== postAPI ===', data)
  return await Axios.post(data.apiURL, data.body, { timeout: 1000000})
}

export const putAPI = async (data: any) => {
  console.log('=== putAPI ===', data)
  if (data.body) return await Axios.put(data.apiURL, data.body)
  if (!data.body) return await Axios.put(data.apiURL)
}

export const deleteAPI = async (data: any) => {
  console.log('=== deleteAPI ===', data)
  // return await Axios.delete(data.apiURL, {data: data.body})
  if (data.body) return await Axios.delete(data.apiURL, {data: data.body})
  if (!data.body) return await Axios.delete(data.apiURL)
}