import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Grid, Paper } from '@mui/material'
import ProjectList from '../../../components/myWorkSpace/editor/ProjectList'
import { useQuery } from 'react-query'
import { getLabelingListAPI } from '../../../api/getAPI'

const ProjectListPage = (props) => {
  const dispatch = useDispatch()
  // const [ pageNum, setPageNum ] = useState(1)

  // const handleSetPageNum = useCallback((page) => {
  //   setPageNum(page);
  // }, [pageNum])

  return (
    // <Container container direction={'column'} >
    //   <ProjectList />
    // </Container>
    <Grid container direction={"column"} >
      
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>프로젝트</Title>
          <ProjectList />
        </Item>
      </Grid>
    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 6px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

export default ProjectListPage