import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';


const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;

const List = React.memo(({ pdfList, totalCount }) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pdfList.length)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  console.log(pdfList)

  return (
    <Wrap style={{position: 'relative'}}>
      <TableContainer component={Paper} sx={{ height: '100%' }} >  
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align="center">No.</Cell>
              <Cell align="center">학년</Cell>
              <Cell align="center">과목</Cell>
              <Cell align="center">출판사</Cell>
              <Cell align="center">배정 부수</Cell>
              <Cell align="center">완료 부수</Cell>
              <Cell align="center">작업 문항 수</Cell>
              <Cell align="center">상태</Cell>
              <Cell align="center">비고</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pdfList.length > 0 && pdfList.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              let grade
              if (row.grade === 0) grade = '중2'
              if (row.grade === 1) grade = '중3'
              if (row.grade === 2) grade = '고1'
              if (row.grade === 3) grade = '고2/고3'
              return (
                <Row
                  key={row.image}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <Cell align="center">{no}</Cell>
                  <Cell align="center">{grade}</Cell>
                  <Cell align="center">{row.subject}</Cell>
                  <Cell align="center">{row.publisher}</Cell>
                  <Cell align="center">{row.all_pdf}</Cell>
                  <Cell align="center">{row.done_pdf}</Cell>
                  <Cell align="center">{row.done_question}</Cell>
                  <Cell align="center">{row.status}</Cell>
                  <Cell align="center">{row.ocr_yn}</Cell>
                </Row>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalCount !== '0' &&
        <TablePagination
          sx={{overflow: 'hidden'}}
          rowsPerPageOptions={[pdfList.length]}
          colSpan={9}
          count={pdfList.length}
          rowsPerPage={pdfList.length}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          // onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
          // ActionsComponent={TablePaginationActions}
        />
      }
    </Wrap>
  )
})

export default List