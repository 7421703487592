// import Command from '@ckeditor/ckeditor5-core/src/command';
// import { Collection, first } from '@ckeditor/ckeditor5-utils';
// // import AutomaticDecorators from '../../../ckeditor5-link/src/utils/automaticdecorators';

// export default class InsertBlockCommand extends Command {
// 	execute(options = {}) {
// 		const model = this.editor.model;
// 		const schema = model.schema;
// 		const selection = model.document.selection;

// 		const blocks = Array.from(selection.getSelectedBlocks());

// 		const value =
// 			options.forceValue === undefined ? !this.value : options.forceValue;

// 		this.editor.model.change((writer) => {
// 			const quotesToMerge = [];

// 			if (!value) {
// 				this._removeQuote(writer, blocks.filter(findQuote));
// 			} else {
// 				const refineBlocks = blocks.filter((block) => {
// 					// Already quoted blocks needs to be considered while quoting too
// 					// in order to reuse their <bQ> elements.
// 					return findQuote(block) || checkCanBeQuoted(schema, block);
// 				});

// 				getRangesOfBlockGroups(writer, refineBlocks)
// 					.reverse()
// 					.forEach((groupRange) => {
// 						let quote = findQuote(groupRange.start);

// 						if (!quote) {
// 							// const blockContent =
// 							// 	writer.createElement('blockContent');
// 							// writer.wrap(
// 							// 	writer.createRange(
// 							// 		groupRange.start,
// 							// 		groupRange.end
// 							// 	),
// 							// 	blockContent
// 							// );

// 							quote = writer.createElement('block');
// 							writer.wrap(groupRange, quote);
// 						}

// 						quotesToMerge.push(quote);
// 					});
// 				quotesToMerge.reverse().reduce((currentQuote, nextQuote) => {
// 					if (currentQuote.nextSibling == nextQuote) {
// 						writer.merge(writer.createPositionAfter(currentQuote));

// 						return currentQuote;
// 					}

// 					return nextQuote;
// 				});
// 			}
// 			// this.editor.model.insertContent(createBlock(writer));
// 		});
// 	}

// 	refresh() {
// 		// const model = this.editor.model;
// 		// const selection = model.document.selection;
// 		// const allowedIn = model.schema.findAllowedParent(
// 		// 	selection.getFirstPosition(),
// 		// 	'block'
// 		// );
// 		// this.isEnabled = allowedIn !== null;

// 		this.value = this._getValue();
// 		this.isEnabled = this._checkEnabled();
// 	}

// 	_removeQuote(writer, blocks) {
// 		// Unquote all groups of block. Iterate in the reverse order to not break following ranges.
// 		getRangesOfBlockGroups(writer, blocks)
// 			.reverse()
// 			.forEach((groupRange) => {
// 				if (groupRange.start.isAtStart && groupRange.end.isAtEnd) {
// 					writer.unwrap(groupRange.start.parent);
// 					writer.unwrap(groupRange.start.parent.parent);

// 					return;
// 				}

// 				// The group of blocks are at the beginning of an <bQ> so let's move them left (out of the <bQ>).
// 				if (groupRange.start.isAtStart) {
// 					const positionBefore = writer.createPositionBefore(
// 						groupRange.start.parent
// 					);

// 					writer.move(groupRange, positionBefore);

// 					return;
// 				}

// 				// The blocks are in the middle of an <bQ> so we need to split the <bQ> after the last block
// 				// so we move the items there.
// 				if (!groupRange.end.isAtEnd) {
// 					writer.split(groupRange.end);
// 				}

// 				// Now we are sure that groupRange.end.isAtEnd is true, so let's move the blocks right.

// 				const positionAfter = writer.createPositionAfter(
// 					groupRange.end.parent
// 				);

// 				writer.move(groupRange, positionAfter);
// 			});
// 	}

// 	_getValue() {
// 		const selection = this.editor.model.document.selection;

// 		const firstBlock = first(selection.getSelectedBlocks());
// 		// In the current implementation, the block quote must be an immediate parent of a block element.
// 		// console.log(
// 		// 	firstBlock,
// 		// 	findQuote(firstBlock),
// 		// 	!!(firstBlock && findQuote(firstBlock))
// 		// );
// 		return !!(firstBlock && findQuote(firstBlock));
// 	}

// 	_checkEnabled() {
// 		if (this.value) {
// 			return true;
// 		}

// 		const selection = this.editor.model.document.selection;
// 		const schema = this.editor.model.schema;

// 		const firstBlock = first(selection.getSelectedBlocks());

// 		if (!firstBlock) {
// 			return false;
// 		}

// 		return checkCanBeQuoted(schema, firstBlock);
// 	}
// }

// function createBlock(writer) {
// 	const block = writer.createElement('block');
// 	const blockContent = writer.createElement('blockContent');

// 	writer.append(blockContent, block);

// 	// There must be at least one paragraph for the description to be editable.
// 	// See https://github.com/ckeditor/ckeditor5/issues/1464.
// 	// writer.appendElement('paragraph', blockContent);

// 	return block;
// }

// function findQuote(elementOrPosition) {
// 	return elementOrPosition.parent.name == 'blockContent'
// 		? elementOrPosition.parent
// 		: null;
// }

// function getRangesOfBlockGroups(writer, blocks) {
// 	let startPosition;
// 	let i = 0;
// 	const ranges = [];

// 	while (i < blocks.length) {
// 		const block = blocks[i];
// 		const nextBlock = blocks[i + 1];

// 		if (!startPosition) {
// 			startPosition = writer.createPositionBefore(block);
// 		}

// 		if (!nextBlock || block.nextSibling != nextBlock) {
// 			ranges.push(
// 				writer.createRange(
// 					startPosition,
// 					writer.createPositionAfter(block)
// 				)
// 			);
// 			startPosition = null;
// 		}

// 		i++;
// 	}

// 	return ranges;
// }
// function checkCanBeQuoted(schema, block) {
// 	// TMP will be replaced with schema.checkWrap().
// 	const isBQAllowed = schema.checkChild(block.parent, 'block');
// 	const isBlockAllowedInBQ = schema.checkChild(['$root', 'block'], block);

// 	return isBQAllowed && isBlockAllowedInBQ;
// }

import { Command } from 'ckeditor5/src/core';
import { first } from 'ckeditor5/src/utils';

export default class InsertBlockCommand extends Command {
	refresh() {
		this.value = this._getValue();
		this.isEnabled = this._checkEnabled();
		this.isNested = this._checkNested();
		// console.log('isNested: ', this.isNested);
	}

	execute(options = {}) {
		const model = this.editor.model;
		const schema = model.schema;
		const selection = model.document.selection;

		const blocks = Array.from(selection.getSelectedBlocks());

		const value =
			options.forceValue === undefined ? !this.value : options.forceValue;
		const nest = options.nest === undefined ? false : options.nest;

		model.change((writer) => {
			if (!nest && !value) {
				this._removeQuote(writer, blocks.filter(findQuote));
			} else {
				const blocksToQuote = blocks.filter((block) => {
					// Already quoted blocks needs to be considered while quoting too
					// in order to reuse their <bQ> elements.
					return findQuote(block) || checkCanBeQuoted(schema, block);
				});

				this._applyQuote(writer, blocksToQuote);
			}
		});
	}

	_getValue() {
		const selection = this.editor.model.document.selection;

		const firstBlock = first(selection.getSelectedBlocks());

		// In the current implementation, the block quote must be an immediate parent of a block element.
		return !!(firstBlock && findQuote(firstBlock));
	}

	_checkEnabled() {
		if (this.value) return true;

		const selection = this.editor.model.document.selection;
		const schema = this.editor.model.schema;

		const firstBlock = first(selection.getSelectedBlocks());

		if (!firstBlock) return false;

		return checkCanBeQuoted(schema, firstBlock);
	}

	_checkNested() {
		const selection = this.editor.model.document.selection;
		const firstPosition = selection.getFirstPosition();
		const lastPosition = selection.getLastPosition();

		if (
			firstPosition.findAncestor('block') &&
			firstPosition.hasSameParentAs(lastPosition)
		) {
			return true;
		} else {
			return false;
		}
	}

	_removeQuote(writer, blocks) {
		// Unquote all groups of block. Iterate in the reverse order to not break following ranges.
		getRangesOfBlockGroups(writer, blocks)
			.reverse()
			.forEach((groupRange) => {
				if (groupRange.start.isAtStart && groupRange.end.isAtEnd) {
					writer.unwrap(groupRange.start.parent);

					return;
				}

				// The group of blocks are at the beginning of an <bQ> so let's move them left (out of the <bQ>).
				if (groupRange.start.isAtStart) {
					const positionBefore = writer.createPositionBefore(
						groupRange.start.parent
					);

					writer.move(groupRange, positionBefore);

					return;
				}

				// The blocks are in the middle of an <bQ> so we need to split the <bQ> after the last block
				// so we move the items there.
				if (!groupRange.end.isAtEnd) {
					writer.split(groupRange.end);
				}

				// Now we are sure that groupRange.end.isAtEnd is true, so let's move the blocks right.

				const positionAfter = writer.createPositionAfter(
					groupRange.end.parent
				);

				writer.move(groupRange, positionAfter);
			});
	}

	_applyQuote(writer, blocks) {
		const quotesToMerge = [];

		// Quote all groups of block. Iterate in the reverse order to not break following ranges.
		getRangesOfBlockGroups(writer, blocks)
			.reverse()
			.forEach((groupRange) => {
				let quote = findQuote(groupRange.start);

				// if (!quote) {
				quote = writer.createElement('block', { 'data-type': '' });
				writer.wrap(groupRange, quote);
				// writer.insert(quote, groupRange.start);
				// }

				quotesToMerge.push(quote);
			});

		// Merge subsequent <bQ> elements. Reverse the order again because this time we want to go through
		// the <bQ> elements in the source order (due to how merge works – it moves the right element's content
		// to the first element and removes the right one. Since we may need to merge a couple of subsequent `<bQ>` elements
		// we want to keep the reference to the first (furthest left) one.
		quotesToMerge.reverse().reduce((currentQuote, nextQuote) => {
			if (currentQuote.nextSibling == nextQuote) {
				writer.merge(writer.createPositionAfter(currentQuote));

				return currentQuote;
			}

			return nextQuote;
		});
	}
}

function findQuote(elementOrPosition) {
	return elementOrPosition.parent.name == 'block'
		? elementOrPosition.parent
		: null;
}

// Returns a minimal array of ranges containing groups of subsequent blocks.
//
// content:         abcdefgh
// blocks:          [ a, b, d, f, g, h ]
// output ranges:   [ab]c[d]e[fgh]
//
// @param {Array.<module:engine/model/element~Element>} blocks
// @returns {Array.<module:engine/model/range~Range>}
function getRangesOfBlockGroups(writer, blocks) {
	let startPosition;
	let i = 0;
	const ranges = [];

	while (i < blocks.length) {
		const block = blocks[i];
		const nextBlock = blocks[i + 1];

		if (!startPosition) {
			startPosition = writer.createPositionBefore(block);
		}

		if (!nextBlock || block.nextSibling != nextBlock) {
			ranges.push(
				writer.createRange(
					startPosition,
					writer.createPositionAfter(block)
				)
			);
			startPosition = null;
		}

		i++;
	}

	return ranges;
}

// Checks whether <bQ> can wrap the block.
function checkCanBeQuoted(schema, block) {
	// TMP will be replaced with schema.checkWrap().
	const isBQAllowed = schema.checkChild(block.parent, 'block');
	const isBlockAllowedInBQ = schema.checkChild(['$root', 'block'], block);

	return isBQAllowed && isBlockAllowedInBQ;
}
