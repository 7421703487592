import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../components/_templates/MainSection'
import Table from '../../components/_organisms/mainSection/Table'
import Toolbar from '../../components/_organisms/mainSection/Toolbar'
import ProgressBarOrigin from '../../elements/progressBar/ProgressBarOrigin'
import { useQuery, useQueryClient } from 'react-query'
import { getAPI } from '../../api/getAPI'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor};
`
const NoneList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #2e6ef2;
  font-size: 1.2rem;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.05);

  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`
const IsSelected = styled.div`
  color: ${props => props.color};
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any[]
  project_type: string
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], project_type=''}: tableBodyProps) => {
  const navigate = useNavigate()
  const onClick = (question_id: number) => {
    if (project_type === 'labeling') {
      navigate(`/workspace/project/labeling/content/question/${question_id}/correcting`)
    } else if (project_type === 'review') {
      // navigate(`/workspace/project/mid-unit/test-paper/question/correcting/${question_id}`)
      navigate(`/workspace/project/review/content/question/${question_id}`)
    }
  }

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        return (
          <Row
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={()=>onClick(row.id)}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="left">{row.title}</Cell>
            <Cell align="center">{row.id}</Cell>
            <Cell align="center">
              {row.status === 1 && <Circle bgColor={'#ddd'} />}
              {row.status === 2 && <Circle bgColor={'red'} />}
              {row.status === 3 && <Circle bgColor={'orange'} />}
              {row.status === 4 && <Circle bgColor={'#66CC33'} />}
            </Cell>
            <Cell align="center">{row.is_difficult ? 'O' : 'X'}</Cell>
            <Cell align="center">
              {row.is_selected && <IsSelected color={'#1F8A70'}>객관식</IsSelected>}
              {!row.is_selected && <IsSelected color={'#DF7857'}>주관식</IsSelected>}
            </Cell>
          </Row>
        )
      })}
    </>
  )
}

const Question = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {'content_id': content_id, 'project_id': project_id, 'project_type': project_type} = useParams()
  const _content_id = content_id
  const _project_id = Number(project_id)
  const _project_type = project_type
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _status = Number(searchParams.get('status'))

  const headList = ['문제명', '문제 ID', '진행 상황', '난이도', '객/주관식']
  const [bodyList, setBodyList] = useState(null)
  const [title, setTitle] = useState('')
  const [isEmptyQuestion, setIsEmptyQuestion] = useState(false)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [ progressInfo, setProgressInfo ] = useState({})

  // ----- filter -----
  const [status, setStatus] = useState(_status)
  const statusList = [
    {name: '전체', value: 0},
    {name: '작업 미완료', value: 1},
    {name: '오류 검출', value: 2},
    {name: '재검수 대기', value: 3},
    {name: '작업 완료', value: 4},
  ]

  const { isFetching } = useQuery([`${_project_type}/contents/${_content_id}/questions`, {page, status}], () => getAPI(`${_project_type}/contents/${_content_id}/questions`, {page: page + 1, unit: 1, status: status}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      setTitle(data.data.title)
      if (data.data.page.content_count === 0 && data.data.questions === null) return setIsEmptyQuestion(true)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.questions} project_type={_project_type} />
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
      // setIsEmptyQuestion(false)
    }
  })

  const changedStatus = (v: number) => {
    setSearchParams({
      page: 1, 
      status: v, 
    })
  }

  const changedPage = (v: number) => {
    setSearchParams({
      page: v + 1, 
      status: status, 
    })
  }

  useEffect(()=>{
    queryClient.invalidateQueries("alarmList")
  }, [])

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setStatus(_status)
  }, [_status])

  const GoToEditor = () => {
    navigate(`/workspace/project/${_project_type}/${_project_id}/content/${_content_id}/editor`)
  }


  return (
    <MainSection>
      <>
        <Toolbar title={`Questions - ${title}`} isFilter={!isEmptyQuestion} filter={status} filterList={statusList} getFilter={changedStatus} filterLabel={'필터'} filterBoxWidth={'9rem'} isButtonCus={_project_type === 'labeling' && true} onClickButton={GoToEditor} buttonName={'에디터 작업하기'} />
        {isEmptyQuestion &&
          <NoneList onClick={GoToEditor}>
              1차 에디터 작업을 완료해 주세요.
          </NoneList>
        }
        {!isEmptyQuestion &&
          <Table 
            headList={headList} 
            bodyList={bodyList} 
            page={page} 
            setPage={changedPage}
            rowsPerPage={rowsPerPage} 
            setRowsPerPage={setRowsPerPage}
            count_total={totalCount}
            isLoading={isFetching}
          />
        }
      </>
    </MainSection>
  )
}

export default Question