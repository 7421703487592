import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'

import { useQuery } from 'react-query'
import { getAPI } from '../../../api/getAPI'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`
const Status = styled.div`
  color: ${props => props.color};
  font-weight: 500;
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor};
`
const BackModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  transition: all 360ms ease;
`
const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 80%;
  max-height: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px;

  transition: all 360ms ease;

  .modal_title {
    color: #222222;
    font-size: 18px;
    font-weight: 700;
  }
  .modal_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any[]
  openModal?: (projectId: number, role: number, labeler: any)=>void
}

// export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], openModal=()=>{}}: tableBodyProps) => {
//   const navigate = useNavigate()
//   const onClick = (projectId: number) => {
//     navigate(`/labeler/matching/test-paper/${projectId}?page=1&filter=`)
//   }

//   return (
//     <>
//       {list.length > 0 && list.map((row: any, index: number) => {
//         const no = page * rowsPerPage + index + 1
//         return (
//           <Row
//             key={row.id}
//             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             onClick={()=>onClick(row.id)}
//           >
//             <Cell align="center">{no}</Cell>
//             <Cell align="left">{row.title}</Cell>
//             <Cell align="center">{row.date}</Cell>
//             <Cell align="center">{row.status}</Cell>
//             <Cell align="center">
              // <Btn onClick={(e) => openModal(e, row.id, row.role, row.labeler)} >
              //   라벨러 현황 ❯
              // </Btn>
//             </Cell>
//           </Row>
//         )
//       })}
//     </>
//   )
// }

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], openModal=()=>{}}: tableBodyProps) => {
  const navigate = useNavigate()
  const onClick = (projectId: number, projectType: string) => {
    navigate(`/workspace/project/${projectType}/${projectId}/content?page=1&subject=`)
  }
  const goToMatching = (e: any, id: number, type: string, title: string, is_books: boolean) => {
    e.stopPropagation()
    navigate({
      pathname: `/project/matching/workspace`,
      search: `?${createSearchParams({projectId: id, role: type === 'labeling' ? 0 : 1, title: title, is_book: is_books})}`
    })
  }
  let no = page * rowsPerPage + 1
  let new_list: any[] = []
  list.length > 0 && list.map((row: any, index: number) => {
    const row_comp = (type: string, no: number, id: number, title: string, term: string, is_finished:boolean, status: number, user: any[]) => {
      const comp = 
        <Row
          key={id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          onClick={()=>onClick(id, type)}
        >
          <Cell align="center">{no}</Cell>
          <Cell align="left">{title} - {type === 'labeling' ? '1차' : '2차'}</Cell>
          <Cell align="center">{term}</Cell>
          <Cell align="center">
            {type === 'labeling' && '-'}
            {type !== 'labeling' && !is_finished && <Circle bgColor={'red'} />}
            {type !== 'labeling' && is_finished && <Circle bgColor={'#66CC33'} />}
          </Cell>
          <Cell align="center">
            {status === 1 && <Status color={'#ddd'}>대기</Status>}
            {status === 2 && <Status color={'#FC7300'}>진행 중</Status>}
            {status === 3 && <Status color={'#66CC33'}>완료</Status>}
          </Cell>
          <Cell align="center">
            <Btn onClick={(e) => openModal(e, id, type === 'labeling' ? 0 : 1, user)} >
              라벨러 현황 ❯
            </Btn>
          </Cell>
        </Row>
      new_list.push(comp)
    }
    if (row.labeling_data) {
      row_comp('labeling', no, row.labeling_data.id, row.title, row.labeling_data.term, row.is_finished_labeling, row.labeling_data.status, row.labeling_data.labeler)
      no += 1
    }
    if (row.review_data) {
      row_comp('review', no, row.review_data.id, row.title, row.review_data.term, row.is_finished_labeling, row.review_data.status, row.review_data.reviewer)
      no += 1
    }
  })

  return new_list
}

const MatchingPage = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _search = searchParams.get('search')
  const _status = Number(searchParams.get('status'))

  const headList = ['프로젝트명', '기간', '1차 완료 여부', '진행 상황', '']
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [keyword, setKeyword] = useState(_search)

  const [isModal, setIsModal] = useState(false)
  const [infoInModal, setInfoInModal] = useState({id: 0, role: 0})
  const [labelersInModal, setLabelersInModal] = useState([])
  const openModal = (e: any, projectId:number, role:number, user:any[]) => {
    e.stopPropagation()
    setInfoInModal({id: projectId, role: role})
    setLabelersInModal(user)
    setIsModal(true)
  }

  // ----- filter -----
  const [status, setStatus] = useState(_status)
  const statusList = [
    {name: '전체', value: 0},
    {name: '대기', value: 1},
    {name: '진행 중', value: 2},
    {name: '완료', value: 3},
  ]

  const { isFetching } = useQuery(['project', page, keyword, status], () => getAPI('projects', {page: page + 1, unit: 1, project_name: keyword, status: status}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.projects} openModal={openModal} />
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
    }
  })

  const changedPage = (v: number) => {
    setSearchParams({
      page: v + 1, 
      search: keyword,
      status: status, 
    })
  }

  const changedKeyword = (v: string) => {
    setSearchParams({
      page: 1, 
      search: v,
      status: status,
    })
  }

  const changedStatus = (v: number) => {
    setSearchParams({
      page: 1, 
      search: keyword,
      status: v, 
    })
  }

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setKeyword(_search)
  }, [_search])

  useEffect(() => {
    setStatus(_status)
  }, [_status])


  return (
    <MainSection>
      <>
        {isModal &&
          <>
            <BackModal onClick={()=>setIsModal(false)}></BackModal>
            <Modal>
              <div className='modal_title'>라벨러 현황</div>
              <div className='modal_content'>
                {labelersInModal?.map((el:any, idx) => {
                  return (
                    <Btn key={el.id} onClick={() => 
                      navigate({
                        pathname: `/labeler/matching/detail`,
                        search: `?${createSearchParams({projectId: infoInModal.id, labelerId: el.id, role: infoInModal.role})}`
                      })
                    }>
                      {el.name} ❯
                    </Btn>
                  )
                })}
                {!labelersInModal &&
                  <div style={{margin: '0 auto 20px'}}>없음</div>
                }
              </div>
            </Modal>
          </>
        }
        <Toolbar title={'생성된 프로젝트'} isSearch keyword={keyword} getKeyword={changedKeyword} isFilter filter={status} filterList={statusList} getFilter={changedStatus} filterLabel={'필터'} filterBoxWidth={'9rem'} />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

export default MatchingPage