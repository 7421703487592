import { useState, useEffect, useCallback, useRef, Component } from 'react'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAPI, postAPI, putAPI, deleteAPI } from '../../../../api'

import _ from 'lodash'
import { Axios } from '../../../../api/api.config'
import styled, {keyframes} from 'styled-components'
import uploadIcon from '../../../../assets/icons/upload.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'
import deleteDisabledIcon from '../../../../assets/icons/delete-disabled.svg'
import runningHorse from '../../../../assets/images/horse/running_horse_clean_01.gif'
import MainSection from '../../../../components/_templates/MainSection'
import Toolbar from '../../../../components/_organisms/mainSection/Toolbar'

import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Detail = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { id } = useParams()
  const _id = id ? Number(id) : null // number
  const [searchParams] = useSearchParams()
  const _service = searchParams.get('service')
  const _tab = searchParams.get('tab')
  const _name = localStorage.getItem("upload-creation-name")
  const host = 'https://d25wu8avft9qw6.cloudfront.net/'

  useEffect(()=>{
    return () => {
      localStorage.removeItem('upload-creation-name')
    }
  }, [])

  const [isInitial, setIsInitial] = useState<boolean>(false) // only in thai
  const [initialData, setInitialData] = useState<any>(null) // only in thai

  const [isEditable, setIsEditable] = useState<boolean>(true)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [pdfFiles, setPdfFiles] = useState([])
  const [pdfCount, setPdfCount] = useState<number>(0)
  const [isFetchingPOST, setIsFetchingPOST] = useState<boolean>(false)

  const { mutate: POST_API } = useMutation(postAPI)
  const { mutate: PUT_API } = useMutation(putAPI)
  const { mutate: DELETE_API } = useMutation(deleteAPI)
  const {data, isFetching} = useQuery([`upload/${_id}/${_service}/pdf`, {_id, _service}], () => 
    getAPI(`upload/${_id}/${_service}/pdf`, {name: _name}), {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!(_id),
      onSuccess: data => {
        console.log('성공했다', data?.data)
        if (data?.status !== 200 && data?.data.uploads.length === 0) return
     
        if (data?.data.is_initial) {
          // setIsInitial(data?.data.is_initial)
          setInitialData(data?.data.uploads)
          return
        }
        
        setIsEditable(data?.data.is_editable)
        const row_sub_section_list = data?.data.uploads.map((e: any) => {
          const sub_Section = e.url.split(host)[1].split('_').slice(-2, -1)[0]
          return sub_Section
        })
        const sub_section_list = row_sub_section_list.reduce((acc, v) => {
          return acc.includes(v) ? acc : [...acc, v];
        }, []).filter(e => e)

        group_images(data?.data.uploads, sub_section_list)
      }
    }
  )

  const save = () => {
    let new_data: any[] = []
    const isPass = pdfFiles.find((e: any) => {
      if (!e.question || !e.explanation) return true
      delete e.name
      new_data.push(e)
      return false
    })
    if (isPass) return alert('문제 또는 해설이 비어있어요!')
    if (!pdfCount) return alert('pdf를 업로드 해주세요.')
    if (window.confirm('최종 저장을 하시겠습니까? 저장이 완료된 후에는 수정이 불가능합니다!') === false) return

    let data = {
      apiURL: `/upload/${_id}/${_service}/grouping`,
      body: new_data
    }

    POST_API(data, {
      onSuccess: (data) => {
        if (data.status === 200) {
          queryClient.invalidateQueries(`upload/${_id}/${_service}/pdf`)
          alert('저장이 완료되었습니다.')

          let data = {
            apiURL: `/upload/${_id}/${_service}/pdf`,
          }

          POST_API(data)

          const pathQuerystring = localStorage.getItem('prevPath')
          if (pathQuerystring === 'null' || pathQuerystring === null || pathQuerystring.includes('/upload/pdf/modification')) {
            navigate('/upload/pdf?service=books&page=1&search_filter=1&search=&filter=0', {replace: true})
          } else {
            navigate(pathQuerystring, {replace: true})
          }
        }
      },
      onError: (err) => {
        if (err.response.data.error === "not exist section and subsection") 
          alert('중단원 링킹이 되지 않았습니다. 중단원 링킹 작업을 먼저 진행해주세요.')
      }
    })
  }

  const count_files = (v: any) => {
    let total_count = 0
    v.forEach(e => {
      if (e.question) total_count += 1
      if (e.explanation) total_count += 1
      if (e.concept) total_count += 1
    })
    return total_count
  }

  const deleteFile = (sub_section: string, img_data: {pdf_id: number, url: string}, type: string) => {
    console.log(sub_section, img_data, type)
    let data = {
      apiURL: `/upload/file?upload_id=${_id}&&type=${'pdf'}&&service=${_service}&&pdf_id=${img_data.pdf_id}&&pre_url=${img_data.url}`,
    }

    DELETE_API(data, {
      onSuccess: (data) => {
        console.log(data)
        if (data.status !== 200) return

        setPdfFiles(prev => {
          const new_list = prev.map((e: any) => {
            if (e.name === sub_section) e[type] = null
            return e
          })
          const total_count = count_files(new_list)
          setPdfCount(total_count)
          return [...new_list]
        })
      },
    })
  }

  const uploadFile = (e: any, sub_section: string, img_data: any, type: string) => {
    console.log(img_data)
    setIsFetchingPOST(true)
    const new_file = e.target.files[0]
    if (!new_file) return

    const formData: any = new FormData()
    formData.append(`file`, new_file)

    let data = {
      apiURL: ``,
      body: formData
    }

    if (img_data) { // 기존 데이터 존재
      // data.apiURL = `/upload/file?upload_id=${_id}&&type=${'pdf'}&&service=${_service}&&pdf_id=${img_data.pdf_id}&&pre_url=${img_data.url}&&name=${img_data.url.split(host)[1]}`
      data.apiURL = `/upload/file?upload_id=${_id}&&type=${'pdf'}&&service=${_service}&&pdf_id=${img_data.pdf_id}&&pre_url=${img_data.url}&&name=${new_file.name}`
      PUT_API(data, {
        onSuccess: (data) => {
          console.log(data)
          if (data?.status !== 200) return
          const new_data = {pdf_id: data?.data.pdf_id, url: data?.data.url}

          setPdfFiles(prev => {
            const new_list = prev.map((e: any) => {
              if (e.name === sub_section) e[type] = new_data
              return e
            })
            const total_count = count_files(new_list)
            setPdfCount(total_count)
            return [...new_list]
          })
          setIsFetchingPOST(false)
        },
      })
    } else { // 비어 있는 상태
      data.apiURL = `/upload/file?upload_id=${_id}&&type=${'pdf'}&&service=${_service}&&name=${new_file.name}`
      POST_API(data, {
        onSuccess: (data) => {
          console.log(data)
          if (data?.status !== 200) return
          const new_data = {pdf_id: data?.data.pdf_id, url: data?.data.url}

          setPdfFiles(prev => {
            const new_list = prev.map((e: any) => {
              if (e.name === sub_section) e[type] = new_data
              return e
            })
            const total_count = count_files(new_list)
            setPdfCount(total_count)
            return [...new_list]
          })
          setIsFetchingPOST(false)
        },
      })
    }
  }

  const upload_S3 = async (e: any, init: boolean) => {
    const selectedFiles: any[] = [...e.target.files]

    let imageSize: number = 0
    const maxSize: number = 1024 ** 3 // 1 GB
    selectedFiles.forEach((e: any) => {
      imageSize += e.size
    })
    if (imageSize > maxSize) return alert('선택하신 파일의 용량이 1GB를 넘어요! 1GB 미만의 파일을 업로드해 주세요.')

    const row_sub_section_list = selectedFiles.map(e => {
      const _list = e.name.split('_')
      let _sub_section = _list.length > 2 && _list[_list.length - 2]
      return _sub_section
    })
    const sub_section_list = row_sub_section_list.reduce((acc, v) => {
      return acc.includes(v) ? acc : [...acc, v];
    }, []).filter(e => e).map(e => e?.normalize('NFC'))

    let result: any[] = []
    sub_section_list.forEach((e: any) => {
      let files: any[] = []
      selectedFiles.forEach((v: any) => {
        const _list = v.name.split('_')
        const _sub_section = _list[_list.length - 2]?.normalize('NFC')
        const _type = _list[_list.length - 1]?.normalize('NFC') // 문제, 해설, 개념
        if (_sub_section === e && (_type === '문제.pdf' || _type === '해설.pdf' || _type === '개념.pdf'))
          return files = [...files, v]
      })
      return result = [...result, ...files]
    })
    // console.log(result, sub_section_list)
    // console.log('\x1B[1m[Before] ', pdfFiles)
    if (result.length === 0) return alert('파일명의 형식이 제대로 지켜지지 않았습니다.')
    setIsFetchingPOST(true)

    const formData: any = new FormData()
    for (let i = 0; i < result.length; i++) {
      formData.append(`files`, result[i])
    }

    let data = {
      apiURL: `/${init ? 're-upload' : 'upload'}/files?upload_id=${_id}&&type=${'pdf'}&&service=${_service}`,
      body: formData
    }
    // 갈아 엎는 게 아니라 추가하도록 지연님께 요청

    POST_API(data, {
      onSuccess: (data) => {
        console.log(data)
        // if (data.status === 200) group_images(data.data, sub_section_list)
        if (data.status === 200) {
          console.log('\x1B[1m[ROW] ', data.data)
          group_images(data.data, sub_section_list, init)
        }
      },
    })
  }

  const group_images = (url_list: {pdf_id: number, url: string}[], sub_section_list: string[], init) => {
    const res = sub_section_list.map(e => {
      let data = {
        name: e,
        question: null,
        explanation: null,
        concept: null,
      }
      url_list.forEach(v => {
        const _name = v.url.split(host)[1]
        const _list = _name.split('_')
        const _sub_section = _list[_list.length - 2]
        const _type = _list[_list.length - 1] // 문제, 해설, 개념
    
        if (_sub_section === e) {
          if (_type === '문제.pdf') return data.question = v
          if (_type === '해설.pdf') return data.explanation = v
          if (_type === '개념.pdf') return data.concept = v
        }
      })
      return data
    })
    console.log(res)

    const total_count = count_files(res)
    setPdfCount(total_count)

    if (init) {
      const total_count = count_files(res)
      setPdfCount(total_count)
      setPdfFiles(res)
    } else {
      setPdfFiles(prev => {
        console.log('\x1B[1m[Current] ', prev)
        const hasData = prev.length === 0
        console.log(hasData)
        if (hasData) return res
          else {
            console.log('\x1B[1m[Process] ', res)
            let fillData = prev.map((e: any) => {
              const sameNameData = res.find((v: any) => v.name === e.name)
              console.log(sameNameData)
              if (sameNameData) {
                if (sameNameData.question) e.question = sameNameData.question
                if (sameNameData.explanation) e.explanation = sameNameData.explanation
                if (sameNameData.concept) e.concept = sameNameData.concept
              }
              console.log(e)
              return e
            })
  
            const restData = res.filter((e: any) => {
              const isDuplicated = !!(prev.find((v: any) => v.name === e.name))
              return !isDuplicated
            })
  
            const _res = [...fillData, ...restData]
            console.log(_res)
            const total_count = count_files(_res)
            setPdfCount(total_count)
  
            return _res
          }
      })
    }
    setIsFetchingPOST(false)
  }
  
  const split_name = (name: string) => {
    if (!name) return ''
    return name.split(host)[1]
  }

  const onPreview = (url: string) => {
    if (!url) return
    setPreviewImage(url)
    setIsPreview(true)
  }
  const offPreview = () => {
    setIsPreview(false)
    setNumPages(0)
    setPageNumber(1)
  }

  const [numPages, setNumPages] = useState<number>(0) // 전체 페이지 수
  const [pageNumber, setPageNumber] = useState<number>(1) // 현재 페이지

  const onClickPageArrow = (e: any, position: string) => {
    e.stopPropagation()
    if (position === 'left') setPageNumber(pageNumber === 1 ? pageNumber : pageNumber - 1)
    if (position === 'right') setPageNumber(numPages === pageNumber ? pageNumber : pageNumber + 1)
  }

  const reset = () => {
    setPdfFiles([])
    setPdfCount(0)
  }
  const getHorse = (n: number) => {
    if (n === 0) {
      return (
        <img 
          id='ddung'
          alt='running_horse'
          src='https://c.tenor.com/ijacVPaTqKIAAAAi/%EB%9A%B1%EC%9D%B4%EC%9B%80%EC%A7%A4.gif' 
        />
      )
    }
    if (n === 1) {
      return (
        <img id='brown_horse' alt='running_horse' src={runningHorse} />
      )
    }
    if (n === 2) {
      return (
        <img 
          id='pikachu'
          alt='running_horse'
          src='https://media.tenor.com/fSsxftCb8w0AAAAi/pikachu-running.gif' 
        />
      )
    }
  }
  console.log(initialData, previewImage)

  return (
    <MainSection isHidden>
      {!isFetching 
        ?
        <>
          <Toolbar
            title='PDF 업로드'
            isSave={isEditable && pdfCount > 0}
            save={()=>save()}
            isButtonCus={isEditable && pdfCount > 0}
            buttonName={'Reset'}
            onClickButton={()=>reset()} 
          />
          <Container>
            {isFetchingPOST && (
              <div className='back-loading'>
                {/* <div className='waiting-time'>약 {s3UploadCount}초 소요 예정입니다.</div> */}
                <LoadingHorse id='loading_horse'>
                  {getHorse(Math.floor(Math.random()*3))}
                </LoadingHorse>
              </div>
            )}
            {isPreview &&
              <Preview>
                <div className='preview-page-wrap'>
                  <div className='preview-exit' onClick={offPreview}>
                    <img alt='preview-delete' src={deleteIcon} />
                  </div>
                  <div className='preview-pagenation'>
                    <div className='preview-pagenation-arrow' onClick={(e)=>onClickPageArrow(e, 'left')}>{'<'}</div>
                    <div className='preview-pagenation-center'>Page {pageNumber} of {numPages}</div>
                    <div className='preview-pagenation-arrow' onClick={(e)=>onClickPageArrow(e, 'right')}>{'>'}</div>
                  </div>
                  <Document
                    className='preview-document'
                    file={`${previewImage}?random=${Math.random()}`}
                    onLoadSuccess={pdf => setNumPages(pdf.numPages)}
                    options={{
                      standardFontDataUrl: 'standard_fonts/',
                    }}
                  >
                    <Page className='preview-page' pageNumber={pageNumber} /> 
                  </Document>
                </div>
              </Preview>
            }
            <div className='title'>{_name}</div>
            {pdfFiles.length > 0 && (
              <div className='info-container'>
                <div className='info-count'><span>{pdfFiles.length}</span>개의 Group, <span>{pdfCount}</span>개의 PDF가 생성되었습니다.</div>
                {isEditable &&
                  <>
                    <label htmlFor='upload_S3' className='add-files-button'>
                      업로드하기
                    </label>
                    <input id='upload_S3' type='file' accept='image/pdf' multiple onChange={(e)=>upload_S3(e, false)} />
                  </>
                }
              </div>
            )}
            <div className='wrap-scroll'>
              {!initialData && pdfFiles.length === 0 && !isFetchingPOST &&
                <div className='file_upload_wrap'>
                  <label htmlFor='fileUpload'>
                    파일을 업로드 해주세요. Click!
                  </label>
                  <input id='fileUpload' type='file' accept='image/pdf' multiple onChange={(e)=>upload_S3(e, true)} />
                </div>
              }
              {initialData && initialData.map((e: any) => {
                return (
                  <div className='content-box-initial' key={e.pdf_id}>
                    <div className={['item-name'].join(' ')} onClick={()=>onPreview(e.url)}>{split_name(e.url)}</div>
                  </div>
                )
              })}
              {!initialData && pdfFiles.map((e: any, index: number) => {
                return (
                  <div className='content-box' key={e.name}>
                    <div className='content-name'>{e.name}</div>
                    <div className='content-files-box'>
                      <div className='content-files-item'>
                        <div className='item-title'>문제</div>
                        <div className={['item-name', !e.question && 'essential'].join(' ')} onClick={()=>onPreview(e.question?.url)}>{split_name(e.question?.url)}</div>
                        {isEditable && e.question &&
                          <div className='item-icon' onClick={()=>deleteFile(e.name, e.question, 'question')}>
                            <img alt='icon-delete' src={deleteDisabledIcon} />
                          </div>
                        }
                        {isEditable && (
                          <>
                            <label className='item-icon' htmlFor={`input-file-question-${e.name}`}>
                              <img alt='icon-upload' src={uploadIcon} />
                            </label>
                            <input type='file' id={`input-file-question-${e.name}`} style={{display: 'none'}} onChange={(v)=>uploadFile(v, e.name, e.question, 'question')} />
                          </>
                        )
                        }
                      </div>
                      <div className='content-files-item'>
                        <div className='item-title'>해설</div>
                        <div className={['item-name', !e.explanation && 'essential'].join(' ')} onClick={()=>onPreview(e.explanation?.url)}>{split_name(e.explanation?.url)}</div>
                        {isEditable && e.explanation &&
                          <div className='item-icon' onClick={()=>deleteFile(e.name, e.explanation, 'explanation')}>
                            <img alt='icon-delete' src={deleteDisabledIcon} />
                          </div>
                        }
                        {isEditable && (
                          <>
                            <label className='item-icon' htmlFor={`input-file-explanation-${e.name}`}>
                              <img alt='icon-upload' src={uploadIcon} />
                            </label>
                            <input type='file' id={`input-file-explanation-${e.name}`} style={{display: 'none'}} onChange={(v)=>uploadFile(v, e.name, e.explanation, 'explanation')} />
                          </>
                        )}
                      </div>
                      <div className='content-files-item'>
                        <div className='item-title'>개념</div>
                        <div className={['item-name', !e.concept && 'is-empty'].join(' ')} onClick={()=>onPreview(e.concept?.url)}>{split_name(e.concept?.url)}</div>
                        {isEditable && e.concept &&
                          <div className='item-icon' onClick={()=>deleteFile(e.name, e.concept, 'concept')}>
                            <img alt='icon-delete' src={deleteDisabledIcon} />
                          </div>
                        }
                        {isEditable && (
                          <>
                            <label className='item-icon' htmlFor={`input-file-concept-${e.name}`}>
                              <img alt='icon-upload' src={uploadIcon} />
                            </label>
                            <input type='file' id={`input-file-concept-${e.name}`} style={{display: 'none'}} onChange={(v)=>uploadFile(v, e.name, e.concept, 'concept')} />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Container>
        </>
        : <></>
      }
    </MainSection>
  )
}

const move = keyframes`
	0%{
    left: 0;
  }
  100%{
    left: 95%;
  }
`
const LoadingHorse = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 0.2s;
  animation-name: ${move};
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  #ddung {
    width: 3rem;
    height: 4rem;
  }
  #brown_horse {
    width: 3rem;
    height: 2rem;
  }
  #pikachu {
    width: 3.7rem;
    height: 3rem;
  }
`
const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);

  .preview-document {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
  .preview-page-wrap {
    position: relative;
    padding: 2rem 3rem 5rem;
    height: 100%;
  }
  .preview-exit {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.3rem;
    border-radius: 4px;
    background-color: #eee;
    cursor: pointer;

    :hover {
      background-color: white;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .preview-pagenation {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .preview-pagenation-arrow {
      width: 4rem;
      height: 2rem;
      color: #555;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #eee;
      cursor: pointer;

      :hover {
        background-color: white;
      }
    }
    .preview-pagenation-center {
      width: fit-content;
      color: white;
    }
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
  .react-pdf__Page__textContent {
    display: none;
  }
`
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  height: calc(100% - 4rem);

  .file_upload_wrap {
    position: relative;
    height: 100%;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;

      :hover {
        background-color: rgba(0, 0, 0, 0.14);
      }
    }
    #fileUpload {
      display: none;
    }
  }

  .back-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .title {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-weight: 600;

    .info-count {
      font-size: 0.9rem;
      padding: 4px 8px;
      width: fit-content;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.07);

      span {
        color: #4ab7a9;
        font-size: 1.1rem;
      }
    }
    #upload_S3 {
      display: none;
    }

    .add-files-button {
      cursor: pointer;
      padding: 0 0.6rem;
      width: fit-content;
      height: 100%;
      color: white;
      font-size: 0.9rem;
      border-radius: 4px;
      background-color: #4ab7a9;

      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background-color: #3fac9d;
      }
    }
  }

  .wrap-scroll {
    height: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .content-box-initial {
      /* width: 100%; */
      .item-name {
        width: fit-content;
        min-height: 1.8rem;
        padding: 8px 16px;
        border-radius: 4px;
        background-color: #e8f1ff;
        cursor: pointer;
        :hover {
          background-color: #d7eaff;
        }
      }
    }

    .content-box {
      padding: 0.7rem;
      max-width: 50rem;
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.4);

      .content-name {
        font-weight: 600;
      }
      .content-files-box {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .content-files-item {
          padding: 4px 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          /* gap: 0.5rem; */

          .item-title {
            min-width: fit-content;
            font-size: 0.8rem;
            font-weight: 600;
          }
          .item-name {
            width: 100%;
            margin: 0 0.5rem 0 0.7rem;
            min-height: 1.8rem;
            padding: 4px 8px;
            border-radius: 4px;
            background-color: #e8f1ff;
            cursor: pointer;
            :hover {
              background-color: #d7eaff;
            }
          }
          .essential {
            background-color: #ffcbcb;
            border: 1px solid #ffc1c1;
            cursor: default;
            :hover {
              background-color: #ffcbcb;
            }
          }
          .is-empty {
            background-color: rgba(0, 0, 0, 0.07);
            cursor: default;
            :hover {
              background-color: rgba(0, 0, 0, 0.07);
            }
          }
          .item-icon {
            width: 2rem;
            height: 1.7rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
            :hover {
              border: 1px solid #fff;
              background-color: rgba(0, 0, 0, 0.1);
            }

            img {
              width: 1.2rem;
              height: 1.2rem;
            }
          }

          .input-file-one {
            border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }
    }
  }
`

export default Detail