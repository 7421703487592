import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components'
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import AssessmentIcon from '@mui/icons-material/Assessment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssignmentIcon from '@mui/icons-material/Assignment'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import arrowSubMenu from '../../assets/icons/sidebar_sub_menu.png'
import { useQuery } from 'react-query'
import { getSideBarAlarmAPI } from '../../api/getAPI'
import { actionCreators as searchActions } from "../../redux/modules/search"
import { actionCreators as sidebarActions } from "../../redux/modules/sidebar"

// import SidebarTH from './Sidebar-th'
import SidebarTH from './Sidebar-th-02'

export const SidebarItemsOfLabeler = [
  {
    to: '/dashboard',
    label: '공지사항',
    icon: <DashboardIcon />,
  },
  {
    to: '/workspace/project',
    label: '나의 작업 공간',
    icon: <AssignmentIcon />,
    query: `?page=1&status=`,
  },
  {
    to: '/workspace/progress',
    label: '나의 작업 상세 확인',
    icon: <FactCheckIcon />
  },
  {
    to: '/mypage',
    label: '마이 페이지',
    icon: <PersonIcon />
  },
  {
    to: '/post',
    label: '게시글',
    icon: <EmailIcon />
  },
  {
    to: '#',
    label: '문제 관리',
    icon: <DashboardIcon />,
    subMenu: [
      {
        to: '/questions/qb',
        query: `?page=1&work_type=${'1'}&grade=&sub_subject=&publisher=&section=&sub_section=`,
        label: '기출'
      },
      {
        to: '/questions/books',
        query: `?page=1&work_type=${'1'}&grade=&sub_subject=&book=&section=&sub_section=`,
        label: '문제집'
      },
    ]
  },
]
export const SidebarItemsOfLabelerTh = [
  // {
  //   to: '/th/mid-unit',
  //   label: '단원 부여',
  //   icon: <DashboardIcon />
  // },
  {
    to: '#',
    label: '단원 부여',
    icon: <DashboardIcon />,
    subMenu: [
      {
        to: '/th/mid-unit/qb',
        query: '?page=1&filter=',
        label: '큐비 문제'
      },
      {
        to: '/th/mid-unit/books',
        query: '?page=1&filter=',
        label: '북스 문제'
      }
    ]
  },
  {
    to: '/th/translation',
    label: '번역',
    icon: <AssignmentIcon />,
  },
]

export const SidebarItemsOfAdmin = [
  {
    to: '/dashboard/status',
    label: '대시보드',
    icon: <AssessmentIcon />
  },
  {
    to: '/notice',
    label: '공지사항',
    icon: <NotificationsIcon />
  },
  {
    to: '#',
    label: '업로드',
    icon: <AssignmentIcon />,
    subMenu: [
      {
        to: '/upload/management',
        query: '?service=books&page=1&search_filter=1&search=&filter=0',
        label: '책 / 기출 생성'
      },
      {
        to: '/upload/pdf',
        query: '?service=books&page=1&search_filter=1&search=&filter=0',
        label: 'pdf 업로드'
      }
    ]
  },
  {
    to: '#',
    label: '프로젝트',
    icon: <AssignmentIcon />,
    subMenu: [
      {
        to: '/project/management',
        query: '?page=1&search=',
        label: '생성 / 수정'
      },
      {
        to: '/project/matching',
        query: '?page=1&search=&status=',
        label: '할당 / 부여'
      },
      {
        to: '/project/workType',
        query: '',
        label: '해설 / 지문'
      }
    ]
  },
  {
    to: '#',
    label: '라벨러 관리',
    icon: <PeopleIcon />,
    subMenu: [
      {
        to: '/labeler/management',
        label: '승인 / 수정'
      },
      {
        to: '/labeler/matching',
        query: '?page=1&search=&status=',
        label: '작업 현황'
      }
    ]
  },
  {
    to: '#',
    label: '문제 관리',
    icon: <DashboardIcon />,
    subMenu: [
      {
        to: '/question/qb',
        label: '기출'
      },
      {
        to: '/question/books',
        label: '문제집'
      },
      {
        to: '/question/error',
        query: '?page=1&search_filter=1&search=&sort=0',
        label: '오류 신고'
      },
    ]
  },
]

const Sidebar = () => {
  const _nation = (localStorage.getItem("_nation")) // 1: 한국, 2: 태국
  const _authority = localStorage.getItem("_authority") // 태국 - 0: 어드민, 1: 중단원 부여자, 2: 스키머, 3: 번역자, 4: 검수자

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const sidebarList = _authority === '0' 
    ? SidebarItemsOfAdmin 
    : ((_nation === '1' || _nation === '2')
        ? SidebarItemsOfLabeler 
        : SidebarItemsOfLabelerTh
      )
  
  const is_sidebar = useSelector((state) => state.sidebar.visible)
  const bubble_talk = useSelector((state) => state.sidebar.bubble_talk)
  const is_bubble_talk = useSelector((state) => state.sidebar.is_bubble_talk)
  const [labeling, setLabeling] = useState(true)
  const [upload, setUpload] = useState(true)
  const [project, setProject] = useState(true)
  const [labeler, setLabeler] = useState(true)
  const [question, setQuestion] = useState(true)
  const [thMidUnit, setThMidUnit] = useState(true)

  const { data: alarmList } = useQuery('alarmList', () => getSideBarAlarmAPI(), {
    enabled: !!(_authority !== '0'),
    onSuccess: data => {
      console.log(data)
    },
    onError: error => {
      console.log(error)
    }
  })

  const handleSubMenu = (e, label) => {
    e.preventDefault()
    if (_authority !== '0') {
      if (label === '문제 관리') setQuestion(!question)
    }

    if (_authority === '0') {
      if (label === '업로드') setUpload(!upload)
      if (label === '프로젝트') setProject(!project)
      if (label === '라벨러 관리') setLabeler(!labeler)
      if (label === '문제 관리') setQuestion(!question)
    }
  }

  const handleSubMenuState = (label) => {
    if (label === '나의 작업 공간') return labeling
    if (label === '업로드') return upload
    if (label === '프로젝트') return project
    if (label === '라벨러 관리') return labeler
    if (label === '문제 관리') return question
    if (label === '단원 부여') return thMidUnit
  }


  useEffect(() => {
    if (_authority !== '0') {
      setLabeling(is_sidebar)
    }

    if (_authority === '0') {
      setUpload(is_sidebar)
      setProject(is_sidebar)
      setLabeler(is_sidebar)
      setQuestion(is_sidebar)
    }
  }, [is_sidebar, _authority])

  const handleError = (e) => {
    if (e === 1) return '연관이미지'
    if (e === 2) return '오타'
    if (e === 3) return '정답'
  }

  const exitBubbleTalk = () => {
    dispatch(sidebarActions.isBubbleTalk(false))
  }

  // const [ activeErrorTab, setActiveErrorTab ] = useState(step_1 === '0' && step_2 === '1' ? '반려' : '오류') // 오류 or 반려
  const [ activeErrorTab, setActiveErrorTab ] = useState(_authority === '2' ? '반려' : '오류') // 오류 or 반려
  const handleErrorTab = (event, newAlignment) => {
    if (newAlignment !== null) {
      setActiveErrorTab(newAlignment)
    }
  }
  const handleOnClickQuestion = async (questionId) => {
    if (activeErrorTab === '오류') {
      await dispatch(searchActions.setSearchKeyword01(questionId))
      navigate(`/workspace/project/labeling/content/question/${questionId}/correcting`)
    } 
    if (activeErrorTab === '반려') {
      await dispatch(searchActions.setSearchKeyword02(questionId))
      navigate(`/workspace/project/review/content/question/${questionId}`)
    }
  }

  if (_nation === '2') return (<SidebarTH _authority={_authority} />)

  return _nation !== '2' && (
    <Wrap>
      <Menu>
      {sidebarList && sidebarList.map(({to, query, label, icon, subMenu}, idx) => {
        const state = handleSubMenuState(label)
        return (
          <div key={label}>
            <LinkContainer onClick={(e) => handleSubMenu(e, label)}>
              <List component="nav" >
                <Link to={`${to}${query ? query : ''}`} className={to === '#' ? 'inactive' : ''} style={{ textDecoration: 'none' }} >
                  <ItemButton >
                    <ListItemIcon>
                      {icon}
                    </ListItemIcon>
                    <ItemText 
                      primary={label} 
                      selected={pathname.includes(to)} 
                      sx={{color: '#ItemText', paddingTop: '2px'}} 
                    />
                    {to === '#' && (
                      <SubIcon src={arrowSubMenu} alt='arrowSubMenu' />
                    )}
                  </ItemButton>
                </Link>
              </List>
            </LinkContainer>
            { to === '#' && state &&
              subMenu.map(({to, query, label}, idx) => {
                return (
                  <LinkSubMenu key={label} open={labeling}>
                    <Link to={`${to}${query ? query : ''}`} style={{ textDecoration: 'none' }} >
                      <SubItemText primary={label} selected={pathname.includes(to)} sx={{color: '#ItemText'}} />
                    </Link>
                  </LinkSubMenu>
                )
              })
            }
          </div>
        )
      })}
      </Menu>
      {/* {(nationType === '1' || nationType === '2') && */}
      {(_nation === '1') &&
        <div style={{width: '90%', display: 'flex', flexDirection: 'column', gap: '1rem', position: 'absolute', bottom: '12px', left: '12px'}}>
          {is_bubble_talk &&
            <BubbleTalk>
              <div className='exit' onClick={exitBubbleTalk}><CloseRoundedIcon sx={{width: '1rem', height: '1rem', color: 'white'}} /></div>
              <div className='common title'>{bubble_talk.title}</div>
              <div className='common content'>{bubble_talk.content}</div>
            </BubbleTalk>
          }
        <Notice opacity={ is_sidebar ? 1 : 0 }>
          <TitleBox>
            <Title>
              {activeErrorTab} 문제
            </Title>
            {/* {(_authority === '2' || _authority === '3') && */}
            {
              <ToggleButtonGroup
                color="primary"
                value={activeErrorTab}
                exclusive
                onChange={handleErrorTab}
                aria-label="Platform"
                style={{height: '1.7rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px'}}
              >
                <ToggleButton value="오류" style={{fontSize: '0.7rem'}}>오류</ToggleButton>
                <ToggleButton value="반려" style={{fontSize: '0.7rem'}}>반려</ToggleButton>
              </ToggleButtonGroup>
            }
          </TitleBox>
          <NoticeListBox>
            {alarmList && activeErrorTab === '오류' 
              && alarmList.data.error_question.length !== 0 && alarmList.data.error_question.map((el, index) => {
                const error_num = [...new Set(el.error)].sort()
                return (
                  <NoticeItem key={String(index)} onClick={() => handleOnClickQuestion(String(el.question_id))}>
                    <div>
                      {el.question_id}&nbsp;-&nbsp;
                      {error_num.map((e, idx) => {
                        return (
                          <span key={String(idx)}>{handleError(e)}&nbsp;&nbsp;</span>
                        )
                      })}
                    </div>
                  </NoticeItem>
                )
              })
            }
            {alarmList && activeErrorTab === '반려' 
              && alarmList?.data.check_question.length !== 0 && alarmList.data.check_question.map((el, index) => {
                return (
                  <NoticeItem key={String(index)} onClick={() => handleOnClickQuestion(String(el.question_id))}>
                    <div>
                      {el.question_id}
                    </div>
                  </NoticeItem>
                )
              })
            }
          </NoticeListBox>
        </Notice>
        </div>
      }
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 100%;
  width: 14.95rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  /* padding: 0 0.5rem; */
  /* border: 1px solid red; */

  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .inactive {
    pointer-events: none;
  }
`
const Menu = styled.div`
  padding-right: 1rem;
  width: 100%;
  /* border: 1px solid green; */

  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Notice = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  /* width: 90%; */
  max-height: 12rem;
  padding: 0rem 0.8rem 0.8rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  transition: all 180ms ease;
  opacity: ${props => props.opacity ? 1 : 0};
  background-color: white;
`
const BubbleTalk = styled.div`
  position: relative;
  padding: 0.5rem;
  width: 100%;
  /* top: -15.5rem; */
  /* top: -11.5rem; */
  /* left: 50%;
  transform: translate(-50%, 0); */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 6px;
  background-color: #ddd;
  /* overflow: visible; */

  .common {
    padding: 0.2rem 0.4rem;
    overflow: auto;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 0.8rem;
    border-radius: 3px;
    background-color: #eee;
  }
  .title {
    position: relative;
  }
  .exit {
    position: absolute;
    top: -0.7rem;
    right: -0.4rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50rem;
    background-color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    cursor: pointer;
  }
  .content {height: 8rem;}
`
const TitleBox = styled.div`
  padding: 0.7rem 0 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const Title = styled.div`
  width: 100%;
  color: #111;
  font-size: 15px;
  font-weight: 700;
`
const NoticeListBox = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.5rem 0 2.9rem;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const NoticeItem = styled.div`
  padding: 8px 8px 5px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);

  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`
const slideDown = keyframes`
  from {
    display: none;
  }
  to {
    display: block;
  }
`;
const slideUp = keyframes`
  from {
    display: block;
  }
  to {
    display: none;
  }
`;

const SubIcon = styled.img`
  width: 11px;
  height: 6px;
`

const LinkSubMenu = styled.div`
  width: 100%;
  height: fit-content;
  padding-left: 80px;

  animation-duration: 0.30s;
  animation-timing-function: ease-out;
  animation-name: ${props => props.open ? slideDown : slideUp};
  animation-fill-mode: forwards;
`

const LinkContainer = styled.div`
  cursor: pointer;
  padding-left: 6px;
`

const ItemButton = styled(ListItemButton)`
  /* background-color: #A1C7E0; */
`

const ItemText = styled(ListItemText)`
  color: ${(props) => (props.selected ? '#1976d2' : 'black' )};
  font-weight: ${(props) => (props.selected ? '24px' : '1rem' )};
`

const SubItemText = styled(ListItemText)`
  color: ${(props) => (props.selected ? '#1976d2' : 'black' )};
  font-weight: ${(props) => (props.selected ? '20px' : '1rem' )};
  padding: 4px;
`

export default Sidebar