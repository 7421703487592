import Editor from './Editor'
import CropContext from '../CropContext'

const EditorWrap = () => {
  return (
    <CropContext>
      <Editor></Editor>
    </CropContext>
  )
}

export default EditorWrap