import Table from './table-core'
import { Col, Head, Body } from './HeadAndBody'

type GrammarTag = {
  grammar_chapter_name: string
  grammar_child_unit_id: number
  grammar_child_unit_name: string
  grammar_parent_unit_name: string
}

const GrammarTable = ({ 
  searchedKeyword,
  contents,
  selected,
  handleClickRaw
}: { 
  searchedKeyword: string
  contents: GrammarTag[]
  selected: GrammarTag | null
  handleClickRaw: (grammar: GrammarTag)=>void
}) => {
  const cols = ["10%", "25%", "25%", "40%"] // total: 100%
  const columns = ["", "대분류", "중분류", "소분류"]

  const col = Col(cols)
  const head = Head(columns)
  const body = Body({ items: contents, selected, onClick: handleClickRaw, searchedKeyword })

  return (
    <Table 
      col={col} 
      head={head} 
      body={body} 
      emptyMsg={!!contents.length ? null : '검색 결과가 없습니다'}
    />
  )
}

export default GrammarTable