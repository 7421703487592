import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import Item from './Item'
import { COLUMN_NAMES } from "./constants";
import AddIcon from '@mui/icons-material/Add';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 240px;
  height: 100%;
  /* border: 1px solid red; */
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 10px 4px;
`
const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 2px;
  background-color: ${props => props.bgColor};
  /* background-color: white; */
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .add_box {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`
const AddItem = styled.div`
  border: 2px solid #82c5d1;
  border-radius: 3px;
  background-color: #e3f3f7;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* background-color: #FF5F5D; */
  cursor: pointer;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 10px 10px 6px;

  span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.92rem;
    font-weight: 600;
  }

  .item_info_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex-direction: column; */
    gap: 10px;
  }
  .item_info {
    width: fit-content;
    font-size: 13px;
    font-weight: 500;
    color: RGB(72, 100, 130);
  }
`
const ChildrenWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 4px 0 10px;
  width: 100%;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const RelatedColumn = ({ children, AddRelatedColumn }) => {
  
  return (
    <Wrap>
      {/* <Title>연관 지문</Title> */}
      
      <ListBox>
        <AddItem onClick={() => AddRelatedColumn()} className='add_box'>
          <AddIcon sx={{ width: '18px', color: 'rgba(0, 0, 0, 0.7)'}} /> <span>연관 지문</span>
        </AddItem>

        <ChildrenWrap>
          {children}
        </ChildrenWrap>

      </ListBox>
    </Wrap>
  )
}

export default RelatedColumn