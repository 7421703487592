/**
 * @module basic-styles/code/codeui
 */

import { Plugin } from 'ckeditor5/src/core';
import { ButtonView } from 'ckeditor5/src/ui';

import icon from '../../theme/icons/textbox.svg';

import '../../theme/css/textbox.css';

const TEXTBOX = 'textbox';

/**
 * The code UI feature. It introduces the Code button.
 *
 * @extends module:core/plugin~Plugin
 */
export default class TextBoxUI extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'TextBoxUI';
	}

	/**
	 * @inheritDoc
	 */
	init() {
		const editor = this.editor;
		const t = editor.t;

		// Add code button to feature components.
		editor.ui.componentFactory.add(TEXTBOX, (locale) => {
			const command = editor.commands.get('insertTextBox');
			const view = new ButtonView(locale);

			view.set({
				label: t('TextBox'),
				icon: icon,
				keystroke: 'CTRL+E',
				tooltip: true,
				isToggleable: true,
			});

			// view.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');
			view.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');
			// view.bind('isEnabled').to(command, 'isEnabled');
			// view.bind('isOn').to(command, 'isOn');

			// Execute command.
			this.listenTo(view, 'execute', () => {
				editor.execute('insertTextBox');
				editor.editing.view.focus();
			});

			return view;
		});
	}
}
