import styled from 'styled-components'
import { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

interface SearchBoxProps {
  defaultKeyword: string
  inputType: string
  getKeyword: (v:string) => void
  searchFilter: number
  searchFilterList: string[]
  getSearchFilter: (v:number) => void
}

const SearchBox = ({ defaultKeyword="", inputType='text', getKeyword=()=>{}, searchFilter=0, searchFilterList=[], getSearchFilter=()=>{} }: SearchBoxProps) => {
  const [keyword, setKeyword] = useState(defaultKeyword)
  const handleKeyword = ({target: {value}}) => setKeyword(value)
  const onChange = ({target: {value}}) => {
    getSearchFilter(value)
  }

  const Enter = async (key: string) => {
    if (key === 'Enter') {
      getKeyword(keyword)
    }
  }

  if (searchFilterList.length === 0) {
    return (
      <Container>
        <Input type={inputType} name="search" value={keyword} onChange={handleKeyword} onKeyPress={(e) => {Enter(e.key)}} />
        <SearchIconBox onClick={() => Enter('Enter')}>
          <SearchIcon sx={{color: '#000', width: '100%'}} />
        </SearchIconBox>
      </Container>
    )
  }

  if (searchFilterList.length > 0) {
    return (
      <Container>
        <FormControl sx={{ mr: 0.5, minWidth: 100 }}>
          <Select
            value={searchFilter}
            onChange={onChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{height: '40px', pt: '3px'}}
          >
            {searchFilterList.map(el => {
              return <MenuItem key={el.value} value={el.value}>{el.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <Input type={inputType} name="search" value={keyword} onChange={handleKeyword} onKeyPress={(e) => {Enter(e.key)}} />
        <SearchIconBox onClick={() => Enter('Enter')}>
          <SearchIcon sx={{color: '#000', width: '100%'}} />
        </SearchIconBox>
      </Container>
    )
  }
}

const Container = styled.div`
  height: 2.5rem;
  display: flex;
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const Input = styled.input`
  padding: 0 0.4rem;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  :hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
`
const SearchIconBox = styled.div`
  position: absolute;
  right: 0;
  width: 2.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  :hover {
  }
`

export default SearchBox