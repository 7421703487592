import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props
  const theme = useTheme()
  const params = useParams()

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;

const PlatformList = React.memo(({ questionList }) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(questionList.length);

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    // handleSetPageNum(newPage + 1)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, questionList.length));
    setPage(0);
  };

  const clickTableCell = (id) => {
    navigate(`/workspace/project/mid-unit/test-paper/question/correcting/${id}`)
  }
  console.log(questionList)

  return (
    <Wrap style={{position: 'relative'}}>
      <TableContainer component={Paper} sx={{ height: '100%' }} >  
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align="center">No.</Cell>
              <Cell align="center">번호</Cell>
              <Cell align="center">2차 입력</Cell>
              {/* <Cell align="center">작성 여부</Cell> */}
              <Cell align="center">난이도</Cell>
              <Cell align="center">오류 여부</Cell>
              <Cell align="center">이미지</Cell>
              <Cell align="center">객관식</Cell>
              <Cell align="center">주관식</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionList.length > 0 && questionList.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              const type = row.type === '객관식' ? true : false
              return (
                <Row
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {clickTableCell(row.id)}}
                >
                  <Cell align="center">{no}</Cell>
                  <Cell align="center">{row.id}</Cell>
                  <Cell align="center">{row.check_yn}</Cell>
                  <Cell align="center">{row.difficult}</Cell>
                  <Cell align="center">
                    {row.error === '-' && '-'}
                    {row.error === 0 && <Circle bgColor={'#66CC33'} />}
                    {row.error === 1 && <Circle bgColor={'red'} />}
                    {row.error === 2 && <Circle bgColor={'orange'} />}
                  </Cell>
                  <Cell align="center">{row.image}</Cell>
                  <Cell align="center">{type ? 'O' : '' }</Cell>
                  <Cell align="center">{type ? '' : 'O'}</Cell>
                </Row>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {questionList.length !== 0 &&
        <TablePagination
          sx={{overflow: 'hidden'}}
          rowsPerPageOptions={[questionList.length]}
          colSpan={9}
          count={questionList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      } */}
    </Wrap>
  )
})

const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor}
`

export default PlatformList