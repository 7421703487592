import {useState, useEffect, useRef, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fabric } from 'fabric'
// import 'fabric-history'
import _ from 'lodash'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import Toolbar from '../../mainSection/Toolbar'

import cropIcon from '../../../../assets/icons/workspace/crop.svg'
import inactiveCropIcon from '../../../../assets/icons/workspace/crop_inactive.svg'
import frameIcon from '../../../../assets/icons/workspace/add-frame.svg'
import textIcon from '../../../../assets/icons/workspace/text-alt.svg'
import circleIcon from '../../../../assets/icons/workspace/add-circle.svg'
import squareIcon from '../../../../assets/icons/workspace/add-square.svg'
import checkMarkIcon from '../../../../assets/icons/check-mark.png'

import { actionCreators as editorWritingActions } from "../../../../redux/modules/thai/editor_writing"

const Container = styled.div`
  display: ${props => props.isVisible ? 'grid' : 'none' };
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 180ms ease;
`
const PaperContainer = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow: hidden;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const ToolboxWrap = styled.div`
  /* margin: 0.2rem 0; */
  display: flex;
  justify-content: flex-end;
`
const Toolbox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;

  .tool {
    padding: 0.2rem 0.4rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .tool_preview {
    width: 4.2rem;
    font-size: 0.8rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .tool__color_picker {
    width: 3rem;

    .get_color {
      cursor: pointer;
    }
  }
  .tool__crop_inactive, .tool__cutting_box_inactive {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }

  .tool img path {
    stroke: '#fff';
  }
`
const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  width: 100%;
  flex: 1;
  padding: 0.8rem;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Canvas = styled.canvas`
  z-index: 1000;
  display: ${props => props.display};
  /* border-radius: 4px; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
`
const CanvasWrap = styled.div`  
  width: 100%;
  height: 100%;
  /* border-radius: 4px; */
  transition: 150ms ease;
  background-color: transparent;
  overflow-y: auto;

  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  ::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
`

interface WorkspaceProps {
  _question_id: number
  imageUrlList: string[]
  isVisible?: boolean
  close?: ()=>void
}

const Workspace = ({
  _question_id=0,
  imageUrlList=[],
  isVisible=false,
  close=()=>{}
}: WorkspaceProps) => {
  const dispatch = useDispatch()
  const th_editor_writing = useSelector((state) => state.th_editor_writing)
  const {
    fabric_canvas_list,
    image_file_url_list,
    click_preview,
    fabric_history_undo,
    fabric_history_redo,
    locked,
  } = th_editor_writing

  const [ workspaceScrollY, setWorkspaceScrollY ] = useState(0) // current scroll position of image_box
  const [ selected, setSelected ] = useState(0) // 클릭된 이미지 index
  const clickImg = (idx) => {
    setSelected(idx)
  }

  // fabricCanvas => json 형태로 반환
  const readyForJSON = () => {
    const clonedCanvas = _.cloneDeep(fabric_canvas_list[selected])
    const fabric_data = JSON.stringify(clonedCanvas.toDatalessJSON(['_URL', '_width', '_height', '_originWidth', '_originHeight']))
    // console.log(fabric_data)
    
    return fabric_data
  }

  // 프리뷰 이미지 생성
  const createPreview = async () => {
    const jpegUrl = fabric_canvas_list[selected].toDataURL({ // base64로 변환
      format: 'jpeg',
      quality: 1,
    })

    await fetch(jpegUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const _FILE = new File([blob], `preview_image_${Math.random()}.png`) // formData 보낼 때 필요
        // console.log(file)
        // const formData = new FormData()
        // formData.append('question_id', `${_question_id}`)
        // formData.append('order', `${selected}`)
        // formData.append('image_file', _FILE)
        // mutate_image_file(formData)

        const _JSON = readyForJSON()
        const _URL = URL.createObjectURL(blob)
        fabric_canvas_list[selected].set({_URL: _URL})
        fabric_canvas_list[selected].set({_FILE: _FILE})
        fabric_canvas_list[selected].set({_previewed: true})
        dispatch(editorWritingActions.set_click_preview(!click_preview))
        
        const image_obj: any = {
          url: _URL,
          json: _JSON,
          base64: jpegUrl,
        }
        let copyList = _.cloneDeep(image_file_url_list)
        copyList[selected] = [...copyList[selected], image_obj]
        dispatch(editorWritingActions.set_image_file_url_list(copyList))
      }
    )
    
  }
  // ===============================================================
  
  // 원형 박스 생성
  const createCircle = () => {
    const newCircle = new fabric.Circle({
      width: 50,
      height: 50,
      radius: 50,
      fill: 'black',
      top: workspaceScrollY, // new - 좌측 상단 위치
      right: 0, // new - 좌측 상단 위치
    })

    // newCircle.setControlsVisibility({mtr: false})
    fabric_canvas_list[selected].add(newCircle)
    fabric_canvas_list[selected].setActiveObject(newCircle)
    fabric_canvas_list[selected].renderAll()
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // 직사각형 박스 생성
  const createRect = () => {
    const newRect = new fabric.Rect({
      name: `rect_${uuidv4()}`,
      width: 100,
      height: 100,
      fill: 'black',
      top: workspaceScrollY, // new - 좌측 상단 위치
      right: 0, // new - 좌측 상단 위치
    })

    // newRect.setControlsVisibility({mtr: false})
    fabric_canvas_list[selected].add(newRect)
    fabric_canvas_list[selected].setActiveObject(newRect)
    fabric_canvas_list[selected].renderAll()
  }

  // 텍스트 박스 생성
  const createTextBox = () => {
    const text = new fabric.Textbox('text', {
      name: `text_${uuidv4()}`,
      fill: 'black',
      fontSize: 20,
      fontFamily: '',
      backgroundColor: 'transparent',
    })
    fabric_canvas_list[selected].add(text)
    fabric_canvas_list[selected].setActiveObject(text)
    fabric_canvas_list[selected].renderAll()
  }

  // 색상 입히기
  const getColor = (e) => {
    const color = e.target.value
    // console.log(color)
    const activeObjectList = fabric_canvas_list[selected].getActiveObjects()
    activeObjectList.forEach(klass => {
      klass.set({fill: color})
    })
    fabric_canvas_list[selected].renderAll()
    saveStateFill()
  }

  // ============================================
  const debounce = _.debounce(e => {
    // console.log(e.target.scrollTop)
    setWorkspaceScrollY(e.target.scrollTop)
  }, 300)

  const handleScroll = useCallback(e => debounce(e), [selected])

  useEffect(() => {
    return () => {
      dispatch(editorWritingActions.set_init_state())
    }
  }, [])

  useEffect(() => {
    // if (fabric_canvas_list.length > 0) {
    //   fabric_canvas_list[selected].renderAll()
    // }
    dispatch(editorWritingActions.set_selected_index(selected))

    const workspaceImage = document.getElementById('image_box');

    if (!workspaceImage) return
    workspaceImage.addEventListener('scroll', (e) => debouce(e))

    return () => {
      workspaceImage.removeEventListener('scroll', handleScroll)
    }
  }, [selected])
  // ============================================

  const [imageBoxWidth, setImageBoxWidth] = useState(0)

  const addRef = useRef<any[]>(null)
  const removeRef = useRef<any[]>(null)
  const modifiedRef = useRef<any[]>(null)
  const addFunc = (e: any, index: number)=>saveState(e, 'add', index)
  const removeFunc = (e: any, index: number)=>saveState(e, 'remove', index)
  const modifiedFunc = (e: any, index: number)=>saveState(e, 'modified', index)
  const [history, setHistory] = useState([])
  const [redoStack, setRedoStack] = useState([])
  console.log('== history ==', history)

  const saveStateFill = useCallback(() => {
    const objects = _.cloneDeep(fabric_canvas_list[selected].getObjects())
    const newState = {
      action: 'modified',
      objects: objects,
    }
    setHistory([...history, newState])
    setRedoStack([])
  }, [fabric_canvas_list, history, redoStack])

  const saveState = useCallback((e: any, type: string, index: number) => {
    console.log('=0=0=0=0=0=0=0=0=', e, type, index)

    const objects = fabric_canvas_list[index].getObjects()
    const clone_objects = _.cloneDeep(fabric_canvas_list[index].getObjects())
    const data = objects.map(el => {
      return {
        fill: el.fill,
        left: el.left,
        top: el.top,
        width: el.width,
        height: el.height,
        scaleX: el.scaleX ? el.scaleX : 0,
        scaleY: el.scaleY ? el.scaleY : 0,
      }
    })
    const newState = {
      action: type,
      name: e.target.name,
      objects: clone_objects,
      // objects: objects,
      data: data,
    }
    console.log('history', history)
    console.log('newState', newState)
    setHistory([...history, newState])
    setRedoStack([])

    fabric_canvas_list.forEach((klass: any, index: number) => klass.off({
      'object:added': addRef.current[index],
      'object:removed': removeRef.current[index],
      'object:modified': modifiedRef.current[index],
    }))
  }, [fabric_canvas_list, fabric_history_undo, fabric_history_redo, locked, history])

  const undo = useCallback(() => {
    fabric_canvas_list[selected].discardActiveObject() // inactive selection

    const current_history = history[history.length - 1]
    const before_history = history[history.length - 2]
    const before_history_2 = _.cloneDeep(history[history.length - 2])
    console.log(current_history)
    console.log(before_history)
    if (!before_history) return

    // if (current_history.action === 'add') {
    //   fabric_canvas_list[selected].set({_objects: before_history.objects})
    // }
    // if (current_history.action === 'remove') {
    //   before_history.objects.forEach((el, idx) => {
    //     if (el.name === current_history.name) fabric_canvas_list[selected].add(el)
    //   })
    // }
    // if (current_history.action === 'modified') {
      // const a = before_history.objects.map((el, idx) => {
      //   if (el.name === current_history.name) {
      //     const data = before_history.data[idx]
      //     el.set({left: data.left, top: data.top, width: data.width, height: data.height, scaleX: data.scaleX, scaleY: data.scaleY})
      //     el.onDeselect = () => false
      //     fabric_canvas_list[selected].renderAll()
      //     return el
      //   }
      //     else return el
      // })
      // fabric_canvas_list[selected].set({_objects: a})

      // const a = fabric_canvas_list[selected].getObjects()
      // a.forEach(el => fabric_canvas_list[selected].remove(el))
      // before_history.objects.forEach(el => fabric_canvas_list[selected].add(el))

      // fabric_canvas_list[selected].set({_objects: before_history.objects})
    // }
    fabric_canvas_list[selected].set({_objects: before_history_2.objects})
    const new_history = history.slice(0, -1)
    setHistory(new_history)
    setRedoStack([...redoStack, current_history])
    
    fabric_canvas_list[selected].renderAll()

  }, [selected, fabric_canvas_list, fabric_history_undo, fabric_history_redo, history])

  const redo = useCallback(() => {
    fabric_canvas_list[selected].discardActiveObject() // inactive selection

    const current_history = redoStack[redoStack.length - 1]
    const before_history = redoStack[redoStack.length - 2]
    if (!before_history) return

    if (current_history.action === 'add') {
      fabric_canvas_list[selected].set({_objects: before_history.objects})
    }
    if (current_history.action === 'remove') {
      before_history.objects.forEach((el, idx) => {
        // console.log(fabric_canvas_list[selected]._objects)
        if (el.name === current_history.name) fabric_canvas_list[selected].add(el)
        // console.log(fabric_canvas_list[selected]._objects)
      })
    }
    if (current_history.action === 'modified') {
      const a = before_history.objects.map((el, idx) => {
        if (el.name === current_history.name) {
          const data = before_history.data[idx]
          el.set({fill: data.fill, left: data.left, top: data.top, width: data.width, height: data.height, scaleX: data.scaleX, scaleY: data.scaleY})
          el.onDeselect = () => false
          fabric_canvas_list[selected].renderAll()
          return el
        }
          else return el
      })
      fabric_canvas_list[selected].set({_objects: a})
    }
    const new_history = redoStack.slice(0, -1)
    setRedoStack(new_history)
    setHistory([...history, current_history])
    
    fabric_canvas_list[selected].renderAll()
  }, [redoStack])

  const handleEvent = () => {
    const addList = fabric_canvas_list.map((k: any, index: number) => ((e) => addFunc(e, index)))
    const removeList = fabric_canvas_list.map((k: any, index: number) => ((e) => removeFunc(e, index)))
    const modifiedList = fabric_canvas_list.map((k: any, index: number) => ((e) => modifiedFunc(e, index)))
    addRef.current = addList
    removeRef.current = removeList
    modifiedRef.current = modifiedList
    
    fabric_canvas_list.forEach((klass: any, index: number) => klass.on({
      'object:added': addList[index],
      'object:removed': removeList[index],
      'object:modified': modifiedList[index],
    }))
    
  }

  useEffect(()=>{
    if (fabric_canvas_list.length === 0) return 
    handleEvent() // Register event

    return () => {
      fabric_canvas_list.forEach((klass: any, index: number) => klass.off({
        'object:added': addRef.current[index],
        'object:removed': removeRef.current[index],
        'object:modified': modifiedRef.current[index],
      }))
    }
  }, [fabric_canvas_list.length, fabric_history_undo, fabric_history_redo, history])
    
  useEffect(()=>{
    const imageBox = document.getElementById('image_box')
    const w = imageBox?.clientWidth

    if (imageBoxWidth === w) return
    setImageBoxWidth(w)
  }, [isVisible])

  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none'

    if (imageBoxWidth === 0) return
    if (fabric_canvas_list.length > 0) return

    // ================================================================================================
    const canvasList = Array.from({ length: imageUrlList.length }).map((item, index) => {
      
      let img = new Image()
      img.src = imageUrlList[index].image_url
      
      const x = imageBoxWidth
      const w = img.width
      const h = img.height
      const y = x * h / w
      // console.log('size =', 'x', x, 'y', y, 'w', w, 'h', h)

      let canvas
      
      const json_data = imageUrlList[index].image_data // 저장된 JSON 데이터 - 있다면 string / 없다면 null
      if (json_data) {
        const a = JSON.parse(json_data)
        const ratio = x / a._width
        // const ratio = Math.round(x / a._width)
        // console.log(a)
        // console.log('size =', 'x', x, 'y', y, '_width', a._width, '_height', a._height)
        // console.log(ratio)

        canvas = new fabric.Canvas(`canvas_${index}`, {
          width: x,
          height: y,
          // _originWidth: w,
          // _originHeight: h,
          uniformScaling: false,
        })
        canvas.set({_width: x})
        
        // canvas.loadFromJSON(json_data, canvas.renderAll.bind(canvas))
        canvas.loadFromJSON(a,  function() {
          canvas.set({_width: x})
          canvas.set({_height: y})
          const klass_list = canvas.getObjects()
          // console.log(klass_list)
          klass_list.forEach(klass => {
            klass.left = klass.left * ratio
            klass.top = klass.top * ratio
            klass.scaleX = klass.scaleX * ratio
            klass.scaleY = klass.scaleY * ratio
          })
          canvas.setBackgroundImage(img.src, canvas.renderAll.bind(canvas), {
            originX: 'left',
            originY: 'top',
            left: 0,
            top: 0,
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height,
            crossOrigin: 'anonymous'
          })
          canvas.renderAll()
        })
        
        return canvas
      } else {
        canvas = new fabric.Canvas(`canvas_${index}`, {
          width: x,
          height: y,
          _width: x,
          _height: y,
          _originWidth: w,
          _originHeight: h,
          uniformScaling: false,
        })
  
        canvas.setBackgroundImage(img.src, canvas.renderAll.bind(canvas), {
          originX: 'left',
          originY: 'top',
          left: 0,
          top: 0,
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height,
          crossOrigin: 'anonymous'
        })
        
        return canvas
      }
      
    })
    // console.log(canvasList)
    dispatch(editorWritingActions.set_fabric_canvas_list(canvasList))
    
    const init_empty_list = canvasList.map(el => [])
    dispatch(editorWritingActions.set_fabric_history_undo(init_empty_list))
    dispatch(editorWritingActions.set_fabric_history_redo(init_empty_list))
    dispatch(editorWritingActions.set_image_file_url_list(init_empty_list))
    // ================================================================================================

    

    fabric.Object.prototype.cornerSize = 7;
    fabric.Object.prototype.cornerColor = 'white';
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.borderColor = 'rgba(140, 180, 255, 1)';
    fabric.Object.prototype.cornerStrokeColor = 'rgba(166, 212, 255, 0.8)';
    fabric.Object.prototype.transparentCorners = false;
    // fabric.Object.prototype.setControlsVisibility({mtr: false})

    // if (canvas._objects.length > 0) return

    canvasList.forEach(el => el.on('object:moving', function (e) {
      let obj = e.target;
       // if object is too big ignore
      if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width){
        return;
      }        
      obj.setCoords();        
      // top-left  corner
      if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
        obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top)
        obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left)
      }
      // bot-right corner
      if(obj.getBoundingRect().top+obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
        obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top)
        obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left)
      }
    }))
    // http://jsfiddle.net/nasir101m/gaz704v7/

    // object:scaling 추가하기

    return () => {
      document.body.style.overscrollBehaviorX = 'auto'
    }
  }, [
    _question_id,
    imageBoxWidth,
    fabric_canvas_list.length,
  ])


  let _clipboard: any = null

  // Keyboard Event
  document.onkeydown = (e) => {
    if (!isVisible) return
    // ctrl / command + backspace (Delete active object)
    if (e.keyCode == 8 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) { 
      const activeObjectList = fabric_canvas_list[selected].getActiveObjects() // Get [active objects]
      activeObjectList.forEach(klass => {
        fabric_canvas_list[selected].discardActiveObject() // If target canvas have group selection, remove it.
        fabric_canvas_list[selected].remove(klass)
      })
      fabric_canvas_list[selected].requestRenderAll()
    } else if (e.keyCode == 67 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) { // ctrl / command + C
      const canvas = fabric_canvas_list[selected]
      if (!canvas.getActiveObject()) return
      canvas.getActiveObject().clone(function(cloned) {
        _clipboard = cloned
      })
    } else if (e.keyCode == 86 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) { // ctrl / command + V
      if (!_clipboard) return
      _clipboard.clone(function(clonedObj: any) {
        fabric_canvas_list[selected].discardActiveObject()
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        })
        if (clonedObj.type === 'activeSelection') {
          clonedObj.canvas = fabric_canvas_list[selected]
          clonedObj.forEachObject(function(obj: any) {
            if (obj.type === 'rect') obj.set({name: `rect_${uuidv4()}`}) // rect일 때, name 추가
            if (obj.type === 'textbox') obj.set({name: `text_${uuidv4()}`}) // text일 때, name 추가
            fabric_canvas_list[selected].add(obj)
          })
          clonedObj.setCoords()
        } else {
          if (clonedObj.type === 'rect') clonedObj.set({name: `rect_${uuidv4()}`}) // rect일 때, name 추가
          if (clonedObj.type === 'textbox') clonedObj.set({name: `text_${uuidv4()}`}) // text일 때, name 추가
          fabric_canvas_list[selected].add(clonedObj)
        }
        _clipboard.top += 10
        _clipboard.left += 10
        fabric_canvas_list[selected].setActiveObject(clonedObj)
        fabric_canvas_list[selected].requestRenderAll()
      })
    } else if (e.keyCode == 90 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.shiftKey) {
      // const canvas = fabric_canvas_list[selected]
      console.log('ctrl + shift + Z')
      // canvas.redo()
    } else if (e.keyCode == 90 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
      // const canvas = fabric_canvas_list[selected]
      console.log('ctrl + Z')
      // undo()
      // canvas.undo()
      // console.log(canvas._objects.pop())
      // console.log(canvas._objects)
      // canvas.renderAll()
    } 
  }

  return (
    <Container isVisible={isVisible} >
      <PaperContainer >
        <Toolbar title={'Workspace'} isClose close={close} />

        <ToolboxWrap>
          <Toolbox>
            <div className='tool tool_preview' onClick={createPreview}>Preview</div>
            <div className='tool tool__circle' onClick={createCircle}><img alt={circleIcon} src={circleIcon} /></div>
            <div className='tool tool__box' onClick={createRect}><img alt={squareIcon} src={squareIcon} /></div>
            <div className='tool tool__text' onClick={createTextBox}><img alt={textIcon} src={textIcon} /></div>
            <div className='tool tool__color_picker' ><input className='get_color' type='color' onChange={getColor} /></div>
          </Toolbox>
        </ToolboxWrap>

        <ImageListWrap>
          <ImageList>
            {imageUrlList.map((el, idx) => {
              return (
                <ItemWrap key={String(idx)} active={selected === idx} onClick={()=>clickImg(idx)}>
                  <ImageItem alt={el.image_url} src={el.image_url} />
                  {fabric_canvas_list[idx]?._previewed &&
                    <CheckSave>
                      <img alt='checkMarkIcon' src={checkMarkIcon} width='18px' />
                    </CheckSave>
                  }
                </ItemWrap>
              )
            })}
          </ImageList>
        </ImageListWrap>

        <CanvasWrap id='image_box' >
          {imageUrlList.map((klass, idx) => {
            return (
              <div key={String(idx)} style={{display: `${selected === idx ? 'flex' : 'none'}`}} >
                <Canvas 
                  id={`canvas_${idx}`} 
                  width={klass.width} 
                  height={klass.height} 
                  // style={{width: `${klass.width}`, height: `${klass.height}`}}
                />
              </div>
            )
          })}
        </CanvasWrap>

      </PaperContainer>
    </Container>
  )
}

const ImageListWrap = styled.div`
  margin: 0.25rem 0;
  width: 100%;
  height: 4.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
  overflow: hidden;

  overflow: auto visible;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const ImageList = styled.div`
  padding: 0.2rem;
  width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
`
const ItemWrap = styled.div`
  position: relative;
  margin: 0.2rem 0;
  width: 5rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: ${(props) => props.active ?  '2px solid #0d65ff' : '2px solid rgba(0, 0, 0, 0.08)'};
`
const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  transition: 150ms ease;
  cursor: pointer;
  :hover {
    /* transform: scale(1.07); */
  }
`
const CheckSave = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
`

export default Workspace