import * as React from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;

const SubList = React.memo(({ OCR, NOCR, KOCR, total, done }) => {

  return (
    <TableContainer component={Paper} sx={{ margin: '1rem 0', minHeight: '115px', border: '1px solid rgba(0, 0, 0, 0.1)'}} >
      <Table sx={{ minWidth: 650, cursor: 'default' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <Cell align="center">누적 완료 <b>문항</b> 수</Cell>
            <Cell align="center"><b>K-OCR</b></Cell>
            <Cell align="center">{KOCR}</Cell>
            <Cell align="center"><b>OCR</b></Cell>
            <Cell align="center">{OCR}</Cell>
            <Cell align="center"><b>NOCR</b></Cell>
            <Cell align="center">{NOCR}</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <Cell align="center">누적 완료 <b>시험지</b> 수</Cell>
            <Cell align="center"><b>전체 배정 부수</b></Cell>
            <Cell align="center">{total}</Cell>
            <Cell align="center"><b>완료 부수</b></Cell>
            <Cell align="center">{done}</Cell>
          </Row>
        </TableBody>
      </Table>
    </TableContainer>
  );
})

export default SubList