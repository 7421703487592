import { produce } from "immer"
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from "redux-actions"

// // actions
const IS_LOADING = "IS_LOADING" // 검색 로딩
const SET_LABELER_RANK_LIST = "SET_LABELER_RANK_LIST" // 라벨러 순위 리스트
const SET_NOTICE_DETAIL = "SET_NOTICE_DETAIL"

// // actionCreators
const isLoading = createAction(IS_LOADING, (boolean) => ({ boolean })) // 검색 로딩
const setLabelerRankList = createAction(SET_LABELER_RANK_LIST, (rankList) => ({ rankList })) // 라벨러 순위 리스트
const setNoticeDetail = createAction(SET_NOTICE_DETAIL, (obj) => ({ obj }))

// // initialState
const initialState = {
  is_loading: false,
  labeler_rank_list: [],
  notice_detail: {},
}

// { labeler_rank_list } 요청
const getLabelerRankListAPI = () => {
  const path = `/labelers/rank`

  return async function (dispatch) {
    try {
      const res = await Axios.get(path)
      // console.log(res)
      if (res.status === 200) {
        dispatch(setLabelerRankList(res.data))
      }
    } catch (err) {
      console.log(err, "getLabelerRankListAPI")
    }
  }
}

// 공지사항 요청
const getNoticeListAPI = (page) => {
  const token = localStorage.getItem('access_token');
  return async function (dispatch) {
    try {
      const res = await Axios.get(`notice/${page}`, {
        headers: { Authorization: token },
      })
      console.log(res)
      // if (res.status === 200) {
      //   dispatch(setLabelerRankList(res.data))
      // }
    } catch (err) {
      console.log(err, "getNoticeListAPI")
    }
  }
}

// 공지사항 상세 페이지
const getNoticeDetailAPI = (notice_id) => {
  const token = localStorage.getItem('access_token');
  return async function (dispatch) {
    try {
      const res = await Axios.get(`notice/detail/${notice_id}`, {
        headers: { Authorization: token },
      })
      // console.log(res)
      console.log(res.data)
      if (res.status === 200) {
        dispatch(setNoticeDetail(res.data))
      }
    } catch (err) {
      console.log(err, "getNoticeDetailAPI")
    }
  }
}

// // reducer
export default handleActions(
  {
    [IS_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = action.payload.boolean;
      }),
      
    [SET_LABELER_RANK_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.labeler_rank_list = action.payload.rankList;
      }),

    [SET_NOTICE_DETAIL]: (state, action) =>
      produce(state, (draft) => {
        draft.notice_detail = action.payload.obj;
      }),
  },
  initialState
);

const actionCreators = {
  isLoading,
  getLabelerRankListAPI,
  setLabelerRankList,
  getNoticeListAPI,
  getNoticeDetailAPI,

  setNoticeDetail,
};

export { actionCreators };
