import React, { useState, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import LayoutModal from '../../elements/modal/LayoutModal'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useQuery } from 'react-query'
import { getNoticeListAPI } from '../../api/getAPI'
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;
const Title = styled.div`
  /* display: inline-block; */
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const BackModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  transition: all 360ms ease;
`
const ContentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 90%;
  max-width: 95%;
  max-width: 80%;
  max-height: 90%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 35px 15px 15px;

  transition: all 360ms ease;

  .header_wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .icon_wrap {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: -1.74rem;
    right: -0.5rem;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* border: 1px solid red; */
  }
  .modal_title {
    color: #222222;
    font-size: 18px;
    font-weight: 700;
  }
  .modal_content {
    color: #222222;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 100%;
    padding: 10px;

    overflow-y: auto;
    overflow-x: overlay;
    word-wrap: break-word;

    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    & img {
      width: 100%;
    }
  }
`

const NoticeList = (props) => {
  const navigate = useNavigate()
  const quillRef = useRef();
  const [ page, setPage ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ checkList, setCheckList ] = useState([])
  const [ normalList, setNormalList ] = useState([])
  const [ totalCount, setTotalCount ] = useState(100)

  const [ modalTitle, setModalTitle ] = useState(false)
  const [ modalContent, setModalContent ] = useState('')
  const [ isModal, setIsModal ] = useState(false)
  const openModal = (title, content) => {
    setModalTitle(title)
    setModalContent(content)
    setIsModal(true)
  }
  const closeModal = () => {
    setIsModal(false)
  }

  useEffect(() => {})

  const { data } = useQuery(['noticeList', page], () => getNoticeListAPI(page + 1), {
    retry: false,
    onSuccess: data => {
      console.log(data)
      const noticeList = data.data.data
      const total_count = data.data.total_count
      const checkArr = noticeList.filter(el => el.check === 1)
      const normalArr = noticeList.filter(el => el.check === 0)
      setCheckList(checkArr)
      setNormalList(normalArr)
      setTotalCount(total_count)
    },
    onError: error => {
      // alert(error)
    }
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const modules = useMemo(() => {
    return {
      toolbar: false,
    };
  }, []);


  return (
    <Wrap style={{position: 'relative'}}>
      <TableContainer component={Paper} sx={{ height: '100%' }} >
        {isModal &&
          <>
            <BackModal onClick={closeModal}></BackModal>
            <ContentModal>
              <div className='header_wrap' >
                <div className='modal_title'>{modalTitle}</div>
                <div className='icon_wrap' onClick={closeModal}>
                  <CloseRoundedIcon medium sx={{width: '2rem', height: '2rem'}} />
                </div>
              </div>
              {/* <div
                className='modal_content'
                dangerouslySetInnerHTML={ {__html: modalContent} }
              >
              </div> */}
              <QuillWrap>
                <ReactQuill
                  ref={quillRef}
                  // theme='snow'
                  value={modalContent}
                  modules={modules}
                  style={{height: '90%'}}
                />
              </QuillWrap>
            </ContentModal>
          </>
        }
        
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align="center">No.</Cell>
              <Cell align="center">내용</Cell>
              <Cell align="center">작성자</Cell>
              <Cell align="center">날짜</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkList.length > 0 && checkList.map((el, index) => {
              return (
                <Row
                  key={String(index)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => openModal(el.title, el.content)}
                >
                  <Cell 
                    align="center"
                  >중요</Cell>
                  <Cell 
                    align="left"
                  >
                    <Title style={{float: 'left'}}>{el.title}</Title>
                  </Cell>
                  <Cell
                    align="center"
                  >{el.writer}</Cell>
                  <Cell
                    align="center"
                  >{el.created_time}</Cell>
                </Row>
              )
            })}
            {normalList.length > 0 && normalList.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              return (
                <Row
                  key={String(index)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => openModal(row.title, row.content)}
                >
                  <Cell align="center">{no}</Cell>
                  <Cell 
                    align="left"
                  >
                    <Title style={{float: 'left'}}>{row.title}</Title>
                  </Cell>
                  <Cell align="center">{row.writer}</Cell>
                  <Cell align="center">{row.created_time}</Cell>
                </Row>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalCount !== '0' &&
        <TablePagination
          sx={{overflow: 'hidden'}}
          rowsPerPageOptions={[20]}
          colSpan={9}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      }
    </Wrap>
  );
}
const QuillWrap = styled.div`
  /* height: 100%; */
  width: 100%;
  overflow-y: auto;
  overflow-x: overlay;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .option-item {
    font-size: 14px;
    :hover {
      font-weight: 900;
    }
  }
`

export default React.memo(NoticeList)