import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination'
import { useQuery } from 'react-query'
import { getNewLabelerListAPI, getOldLabelerListAPI } from '../../../api/getAPI'

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;

const NewLabelerList = React.memo((props) => {
  const { type } = props
  const navigate = useNavigate()
  const [ page, setPage ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ labelerList, setLabelerList ] = useState([])
  const [ totalCount, setTotalCount ] = useState([])

  const requestNew = useQuery(['newLabelerList', page], () => getNewLabelerListAPI(page + 1), 
  {
    onSuccess: data => {
      // console.log(data)
      const labelerList = data.data.unsigned_labeler
      const total_count = data.data.total_count
      handleLabelerList(labelerList)
      handleTotalCount(total_count)
    },
    onError: error => {
      console.log(error)
    }
  })

  const handleLabelerList = useCallback((list) => {
    setLabelerList(list)
  }, [labelerList])
  
  const handleTotalCount = useCallback((count) => {
    setTotalCount(count)
  }, [totalCount])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToDetail = (id) => {
    navigate(`/labeler/management/info/${id}`)
  }

  // console.log('page', labelerList)

  return (
    <Wrap>
      <TableContainer component={Paper} sx={{ height: '100%' }} >        
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align="center">No.</Cell>
              <Cell align="center">이름</Cell>
              <Cell align="center">가입일</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labelerList.length > 0 && labelerList.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              return (
                <Row
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { cursor: 'pointer' } }}
                  onClick={() => goToDetail(row.id)}
                >
                  <Cell align="center">{no}</Cell>
                  <Cell align="center">{row.user_name}</Cell>
                  <Cell align="center">{row.created_time}</Cell>
                </Row>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{overflow: 'hidden'}}
        rowsPerPageOptions={[10]}
        colSpan={9}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Wrap>
  );
})

export default NewLabelerList