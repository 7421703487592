import * as S from './style/EditAreaStyle'
import { useParams } from 'react-router'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditorQuestion from './editorArea/question/EditorQuestion'
import EditorQuestionInfo from './editorArea/questionInfo/EditorQuestionInfo'
import { actionCreators as writeEditorActions } from "../../redux/modules/writeEditor"
import ReEditArea from './ReEditArea'
import Accordion from '../_molecules/Accordion'
import _ from 'lodash'
// interface ContainerProps {
//   children? : React.ReactNode;
// }

const EditArea = () => {
  const {'data_type': params02, 'question_id': params} = useParams()
  const data_type = params02
  const _nation = localStorage.getItem("_nation")
  const _authority = localStorage.getItem("_authority")
  const is_books = useSelector((state) => state.writeEditor.is_books)
  const [ tab, setTab ] = useState('문제')
  const onClickTab = (name) => {
    setTab(name)
  }

  const qb = [
    { key: 0, name: '문제' },
    { key: 1, name: '답' },
    { key: 2, name: '해설' },
  ]
  const [ tabItems, setTabItems ] = useState(qb)

  // useEffect(() => {
  //   if (is_books) setTabItems([...tabItems, { key: 2, name: '해설' }])
  // }, [is_books])

  return (
    <S.Container>
      {/* 지문 작성자 */}
      {data_type === 'related_text' 
        ? <ReEditArea type={'지문'} />
        : (
          <>
            <S.Header>
              {tabItems.map((item, idx) => {
                return (
                  <S.HeaderItem 
                    key={item.name} 
                    onClick={() => onClickTab(item.name)} 
                    backgroundColor={item.name === tab ? true : false} 
                  >
                    {item.name}
                  </S.HeaderItem>
                )
              })}
            </S.Header>
            <S.Main>
              {tab === '문제' &&
                <ReEditArea type={'문제'} />
              }
              {tab === '답' &&
                <EditorQuestionInfo />
              }
              {/* {tab === '해설' && (is_books || (_nation === '1' && _authority === '4')) && */}
              {tab === '해설' && 
                <ReEditArea type={'해설'} />
              }
            </S.Main>
          </>
        )
      }
    </S.Container>
  )
}

export default EditArea