import { produce } from "immer"
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from "redux-actions"

// actions
const IS_BOOKS = "IS_BOOKS" // 
const IS_LOADING = "IS_LOADING" // 스켈레톤 로딩
const IS_REC_OCR_LOADING = "IS_REC_OCR_LOADING" // rec ocr 로딩
const GET_PROJECT_CONTENT_ID = "GET_PROJECT_CONTENT_ID" // project_id + content_id
const IS_WRITE_YN_MULTIPLE = "IS_WRITE_YN_MULTIPLE" // write_yn + is_multiple 저장

const SET_IMAGE_DATA = "SET_IMAGE_DATA" // 이미지 obj - 문제 / 답안지 / 연관 지문
const SET_QUESTION_DATA_HTML = "SET_QUESTION_DATA_HTML" // 메인 문제 html 데이터
const SET_EXPLANATION_DATA_HTML = "SET_EXPLANATION_DATA_HTML" // 해설 html 데이터
const SET_RELATED_TEXT_DATA_HTML = "SET_RELATED_TEXT_DATA_HTML" // 지문 html 데이터

const SET_MULTIPLE_ANSWER_LIST = "SET_MULTIPLE_ANSWER_LIST" // 객관식 정답 저장
const SET_MULTIPLE_HTML_LIST = "SET_MULTIPLE_HTML_LIST" // 객관식 데이터 저장
const SET_MULTIPLE_HTML_RAW = "SET_MULTIPLE_HTML_RAW" // 객관식 raw 저장
const IS_MULTIPLE_RAW_EMPTY = "IS_MULTIPLE_RAW_EMPTY" // 객관식 raw 데이터 한 개라도 입력 없으면 null

const SET_ESSAY_ANSWER_LIST = "SET_ESSAY_ANSWER_LIST" // 주관식 정답 저장

const SET_DIFFICULTY = "SET_DIFFICULTY" // 
const SET_EXPLANATION = "SET_EXPLANATION" // 

const SET_INIT_STATE = "SET_INIT_STATE" // 


// actionCreators
const isBooks = createAction(IS_BOOKS, (boolean) => ({ boolean }))
const isLoading = createAction(IS_LOADING, (boolean) => ({ boolean }))
const isRecOcrLoading = createAction(IS_REC_OCR_LOADING, (boolean) => ({ boolean }))
const get_project_content_id = createAction(GET_PROJECT_CONTENT_ID, (obj) => ({ obj }))
const is_writeYN_multiple = createAction(IS_WRITE_YN_MULTIPLE, (obj) => ({ obj }))

const set_image_data = createAction(SET_IMAGE_DATA, (obj) => ({ obj }))
const set_question_data_html = createAction(SET_QUESTION_DATA_HTML, (string) => ({ string }))
const set_explanation_data_html = createAction(SET_EXPLANATION_DATA_HTML, (string) => ({ string }))
const set_related_text_data_html = createAction(SET_RELATED_TEXT_DATA_HTML, (string) => ({ string }))

const set_multiple_answer_list = createAction(SET_MULTIPLE_ANSWER_LIST, (list) => ({ list }))
const set_multiple_html_list = createAction(SET_MULTIPLE_HTML_LIST, (list) => ({ list }))
const set_multiple_html_raw = createAction(SET_MULTIPLE_HTML_RAW, (string) => ({ string }))
const set_is_multiple_raw_empty = createAction(IS_MULTIPLE_RAW_EMPTY, (bool) => ({ bool }))

const set_essay_answer_list = createAction(SET_ESSAY_ANSWER_LIST, (list) => ({ list }))

const set_difficulty = createAction(SET_DIFFICULTY, (int) => ({ int }))
const set_explanation = createAction(SET_EXPLANATION, (int) => ({ int }))

const set_init_state = createAction(SET_INIT_STATE, () => ({  }))



const init_multiple_html = `
  <section class="pr-multiple_box_wrap">
    <div class="pr-multiple_box" data-num="">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="①">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="②">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="③">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="④">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
    <div class="pr-multiple_box" data-num="⑤">
      <div class="pr-multiple_box_content_wrap">
        <div class="pr-multiple_box_content">
          <p></p>
        </div>
      </div>
    </div>
  </section>
`

// initialState
const initialState = {
  is_books: false,                         // 큐비 or 북스
  is_loading: true,                        // 스켈레톤 로딩
  is_rec_ocr_loading: false,               // rec ocr 로딩
  project_id: null,
  content_id: null,
  pdf_id: null,
  write_yn: 0,                             // 변경됨: 1, 처음: 0
  is_multiple: -1,                         // 객관식: 1, 주관식: 0
  
  data_html: '',                           // 메인 문제 데이터 - html
  explanation_html: '',                    // 해설 데이터 - html
  related_text_html: '',                   // 지문 데이터 - html
  image_data: {
    question_image_name: '', 
    solution_image_name: '', 
    solution_image_name_2: [], 
    related_image_name: ''
  },                                       // question_image_name / solution_image_name / related_image_url
  
  // 객관식
  multiple_answer_list: [],                // ['1', '2', '3'] : 정답
  multiple_html_raw: init_multiple_html,   // '<p></p>'
  is_multiple_raw_empty: false,            // 비어있으면 true, 다 작성 완료면 false
  
  // 주관식
  essay_answer_list: ['<p></p>'],          // ['<p></p>', '<p></p>'] : 정답
  
  
  // commonInfo
  difficulty: 0,                           // 난이도 (int)
  is_explanation: 1,                       // 해설 유무 (int)
}

// 해설 OCR 요청
const postExplanationOcrContentAPI = (questionId, image_url) => {
  const accessToken = localStorage.getItem('access_token')
  const path = `books/labeling/ocr/explanation`
  const body = {
    image_url: image_url
  }

  return async function (dispatch) {
    try {
      dispatch(isRecOcrLoading(true))
      const res = await Axios.post(path, body, {
        headers: { Authorization: accessToken },
      })
      console.log(res)
      if (res.status !== 200) return 
      const newData = await dispatch(storeNewData(res.data))  // math parsing
      console.log(newData)
      await dispatch(set_explanation_data_html(newData))
    } catch (err) {
      console.log(err, "postExplanationOcrContentAPI")
    }
    dispatch(isRecOcrLoading(false))
  }
}


// 1차 검수 - 작성 : 데이터 요청
const getOcrContentAPI = (questionId) => {
  const token = localStorage.getItem('access_token')
  // const path = `/question/${questionId}/all?nation=[1]`
  const path = `/question/${questionId}`

  return async function (dispatch) {
    try {
      await dispatch(set_init_state())
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.status === 200) {
        const ko_data = res.data
        const html_data = ko_data.html
        const image_data = ko_data.image

        await dispatch(storeOldQuestionData(ko_data, questionId))

        let answer_image
        if (typeof image_data.answer_image === 'string') {
          answer_image = `${image_data.answer_image}?time=${Math.random()}` 
        } else {
          answer_image = image_data.answer_image.map(el => `${el}?time=${Math.random()}`)
        }

        await dispatch(set_image_data(
            { 
              question_image_name: `${image_data.question_image}?time=${Math.random()}`, 
              solution_image_name: answer_image,
              related_image_name: `${image_data.relation_image}?time=${Math.random()}` 
            },
          )
        )
        await dispatch(is_writeYN_multiple(
            { 
              write_yn: null, 
              is_multiple: ko_data.is_select
            },
          )
        )

        // 기출에서의 해설 라벨링 작업이 시작되면서 기출에서도 해설 작성 및 저장이 가능하도록 로직 추가함
        await dispatch(set_explanation_data_html(html_data.explanation_html))
        
        if (ko_data.is_book) {
          await dispatch(isBooks(ko_data.is_book))
          await dispatch(set_explanation_data_html(html_data.explanation_html))
          await dispatch(set_explanation(html_data.explanation_html === '<p>해설 없음</p>' ? 0 : 1))
        }
        
        await dispatch(isLoading(false))
      }
    } catch (err) {
      console.log(err, "getOcrContentAPI")
      if (err.response.status === 444) alert(err.response.data.message)
      await dispatch(isLoading(false))
    }
  }
}

// 지문 라벨링에 필요한 데이터 요청
const getRelatedTextAPI = (relatedTextId) => {
  const token = localStorage.getItem('access_token')
  const path = `/related-text/${relatedTextId}`

  return async function (dispatch) {
    try {
      await dispatch(set_init_state())
      const res = await Axios.get(path, {
        headers: { Authorization: token },
      })
      console.log(res)
      if (res.status === 200) {
        const html_data = res.data.html
        const image_data = res.data.image

        // update 지문 html
        await dispatch(set_related_text_data_html(html_data))

        // update 지문 image
        await dispatch(set_image_data(
            { 
              question_image_name: '', 
              solution_image_name: '',
              related_image_name: `${image_data}?time=${Math.random()}` 
            },
          )
        )
        await dispatch(isLoading(false))
      }
    } catch (err) {
      console.log(err, "getRelatedTextAPI - GET 지문")
      if (err.response.status === 444) alert(err.response.data.message)
      await dispatch(isLoading(false))
    }
  }
}

// ocr - 처음
const storeNewData = (data) => {
  return async function (dispatch) {
    try {
      const result = data.text.split('$').map((v, i)=>{
        if (i % 2 === 1) {
          return `<span class="math-tex">\\(${v}\\)</span>`
        } else {
          return v
        }
      }).join('')
      return `<p>${result}</p>`

    } catch (err) {
      console.log(err, "storeNewData")
    }
  }
}

// question_ocr - 처음 X
const storeOldQuestionData = (ko_data, id) => {
  const html_data = ko_data.html
  return async function (dispatch) {
    try {
      await dispatch(set_question_data_html(html_data.question_html))  // 문제 html
      if (ko_data.difficult) await dispatch(set_difficulty(ko_data.difficult))  // 난이도

      if (ko_data.is_select === 1) await dispatch(set_multiple_answer_list(ko_data.answer === null ? [] : ko_data.answer)) // 정답 - 객관식
      if (ko_data.is_select === 0) await dispatch(set_essay_answer_list(ko_data.answer === null ? ['<p></p>'] : ko_data.answer)) // 정답 - 주관식
      if (html_data.selection_html) await dispatch(set_multiple_html_raw(html_data.selection_html)) // 객관식 html - 한국
      
    } catch (err) {
      console.log(err, "storeOldQuestionData")
    }
  }
}

// 문제 데이터 Reset
const postQuestionResetAPI = (question_id = 0) => {
  if (window.confirm('지금까지 수정된 모든 내용이 삭제됩니다. Reset 하시겠습니까?') === false) return
  const path = `labeling/reset`
  const accessToken = localStorage.getItem("access_token")
  const body = {
    question_id: Number(question_id),
  }
  // console.log(body)

  return async function (dispatch) {
    try {
      const res = await Axios.post(path, body, {
        headers: { Authorization: accessToken },
      })
      if (res.data.message === 'reset success') {
        // dispatch(getOcrContentAPI(question_id))
        window.location.reload()
      }
    } catch (err) {
      console.log(err, "postQuestionResetAPI")
    }
  }
}


// // reducer
export default handleActions(
  {
    [IS_BOOKS]: (state, action) =>
      produce(state, (draft) => {
        draft.is_books = action.payload.boolean;
      }),
    [IS_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_loading = action.payload.boolean;
      }),
    [IS_REC_OCR_LOADING]: (state, action) =>
      produce(state, (draft) => {
        draft.is_rec_ocr_loading = action.payload.boolean;
      }),
    [GET_PROJECT_CONTENT_ID]: (state, action) =>
      produce(state, (draft) => {
        draft.project_id = action.payload.obj.project_id;
        draft.content_id = action.payload.obj.content_id;
      }),
    [IS_WRITE_YN_MULTIPLE]: (state, action) =>
      produce(state, (draft) => {
        draft.write_yn = action.payload.obj.write_yn;
        draft.is_multiple = action.payload.obj.is_multiple;
      }),
    [SET_QUESTION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.data_html = action.payload.string;
      }),
    [SET_EXPLANATION_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.explanation_html = action.payload.string;
      }),
    [SET_RELATED_TEXT_DATA_HTML]: (state, action) =>
      produce(state, (draft) => {
        draft.related_text_html = action.payload.string;
      }),
      [SET_MULTIPLE_ANSWER_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_answer_list = action.payload.list;
      }),
      [SET_MULTIPLE_HTML_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_html_list = action.payload.list;
      }),
      [SET_MULTIPLE_HTML_RAW]: (state, action) =>
      produce(state, (draft) => {
        draft.multiple_html_raw = action.payload.string;
      }),
      [IS_MULTIPLE_RAW_EMPTY]: (state, action) =>
      produce(state, (draft) => {
        draft.is_multiple_raw_empty = action.payload.bool;
      }),
      [SET_ESSAY_ANSWER_LIST]: (state, action) =>
      produce(state, (draft) => {
        draft.essay_answer_list = action.payload.list;
      }),
    [SET_IMAGE_DATA]: (state, action) =>
      produce(state, (draft) => {
      draft.image_data = action.payload.obj;
    }),
    [SET_DIFFICULTY]: (state, action) =>
      produce(state, (draft) => {
        draft.difficulty = action.payload.int;
      }),
    [SET_EXPLANATION]: (state, action) =>
      produce(state, (draft) => {
        draft.is_explanation = action.payload.int;
      }),
    [SET_INIT_STATE]: (state, action) =>
      produce(state, (draft) => {
        draft.is_books = false;
        draft.is_loading = true;
        draft.is_rec_ocr_loading = false;
        draft.project_id = null;
        draft.pdf_id = null;
        draft.write_yn = 0;
        draft.is_multiple = -1;
        draft.data_html = '';
        draft.explanation_html = '';
        draft.related_text_html = '';
        draft.image_data = {
          question_image_name: '', 
          solution_image_name: '', 
          solution_image_name_2: [], 
          related_image_name: ''
        };
        draft.multiple_answer_list = [];
        draft.multiple_html_raw = init_multiple_html;
        draft.is_multiple_raw_empty = false;
        draft.essay_answer_list = [];
        draft.difficulty = 0;
        draft.is_explanation = 1;
      }),
  }, 
  initialState
);

  
const actionCreators = {
  getOcrContentAPI,
  getRelatedTextAPI,
  postExplanationOcrContentAPI,
  postQuestionResetAPI,

  set_question_data_html,
  set_explanation_data_html,
  set_related_text_data_html,

  set_multiple_answer_list,
  set_multiple_html_list,
  set_multiple_html_raw,
  set_is_multiple_raw_empty,

  set_essay_answer_list,

  set_difficulty,
  set_explanation,

  set_init_state,

  isRecOcrLoading
};

export { actionCreators };