import * as S from './Form.style'
import { forwardRef } from 'react'
import Select from './Select'
import ReplayIcon from '@mui/icons-material/Replay'
import SelectBox from '../../../elements/SelectBox'

const Form = forwardRef(function Form(props, ref) {
  const { title, type, value, width, disabled, phone, className, onkeypress, onCheckId,
    flexAlign, titleFlex, inputFlex, onchange, recall, recallFn, select_type=null, date_type, check_btn=null, setState } = props

  // console.log(select)
  const formData = new FormData()
  const date = new Date()

  function makeRangeList(start, end, diff = 1, reverse = false) {
    const list = []
    if (reverse) {
      for (let i = end; i >= start; i -= diff) {
        list.push(i)
      }
    } else {
      for (let i = start; i <= end; i += diff) {
        list.push(i)
      }
    }
    return list
  }

  return (
      <S.Wrap 
        className="form_box" 
        flexAlign={flexAlign}
        titleFlex={titleFlex}
        inputFlex={inputFlex}
      >
        {!phone && <div className="title">{title}</div> }
        <div className="input_wrap">
          {(!date_type && !select_type) &&
            <S.Input
              ref={ref}
              type={type} 
              name='userid' 
              width={width}
              border={!disabled} 
              disabled={disabled} 
              onChange={onchange}
              className={className}
              onKeyPress={onkeypress}
              defaultValue={value ? value : ''}
              placeholder={!disabled ? '입력하세요.' : null} 
            />
          }
          {check_btn &&
            <S.CheckBtn onClick={onCheckId}>중복 확인</S.CheckBtn>
          }
          {date_type &&
            <Select name={'year'} defaultContent={'선택하세요'}>
              {makeRangeList(1990, date.getFullYear(), 1, true)}
            </Select>
          }
          {select_type &&
            <SelectBox type={select_type} setState={setState} />
          }
          
          {recall && 
            <div 
              className='recall'
              onClick={recallFn}
            >
              <ReplayIcon sx={{fontSize: 30}} />
            </div>
          }
        </div>
      </S.Wrap>
    )
})

export default Form