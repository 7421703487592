import { Axios } from './api.config'
import axios from 'axios'
const baseURL = process.env.REACT_APP_API_URL

// 로그인 [src/components/main/Login.js]
export const loginAPI = async (id, pw, device) => {
  return await Axios.post(`login`, { id: id, password: pw, device })
}

export const requestImages = async ({type, period, page, subject}) => {
  return await Axios.post(`search/images`, {params: {type, period, page, subject}})
}

// 로그인
export const postLogin = async (data) => {
  const response = await Axios.post(`account/signin`, data)
  return response
}

// ----------------------------------------

// 로그인
export const postLoginAPI = async (obj) => {
  const {data} = await Axios.post(`account/login`, obj)
  return data
}

// 회원가입
export const postSignUpAPI = async (userData) => {
  const {data} = await Axios.post(`account/register`, userData)
  return data
}

// 아이디 찾기
export const postFindID = async (idData) => {
  const {data} = await Axios.post(`account/id`, idData)
  return data
}

// 전화번호 인증하기
export const postCheckPhone = async (obj) => {
  const {data} = await Axios.post(`account/sms`, obj)
  return data
}

// 아이디 중복 체크 
export const postCheckId = async (obj) => {
  const {data} = await Axios.post(`account/id/check`, obj)
  return data
}

// --------------------  라벨러  --------------------


// 1차 에디터 완료
export const postCompleteEditorAPI = async (obj) => {
  const {data} = await Axios.post(`ai/result`, obj)
  return data
}

// 중단원 입력 및 검수 완료
export const postMidUnitAPI = async (obj) => {
  const {data} = await Axios.post(`labeling/check`, obj)
  return data
}

// 큐비북스 2차 검수 저장하기
// export const postSaveBooksMidUnitAPI = async (obj) => {
//   const {data} = await Axios.post(`books/labeling/check`, obj)
//   return data
// }

// 이미지 자르기
export const postCropImageAPI = async (obj) => {
  const {data} = await Axios.post(`labeling/crop`, obj)
  return data
}

// ctrl + S
export const postImageEditorSaveAPI = async (obj) => {
  const {data} = await Axios.post(`labeling/boxing-save`, obj)
  return data
}

// 3차 라벨링 저장
export const postSaveAPI = async (obj) => {
  // console.log(obj)
  // const path = obj.question_id.split('_')[0] === 'TH' ? `thai` : `labeling`
  // const {data} = await Axios.post(`${path}/question/detail`, obj)
  const {data} = await Axios.post(`labeling/question/detail`, obj)
  return data
}

// 3차 라벨링 저장 - 태국
export const postThaiSaveAPI = async (obj) => {
  const {data} = await Axios.post(`thai/question/detail`, obj)
  return data
}

// 3차 라벨링 게시글 작성
export const postWriteAPI = async (obj) => {
  const {data} = await Axios.post(`labeling/errors`, obj)
  return data
}

// --------------------  어드민  --------------------


// 프로젝트 생성 - 프로젝트 id 받기
export const postProjectCreationAPI = async (obj) => {
  const {data} = await Axios.post(`project`, obj)
  return data
}

// 프로젝트 생성
  // (최종 완료) 매칭된 라벨러 등록
export const postLabelerMatchingAPI = async (obj) => {
  const {data} = await Axios.post(`project/labeler`, obj)
  return data
}

// 프로젝트 할당/부여
  // 라벨러와 pdf 매칭
export const postPDFMatchingAPI = async (arg) => {
  console.log(arg)
  const role_path = arg.role === 0 ? 'admin-pdf' : 'done-pdf'
  const path = `/project/${role_path}`;
  const {data} = await Axios.post(path, arg.data)
  return data
}

// 공지사항 등록
export const postNoticeAPI = async (obj) => {
  const {data} = await Axios.post(`notice`, obj)
  return data
}

// 공지사항 - 이미지 첨부
export const postImageUploadAPI = async (formData) => {
  const {data} = await Axios.post(`upload-image`, formData)
  return data
}

// 2차 검수 - 최종 완료
export const postFinalAPI = async (body) => {
  const {data} = await Axios.post(`labeling/check/final`, body)
  return data
}

// ----------- delete -----------

// 
export const deletePdfAPI = async (obj) => {
  const {data} = await Axios.delete(`project/give`, {
    data: obj
  })
  return data
}

// 공지사항 삭제
export const deleteNoticeAPI = async (obj) => {
  const {data} = await Axios.delete(`notice`, {
    data: obj
  })
  return data
}


// --------------------  태국  --------------------


// 중단원 매칭 저장
export const postSaveThaiMidUnitAPI = async (obj) => {
  const {data} = await Axios.post(`thai/subsection/matching`, obj)
  return data
}

// 태국 문제 삭제
export const postDeleteThaiQuestionAPI = async (obj) => {
  const {data} = await Axios.delete(`thai/question/delete`, { data: obj })
  return data
}
