import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Wrap = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
const Row = styled(TableRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`;
const Title = styled.div`
  /* display: inline-block; */
  min-width: 300px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SubSectionList = forwardRef(function SubSectionList(props, ref) {
  const { workType, subSectionList, pageNum, getPageNum, totalCount, selectedItem, handleSelectedItem } = props
  const [ page, setPage ] = useState(pageNum - 1)
  const [ rowsPerPage, setRowsPerPage ] = useState(20)
  const [ selected, setSelected ] = useState([])
  console.log(workType)
  
  const handleChangePage = (event, newPage) => {
    getPageNum(newPage + 1)
    setPage(newPage)
  }

  useEffect(() => {
    setPage(pageNum - 1)
  }, [pageNum])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20))
    setPage(0);
  }

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelectedItems = subSectionList.map((n) => n.id)
      handleSelectedItem(newSelectedItems)
      return
    }
    handleSelectedItem([])
  }

  const handleSelectOne = (id) => {

    const selectedIndex = selectedItem.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1));
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      );
    }
    handleSelectedItem(newSelected);
  };

  const isSelected = (id) => selectedItem.indexOf(id) !== -1;

  // const handleSubmit = () => {
  //   return selectedItem
  // }
  // const handleInitialSelected = () => {
  //   handleSelectedItem([])
  // }
  
  // useImperativeHandle(ref, () => ({ handleSubmit, handleInitialSelected }))

  return (
    <Wrap>
      <TableContainer component={Paper} sx={{height: '100%'}} >    
        <Table sx={{ minWidth: 200, cursor: 'default' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Cell align="center">No.</Cell>
              <Cell align="center">중단원</Cell>
              <Cell align="center">라벨러</Cell>
              {workType === '2' && <Cell align="center">검수자</Cell>}
              
              <Cell align="center">문제/지문 개수</Cell>
              <Cell align="center">
                <Checkbox
                  sx={{marginRight: '3px'}}
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < subSectionList.length}
                  checked={subSectionList.length > 0 && selected.length === subSectionList.length}
                  onChange={handleSelectAll}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subSectionList.length > 0 && subSectionList.map((row, index) => {
              const no = page * rowsPerPage + index + 1
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <Row
                  key={row.id}
                  hover
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { cursor: 'pointer' } }}
                  onClick={() => handleSelectOne(row.id)}
                >
                  
                  <Cell align="center">{no}</Cell>
                  <Cell align="left"
                    style={{
                      maxWidth: '25rem',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      wordBread: 'break-all'
                    }}
                  >{row.name}</Cell>
                  <Cell align="center">{row.labelers.join(' ')}</Cell>
                  {workType === '2' && <Cell align="center">{row.reviewers.join(' ')}</Cell>}
                  <Cell align="center">{row.count}</Cell>
                  <Cell align="center">
                    <Checkbox
                      sx={{padding: 0}}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </Cell>
                </Row>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{overflow: 'hidden'}}
        rowsPerPageOptions={[20]}
        colSpan={9}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Wrap>
  );
})

export default SubSectionList