import styles from './table.module.scss'

interface TrProps {
  children?: React.ReactNode;
  isSelected?: boolean
  disabled?: boolean
  active?: boolean
  even?: boolean
  onClick?: ()=>void
  style?: any
}
const Tr = ({
  children, 
  isSelected,
  disabled,
  active,
  even,
  onClick,
  style
}: TrProps) => {
  return (
    <tr 
      className={`
        ${styles.tr} 
        ${even ? styles.tr_even : ""}
        ${active ? styles.tr_active : ""}
        ${disabled ? styles.tr_disabled : ""}
        ${isSelected ? styles.tr_is_selected : ""}
      `} 
      onClick={onClick} 
      style={style}
    >
      {children}
    </tr>
  )
}

export default Tr