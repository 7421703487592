import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../../components/_templates/MainSection'
import Table from '../../../components/_organisms/mainSection/Table'
import Toolbar from '../../../components/_organisms/mainSection/Toolbar'

import { useQuery } from 'react-query'
import { getAPI } from '../../../api/getAPI'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`
const BackModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  transition: all 360ms ease;
`
const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 80%;
  max-height: 80%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px;

  transition: all 360ms ease;

  .modal_title {
    color: #222222;
    font-size: 18px;
    font-weight: 700;
  }
  .modal_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any
  openModal?: (projectId: number, role: number, labeler: any)=>void
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], openModal=()=>{}}: tableBodyProps) => {
  const navigate = useNavigate()
  const onClick = () => {
    console.log('ddddddddddd')
  }

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        return (
          <Row
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={onClick}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="left">{row.title}</Cell>
            <Cell align="center">{row.date}</Cell>
            <Cell align="center">{row.status}</Cell>
          </Row>
        )
      })}
    </>
  )
}

const MatchingPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _search = searchParams.get('search')

  const headList = ['프로젝트명', '기간', '상태', '']
  const [bodyList, setBodyList] = useState(null)
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [keyword, setKeyword] = useState(_search)
  const getKeyword = (v: string) => {
    setKeyword(v)
  }

  const [isModal, setIsModal] = useState(false)
  const [infoInModal, setInfoInModal] = useState({id: 0, role: 0})
  const [labelersInModal, setLabelersInModal] = useState([])
  const openModal = (e, projectId:number, role:number, labeler:any) => {
    e.stopPropagation()
    setInfoInModal({id: projectId, role: role})
    setLabelersInModal(labeler)
    setIsModal(true)
  }

  const { isFetching } = useQuery(['project', page, keyword], () => getAPI('project/all', {page: page + 1, project_name: keyword}), {
    // enabled: !!(grade !== ''),
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.data} openModal={openModal} />
      setBodyList(list)
      setTotalCount(data.data.total_count)
    }
  })

  const changedKeyword = () => {
    setPage(0)
    setSearchParams({page: 1, search: keyword})
  }

  const changedPage = () => {
    setPage(page)
    setSearchParams({page: page + 1, search: keyword})
  }

  useEffect(() => {
    if (_search === keyword && _page - 1 === page) {
      return
    } else if (_search !== keyword && _page - 1 === page) {
      return changedKeyword()
    } else if (_search === keyword && _page - 1 !== page) {
      return changedPage()
    }
  }, [page, keyword])

  useEffect(() => {
    console.log('----_page----', _page)
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    console.log('----_search----', _search)
    setKeyword(_search)
  }, [_search])


  return (
    <MainSection>
      <>
        {isModal &&
          <>
            <BackModal onClick={()=>setIsModal(false)}></BackModal>
            <Modal>
              <div className='modal_title'>라벨러 현황</div>
              <div className='modal_content'>
                {labelersInModal?.map((el:any, idx) => {
                  return (
                    <Btn key={el.id} onClick={() => 
                      navigate({
                        pathname: `/labeler/matching/detail`,
                        search: `?${createSearchParams({projectId: infoInModal.id, labelerId: el.id, role: infoInModal.role})}`
                      })
                    }>
                      {el.user_name} ❯
                    </Btn>
                  )
                })}
                {labelersInModal.length === 0 &&
                  <div style={{margin: '0 auto 20px'}}>없음</div>
                }
              </div>
            </Modal>
          </>
        }
        <Toolbar title={'생성된 프로젝트'} isSearch keyword={keyword} getKeyword={getKeyword} />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={setPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

export default MatchingPage