import axios from 'axios'
import { produce } from 'immer'
import { Axios } from "../../api/api.config"
import { createAction, handleActions } from 'redux-actions'

// import { API } from "./api";

// actions
const IS_LOGIN = "IS_LOGIN";
const LOG_OUT = "LOG_OUT";
const SET_USER = "SET_USER";
const SET_CODE = "SET_CODE";
const SET_CODE_PW = "SET_CODE_PW";

// actionCreators
export const isLogin = createAction(IS_LOGIN, (boolean) => ({ boolean }));
export const logOut = createAction(LOG_OUT, () => ({}));
export const setUser = createAction(SET_USER, (token) => ({ token }));
export const setCode = createAction(SET_CODE, (string) => ({ string }));
export const setCodePW = createAction(SET_CODE_PW, (string) => ({ string }));

// initialState
const initialState = {
  is_login: false,
  code: null,
  code_pw: null,
};

// 일반 로그인
const loginAPI = (loginData) => {
  const api = `/account/login`
  const body = loginData
  // console.log(body)

  return async function (dispatch) {
    try {
      const res = await Axios.post(api, body)
      // console.log(res)
      if (res.status === 200) {
        const role = res.data.admin === 0 ? 'labeler' : 'admin'
        const group = res.data.group
        const token = `Bearer ${res.data.token}`
        localStorage.setItem("role", role)
        localStorage.setItem("group", group)
        localStorage.setItem("access_token", token)
        
        dispatch(isLogin(true))
      } else {
        alert('존재하지 않는 계정입니다')
      }
    } catch (err) {
      console.log(err, "loginAPI")
    }
  }
}

// 회원가입
const signUpAPI = (signUpData) => {
  const api = `/account/register`
  const body = signUpData
  // console.log(body)

  return async function (dispatch) {
    try {
      const res = await Axios.post(api, body)
      // console.log(res)
      // if (res.status === 200) {
      //   localStorage.setItem("access", 'admin')
      //   dispatch(isLogin(true))
      // } else {
      //   alert('회원가입 실패')
      // }
    } catch (err) {
      console.log(err, "signUpAPI")
    }
  }
}

// 인증번호 요청
const requestCodeAPI = (codeData, type) => {
  const api = `/account/sms`
  const body = codeData
  // console.log(body)
  // console.log(type)

  return async function (dispatch) {
    try {
      const res = await Axios.post(api, body)
      // console.log(res)
      if (res.status === 201) {
        if (type === 'id') dispatch(setCode(res.data.CODE))
        if (type === 'pw') dispatch(setCodePW(res.data.CODE))
      } else {
        alert('인증번호 요청에 실패하였습니다. 다시 시도해 주세요.')
      }
    } catch (err) {
      console.log(err, "requestCodeAPI")
      if (err.response.data.message === 'user != phone') {
        alert('존재하지 않는 유저 정보입니다. 다시 입력해 주세요.')
      }
    }
  }
}

// 아이디 찾기
const findIdAPI = (idData) => {
  const api = `/account/id`
  const body = idData
  // console.log(body)

  return async function (dispatch) {
    try {
      const res = await Axios.post(api, body)
      // console.log(res)
      // if (res.status === 201) {
      //   dispatch(setCode(res.data.CODE))
      // } else {
      //   alert('회원가입 실패')
      // }
    } catch (err) {
      console.log(err, "requestCodeAPI")
    }
  }
}

// 로그인 상태 체크
const loggedIn = () => {
  return function (dispatch) {
    const token = localStorage.getItem("access") ? true : false

    if (token) {
      dispatch(isLogin(true))
    }
  }
}

// 로그아웃 localstorage 삭제
const isLogout = () => {
  return function (dispatch, { history }) {
    localStorage.removeItem("access_token");
    dispatch(logOut());
    // history.push("/");
  };
};

// reducer
export default handleActions(
  {
    [IS_LOGIN]: (state, action) =>
      produce(state, (draft) => {
        // draft.token = action.payload.token;
        draft.is_login = action.payload.boolean;
      }),

    [SET_USER]: (state, action) =>
      produce(state, (draft) => {
        draft.token = action.payload.token;
        draft.is_login = true;
      }),

    [SET_CODE]: (state, action) =>
      produce(state, (draft) => {
        draft.code = action.payload.string;
      }),

    [SET_CODE_PW]: (state, action) =>
      produce(state, (draft) => {
        draft.code_pw = action.payload.string;
      }),

    [LOG_OUT]: (state, action) =>
      produce(state, (draft) => {
        draft.token = null;
        draft.is_login = false;
      }),
  },
  initialState
);

const actionCreators = {
  setUser,
  loginAPI,
  isLogin,
  loggedIn,
  isLogout,
  signUpAPI,
  requestCodeAPI,
  findIdAPI,
  setCode,
  setCodePW
};

export { actionCreators };
