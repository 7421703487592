import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams, useParams } from 'react-router-dom'
import styled from 'styled-components'
import MainSection from '../../components/_templates/MainSection'
import Table from '../../components/_organisms/mainSection/Table'
import Toolbar from '../../components/_organisms/mainSection/Toolbar'
import ProgressBarOrigin from '../../elements/progressBar/ProgressBarOrigin'
import { useQuery } from 'react-query'
import { getAPI } from '../../api/getAPI'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const Row = styled(TableRow)`
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Cell = styled(TableCell)`
  word-break: keep-all;
`
const Btn = styled.div`
  padding: 5px 8px;
  min-width: fit-content;
  border-radius: 4px;
  transition: all 120ms ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    color: white;
    background-color: #2e6ef2;
    border: 1px solid #2e6ef2;
  }
`
const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: ${props => props.bgColor};
`

interface tableBodyProps {
  page: number
  rowsPerPage: number
  list: any
  openModal?: (projectId: number, role: number, labeler: any)=>void
  project_type: string
  project_id: number
}

export const CreateTableBody = ({page=0, rowsPerPage=0, list=[], project_type, project_id}: tableBodyProps) => {
  const navigate = useNavigate()
  const onClick = (content_id: number) => {
    navigate(`/workspace/project/${project_type}/${project_id}/content/${content_id}/question?page=1&status=`)
  }

  return (
    <>
      {list.length > 0 && list.map((row: any, index: number) => {
        const no = page * rowsPerPage + index + 1
        return (
          <Row
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={()=>onClick(row.id)}
          >
            <Cell align="center">{no}</Cell>
            <Cell align="left">{row.title}</Cell>
            <Cell align="center">{row.user_name}</Cell>
            <Cell align="center">
              {row.review_status === 1 && <Circle bgColor={'red'} />}
              {row.review_status === 2 && <Circle bgColor={'orange'} />}
              {row.review_status === 3 && <Circle bgColor={'#66CC33'} />}
            </Cell>
            <Cell align="center">{row.is_ocr ? 'O' : 'X'}</Cell>
            <Cell align="center">
              {row.question_count === 0 && '-'}
              {row.question_count !== 0 && `${row.completed_question_count}/${row.question_count}`}
            </Cell>
            <Cell align="center">{row.gaek_count}</Cell>
            <Cell align="center">{row.joo_count}</Cell>
          </Row>
        )
      })}
    </>
  )
}

const MatchingPage = () => {
  const {'project_id': project_id, 'project_type': project_type} = useParams()
  const _project_id = Number(project_id)
  const _project_type = project_type
  const [searchParams, setSearchParams] = useSearchParams()
  const _page = Number(searchParams.get('page'))
  const _subject = Number(searchParams.get('subject'))
  const _user = Number(searchParams.get('user'))

  const headList = ['제목', '유저명', '검수 현황', 'OCR 유무', '진행 상황', '객관식', '주관식']
  const [bodyList, setBodyList] = useState(null)
  const [title, setTitle] = useState('')
  const [page, setPage] = useState(_page - 1)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [ progressInfo, setProgressInfo ] = useState<any>({})

  // ----- filter -----
  const [subject, setSubject] = useState(_subject)
  const [subjectList, setSubjectList] = useState([{name: '전체', value: 0}])

  const [isUser, setIsUser] = useState(false)
  const [user, setUser] = useState(_user)
  const [userList, setUserList] = useState([{name: '전체', value: 0}])

  const { isFetching } = useQuery([`labeling/${_project_id}/contents`, {page, subject, user, _project_type}], () => getAPI(`${_project_type}/${_project_id}/contents`, {page: page + 1, subject: subject, user: user}), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log(data)
      const list: any = () => <CreateTableBody page={page} rowsPerPage={rowsPerPage} list={data.data.contents} project_type={_project_type} project_id={_project_id} />
      const subjects = data.data.filter.subjects.map(el => {return {name: el.name, value: el.id}})
      if (data.data.filter.users) {
        const users = data.data.filter.users?.map(el => {return {name: el.name, value: el.id}})
        setIsUser(true)
        setUserList(users)
      }
      setTitle(`Contents - ${data.data.title}`)
      setSubjectList(subjects)
      setProgressInfo(data.data.progress_bar)
      setBodyList(list)
      setTotalCount(data.data.page.content_count)
    }
  })

  const changedSubject = (v: number) => {
    setSearchParams({
      page: 1, 
      subject: v, 
      user: user,
    })
  }

  const changedUser = (v: number) => {
    setSearchParams({
      page: 1, 
      subject: subject, 
      user: v,
    })
  }

  const changedPage = (v: number) => {
    setSearchParams({
      page: v + 1, 
      subject: subject, 
      user: user,
    })
  }

  useEffect(() => {
    setPage(_page - 1)
  }, [_page])

  useEffect(() => {
    setSubject(_subject)
  }, [_subject])

  useEffect(() => {
    setUser(_user)
  }, [_user])


  return (
    <MainSection>
      <>
        <Toolbar 
          title={title} 
          isFilter 
          filter={subject} 
          filterList={subjectList} 
          getFilter={changedSubject} 
          filterLabel={'과목'} 
          filterBoxWidth={'9rem'} 
          isFilter_2={isUser}
          filter_2={user} 
          filterList_2={userList} 
          getFilter_2={changedUser} 
          filterLabel_2={'라벨러'} 
          filterBoxWidth_2={'9rem'} 
        />
        <ProgressBarOrigin 
          overall={progressInfo.total_content_count} 
          done={progressInfo.completed_content_count} 
          percent={progressInfo.percentage} 
        />
        <Table 
          headList={headList} 
          bodyList={bodyList} 
          page={page} 
          setPage={changedPage}
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage}
          count_total={totalCount}
          isLoading={isFetching}
        />
      </>
    </MainSection>
  )
}

export default MatchingPage