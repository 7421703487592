import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Grid, Paper } from '@mui/material'
import Button from '../../elements/button/Button'
import MyinfoForm from '../../components/mypage/MyInfoForm'
import { useQuery, useMutation } from 'react-query'
import { putMyPageInfoAPI } from '../../api/putAPI' 

import ChatContainer from '../../components/common/chat/Container'

const Mypage = (props) => {
  const storeRef = useRef()
  const { mutate } = useMutation(putMyPageInfoAPI, {
    onSuccess: data => {
      console.log(data)
      if (data.message === 'change success') {
        alert('내 정보가 수정되었습니다.')
        handlePostInfo(null)
        // window.location.reload()
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const handlePostInfo = (e) => {
    return storeRef.current.handleSetInfo(e)
  }

  const handleGetResult = () => {
    return storeRef.current.handleSubmit()
  }

  const handleStore = () => {
    const result = handleGetResult()
    // if (!result) return
    if (result === 'alert01') return alert('모든 항목을 입력해 주세요.')
    if (result === 'alert02') return alert('변경된 내용이 없습니다.')
    if (result === 'alert03') return alert('전화번호 변경 시, 번호 인증이 필요합니다.')
    console.log('mutate')
    mutate(result)
    // navigate(`/workspace/labeling/editor/5231`)
  }

  return (
    <Grid container direction={"column"} >

      {/* <ChatContainer /> */}
                  
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Item hidden={'hidden'} >
          <Title>
            <BtnBox>
              <div className='title'>내 정보</div>
              <BtnBox02>
                <Button onclick={handleStore}>수정하기</Button>
              </BtnBox02>
              
            </BtnBox>
          </Title>
          <MyinfoForm ref={storeRef} />
        </Item>
      </Grid>
    </Grid>
  )
}
const Item = styled(Paper)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow-y: ${props => props.hidden ? props.hidden : 'auto'};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
const Title = styled.div`
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }
`
const BtnBox02 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export default Mypage