import Command from '@ckeditor/ckeditor5-core/src/command';

export class PlusMultipleBoxCommand extends Command {
	execute() {
		const findNode = function (writer, name, root) {
			const range = writer.createRangeIn(root);

			for (const value of range.getWalker({ ignoreElementEnd: true })) {
				const node = value.item;

				if (node.is('element', name)) return node;
			}
		};

		const findNodes = function (writer, name, root) {
			let nodes = [];
			const range = writer.createRangeIn(root);

			for (const value of range.getWalker({
				ignoreElementEnd: true,
			})) {
				const node = value.item;

				if (node.is('element', name)) nodes.push(node);
			}

			return nodes;
		};

		this.editor.model.change((writer) => {
			const model = this.editor.model;
			const multipleBox = findNodes(
				writer,
				'multipleBoxContentWrap',
				model.document.getRoot()
			);

			// if (multipleBox[0].childCount) {
			const a = findNode(
				writer,
				'multipleBoxWrap',
				model.document.getRoot()
			);
			// }
			console.log(a);
			writer.setAttribute('hasColumns', true, a);

			for (const value of multipleBox) {
				const position = writer.createPositionAt(
					value,
					value.maxOffset
				);

				this.editor.model.insertContent(
					createMultipleBox(writer),
					position,
					'end'
				);
			}
		});
	}

	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;
		const allowedIn = model.schema.findAllowedParent(
			selection.getFirstPosition(),
			'multipleBox'
		);

		this.isEnabled = allowedIn === null;
	}
}

function createMultipleBox(writer) {
	const multipleBoxDescription = writer.createElement('multipleBoxContent');

	writer.appendElement('paragraph', multipleBoxDescription);

	return multipleBoxDescription;
}
