import React from 'react';
import styled from 'styled-components'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      // display: true,
      text: 'Chart.js Bar Chart',
    },
  },
}

interface ChartProps {
  data: any
}

const VerticalBarChart = ({data}: ChartProps) => {
  return (
    <Container>
      <Bar options={options} data={data} />
    </Container>
  )
}

const Container = styled.div`
  width: 40rem;
  /* border: 1px solid red; */
`

export default VerticalBarChart
